import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  @media (min-width: 1100px) {
    display: flex;
    flex-direction: column;
    padding: 44px;
    border-radius: 16px;
    background: white;
    box-shadow: 4px 1px 84px rgba(0, 0, 0, 0.12);
    margin-left: 197px;
    margin-right: 26px;
    width: 415px;
    min-width: 415px;
  }

  @media (min-width: 600px) AND (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    padding: 44px;
    border-radius: 16px;
    background: white;
    box-shadow: 4px 1px 84px rgba(0, 0, 0, 0.12);
    margin-left: 25%;
    width: 415px;
    min-width: 415px;
  }

  @media (max-width: 600px) {
    transform: scale(0.9);
    display: flex;
    flex-direction: column;
    padding: 44px;
    border-radius: 16px;
    background: white;
    box-shadow: 4px 1px 84px rgba(0, 0, 0, 0.12);
    margin-left: 0;
    width: 415px;
    min-width: 415px;
  }
`

export const Title = styled.div`
  font-size: 20px;
  margin-top: 44px;
  color: #080038;
`

export const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #393360;
  width: 220px;
  line-height: normal;
  margin-top: 20px;
`

export const FooterText = styled.p`
  font-size: 12px;
  color: #080038;
  text-align: center;

  > a {
    color: #f46363;
    text-decoration: none;
  }
`
export const ImageLogo = styled.img`
  width: 162px;
  object-fit: contain;
  object-position: center center;
`

export const ImageText = styled.div`
  width: 605px;
  height: 768px;
  margin: 28px;
  position: relative;
  filter: drop-shadow(4px 1px 84px rgba(0, 0, 0, 0.12));
`

export const Image = styled.img`
  width: 605px;
  height: 769px;
  object-fit: cover;
  object-position: center center;
  @media (max-width: 1300px) {
    display: none;
  }
`

export const TextImage = styled.div`
  position: absolute;
  top: 54px;
  left: 50px;
  color: white;
  font-family: 'basic-grotesque-pro-regular', sans-serif;
  width: 402px;
  font-size: 36.58px;
  font-weight: 400;
  line-height: 43.9px;
  @media (max-width: 1300px) {
    display: none;
  }
`

export const NakedLayout = styled.section`
  overflow-x: hidden;
  overflow-y: hidden;
`
