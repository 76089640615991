import { Dialog } from '../../ui'
import { CloseButtonContainer, CloseButton } from './style'
import { IconCross } from '../../ui/icon/icons'
import SendCta from '../../prescription/send-prescription-dialog/send-cta'

const MarkedAsSent = ({
  isOpen,
  setIsOpen,
  lastStatus,
  prescriptionId,
  status,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      type='defaultCenter'
    >
      <CloseButtonContainer>
        <CloseButton onClick={() => setIsOpen(false)}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>

      <div>
        <SendCta
          lastStatus={lastStatus}
          prescriptionId={prescriptionId}
          status={status}
          setIsOpen={setIsOpen}
        />
      </div>
    </Dialog>
  )
}

export default MarkedAsSent
