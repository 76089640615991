import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import {
  Cell,
  Container,
  Content,
  HeadRow,
  MenuContainer,
  Row,
  Table,
  TableBody,
  TableHead,
  Header,
  SeeMore,
  SeeIcon,
  FlexContainer,
} from './style'
import { AiOutlineEye } from 'react-icons/ai'
import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import { Button, Dropdown, NavLink, Spinner } from '../../components/ui'
import { useOnboardingProfileCheck } from '../../utils/hooks'
import { HeaderLinks } from '../../components/section/header/props'
import { PatientCell } from '../../components/ui'
import QuizStatusTag from '../../components/quizzes/quiz-status-tag'
import QuizDateTime from '../../components/quizzes/quiz-date-time'
import { useGetQuizzesByPrescriberId } from '../../services/api/quiz/quiz'
import { QuizStatus, Role } from '../../services/api/types'
import { useGetPatients } from '../../services/api/patients/patients'
import BlockAccessModal from '../../components/block-access-modal'
import BlockQuizAccessModal from './block-access-modal'
import { useGetMe } from '../../utils/hooks/getMe'

const QuizzesPage = () => {
  useOnboardingProfileCheck()

  // Attributes
  const history = useHistory()
  const { data: prescriber } = useGetMe()

  const [isBlockAccessModalOpen, setIsBlockAccessModalOpen] = useState(false)
  const [isBlockQuizAccessModalOpen, setIsBlockQuizAccessModalOpen] =
    useState(false)

  const { data: patients, isLoading: patientsLoading } = useGetPatients(
    prescriber.id,
    {
      size: 1000,
    }
  )

  const { data: quizzes, isLoading } = useGetQuizzesByPrescriberId(
    prescriber?.id,
    {
      query: { enabled: !!prescriber?.id },
    }
  )

  const [seeMore, setSeeMore] = useState(false)

  // Render
  return (
    <Layout active={HeaderLinks.Questionnaires}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Questionnaires </title>
      </Helmet>
      <Container>
        <PageTitle
          title='Mes questionnaires'
          subtitle={
            <Header>
              <p>
                Ce questionnaire est un outil vous permettant de mieux préparer
                votre consultation et votre accompagnement patient. Il a été
                développé par des experts afin que l’interprétation de ses
                résultats puissent vous guider lors de votre consultation. Il
                permet une analyse des besoins spécifiques de l’organisme en
                nutriments et micronutriments liés au mode de vie, à
                l’alimentation, à l’environnement de votre patient.
              </p>
              {!seeMore && (
                <button onClick={() => setSeeMore(true)}>Voir plus</button>
              )}
              <SeeMore seeMore={seeMore}>
                <p>
                  À travers d’une série de 100 questions (environ 10 minutes),
                  définissez le profil de votre patient, ses éventuelles
                  carences nutritionnelles et son niveau de bien-être sur les 3
                  axes d’analyse :
                </p>
                <ul>
                  <li>Bien-être mental</li>
                  <li>Bien-être physique</li>
                  <li>Bien-être du microbiote intestinal</li>
                </ul>
                <p>
                  Grâce à l’analyse des résultats qui vous seront communiqués,
                  définissez l’accompagnement complet personnalisé pour votre
                  patient (plan alimentaire, hygiène de vie et complémentation).
                  Découvrez un exemple de résultats de questionnaire
                  préparatoire en téléchargeant{' '}
                  <a
                    href='https://firebasestorage.googleapis.com/v0/b/compliment-pro-prod.appspot.com/o/476_2022-07-20T14_31_50.505692Z%20(1).pdf?alt=media&token=2a85420d-4df8-46bc-b866-e65fbabbdfa3'
                    target='_blank'
                    rel='noreferrer'
                  >
                    ce document
                  </a>
                  .
                </p>
                <button onClick={() => setSeeMore(false)}>Voir moins</button>
              </SeeMore>
            </Header>
          }
          rightElements={
            <Button
              onClick={() => {
                setIsBlockQuizAccessModalOpen(true)
              }}
              iconLeft='plus'
              appearance='primary'
              isDisabled={undefined}
              isLoading={undefined}
              isActive={undefined}
            >
              Nouveau questionnaire
            </Button>
          }
        />
        <Content>
          {isLoading ? (
            <Spinner fullScreen={undefined} />
          ) : (
            <Table>
              <TableHead>
                <HeadRow>
                  <Cell>Date</Cell>
                  <Cell>Patient</Cell>
                  <Cell>Statut</Cell>
                  <Cell />
                </HeadRow>
              </TableHead>

              <TableBody>
                <Row>
                  <Cell>
                    <QuizDateTime dateTime={new Date()} />
                  </Cell>
                  {patientsLoading ? (
                    <Cell>
                      <Spinner fullScreen={undefined} />
                    </Cell>
                  ) : (
                    <PatientCell
                      email='john@exemple.com'
                      lastName='Exemple'
                      firstName='John'
                      id={-1}
                    />
                  )}
                  <Cell width='6.25rem'>
                    <QuizStatusTag status={'COMPLETED'} />
                  </Cell>
                  <Cell width='4.375rem'>
                    <FlexContainer>
                      <NavLink
                        as={Link}
                        to={`/quizzes/${-1}`}
                        variant={undefined}
                      >
                        <SeeIcon>
                          <AiOutlineEye />
                        </SeeIcon>
                      </NavLink>
                      {/* Error something wrong check with Tanguy*/}
                      {/*'COMPLETED' !== QuizStatus.SENT && (
                        <Dropdown
                          content={
                            <MenuContainer>
                              <NavLink
                                as={Link}
                                to={`/quizzes/${-1}`}
                                variant={undefined}
                              >
                                Voir
                              </NavLink>
                            </MenuContainer>
                          }
                        >
                          <Button
                            iconLeft='menu'
                            appearance='minimal'
                            children={undefined}
                            isDisabled={undefined}
                            isLoading={undefined}
                            isActive={undefined}
                          />
                        </Dropdown>
                        )*/}
                    </FlexContainer>
                  </Cell>
                </Row>
                {quizzes.map(quiz => {
                  const patient = patients?.data?.find(
                    patient => patient.id === quiz.patientId
                  )
                  return (
                    <Row key={quiz.id}>
                      <Cell>
                        <QuizDateTime dateTime={new Date(quiz.createdAt)} />
                      </Cell>
                      {patientsLoading ? (
                        <Cell>
                          <Spinner fullScreen={undefined} />
                        </Cell>
                      ) : (
                        <PatientCell
                          email={patient?.email}
                          firstName={patient?.firstName}
                          lastName={patient?.lastName}
                          id={patient?.id}
                        />
                      )}
                      <Cell width='6.25rem'>
                        <QuizStatusTag status={quiz.status} />
                      </Cell>
                      <Cell width='4.375rem'>
                        {quiz.status !== QuizStatus.SENT && (
                          <FlexContainer>
                            <NavLink
                              as={Link}
                              to={`/quizzes/${quiz.id}`}
                              variant={undefined}
                            >
                              <SeeIcon>
                                <AiOutlineEye />
                              </SeeIcon>
                            </NavLink>
                            <Dropdown
                              content={
                                <MenuContainer>
                                  <NavLink
                                    as={Link}
                                    to={`/quizzes/${quiz.id}`}
                                    variant={undefined}
                                  >
                                    Voir
                                  </NavLink>
                                </MenuContainer>
                              }
                            >
                              <Button
                                iconLeft='menu'
                                appearance='minimal'
                                children={undefined}
                                isDisabled={undefined}
                                isLoading={undefined}
                                isActive={undefined}
                              />
                            </Dropdown>
                          </FlexContainer>
                        )}
                      </Cell>
                    </Row>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </Content>
      </Container>
      <BlockAccessModal
        isOpen={isBlockAccessModalOpen}
        setIsOpen={setIsBlockAccessModalOpen}
      />

      <BlockQuizAccessModal
        isOpen={isBlockQuizAccessModalOpen}
        setIsOpen={setIsBlockQuizAccessModalOpen}
      />
    </Layout>
  )
}

export default QuizzesPage
