import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const FileContainer = styled.div``

export const AttachFile = styled.button`
  margin: 0px 0;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 17px;
  cursor: pointer;
  span {
    margin-right: 5px;
    font-size: 14px;
    background-color: ${Color.DEEP_SEA};
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  p {
    font-size: 14px;
  }
`
