import { Container } from './style'
import { NoFound } from '../../../ui'
import { NoProductProps } from './props'

const NoProduct = ({ setIsOpenDialogMoreProducts }: NoProductProps) => {
  return (
    <Container>
      <NoFound
        title='Votre panier est vide !'
        descritpion=''
        clearAllFilters={() => setIsOpenDialogMoreProducts(true)}
        textButton='Ajouter des produits'
      />
    </Container>
  )
}

export default NoProduct
