import { NoFound } from '../ui'
import OrderRow from './order-row'
import { Section, Table, HeadRow, Cell, TableHead, TableBody } from './style'

const OrdersTable = () => {
  //Attributes
  const orders: any[] = [
    {
      id: '0067890813',
      createdAt: '26/01/2022',
      totalPrice: 50.0,
      totalDiscounts: -10.0,
      promoCode: 'Promo Code Discount',
      prescriptionId: 3578903422,
      prescriberId: 5,
    },
    {
      id: '0067890813',
      createdAt: '26/01/2022',
      totalPrice: 50.0,
      totalDiscounts: -10.0,
      promoCode: 'Promo Code Discount',
      prescriptionId: 3578903422,
      prescriberId: 5,
    },
    {
      id: '0067890813',
      createdAt: '26/01/2022',
      totalPrice: 50.0,
      totalDiscounts: -10.0,
      promoCode: 'Promo Code Discount',
      prescriptionId: 3578903422,
      prescriberId: 5,
    },
    {
      id: '0067890813',
      createdAt: '26/01/2022',
      totalPrice: 50.0,
      totalDiscounts: -10.0,
      promoCode: 'Promo Code Discount',
      prescriptionId: 3578903422,
      prescriberId: 5,
    },
    {
      id: '0067890813',
      createdAt: '26/01/2022',
      totalPrice: 50.0,
      totalDiscounts: -10.0,
      promoCode: 'Promo Code Discount',
      prescriptionId: 3578903422,
      prescriberId: 5,
    },
    {
      id: '0067890813',
      createdAt: '26/01/2022',
      totalPrice: 50.0,
      totalDiscounts: -10.0,
      promoCode: 'Promo Code Discount',
      prescriptionId: 3578903422,
      prescriberId: 5,
    },
  ]
  //Render
  return (
    <Section>
      {orders && (
        <div>
          <Table>
            <TableHead>
              <HeadRow>
                <Cell>Order Id</Cell>
                <Cell>Date</Cell>
                <Cell>Prix</Cell>
                <Cell>Réduction</Cell>
                <Cell>Code promo</Cell>
                <Cell>Prescription liée</Cell>
                <Cell>Prescripteur</Cell>
                <Cell>Tracking</Cell>
                <Cell>Actions</Cell>
              </HeadRow>
            </TableHead>
            <TableBody>
              {orders?.map(order => {
                return (
                  <OrderRow
                    order={order}
                    prescriber={{
                      email: 'rap@gmail.com',
                      firstName: 'michel',
                      lastName: 'thomas',
                    }}
                  />
                )
              })}
            </TableBody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {!orders?.length && (
              <NoFound title='Aucune recommandation' descritpion='' />
            )}
          </div>
        </div>
      )}
    </Section>
  )
}

export default OrdersTable
