import { useState } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import { Container, Content } from './style'
import { useOnboardingProfileCheck } from '../../utils/hooks'
import { ViewMode } from '../../utils/constants'
import { HeaderLinks } from '../../components/section/header/props'
import Products from '../../components/products-v2'
import RightElements from '../../components/products-v2/right-elements'
import UrlProductDialog from '../../components/product/url-product-dialog'
import { useGetUserBySub } from '../../services/api/users/users'
import FavoriteButton from '../../components/products-v2/favorite'
import { Role } from '../../services/api/types'

const ProductsPage = () => {
  useOnboardingProfileCheck()
  const userRole = localStorage.getItem('role') as Role
  // Attributes
  const [viewMode, setViewMode] = useState(ViewMode.GRID)
  const { data: prescriber } = useGetUserBySub(
    { role: userRole },
    {
      query: {
        enabled: !!userRole,
        retry: 1,
      },
    }
  )

  return (
    <Layout active={HeaderLinks.Catalogue}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Catalogue</title>
      </Helmet>
      <Container>
        <PageTitle
          title={`Bonjour ${prescriber?.firstName} 👋`}
          subtitleInfo='Que recherchez vous?'
          rightSecondElements={<FavoriteButton />}
          rightElements={
            <RightElements viewMode={viewMode} setViewMode={setViewMode} />
          }
          titleClassName='custom-title-class'
        />
        <Content>
          <Products
            viewModeProduct={ViewMode.GRID}
            from='productPage'
            doNotAllowProductToBeAdded
            allowProductUrl={true}
          />
        </Content>
      </Container>
      <UrlProductDialog />
    </Layout>
  )
}

export default ProductsPage
