const InfoBig = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 28 29'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <path
      d='M14.0007 26.3353C20.444 26.3353 25.6673 21.1119 25.6673 14.6686C25.6673 8.2253 20.444 3.00195 14.0007 3.00195C7.55733 3.00195 2.33398 8.2253 2.33398 14.6686C2.33398 21.1119 7.55733 26.3353 14.0007 26.3353Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 19.3346V14.668'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 10.002H14.0117'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default InfoBig
