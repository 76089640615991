const IconCross = props => (
  <svg
    viewBox='0 0 10 10'
    {...props}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M7.5 2.5L2.5 7.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2.5 2.5L7.5 7.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default IconCross
