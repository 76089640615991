import { QuizCategoryDTO, QuizScoreDTO } from '../../../services/api/types'

export interface QuizCategoryProps {
  format?: QuizCategoryFormat
  category: QuizCategoryDTO
  scores: QuizScoreDTO[]
  color?: QuizCategoryColor
  patientFirstname?: string
  patientName?: string
  quizCreatedAt?: string
}

export enum QuizCategoryFormat {
  GRID = 'GRID',
  LIST = 'list',
}

export enum QuizCategoryColor {
  DEFAULT = 'DEFAULT',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
}

export const QuizCategoryColors = [
  QuizCategoryColor.DEFAULT,
  QuizCategoryColor.BLUE,
  QuizCategoryColor.ORANGE,
  QuizCategoryColor.GREEN,
]
