import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useGetMe } from '../../utils/hooks/getMe'
import { Role } from '../../services/api/types'

/**
 * This hook is checking if the authUser have a firstName and lastName, if
 * one of them are missing, he will redirect the user to the onboarding
 * step where it's asked to fill these information
 */
export const useOnboardingProfileCheck = () => {
  const { data: presciber } = useGetMe()
  let history = useHistory()

  useEffect(() => {
    if (localStorage.getItem('role') === Role.PRESCRIBER) {
      if (presciber && !presciber.isOnboarded) {
        history.push('/onboarding/profile')
        return
      }
    }
    if (localStorage.getItem('role') === Role.PATIENT) {
      history.push('/patient/recommendations')
    }
  }, [presciber, history])
}
