import { useMemo, useState } from 'react'
import {
  useGetPrescriberRewardsByPrescriberId,
  useGetPrescriberEaringsByPrescriberId,
} from '../../services/api/prescriber/prescriber'
import {
  PrescriberRewardDTO,
  RewardType,
  PrescriberEarningsDTO,
} from '../../services/api/types'
import RewardMonthlyCard from './cards/monthly-card'
import { RewardsProps } from './props'
import RewardContainer from './reward-container'
import RewardDialog from './reward-dialog'
import RewardFormDialog from './reward-form-dialog'
import { Container, TableContainer } from './style'
import GainsTable from './tables/table'
import GainsTableHeader from './tables/header'
import Gain from './tables/table/gain'
import OrderStatus from './tables/table/order-status'
import { getMaxPercentageItem } from '../../utils/helpers'
import { useGetDiscountsByPrescriberId } from '../../services/api/prescriber/prescriber'

const Rewards = ({ prescriber }: RewardsProps) => {
  // Attributes
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
  const [selectedReward, setSelectedReward] = useState<PrescriberRewardDTO>()
  const { data: rewards, refetch } = useGetPrescriberRewardsByPrescriberId(
    prescriber?.id,
    {
      size: 100, // Shouldn't have a lot of rewards
    },
    { query: { enabled: !!prescriber?.id } }
  )

  const [currentOrderPage, setCurrentOrderPage] = useState(0)

  const { data: earings } = useGetPrescriberEaringsByPrescriberId(
    prescriber?.id,
    {
      page: currentOrderPage,
      size: 5,
    },
    { query: { enabled: !!prescriber?.id } }
  )

  const discounts = useGetDiscountsByPrescriberId(
    prescriber?.id,
    {},
    { query: { enabled: !!prescriber?.id } }
  )

  const discountCode = getMaxPercentageItem(
    discounts?.data?.data,
    prescriber?.commissionPercentage
  )

  const [globalRewards, monthlyRewards] = useMemo(() => {
    return [
      rewards?.data?.filter(reward => reward.type === RewardType.GLOBAL),
      rewards?.data?.filter(reward => reward.type === RewardType.MONTHLY),
    ]
  }, [rewards?.data])

  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: (o: any) => <OrderStatus status={o.remunerationType} />,
      },
      {
        Header: 'Date',
        accessor: (o: PrescriberEarningsDTO) =>
          `${new Date(o.exchangeDate).toLocaleDateString()}`,
      },
      {
        Header: 'Point échangé',
        accessor: (o: PrescriberEarningsDTO) => <Gain gain={o.amount} />,
      },
      {
        Header: 'Nom',
        accessor: (o: PrescriberEarningsDTO) => (o?.name ? o.name : '-'),
      },
    ],
    []
  )

  // Render
  if (!globalRewards?.length && !monthlyRewards?.length) {
    return <div />
  }

  return (
    <div>
      <Container style={{ margin: prescriber?.disableCommission ? '0' : '' }}>
        {!prescriber?.disableCommission && (
          <TableContainer>
            <GainsTableHeader
              title='Mes échanges'
              subtitle=''
              setPage={setCurrentOrderPage}
              page={earings?.page}
              nbPages={earings?.nbPages}
              size={
                earings?.totalAmount < earings?.size
                  ? earings?.totalAmount
                  : earings?.size
              }
              totalAmount={earings?.totalAmount}
            />
            <GainsTable columns={columns} data={earings?.data ?? []} />
          </TableContainer>
        )}

        {!!monthlyRewards?.length && (
          <RewardContainer title={`Vos achats à -${discountCode?.percentage}%`}>
            <RewardMonthlyCard
              title={monthlyRewards[0].title}
              description={monthlyRewards[0].description}
              totalCount={monthlyRewards[0].totalCount}
              count={monthlyRewards[0].count}
              status={monthlyRewards[0].status}
              size={!!globalRewards?.length ? 'SMALL' : 'BIG'}
              onTooltipClick={
                !globalRewards?.length
                  ? () => setIsDialogOpened(true)
                  : undefined
              }
              onSelect={() => setSelectedReward(monthlyRewards[0])}
            />
          </RewardContainer>
        )}
      </Container>

      {/* DIALOGS */}
      <RewardDialog
        isOpen={isDialogOpened}
        onClose={() => setIsDialogOpened(false)}
      />
      <RewardFormDialog
        isOpen={selectedReward != null}
        onClose={() => {
          setSelectedReward(null)
          refetch()
        }}
        rewardId={selectedReward?.id}
        formId={selectedReward?.formId}
        prescriber={prescriber}
      />
    </div>
  )
}

export default Rewards
