import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'
import { ViewMode } from '../../../../utils/constants'

export const Container = styled.div`
  position: relative;
  display: flex;
  border: 10px solid rgba(255, 255, 255, 0);
  flex-direction: row;
  border-radius: 24px;
  margin-bottom: 20px;
  padding-right: ${({ $mode }) => ($mode === ViewMode.LIST ? '5px' : 0)};
  padding-bottom: ${({ $mode }) => ($mode === ViewMode.GRID ? '5px' : 0)};
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.LIST ? 'row' : 'column'};
  width: ${({ $mode }) => {
    if ($mode === ViewMode.LIST) {
      return '100%'
    } else if ($mode === ViewMode.GRID) {
      return '23%'
    } else if ($mode === ViewMode.GRID2) {
      return '48%'
    } else if ($mode === ViewMode.SLIDE) {
      return '250px'
    }
  }};
  min-height: 140px;

  ${MediaQuery.XL_AND_DOWN} {
    width: ${({ $mode }) => ($mode === ViewMode.SLIDE ? ' 45%' : '30%')};
  }

  ${MediaQuery.L_AND_DOWN} {
    width: 30%;
  }
  ${MediaQuery.M_AND_DOWN} {
    width: 48%;
  }
  ${MediaQuery.S_AND_DOWN} {
    width: 100%;
  }

  background-color: #fdfdfe;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
`
export const BrandImage = styled.img`
  object-fit: contain;
  height: 30px !important ;
`

export const Image = styled.img`
  cursor: pointer;
  width: ${({ $mode }) =>
    $mode === ViewMode.GRID ||
    $mode === ViewMode.GRID2 ||
    $mode === ViewMode.SLIDE
      ? '100%'
      : '180px'};
  height: ${({ $mode }) =>
    $mode === ViewMode.GRID ||
    $mode === ViewMode.GRID2 ||
    $mode === ViewMode.SLIDE
      ? '130px'
      : 'auto'};
  object-fit: cover;
  border-radius: 14px;
  background-color: #e7e8e9;
  margin-right: 10px;
  margin-bottom: 10px;
`

export const Content = styled.div`
  width: 100%;
`
export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  h4 {
    cursor: pointer;
    color: #393360;
    font-weight: 300;
    font-size: ${({ $mode }) => ($mode === ViewMode.LIST ? '20px' : '18px')};
    margin-right: 10px;
    margin-bottom: 10px;

    span {
      font-size: 10px;
      margin-left: 8px;
      color: #3a835f;
    }
  }

  img {
    object-fit: contain;
    height: 30px !important ;
    width: 50px;
  }
`
export const Price = styled.div`
  font-size: 16px;

  color: #3a845e;
  margin-bottom: 20px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Button = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  background: #3a845e;
  border: 1px solid #3a845e;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
`

export const DetailsButton = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  background: #f8f9fc;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  color: #3a845e;
`

export const FavIcon = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  border: 1px solid #e7e9f4;
  border-radius: 100px;

  color: #f9b50b;
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

export const MenuContainerItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  opacity: 0.7;
  p {
    margin-bottom: 2px;
  }
`

export const Checkbox = styled.div`
  width: 15px;
  height: 15px;
  border: 2px solid #65b293;
  margin-right: 10px;
  border-radius: 3px;
  background-color: ${props => (props.checked ? '#65b293' : '')};
`
