/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type {
  NoteDTO,
  NotePagingDTO,
  GetPrescriberNotesParams,
  CreateNoteDTO,
  UpdateNoteDTO,
} from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getNoteById = (
  noteId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NoteDTO>(
    { url: `/notes/${noteId}`, method: 'get' },
    options
  )
}

export const getGetNoteByIdQueryKey = (noteId: string) => [`/notes/${noteId}`]

export const useGetNoteById = <
  TData = AsyncReturnType<typeof getNoteById>,
  TError = unknown
>(
  noteId: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getNoteById>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetNoteByIdQueryKey(noteId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getNoteById>> = () =>
    getNoteById(noteId, requestOptions)

  const query = useQuery<AsyncReturnType<typeof getNoteById>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!noteId, ...queryOptions }
  )

  return {
    queryKey,
    ...query,
  }
}

export const getPrescriberNotes = (
  prescriberId: number,
  params?: GetPrescriberNotesParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NotePagingDTO>(
    { url: `/prescriber/${prescriberId}/notes`, method: 'get', params },
    options
  )
}

export const getGetPrescriberNotesQueryKey = (
  prescriberId: number,
  params?: GetPrescriberNotesParams
) => [`/prescriber/${prescriberId}/notes`, ...(params ? [params] : [])]

export const useGetPrescriberNotes = <
  TData = AsyncReturnType<typeof getPrescriberNotes>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetPrescriberNotesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriberNotes>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrescriberNotesQueryKey(prescriberId, params)

  const queryFn: QueryFunction<AsyncReturnType<typeof getPrescriberNotes>> =
    () => getPrescriberNotes(prescriberId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriberNotes>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const createPatientNote = (
  patientId: number,
  createNoteDTO: CreateNoteDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NoteDTO>(
    {
      url: `/patients/${patientId}/notes`,
      method: 'post',
      data: createNoteDTO,
    },
    options
  )
}

export const useCreatePatientNote = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createPatientNote>,
    TError,
    { patientId: number; data: CreateNoteDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createPatientNote>,
    { patientId: number; data: CreateNoteDTO }
  > = props => {
    const { patientId, data } = props || {}

    return createPatientNote(patientId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createPatientNote>,
    TError,
    { patientId: number; data: CreateNoteDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const updatePatientNote = (
  patientId: number,
  noteId: string,
  updateNoteDTO: UpdateNoteDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NoteDTO>(
    {
      url: `/patients/${patientId}/notes/${noteId}`,
      method: 'put',
      data: updateNoteDTO,
    },
    options
  )
}

export const useUpdatePatientNote = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePatientNote>,
    TError,
    { patientId: number; noteId: string; data: UpdateNoteDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePatientNote>,
    { patientId: number; noteId: string; data: UpdateNoteDTO }
  > = props => {
    const { patientId, noteId, data } = props || {}

    return updatePatientNote(patientId, noteId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof updatePatientNote>,
    TError,
    { patientId: number; noteId: string; data: UpdateNoteDTO },
    TContext
  >(mutationFn, mutationOptions)
}
