import {
  useGetOrdersSummaryByPrescriberId,
  useGetPrescriberEaringsByPrescriberId,
} from '../../../../services/api/prescriber/prescriber'
import { Euro } from '../../../ui/icon/icons'
import SummaryBox from '../summary-box'
import { SummariesContainerProps } from './props'
import { Container } from './style'
import {
  RemunerationType,
  PrescriberEarningsDTO,
} from '../../../../services/api/types'

const SummariesContainer = ({ prescriber }: SummariesContainerProps) => {
  // Attributes
  const { data: summary } = useGetOrdersSummaryByPrescriberId(prescriber?.id, {
    query: { enabled: !!prescriber?.id },
  })

  const { data: earings } = useGetPrescriberEaringsByPrescriberId(
    prescriber?.id,
    {
      page: 0,
      size: 1000,
    },
    { query: { enabled: !!prescriber?.id } }
  )

  function sumAmounts(
    data: PrescriberEarningsDTO[],
    remunerationType: RemunerationType
  ) {
    let sum = 0

    data?.forEach(item => {
      if (item.remunerationType === remunerationType) {
        sum += item.amount
      }
    })

    return sum
  }

  const LEGACY = sumAmounts(earings?.data, 'LEGACY')
  const GIFT_CARD_PATIENT = sumAmounts(earings?.data, 'GIFT_CARD_PATIENT')
  const GIFT_CARD_PRO = sumAmounts(earings?.data, 'GIFT_CARD_PRO')
  const PAYMENT_RECEIVED = sumAmounts(earings?.data, 'PAYMENT_RECEIVED')
  const PAYMENT_SENT = sumAmounts(earings?.data, 'PAYMENT_SENT')

  const EarningsExchanged = LEGACY + GIFT_CARD_PRO + PAYMENT_SENT
  const paymentsToCompliment = PAYMENT_RECEIVED
  const balance =
    Number(summary?.totalPrice) +
    PAYMENT_RECEIVED -
    LEGACY -
    GIFT_CARD_PRO -
    PAYMENT_SENT -
    GIFT_CARD_PATIENT

  // Render
  return (
    <Container>
      <SummaryBox
        icon={(width, height, color) => (
          <Euro width={width} height={height} fill={color} stroke={color} />
        )}
        title='Total de vos points'
        price={
          summary?.totalPrice ? `${summary.totalPrice.toFixed(2)}pts` : '-'
        }
        tooltipContent='Montant total des points que vous avez générés sur les commandes de vos clients.'
      />
      <SummaryBox
        icon={(width, height, color) => (
          <Euro width={width} height={height} fill={color} stroke={color} />
        )}
        title='Points échangés'
        price={EarningsExchanged ? `${EarningsExchanged.toFixed(2)}pts` : '-'}
        tooltipContent='Total des points que vous avez échangés contre des bons d’achats ou des virements.'
      />

      {paymentsToCompliment ? (
        <SummaryBox
          icon={(width, height, color) => (
            <Euro width={width} height={height} fill={color} stroke={color} />
          )}
          title='Vos paiements à Simplycure'
          price={
            paymentsToCompliment ? `${paymentsToCompliment.toFixed(2)}pts` : '-'
          }
          tooltipContent='Montants que vous avez payés à Simplycure (Par exemple lorsque vous devez rembourser  des commandes non payées pour vos clients)'
        />
      ) : (
        ''
      )}
      <SummaryBox
        icon={(width, height, color) => (
          <Euro width={width} height={height} fill={color} stroke={color} />
        )}
        title='Balance'
        price={
          balance ? (
            <p style={{ color: balance > 0 ? '#27ae60' : '#e74c3c' }}>
              {balance.toFixed(2)}pts
            </p>
          ) : (
            '-'
          )
        }
        tooltipContent='Solde actuel de vos points (ne prend pas encore en compte les commandes non payées pour vos clients)'
      />
    </Container>
  )
}

export default SummariesContainer
