import { useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import {
  Header,
  Nav,
  Ul,
  Li,
  OptionsContainer,
  Academy,
  DropDownContent,
  DropDownContentItem,
} from './style'
import { Logo, DropdownButton } from '../../ui'
import AccesAcademyDialog from './acces-academy-dialog'
import AuthState from '../../auth/auth-state'
import { HeaderLinks } from './props'
import SubMenu from './sub-menu'
import CreateRecoDialog from '../../reco/create-reco-dialog'
import BlockAccessModal from '../../../components/block-access-modal'
import { useGetMe } from '../../../utils/hooks/getMe'

const HeaderEl = ({ isPublic, active }) => {
  const history = useHistory()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isCreateRecoDialogOpen, setIsCreateRecoDialogOpen] = useState('')
  const { data: prescriber } = useGetMe()

  const [isBlockAccessModalOpen, setIsBlockAccessModalOpen] = useState(false)

  return (
    <Header>
      <Link to='/'>
        <Logo />
      </Link>
      {!isPublic && (
        <Nav>
          <Ul>
            <SubMenu headerText='Accueil' headerHref='/' subNavItem={[]} />
            <SubMenu
              headerText='Clients'
              headerHref='/patients'
              subNavItem={[
                {
                  headerText: 'Clients',
                  headerHref: '/patients',
                },
                {
                  headerText: 'Notes',
                  headerHref: '/notes',
                },
              ]}
            />
            <SubMenu
              headerText='Recommandations'
              headerHref='/prescriptions'
              subNavItem={[]}
            />
            <SubMenu
              headerText='Protocoles'
              headerHref='/protocoles'
              subNavItem={[]}
            />
            <SubMenu
              headerText='Catalogue'
              headerHref='/catalog'
              subNavItem={[
                {
                  headerText: 'Catalogue',
                  headerHref: '/catalog',
                },
                {
                  headerText: 'Mes favoris',
                  headerHref: '/favorites',
                },
                {
                  headerText: 'Charte de qualité',
                  externalLink: true,
                  headerHref:
                    'https://www.compliment-pro.com/qualityCharter.pdf',
                },
              ]}
            />
          </Ul>
        </Nav>
      )}

      {!isPublic && prescriber?.email && (
        <OptionsContainer>
          <DropdownButton
            popupContentWidth='250px'
            textColor='white'
            onClick={() => {
              history.push('/prescriptions/new?step=2')
            }}
            linkText='Créer une recommandation'
            DropDownContent={
              <DropDownContent>
                <DropDownContentItem
                  onClick={() => {
                    history.push('/prescriptions/new?step=2')
                  }}
                >
                  Créer une recommandation
                </DropDownContentItem>
                <DropDownContentItem
                  onClick={() => history.push('/patients/new')}
                >
                  Créer un client
                </DropDownContentItem>
                <DropDownContentItem
                  onClick={() =>
                    history.push(
                      '/marketplace-protocoles/new?step=1&type=marketplace'
                    )
                  }
                >
                  Créer un protocole
                </DropDownContentItem>
              </DropDownContent>
            }
          />

          <Li active={(active === HeaderLinks.Academy).toString()}>
            <a>
              <Academy
                onClick={() => {
                  const url =
                    'https://complimentme.notion.site/complimentme/Compliment-Academy-911e0b279ed546caa8d519066b4dfe44'

                  if (prescriber?.isVerified) window.open(url, '_blank')
                  else setIsBlockAccessModalOpen(true)
                }}
              >
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/academyicon45678%22%22fr.png?alt=media&token=d7fb7a9d-7496-4df5-bd8e-62c81c1a3a24'
                  alt='academy'
                />
                <span>Academy</span>
              </Academy>
            </a>
          </Li>

          <AuthState />
        </OptionsContainer>
      )}

      <AccesAcademyDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
      <CreateRecoDialog
        isOpen={isCreateRecoDialogOpen}
        onClose={() => setIsCreateRecoDialogOpen('')}
        type={isCreateRecoDialogOpen}
      />
      <BlockAccessModal
        isOpen={isBlockAccessModalOpen}
        setIsOpen={setIsBlockAccessModalOpen}
      />
    </Header>
  )
}

export default HeaderEl
