import { IBadgeProps } from './props'
import { BadgePrimaryContainer, BadgePrimaryValue } from './style'

const Badge = ({ type, value }: IBadgeProps) => {
  //Render
  switch (type) {
    default:
      return (
        <BadgePrimaryContainer>
          <BadgePrimaryValue>{value}</BadgePrimaryValue>
        </BadgePrimaryContainer>
      )
  }
}

export default Badge
