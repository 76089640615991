import {
  Container,
  Title,
  Card,
  CardTitle,
  CardButton,
  CardButtonText,
  CardButtonIcon,
  FlexList,
} from './style'
import { IMoreInformationProps } from './props'
import { BsDownload } from 'react-icons/bs'
import { AiOutlineLink } from 'react-icons/ai'
import { HorizontalSeparator } from '../../left-side/style'
import { useGetProductInformationsByProductId } from '../../../../../services/api/products/products'

const MoreInformation = ({ product }: IMoreInformationProps) => {
  // Attibutes
  const { data: productInformations } = useGetProductInformationsByProductId(
    product?.id,
    {
      query: { enabled: !!product?.id },
    }
  )

  return (
    !!productInformations?.length && (
      <Container>
        <Title>Plus d’informations</Title>
        <HorizontalSeparator />
        <div>
          <FlexList>
            {productInformations?.map(productInformation => {
              return (
                <Card key={productInformation?.id}>
                  <CardTitle>{productInformation?.name}</CardTitle>
                  <CardButton href={productInformation?.link} target='_blank'>
                    <CardButtonText>
                      {productInformation?.linkTitle}
                    </CardButtonText>
                    {productInformation.productLinkType === 'DOWNLOAD' && (
                      <CardButtonIcon>
                        <BsDownload />
                      </CardButtonIcon>
                    )}
                    {productInformation.productLinkType === 'REDIRECT' && (
                      <CardButtonIcon>
                        <AiOutlineLink />
                      </CardButtonIcon>
                    )}
                  </CardButton>
                </Card>
              )
            })}
          </FlexList>
        </div>
      </Container>
    )
  )
}

export default MoreInformation
