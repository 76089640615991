/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PatientTarget =
  | 'ZERO_TO_SIX_MONTHS'
  | 'SEVEN_TO_ELEVEN_MONTHS'
  | 'ONE_TO_THREE_YEARS'
  | 'FOUR_TO_SIX_YEARS'
  | 'SEVEN_TO_TEN_YEARS'
  | 'ELEVEN_TO_FOURTEEN_YEARS'
  | 'FIFTEEN_TO_SEVENTEEN_YEARS'
  | 'EIGHTEEN_TO_SIXTY_YEARS'
  | 'SIXTY_TO_MORE_YEARS'
  | 'Pregnancy'
  | 'Feeding'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientTarget = {
  ZERO_TO_SIX_MONTHS: 'ZERO_TO_SIX_MONTHS' as PatientTarget,
  SEVEN_TO_ELEVEN_MONTHS: 'SEVEN_TO_ELEVEN_MONTHS' as PatientTarget,
  ONE_TO_THREE_YEARS: 'ONE_TO_THREE_YEARS' as PatientTarget,
  FOUR_TO_SIX_YEARS: 'FOUR_TO_SIX_YEARS' as PatientTarget,
  SEVEN_TO_TEN_YEARS: 'SEVEN_TO_TEN_YEARS' as PatientTarget,
  ELEVEN_TO_FOURTEEN_YEARS: 'ELEVEN_TO_FOURTEEN_YEARS' as PatientTarget,
  FIFTEEN_TO_SEVENTEEN_YEARS: 'FIFTEEN_TO_SEVENTEEN_YEARS' as PatientTarget,
  EIGHTEEN_TO_SIXTY_YEARS: 'EIGHTEEN_TO_SIXTY_YEARS' as PatientTarget,
  SIXTY_TO_MORE_YEARS: 'SIXTY_TO_MORE_YEARS' as PatientTarget,
  Pregnancy: 'Pregnancy' as PatientTarget,
  Feeding: 'Feeding' as PatientTarget,
}
