import styled from 'styled-components/macro'

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
`
export const NavItem = styled.div`
  border-left: ${props => (props.isActive ? '2.5px solid #01875c' : '')};
  &:hover {
    border-left: 2.5px solid #eee;
  }
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  a {
    white-space: nowrap;
    font-size: 13px;
    text-decoration: none;
    color: black;
    margin: 0 20px;
  }
`
