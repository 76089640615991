import { View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import Belgium from '../../assets/images/belgium.jpg'
import France from '../../assets/images/france.jpg'

const BookletFooter = ({}) => (
  <View style={styles.footer}>
    <View>
      <Text
        style={{
          color: '#0D133C',
          marginBottom: '3px',
        }}
      >
        Besoin d’aide pour commander ?
      </Text>
      <Text
        style={{
          color: '#0D133C',
        }}
      >
        Nous sommes là pour vous.
      </Text>
    </View>
    <View>
      <Text
        style={{
          color: '#5DB498',
          marginBottom: '3px',
        }}
      >
        Email: hello@simplycure.com
      </Text>
      <Text
        style={{
          color: '#5DB498',
        }}
      >
        Site web: www.simplycure.com
      </Text>
    </View>
    <View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '3px',
        }}
      >
        <Image source={France} style={{ width: '8px', marginRight: '4px' }} />
        <Text
          style={{
            color: '#5DB498',
          }}
        >
          +33 7 45 89 29 69
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Image source={Belgium} style={{ width: '8px', marginRight: '4px' }} />
        <Text
          style={{
            color: '#5DB498',
          }}
        >
          🇧🇪 +32 2 808 79 14
        </Text>
      </View>
    </View>
  </View>
)

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#F8F9FC',
    border: '1px solid #e7e9f4',
    borderRadius: '10px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 10px',
    margin: '5px 10px 10px 15px',
    minHeight: 'auto',
    fontSize: '8px',
  },
})

export default BookletFooter
