import { Button } from '../../ui'
import { Step, Img, Welcom, WelcomContent, WelcomHeader } from '../modal/style'
import OnboardingImg from '../../../assets/images/onboarding.webp'
import Cookies from 'js-cookie'
import { useGetDiscountWithPrescriberByCode } from '../../../services/api/discount/discount'
import { CiDeliveryTruck } from 'react-icons/ci'
import { BsCurrencyEuro } from 'react-icons/bs'
import { AiOutlineCustomerService } from 'react-icons/ai'
import { useHistory } from 'react-router'

const Step1 = ({ setOpen }) => {
  const history = useHistory()
  const savedPromoCode = Cookies.get('promoCode')

  const { data: discountWithPrescriberByCode } =
    useGetDiscountWithPrescriberByCode(savedPromoCode?.toUpperCase(), {
      query: {
        enabled: !!savedPromoCode,
      },
    })

  return (
    <Step>
      <Welcom>
        <WelcomContent>
          <div>
            <WelcomHeader>
              <p>Bienvenue</p>
            </WelcomHeader>

            {discountWithPrescriberByCode?.percentage === 0 ? (
              <>
                <p>
                  Grâce à {discountWithPrescriberByCode?.prescriberFirstName}{' '}
                  {discountWithPrescriberByCode?.prescriberLastName}, vous
                  pouvez désormais accéder à tout le catalogue Simplycure.
                </p>
                <p>
                  {discountWithPrescriberByCode?.prescriberFirstName}{' '}
                  {discountWithPrescriberByCode?.prescriberLastName} a choisi
                  Simplycure comme partenaire de compléments alimentaires. Voici
                  quelques raisons:
                </p>
              </>
            ) : (
              <>
                <p>
                  Grâce à {discountWithPrescriberByCode?.prescriberFirstName}{' '}
                  {discountWithPrescriberByCode?.prescriberLastName}, vous
                  bénéficiez de {discountWithPrescriberByCode?.percentage} % sur
                  tout le catalogue Simplycure.
                </p>

                <p>
                  {discountWithPrescriberByCode?.prescriberFirstName}{' '}
                  {discountWithPrescriberByCode?.prescriberLastName} a choisi
                  Simplycure comme partenaire de compléments alimentaires. Voici
                  quelques raisons:
                </p>
              </>
            )}

            <ul>
              <li>
                <span>
                  <CiDeliveryTruck />
                </span>
                Livraison à domicile ou en point relais dans les 48 à 72h
              </li>

              <li>
                <span>
                  <BsCurrencyEuro />
                </span>
                Satisfait ou remboursé, retours gratuits
              </li>

              <li>
                <span>
                  <AiOutlineCustomerService />
                </span>
                Notre service client est disponible 24/7
              </li>
            </ul>
          </div>
          <Button
            onClick={() => {
              history.push('/patient-catalog')
              setOpen(false)
            }}
          >
            Continuer
          </Button>
        </WelcomContent>
      </Welcom>
      <Img src={OnboardingImg} alt='OnboardingImg' />
    </Step>
  )
}

export default Step1
