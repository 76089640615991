import {
  Card,
  Header,
  Title,
  PriceText,
  PromoText,
  ImagePrescriptor,
  ImageProduct,
  PrescriptorLabel,
  PrescriptorBody,
  PrescriptorLabelTitle,
  PrescriptorTitle,
  PrescriptorEmail,
  UnderTitle,
  PriceContainer,
  PromoContainer,
  ProductDiv,
  Separator,
  BottomContainer,
  ProductLabel,
  TopContainer,
  Product,
  BoughtStatusContainer,
  BoughtStatus,
  SeenStatusContainer,
  NotSeenStatusContainer,
  SeenStatus,
  NotSeenStatus,
} from './style'
import { Avatar } from '../../ui'
import { useHistory } from 'react-router'
import { Button } from '../../ui'
import { IRecommendationCardProps } from './props'
import { format, parseISO } from 'date-fns'
import {
  useCalculPrescriptionAmounts,
  useGetPrescriptionStatus,
} from '../../../services/api/prescriptions/prescriptions'
import { useEffect } from 'react'
import {
  PrescriptionRecommendedDuration,
  PrescriptionStatus,
  PrescriptionType,
} from '../../../services/api/types'
import { fr } from 'date-fns/locale'
import { returnRecommendedDuration } from '../../../utils/helpers'

function RecommendationCard({ prescription }: IRecommendationCardProps) {
  //Attribute
  const history = useHistory()
  const parsedDate = parseISO(prescription?.createdAt)
  //format to be as mar. 30/10/23
  const formattedDate = format(parsedDate, 'EEE dd/MM/yy', { locale: fr })

  //Query
  const { data: amounts, mutateAsync: calculatePrice } =
    useCalculPrescriptionAmounts()

  const { data: lastStatus } = useGetPrescriptionStatus(
    prescription?.id,
    prescription?.statuses?.[0]?.id
  )

  let mergedLastStatusData = [
    ...(Array.isArray(lastStatus?.takes?.[0]?.items)
      ? lastStatus?.takes?.[0]?.items
      : []),
    ...(Array.isArray(lastStatus?.takes?.[1]?.items)
      ? lastStatus?.takes?.[1]?.items
      : []),
  ]

  //Function
  const redirectRecommandationId = () => {
    return history.push(`/r/${prescription.id}`)
  }

  function displayBadgeAccordingToStatus() {
    const sentStatus = prescription?.statuses?.find(i => i.status === 'SENT')

    if (prescription?.statuses?.[0]?.status === PrescriptionStatus.ORDERED) {
      return (
        <BoughtStatusContainer>
          <BoughtStatus>Acheté</BoughtStatus>
        </BoughtStatusContainer>
      )
    }

    if (sentStatus?.visitedAt) {
      return (
        <SeenStatusContainer>
          <SeenStatus>Consultée</SeenStatus>
        </SeenStatusContainer>
      )
    }

    return (
      <NotSeenStatusContainer>
        <NotSeenStatus>Non Consultée</NotSeenStatus>
      </NotSeenStatusContainer>
    )
  }

  function calculatePriceToDisplay() {
    calculatePrice({
      data: {
        prescriptionId: prescription?.id,
        prescriptionType: prescription?.prescriptionType,
        recommendedDuration: returnRecommendedDuration(
          prescription.recommendedDuration
        ),
        discountId: prescription?.discount?.id,
        products: lastStatus?.takes?.flatMap(take =>
          take.items.map(i => {
            return {
              variantTitle: i.variantTitle,
              quantity: i.quantity,
              productHandle: i.product.handle,
            }
          })
        ),
      },
    })
  }

  useEffect(() => {
    if (!prescription.prescriptionType || !lastStatus) return
    calculatePriceToDisplay()
  }, [prescription, prescription.prescriptionType, lastStatus])

  //Render
  return (
    <Card>
      <TopContainer>
        <Header>
          <div>
            <Title>{`Recommendations #${prescription?.id}`}</Title>
            <UnderTitle>{formattedDate}</UnderTitle>
          </div>
          <div>{displayBadgeAccordingToStatus()}</div>
        </Header>
        <PrescriptorLabel>
          <ImagePrescriptor
            src={'/assets/images/poeple.png'}
            alt='logo-image'
          />
          <PrescriptorLabelTitle>Prescripteur</PrescriptorLabelTitle>
        </PrescriptorLabel>
        <PrescriptorBody>
          <Avatar
            image={undefined}
            firstName={prescription?.prescriberFirstName}
            lastName={prescription?.prescriberLastName}
            hasBackgroundColor={true}
            isSquare={true}
          />
          <div>
            <PrescriptorTitle>{`${prescription.prescriberFirstName} ${prescription.prescriberLastName}`}</PrescriptorTitle>
            <PrescriptorEmail>{prescription?.prescriberEmail}</PrescriptorEmail>
          </div>
        </PrescriptorBody>
        <ProductLabel>
          <ImageProduct src={'/assets/images/product.png'} alt='logo-image' />
          <PrescriptorLabelTitle>Produits:</PrescriptorLabelTitle>
        </ProductLabel>
        <ProductDiv>
          {mergedLastStatusData
            ?.map(p => (
              <Product key={p?.product?.name}>{p?.product?.name}</Product>
            ))
            ?.slice(0, 2)}
          {mergedLastStatusData?.length > 2 && (
            <Product>+{mergedLastStatusData?.length - 2} autres</Product>
          )}
        </ProductDiv>
      </TopContainer>
      <Separator />
      <BottomContainer>
        <PriceContainer>
          <PriceText>PRIX</PriceText>
          {amounts && <PriceText>{amounts?.total}€</PriceText>}
        </PriceContainer>
        <PromoContainer>
          <PriceText>Code promo</PriceText>
          {amounts && (
            <PromoText>
              {(amounts?.total - amounts?.subTotal).toFixed(2)}€
            </PromoText>
          )}
        </PromoContainer>
        <Button
          appearance={undefined}
          iconLeft={undefined}
          isActive={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          style={{ width: '100%' }}
          onClick={() => redirectRecommandationId()}
        >
          Commander
        </Button>
      </BottomContainer>
    </Card>
  )
}

export default RecommendationCard
