import styled from 'styled-components/macro'

export const ParagraphWeak = styled.div`
  margin-left: 12px;
  color: #f46363;
  font-size: 10px;
  font-weight: 500;
`

export const ParagraphMedium = styled.div`
  margin-left: 12px;
  color: #ff9138;
  font-size: 10px;
  font-weight: 500;
`

export const ParagraphStrong = styled.div`
  margin-left: 12px;
  color: #2ed477;
  font-size: 10px;
  font-weight: 500;
`

export const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`
