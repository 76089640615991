import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 20px;
  border-top: 1px solid #e9eaf0;

  & > *:not(:last-child) {
    margin-bottom: 21px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 3px 9px;
  border-radius: 4px;

  background-color: ${({ color }) => color};
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #080038;
`

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 7px;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const Question = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #8f95b2;
`

export const Response = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #080038;
`
