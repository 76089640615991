import { Container } from './style'
import Step1 from '../step-1/Step1'

const ModalEl = ({ open, setOpen }) => {
  //Render
  return (
    <Container style={{ display: open ? 'block' : 'none' }}>
      <Step1 setOpen={setOpen} />
    </Container>
  )
}

export default ModalEl
