import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: relative;
  margin-top: 20px;
  background-color: yellow;
  width: 100%;
  height: 600px;
  overflow: auto;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 15px;
`

export const Header = styled.div`
  position: sticky;
  padding: 0px 30px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 80px;
  z-index: 5;
  h3 {
    font-size: 16px;
  }
  a {
    text-decoration: none;
    color: inherit;
    font-size: 12px;
    opacity: 0.7;
  }

  p {
    display: flex;
    font-size: 12px;
    opacity: 0.7;
  }

  span {
    font-size: 13px;
    display: block;
    margin-top: 1px;
    margin-right: 4px;
  }
`

export const List = styled.div`
  padding: 0px 30px 25px 30px;
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const Right = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Date = styled.div`
  font-size: 12px;
  opacity: 0.5;
`

export const TextWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  h3 {
    margin-bottom: 3px;
    font-size: 14px;
  }
  p {
    font-size: 12px;
    opacity: 0.5;
  }

  span {
    font-size: 10px;
    margin-left: 8px;
    color: #3a835f;
  }
`

export const Button = styled.a`
  margin-left: 15px;
  cursor: pointer;
  border-radius: 8px;
  background: #65b293;
  padding: 8px;
  font-size: 11px;
  color: white;
  text-decoration: none;
  background-color: ${props => (props.isSelected ? '#65b293;' : '#c8c8c8')};
`

export const PlanningButton = styled.button`
  // Add your base styles for the button
  padding: 8px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  font-size: 13px;
  transition: background-color 0.3s, color 0.3s;

  // Conditional styles based on the prop 'isSelected'
  background-color: ${props => (props.isSelected ? '#65b293;' : '#c8c8c8')};
  color: white;

  // Disabled the hover effects for unselected state
  &:hover {
    background-color: ${props => (props.isSelected ? '#65b293;' : '#c8c8c8')};
  }

  // You may want to disable the button if no items are selected
  &:disabled {
    cursor: not-allowed;
  }
`
