import { Modal, Description, Button } from './style'
import { DeletePrescriptionModalProps } from './props'
import { Dialog } from '../../.../../../../ui'

const DeletePrescriptionModal = ({
  setIsOpen,
  isOpen,
  onClick,
}: DeletePrescriptionModalProps) => {
  return (
    <Dialog type='default' isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal>
        <Description>
          En supprimant cette recommandation, votre client ne pourra plus y
          accéder
        </Description>
        <Button onClick={onClick}>Supprimer</Button>
      </Modal>
    </Dialog>
  )
}

export default DeletePrescriptionModal
