import { useEffect, useState } from 'react'
import {
  Title,
  FlexLeftColumn,
  CheckboxList,
  Checkbox,
  CheckboxLabel,
  TextButton,
} from './style'
import { useQueryClient } from 'react-query'
import toast from 'react-hot-toast'

import { useGetAllBrands } from '../../../../services/api/brands/brands'
import {
  useCreateConsent,
  useDeleteConsentByConsentId,
  useGetConsentsByPrescriberId,
} from '../../../../services/api/consents/consents'

import { Spinner } from '../../../ui'
import { useLocation } from 'react-router'
import { Description } from '../../../reco/create-reco-dialog/style'
import { BrandsFormProps } from './props'
import { useGetMe } from '../../../../utils/hooks/getMe'

const BrandsForm = ({ title, description }: BrandsFormProps) => {
  // Attributes
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const isOnboardingLocation = location.pathname === '/onboarding/profile'
  const queryClient = useQueryClient()
  const { data: prescriber } = useGetMe()

  const { data: brands, isLoading: isBrandsLoading } = useGetAllBrands()
  const { data: consents } = useGetConsentsByPrescriberId(prescriber?.id, {
    query: { enabled: !!prescriber?.id },
  })

  function refetConsents() {
    queryClient.invalidateQueries([`/prescribers/${prescriber?.id}/consents`])
  }

  const { mutateAsync: createConsent } = useCreateConsent({
    mutation: {
      onSettled: () => {
        refetConsents()
      },
    },
  })
  const { mutateAsync: deleteConsentByConsentId } = useDeleteConsentByConsentId(
    {
      mutation: {
        onSettled: () => {
          refetConsents()
        },
      },
    }
  )

  // Functions
  const onChangeBrandCheckout = (checkoutValue, brand, checked) => {
    const value = JSON.parse(checkoutValue)
    if (!checked)
      toast.promise(
        createConsent({
          prescriberId: prescriber?.id,
          data: {
            brandId: value?.id,
          },
        }),
        {
          loading: 'en cours...',
          success: `${brand.name} a bien été ajouté`,
          error: "Une erreur s'est produite ",
        },
        {
          duration: 3000,
        }
      )
    if (checked)
      toast.promise(
        deleteConsentByConsentId({
          prescriberId: prescriber?.id,
          consentId: consents?.find(consent => consent.brand.id === brand.id)
            .id,
        }),
        {
          loading: 'en cours...',
          success: `${brand.name} a bien été supprimé`,
          error: "Une erreur s'est produite ",
        },
        {
          duration: 3000,
        }
      )
  }

  const selectAllBrands = async e => {
    e.preventDefault()
    setIsLoading(true)
    if (!brands) {
      console.error('brands is undefined')
      return
    }
    const promises = brands?.map(brand => {
      if (!brand || !brand.id) {
        console.error('brand or brand.id is undefined')
        return
      }
      return createConsent({
        prescriberId: prescriber.id,
        data: {
          brandId: brand.id,
        },
      }).catch(error => console.error('Error with creating consent: ', error))
    })
    await Promise.all(promises)
    setIsLoading(false)
  }

  const deSelectAllBrands = async e => {
    e.preventDefault()
    setIsLoading(true)
    if (!brands) {
      console.error('brands is undefined')
      return
    }
    const promises = brands?.map(brand => {
      if (!brand || !brand.id) {
        console.error('brand or brand.id is undefined')
        return
      }
      return deleteConsentByConsentId({
        prescriberId: prescriber.id,
        consentId: consents?.find(consent => consent.brand.id === brand.id)?.id,
      }).catch(error => console.error('Error with creating consent: ', error))
    })
    await Promise.all(promises)
    setIsLoading(false)
  }

  useEffect(() => {
    const processBrands = async brands => {
      const promises = brands?.map(brand => {
        if (!brand || !brand.id) {
          console.error('brand or brand.id is undefined')
          return
        }
        return createConsent({
          prescriberId: prescriber.id,
          data: {
            brandId: brand.id,
          },
        }).catch(error => console.error('Error with creating consent: ', error))
      })
      await Promise.all(promises)
    }
    if (brands && isOnboardingLocation) processBrands(brands)
  }, [brands])

  // Render
  return isBrandsLoading ? (
    <Spinner fullScreen={false} />
  ) : (
    <FlexLeftColumn>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <TextButton
        onClick={e => {
          selectAllBrands(e)
        }}
      >
        Tous sélectionner
      </TextButton>
      <TextButton
        onClick={e => {
          deSelectAllBrands(e)
        }}
      >
        Tous désélectionner
      </TextButton>

      {isLoading ? (
        <Spinner fullScreen={false} />
      ) : (
        <CheckboxList>
          {brands
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((brand, index) => {
              const checked = !!consents?.find(
                consent => consent.brand.id === brand.id
              )
              return (
                <CheckboxLabel for={brand.name} key={brand.id}>
                  <Checkbox
                    onChange={e =>
                      onChangeBrandCheckout(e.target.value, brand, checked)
                    }
                    value={JSON.stringify(brand)}
                    name={brand.name}
                    type='checkbox'
                    checked={checked}
                  />
                  {brand.name}
                </CheckboxLabel>
              )
            })}
        </CheckboxList>
      )}
    </FlexLeftColumn>
  )
}

export default BrandsForm
