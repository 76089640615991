import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const GlobalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  @media (min-width: 1101px) {
    height: calc(100vh - 4.6875rem);
    background-color: var(--BG, #fdfcfe);
  }
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 4.6875rem;
  min-height: 4.6875rem;
  position: sticky;
  top: 0;
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  background-color: white;
  z-index: 10;
  box-shadow: 0 0.125rem 0.125rem -0.125rem rgba(0, 0, 0, 0.2);
  ${MediaQuery.M_AND_DOWN} {
    height: 3.75rem;
    min-height: 3.75rem;
    padding: 0.625rem 1.25rem 0.3125rem 1.25rem;
  }

  ${MediaQuery.L_AND_DOWN} {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
  }

  a {
    text-decoration: none;
    color: black;
  }
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const NavItem = styled.div`
  font-size: 0.8125rem;
  margin-right: 2.5rem;
  padding-bottom: 0.1875rem;
  ${MediaQuery.M_AND_DOWN} {
    margin-right: 1.25rem;
  }
`

export const CartWrapper = styled.div`
  margin-right: 2.5rem;
  ${MediaQuery.M_AND_DOWN} {
    margin-right: 1.25rem;
  }
`

export const MobileNavWrapper = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
  display: none;
  ${MediaQuery.M_AND_DOWN} {
    display: flex;
  }
`
