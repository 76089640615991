import styled from 'styled-components/macro'
import { Icon } from '../../../../components/ui'

export const BreadCrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  margin: 50px 0;

  > a,
  > span {
    color: inherit;
    text-decoration: none;
    font-size: 14px;
  }

  > a {
    font-weight: 900;
  }

  > ${Icon} {
    margin: 0 10px;
    width: 10px;
    height: 10px;
  }
`

export const RecommandationPageHeader = styled.div`
  display: flex;
  width: 100%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 60%;
  margin: 50px 0;
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  a {
    font-size: 12px;
    padding: 2px 10px;
  }
`
