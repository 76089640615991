import styled from 'styled-components/macro'

export const Container = styled.div``

export const Title = styled.h4`
  font-size: 18px;
  margin-left: 20px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 14px;
    margin-top: 0.7em;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`

export const ButtonProtocolsWrapper = styled.div`
  display: flex;
  margin: 20px 0 0px 0;
`

export const ProductSearchBarHeader = styled.p`
  font-size: 14px;
  margin-top: 0.7em;
`

export const StickyContainer = styled.div`
  display: flex;
  position: sticky;
  top: 85px;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  z-index: 1;
  border-radius: 14px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  width: 100%;
  margin-top: 20px;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Nav = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px 24px 0px 0px;
  gap: 16px;
  width: 100%;
`

export const NavItem = styled.div`
  cursor: pointer;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: ${props => (props.active ? '#080038' : '#8f95b2')};
  border-bottom: ${props => (props.active ? '1px solid #3A845E;' : '')};
  padding-bottom: 8px;
`
export const FavoritesListByPackagingTypeWrapper = styled.div`
  margin-top: 20px;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
