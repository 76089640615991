import styled from 'styled-components/macro'

const Container = styled.span`
  max-width: 120px;
  font-size: 10px;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 4px;
  letter-spacing: 1px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
`

export const Success = styled(Container)`
  color: #0c4329;
  background-color: #8ec4ab;
  border-color: #badbcc;
`

export const Danger = styled(Container)`
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
`

export const Info = styled(Container)`
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
`

export const Warning = styled(Container)`
  color: ${props => (props.isPrescriptionConsulted ? '#0f5132' : ' #664d03')};
  background-color: ${props =>
    props.isPrescriptionConsulted ? '#d1e7dd' : '#fff3cd'};
`

export const Done = styled(Container)`
  color: #ff7021;
  background-color: rgb(255, 141, 78, 0.4);
  border-color: #ffecb5;
`

export const CheckIcon = styled.span`
  font-size: 12px;
  margin-left: 5px;
  margin-top: 3px;
`
export const Text = styled.span``
