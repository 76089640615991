/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PurchaseOption = 'SUBSCRIPTION' | 'ONE_TIME' | 'BOTH'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PurchaseOption = {
  SUBSCRIPTION: 'SUBSCRIPTION' as PurchaseOption,
  ONE_TIME: 'ONE_TIME' as PurchaseOption,
  BOTH: 'BOTH' as PurchaseOption,
}
