import { useEffect, useMemo, useState } from 'react'

import {
  Container,
  PlaceholderContainer,
  ProtocolsListContainer,
  SearchBarContainer,
  SearchBarInput,
  SearchBarLeftContainer,
  SearchBarVerticalLine,
  TabContainer,
  TabItem,
} from './style'
import ProtocolCard from '../card'
import { useGetMe } from '../../../utils/hooks/getMe'

import {
  getGetProtocolsQueryKey,
  getProtocols,
  useGetProtocols,
} from '../../../services/api/protocols/protocols'
import { GetProtocolsType, Role } from '../../../services/api/types'
import { Spinner } from '../../ui'
import ProtocolsPlaceholder from '../placeholder'
import { useInfiniteQuery } from 'react-query'
import { useInView } from 'react-intersection-observer'

const PAGE_SIZE = 20

export const Tab = {
  TEMPLATE_CUSTOM: 'template-custom',
  TEMPLATE_COMPLIMENT: 'template-compliment',
  TEMPLATE_COMMUNITY: 'template-community',
}

export const SearchBarLoupe = () => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='34' height='34' rx='17' fill='#D7603A' fill-opacity='0.08' />
      <path
        d='M16.2273 22.4095C19.6414 22.4095 22.4091 19.6418 22.4091 16.2277C22.4091 12.8136 19.6414 10.0459 16.2273 10.0459C12.8132 10.0459 10.0455 12.8136 10.0455 16.2277C10.0455 19.6418 12.8132 22.4095 16.2273 22.4095Z'
        stroke='#D7603A'
        stroke-width='1.54545'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M23.9549 23.9551L20.5935 20.5938'
        stroke='#D7603A'
        stroke-width='1.54545'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

const ProtocolsContainer = () => {
  // Attributes
  const [search, setSearch] = useState('')
  const [activeTab, setActiveTab] = useState(Tab.TEMPLATE_COMPLIMENT)
  const { data: prescriber } = useGetMe()

  const { ref, entry } = useInView()

  const {
    data: protocolsData,
    refetch: refetchProtocols,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery(
    getGetProtocolsQueryKey(prescriber?.id, {
      q: search,
      size: PAGE_SIZE,
      protocolsType:
        activeTab === Tab.TEMPLATE_COMPLIMENT
          ? GetProtocolsType.COMPLIMENT
          : GetProtocolsType.SELF,
    }),
    async params => {
      return await getProtocols(prescriber?.id, {
        q: search,
        page: params?.pageParam,
        size: PAGE_SIZE,
        protocolsType:
          activeTab === Tab.TEMPLATE_COMPLIMENT
            ? GetProtocolsType.COMPLIMENT
            : GetProtocolsType.SELF,
      })
    },
    {
      getNextPageParam: page => {
        if (page?.page + 1 !== page?.nbPages) {
          return page?.page + 1
        } else {
          return undefined
        }
      },
    }
  )

  const protocols = useMemo(
    () => protocolsData?.pages?.flatMap(page => page.data),
    [protocolsData]
  )

  useEffect(() => {
    // Scroll Observer
    if (entry?.isIntersecting && !isFetchingNextPage && hasNextPage) {
      // Fetch the new page
      fetchNextPage()
    }
  }, [entry])

  // Render
  return (
    <Container>
      <SearchBarContainer>
        <SearchBarLeftContainer>
          <SearchBarLoupe />
          <SearchBarVerticalLine />
          <SearchBarInput
            value={search}
            onChange={e => setSearch(e?.target?.value)}
            placeholder='Recherchez un protocole'
          />
        </SearchBarLeftContainer>
      </SearchBarContainer>
      {/* Header */}
      <TabContainer>
        <TabItem
          isActive={activeTab === Tab.TEMPLATE_COMPLIMENT}
          onClick={() => setActiveTab(Tab.TEMPLATE_COMPLIMENT)}
        >
          Simplycure
        </TabItem>
        <TabItem
          isActive={activeTab === Tab.TEMPLATE_CUSTOM}
          onClick={() => setActiveTab(Tab.TEMPLATE_CUSTOM)}
        >
          Mes protocoles
        </TabItem>
      </TabContainer>

      {/* Protocols list */}
      <ProtocolsListContainer>
        {isLoading ? (
          <Spinner fullScreen={undefined} />
        ) : !protocols?.length ? (
          <PlaceholderContainer>
            <ProtocolsPlaceholder />
          </PlaceholderContainer>
        ) : (
          protocols?.map(protocol => (
            <ProtocolCard
              protocol={protocol}
              key={protocol.id}
              refetchProtocols={refetchProtocols}
            />
          ))
        )}
      </ProtocolsListContainer>
      <div style={{ padding: '.25rem' }} ref={ref} />
      {isFetchingNextPage && <Spinner fullScreen={undefined} />}
    </Container>
  )
}

export default ProtocolsContainer
