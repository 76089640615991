import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 1.25rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--Stroke, #e9eaf0);
  background: var(--Surfaces-Fill-surface-fill-white, #fff);
  position: fixed;
  top: 0px;
`
export const ImageLogo = styled.img`
  margin-top: 0.1875rem;
  width: 6.3125rem;
  height: 1.25rem;
  object-fit: contain;
  object-position: center center;
`
