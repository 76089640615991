import { useState, useEffect } from 'react'
import { Dialog, Button } from '../ui'
import {
  CloseButtonContainer,
  CloseButton,
  Container,
  ButtonWrapper,
  DownloadIcon,
} from './style'
import { IconCross } from '../ui/icon/icons'
import { Pdf } from '../order-form-pdf/booklet'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import FavoritesSelection from './favorites-selection'
import DiscountSlider from './discount-slider'
import {
  getAllProductsFromPrescriberFavoriteListItemsByListId,
  useGetAllPrescriberFavoriteListsByPrescriberId,
} from '../../services/api/favorites/favorites'
import { useGetDiscountsByPrescriberId } from '../../services/api/prescriber/prescriber'
import QRCode from 'qrcode'
import toast from 'react-hot-toast'
import NoFavorites from './favorites-selection/no-favorites'
import { MdDownload } from 'react-icons/md'
import { useGetMe } from '../../utils/hooks/getMe'

const OrderFormDialog = ({
  isOpen,
  setIsOpen,
  setIsCreateCategoryDialogOpen,
}) => {
  const { data: prescriber } = useGetMe()

  const { data: favoriteLists } =
    useGetAllPrescriberFavoriteListsByPrescriberId(prescriber?.id, null, {
      query: {
        enabled: !!prescriber?.id,
      },
    })

  const discountList = useGetDiscountsByPrescriberId(
    prescriber?.id,
    {},
    {
      query: { enabled: !!prescriber?.id },
    }
  )
  const [selectedFavorites, setSelectedFavorites] = useState([])
  const [selectValue, setSelectValue] = useState(10)
  const [step, setStep] = useState(1)

  const selectedDiscount = discountList?.data?.data?.find(
    i => i?.percentage === selectValue
  )
  const percentageArray = discountList?.data?.data?.map(i => i?.percentage)

  const [data, setData] = useState([])

  let totalProductsCount = 0
  data.forEach(favoriteData => {
    totalProductsCount += favoriteData.products.length
  })

  const generateQRCode = async () => {
    try {
      const generatedDataUrl = await QRCode.toDataURL(
        `http://app.simplycure.com/patient-catalog?discountCode=${selectedDiscount?.code}`,
        {
          width: 144,
          color: {
            dark: '#000',
            light: '#FFF',
          },
          errorCorrectionLevel: 'H',
        }
      )

      return generatedDataUrl
    } catch (err) {
      console.error(err)
    }
  }

  const saveBookletPdf = async () => {
    let qrCodeUrl = ''

    qrCodeUrl = await generateQRCode()

    const blob = await pdf(
      <Pdf
        favorites={data}
        percentage={selectValue}
        discountCode={selectedDiscount?.code}
        qrCodeUrl={qrCodeUrl}
        prescriberFullName={`${prescriber?.firstName} ${prescriber?.lastName}`}
      />
    ).toBlob()

    saveAs(
      blob,
      `Bon_de_commande_Simplycure_${prescriber?.firstName}${prescriber?.lastName}${selectValue}`
    )
  }

  const handleNextStep = () => {
    setStep(step + 1)
  }

  const handlePreviousStep = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      const newData = await Promise.all(
        selectedFavorites?.map(async favorite => {
          const products =
            await getAllProductsFromPrescriberFavoriteListItemsByListId(
              favorite?.id,
              {
                packagingType: 'BOTH',
              }
            )

          // Sort products by brand first and then alphabetically by name within each brand
          const sortProducts = products?.sort((a, b) => {
            // First, compare by brand name
            const brandComparison = a.brand.name.localeCompare(b.brand.name)
            // If brands are the same, then compare by name
            return brandComparison === 0
              ? a.name.localeCompare(b.name)
              : brandComparison
          })

          return { favoriteName: favorite?.name, products: sortProducts }
        })
      )
      setData(newData)
    }

    fetchData()
  }, [selectedFavorites])

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      type='defaultCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={() => setIsOpen(false)}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        {!favoriteLists?.length ? (
          <NoFavorites
            onClose={setIsOpen}
            setIsCreateCategoryDialogOpen={setIsCreateCategoryDialogOpen}
          />
        ) : (
          <>
            {/* {step === 1 && ( */}
            <div
              style={{
                visibility: step === 1 ? 'visible' : 'hidden',
                maxHeight: step === 1 ? '' : '0px',
                display: step === 1 ? 'block' : 'none',
              }}
            >
              <FavoritesSelection
                selectedFavorites={selectedFavorites}
                setSelectedFavorites={setSelectedFavorites}
                onNextStep={handleNextStep}
                totalProductsCount={totalProductsCount}
              />
            </div>
            {/* )} */}
            {/* {step === 2 && ( */}
            <div
              style={{
                visibility: step === 2 ? 'visible' : 'hidden',
                maxHeight: step === 2 ? '' : '0px',
                display: step === 2 ? 'block' : 'none',
              }}
            >
              <DiscountSlider
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                percentageArray={percentageArray}
                discountCode={selectedDiscount?.code}
              />
            </div>
            {/* )} */}

            {step === 2 && (
              <ButtonWrapper>
                <Button
                  appearance='primary'
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                  onClick={handlePreviousStep}
                  style={{
                    height: '32px',
                    fontSize: '12px',
                    padding: '10px 15px',
                  }}
                >
                  Précédent
                </Button>
                <Button
                  appearance='primary'
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                  onClick={() => {
                    toast
                      .promise(
                        saveBookletPdf(),
                        {
                          loading: 'en cours...',
                          success: `Le bon de commande a bien été téléchargé`,
                          error: "Une erreur s'est produite ",
                        },
                        {
                          duration: 3000,
                        }
                      )
                      .then(() => {
                        setIsOpen(false)
                        setStep(1)
                        setSelectedFavorites([])
                      })
                  }}
                  style={{
                    height: '32px',
                    fontSize: '12px',
                    padding: '10px 15px',
                  }}
                >
                  <DownloadIcon>
                    <MdDownload />
                  </DownloadIcon>
                  Téléchager mon bon de commande
                </Button>
              </ButtonWrapper>
            )}
          </>
        )}
      </Container>
    </Dialog>
  )
}

export default OrderFormDialog
