import { InfoBig } from '../../../ui/icon/icons'
import { SummaryBoxProps } from './props'
import {
  BottomContainer,
  Container,
  IconContainer,
  Price,
  Title,
  Tooltip,
  TooltipContainer,
  TooltipContent,
  TopContainer,
} from './style'
import { useState } from 'react'

const SummaryBox = ({
  icon,
  button,
  hasCustomBg = false,
  tooltipContent,
  title,
  price,
}: SummaryBoxProps) => {
  // Attributes
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)

  // Render
  return (
    <Container hasCustomBg={hasCustomBg}>
      <TopContainer>
        {icon && (
          <IconContainer>{icon('1.5rem', '1.5rem', '#65B293')}</IconContainer>
        )}
        {button && <>{button()}</>}
        <TooltipContainer>
          <InfoBig
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
            width='1.75rem'
            height='1.75rem'
            stroke={hasCustomBg ? 'white' : '#080038'}
          />

          <Tooltip show={isTooltipOpen} hasCustomBg={hasCustomBg}>
            <TooltipContent hasCustomBg={hasCustomBg}>
              {tooltipContent}
            </TooltipContent>
          </Tooltip>
        </TooltipContainer>
      </TopContainer>
      <BottomContainer>
        <Title hasCustomBg={hasCustomBg}>{title}</Title>
        <Price hasCustomBg={hasCustomBg}>{price}</Price>
      </BottomContainer>
    </Container>
  )
}

export default SummaryBox
