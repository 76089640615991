import styled from 'styled-components/macro'

export const Container = styled.div``

export const ProgressBarPoint = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #03865c; // Choose a color that stands out
  border: 2px solid #ffffff; // Optional, adds a border for better visibility

  &:before {
    content: '';
    position: absolute;
    bottom: -6px; // Adjust as needed
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #03865c; // Same as the background color of the point
  }

  &:after {
    content: attr(data-label); // Use a data attribute for the label
    position: absolute;
    bottom: -25px; // Adjust based on the size of your arrow and desired spacing
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px; // Adjust as needed
    color: #000; // Choose a color that is visible on your progress bar
    white-space: nowrap;
  }
`
