import styled from 'styled-components/macro'

export const FavIcon = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  height: 2rem;
  width: 2rem;
  position: absolute;
  top: 0.3125rem;
  right: 0.625rem;
  border-radius: 6.25rem;
  background: #ffffff;
  border: 0.0625rem solid #e7e9f4;
  color: #f9b50b;
`

export const Dropdown = styled.div`
  position: relative;
`

export const Drawer = styled.div`
  display: flex;
  position: absolute;
  min-width: 100%;
  width: auto;
  width: max-content;
  height: auto;

  right: 0;
  transition: all 0.2s ease;
  transform-origin: top;
  background-color: white;
  border-radius: 0.625rem;
  top: 0.625rem;
  box-shadow: 0 0.9375rem 2.8125rem 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  ${({ isOpen }) => {
    if (isOpen) {
      return `
        visibility: visible;
        transform: scale(1);
        opacity: 1;
      `
    }
    return `
      visibility: hidden;
      transform: scale(0.8);
      opacity: 0;
    `
  }}

  &::after {
    content: '';
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 0.625rem;
    top: -0.625rem;
    left: 0;
  }
`
