/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type GetPrescriptionStatus =
  | 'ALL'
  | 'ORDERED'
  | 'TO_RELAUNCH'
  | 'ARCHIVED'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPrescriptionStatus = {
  ALL: 'ALL' as GetPrescriptionStatus,
  ORDERED: 'ORDERED' as GetPrescriptionStatus,
  TO_RELAUNCH: 'TO_RELAUNCH' as GetPrescriptionStatus,
  ARCHIVED: 'ARCHIVED' as GetPrescriptionStatus,
}
