import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const BackIcon = styled.div`
  cursor: pointer;
  width: auto;
  width: 100px;
  height: 37px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.DEEP_SEA};
  background-color: #d8e6df;
  p {
    font-size: 12px;
    margin-left: 5px;
  }
`
