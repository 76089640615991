import { Helmet } from 'react-helmet'

import { Container, Content, Aside, Title } from './style'
import Layout from '../../../components/layout/default'
import { useOnboardingProfileCheck } from '../../../utils/hooks'
import SettingAsideNav from '../../../components/settings/aside-nav'
import AddressFrom from '../../../components/onboarding/address-step/address-form'
import ProfileView from '../../../components/prescriber/profile-view'

const AddressPage = () => {
  useOnboardingProfileCheck()

  return (
    <Layout isPublic={false} active={false}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Paramètres adresse </title>
      </Helmet>
      <Container>
        <Aside>
          <SettingAsideNav />
        </Aside>
        <Content>
          <Title>Profil</Title>
          <ProfileView />
          <Title>Adresse</Title>
          <AddressFrom />
        </Content>
      </Container>
    </Layout>
  )
}

export default AddressPage
