import styled from 'styled-components/macro'
import imgFullPlant from '../../../assets/images/background-full-plant.webp'
import imgPlant from '../../../assets/images/background-plant.png'

export const GlobalContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(${props =>
    props.isFullBackground ? imgFullPlant : imgPlant});
  background-size: cover;
  min-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`
