import styled from 'styled-components/macro'
import { Color } from '../../../../../utils/constants'

export const Container = styled.td`
  font-size: 10px;
`
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const DropdownContent = styled.div`
  padding: 10px 20px;
  a {
    color: inherit;
    text-decoration: none;
  }
`

export const DropdownItem = styled.div`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
`

export const DropdownIcon = styled.span`
  margin-right: 10px;
  font-size: 15px;
`

export const DropdownText = styled.p`
  font-size: 12px;
`

export const MoreIcon = styled.span`
  font-size: 20px;
  color: ${Color.DEEP_SEA};
`

export const SeeIcon = styled.div`
  font-size: 15px;
  margin-right: 40px;
  color: black;
`
