import { useState } from 'react'
import {
  PatientLink,
  LinkWrapper,
  IconWrapper,
} from '../../../share-access-dialog/style'
import { Button, Tooltip } from '../../../ui'
import { FaRegCopy, FaCheck } from 'react-icons/fa'
import { Container } from './style'
import { LinkSectionProps } from './props'
import SendCta from '../send-cta'

const LinkSection = ({
  prescriptionId,
  lastStatus,
  status,
}: LinkSectionProps) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  // Functions
  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/r/${prescriptionId}`
    )
    setIsLinkCopied(true)
    setTimeout(() => setIsLinkCopied(false), 3000)
  }

  const prescriptionRecoLink = `${window.location.origin}/r/${prescriptionId}`

  return (
    <Container>
      <LinkWrapper>
        <PatientLink>{prescriptionRecoLink}</PatientLink>
        <Tooltip
          size='l'
          label={isLinkCopied ? `Copié !` : 'Copier dans le presse papier'}
        >
          <Button
            appearance='minimal'
            onClick={handleCopyToClipBoard}
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            <IconWrapper>
              {isLinkCopied ? <FaCheck /> : <FaRegCopy />}
            </IconWrapper>
            copier
          </Button>
        </Tooltip>

        <Button
          onClick={() => {
            window.open(prescriptionRecoLink, '_blank')
          }}
          appearance='minimal'
          iconLeft={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
        >
          Ouvrir
        </Button>
      </LinkWrapper>

      <SendCta
        prescriptionId={prescriptionId}
        lastStatus={lastStatus}
        status={status}
      />
    </Container>
  )
}

export default LinkSection
