import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100%;
  padding: 30px 0;
`

export const Title = styled.h1`
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 20px;
`
