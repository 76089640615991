import {
  SearchContainer,
  Right,
  Input,
  PatientList,
  PatientItem,
  Initial,
  FullName,
  Email,
  InfoPatient,
  InputWrapper,
  Flex,
  PatientItemLeft,
} from '../style'
import { Spinner } from '../../../ui'
import Popup from 'reactjs-popup'
import { SearchProps } from './props'

const Search = ({
  setShowSecondDiv,
  search,
  setSearch,
  showSecondDiv,
  isLoading,
  patients,
  handlePatientSelect,
}: SearchProps) => {
  return (
    <SearchContainer>
      <Right>
        <InputWrapper>
          <Flex>
            <Popup
              trigger={
                <Input
                  onClick={() => setShowSecondDiv(true)}
                  placeholder='Rechercher un client'
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
              }
              position='bottom left'
              open={showSecondDiv}
              contentStyle={{
                padding: '0px',
                border: 'none',
                width: '100%',
              }}
              arrow={false}
            >
              <PatientList>
                {isLoading ? (
                  <Spinner fullScreen={false} />
                ) : (
                  patients?.map(p => {
                    return (
                      <PatientItem
                        key={p?.id}
                        onClick={() => {
                          handlePatientSelect(p)
                        }}
                      >
                        <PatientItemLeft>
                          <Initial>
                            {p?.firstName && p?.lastName
                              ? `${p?.firstName?.[0]}${p?.lastName?.[0]}`
                              : p?.email?.[0]}
                          </Initial>
                          <InfoPatient>
                            <FullName>
                              {p?.firstName} {p?.lastName}
                            </FullName>
                            <Email>{p?.phone}</Email>
                            <Email>{p?.email}</Email>
                          </InfoPatient>
                        </PatientItemLeft>
                      </PatientItem>
                    )
                  })
                )}
              </PatientList>
            </Popup>
          </Flex>
        </InputWrapper>
      </Right>
    </SearchContainer>
  )
}

export default Search
