import React from 'react'
import { GlobalContainer, Main } from './style'
import Header from './header'

interface DefaultLayoutProps {
  children: React.ReactNode
  onClick?: () => any
  backLink?: string
}

const DefaultLayout = ({ children, onClick, backLink }: DefaultLayoutProps) => (
  <GlobalContainer>
    <Header onClick={onClick} backLink={backLink ? backLink : '/notes'} />
    <Main>{children}</Main>
  </GlobalContainer>
)

export default DefaultLayout
