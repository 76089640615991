import styled from 'styled-components/macro'

export const Container = styled.div`
  background: #f8f9fc;
  border: 1px solid #e7e9f4;
  border-radius: 12px;

  padding: 20px;
`

export const MainContent = styled.div`
  display: flex;
`

export const Left = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Right = styled.div`
  width: 100%;
`

export const Img = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 5px;
  object-fit: cover;
`

export const IconsWrapper = styled.div`
  display: flex;
  & > * {
    margin-right: 5px;
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
  font-size: 15px;
  width: 26px;
  height: 26px;
  background: ${props => (props.background ? props.background : '')};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

export const DescritpionWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e9eaf0;
  border-radius: 8px;
  padding: 12px;
`
export const DescritpionTextInput = styled.textarea`
  color: #080038;
  font-size: 13px;
  width: 100%;
  min-height: 20px;
  border: none;
  outline: none;
  resize: none;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const HeaderLeft = styled.div``

export const Name = styled.h3`
  margin-bottom: 20px;
`
export const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Price = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`

export const Units = styled.div`
  font-size: 12px;
  color: #8f95b2;
`

export const Title = styled.h5`
  margin: 10px 0 5px 0;
`

export const ArrowDown = styled.span`
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
`

export const BrandImg = styled.img`
  object-fit: contain;
  width: 55px;
  margin-left: 20px;
  margin-top: -18px;
`

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`
