import styled from 'styled-components/macro'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 8px 0;

  > button {
    margin-top: 20px;
  }
`

export const Label = styled.label`
  width: 400px;
  font-size: 12px;
  opacity: 0.7;
  margin-top: 12px;
  display: flex;
  input {
    margin-right: 5px;
  }

  a {
    color: inherit;
  }
`

export const ErrorMessage = styled.p`
  font-size: 12px;
  margin-top: 8px;
`
