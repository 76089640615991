import styled from 'styled-components/macro'

export const Container = styled.div`
  min-width: 49%;

  & > div:not(:last-child) {
    margin-bottom: 24px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #080038;
`

export const TooltipIconContainer = styled.div`
  min-width: 28px;
  height: 28px;
  cursor: pointer;
`

export const ContentContainer = styled.div``
