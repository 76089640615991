import { PricingProps } from './props'
import { Price, BarredPrice, Flex } from './style'
import { PrescriptionRecommendedDuration } from '../../../../services/api/types'

const Pricing = ({
  price,
  quantity,
  subtotalAmount,
  totalAmount,
  cartDuration,
  isStandAlone,
}: PricingProps) => {
  const normalizeCartDuration = duration => {
    if (duration === PrescriptionRecommendedDuration.ONE_MONTH) {
      return ''
    } else if (duration === PrescriptionRecommendedDuration.TWO_MONTH) {
      return 2
    } else if (duration === PrescriptionRecommendedDuration.THREE_MONTH) {
      return 3
    } else {
      return ''
    }
  }

  return (
    <Price>
      {subtotalAmount !== totalAmount ? (
        <Flex>
          <BarredPrice>{subtotalAmount}€</BarredPrice>
          <Price>
            {totalAmount}€
            {!isStandAlone && (
              <small>
                /{normalizeCartDuration(cartDuration)}
                mois
              </small>
            )}
          </Price>
        </Flex>
      ) : (
        <>
          €{price ? price * quantity : price}
          {!isStandAlone && (
            <small>
              /{normalizeCartDuration(cartDuration)}
              mois
            </small>
          )}
        </>
      )}
    </Price>
  )
}

export default Pricing
