import styled from 'styled-components/macro'

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`

export const Title = styled.h4`
  font-size: 18px;
  margin-left: 20px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
