import { QuizCategoryGraphProps } from './props'
import { Graph, GraphLegend } from './style'
import { Radar } from 'react-chartjs-2'

const QuizCategoryGraph = ({
  scores,
  title,
  labels,
  color,
}: QuizCategoryGraphProps) => {
  // Attributes

  scores = scores.filter(function (element) {
    return element !== undefined
  })
  const data = {
    labels: scores?.map(i => i?.title),

    datasets: [
      {
        label: '',
        data: scores?.map(i => i?.value),
        backgroundColor: [`${color}80`],
        borderColor: [`${color}80`],
        borderWidth: 1,
      },
    ],
  }

  // Render
  return (
    <Graph>
      {title && <h4>{title}</h4>}
      {!!labels?.length && (
        <GraphLegend>
          {labels.map((label, i) => (
            <li key={`graph_label_${i}`}>{label}</li>
          ))}
        </GraphLegend>
      )}
      <Radar
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            r: {
              max: 3,
              min: 0,
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
      />
    </Graph>
  )
}

export default QuizCategoryGraph
