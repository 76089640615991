import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95vw;
  padding: 0 1.25rem;
  background-color: #fdfcfe;
`

export const Content = styled.div`
  width: 100%;
  margin-left: 2.5rem;
`
