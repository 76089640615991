/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type {
  DiscountPagingDTO,
  GetDiscountsByPrescriberIdParams,
  PrescriberRewardPagingDTO,
  GetPrescriberRewardsByPrescriberIdParams,
  PrescriberRewardDTO,
  UpdatePrescriberRewardDTO,
  DiscountListDTO,
  PrescriberDTO,
  UpdatePrescriberDTO,
  UpdateOnboardingDTO,
  PrescriberOrderPagingDTO,
  GetOrdersByPrescriberIdParams,
  PrescriberOrdersSummaryDTO,
  WaitingPrescriptionPagingDTO,
  GetWaitingPrescriptionsByPrescriberIdParams,
  PrescriberProfessionPagingDTO,
  GetPrescriberProfessionsParams,
  PrescriberFileResponseDTO,
  FileDTO,
  PrescriberEarningsPagingDTO,
  GetPrescriberEaringsByPrescriberIdParams,
  PrescriberAddressDTO,
  CreatePrescriberAddressDTO,
  UpdatePrescriberAddressDTO,
} from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getDiscountsByPrescriberId = (
  prescriberId: number,
  params?: GetDiscountsByPrescriberIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<DiscountPagingDTO>(
    { url: `/prescribers/${prescriberId}/discounts`, method: 'get', params },
    options
  )
}

export const getGetDiscountsByPrescriberIdQueryKey = (
  prescriberId: number,
  params?: GetDiscountsByPrescriberIdParams
) => [`/prescribers/${prescriberId}/discounts`, ...(params ? [params] : [])]

export const useGetDiscountsByPrescriberId = <
  TData = AsyncReturnType<typeof getDiscountsByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetDiscountsByPrescriberIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDiscountsByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetDiscountsByPrescriberIdQueryKey(prescriberId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDiscountsByPrescriberId>
  > = () => getDiscountsByPrescriberId(prescriberId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getDiscountsByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getPrescriberRewardsByPrescriberId = (
  prescriberId: number,
  params?: GetPrescriberRewardsByPrescriberIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberRewardPagingDTO>(
    { url: `/prescribers/${prescriberId}/rewards`, method: 'get', params },
    options
  )
}

export const getGetPrescriberRewardsByPrescriberIdQueryKey = (
  prescriberId: number,
  params?: GetPrescriberRewardsByPrescriberIdParams
) => [`/prescribers/${prescriberId}/rewards`, ...(params ? [params] : [])]

export const useGetPrescriberRewardsByPrescriberId = <
  TData = AsyncReturnType<typeof getPrescriberRewardsByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetPrescriberRewardsByPrescriberIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriberRewardsByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrescriberRewardsByPrescriberIdQueryKey(prescriberId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPrescriberRewardsByPrescriberId>
  > = () =>
    getPrescriberRewardsByPrescriberId(prescriberId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriberRewardsByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const updatePrescriberRewardByPrescriberIdAndRewardId = (
  prescriberId: number,
  rewardId: string,
  updatePrescriberRewardDTO: UpdatePrescriberRewardDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberRewardDTO>(
    {
      url: `/prescribers/${prescriberId}/rewards/${rewardId}`,
      method: 'put',
      data: updatePrescriberRewardDTO,
    },
    options
  )
}

export const useUpdatePrescriberRewardByPrescriberIdAndRewardId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePrescriberRewardByPrescriberIdAndRewardId>,
    TError,
    { prescriberId: number; rewardId: string; data: UpdatePrescriberRewardDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePrescriberRewardByPrescriberIdAndRewardId>,
    { prescriberId: number; rewardId: string; data: UpdatePrescriberRewardDTO }
  > = props => {
    const { prescriberId, rewardId, data } = props || {}

    return updatePrescriberRewardByPrescriberIdAndRewardId(
      prescriberId,
      rewardId,
      data,
      requestOptions
    )
  }

  return useMutation<
    AsyncReturnType<typeof updatePrescriberRewardByPrescriberIdAndRewardId>,
    TError,
    { prescriberId: number; rewardId: string; data: UpdatePrescriberRewardDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const createDefaultDiscountsByPrescriberId = (
  prescriberId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<DiscountListDTO>(
    { url: `/prescribers/${prescriberId}/default-discounts`, method: 'post' },
    options
  )
}

export const useCreateDefaultDiscountsByPrescriberId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createDefaultDiscountsByPrescriberId>,
    TError,
    { prescriberId: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createDefaultDiscountsByPrescriberId>,
    { prescriberId: number }
  > = props => {
    const { prescriberId } = props || {}

    return createDefaultDiscountsByPrescriberId(prescriberId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createDefaultDiscountsByPrescriberId>,
    TError,
    { prescriberId: number },
    TContext
  >(mutationFn, mutationOptions)
}
export const updatePrescriber = (
  prescriberId: number,
  updatePrescriberDTO: UpdatePrescriberDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberDTO>(
    {
      url: `/prescribers/${prescriberId}`,
      method: 'put',
      data: updatePrescriberDTO,
    },
    options
  )
}

export const useUpdatePrescriber = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePrescriber>,
    TError,
    { prescriberId: number; data: UpdatePrescriberDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePrescriber>,
    { prescriberId: number; data: UpdatePrescriberDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return updatePrescriber(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof updatePrescriber>,
    TError,
    { prescriberId: number; data: UpdatePrescriberDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const updatePrescriberOnboarding = (
  prescriberId: number,
  updateOnboardingDTO: UpdateOnboardingDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberDTO>(
    {
      url: `/prescribers/${prescriberId}/onboarding`,
      method: 'put',
      data: updateOnboardingDTO,
    },
    options
  )
}

export const useUpdatePrescriberOnboarding = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePrescriberOnboarding>,
    TError,
    { prescriberId: number; data: UpdateOnboardingDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePrescriberOnboarding>,
    { prescriberId: number; data: UpdateOnboardingDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return updatePrescriberOnboarding(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof updatePrescriberOnboarding>,
    TError,
    { prescriberId: number; data: UpdateOnboardingDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const getOrdersByPrescriberId = (
  prescriberId: number,
  params?: GetOrdersByPrescriberIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberOrderPagingDTO>(
    { url: `/prescribers/${prescriberId}/orders`, method: 'get', params },
    options
  )
}

export const getGetOrdersByPrescriberIdQueryKey = (
  prescriberId: number,
  params?: GetOrdersByPrescriberIdParams
) => [`/prescribers/${prescriberId}/orders`, ...(params ? [params] : [])]

export const useGetOrdersByPrescriberId = <
  TData = AsyncReturnType<typeof getOrdersByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetOrdersByPrescriberIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getOrdersByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetOrdersByPrescriberIdQueryKey(prescriberId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getOrdersByPrescriberId>
  > = () => getOrdersByPrescriberId(prescriberId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getOrdersByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getOrdersSummaryByPrescriberId = (
  prescriberId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberOrdersSummaryDTO>(
    { url: `/prescribers/${prescriberId}/orders-summary`, method: 'get' },
    options
  )
}

export const getGetOrdersSummaryByPrescriberIdQueryKey = (
  prescriberId: number
) => [`/prescribers/${prescriberId}/orders-summary`]

export const useGetOrdersSummaryByPrescriberId = <
  TData = AsyncReturnType<typeof getOrdersSummaryByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getOrdersSummaryByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetOrdersSummaryByPrescriberIdQueryKey(prescriberId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getOrdersSummaryByPrescriberId>
  > = () => getOrdersSummaryByPrescriberId(prescriberId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getOrdersSummaryByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getWaitingPrescriptionsByPrescriberId = (
  prescriberId: number,
  params?: GetWaitingPrescriptionsByPrescriberIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<WaitingPrescriptionPagingDTO>(
    {
      url: `/prescribers/${prescriberId}/waiting-prescriptions`,
      method: 'get',
      params,
    },
    options
  )
}

export const getGetWaitingPrescriptionsByPrescriberIdQueryKey = (
  prescriberId: number,
  params?: GetWaitingPrescriptionsByPrescriberIdParams
) => [
  `/prescribers/${prescriberId}/waiting-prescriptions`,
  ...(params ? [params] : []),
]

export const useGetWaitingPrescriptionsByPrescriberId = <
  TData = AsyncReturnType<typeof getWaitingPrescriptionsByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetWaitingPrescriptionsByPrescriberIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getWaitingPrescriptionsByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetWaitingPrescriptionsByPrescriberIdQueryKey(prescriberId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getWaitingPrescriptionsByPrescriberId>
  > = () =>
    getWaitingPrescriptionsByPrescriberId(prescriberId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getWaitingPrescriptionsByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getPrescriberProfessions = (
  params?: GetPrescriberProfessionsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberProfessionPagingDTO>(
    { url: `/professions`, method: 'get', params },
    options
  )
}

export const getGetPrescriberProfessionsQueryKey = (
  params?: GetPrescriberProfessionsParams
) => [`/professions`, ...(params ? [params] : [])]

export const useGetPrescriberProfessions = <
  TData = AsyncReturnType<typeof getPrescriberProfessions>,
  TError = unknown
>(
  params?: GetPrescriberProfessionsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriberProfessions>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPrescriberProfessionsQueryKey(params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPrescriberProfessions>
  > = () => getPrescriberProfessions(params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriberProfessions>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query,
  }
}

export const getPrescriberFiles = (
  prescriberId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberFileResponseDTO[]>(
    { url: `/prescribers/${prescriberId}/files`, method: 'get' },
    options
  )
}

export const getGetPrescriberFilesQueryKey = (prescriberId: number) => [
  `/prescribers/${prescriberId}/files`,
]

export const useGetPrescriberFiles = <
  TData = AsyncReturnType<typeof getPrescriberFiles>,
  TError = unknown
>(
  prescriberId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriberFiles>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPrescriberFilesQueryKey(prescriberId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getPrescriberFiles>> =
    () => getPrescriberFiles(prescriberId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriberFiles>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const uploadPrescriberFile = (
  prescriberId: number,
  fileDTO: FileDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberFileResponseDTO>(
    {
      url: `/prescribers/${prescriberId}/files`,
      method: 'post',
      data: fileDTO,
    },
    options
  )
}

export const useUploadPrescriberFile = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof uploadPrescriberFile>,
    TError,
    { prescriberId: number; data: FileDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof uploadPrescriberFile>,
    { prescriberId: number; data: FileDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return uploadPrescriberFile(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof uploadPrescriberFile>,
    TError,
    { prescriberId: number; data: FileDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const deletePrescriberFile = (
  prescriberId: number,
  fileId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/prescribers/${prescriberId}/files/${fileId}`, method: 'delete' },
    options
  )
}

export const useDeletePrescriberFile = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deletePrescriberFile>,
    TError,
    { prescriberId: number; fileId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deletePrescriberFile>,
    { prescriberId: number; fileId: string }
  > = props => {
    const { prescriberId, fileId } = props || {}

    return deletePrescriberFile(prescriberId, fileId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof deletePrescriberFile>,
    TError,
    { prescriberId: number; fileId: string },
    TContext
  >(mutationFn, mutationOptions)
}
export const getPrescriberEaringsByPrescriberId = (
  prescriberId: number,
  params?: GetPrescriberEaringsByPrescriberIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberEarningsPagingDTO>(
    { url: `/prescribers/${prescriberId}/earnings`, method: 'get', params },
    options
  )
}

export const getGetPrescriberEaringsByPrescriberIdQueryKey = (
  prescriberId: number,
  params?: GetPrescriberEaringsByPrescriberIdParams
) => [`/prescribers/${prescriberId}/earnings`, ...(params ? [params] : [])]

export const useGetPrescriberEaringsByPrescriberId = <
  TData = AsyncReturnType<typeof getPrescriberEaringsByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetPrescriberEaringsByPrescriberIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriberEaringsByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrescriberEaringsByPrescriberIdQueryKey(prescriberId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPrescriberEaringsByPrescriberId>
  > = () =>
    getPrescriberEaringsByPrescriberId(prescriberId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriberEaringsByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const createPrescriberAddress = (
  prescriberId: number,
  createPrescriberAddressDTO: CreatePrescriberAddressDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberAddressDTO>(
    {
      url: `/prescribers/${prescriberId}/address`,
      method: 'post',
      data: createPrescriberAddressDTO,
    },
    options
  )
}

export const useCreatePrescriberAddress = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createPrescriberAddress>,
    TError,
    { prescriberId: number; data: CreatePrescriberAddressDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createPrescriberAddress>,
    { prescriberId: number; data: CreatePrescriberAddressDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return createPrescriberAddress(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createPrescriberAddress>,
    TError,
    { prescriberId: number; data: CreatePrescriberAddressDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const updatePrescriberAddress = (
  prescriberId: number,
  updatePrescriberAddressDTO: UpdatePrescriberAddressDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberAddressDTO>(
    {
      url: `/prescribers/${prescriberId}/address`,
      method: 'put',
      data: updatePrescriberAddressDTO,
    },
    options
  )
}

export const useUpdatePrescriberAddress = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePrescriberAddress>,
    TError,
    { prescriberId: number; data: UpdatePrescriberAddressDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePrescriberAddress>,
    { prescriberId: number; data: UpdatePrescriberAddressDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return updatePrescriberAddress(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof updatePrescriberAddress>,
    TError,
    { prescriberId: number; data: UpdatePrescriberAddressDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const getPrescriberAddressByPrescriberId = (
  prescriberId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberAddressDTO>(
    { url: `/prescribers/${prescriberId}/address`, method: 'get' },
    options
  )
}

export const getGetPrescriberAddressByPrescriberIdQueryKey = (
  prescriberId: number
) => [`/prescribers/${prescriberId}/address`]

export const useGetPrescriberAddressByPrescriberId = <
  TData = AsyncReturnType<typeof getPrescriberAddressByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriberAddressByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrescriberAddressByPrescriberIdQueryKey(prescriberId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPrescriberAddressByPrescriberId>
  > = () => getPrescriberAddressByPrescriberId(prescriberId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriberAddressByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}
