import styled from 'styled-components/macro'
import {
  MediaQuery
} from '../../utils/constants'

export const Container = styled.div `
  width: 100%;
  padding: 40px 20px;
  background-color: #fdfcfe;
`

export const Left = styled.div `
  width: 70%;
`
export const Profile = styled.div ``
export const Right = styled.div `
  width: 26%;
`

export const Cta = styled.div ``

export const NewsFeed = styled.div ``

export const Statistics = styled.div ``

export const Content = styled.div `
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
`

export const StatsPreviewContainer = styled.section `
  display: flex;
  width: 100%;
`

export const TitleWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  width: 97%;
  margin: 0 auto;
`

export const Button = styled.button `
  cursor: pointer;
  color: #65b293;
  border-radius: 6px;
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #65b293;
  background: transparent;
  white-space: nowrap;
`

export const Wrapper = styled.div `
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  ${MediaQuery.L_AND_DOWN} {
    flex-direction: column;
  }
`