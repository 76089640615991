/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { TypesForProductDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getAllTypesForProduct = (
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TypesForProductDTO[]>(
    { url: `/product-types`, method: 'get' },
    options
  )
}

export const getGetAllTypesForProductQueryKey = () => [`/product-types`]

export const useGetAllTypesForProduct = <
  TData = AsyncReturnType<typeof getAllTypesForProduct>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<typeof getAllTypesForProduct>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllTypesForProductQueryKey()

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllTypesForProduct>> =
    () => getAllTypesForProduct(requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getAllTypesForProduct>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query,
  }
}

export const getAllProductTypesByProductId = (
  productId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TypesForProductDTO[]>(
    { url: `/products/${productId}/types`, method: 'get' },
    options
  )
}

export const getGetAllProductTypesByProductIdQueryKey = (productId: string) => [
  `/products/${productId}/types`,
]

export const useGetAllProductTypesByProductId = <
  TData = AsyncReturnType<typeof getAllProductTypesByProductId>,
  TError = unknown
>(
  productId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllProductTypesByProductId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllProductTypesByProductIdQueryKey(productId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllProductTypesByProductId>
  > = () => getAllProductTypesByProductId(productId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getAllProductTypesByProductId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}
