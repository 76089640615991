import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const BoxTable = styled.div`
  margin-top: 30px;
`
export const TitleBox = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  span {
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    background-color: ${Color.DEEP_SEA};
    border-radius: 5px;
    color: white;
  }
`

export const Title = styled.h3``

export const Table = styled.table`
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(65, 91, 115, 0.15);
  padding: 10px;
  border-radius: 5px;
  th {
    text-align: start;

    border-bottom: 1px solid #eee;
    font-size: 14px;
  }
  td {
    padding: 5px 0;
    font-size: 14px;
  }
`
export const TableHeader = styled.thead``

export const TableHeaderWrapper = styled.tr``

export const TableBody = styled.tbody``

export const TableHeaderItem = styled.th`
  text-align: start;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  font-size: 14px;
`

export const NoPrescriptionMessage = styled.p`
  text-align: center;
  font-size: 13px;
  opacity: 0.5;
  margin-top: 60px;
`

export const MoreIcon = styled.button`
  background-color: ${Color.DEEP_SEA};
  font-size: 15px;
  width: 27px;
  height: 27px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  color: white;
`
