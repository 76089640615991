import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const Container = styled.div`
  padding-bottom: 40px;
`
export const SecondaryTitle = styled.h2`
  font-size: 18px;
  line-height: 21.6px;
  margin-bottom: 20px;
  font-weight: 400;
  scroll-margin: 56px;
`

export const Wrapper = styled.div`
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border-radius: 8px;
  background: white;
  padding: 24px 20px;
  margin-bottom: 32px;
`

export const IconContainer = styled.div`
  border: 1px solid ${props => props.color};
  box-sizing: border-box;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  margin-right: 8px;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  & > div:not(:last-child) {
    margin-right: ${props => props.gap};
  }
`

export const CardContainer = styled.div`
  margin-bottom: ${props => (props.isLast ? '0px' : '20px')};
`

export const RightContainer = styled.div`
  background: #fdfcfe;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  ${MediaQuery.M_AND_UP} {
    overflow-y: scroll;
  }
`

export const DialogBody = styled.div`
  display: flex;
  padding: 40px 65px;
  ${MediaQuery.L_AND_DOWN} {
    padding: 20px 20px;
  }
`

export const BodyCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const FormulationContainer = styled.div`
  background: #f7f5f8;
  padding: 16px 24px;
  margin-bottom: 40px;
  border-radius: 8px;
`

export const FormulationBody = styled.div`
  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const PointTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  white-space: pre-wrap;
  color: #080038;
`

export const ContraindictionTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-wrap;
  color: #080038;
`

export const ImgLabelProduct = styled.img`
  width: 100%;
  margin-bottom: 32px;
`

export const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 32px;

  & > div:not(:last-child) {
    margin-right: 8px;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 8px;
  }
`

export const LabelImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 30px;
`

export const LabelTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-wrap;
  color: #080038;
`

export const IconImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`
