/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type Role = 'PRESCRIBER' | 'PATIENT'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  PRESCRIBER: 'PRESCRIBER' as Role,
  PATIENT: 'PATIENT' as Role,
}
