import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Wrapper = styled.div`
  @media (min-width: 68.75rem) {
    display: flex;
    flex-direction: column;
    padding: 2.75rem;
    border-radius: 1rem;
    background: white;
    box-shadow: 0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12);
    margin-left: 12.3125rem;
    margin-right: 1.625rem;
    width: 25.9375rem;
    min-width: 25.9375rem;
  }

  @media (min-width: 37.5rem) AND (max-width: 68.75rem) {
    display: flex;
    flex-direction: column;
    padding: 2.75rem;
    border-radius: 1rem;
    background: white;
    box-shadow: 0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12);
    margin-left: 25%;
    width: 25.9375rem;
    min-width: 25.9375rem;
  }

  @media (max-width: 37.5rem) {
    transform: scale(0.9);
    display: flex;
    flex-direction: column;
    padding: 2.75rem;
    border-radius: 1rem;
    background: white;
    box-shadow: 0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12);
    margin-left: 0;
    width: 25.9375rem;
    min-width: 25.9375rem;
  }
`

export const FormDisplay = styled.div`
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const Title = styled.div`
  color: var(--Text-500, #080038);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.375rem;
`

export const Subtitle = styled.div`
  color: var(--Text-400, #393360);
  width: 20.4375rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2.375rem;
`

export const FooterText = styled.p`
  margin-top: 8px;
  font-size: 12px;
  color: #080038;
  text-align: center;

  > a {
    color: #f46363;
    text-decoration: none;
  }
`

export const ImageLogo = styled.img`
  width: 10.125rem;
  object-fit: contain;
  object-position: center center;
  margin-bottom: 1.25rem;
`

export const ImageText = styled.div`
  width: 37.8125rem;
  height: 48rem;
  margin: 1.75rem;
  position: relative;
  filter: drop-shadow(0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12));
`

export const Image = styled.img`
  width: 37.8125rem;
  height: 48.0625rem;
  object-fit: cover;
  object-position: center center;
  @media (max-width: 81.25rem) {
    display: none;
  }
`

export const TextImage = styled.div`
  position: absolute;
  top: 3.375rem;
  left: 3.125rem;
  color: white;
  font-family: 'basic-grotesque-pro-regular', sans-serif;
  width: 25.125rem;
  font-size: 2.2862rem;
  font-weight: 400;
  line-height: 2.7437rem;
  @media (max-width: 81.25rem) {
    display: none;
  }
`

export const NakedLayout = styled.section`
  overflow-x: hidden;
  overflow-y: hidden;
`

export const BackButtonWrapper = styled.div`
  margin-bottom: 1.25rem;
`
