import { useRef } from 'react'
import Router from './router'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import { ReactQueryDevtools } from 'react-query/devtools'
import 'react-phone-number-input/style.css'
import 'react-phone-input-2/lib/style.css'
import { Global } from './style'
import { AuthProvider } from './services/auth/provider'

const App = () => {
  return <Router />
}

const QueryApp = () => {
  // Attributes
  const queryClientRef = useRef()

  if (!queryClientRef.current) {
    // @ts-ignore
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchIntervalInBackground: true,
          refetchOnReconnect: false,
          refetchOnMount: true,
          refetchOnWindowFocus: false,
        },
      },
    })
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack }) => (
          <div>
            <div>You have encountered an error</div>
            <div>{error.toString()}</div>
            <div>{componentStack}</div>
          </div>
        )}
      >
        <Global>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Global>
        <ReactQueryDevtools initialIsOpen={false} />
      </Sentry.ErrorBoundary>
    </QueryClientProvider>
  )
}

export default QueryApp
