import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const LogoContainer = styled.div`
  height: 100%;
  width: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${MediaQuery.M_AND_DOWN} {
    width: 130px;
  }
`
