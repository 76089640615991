import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`

export const ProductInfo = styled.div`
  width: 65%;

  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
    margin-bottom: 40px;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  background-color: #eef6f9;
  border-radius: 10px;
  width: 30%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
`
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TitleHeader = styled.div`
  img {
    object-fit: contain;
  }
`
export const InfoHeader = styled.div``

export const Title = styled.h2`
  font-weight: 300;
`

export const InfoIcon = styled.span`
  cursor: pointer;
  font-size: 20px;
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  & > div:not(:last-child) {
    margin-right: ${props => props.gap};
  }
`

export const Hr = styled.hr`
  border: 1px solid #eee;
  margin-bottom: 15px;
  margin-top: 10px;
`

export const RecommendedQuantity = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
export const RecommendedQuantityText = styled.p`
  font-size: 12px;
  opacity: 0.9;
  margin-right: 10px;
`
export const RecommendedQuantityNumber = styled.div`
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  background-color: #e8efed;
  color: #3a835f;
`
export const BannerPricing = styled.div`
  ${MediaQuery.S_AND_DOWN} {
    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 20px 0;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`

export const HandleQuantityWrapper = styled.div`
  display: inline-block;
`

export const HandleQuantityFlexWrapper = styled.div`
  display: flex;
  border-radius: 5px;
  font-size: 12px;
  width: 100px;
  margin-bottom: 20px;
  border: 1px solid #eee;

  ${MediaQuery.S_AND_DOWN} {
    margin: 0;
  }
`

export const HandleQuantity = styled.div`
  display: flex;
  align-items: center;
`

export const MinusButton = styled.span`
  cursor: pointer;
  margin: 0 10px;
`

export const PlusButton = styled.span`
  cursor: pointer;
  margin: 0 10px;
`
export const Quantity = styled.span`
  margin: 0 10px;
`

export const QuantityButton = styled.div`
  cursor: pointer;
  background-color: #3a835f;
  padding: 10px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 10px;
  color: white;
`

export const PricingWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-bottom: 20px;
  ${MediaQuery.S_AND_DOWN} {
    margin: 0;
  }
`
export const Pricing = styled.div`
  font-size: 22px;
  margin-right: 5px;
`

export const PerMonth = styled.span`
  margin-top: 9px;
  font-size: 12px;
`

export const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
`

export const Table = styled.table`
  font-size: 12px;
  opacity: 0.8;
  border-collapse: collapse;
  color: #333;

  ${MediaQuery.L_AND_DOWN} {
    font-size: 10px;
  }

  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }

  th,
  td {
    border-bottom: 1px solid #eee;
    padding: 10px 20px;
    ${MediaQuery.L_AND_DOWN} {
      padding: 7px 14px;
    }
    ${MediaQuery.M_AND_DOWN} {
      padding: 5px 10px;
    }
  }

  td {
    div {
      display: flex;
      align-items: center;
    }
    span {
      margin-right: 5px;
      display: inline-block;
      min-width: 20px;
      min-height: 20px;
      background-color: #e2e4f3;
      border-radius: 100%;

      ${MediaQuery.L_AND_DOWN} {
        min-width: 16px;
        min-height: 16px;
      }
    }
  }
`

export const ImgLabel = styled.img`
  max-width: 25px;
  max-height: 25px;
  margin-right: 5px;
  object-fit: contain;
`
export const QuantityDescription = styled.p`
  margin-top: 20px;
  font-size: 12px;
  opacity: 0.7;
`

export const AddToCartWrapper = styled.p`
  margin-bottom: 20px;
`

export const FavoriteWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
