import { useHistory } from 'react-router'
import { Button } from './style'

const FavoriteButton = () => {
  const history = useHistory()

  function redirectionToFavorites() {
    history.push('/favorites')
  }

  return <Button onClick={redirectionToFavorites}>Mes favoris</Button>
}

export default FavoriteButton
