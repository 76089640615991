import React, { useState } from 'react'
import { Container, Content } from './style'
import ComingSoon from './coming-soon'
import LastestNews from './latest-news'
import { ButtonGroup, Button } from '../webinars/style'

const NewsFeed = () => {
  const [showComponent, setShowComponent] = useState('latestNews')

  const handleButtonClick = component => {
    setShowComponent(component)
  }

  return (
    <Container>
      <h3>Actualités Simplycure</h3>

      <ButtonGroup>
        <Button
          onClick={() => handleButtonClick('latestNews')}
          selected={showComponent === 'latestNews'}
        >
          Dernières actualités
        </Button>
        <Button
          onClick={() => handleButtonClick('comingSoon')}
          selected={showComponent === 'comingSoon'}
        >
          Prochaines actualités
        </Button>
      </ButtonGroup>

      <Content>
        {showComponent === 'comingSoon' && <ComingSoon />}
        {showComponent === 'latestNews' && <LastestNews />}
      </Content>
    </Container>
  )
}

export default NewsFeed
