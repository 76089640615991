import styled from 'styled-components/macro'

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 36px 24px 24px 24px;
  border-bottom: 1px solid #e7e9f4;
`

export const Title = styled.h3`
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 17px;
`

export const Content = styled.div`
  padding: 36px 32.5px 17px 32.5px;

  & > *:not(:last-child) {
    margin-bottom: 36px;
  }
`

export const DragDropContainer = styled.div``

export const FileDroppedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
  border: 1px solid #3a845e;
  border-radius: 8px;
`

export const FileDroppedTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #080038;
`

export const FileDroppedRemove = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DragDrop = styled.div`
  background-color: #f8f9fc;
  padding: 29px;
  border: 2px dotted #e7e7e7;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 212px;
`

export const DragDropContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 186px;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Image = styled.img`
  width: 63px;
`

export const DragDropContentTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 2px;
  }
`

export const DragDropTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #080038;
  text-align: center;
`

export const DragDropSubtitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #ceccd7;
`

export const DragDropExtError = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #f46363;
  text-align: center;
`

export const Footer = styled.div`
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

export const ConfirmationText = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #080038;
`

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #3a845e;
  border-radius: 3.33px;
  width: 20px;
  height: 20px;
`

export const Info = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #080038;
  text-align: center;
`
