import { Button } from '../../ui'
import { BannerContainer, Description, TextContainer, Title } from './style'
import { BookingDemoBannerProps } from './props'

const BookingDemoBanner = ({ prescriber }: BookingDemoBannerProps) => {
  //Attributes
  const redirectionLink = `https://share-eu1.hsforms.com/1xyWe3pUVTT6EZMmx4JTqGwf2dsm?&email=${prescriber?.email}&firstname=${prescriber?.firstName}&lastname=${prescriber?.lastName}`

  //Render
  return (
    <BannerContainer>
      <TextContainer>
        <Title>Rencontrez l’un de nos conseillers !</Title>
        <Description>
          Vous avez des questions? Réservez un meeting pour avoir des réponses!
        </Description>
      </TextContainer>
      <a href={redirectionLink} target='_blank' rel='noreferrer'>
        <Button
          appearance={'primary'}
          iconLeft={undefined}
          isActive={undefined}
          isDisabled={false}
          isLoading={false}
          style={{
            borderRadius: '.56rem',
            backgroundColor: 'white',
            color: '#619D7E',
            fontWeigt: 500,
          }}
        >
          Réserver un meeting
        </Button>
      </a>
    </BannerContainer>
  )
}
export default BookingDemoBanner
