import { forwardRef, useState } from 'react'
import { InputContainer, Input, Eye } from './style'
import Icon from '../icon'
import Textarea from '../textarea'
import Select from '../select'
import { IconEye } from '../icon/icons'
import IconEyeOff from '../icon/icons/IconEyeOff'
import { InputElProps } from './props'

const InputEl = forwardRef<HTMLInputElement, InputElProps>(
  ({ iconLeft, type, inputContainerStyling, customHeight, ...props }, ref) => {
    const extraProps: any = {}
    let InputComponent = Input
    if (type === 'textarea') {
      InputComponent = Textarea
      extraProps.hasBorder = false
    } else if (type === 'select') {
      InputComponent = Select
    }

    const [isEyeClicked, setIsEyeClicked] = useState(false)

    if (type === 'select') {
      return <InputComponent {...props} {...extraProps} ref={ref} />
    }

    return (
      <InputContainer style={inputContainerStyling}>
        {iconLeft && <Icon name={iconLeft} />}
        <InputComponent
          {...props}
          {...extraProps}
          customHeight={customHeight}
          type={
            type === 'password' ? (isEyeClicked ? 'text' : 'password') : type
          }
          ref={ref}
        />
        {type === 'password' ? (
          isEyeClicked ? (
            <Eye>
              <IconEyeOff
                fill='#8F95B2'
                width='24px'
                height='24px'
                cursor='pointer'
                onClick={() => setIsEyeClicked(!isEyeClicked)}
              />
            </Eye>
          ) : (
            <Eye>
              <IconEye
                fill='#8F95B2'
                width='24px'
                height='24px'
                cursor='pointer'
                onClick={() => setIsEyeClicked(!isEyeClicked)}
              />
            </Eye>
          )
        ) : (
          <div></div>
        )}
      </InputContainer>
    )
  }
)

InputEl.defaultProps = {
  size: 'm',
}

export default InputEl
