import { Filters, Filter } from './style'
import { IoMdClose } from 'react-icons/io'
import { IFiltersListProps } from './props'

const FiltersList = ({
  values,
  setFieldValue,
  type,
  excludes = false,
}: IFiltersListProps) => {
  // Functions
  function removeItem(itemValue) {
    const newArray = values[type].filter(elt => elt !== itemValue)
    setFieldValue(type, newArray)
  }
  function modifyDisplayValue(value: any) {
    if (!value) return
    switch (type) {
      case 'allergens':
        return 'Sans ' + value.name
      default:
        return value.name
    }
  }

  // Render
  if (!values[type].length) return <></>
  return (
    <Filters>
      {values[type].map((value, index) => (
        <Filter
          key={index}
          style={{
            background: '#F8F9FC',
            border: '1px solid #E9EAF0',
            margin: 0,
          }}
        >
          <p style={{ color: excludes ? '#8F95B2' : '#080038' }}>
            {modifyDisplayValue(value)}
          </p>
          <span onClick={() => removeItem(value)}>
            <IoMdClose />
          </span>
        </Filter>
      ))}
    </Filters>
  )
}

export default FiltersList
