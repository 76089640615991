import PropTypes from 'prop-types'

import { GlobalContainer, Main, Aside, Content } from './style'
import ActionHeader from '../../section/action-header'

const ActionLayout = ({ children, aside, top, header = null }) => (
  <GlobalContainer>
    {top && <ActionHeader>{top}</ActionHeader>}
    {header && <>{header}</>}
    <Content>
      <Main>{children}</Main>
      {aside && <Aside>{aside}</Aside>}
    </Content>
  </GlobalContainer>
)

ActionLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ActionLayout
