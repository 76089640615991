import styled from 'styled-components/macro'

export const LabelDescription = styled.p`
  font-size: 0.875rem;
`
export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 1.5rem;
  width: 100%;
`
export const SalesContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`
export const SalesButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: ${props =>
    !!props.isSelected && props.isSelected !== undefined
      ? `1px solid black`
      : `1px solid #e9eaf0`};
  text-align: center;
  font-size: 0.875rem;
  cursor: pointer;
  background-color: white;
  transition: all 0.1s ease;
  &:hover {
    border: 1px solid black;
  }
`
