import { PatientItem, Inital, Email } from './style'
import { PatientEmailProps } from './props'

const PatientItemC = ({
  setEmail,
  addEmail,
  setShowPatientList,
  id,
  firstName,
  lastName,
  email,
}: PatientEmailProps) => {
  return (
    <PatientItem
      onClick={() => {
        setEmail('')
        addEmail(email)
        setShowPatientList(false)
      }}
      key={id}
    >
      {firstName && (
        <Inital>
          {firstName} {lastName}
        </Inital>
      )}
      <Email>{email}</Email>
    </PatientItem>
  )
}

export default PatientItemC
