/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type TakePeriodType = 'DAY' | 'NIGHT'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TakePeriodType = {
  DAY: 'DAY' as TakePeriodType,
  NIGHT: 'NIGHT' as TakePeriodType,
}
