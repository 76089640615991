import { Container, IconContainer, Text } from './style'
import { QuizDateTimeProps } from './props'
import format from 'date-fns/format'
import { Navigator } from '../../ui/icon/icons'

const QuizDateTime = ({ dateTime }: QuizDateTimeProps) => {
  // Render
  return (
    <Container>
      <IconContainer>
        <Navigator width='.9031rem' height='.8387rem' fill={'#3A845E'} />
      </IconContainer>
      <Text>{format(dateTime, 'dd MMMM yyyy')}</Text>
    </Container>
  )
}

export default QuizDateTime
