import React from 'react'
import { Card, Title, SubTitle, Image } from './style'
import { Button } from '../../components/ui'
import { useHistory } from 'react-router'

function PortailCardRedirection({
  title,
  subtitle,
  imgSrc,
  imgAlt,
  btnText,
  redirectUrl,
}) {
  const history = useHistory()
  const handleRedirect = () => {
    history.push(`${redirectUrl}`)
  }
  return (
    <Card>
      <Image src={imgSrc} alt={imgAlt} />
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <Button type='submit' onClick={() => handleRedirect()}>
        {btnText}
      </Button>
    </Card>
  )
}

export default PortailCardRedirection
