/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PackagingFormatType =
  | 'POT'
  | 'BLISTER'
  | 'FLACON'
  | 'BOTTLE'
  | 'BOX'
  | 'BAG'
  | 'SPRAY'
  | 'TUBE'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PackagingFormatType = {
  POT: 'POT' as PackagingFormatType,
  BLISTER: 'BLISTER' as PackagingFormatType,
  FLACON: 'FLACON' as PackagingFormatType,
  BOTTLE: 'BOTTLE' as PackagingFormatType,
  BOX: 'BOX' as PackagingFormatType,
  BAG: 'BAG' as PackagingFormatType,
  SPRAY: 'SPRAY' as PackagingFormatType,
  TUBE: 'TUBE' as PackagingFormatType,
}
