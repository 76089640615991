import { Tab, TabItem } from './style'
import { PrescriptionTabProps } from './props'

const PrescriptionTab = ({ setTab, tab }: PrescriptionTabProps) => {
  return (
    <Tab>
      <TabItem active={tab === 'CREATED'} onClick={() => setTab('CREATED')}>
        Produits recommandés
      </TabItem>
      <TabItem active={tab === 'ORDERED'} onClick={() => setTab('ORDERED')}>
        Produits achetés
      </TabItem>
    </Tab>
  )
}

export default PrescriptionTab
