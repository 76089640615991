import styled from 'styled-components/macro'

export const AdjustmentItem = styled.span`
  cursor: pointer;
  margin-right: 10px;
  padding: 6px 10px;
  background: #ffffff;
  border: 1px solid #e7e9f4;
  border-radius: 100px;
  font-size: 10px;
  color: #8f95b2;
`

export const ArrowDown = styled.span`
  font-size: 8px;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  transform: ${props => (props.isOpen ? 'rotate(-180deg)' : '')};
  transition: all 0.2s;
`
