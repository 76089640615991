import { Button, Dialog } from '../../../ui'
import {
  DialogContainer,
  DialogContent,
  Title,
} from '../../../../pages/prescriptions/new-prescription-compliment/style'
import { RecoLinkProps } from './props'

const SaveDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  onPrescriptionSubmitted,
  setDraftChanged,
}: RecoLinkProps) => {
  return (
    <Dialog
      type='default'
      isOpen={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      styleContainer={undefined}
    >
      <DialogContainer>
        <DialogContent>
          <Title isCenter>
            Veuillez sauvegarder la recommandation avant de procéder à la copie
            du lien.
          </Title>
          <br></br>

          <Button
            appearance={undefined}
            onClick={async () => {
              await onPrescriptionSubmitted(false).then(() => {
                setIsDialogOpen(false)
                setDraftChanged(false)
              })
            }}
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            Sauvegarder
          </Button>
        </DialogContent>
      </DialogContainer>
    </Dialog>
  )
}

export default SaveDialog
