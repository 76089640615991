import styled from 'styled-components/macro'

export const Cell = styled.td`
  flex-grow: 1;
  width: ${({ width }) => width || 'auto'};
`

export const Row = styled.tr`
  width: 100%;

  ${Cell}:nth-child(4) {
    max-width: 100px;
  }
`
export const SeeIcon = styled.div`
  font-size: 15px;
`
export const Price = styled.span`
  font-size: 12px;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const HeadRow = styled(Row)`
  ${Cell} {
    padding: 10px 0;
  }
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  a {
    font-size: 12px;
    padding: 2px 10px;
  }
`

export const PrescriptionTypeWrapper = styled.div`
  opacity: 0.5;
  font-size: 12px;

  ul {
    list-style: none;
  }
`

export const BoughtStatusContainer = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem;
  text-align: center;
  background-color: #3a845e;
`
export const SeenStatusContainer = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem;
  text-align: center;
  background-color: #d5f6e4;
`
export const NotSeenStatusContainer = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem;
  text-align: center;
  background-color: #fcf2c9;
`

export const BoughtStatus = styled.p`
  color: #f1fcf6;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
`
export const SeenStatus = styled.p`
  color: #080038;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
`
export const NotSeenStatus = styled.p`
  color: #923b3b;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
`
