import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px;

  padding: 40px 25px;
  border-top: 1px solid #e9eaf0;

  & > *:not(:last-child) {
    margin-right: 50px;
  }
`

export const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
`

export const Question = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #080038;
`
