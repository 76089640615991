import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.height};
  padding: 8px 24px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  background-color: white;
`

export const Text = styled.p`
  color: #8f95b2;
  font-size: 12px;
`

export const IconsContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 4px;
  }
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
`
