import { useState } from 'react'
import { GainsTableProps } from './props'
import { useTable } from 'react-table'
import { Container, NoFoundWrapper, Button } from './style'
import { NoFound } from '../../../ui'
import SponsorFormDialog from '../../../sponsor/form-dialog'
import { useGetMe } from '../../../../utils/hooks/getMe'

import BlockAccessModal from '../../../block-access-modal'

const GainsTable = ({ columns, data }: GainsTableProps) => {
  // Attributes
  const [isBlockAccessModalOpen, setIsBlockAccessModalOpen] = useState(false)
  const { data: prescriber } = useGetMe()

  const [isFormOpened, setIsFormOpened] = useState<boolean>(false)
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  // Render
  return (
    <Container>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th style={{ fontWeight: 500 }} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            )
          })}
        </tbody>
        {!rows.length && (
          <NoFoundWrapper>
            <NoFound
              title='Vous n’avez pas encore échangé vos points'
              descritpion=''
            />
            <Button
              onClick={() => {
                if (prescriber?.isVerified) setIsFormOpened(true)
                else setIsBlockAccessModalOpen(true)
              }}
            >
              Echanger mes points
            </Button>
          </NoFoundWrapper>
        )}
      </table>

      {isFormOpened && (
        <SponsorFormDialog
          isOpen={isFormOpened}
          onClose={() => setIsFormOpened(false)}
          formId={'b1a762c1-83de-4738-a970-573a510ffd34'}
          prescriber={prescriber}
          succeesMessageTitle='Merci ! Votre demande d’échange de points a bien été enregistrée.'
          succeesMessageDescription=''
        />
      )}

      <BlockAccessModal
        isOpen={isBlockAccessModalOpen}
        setIsOpen={setIsBlockAccessModalOpen}
      />
    </Container>
  )
}

export default GainsTable
