import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
`
export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  gap: 4px;
  width: 100m;
  height: 72px;
  background: #ffffff;
  border: 1px solid #e7e9f4;
  border-radius: 12px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`
export const TextContent = styled.div``

export const TextContentTitle = styled.div`
  font-size: 14px;
`

export const TextContentQuantity = styled.div`
  font-size: 12px;
  opacity: 0.5;
`

export const Button = styled.div`
  cursor: pointer;
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  background: #3a845e;
  border: 1px solid #3a845e;
  border-radius: 8px;
  color: white;
  white-space: nowrap;
`
