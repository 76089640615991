import { useState } from 'react'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQueryClient,
} from 'react-query'
import { Dialog } from '../../../../ui'
import { IconCross } from '../../../../ui/icon/icons'
import {
  Container,
  CloseButtonContainer,
  CloseButton,
  Title,
  SubTitle,
  Input,
  Button,
} from './style'
import toast from 'react-hot-toast'
import {
  useCreatePrescriberFavoriteList,
  useUpdatePrescriberFavoriteListById,
} from '../../../../../services/api/favorites/favorites'
import { useGetMe } from '../../../../../utils/hooks/getMe'
import { useHistory } from 'react-router'
import { ProductDTO } from '../../../../../services/api/types'

export interface Props {
  isOpen: boolean
  onClose: () => void
  categoryName?: string
  categoryId?: string
  addProductToFavorite?: (id: string) => void
  refetchCurrentFavoriteList?: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<ProductDTO[], unknown>>
}

const CreateCategory = ({
  isOpen,
  onClose,
  categoryName,
  categoryId,
  addProductToFavorite,
  refetchCurrentFavoriteList,
}: Props) => {
  // Attributes
  const [newCategory, setNewCategory] = useState(
    categoryName ? categoryName : ''
  )
  const { data: prescriber } = useGetMe()
  const history = useHistory()
  const avoidRedirectionFromPrescription =
    history.location.pathname.includes('/prescriptions') ||
    history.location.pathname.includes('/marketplace-protocoles')

  const queryClient = useQueryClient()

  // Refetch favorites after category modification
  function refetchFavorite() {
    queryClient.invalidateQueries([
      `/prescribers/${prescriber?.id}/favorite-lists`,
    ])
    if (!!refetchCurrentFavoriteList) refetchCurrentFavoriteList()
  }

  // Create favorite list mutation
  const {
    mutateAsync: createPrescriberFavoriteList,
    isLoading: isCreatePrescriberFavoriteListLoading,
  } = useCreatePrescriberFavoriteList({
    mutation: {
      onSuccess: data => {
        refetchFavorite()
        setNewCategory('')
        onClose()
        addProductToFavorite && addProductToFavorite(data.id)
        toast.success('La catégorie a bien été créée')
        if (!!avoidRedirectionFromPrescription) return
        history.push(`/favorites?favoriteListId=${data.id}`)
      },
      onError: error => {
        toast.error('Oops! Une erreur est survenue.')
      },
    },
  })

  // Update favorite list mutation
  const { mutateAsync: updatePrescriberFavoriteListById } =
    useUpdatePrescriberFavoriteListById({
      mutation: {
        onSuccess: () => {
          refetchFavorite()
          toast.success('La catégorie a bien été modifiée')
          onClose()
        },
        onError: error => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })

  // Add category function
  const addCategory = () => {
    if (!newCategory.length) {
      toast.error('Oops! Une erreur est survenue.')
      return
    }

    if (categoryId) {
      updatePrescriberFavoriteListById({
        prescriberId: prescriber?.id,
        favoriteListId: categoryId,
        data: {
          name: newCategory,
        },
      })
    } else {
      createPrescriberFavoriteList({
        prescriberId: prescriber?.id,
        data: {
          name: newCategory,
        },
      })
    }
  }

  // Handle new category input change
  const handleNewCategoryChange = event => {
    setNewCategory(event.target.value)
  }

  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      type='minimalCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={onClose}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <Title>
          {categoryId ? 'Modifier' : 'Créer'} une nouvelle catégorie
        </Title>
        <SubTitle>
          Entrez le nom de la nouvelle catégorie que vous souhaitez{' '}
          {categoryId ? 'modifier' : 'créer'}.
        </SubTitle>
        <Input
          placeholder='Insérer un nom'
          onChange={handleNewCategoryChange}
          value={newCategory}
        />
        <Button
          isLoading={isCreatePrescriberFavoriteListLoading}
          onClick={addCategory}
        >
          Confirmer
        </Button>
      </Container>
    </Dialog>
  )
}

export default CreateCategory
