import { Helmet } from 'react-helmet'

import { Container, Content, Aside } from './style'
import Layout from '../../../components/layout/default'
import { useOnboardingProfileCheck } from '../../../utils/hooks'
import SettingAsideNav from '../../../components/settings/aside-nav'
import BrandsForm from '../../../components/onboarding/brands-step/brands-form'

const BrandsPage = () => {
  useOnboardingProfileCheck()

  return (
    <Layout isPublic={false} active={false}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Marques </title>
      </Helmet>
      <Container>
        <Aside>
          <SettingAsideNav />
        </Aside>
        <Content>
          <BrandsForm
            title='Avec quelles marques accéptez-vous de partager vos données et votre activité ?'
            description='En sélectionnant ces marques, vous accéptez de partager certaines informations (nom, e-mail et région géographique) et de de recevoir plus d’informations sur leurs produits.'
          />
        </Content>
      </Container>
    </Layout>
  )
}

export default BrandsPage
