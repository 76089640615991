import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div:not(:last-child) {
    margin-right: 32px;
  }
`

export const ButtonContainer = styled.a`
  font-size: 12px;
  background: #ffffff;
  border-radius: 8px;
  color: #2e6a4b;
  border: none;
  padding: 8px 10px;
  cursor: pointer;
  text-decoration: none;
`
