import { Container, Content, Image, Subtitle, Title } from './style'

const ProtocolsPlaceholder = () => {
  // Render
  return (
    <Container>
      <Image src='/assets/images/not-found.png' />
      <Content>
        <Title>Aucun protocole</Title>
        <Subtitle>
          Les protocoles sont des recommandations de produits pré-enregistrées
          que vous pouvez réutiliser à tout moment.
        </Subtitle>
      </Content>
    </Container>
  )
}

export default ProtocolsPlaceholder
