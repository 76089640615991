import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  position: relative;

  table {
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 12px;
  }

  thead > tr {
    height: 77px;
  }

  th {
    padding: 0 12px;
    text-align: start;
    color: #080038;
    font-size: 14px;
    line-height: 17px;
  }
  th:first-child {
    padding: 0 12px 0 30px;
  }
  th:last-child {
    padding: 0 30px 0 12px;
  }

  tbody > tr {
    height: 78px;
  }

  td {
    padding: 0 12px;
    font-size: 14px;
    line-height: 17px;
    color: #080038;
  }
  td:first-child {
    padding: 0 12px 0 30px;
  }
  td:last-child {
    padding: 0 30px 0 12px;
  }
`

export const NoFoundWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const Button = styled.button`
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  border: none;
  color: #65b293;
  background-color: transparent;
  border: 1px solid #3a845e;
  color: #3a845e;
  cursor: pointer;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  padding: 8px;
  width: 140px;
`
