import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  right: -3.125rem;
  top: 0.1875rem;

  ${MediaQuery.M_AND_DOWN} {
    right: 0.9375rem;
    top: 0.5rem;
  }
`

export const CloseButton = styled.div`
  background: white;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Content = styled.div`
  padding-bottom: 0.625rem;
`

export const Header = styled.div`
  border-bottom: 0.0625rem solid #eee;
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  font-size: 1.125rem;
`

export const Body = styled.div`
  padding: 0.625rem 1.25rem;
`

export const Title = styled.div`
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
`

export const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  margin-right: 1.25rem;
  justify-content: space-between;
  width: 100%;
  gap: 0.625rem;
  ${MediaQuery.L_AND_DOWN} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const PromoCodeWrapper = styled.div`
  display: flex;
  gap: 0rem;
`
export const WrapperContentCode = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const IconWrapper = styled.div`
  color: var(--Success-500, #2ed477);
  font-size: 0.9375rem;
  padding-top: 0.3125rem;
  margin-right: 0.625rem;
`

export const ChooseButton = styled.div`
  cursor: pointer;
  border-radius: 0.25rem;
  background: var(--Text-500, #080038);
  color: white;
  padding: 0rem 0.625rem;
  white-space: nowrap;
  ${MediaQuery.L_AND_DOWN} {
    text-align: center;
    width: 10rem;
  }
`

export const WrapperPromoCode = styled.div`
  line-height: 1.25rem;
`

export const WrapperChoseButtonMobile = styled.div`
  ${MediaQuery.L_AND_UP} {
    display: none;
  }
`

export const WrapperChoseButtonDesktop = styled.div`
  ${MediaQuery.L_AND_DOWN} {
    display: none;
  }
`

export const List = styled.div`
  margin-bottom: 1.25rem;
`

export const Item = styled.div`
  margin-bottom: 0.4375rem;
  font-size: 0.875rem;
  opacity: 0.7;
  input {
    margin-right: 0.3125rem;
  }
`
