import { Dialog } from '../ui'
import { CreateRecoDialogProps } from './props'
import { Container, CloseButtonContainer, CloseButton } from './style'
import Content from './home-page-content'
import { IconCross } from '../ui/icon/icons'

const ShareAccessDialog = ({ isOpen, onClose }: CreateRecoDialogProps) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} type='minimalCenter'>
      <CloseButtonContainer onClick={() => onClose()}>
        <CloseButton>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <Content displayEditPercentageButton />
      </Container>
    </Dialog>
  )
}

export default ShareAccessDialog
