import styled from 'styled-components/macro'

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dddddd;
  padding: 25px;
  border-radius: 3px;
`

export const Title = styled.h1`
  font-size: 22px;
  text-align: center;
`
