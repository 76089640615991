import { Container, Title, Description, TextWrapper } from './style'

const BoxInfo = () => {
  return (
    <Container>
      <TextWrapper>
        <Title>
          Des sachets personnalisés <br></br> à vos besoins
        </Title>
        <Description>
          Pour vous faciliter, nous emballons vos compléments dans des sachets
          quotidiens personnalisés & recyclables
        </Description>
      </TextWrapper>
    </Container>
  )
}

export default BoxInfo
