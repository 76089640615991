export function calculateNutritionalValues(productVnr) {
  const data = productVnr.map(value => {
    return value?.array?.map(vnrValue => {
      const pourcentage = Number(
        (
          ((value?.quantity * vnrValue.productNutritional.quantity) /
            vnrValue?.value) *
          100
        ).toFixed(0)
      )

      const pourcentageMax = Number(
        (
          ((value?.quantity * vnrValue.productNutritional.quantity) /
            vnrValue?.maxValue) *
          100
        ).toFixed(0)
      )

      return {
        ingredientName: vnrValue?.nutritionalIntake?.name,
        vnr: {
          value: vnrValue?.value,
          pourcentage: pourcentage,
        },
        dosageMax: {
          value: vnrValue?.maxValue,
          pourcentage: pourcentageMax,
        },
        quantity: {
          value: value.quantity * vnrValue.productNutritional.quantity,
          unit: vnrValue.productNutritional.unit,
        },
        productsName: [value?.product?.name], // Make it an array
      }
    })
  })

  // Flatten the array of arrays into a single array
  const flatData = data.flat()

  // Merge objects with the same ingredientName, add the dosageMax pourcentage and quantity.value
  const mergedData = flatData.reduce((acc, cur) => {
    const existingIngredient = acc.find(
      item => item?.ingredientName === cur?.ingredientName
    )

    if (existingIngredient) {
      existingIngredient.dosageMax.pourcentage += cur.dosageMax.pourcentage
      existingIngredient.vnr.pourcentage += cur.vnr.pourcentage
      existingIngredient.quantity.value += cur.quantity.value
      existingIngredient.productsName.push(...cur.productsName)
    } else {
      acc.push(cur)
    }

    return acc
  }, [])

  return mergedData?.filter(item => item !== undefined)
}
