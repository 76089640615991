import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  @media (min-width: 62.5rem) {
    display: flex;
    flex-direction: column;
    padding: 2.75rem;
    box-shadow: 0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12);
    border-radius: 1rem;
    background: white;
    margin-left: 12.3125rem;
  }

  @media (min-width: 31.25rem) AND (max-width: 62.5rem) {
    transform: scale(0.8);
    background: white;
    box-shadow: 0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 2.625rem;
    position: relative;
    left: 0;
  }

  @media (max-width: 31.25rem) {
    transform: scale(0.9);
    background: white;
    box-shadow: 0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 2.625rem;
    position: relative;
  }
`

export const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 0.375rem;
`

export const Subtitle = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  font-weight: normal;
  color: #393360;
  width: 20.4375rem;
  line-height: 1.05rem;
`

export const TitleSuccess = styled.h1`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 2.5rem;
  width: 19.375rem;
`

export const SubtitleSuccess = styled.h2`
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  font-weight: normal;
  color: #8f95b2;
  width: 19.375rem;
  line-height: 1.05rem;
`

export const ImageLogo = styled.img`
  width: 14.4375rem;
  object-fit: contain;
  object-position: center center;
`

export const ImageText = styled.div`
  width: 37.8125rem;
  height: 100%;
  margin: 1.75rem;
  position: relative;
  filter: drop-shadow(0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12));

  @media (max-width: 62.5rem) {
    visibility: hidden;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const TextImage = styled.div`
  position: absolute;
  top: 3.375rem;
  left: 3.125rem;
  color: white;
  max-width: 20rem;
  font-size: 2.2862rem;
  font-weight: 400;
  line-height: 2.7437rem;
`

export const TextImagePrescription = styled.div`
  position: absolute;
  top: 3.5625rem;
  left: 4.3125rem;
  color: white;
  max-width: 26.875rem;
  font-size: 2.2862rem;
  font-weight: 400;
  line-height: 2.7437rem;
`

export const FooterText = styled.p`
  font-size: 0.75rem;
  color: #080038;
  text-align: center;

  > a {
    color: #f46363;
    text-decoration: none;
  }
`

export const FooterTextSuccess = styled.p`
  font-size: 0.75rem;
  max-width: 17.5rem;
  color: #8f95b2;

  > a {
    color: #f46363;
    text-decoration: none;
  }
`

export const SuccessRegistrationContainer = styled.div`
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  border-radius: 0.625rem;
  padding: 1.5625rem;
  width: 100%;
  font-size: 1.375rem;
  line-height: 1.75rem;
  max-width: 28.125rem;
  text-align: center;

  > a {
    color: inherit;
  }
`
export const BackButtonWrapper = styled.div`
  margin-bottom: 1.25rem;
`
