import { MdOutlineNotificationImportant } from 'react-icons/md'
import {
  getNumberBetweenUnderscores,
  getSuggestion,
} from '../../../../../utils/helpers'
import { SuggestionCardProps } from './props'
import { Suggestion, SuggestionIcon } from './style'

const SuggestionCard = ({
  instructionQuantity,
  selectedTimings,
  selectedDurations,
  variantTitle,
  setQuantity,
  quantity,
  takeTotalQuantity,
}: SuggestionCardProps) => {
  const suggestion = getSuggestion(
    instructionQuantity,
    selectedTimings,
    selectedDurations,
    takeTotalQuantity
  )

  if (quantity === suggestion || selectedDurations === 'as needed') return <></>

  return (
    <Suggestion>
      <SuggestionIcon>
        <MdOutlineNotificationImportant />
      </SuggestionIcon>
      <p>
        D'après vos instructions de dosage, votre client aura besoin d'au moins{' '}
        {suggestion + ' '}
        unités.
        <span onClick={() => setQuantity(suggestion)}>
          Recommander{' ' + suggestion + ' '}unités ?
        </span>
      </p>
    </Suggestion>
  )
}

export default SuggestionCard
