import { View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import { getSuggestionSentence } from '../../../utils/helpers/functions'
import { IBookletFooterProps } from './props'

const BookletProProducts = ({
  product,
  prescriberFirstName,
  prescriberLastName,
  discountPercentage,
}: IBookletFooterProps) => {
  const variantPrice = product?.takeInfo?.product?.variantPrice
  const discountedPrice =
    variantPrice - (variantPrice * discountPercentage) / 100

  const quantity = product?.takeInfo?.quantity

  const instructionDuration = product?.takeInfo?.instructionDuration
  const instructionQuantity = product?.takeInfo?.instructionQuantity
  const instructionTiming = product?.takeInfo?.instructionTiming
  const takeFormat = product?.product?.takeFormat

  const note = product?.takeInfo?.note

  // Render
  return (
    <View style={styles.wrapper} wrap={false}>
      <Image style={styles.productImage} src={product?.product?.coverImage} />

      <View style={styles.productDetails}>
        <Text style={styles.productName}>
          {quantity} x {product?.product?.name}
        </Text>
        <Text style={styles.prescriberInstructions}>
          Instructions de{' '}
          <Text
            style={{
              color: '#00855A',
            }}
          >
            {prescriberFirstName} {prescriberLastName}
          </Text>
          :{' '}
          {getSuggestionSentence(
            instructionQuantity,
            instructionTiming,
            instructionDuration,
            takeFormat
          )}
        </Text>
        {note && <Text style={styles.loremIpsumText}>{note}</Text>}
      </View>
      <View style={styles.pricingSection}>
        <View style={{ alignItems: 'flex-end' }}>
          {discountPercentage && (
            <Text style={styles.originalPrice}>
              {variantPrice?.toFixed(2)}€
            </Text>
          )}
          {discountPercentage && (
            <Text style={styles.discountedPrice}>
              {discountedPrice?.toFixed(2)}€ ({discountPercentage}%)
            </Text>
          )}
        </View>
        <Text style={styles.totalPrice}>
          Total pour {quantity} unité
          {quantity > 1 ? 's' : ''}: {(discountedPrice * quantity)?.toFixed(2)}€
        </Text>
      </View>
    </View>
  )
}

export default BookletProProducts

// Create styles
const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#F8F9FC',
    border: '1px solid #e7e9f4',
    borderRadius: '10px',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '10px 10px',
    margin: '0px 20px 10px 15px',
    minHeight: 'auto',
  },
  productImage: {
    width: '40px',
    height: '40px',
    borderRadius: '10px',
  },
  productDetails: {
    width: '60%',
    minHeight: 'auto',
  },
  productName: {
    fontSize: '11px',
    fontWeight: 'bold',
    marginBottom: '10px',
    width: '100%',
  },
  prescriberInstructions: {
    fontSize: '9px',
    marginBottom: '5px',
  },
  loremIpsumText: {
    fontSize: '8px',
    backgroundColor: 'white',
    padding: '5px',
    borderRadius: '3px',
  },
  pricingSection: {
    width: '20%',
    height: '100%',
    alignItems: 'flex-end',
    flexDirection: 'column',
    alignContent: 'space-between',
    justifyContent: 'space-between',
  },
  originalPrice: {
    fontSize: '9px',
    textDecoration: 'line-through',
    marginBottom: '5px',
  },
  discountedPrice: {
    fontSize: '9px',
    marginBottom: '5px',
  },
  totalPrice: {
    fontSize: '9px',
    fontWeight: 'bold',
    marginTop: 'auto',
  },
})
