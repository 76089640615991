/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PackagingType = 'PERSONNALISED' | 'ORIGINAL' | 'BOTH'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PackagingType = {
  PERSONNALISED: 'PERSONNALISED' as PackagingType,
  ORIGINAL: 'ORIGINAL' as PackagingType,
  BOTH: 'BOTH' as PackagingType,
}
