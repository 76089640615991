import { useState, useEffect } from 'react'
import {
  Container,
  Choice,
  ChoiceHeader,
  ButtonWrapper,
  Customized,
  SelectorItem,
  SelectorWrapper,
  SubscriptionInfo,
  InfoCircle,
} from './style'

import toast from 'react-hot-toast'

import { Color } from '../../../utils/constants'
import { Button, Tooltip } from '../../ui'
import {
  PrescriptionRecommendedDuration,
  PurchaseOption,
} from '../../../services/api/types'
import { useAddToCart, useCartLinesRemove } from '../../../utils/hooks'
import { removeVariantsToCart } from '../../../utils/helpers'
import { addBoxProduct, getMonthlyPrice } from '../../../utils/helpers'
import { PaymentSettingsProps } from './props'
import PricingWapper from './pricing'
import Popup from 'reactjs-popup'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { ButtonShare } from '../../../pages/prescriptions/style'

const PaymentSettings = ({
  cart,
  cartAttributesUpdate,
  updateSubscription,
  updateOneTimePurchase,
  isSubscription,
  prescription,
  checkoutUrl,
  selectDefaultDuration,
  cartAttributesUpdateLoading,
  updateCartLoading,
  displayMAndDown,
  height,
  onClose,
  goBackButton = false,
  isTakesMoreThanThreeProducts,
  isMarketPlace,
  discountCode,
  discountPercentage,
  goToCheckout,
  setSliderProductDialogOpen,
  sliderProductDialogOpen,
}: PaymentSettingsProps) => {
  // Attributes
  const boxProductId = cart?.lines?.edges?.find(i =>
    i?.node?.merchandise?.product?.handle?.includes(
      'boite-a-sachets-compliment'
    )
  )?.node?.id
  const [, setFormat] = useState('1')
  const [, setDuration] = useState('1')
  const { addToCart } = useAddToCart()
  const { cartLinesRemove } = useCartLinesRemove()

  const handleOpenDialogMoreProducts = () => {
    setSliderProductDialogOpen(true)
  }

  useEffect(() => {
    updateCartLoading === false &&
      cartAttributesUpdateLoading === false &&
      cartAttributesUpdate &&
      setFormat('')
    updateCartLoading === false &&
      cartAttributesUpdateLoading === false &&
      cartAttributesUpdate &&
      setDuration('')
  }, [updateCartLoading, cartAttributesUpdateLoading, cartAttributesUpdate])

  useEffect(() => {
    const boxProduct = cart?.lines?.edges?.find(i =>
      i?.node?.merchandise?.product?.handle.includes('boite-a-sachets')
    )
    !isMarketPlace &&
      !boxProduct &&
      isTakesMoreThanThreeProducts &&
      addBoxProduct(
        addToCart,
        cart,
        selectDefaultDuration(cart),
        getMonthlyPrice(cart)
      )
    // eslint-disable-next-line
  }, [])

  const selectPurchaseTypeDefaultValue = (type: string) => {
    if (
      isSubscription ||
      prescription?.purchaseOption === PurchaseOption.SUBSCRIPTION
    ) {
      return 'Abonnement' === type
    } else {
      return 'Achat unique' === type
    }
  }

  const selectPurchaseTypeOptions = () => {
    if (prescription?.purchaseOption === PurchaseOption.SUBSCRIPTION) {
      return (
        <SelectorItem
          onClick={() => {
            updateSubscription()
          }}
        >
          <div>
            {selectPurchaseTypeDefaultValue('Abonnement') && <span></span>}
          </div>
          <p>Abonnement</p>
        </SelectorItem>
      )
    } else if (prescription?.purchaseOption === PurchaseOption.ONE_TIME) {
      return (
        <SelectorItem
          onClick={() => {
            updateOneTimePurchase(selectDefaultDuration(cart))
          }}
        >
          <div>
            {selectPurchaseTypeDefaultValue('Achat unique') && <span></span>}
          </div>
          <p>Achat unique</p>
        </SelectorItem>
      )
    } else {
      return (
        <>
          <SelectorItem
            onClick={() => {
              updateSubscription()
            }}
          >
            <div>
              {selectPurchaseTypeDefaultValue('Abonnement') && <span></span>}
            </div>
            <p>Abonnement</p>
          </SelectorItem>
          <SelectorItem
            onClick={() => {
              updateOneTimePurchase(selectDefaultDuration(cart))
            }}
          >
            <div>
              {selectPurchaseTypeDefaultValue('Achat unique') && <span></span>}
            </div>
            <p>Achat unique</p>
          </SelectorItem>
        </>
      )
    }
  }

  // Render
  return (
    <Container displayMAndDown={displayMAndDown} height={height}>
      {!isMarketPlace && (
        <Customized>
          <Choice>
            <ChoiceHeader>
              <h3>Type d'achat</h3>
              <Tooltip
                size='l'
                label='Choississez entre un achat unique ou un abonnement mensuel sans engagement.'
              >
                <InfoCircle>
                  <AiOutlineInfoCircle />
                </InfoCircle>
              </Tooltip>
            </ChoiceHeader>

            <SelectorWrapper>{selectPurchaseTypeOptions()}</SelectorWrapper>
          </Choice>

          <Choice>
            <ChoiceHeader>
              <h3>Durée de la cure</h3>
              <Tooltip
                size='l'
                label='Votre prescripteur vous recommande de suivre cette recommandation pendant 3 mois. Pour chaque mois recommandé, vous recevrez une boite de 30 sachets quotidien contenant votre prescription.'
              >
                <InfoCircle>
                  <AiOutlineInfoCircle />
                </InfoCircle>
              </Tooltip>
            </ChoiceHeader>
            <SelectorWrapper>
              <SelectorItem
                onClick={() => {
                  setDuration('1')
                  toast.promise(
                    updateOneTimePurchase(
                      PrescriptionRecommendedDuration.ONE_MONTH
                    ),
                    {
                      loading: 'en cours...',
                      success: '',
                      error: 'Oops! Une erreur est survenue.',
                    },
                    {
                      duration: 3000,
                    }
                  )
                }}
              >
                <div>
                  {selectDefaultDuration(cart) ===
                    PrescriptionRecommendedDuration.ONE_MONTH && <span></span>}
                </div>
                <p>1mois</p>
              </SelectorItem>
              {!isSubscription && (
                <SelectorItem
                  onClick={() => {
                    setDuration('2')
                    toast.promise(
                      updateOneTimePurchase(
                        PrescriptionRecommendedDuration.TWO_MONTH
                      ),
                      {
                        loading: 'en cours...',
                        success: '',
                        error: 'Oops! Une erreur est survenue.',
                      },
                      {
                        duration: 3000,
                      }
                    )
                  }}
                >
                  <div>
                    {selectDefaultDuration(cart) ===
                      PrescriptionRecommendedDuration.TWO_MONTH && (
                      <span></span>
                    )}
                  </div>
                  <p>2mois</p>
                </SelectorItem>
              )}
              {!isSubscription && (
                <SelectorItem
                  onClick={() => {
                    setDuration('3')
                    toast.promise(
                      updateOneTimePurchase(
                        PrescriptionRecommendedDuration.THREE_MONTH
                      ),
                      {
                        loading: 'en cours...',
                        success: '',
                        error: 'Oops! Une erreur est survenue.',
                      },
                      {
                        duration: 3000,
                      }
                    )
                  }}
                >
                  <div>
                    {selectDefaultDuration(cart) ===
                      PrescriptionRecommendedDuration.THREE_MONTH && (
                      <span></span>
                    )}
                  </div>
                  <p>3mois</p>
                </SelectorItem>
              )}
            </SelectorWrapper>
            {isSubscription && (
              <SubscriptionInfo>
                <p>
                  <span>
                    <img
                      src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/redoicon.png?alt=media&token=ddeba45f-6b29-48b0-b900-dee6dcf91932'
                      alt='icon'
                    />
                  </span>
                  Votre cure se renouvelle automatiquement tous les 30 jours
                </p>
                <p>
                  <span>
                    <img
                      src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/euroicon.png?alt=media&token=abf95cfc-fc6e-43be-ad40-8182ae0e493a'
                      alt='icon'
                    />
                  </span>
                  Payer en plusieurs fois.
                </p>
                <p>
                  <span>
                    <img
                      src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/crossicon.png?alt=media&token=ec02cdee-88ea-4f34-ab47-8fde0a446c82'
                      alt='icon'
                    />
                  </span>
                  Sans engagement.Stoppez quand vous voulez.
                </p>
                <p>
                  <span>
                    <img
                      src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/file-blankicon2.png?alt=media&token=76d87fdd-fc86-4c6b-8880-65b1d82a4b4b'
                      alt='icon'
                    />
                  </span>
                  Adaptez le contenu de votre cure tous les mois.
                </p>
              </SubscriptionInfo>
            )}
          </Choice>
          <Choice>
            <ChoiceHeader>
              <h3>Recevoir ma cure</h3>

              {/* <Popup
                trigger={
                  <InfoCircle>
                    <AiOutlineInfoCircle />
                  </InfoCircle>
                }
                modal
                nested
                position='top center'
              > */}
              {/* something wrong check with Tanguy*/}
              {/*close => <BoxInfo />*/}
              {/* </Popup> */}
            </ChoiceHeader>
            <SelectorWrapper>
              {isTakesMoreThanThreeProducts && (
                <SelectorItem
                  onClick={() => {
                    setFormat('1')
                    addBoxProduct(
                      addToCart,
                      cart,
                      selectDefaultDuration(cart),
                      getMonthlyPrice(cart)
                    )
                  }}
                >
                  <div>{boxProductId && <span></span>}</div>
                  <p>En sachets</p>
                </SelectorItem>
              )}
              <SelectorItem
                onClick={() => {
                  setFormat('2')
                  removeVariantsToCart(
                    cartLinesRemove,
                    [boxProductId],
                    cart?.id
                  )
                }}
              >
                <div>{!boxProductId && <span></span>}</div>
                <p>En pots</p>
              </SelectorItem>
            </SelectorWrapper>
          </Choice>
        </Customized>
      )}

      <PricingWapper
        cart={cart}
        selectDefaultDuration={selectDefaultDuration}
        isSubscription={isSubscription}
        discountPercentage={discountPercentage}
        discountCode={discountCode}
        boxProductId={boxProductId}
        isTakesMoreThanThreeProducts={isTakesMoreThanThreeProducts}
        isMarketPlace={isMarketPlace}
      />

      <ButtonWrapper>
        <ButtonShare
          appearance='primary'
          iconLeft={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
          style={{ width: '100%', marginBottom: '0.625rem' }}
          onClick={handleOpenDialogMoreProducts}
        >
          Ajouter un produit
        </ButtonShare>
        {cart?.checkoutUrl && goToCheckout ? (
          <Button
            style={{ width: '100%' }}
            onClick={goToCheckout}
            appearance='primary'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            Continuer vers le paiement
          </Button>
        ) : (
          <Button
            style={{ width: '100%' }}
            as='a'
            href={`${checkoutUrl}`}
            appearance='primary'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            Continuer vers le paiement
          </Button>
        )}

        <div style={{ marginBottom: '0.625rem' }}></div>
        {goBackButton && (
          <Button
            onClick={onClose}
            style={{ border: 'none', color: Color.DEEP_SEA, width: '100%' }}
            appearance={undefined}
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            Retourner sur la cure
          </Button>
        )}
      </ButtonWrapper>
    </Container>
  )
}

export default PaymentSettings
