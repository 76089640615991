import styled from 'styled-components/macro'

import { ViewMode, MediaQuery } from '../../utils/constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0 1.25rem;
  min-height: calc(100vh - 4.6875rem);
  ${MediaQuery.M_AND_DOWN} {
    padding: 0rem;
  }
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
`
export const Wrapper = styled.div`
  display: flex;
  ${MediaQuery.L_AND_DOWN} {
    flex-direction: column;
    gap: 1.5rem;
  }
  justify-content: space-between;
  width: 100%;
`

export const ProductsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'row' : 'column'};
  padding: 1.25rem 0;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 2.5rem 0 1.25rem 0;
  position: sticky;
  top: 5.25rem;
  background-color: white;
  padding: 1.25rem;
  z-index: 1;
  border-radius: 0.875rem;
  flex-direction: column;
  box-shadow: 0rem 0rem 0.0625rem rgba(40, 41, 61, 0.08),
    0rem 0.0313rem 0.125rem rgba(96, 97, 112, 0.16);
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const InputContainer = styled.div`
  width: 15.625rem;
  max-width: 15.625rem;
`
export const ButtonWrapper = styled.div`
  justify-content: flex-end;
  padding-left: 0.125rem;
  width: 18.75rem;
  max-width: 18.75rem;
`

export const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${MediaQuery.L_AND_DOWN} {
    flex-direction: column;
  }
`
