import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 54px 0;
  background-color: #fdfcfe;
`

export const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`
