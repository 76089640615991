/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type {
  ProductPagingDTO,
  GetProductsParams,
  ProductDTO,
  GetProductByIdOrHandleParams,
  ProductBenefitPagingDTO,
  GetAllProductBenefitsByProductIdParams,
  ProductLabelPagingDTO,
  GetAllProductLabelsByProductIdParams,
  ProductFormulationPagingDTO,
  GetAllProductFormulationsByProductIdParams,
  ProductTraceabilityPagingDTO,
  GetAllProductTraceabilitiesByProductIdParams,
} from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getProducts = (
  params?: GetProductsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductPagingDTO>(
    { url: `/products`, method: 'get', params },
    options
  )
}

export const getGetProductsQueryKey = (params?: GetProductsParams) => [
  `/products`,
  ...(params ? [params] : []),
]

export const useGetProducts = <
  TData = AsyncReturnType<typeof getProducts>,
  TError = unknown
>(
  params?: GetProductsParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getProducts>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsQueryKey(params)

  const queryFn: QueryFunction<AsyncReturnType<typeof getProducts>> = () =>
    getProducts(params, requestOptions)

  const query = useQuery<AsyncReturnType<typeof getProducts>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  )

  return {
    queryKey,
    ...query,
  }
}

export const getProductByIdOrHandle = (
  productId: string,
  params?: GetProductByIdOrHandleParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductDTO>(
    { url: `/products/${productId}`, method: 'get', params },
    options
  )
}

export const getGetProductByIdOrHandleQueryKey = (
  productId: string,
  params?: GetProductByIdOrHandleParams
) => [`/products/${productId}`, ...(params ? [params] : [])]

export const useGetProductByIdOrHandle = <
  TData = AsyncReturnType<typeof getProductByIdOrHandle>,
  TError = unknown
>(
  productId: string,
  params?: GetProductByIdOrHandleParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getProductByIdOrHandle>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetProductByIdOrHandleQueryKey(productId, params)

  const queryFn: QueryFunction<AsyncReturnType<typeof getProductByIdOrHandle>> =
    () => getProductByIdOrHandle(productId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getProductByIdOrHandle>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getAllProductBenefitsByProductId = (
  productId: string,
  params?: GetAllProductBenefitsByProductIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductBenefitPagingDTO>(
    { url: `/products/${productId}/benefits`, method: 'get', params },
    options
  )
}

export const getGetAllProductBenefitsByProductIdQueryKey = (
  productId: string,
  params?: GetAllProductBenefitsByProductIdParams
) => [`/products/${productId}/benefits`, ...(params ? [params] : [])]

export const useGetAllProductBenefitsByProductId = <
  TData = AsyncReturnType<typeof getAllProductBenefitsByProductId>,
  TError = unknown
>(
  productId: string,
  params?: GetAllProductBenefitsByProductIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllProductBenefitsByProductId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllProductBenefitsByProductIdQueryKey(productId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllProductBenefitsByProductId>
  > = () => getAllProductBenefitsByProductId(productId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getAllProductBenefitsByProductId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getAllProductLabelsByProductId = (
  productId: string,
  params?: GetAllProductLabelsByProductIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductLabelPagingDTO>(
    { url: `/products/${productId}/labels`, method: 'get', params },
    options
  )
}

export const getGetAllProductLabelsByProductIdQueryKey = (
  productId: string,
  params?: GetAllProductLabelsByProductIdParams
) => [`/products/${productId}/labels`, ...(params ? [params] : [])]

export const useGetAllProductLabelsByProductId = <
  TData = AsyncReturnType<typeof getAllProductLabelsByProductId>,
  TError = unknown
>(
  productId: string,
  params?: GetAllProductLabelsByProductIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllProductLabelsByProductId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllProductLabelsByProductIdQueryKey(productId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllProductLabelsByProductId>
  > = () => getAllProductLabelsByProductId(productId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getAllProductLabelsByProductId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getAllProductFormulationsByProductId = (
  productId: string,
  params?: GetAllProductFormulationsByProductIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductFormulationPagingDTO>(
    { url: `/products/${productId}/formulations`, method: 'get', params },
    options
  )
}

export const getGetAllProductFormulationsByProductIdQueryKey = (
  productId: string,
  params?: GetAllProductFormulationsByProductIdParams
) => [`/products/${productId}/formulations`, ...(params ? [params] : [])]

export const useGetAllProductFormulationsByProductId = <
  TData = AsyncReturnType<typeof getAllProductFormulationsByProductId>,
  TError = unknown
>(
  productId: string,
  params?: GetAllProductFormulationsByProductIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllProductFormulationsByProductId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllProductFormulationsByProductIdQueryKey(productId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllProductFormulationsByProductId>
  > = () =>
    getAllProductFormulationsByProductId(productId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getAllProductFormulationsByProductId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getAllProductTraceabilitiesByProductId = (
  productId: string,
  params?: GetAllProductTraceabilitiesByProductIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductTraceabilityPagingDTO>(
    { url: `/products/${productId}/traceabilities`, method: 'get', params },
    options
  )
}

export const getGetAllProductTraceabilitiesByProductIdQueryKey = (
  productId: string,
  params?: GetAllProductTraceabilitiesByProductIdParams
) => [`/products/${productId}/traceabilities`, ...(params ? [params] : [])]

export const useGetAllProductTraceabilitiesByProductId = <
  TData = AsyncReturnType<typeof getAllProductTraceabilitiesByProductId>,
  TError = unknown
>(
  productId: string,
  params?: GetAllProductTraceabilitiesByProductIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllProductTraceabilitiesByProductId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllProductTraceabilitiesByProductIdQueryKey(productId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllProductTraceabilitiesByProductId>
  > = () =>
    getAllProductTraceabilitiesByProductId(productId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getAllProductTraceabilitiesByProductId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}
