import styled from 'styled-components/macro'
import { Color } from '../../../../utils/constants'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const AttachFile = styled.button`
  margin: 20px 0;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 17px;
  cursor: pointer;
  span {
    margin-right: 10px;
    font-size: 18px;
    background-color: ${Color.DEEP_SEA};
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  p {
  }
`

export const FileList = styled.div`
  margin-bottom: 20px;
`

export const File = styled.div`
  display: flex;
  align-items: center;
  p {
  }
  span {
    margin-left: 10px;
    font-size: 10px;
    margin-top: 4px;
  }
`
