import { useState } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout/patient-public'
import PageTitle from '../../components/section/page-title'
import { Container, Content, PageTitleWrapper } from './style'
import { useOnboardingProfileCheck } from '../../utils/hooks'
import { ViewMode } from '../../utils/constants'
import Discount from './discount-message'
import UrlProductDialog from '../../components/product/url-product-dialog'
import PatientOnboarding from '../../components/patient-catalog-onboarding/modal'
import Modal from './access-modal'
import Products from '../../components/products-v2'

const PatientProductsPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isPatientOnboardingOpen, setIsPatientOnboardingOpen] = useState(false)

  return (
    <Layout>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Catalogue</title>
      </Helmet>
      <Container>
        <PageTitle
          title={
            <PageTitleWrapper>
              <p>Découvrez la gamme de produits</p>
              <Discount />
            </PageTitleWrapper>
          }
        />
        <Content>
          <Products
            viewModeProduct={ViewMode.GRID}
            from='productPage'
            enableFavorite={false}
            enableAddToCart={true}
            isPatientCatalog
            allowProductUrl={true}
          />
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setIsPatientOnboardingOpen={setIsPatientOnboardingOpen}
          />
        </Content>
      </Container>
      <UrlProductDialog />
      <PatientOnboarding
        open={isPatientOnboardingOpen}
        setOpen={setIsPatientOnboardingOpen}
      />
    </Layout>
  )
}

export default PatientProductsPage
