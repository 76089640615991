import { useState } from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import {
  Form,
  MoreInfo,
  MoreInfoTitle,
  MoreInfoArrow,
  Email,
  EmailWrapper,
  EmailLabel,
  InfoCircle,
  PhoneInputLabel,
} from './style'
import { Button, Field, FieldGroup, PhoneInput, Tooltip } from '../../ui'
import { PatientSexe } from '../../../services/api/types'
import { Collapse } from 'react-collapse'
import { IoIosArrowDown } from 'react-icons/io'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const PatientSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Champs obligatoire'),
  firstName: Yup.string(),
  lastName: Yup.string(),
  phone: Yup.string(),
})

const CreatePatientForm = ({ onSubmit, isLoading, patient = null }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        email: patient ? patient?.email : '',
        firstName: patient ? patient?.firstName : ' ',
        lastName: patient ? patient?.lastName : ' ',
        phone: patient ? patient?.phone : ' ',
        age: patient ? patient?.age : ' ',
        sexe: patient ? patient?.sexe : '',
        pathology: patient ? patient?.pathology : '',
      }}
      validationSchema={PatientSchema}
      onSubmit={values => {
        onSubmit(values)
      }}
    >
      {props => (
        <Form onSubmit={props.handleSubmit}>
          {patient ? (
            <EmailWrapper>
              <EmailLabel>Email</EmailLabel>
              <Email>{props.values.email}</Email>
            </EmailWrapper>
          ) : (
            <Field
              placeholder='john.doe@email.com'
              label='Email'
              name='email'
              id='login-email'
              type='email'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.email}
              error={props.errors.email}
              touched={props.touched.email}
            />
          )}

          <PhoneInput
            id='phone'
            label={
              <PhoneInputLabel>
                <p>Téléphone</p>
                <Tooltip
                  size='l'
                  label='En ajoutant le numéro de téléphone de votre client, nous enverrons la recommandation également par SMS afin de maximiser les taux d’ouvertures.'
                >
                  <InfoCircle>
                    <AiOutlineInfoCircle />
                  </InfoCircle>
                </Tooltip>
              </PhoneInputLabel>
            }
            name='phone'
            value={props.values.phone}
            onChange={props.handleChange}
            error={props.errors.phone}
            touched={props.touched.phone}
            isRequired={false}
            info='Une recommandation envoyée également par SMS se convertit 2 fois plus souvent.'
          />

          <FieldGroup>
            <Field
              placeholder='John'
              label='Prénom'
              id='first-name'
              name='firstName'
              type='text'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.firstName}
              error={props.errors.firstName}
              touched={props.touched.firstName}
            />
            <Field
              placeholder='Doe'
              label='Nom de famille'
              id='last-name'
              name='lastName'
              type='text'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.lastName}
              error={props.errors.lastName}
              touched={props.touched.lastName}
            />
          </FieldGroup>

          <MoreInfo>
            <MoreInfoTitle onClick={() => setIsOpen(!isOpen)}>
              <p>Plus d'informations</p>
              <MoreInfoArrow isOpen={isOpen}>
                <IoIosArrowDown />
              </MoreInfoArrow>
            </MoreInfoTitle>
            <Collapse
              isOpened={isOpen}
              style={{
                width: '100%',
              }}
            >
              <FieldGroup>
                <Field
                  placeholder="L'age du client"
                  label='Age'
                  id='age'
                  name='age'
                  type='number'
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.age}
                  error={props.errors.age}
                  touched={props.touched.age}
                />
                <Field
                  placeholder='Homme ou Femme'
                  label='Sexe'
                  id='sexe'
                  name='sexe'
                  type='select'
                  options={[
                    { label: 'Homme', value: PatientSexe.MAN },
                    { label: 'Femme', value: PatientSexe.WOMAN },
                  ]}
                  onChange={e =>
                    props.handleChange({
                      target: { value: e.value, name: 'sexe' },
                    })
                  }
                  onBlur={props.handleBlur}
                  value={
                    props.values.sexe && {
                      label:
                        props.values.sexe === PatientSexe.MAN
                          ? 'Homme'
                          : 'Femme',
                      value: props.values.sexe,
                    }
                  }
                  error={props.errors.sexe}
                  touched={props.touched.sexe}
                />
              </FieldGroup>
              <Field
                placeholder='Notes'
                label='Notes'
                id='pathology'
                name='pathology'
                type='textarea'
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.pathology}
                error={props.errors.pathology}
                touched={props.touched.pathology}
              />
            </Collapse>
          </MoreInfo>

          <Button
            type='submit'
            isLoading={isLoading}
            isDisabled={
              !props.values.email &&
              !props.values.firstName &&
              !props.values.lastName &&
              !props.values.phone
            }
          >
            Enregistrer
          </Button>
        </Form>
      )}
    </Formik>
  )
}

CreatePatientForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

export default CreatePatientForm
