/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { AllergenForProductDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getAllAllergensForProduct = (
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AllergenForProductDTO[]>(
    { url: `/product-allergens`, method: 'get' },
    options
  )
}

export const getGetAllAllergensForProductQueryKey = () => [`/product-allergens`]

export const useGetAllAllergensForProduct = <
  TData = AsyncReturnType<typeof getAllAllergensForProduct>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<typeof getAllAllergensForProduct>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetAllAllergensForProductQueryKey()

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllAllergensForProduct>
  > = () => getAllAllergensForProduct(requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getAllAllergensForProduct>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query,
  }
}
