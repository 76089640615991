import { useState } from 'react'
import {
  Container,
  Title,
  List,
  Item,
  ItemTitleWrapper,
  ItemTitle,
  ItemDescription,
  MoreButton,
  MoreDescription,
  SeeMore,
} from './style'
// import { Dialog } from '../../../../ui'
import { StudiesProps } from './props'
import { HorizontalSeparator } from '../../left-side/style'
import { useGetClinicalStudiesByProductId } from '../../../../../services/api/clinical-studies/clinical-studies'
import { ProductClinicalStudyDTO } from '../../../../../services/api/types'

const Studies = ({ product }: StudiesProps) => {
  // Attibutes
  const { data: clinicalStudiesByProductId } = useGetClinicalStudiesByProductId(
    product?.id,
    {
      query: { enabled: !!product?.id },
    }
  )

  // Render
  return (
    !!clinicalStudiesByProductId?.length && (
      <Container>
        <Title>Etudes cliniques</Title>
        <HorizontalSeparator />
        <List>
          {clinicalStudiesByProductId?.map(clinicalStudie => {
            return <Studie clinicalStudie={clinicalStudie} />
          })}
        </List>
      </Container>
    )
  )
}

export default Studies

const Studie = ({
  clinicalStudie,
}: {
  clinicalStudie: ProductClinicalStudyDTO
}) => {
  // Attributes
  const minLengthStringTitle = 100
  const minLengthStringDescription = 100

  const [maxLengthStringTitle, setMaxLengthStringTitle] =
    useState(minLengthStringTitle)
  const [maxLengthStringDescription, setMaxLengthStringDescription] = useState(
    minLengthStringDescription
  )

  // Render
  return (
    <Item key={clinicalStudie?.id}>
      <ItemTitleWrapper>
        <ItemTitle>
          {clinicalStudie?.title?.slice(0, maxLengthStringTitle)}
          {clinicalStudie?.title?.length > maxLengthStringTitle ? '...' : ''}
          {clinicalStudie?.title?.length > minLengthStringTitle ? (
            <>
              {clinicalStudie?.title?.length > maxLengthStringTitle ? (
                <SeeMore onClick={() => setMaxLengthStringTitle(1000)}>
                  Voir plus
                </SeeMore>
              ) : (
                <SeeMore
                  onClick={() => setMaxLengthStringTitle(minLengthStringTitle)}
                >
                  Voir moins
                </SeeMore>
              )}
            </>
          ) : (
            ''
          )}
        </ItemTitle>
        <MoreDescription>{clinicalStudie?.moreDescription}</MoreDescription>
      </ItemTitleWrapper>
      <ItemDescription>
        {clinicalStudie?.description?.slice(0, maxLengthStringDescription)}
        {clinicalStudie?.description?.length > maxLengthStringDescription
          ? '...'
          : ''}
        {clinicalStudie?.description?.length > minLengthStringDescription ? (
          <>
            {clinicalStudie?.description?.length >
            maxLengthStringDescription ? (
              <SeeMore onClick={() => setMaxLengthStringDescription(1000)}>
                Voir plus
              </SeeMore>
            ) : (
              <SeeMore
                onClick={() =>
                  setMaxLengthStringDescription(minLengthStringDescription)
                }
              >
                Voir moins
              </SeeMore>
            )}
          </>
        ) : (
          ''
        )}
      </ItemDescription>
      <MoreButton href={clinicalStudie?.link} target='_blank'>
        Lire l'étude
      </MoreButton>
    </Item>
  )
}
