import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Label,
  Description,
  ControlContainer,
  EditForm,
  Value,
  ElementGroup,
} from './style'
import { Icon, Input, Button, Tooltip } from '../../../ui'
import DiscountCode from '../../../prescriber/discount-code'
import {
  PatientLink,
  LinkWrapper,
  IconWrapper,
} from '../../../share-access-dialog/style'
import { FaRegCopy, FaCheck } from 'react-icons/fa'

const ProfileItem = ({
  defaultValue,
  description,
  isEditing,
  isLoading,
  label,
  onSubmit,
  openEdit,
  valueSuffix,
  selectValue,
  setSelectedValue,
  type,
  firstName,
  lastName,
  activeDiscount = '',
  activeDiscountPercentage = undefined,
}) => {
  const [value, setValue] = useState('')
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const inputRef = useRef(null)

  const textToCopy = defaultValue
  // Functions
  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(textToCopy)
    setIsLinkCopied(true)
    setTimeout(() => setIsLinkCopied(false), 3000)
  }

  useEffect(() => {
    isEditing && !type === 'select' && inputRef.current.focus()
  }, [isEditing, type])

  return (
    <ElementGroup>
      <Label>{label}</Label>
      {isEditing ? (
        <EditForm>
          {type === 'select' ? (
            <DiscountCode
              selectValue={selectValue ?? 0}
              setSelectedValue={setSelectedValue}
              isEditable={true}
              activeDiscount={activeDiscount}
            />
          ) : (
            <Input
              value={value}
              onChange={e => setValue(e.target.value)}
              ref={inputRef}
              isDisabled={isLoading}
              inputContainerStyling={{ margin: '10px 0' }}
            />
          )}
          <Button
            onClick={() => onSubmit(value)}
            isLoading={isLoading}
            style={{ margin: '10px', maxHeight: '50px' }}
          >
            Enregistrer
          </Button>
        </EditForm>
      ) : (
        <Value>
          <LinkWrapper>
            <PatientLink>{defaultValue}</PatientLink>
            <Tooltip
              size='l'
              label={isLinkCopied ? `Copié !` : 'Copier dans le presse papier'}
            >
              <Button
                appearance='minimal'
                onClick={handleCopyToClipBoard}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                <IconWrapper>
                  {' '}
                  {isLinkCopied ? <FaCheck /> : <FaRegCopy />}
                </IconWrapper>
                copier
              </Button>
            </Tooltip>
          </LinkWrapper>

          {openEdit && (
            <ControlContainer>
              <Icon
                name='pencil'
                onClick={() => {
                  openEdit()
                  setValue(defaultValue)
                }}
              />
            </ControlContainer>
          )}
        </Value>
      )}
      <Description>{description}</Description>
    </ElementGroup>
  )
}

ProfileItem.defaultProps = {
  valueSuffix: '',
}

ProfileItem.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  openEdit: PropTypes.func,
  valueSuffix: PropTypes.string,
}

export default ProfileItem
