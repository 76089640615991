export enum HeaderLinks {
  'Patients' = 'Clients',
  'Recommandations' = 'Recommandations',
  'Questionnaires' = 'Questionnaires',
  'Catalogue' = 'Catalogue',
  'Protocoles' = 'Protocoles',
  'Points' = 'Points',
  'Academy' = 'Academy',
  'Notes' = 'Notes',
}
