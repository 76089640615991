import { ShopifyService } from './shopify.service'

export namespace ProductService {
  export interface Single {
    title: string
    handle: string
    description: string
  }

  export async function getSingle(productHandle: string): Promise<Single> {
    const { productByHandle } = await ShopifyService.getProductSingle({
      handle: productHandle,
    })
    if (!productByHandle) throw new Error('Product not found')

    const { title, handle, description } = productByHandle

    const product: Single = {
      title: title,
      handle: handle,
      description: description,
    }

    return product
  }
}
