import { Link } from 'react-router-dom'

import { Header, Nav, Ul, Li, Button, BackIcon } from './style'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const HeaderEl = ({ onClick, backLink }) => {
  return (
    <Header>
      <Link to={backLink}>
        <BackIcon>
          <AiOutlineArrowLeft />
        </BackIcon>
      </Link>
      <Nav>
        <Ul>
          <Li>
            <Button onClick={onClick}>Sauvegarder</Button>
          </Li>
        </Ul>
      </Nav>
    </Header>
  )
}

export default HeaderEl
