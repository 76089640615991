import styled from 'styled-components/macro'

export const Content = styled.div`
  max-width: 95%;
  width: 100%;
  margin: 0px auto;
`

export const Header = styled.div`
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: 68.8125rem) {
    margin-top: 2rem;
    margin-bottom: 2.25rem;
    padding: 0rem 3.125rem;
  }
`
export const Wrapper = styled.div`
  padding: 0rem 3.125rem;
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  @media (min-width: 68.8125rem) {
    color: var(--Text-500, #080038);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'basic-grotesque-pro', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`
export const Title = styled.h1`
  color: var(--Text-400, #393360);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  padding-top: 2rem;
`

export const SubTitle = styled.h1`
  margin-top: 0.75rem;
  color: var(--Text-300, #6b6688);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
