import styled from 'styled-components/macro'

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.625rem 0;
  width: 100%;
  > label {
    margin-bottom: 0.625rem;
  }
`

export const ErrorContainer = styled.span`
  color: #f56c6c;
  font-size: 0.75rem;
  padding-top: 0.3125rem;
`
