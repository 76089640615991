import styled from 'styled-components/macro'

export const ActionSection = styled.section`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  padding: 20px 0;
  border-top: 1px solid #ddd;
  background-color: #fff;
`
