import styled from 'styled-components'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  width: 100%;
  background: radial-gradient(56.5% 56.5% at 50% 50%, #479b70 0%, #3a845e 100%);
  a {
    color: inherit;
    text-decoration: none;
  }
`
export const Wrapper = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding: 80px 40px;
  ${MediaQuery.XL_AND_DOWN} {
    width: 100%;
  }
  ${MediaQuery.L_AND_DOWN} {
    padding: 40px 20px;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  color: white;

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 80px;
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 15px;
  }
  h5 {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 15px;
  }
  a {
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    width: 300px;
  }
`
export const InputBox = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: white;
  input::placeholder {
    color: white;
  }
  input,
  ::placeholder {
    color: white;
    border: 1px solid white;
    background-color: transparent;
    padding: 10px 15px;
    border-radius: 30px;
    margin-right: 10px;
    outline: none;
  }
  button {
    color: white;
    border: 1px solid white;
    background-color: transparent;
    padding: 10px 15px;
    border-radius: 30px;
    cursor: pointer;
  }
`

export const SocialWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const SocialItem = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid white;
`

export const BottomFooter = styled.div`
  display: flex;
  margin-top: 40px;
  padding-top: 40px;
  color: white;
  border-top: 1px solid white;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`
export const BottomFooterRight = styled.div`
  font-size: 13px;
`
export const BottomFooterRightTitle = styled.h3`
  font-size: 13px;
  margin-bottom: 20px;
`

export const BottomFooterLeft = styled.div`
  font-size: 10px;
  margin-right: 80px;
  ${MediaQuery.M_AND_DOWN} {
    margin-right: 0;
    margin-bottom: 20px;
  }
`

export const LogosWrapper = styled.div`
  display: flex;
`

export const LogoWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 30px;
  width: 50px;
  margin-right: 10px;
  img {
    width: 40%;
    object-fit: contain;
  }
`
