import styled from 'styled-components/macro'

export const Container = styled.div`
  border: 1px solid #e7e9f4;
  border-radius: 12px;
  padding: 24px 24px 28px 24px;
  background: #f8f9fc;
  margin-top: 35px;
`

export const Header = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
`

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  border-radius: 8px;
  border: 1px solid var(--Stroke, #00855a);
  background: var(--White-500, #fff);
  font-size: 12px;
  p {
    color: #3a845e;
  }
`
