import styled from 'styled-components/macro'

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 272px;
  min-height: 606px;
  border: 1px solid #e7e9f4;
  border-radius: 12px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 12px;
  border-bottom: 1px solid #e7e9f4;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #080038;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 8px;
  gap: 4px;
  width: 272px;
  min-height: 284px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`

export const CategoryTitle = styled.div`
  cursor: pointer;
  display: flex;
  padding: 12px 8px 12px 12px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #080038;
  background-color: ${props => (props.currentFavoriteListId ? '#e0f0e9' : '')};
`

export const New = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px 12px 8px;
  gap: 4px;
  width: 256px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #3a845e;
`

export const NewPlus = styled.p``
export const NewText = styled.p``
