import styled from 'styled-components/macro'

export const Category = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 12px 8px 12px 12px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #080038;
  background-color: ${props => (props.currentFavoriteListId ? '#e0f0e9' : '')};
`

export const CategoryName = styled.p``

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`
export const MenuContainerItem = styled.div`
  cursor: pointer;
  font-size: 12px;
  padding: 5px 10px;
`

export const GoKebabVerticalWrapper = styled.div`
  padding: 5px;
`
