import styled from 'styled-components/macro'

export const Container = styled.div`
  background: #e0f0e9 0% 0% no-repeat padding-box;
  border: 1px solid #3a835f;
  border-radius: 8px;
  opacity: 1;
  color: #3a835f;
  opacity: 1;
  padding: 10px 20px;
  display: flex;
  margin: 10px 0;
`

export const Icon = styled.span`
  font-size: 20px;
  margin-right: 5px;
`

export const P = styled.p`
  font-size: 12px;
`
