import Layout from '../../../components/layout/note'
import { toast } from 'react-toastify'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'

import { Container, NoteTakingWrapper, PatientInfoWrapper } from './style'

import { useCreatePatientNote } from '../../../services/api/notes/notes'
import { NoteState } from '../props'

import CreateUpdateNote from '../../../components/notes/create-update-note'
import PatientInfo from '../../../components/notes/patient-info'

import { useImmer } from 'use-immer'

const NotesPage = ({ location, history }) => {
  // Attributes
  const { patientId: urlPatientId } = queryString.parse(location.search)
  const { backUrl: urlBackUrl } = queryString.parse(location.search)
  const [note, setNotes] = useImmer<NoteState>({
    title: '',
    description: '',
    consultationDate: new Date(),
    patientId: urlPatientId ? Number(urlPatientId) : null,
  })
  const { mutateAsync: createPatientNote } = useCreatePatientNote({
    mutation: {
      onSuccess: () => {
        toast('La note a bien été créée')
        history.push('/notes')
      },
      onError: () => {
        toast.error('Oops! Une erreur est survenue.')
      },
    },
  })

  //Function
  const createtNote = () => {
    if (!note?.patientId) {
      toast.error('Veuillez selectionner un client')
      return
    }
    createPatientNote({
      patientId: note?.patientId,
      data: {
        title: note?.title,
        description: note?.description,
        consultedAt: note?.consultationDate?.toISOString(),
      },
    })
  }

  // Render
  return (
    <Layout backLink={urlBackUrl as string} onClick={createtNote}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Nouvelle note </title>
      </Helmet>
      <Container>
        <NoteTakingWrapper>
          <CreateUpdateNote
            title={note.title}
            description={note.description}
            consultationDate={note.consultationDate}
            patientId={note?.patientId}
            setNotes={setNotes}
          />
        </NoteTakingWrapper>
        <PatientInfoWrapper>
          {note?.patientId && <PatientInfo patientId={note?.patientId} />}
        </PatientInfoWrapper>
      </Container>
    </Layout>
  )
}

export default NotesPage
