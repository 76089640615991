import styled from 'styled-components/macro'
export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.25rem 1.5rem;
  gap: 0.625rem;
  border-bottom: 1px solid var(--Stroke, #e9eaf0);
  background: var(--Surfaces-Fill-surface-fill-white, #fff);
`
export const ArrowBack = styled.div`
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.4167rem;
  align-items: flex-start;
  border-radius: 0.25rem;
  background: var(--Compliment-Dark-Green-100, #d8e6df);
  cursor: pointer;
`

export const ImageLogo = styled.img`
  margin-top: 0.1875rem;
  width: 6.3125rem;
  height: 1.25rem;
  object-fit: contain;
  object-position: center center;
  cursor: pointer;
`
