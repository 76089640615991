import { Dialog } from '../ui'
import { IconCross } from '../ui/icon/icons'
import { BlockAccessModalProps } from './props'
import {
  CloseButton,
  CloseButtonContainer,
  Container,
  ContentContainer,
  Section,
  SectionTitle,
} from './style'
import { CiCircleAlert } from 'react-icons/ci'
import { InlineWidget } from 'react-calendly'

const TabDialog = ({ isOpen, setIsOpen, title }: BlockAccessModalProps) => {
  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      type='defaultCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={() => setIsOpen(false)}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <ContentContainer>
          <Section>
            <span>
              <CiCircleAlert />
            </span>
            <SectionTitle>
              {title
                ? title
                : `Cette fonctionnalité n’est accessible que si votre compte est
              vérifié. Pour vérifier votre compte, veuillez prendre RDV avec
              notre équipe :`}
            </SectionTitle>
          </Section>
        </ContentContainer>

        <InlineWidget url='https://share-eu1.hsforms.com/1xyWe3pUVTT6EZMmx4JTqGwf2dsm' />
      </Container>
    </Dialog>
  )
}

export default TabDialog
