import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import NakedLayout from '../../components/layout/naked'
import LoginForm from '../../components/auth/login-form'
import {
  Wrapper,
  FooterText,
  ImageLogo,
  Subtitle,
  ImageText,
  Image,
  TextImage,
  BackButtonWrapper,
} from './style'
import { BackButton } from '../../components/ui'
import { useHistory } from 'react-router'

const LoginPage = () => {
  //Attributes
  const history = useHistory()
  //Functions
  function handleBackButton() {
    history.push('/portail')
  }
  //Return
  return (
    <NakedLayout>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Login </title>
      </Helmet>
      <Wrapper>
        <BackButtonWrapper>
          <BackButton onClick={handleBackButton} />
        </BackButtonWrapper>
        <ImageLogo
          src={'/assets/images/logo-compliment.png'}
          alt='logo-image'
        />
        <Subtitle>Connectez-vous à votre compte professionnel !</Subtitle>
        <LoginForm />
        <FooterText>
          Pas encore de compte ?{' '}
          <Link
            to={{
              pathname: '/register',
            }}
          >
            Créer un compte
          </Link>
        </FooterText>
      </Wrapper>
      <ImageText>
        <Image
          src={'/assets/images/image-easy-prescription.png'}
          alt='image-easy-presciption'
        />
        <TextImage>
          Facilitez votre recommandation, offrez une expérience client unique.
        </TextImage>
      </ImageText>
    </NakedLayout>
  )
}

export default LoginPage
