import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Icon = styled.div`
  width: ${({ width }) => (width ? width : '14px')};
  height: ${({ height }) => (height ? height : '14px')};
  position: relative;

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: '${({ color }) => color || Color.MINE_SHAFT}';
    stroke: '${({ stroke }) => stroke || Color.MINE_SHAFT}';
  }
`
