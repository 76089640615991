import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const QuantityFlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column-reverse;

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 0.75rem;
  }
`

export const Price = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 1.25rem;
  font-weight: 500;
  font-family: 'Poppins';
  color: black;
  margin-top: 0.75rem;
  align-items: center;
  span {
    font-size: 0.875rem;
    color: #3a845e;
    font-weight: 500;
  }

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: row;
    font-size: 1.125rem;
    align-items: center;
  }
`

export const QuantityButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #3a835f;
  padding: 0.625rem 0.9375rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  font-size: 0.625rem;
  color: white;

  ${MediaQuery.M_AND_DOWN} {
    border-radius: 0rem;
    border: 0rem;
    margin-top: 0rem;
    width: 100%;
    font-size: 0.75rem;
    justify-content: center;
  }
`

export const HandleQuantityFlexWrapper = styled.div`
  margin-top: 0.625rem;
  display: flex;
  border-radius: 0.125rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  font-size: 0.75rem;
  height: 2.125rem;
  margin-bottom: 0rem;
  border: 1px solid #e9eaf0;
  background-color: white;
  width: 9.375rem;
  justify-content: space-between;
  margin-right: 0.9375rem;

  ${MediaQuery.M_AND_DOWN} {
    margin: 0;
    border-radius: 0rem;
    border: 0rem;
    height: 1.75rem;
    margin-top: 0rem;
    width: 100%;
  }
`
