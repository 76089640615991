import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  width: 49%;
  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
    margin-bottom: 40px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
`

export const Button = styled.button`
  padding: 8px;
  border: none;
  background-color: transparent;
  font-size: 12px;
  cursor: pointer;
  border-bottom: ${({ selected }) => (selected ? '2px solid #65b293' : 'none')};
`
