import { fr } from '../../locales'
import { ProductHandle } from '../constants'

function getVariantTitleByRecommendedDuration(recommendedDuration) {
  return {
    '1-month': '1month_onetime',
    '2-month': '2month_onetime',
    '3-month': '3month_onetime',
    '1-month-subscription': '1month_subscription',
    '2-month-subscription': '2month_subscription',
    '3-month-subscription': '3month_subscription',
  }[recommendedDuration]
}

/**
 * Return is the product is part of a cure or not
 *
 * @param {String} handle Product handle
 * @return {Boolean}
 */
function getIsStandAloneProduct(handle) {
  return fr.products[handle]?.standAlone || false
}

function getPillPicture(handle) {
  let url = ''
  try {
    url =
      process.env.REACT_APP_AWS_BUCKET +
      `assets/images/products/${handle}-detoured.png`
  } catch (e) {
    url =
      process.env.REACT_APP_AWS_BUCKET + `assets/images/products/fallback.svg`
  }
  return url
}

class Product {
  data = {}

  constructor(newData) {
    this.data = newData
  }

  get handle() {
    return this.data?.shopify_product_handle
  }

  get name() {
    return this.data?.shopify_product?.title
  }

  get isShown() {
    return fr.products[this.handle]?.display
  }

  get formatQuantity() {
    return fr.products[this.handle]?.format?.quantity
  }

  get noProductPageUrl() {
    return fr.products[this.handle]?.noProductPageUrl
  }

  get formatType() {
    return fr.products[this.handle]?.format?.type
  }

  get format() {
    return `${fr.products[this.handle]?.format?.quantity} ${
      fr.products[this.handle]?.format?.type
    }`
  }

  get ingredients() {
    return fr.products[this.handle]?.ingredients || []
  }

  get tags() {
    return fr.products[this.handle]?.categories || []
  }

  get dosage() {
    return fr.products[this.handle]?.dosage || []
  }

  get isStandAlone() {
    return fr.products[this.handle]?.standAlone || false
  }

  get headline() {
    return fr.products[this.handle]?.headline
  }

  get strongPoints() {
    return fr.products[this.handle]?.strongPoints || []
  }

  get forWho() {
    return fr.products[this.handle]?.forWho
  }

  get benefits() {
    return fr.products[this.handle]?.benefits
  }

  get whyRecommendIt() {
    return fr.products[this.handle]?.whyRecommendIt
  }

  get deficiencies() {
    return fr.products[this.handle]?.deficiencies
  }

  get supplier() {
    return fr.products[this.handle]?.supplier
  }

  get contraindication() {
    return fr.products[this.handle]?.contraindication
  }

  get pillPicture() {
    return getPillPicture(this.handle)
  }

  getVariantForStandAloneProduct() {
    let variant
    if (this.handle === ProductHandle.COLLAGENE) {
      variant = this.data?.shopify_product?.variants?.edges?.find(
        ({ node: { title } }) => {
          return title === '15sticks_onetime'
        }
      )
    } else if (
      this.handle === ProductHandle.MELATONINE ||
      this.handle === ProductHandle.GLUTAMINE ||
      this.handle === ProductHandle.VEGAN_WHEY
    ) {
      variant = this.data?.shopify_product?.variants?.edges?.find(
        ({ node: { title } }) => {
          return title === '1month_onetime'
        }
      )
    }
    return variant
  }

  getVariant(recommendedDuration = '1-month') {
    return this.data?.shopify_product?.variants?.edges?.find(
      ({ node: { title } }) =>
        title === getVariantTitleByRecommendedDuration(recommendedDuration)
    )
  }

  getPrice(recommendedDuration = '1-month') {
    const isStandAloneProduct = getIsStandAloneProduct(this.handle)
    if (isStandAloneProduct) {
      return this.getVariantForStandAloneProduct()?.node?.price
    }
    return this.getVariant(recommendedDuration)?.node?.price || 0
  }

  getVariantId(recommendedDuration = '1-month') {
    const isStandAloneProduct = getIsStandAloneProduct(this.handle)
    if (isStandAloneProduct) {
      return this.getVariantForStandAloneProduct()?.node?.id
    }

    return this.getVariant(recommendedDuration)?.node?.id || undefined
  }
}

export default Product
