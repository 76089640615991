import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  padding: 0 1.25rem;
`
export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  max-width: 62.375rem;
  width: 100%;
  padding: 4.0625rem 1.25rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const ActionFooter = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  height: 4.6875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  border-top: 0.0625rem solid #e3e8ee;
  background-color: #fff;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 37.5rem;
`

export const Title = styled.h3`
  font-size: 1.125rem;
  margin-left: 1.25rem;
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
`

export const DialogContainer = styled.div`
  padding: 1.25rem;
`

export const Aside = styled.div`
  width: 100%;
  padding: 1.25rem;
`

export const AttachFile = styled.button`
  margin: 0rem 0;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 1.0625rem;
  cursor: pointer;
  span {
    margin-right: 0.625rem;
    font-size: 1.125rem;
    background-color: ${Color.DEEP_SEA};
    color: white;
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3125rem;
  }
  p {
  }
`

export const FileList = styled.div``

export const File = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
  p {
  }
  span {
    margin-left: 0.625rem;
    font-size: 0.625rem;
    margin-top: 0.25rem;
  }
`

export const FileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background: #f8f9fc;
  border: 0.0625rem solid #e7e9f4;
  border-radius: 0.75rem;
  margin-bottom: 1.875rem;
`

export const LastUpdate = styled.p`
  float: right;
  font-size: 0.8125rem;
  margin-bottom: 1.25rem;
`
