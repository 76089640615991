/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PrescriptionTakeType = 'IN_CURE' | 'STAND_ALONE'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrescriptionTakeType = {
  IN_CURE: 'IN_CURE' as PrescriptionTakeType,
  STAND_ALONE: 'STAND_ALONE' as PrescriptionTakeType,
}
