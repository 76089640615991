import { useState, useEffect } from 'react'
import Layout from '../../components/layout/portail'
import { Content, Title, SubTitle, Header, Wrapper } from './style'
import PrescriptionsContainer from '../../components/prescription/prescriptions-container'
import RecommendationsContainer from '../../components/patient-recommendations-container/RecommendationsContainer'
import { GetPrescriptionStatus } from '../../services/api/types'
import { useQuery } from '../../utils/hooks/getQuery'
import {
  getAllPrescriptionsByPatient,
  getGetAllPrescriptionsByPatientQueryKey,
} from '../../services/api/prescriptions/prescriptions'
import { useInfiniteQuery } from 'react-query'
import { useGetMe } from '../../utils/hooks/getMe'
import { Helmet } from 'react-helmet'
import { PrescriptionNotFound } from '../../components/prescription-not-found'

function PatientRecommendationsPage() {
  //Attributes
  const [isMobile, setIsMobile] = useState(false)
  const PAGE_SIZE = 20
  const query = useQuery()
  const nav =
    (query.get('nav') as GetPrescriptionStatus) ?? GetPrescriptionStatus.ALL

  //Queries
  const { data: patient } = useGetMe()
  const {
    data: prescriptionsData,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    getGetAllPrescriptionsByPatientQueryKey(patient?.id, {
      status: nav,
      size: PAGE_SIZE,
    }),
    async params => {
      return await getAllPrescriptionsByPatient(patient?.id, {
        status: nav,
        page: params?.pageParam,
        size: PAGE_SIZE,
      })
    },
    {
      getNextPageParam: page => {
        if (page?.page + 1 !== page?.nbPages) {
          return page?.page + 1
        } else {
          return undefined
        }
      },
    }
  )
  const prescriptions = prescriptionsData?.pages
    ?.flatMap(page => page.data)
    .reverse()

  const filteredPrescriptions = prescriptions?.filter(prescription => {
    return prescription?.statuses?.find(i => i.status === 'SENT')
  })

  //Effects
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100)
    }
    handleResize() // Call the function once to set initial state
    window.addEventListener('resize', handleResize) // Add event listener for resize
    return () => {
      window.removeEventListener('resize', handleResize) // Cleanup
    }
  }, [])

  // Render
  return (
    <Layout
      nav={[
        {
          url: '/patient/recommendations',
          text: 'Mes recommandations',
        },
        // {
        //   url: '/patient/orders',
        //   text: 'Historique de commande',
        // },
        {
          url: '/patient/catalog',
          text: 'Catalogue',
        },
      ]}
      showCart
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | recommandations</title>
      </Helmet>
      <Content>
        {prescriptions && prescriptions.length !== 0 && (
          <Header>
            <Title>Historique des recommandations</Title>
            <SubTitle>
              Chaque ligne correspond à une recommendations effectué par votre
              thérapeute
            </SubTitle>
          </Header>
        )}
        {prescriptions && prescriptions.length === 0 ? (
          <PrescriptionNotFound />
        ) : isMobile ? (
          <RecommendationsContainer
            prescriptions={filteredPrescriptions}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
          />
        ) : (
          <Wrapper>
            <PrescriptionsContainer
              type='patient'
              nav={undefined}
              headerCta={<></>}
              refetchPrescriptions={undefined}
              prescriptions={filteredPrescriptions}
              isLoading={isLoading}
              fetchNextPage={undefined}
              isFetchingNextPage={undefined}
              hasNextPage={undefined}
            />
          </Wrapper>
        )}
      </Content>
    </Layout>
  )
}

export default PatientRecommendationsPage
