import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const Title = styled.h4`
  font-size: 18px;
  margin-left: 20px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
