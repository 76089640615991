import styled from 'styled-components/macro'

import { Color } from '../../../../utils/constants'

export const ProductPreview = styled.div`
  padding: 10px 0;
  min-height: 90px;
  border-bottom: ${({ isLast }) =>
    isLast ? 'none' : `1px dotted ${Color.MYSTIC}`};
`

export const MainContainer = styled.div`
  display: flex;
`

export const NotesContainer = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: ${Color.MYSTIC};
  margin-top: 10px;

  > p {
    font-size: 12px;
  }
`

export const ProductName = styled.div`
  font-size: 16px;
  flex: 1;
  position: relative;
  margin-right: 15px;
`

export const IconsContainer = styled.span`
  display: flex;
  cursor: pointer;

  > div:not(:last-child) {
    margin-right: 5px;
  }
`

export const SmallPrice = styled.div`
  font-size: 18px;
`

export const Img = styled.img`
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: #faf2e7;
`

export const Quantity = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
`

export const Minus = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.MYSTIC};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    border-bottom: 2px solid black;
    width: 6px;
    border-radius: 5px;
  }
`

export const Plus = styled(Minus)`
  &:before {
    content: '';
    border-left: 2px solid black;
    height: 6px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 5px;
  }
`

export const NumberQuantity = styled.span`
  display: inline-flex;
  justify-content: center;
  min-width: 30px;
`

export const Block2 = styled.div`
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`

export const Block1 = styled(Block2)`
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-right: 10px;
`

export const Title = styled.h2`
  font-size: 22px;
  padding-right: 0;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const DialogContainer = styled.div`
  padding: 20px;
`

export const P = styled.p`
  text-transform: lowercase;
  display: flex;
  font-size: 12px;
  align-items: center;
`
