import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../../utils/constants'

export const Wrapper = styled.div``

export const Price = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  ${MediaQuery.M_AND_DOWN} {
    margin-right: 10px;
  }
`
export const BarredPrice = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
  text-decoration: line-through;
  opacity: 0.4;
`
export const DiscountPercentage = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
  opacity: 0.4;
  margin-left: 5px;
`

export const Units = styled.div`
  font-size: 12px;
  color: #8f95b2;
`
export const PriceWrapper = styled.div`
  ${MediaQuery.M_AND_DOWN} {
    margin-top: 4px;
    display: flex;
    align-items: center;
  }
`
