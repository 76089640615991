import IconButton from '../ui/icon-button'
import { ChevronLeft, ChevronRight } from '../ui/icon/icons'
import { PagerProps } from './props'
import { Container, IconsContainer, Text } from './style'

const Pager = ({
  width = '100%',
  height = '100%',
  page,
  nbPages,
  size,
  totalAmount,
  setPage,
}: PagerProps) => {
  // Render
  return (
    <Container width={width} height={height}>
      <Text>
        {page !== undefined && size !== undefined ? page * size + 1 : '0'}-
        {page !== undefined && size !== undefined
          ? Math.min(page * size + size, totalAmount)
          : '0'}{' '}
        of {totalAmount ?? '0'}
      </Text>
      <IconsContainer>
        <IconButton
          color='#8F95B2'
          onHoverBgColor='#E7E9F4'
          onClick={() => setPage(Math.max(page - 1, 0))}
        >
          {color => <ChevronLeft width='1.5rem' height='1.5rem' fill={color} />}
        </IconButton>
        <IconButton
          color='#8F95B2'
          onHoverBgColor='#E7E9F4'
          onClick={() => setPage(Math.min(page + 1, nbPages - 1))}
        >
          {color => (
            <ChevronRight width='1.5rem' height='1.5rem' fill={color} />
          )}
        </IconButton>
      </IconsContainer>
    </Container>
  )
}

export default Pager
