import { useState } from 'react'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import { useGetProductByIdOrHandle } from '../../../services/api/product/product'
import ProductDialog from '../product-dialog'

const UrlProductDialog = () => {
  const { location, replace } = useHistory()
  const { productIdOrHandle } = queryString.parse(location.search)
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(
    !!productIdOrHandle
  )

  const { data: product, isLoading } = useGetProductByIdOrHandle(
    productIdOrHandle as string,
    {},
    {
      query: {
        enabled: !!productIdOrHandle,
      },
    }
  )

  return (
    <ProductDialog
      isOpen={isProductDialogOpen}
      onClose={() => {
        setIsProductDialogOpen(false)
        replace(location.pathname)
      }}
      product={product}
      isProductLoading={isLoading}
      doNotAllowProductToBeAdded
    />
  )
}

export default UrlProductDialog
