import { FileContainer, AttachFile } from './style'
import { RiAttachment2 } from 'react-icons/ri'
import FormDialog from '../../prescription/create-prescription-form/step-3/form-dialog'
import { PrescriptionFileDTO } from '../../../services/api/types/prescriptionFileDTO'
import { FileUploadProps } from './props'

const FileUpload = ({
  isFormFilesOpened,
  setIsFormFilesOpened,
  draft,
  setFieldValue,
  setDraftChanged,
}: FileUploadProps) => {
  return (
    <>
      <FormDialog
        isOpen={isFormFilesOpened}
        onClose={() => setIsFormFilesOpened(false)}
        files={draft.files}
        setFiles={(value: PrescriptionFileDTO[]) => {
          setDraftChanged(true)
          setFieldValue('files', value)
        }}
      />
      <FileContainer>
        {setFieldValue && (
          <>
            <AttachFile
              onClick={e => {
                e.preventDefault()
                setIsFormFilesOpened(true)
              }}
            >
              <span>
                <RiAttachment2 />
              </span>
              <p>Joindre un fichier</p>
            </AttachFile>
          </>
        )}
      </FileContainer>
    </>
  )
}

export default FileUpload
