/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PrescriptionRecommendedDuration =
  | 'ONE_MONTH'
  | 'TWO_MONTH'
  | 'THREE_MONTH'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrescriptionRecommendedDuration = {
  ONE_MONTH: 'ONE_MONTH' as PrescriptionRecommendedDuration,
  TWO_MONTH: 'TWO_MONTH' as PrescriptionRecommendedDuration,
  THREE_MONTH: 'THREE_MONTH' as PrescriptionRecommendedDuration,
}
