import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  max-width: 998px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 40px;

  ${MediaQuery.XL_AND_DOWN} {
    width: 100%;
  }
  ${MediaQuery.L_AND_DOWN} {
    padding: 40px 20px;
  }

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
  & > *:not(:last-child) {
    border-right: 1px solid #eeeeee;
  }

  ${MediaQuery.M_AND_DOWN} {
    & > *:not(:last-child) {
      border-right: 0px;
      border-bottom: 1px solid #eeeeee;
    }
  }
`

export const Item = styled.div`
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
`

export const ItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 50px;
  height: 50px;
  background: #e0f0e9;
  border-radius: 8px;
  margin-bottom: 15px;
  img {
    width: 21px;
    object-fit: contain;
  }
`

export const ItemText = styled.p`
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #080038;
`
