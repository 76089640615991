import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NewPrescriptionHeaderProps } from './props'
import {
  Container,
  CTAContainer,
  FlexItem,
  TextContainer,
  TextContainerHeader,
  ProtocolButton,
  ButtonWrapper,
} from './style'
import {
  DialogContent,
  Title,
  DialogContainer,
} from '../new-prescription-compliment/style'
import { toast } from 'react-hot-toast'
import { Button, ButtonGroup, Dialog } from '../../../components/ui'
import { BackButton } from '../../../components/ui'

const NewPrescriptionHeader = ({
  setIsSliderProtocolDialogOpen,
  onPrescriptionSubmitted,
  isPrescriptionModified,
  setDraftChanged,
  draft,
  bypassBackCondition,
}: NewPrescriptionHeaderProps) => {
  // Attributes
  const history = useHistory()
  const [isDialogOpen, setIsDialogOpen] = useState('')
  const [isDialogOpen2, setIsDialogOpen2] = useState(false)

  // Functions
  const switchPrescriptionType = (url: string) => {
    history.push(url)
  }

  // Render
  return (
    <Container>
      <FlexItem>
        <CTAContainer>
          <BackButton
            onClick={() => {
              if (
                isPrescriptionModified &&
                draft?.takes?.length &&
                !bypassBackCondition
              )
                setIsDialogOpen2(true)
              else history.push('/prescriptions')
            }}
          />
        </CTAContainer>
        <TextContainer>
          <TextContainerHeader>Nouvelle recommandation</TextContainerHeader>
        </TextContainer>
      </FlexItem>

      <div>
        {isPrescriptionModified && (
          <Button
            appearance={undefined}
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
            style={{
              marginRight: '20px',
              border: 'none',
            }}
            onClick={() => {
              if (!draft?.takes?.length) {
                toast.error('Veuillez ajouter un produit')
                return
              }
              onPrescriptionSubmitted(false)
              setDraftChanged(false)
            }}
          >
            Sauvegarder
          </Button>
        )}
        {history.location.pathname === '/prescriptions/new' && (
          <ProtocolButton onClick={() => setIsSliderProtocolDialogOpen(true)}>
            Choisir un protocole
          </ProtocolButton>
        )}
      </div>

      <Dialog
        type='default'
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen('')}
        styleContainer={undefined}
      >
        <DialogContainer>
          <DialogContent>
            <Title isCenter>
              Attention, si vous changez de type de recommandation toutes vos
              données encodées seront perdues.
              <br />
              <br />
              Êtes-vous certain de vouloir changer de type de recommandation ?
            </Title>
            <ButtonGroup spaced>
              <Button
                appearance='minimal'
                onClick={() => setIsDialogOpen('')}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Non, je continue ma recommandation
              </Button>
              <Button
                onClick={() => {
                  switchPrescriptionType(isDialogOpen)
                  setIsDialogOpen('')
                }}
                appearance={undefined}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Oui, je suis sûr
              </Button>
            </ButtonGroup>
          </DialogContent>
        </DialogContainer>
      </Dialog>

      <Dialog
        type='default'
        isOpen={isDialogOpen2}
        onClose={() => setIsDialogOpen2(false)}
        styleContainer={undefined}
      >
        <DialogContainer>
          <DialogContent>
            <Title isCenter>
              Souhaitez-vous enregistrer la recommandation avant de quitter?
              <br />
              <br />
              Si vous quittez sans enregistrer, toutes vos données encodées
              seront perdues
            </Title>
            <ButtonWrapper>
              <Button
                appearance={undefined}
                onClick={() => {
                  onPrescriptionSubmitted(true).then(id => {
                    history.push('/prescriptions')
                  })
                }}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Enregistrer et quitter
              </Button>
              <Button
                onClick={() => {
                  setIsDialogOpen2(false)
                  history.push('/prescriptions')
                }}
                appearance='minimal'
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                quitter
              </Button>
            </ButtonWrapper>
          </DialogContent>
        </DialogContainer>
      </Dialog>
    </Container>
  )
}

export default NewPrescriptionHeader
