import { gql } from '@apollo/client'

export const ALL_PRODUCTS = gql`
  query getProducts {
    shopify_product {
      id
      shopify_product_handle
      shopify_product {
        handle
        id
        title
        variants(first: 10) {
          edges {
            node {
              price
              title
              id
            }
          }
        }
      }
    }
  }
`
