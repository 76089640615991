import styled from 'styled-components/macro'

import { Color, MediaQuery } from '../../utils/constants'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  background-color: #fdfcfe;
`
export const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100%;
  background-color: ${props => (props.blur ? '#f9f8fb90' : 'none')};
  display: ${props => (props.blur ? 'block' : 'none')};
  z-index: 1;
`

export const Content = styled.div`
  max-width: 62.375rem;
  width: 95%;
  padding: 1.25rem 1.25rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  ${MediaQuery.M_AND_DOWN} {
    padding: 0.625rem 0rem;
  }
`

export const HeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.0625rem solid #ddd;
  ${MediaQuery.M_AND_DOWN} {
    align-items: start;
    flex-direction: column;
    padding-bottom: 1.25rem;
  }
`

export const MainTitle = styled.h1`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.8125rem;
  margin-bottom: 1.25rem;
  color: ${Color.MINE_SHAFT};
  ${MediaQuery.M_AND_DOWN} {
    font-size: 1.125rem;
  }
  b {
    font-weight: 900;
  }
`

export const SecondaryTitle = styled.h3`
  font-size: 0.9375rem;
  margin-top: 1.25rem;
  margin-bottom: 0.9375rem;
  color: ${Color.MINE_SHAFT};
  ${MediaQuery.M_AND_DOWN} {
    margin-top: 0.625rem;
  }
`

export const BodySection = styled.section`
  margin-top: -1.25rem;
  display: flex;
  width: 100%;
  padding-bottom: 1.25rem;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`

export const FooterBar = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 1.25rem;
  border-top: 0.0625rem solid #ddd;
  background-color: #fff;
  ${MediaQuery.M_AND_DOWN} {
    padding: 0.625rem 1.25rem;
    background-color: #fdfcfe;
  }
  ${MediaQuery.M_AND_UP} {
    display: none;
  }
`

export const FooterLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.625rem;
`
export const FooterLinePrice = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`

export const FooterIcon = styled.span`
  font-size: 1.625rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
  }
`

export const P = styled.p`
  font-size: 1rem;
  white-space: pre-wrap;
`

export const Note = styled(P)`
  padding: 1.25rem;
  border-radius: 0.3125rem;
  background-color: #fff;
  box-shadow: 0rem 0rem 0.0625rem rgba(40, 41, 61, 0.08),
    0rem 0.0313rem 0.125rem rgba(96, 97, 112, 0.16);
  border-radius: 0.5rem;
  max-width: 31.25rem;
  font-weight: 400;
  font-size: 0.875rem;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const Right = styled(Left)`
  margin-top: -1.875rem;
  margin-left: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40%;
  max-width: 40%;
  ${MediaQuery.M_AND_DOWN} {
    margin-left: 0;
    min-width: 100%;
    max-width: 100%;
    margin-top: 1.875rem;
  }
`

export const Img = styled.img`
  width: 100%;
  height: auto;
  border-radius: 1.25rem;
  object-fit: cover;
  max-width: 23.75rem;
  ${MediaQuery.M_AND_DOWN} {
    max-height: 12.5rem;
  }
  ${MediaQuery.S_AND_DOWN} {
    min-width: 100%;
  }
`

export const TransportInfo = styled.div`
  display: flex;
  padding: 1.875rem 0;
  align-items: center;
  > ${P} {
    font-size: 0.75rem;
    max-width: 15.625rem;
    color: ${Color.DEEP_SEA};
  }
`

export const IconWrapper = styled.div`
  width: 3.75rem;
  min-width: 3.75rem;
  max-width: 3.75rem;
  height: 3.75rem;
  min-height: 3.75rem;
  max-height: 3.75rem;
  border-radius: 50%;
  background-color: ${Color.MONTE_CARLO}55;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.9375rem;
  > div {
    width: 2.5rem;
    height: 2.5rem;
    stroke: ${Color.DEEP_SEA};
  }
`

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 2.5rem;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  align-items: center;
  width: 100%;
  ${MediaQuery.M_AND_DOWN} {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 0;
  }
`

export const Li = styled.li`
  position: relative;
  font-size: 0.875rem;
  width: 80%;
  max-width: 15.625rem;
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
  > div {
    position: absolute;
    top: 50%;
    right: calc(100% + 0.9375rem);
    transform: translateY(-50%);
    fill: ${Color.DEEP_SEA};
  }
`

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TotalPrice = styled.p`
  > b {
    font-size: 1.625rem;
    ${MediaQuery.M_AND_DOWN} {
      font-size: 1.25rem;
    }
  }
  > small {
    font-size: 0.75rem;
    opacity: 0.8;
    ${MediaQuery.M_AND_DOWN} {
      font-size: 0.625rem;
    }
  }
`

export const Discount = styled.p`
  font-size: 0.75rem;
  max-width: 11.25rem;
  margin: 0 1.875rem;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
  > b {
    font-weight: 900;
  }
  > strong {
    color: ${Color.DEEP_SEA};
  }
`

export const PaymentMethods = styled.div`
  display: flex;
`

export const PaymentImage = styled.img`
  width: 3.4375rem;
  height: 1.875rem;
  object-fit: contain;
`

export const RowText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
`

export const TextLeft = styled(P)`
  font-size: 0.875rem;
  border-bottom: 0.0625rem dotted #ddd;
  flex: 1;
`

export const TextRight = styled(P)`
  border-bottom: 0.0625rem dotted #ddd;
`

export const SelectionWrapper = styled.div`
  margin-top: 1.25rem;
  background-color: #d6f1e7;
  padding: 0.625rem 1.25rem;
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 300;
  font-size: 0.8125rem;
`

export const Selection = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid ${Color.DEEP_SEA};
  border-radius: 6.25rem;
  margin-right: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 6.25rem;
    background-color: ${Color.DEEP_SEA};
  }
`

export const Select = styled.select`
  font-size: 1.5625rem;
  border: none;
  outline: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0.3125rem;
  border: 0.0625rem solid #dfdfdf;
  border-radius: 0.125rem;
  margin-right: 0.625rem;
  padding: 0.1875rem 0.625rem;
  padding-right: 1.875rem;
`

export const OrderSummary = styled.div`
  padding: 0.625rem 1.25rem;
  /* box-shadow: 0rem 0rem .375rem rgba(65, 91, 115, 0.25); */
  border-radius: 0.9375rem;
`

export const InfoCard = styled.div`
  margin-top: 1.875rem;
  padding: 1.25rem 1.25rem;
  /* box-shadow: 0rem 0rem .375rem rgba(65, 91, 115, 0.25); */
  border-radius: 0.9375rem;
`

export const InfoCardContainer = styled.div`
  display: flex;
  img {
    width: 50%;
    height: 25rem;
  }
  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
  }
`

export const InfoCardTitle = styled.h3`
  font-size: 1.5625rem;
  margin-bottom: 1.5625rem;
`

export const LineButton = styled.span`
  font-size: 0.8125rem;
  cursor: pointer;
  font-weight: 300;
  border-bottom: 0.0625rem solid ${Color.DEEP_SEA};
  color: ${Color.DEEP_SEA};
  text-decoration: none;
  white-space: nowrap;
  span {
    margin-right: 0.3125rem;
    font-size: 0.625rem;
  }
`

export const TitleWrapperFlex = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${MediaQuery.M_AND_DOWN} {
    align-items: start;
    flex-direction: column;
    margin-bottom: 1.25rem;
  }
`

export const DialogPaymentClose = styled.span`
  position: absolute;
  top: 0.625rem;
  right: 1.4375rem;
  z-index: 10000;
  font-size: 1.875rem;
`

export const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  min-width: 100vw;
`

export const DialogContainer = styled.div`
  padding: 1.25rem;
`

export const FilesWrapper = styled.div`
  margin-top: 1.25rem;
  display: flex;
`

export const FilesWrapperIcon = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  background-color: ${Color.DEEP_SEA};
  color: white;
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;
`

export const FilesWrapperContent = styled.div``

export const FilesWrapperTitle = styled.h4`
  font-size: 0.625rem;
`

export const FileList = styled.div`
  display: flex;
  margin-top: 0.3125rem;
`

export const File = styled.a`
  cursor: pointer;
  border: 0.0625rem solid #eee;
  display: inline-block;
  margin-right: 0.3125rem;
  font-size: 0.5rem;
  width: 2.5rem;
  height: 1.0625rem;
  padding: 0.125rem;
`

export const ReOrder = styled.div`
  margin-top: 0.9375rem;
  background-color: #e0f0e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8125rem;
  padding: 0.9375rem;
  border-radius: 0.25rem;
  z-index: 2;
  position: relative;
  border: 0.0625rem solid #00855a20;
  p {
    color: ${Color.DEEP_SEA};
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
  }
  div {
    display: flex;
  }
`

export const ReOrderButton = styled.button`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  cursor: pointer;
  text-decoration: none;
  background-color: ${Color.DEEP_SEA};
  color: white;
  padding: 0.375rem 0.625rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.6875rem;
`

export const PrescriberAdviceButton = styled.button`
  margin-top: 1.25rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;

  p {
    color: inherit;
    font-size: 0.75rem;
    text-decoration: underline;
  }

  span {
    margin-bottom: 0.875rem;
    transition: all 0.2s;
  }

  &:hover span {
    margin-left: 0.125rem;
  }
`

export const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`
