import { useState, useRef, useEffect } from 'react'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { useSendPrescription } from '../../../../services/api/prescriptions/prescriptions'
import { Title, SubTitle, Emails, Container } from './style'
import { EmailSectionProps } from './props'
import {
  useGetPatients,
  useCreatePatient,
} from '../../../../services/api/patients/patients'
import { InputWrapper } from '../../../share-access-dialog/home-page-content/send-email/style'
import toast from 'react-hot-toast'
import EmailItem from './email-item'
import Footer from './footer'
import SearchPatient from './search-patient'
import { useQueryClient } from 'react-query'
import {
  refetchPrescription,
  refetchPatient,
} from '../../../../utils/helpers/refetch'

const EmailSection = ({
  prescriptionId,
  setIsInnerDialogOpen,
}: EmailSectionProps) => {
  const queryClient = useQueryClient()
  const { data: prescriber } = useGetMe()

  const { mutateAsync: sendPrescription } = useSendPrescription()
  const [emails, setEmails] = useState([])
  const [isChecked, setChecked] = useState(false)

  const [email, setEmail] = useState('')
  const [showPatientList, setShowPatientList] = useState(false)
  const inputWrapperRef = useRef(null)
  const { mutateAsync: addPatient } = useCreatePatient({
    mutation: {
      onSuccess: async patient => {
        refetchPatient(queryClient, prescriber?.id)
        toast('Le client a été créé avec succès !')
      },
      onError: error => {},
    },
  })

  const { data: patients, isLoading } = useGetPatients(
    prescriber?.id,
    {
      q: email,
      size: 1000,
    },
    {
      query: {
        enabled: !!prescriber?.id,
      },
    }
  )

  const handleChange = event => {
    setChecked(event.target.checked)
  }

  const addEmail = async email => {
    let patient = null
    if (validateEmail(email)) {
      if (!patients?.data?.filter(patient => patient.email === email)?.length) {
        patient = await addPatient({
          prescriberId: prescriber?.id,
          data: {
            email: email,
            firstName: ' ',
            lastName: ' ',
            phone: ' ',
          },
        })
      }

      setEmails(prev => {
        // Check if the email already exists in the list
        if (prev.includes(email)) {
          // If the email exists, show an error message and do not update the list
          return prev
        } else {
          // If the email doesn't exist, add it to the list
          return [...prev, email]
        }
      })
      setEmail('')

      return patient
    } else {
      toast.error('Ajouter un email valide pour créer un nouveau client')
      throw Error('email invalid')
    }
  }

  const validateEmail = email => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const deleteEmail = index => {
    setEmails(prev => prev.filter((_, i) => i !== index))
  }

  const sendEmail = () => {
    toast
      .promise(
        sendPrescription({
          prescriberId: prescriber?.id,
          prescriptionId: prescriptionId,
          data: { emails: emails },
        }),
        {
          loading: 'en cours...',
          success: 'Votre recommandation a bien été envoyée',
          error: 'Ajouter un client pour envoyer la recommandation par email.',
        },
        {
          duration: 3000,
        }
      )
      .then(() => {
        refetchPrescription(queryClient, prescriber?.id, prescriptionId)
      })
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        inputWrapperRef.current &&
        !inputWrapperRef.current.contains(event.target)
      ) {
        setShowPatientList(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [inputWrapperRef])

  return (
    <Container>
      <Title>Envoyez la recommandation par email et SMS</Title>
      <SubTitle>Vous pouvez sélectionner plusieurs clients</SubTitle>

      <InputWrapper ref={inputWrapperRef}>
        <SearchPatient
          email={email}
          setShowPatientList={setShowPatientList}
          setEmail={setEmail}
          addEmail={addEmail}
          patients={patients}
          showPatientList={showPatientList}
          isLoading={isLoading}
          emails={emails}
          setIsInnerDialogOpen={setIsInnerDialogOpen}
        />
      </InputWrapper>
      <Emails>
        {emails.map((email, index) => (
          <EmailItem
            setIsInnerDialogOpen={setIsInnerDialogOpen}
            email={email}
            index={index}
            deleteEmail={deleteEmail}
            isChecked={isChecked}
          />
        ))}
      </Emails>

      <Footer
        isChecked={isChecked}
        handleChange={handleChange}
        setChecked={setChecked}
        sendEmail={sendEmail}
        emails={emails}
      />
    </Container>
  )
}

export default EmailSection
