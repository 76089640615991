import {
  Container,
  IconContainer,
  Question,
  QuestionContainer,
  SectionContainer,
} from './style'
import { QuizCategoryBodyGridProps } from './props'

const QuizCategoryBodyGrid = ({ category }: QuizCategoryBodyGridProps) => {
  // Render
  return (
    <Container>
      {category?.sections?.map(section => (
        <SectionContainer key={section.id}>
          {section?.questions?.map((question, i) => (
            <QuestionContainer key={`sections-${section.id}-questions-${i}`}>
              <IconContainer></IconContainer>
              <Question>{`${question.title}: ${
                question.response ? question.response : '/'
              }`}</Question>
            </QuestionContainer>
          ))}
        </SectionContainer>
      ))}
    </Container>
  )
}

export default QuizCategoryBodyGrid
