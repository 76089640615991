import { Link } from 'react-router-dom'

import { Header, Nav, Ul, Li, CalendarLink } from './style'
import { Logo } from '../../../ui'
import { HeaderLinks } from './props'

const HeaderEl = ({ isPublic, active }) => {
  return (
    <Header>
      <a href='https://www.compliment-pro.com/patient'>
        <Logo />
      </a>
      <Nav>
        <Ul>
          <Li active={(active === HeaderLinks.Catalogue).toString()}>
            <Link href='/public-catalog'>Catalogue produit</Link>
          </Li>
          <Li>
            <a href='https://app.livestorm.co/compliment-srl'>
              Formation Webinar
            </a>
          </Li>
          <Li>
            <a href='https://about.compliment.me/pages/select'>
              Modèle de qualité SELECT
            </a>
          </Li>
          <Li>
            <CalendarLink href='https://meetings-eu1.hubspot.com/victor-boels/siteweb-en-apprendre-plus?embed=true'>
              <img
                src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/calendat6789fnearuigr.png?alt=media&token=86308df7-1467-420d-ad06-ff1ceb88c672'
                alt='calendar'
              />
              Réserver une démo
            </CalendarLink>
          </Li>

          <Li>
            <a href='https://www.compliment-pro.com/patient'>Espace patient</a>
          </Li>
          <Li>
            <Link to='/login'>Se connecter </Link>
          </Li>

          <Li active={(active === HeaderLinks.Points).toString()}>
            <Link to='/register'>
              <button>Créer un compte</button>
            </Link>
          </Li>
        </Ul>
      </Nav>
    </Header>
  )
}

export default HeaderEl
