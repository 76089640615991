import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const Container = styled.div`
  padding: 1.25rem 0;
  width: 100%;
`

export const ContainerList = styled.div`
  width: 100%;
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(16.25rem, 1fr));
  ${MediaQuery.M_AND_DOWN} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.625rem;
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
    @media (max-width: 20.625rem) {
      grid-template-columns: repeat(1, minmax(6.875rem, 1fr));
    }
  }
`

export const NoFoundWrapper = styled.div`
  margin: 1.25rem auto;
  display: flex;
  justify-content: center;
`
