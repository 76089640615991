import { LogoContainer } from './style'
import { LogoProps } from './props'

const Logo = ({ color = '#65b294' }: LogoProps) => (
  <LogoContainer>
    <svg height='45' id='Calque_1' viewBox='0 0 800 238.78'>
      <defs></defs>
      <path
        fill='#1f1c3e'
        d='m57.22,100.79c16.3,3.2,24.3,9.75,24.3,21.83,0,12.81-10.19,23.43-27.94,23.43s-27.5-10.04-31.87-21.39l12.08-7.57c3.49,9.46,10.19,16.3,19.94,16.3,7.57,0,13.68-4.07,13.68-10.33s-5.38-8.88-17.03-11.2c-13.53-2.76-24.59-9.02-24.59-21.83s12.22-20.23,25.76-20.23c15.28,0,23.86,6.11,28.96,17.9l-10.91,6.4c-3.35-8.59-10.04-12.95-17.75-12.95-6.84,0-12.66,3.06-12.66,8.15,0,6.11,6.55,9.31,18.04,11.5Z'
      />
      <path
        fill='#1f1c3e'
        d='m93.6,43.6h16.88v16.73h-16.88v-16.73Zm1.02,28.23h14.84v72.18h-14.84v-72.18Z'
      />
      <path
        fill='#1f1c3e'
        d='m227.91,144.01h-14.84v-47.73c0-8.29-3.93-13.39-12.51-13.39s-15.86,6.26-15.86,21.39v39.73h-14.84v-47.29c0-8.15-4.07-13.82-11.93-13.82-8.73,0-16.3,6.98-16.3,21.39v39.73h-14.84v-72.18h14.84v10.33c4.37-7.57,12.51-12.37,21.25-12.37,9.75,0,16.73,4.37,19.79,12.81,5.09-8.59,13.24-12.81,22.99-12.81,13.68,0,22.26,8.59,22.26,21.39v52.82Z'
      />
      <path
        fill='#1f1c3e'
        d='m243.64,71.83h14.84v11.06c4.51-7.13,14.41-13.1,25.61-13.1,18.04,0,33.32,14.26,33.32,38.12s-15.28,38.12-34.63,38.12c-10.33,0-19.64-4.66-24.3-11.93v39.58h-14.84v-101.86Zm13.82,36.09c0,14.55,10.04,24.45,22.41,24.45s22.41-9.89,22.41-24.45-10.04-24.45-22.41-24.45-22.41,9.89-22.41,24.45Z'
      />
      <path fill='#1f1c3e' d='m330.38,42.15h14.84v101.86h-14.84V42.15Z' />
      <path
        fill='#1f1c3e'
        d='m363.99,159.58c1.75.73,4.07,1.46,6.55,1.46,3.78,0,6.11-1.16,8.73-7.71l3.35-8.88-29.39-72.61h17.03l20.37,52.09,20.23-52.09h16.44l-34.92,86.73c-4.66,11.79-11.5,16.15-20.23,16.15-3.93,0-7.86-.73-10.48-1.6l2.33-13.53Z'
      />
      <path
        fill='#1f1c3e'
        d='m496.01,125.24c-6.84,13.53-18.19,20.81-33.18,20.81-20.95,0-37.4-15.57-37.4-38.12s17.17-38.12,37.4-38.12c15.86,0,27.5,7.42,32.6,19.64l-11.79,7.86c-5.24-10.62-12.66-13.82-20.66-13.82-12.22,0-22.41,9.17-22.41,24.45s9.6,24.45,22.41,24.45c10.91,0,16.73-5.53,21.54-14.55l11.5,7.42Z'
      />
      <path
        fill='#1f1c3e'
        d='m507.52,71.83h14.84v43.36c0,11.2,5.82,17.17,16.01,17.17s18.92-9.02,18.92-21.54v-39h14.84v72.18h-14.84v-11.64c-4.66,8.59-12.95,13.68-23.43,13.68-14.11,0-26.34-8.73-26.34-27.79v-46.42Z'
      />
      <path
        fill='#1f1c3e'
        d='m627.43,84.64c-1.89-.58-3.64-.87-5.68-.87-10.48,0-18.04,9.46-18.04,22.26v37.98h-14.84v-72.18h14.84v12.22c3.93-8.44,10.91-14.26,20.81-14.26,1.89,0,3.49.29,5.38.73l-2.47,14.11Z'
      />
      <path
        fill='#1f1c3e'
        d='m646.79,111.12c.58,12.81,9.17,21.97,21.97,21.97,11.79,0,17.32-6.4,21.54-14.99l11.5,6.84c-6.69,13.82-17.17,21.1-33.47,21.1-19.79,0-36.38-15.57-36.38-38.12s16.59-38.12,37.11-38.12,34.92,14.99,34.92,36.09v5.24h-57.19Zm0-11.35h43.07c-1.16-11.79-11.06-18.04-21.1-18.04-10.77,0-19.21,7.57-21.97,18.04Z'
      />
      <path
        fill='#65b294'
        d='m750.01,148.85c1.13-1.27,1.1-3.23-.1-4.45-.8-.82-1.99-1.18-3.11-.92-.61.14-1.16.45-1.61.9-5.05,5.13-12.15,9.48-19.29,13.64.71-4.38,2.14-8.09,4.28-11.24,1.09-1.44.81-3.5-.64-4.6-.7-.52-1.58-.74-2.4-.63-.95.12-1.79.64-2.3,1.41-3.54,5.16-5.53,11.6-5.93,19.15-1.69.99-3.38,1.99-5.11,3.08l-.15.11c-4.49,2.82-10.14,6.65-14.34,11.45-4.42,5.06-6.67,10.45-6.89,16.45-.04.87.27,1.71.86,2.36.59.64,1.4,1.02,2.27,1.06h.15c.82,0,1.6-.3,2.21-.85.65-.59,1.03-1.4,1.07-2.3.16-4.52,1.84-8.46,5.3-12.41,2.74-3.14,6.63-6.27,12.18-9.8,10.3,1.1,19.56-.27,27.52-4.06.8-.36,1.41-1.02,1.72-1.84.31-.82.27-1.71-.09-2.49-.57-1.25-1.83-2.05-3.24-1.92l-1.18.31c-4.33,2.06-8.99,3.29-14.09,3.69,5.38-3.1,10.88-6.32,15.78-9.96l7.12-6.14Z'
      />
      <path
        fill='#65b294'
        d='m775.92,129.27c-7.79-2.29-17.4-3.54-27.38-3.4-10.51.1-20.55,1.62-29.05,4.39-9.93,3.24-17.15,7.99-21.45,14.13-5.04,7.18-5.74,16.11-2.09,25.64.26.84.82,1.53,1.6,1.94.78.41,1.68.49,2.49.24.84-.26,1.53-.82,1.95-1.6.41-.78.49-1.67.15-2.74-2.99-7.82-2.57-14.28,1.27-19.74,3.4-4.85,9.68-8.87,18.14-11.63,14.42-4.7,34.43-5.35,49.55-1.7-1.69,6.84-6.18,19.41-14.11,29.49-4.93,6.26-10.36,10.52-16.14,12.65-6.97,2.55-14.34,2.16-22.32-1.14-.77-.41-1.66-.5-2.51-.24-.84.26-1.53.83-1.92,1.6-.41.77-.5,1.66-.24,2.5.26.84.83,1.53,1.52,1.88l.46.21c5.42,2.25,10.7,3.38,15.79,3.38,3.95,0,7.79-.68,11.49-2.05,6.97-2.57,13.38-7.52,19.05-14.73,9.41-11.94,14.78-27.7,16.11-35.41.27-1.66-.75-3.24-2.32-3.67Z'
      />
    </svg>
  </LogoContainer>
)

export default Logo
