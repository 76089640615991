import React from 'react'
import RecommendationCard from './recommendation-card'
import { useInView } from 'react-intersection-observer'
import { Spinner, NoFound } from '../ui'
import { useEffect } from 'react'
import { Container } from './style'

function RecommendationsContainer({
  isLoading = false,
  prescriptions,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
}) {
  // ATTRIBUTES
  const { ref, entry } = useInView()

  // EFFECT
  useEffect(() => {
    // Scroll Observer
    if (entry?.isIntersecting && !isFetchingNextPage && hasNextPage) {
      // Fetch the new page
      fetchNextPage()
    }
  }, [entry])

  // RENDER
  return (
    <Container>
      {isLoading && <Spinner fullScreen={undefined} />}
      {prescriptions?.map(prescription => {
        return (
          <RecommendationCard
            key={prescription?.id}
            prescription={prescription}
          />
        )
      })}
      <div style={{ padding: '.25rem' }} ref={ref} />
      {isFetchingNextPage && <Spinner fullScreen={undefined} />}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {!prescriptions?.length && (
          <NoFound title='Aucune recommandation' descritpion='' />
        )}
      </div>
    </Container>
  )
}

export default RecommendationsContainer
