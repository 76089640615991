import styled from 'styled-components/macro'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  table {
    width: 100%;
    background-color: white;
    box-shadow: 0rem 0rem 0.0625rem rgba(40, 41, 61, 0.08),
      0rem 0.0313rem 0.125rem rgba(96, 97, 112, 0.16);
    border-radius: 0.75rem;
  }
  thead > tr {
    height: 4.8125rem;
  }
  th {
    padding: 0 0.75rem;
    text-align: start;
    color: #080038;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
  th:first-child {
    padding: 0 0.75rem 0 1.875rem;
  }
  th:last-child {
    padding: 0 1.875rem 0 0.75rem;
  }
  tbody > tr {
    height: 4.875rem;
  }
  td {
    padding: 0 0.75rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #080038;
  }
  td:first-child {
    padding: 0 0.75rem 0 1.875rem;
  }
  td:last-child {
    padding: 0 1.875rem 0 0.75rem;
  }
`

export const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 1.25rem;
`

export const Table = styled.table`
  /* width: 100%;
  border-collapse: collapse; */
`

export const TableHead = styled.thead`
  /* border-bottom: .0625rem solid black; */
`

export const TableBody = styled.tbody``

export const Cell = styled.td`
  /* flex-grow: 1;
  width: ${({ width }) => width || 'auto'}; */
`

export const Row = styled.tr`
  /* width: 100%;
  ${Cell}:nth-child(4) {
    max-width: 6.25rem;
  } */
`

export const HeadRow = styled(Row)`
  /* ${Cell} {
    padding: .625rem 0;
  } */
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 0.3125rem 0;
  a {
    font-size: 0.75rem;
    padding: 0.125rem 0.625rem;
  }
`

// export const Container = styled.div`
//   margin-top: 3.75rem;
//   width: 100%;

// `
