import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 30px;
  }
`

export const TitleInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 20px;
  outline: none;
  width: 100%;
`

export const DescriptionWrapper = styled.div``

export const DescriptionWrapperTitle = styled.h5`
  margin-bottom: 10px;
`

export const DescriptionWrapperInput = styled.textarea`
  background-color: transparent;
  border: none;
  width: 100%;
  min-height: 370px;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(65, 91, 115, 0.12);
  outline: none;
  font-style: inherit;
  font-family: inherit;
`

export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  .react-datepicker__input-container {
    input {
      border: none;
      outline: none;
      opacity: 0.5;
      cursor: pointer;
    }
  }
`

export const DatePickerWrapperTitle = styled.h5`
  white-space: nowrap;
  margin-right: 10px;
`

export const DatePickerWrapperDate = styled.div`
  display: flex;
  align-items: center;
`

export const DatePickerWrapperDateIcon = styled.span`
  margin: 5px 5px 0 0;
`

export const PatientWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const PatientWrapperTitle = styled.h5`
  white-space: nowrap;
  margin-right: 10px;
`

export const PatientWrapperSelect = styled.div`
  display: flex;
  font-size: 14px;
`

export const PatientWrapperSelectPatientButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.5;
  margin-right: 5px;
`

export const PatientWrapperCreatePatientButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`
