import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 20px;
  max-width: 998px;
`

export const Aside = styled.aside`
  width: 250px;
`

export const Content = styled.div`
  padding: 20px;
  max-width: 998px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
`
