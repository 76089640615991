import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Content = styled.div`
  max-width: ${props => props.styleContent?.maxWidth || '90%'};
  width: 100%;
  padding: ${props => props.styleContent?.padding || '2.5rem 0 1.25rem 0'};
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 0.625rem;
  }

  & > *:nth-child(2) {
    margin-left: auto;
  }
`

export const Right = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.h1`
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 2.125rem;
  color: #080038;
  width: 100%;

  /* Utilisation de la prop className specifique pour la page catalogque */
  &.custom-title-class {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`

export const TitleBlock = styled.div`
  display: block;
  width: 100%;
`

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #080038;
  max-width: 40rem;
`

export const ButtonFavorite = styled.button`
  cursor: pointer;
  padding: 0.5rem 1.25rem;
  border-radius: 0.3125rem;
  border: none;
  margin-right: 1.25rem;
  background-color: ${Color.DEEP_SEA};
  color: white;
  ${MediaQuery.M_AND_DOWN} {
    margin-bottom: 0.9375rem;
  }
`
