import React from 'react'
import { GlobalContainer, Main } from './style'
import Header from './header'

interface DefaultLayoutProps {
  children: React.ReactNode
}

const DefaultLayout = ({ children }: DefaultLayoutProps) => (
  <GlobalContainer>
    <Header />
    <Main>{children}</Main>
  </GlobalContainer>
)

export default DefaultLayout
