import { Container, Title, FaqItemContainer } from './style'
import FaqItem from './faq-item'

const Faq = () => {
  return (
    <Container>
      <Title>Nos questions les plus fréquentes</Title>
      <div>
        <FaqItemContainer>
          <FaqItem
            question='Quels sont les frais de livraison ?'
            text={
              <img
                src='/assets/images/delivery-prices.png'
                alt='delivery-prices'
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'block',
                }}
              />
            }
          />
        </FaqItemContainer>

        <FaqItemContainer>
          <FaqItem
            question='Quelles sont les méthodes de paiement?'
            text={
              <div>
                <p>Plusieurs options de paiement s’offrent à vos clients :</p>
                <ul>
                  <li>
                    Carte bancaire débit ou crédit (VISA, Mastercard, Maestro,
                    AMEX, Bancontact){' '}
                  </li>
                  <li>Virement PayPal, Google Pay ou par iDeal</li>
                  <li>Paiement en 3 fois sans frais avec Alma</li>
                </ul>
                <p>
                  Vous pouvez également nous appeler pour passer commande par
                  téléphone.
                </p>
                <p>
                  Si aucun de ces moyens de paiement ne vous convient, contactez
                  nous via la bulle de chat !
                </p>
              </div>
            }
          />
        </FaqItemContainer>
        <FaqItemContainer>
          <FaqItem
            question='Puis-je modifier ma recommandation de produit ?'
            text={
              <p>
                Oui il vous suffit de supprimer les produits que vous ne
                souhaitez pas commander ou cliquer sur “ajouter des produits”.
              </p>
            }
          />
        </FaqItemContainer>
      </div>
    </Container>
  )
}

export default Faq
