import { useState } from 'react'

import {
  Container,
  Img,
  Name,
  InfoContainer,
  Info,
  Price,
  P,
  Left,
  Right,
  Quantity,
  Minus,
  Number,
  Plus,
  MoreInfo,
  IconImg,
  IconWrapper,
  NotesContainer,
  NotesContainerTitle,
  BrandImg,
  NotesContent,
  H4,
} from './style'
import toast from 'react-hot-toast'
import ProductDialog from '../product-dialog/index'
import { useGetProductDosingByProductId } from '../../../services/api/dosings/dosings'
import { PrescriptionRecommendedDuration } from '../../../services/api/types'
import { useGetProductByIdOrHandle } from '../../../services/api/product/product'
import { ProductTileProps } from './props'
import {
  getTakePackagingFormatName,
  getTakeFormatName,
  getTimingDescription,
  getDuration,
  getNumberBetweenUnderscores,
} from '../../../utils/helpers'
import Pricing from './pricing'

const ProductTile = ({
  item,
  cartDuration,
  isStandAlone = false,
  abilityToManageQuantity = false,
  updateVariantToCart,
  removeVariantsToCart,
  editMode = false,
  nodeId,
  attributes,
  prescriberName,
  isMarketplacePrescription,
  price,
  subtotalAmount,
  totalAmount,
}: ProductTileProps) => {
  // Attributes
  const { data: product, isLoading } = useGetProductByIdOrHandle(
    item?.product?.id
  )
  const variant = product?.variants?.find(
    i => i?.id === item?.product?.variantId
  )

  const { data: dosings } = useGetProductDosingByProductId(product?.id)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const productAttributes = attributes?.map(i => ({
    key: i?.key,
    value: i?.value,
  }))

  // Functions
  const normalizeCartDuration = duration => {
    if (duration === PrescriptionRecommendedDuration.ONE_MONTH) {
      return ''
    } else if (duration === PrescriptionRecommendedDuration.TWO_MONTH) {
      return 2
    } else if (duration === PrescriptionRecommendedDuration.THREE_MONTH) {
      return 3
    } else {
      return ''
    }
  }

  // Render
  return (
    <Container>
      <Img
        onClick={() => setIsDialogOpen(true)}
        alt={item?.product?.name}
        src={product?.coverImage}
      />

      <InfoContainer>
        <Info>
          <Left>
            <Name onClick={() => setIsDialogOpen(true)}>
              {item?.product?.name}
              <MoreInfo>Plus d'infos</MoreInfo>
              <BrandImg
                alt={item?.product?.name}
                src={product?.brand?.iconUrl}
              />
            </Name>
            {isMarketplacePrescription ? (
              <div>
                <P>
                  {item.quantity}{' '}
                  {getTakePackagingFormatName(
                    item.quantity,
                    dosings?.packagingFormat
                  )}{' '}
                  de {getNumberBetweenUnderscores(variant?.title)}{' '}
                  {getTakeFormatName(
                    getNumberBetweenUnderscores(variant?.title),
                    dosings?.takeFormat
                  )}
                </P>
                <H4>Instructions de prise:</H4>
                <P>
                  {product?.brandInstructionQuantity}
                  {getTakeFormatName(
                    dosings?.takeTotalQuantity,
                    dosings?.takeFormat
                  )}{' '}
                  {getTimingDescription(item.instructionTiming)}{' '}
                  {getDuration(item.instructionDuration)}
                </P>
              </div>
            ) : (
              <P>
                {!isStandAlone
                  ? `${item?.quantity} x  ${`30 gélules en sachet` ?? ''}`
                  : `${item?.quantity ?? 0} ${getTakePackagingFormatName(
                      item?.quantity,
                      dosings?.packagingFormat
                    )} de ${dosings?.takeTotalQuantity ?? ''} 
                  ${`${getTakeFormatName(
                    dosings?.takeTotalQuantity,
                    dosings?.takeFormat
                  )}`} (${dosings?.contentTotal ?? ''} ${
                      dosings?.contentUnit ?? ''
                    })
                  `}
              </P>
            )}
            {item?.note && (
              <NotesContainer>
                <NotesContent>
                  <NotesContainerTitle>
                    Conseils d'utilisations
                    {prescriberName ? ` de ${prescriberName}` : ``}:
                  </NotesContainerTitle>
                  {/*Removed ReadMoreReadLess because it's not a good library */}
                  {item?.note}
                </NotesContent>
              </NotesContainer>
            )}
            {editMode && abilityToManageQuantity && (
              <Quantity>
                <Minus
                  onClick={() => {
                    if (item?.quantity === 1)
                      removeVariantsToCart && removeVariantsToCart([nodeId])
                    else
                      updateVariantToCart &&
                        updateVariantToCart(
                          nodeId,
                          item?.quantity - 1,
                          productAttributes
                        )
                  }}
                />

                <Number>
                  {item?.quantity
                    ? item?.quantity * item?.product?.formatQuantity
                    : item?.product?.formatQuantity}
                </Number>

                <Plus
                  onClick={() =>
                    updateVariantToCart &&
                    updateVariantToCart(
                      nodeId,
                      item?.quantity + 1,
                      productAttributes
                    )
                  }
                />
              </Quantity>
            )}
          </Left>
          <Right>
            <IconWrapper>
              {abilityToManageQuantity && (
                <IconImg
                  onClick={() => {
                    toast.promise(
                      removeVariantsToCart && removeVariantsToCart([nodeId]),
                      {
                        loading: 'en cours...',
                        success: 'Le produit a bien été supprimé',
                        error: 'Oops! Une erreur est survenue.',
                      },
                      {
                        duration: 3000,
                      }
                    )
                  }}
                  src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/Delete.png?alt=media&token=b87ddd79-e71f-4929-80b7-1df8f811f8b1'
                />
              )}
            </IconWrapper>

            <Pricing
              price={item?.product?.variantPrice}
              quantity={item?.quantity}
              isStandAlone={isStandAlone}
              cartDuration={cartDuration}
              subtotalAmount={subtotalAmount}
              totalAmount={totalAmount}
            />
          </Right>
        </Info>
      </InfoContainer>
      <ProductDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        product={product}
        isProductLoading={isLoading}
      />
    </Container>
  )
}

export default ProductTile
