import { BsArrowClockwise } from 'react-icons/bs'
import { BrandInstructionTiming } from '../../../../../services/api/types/brandInstructionTiming'
import { PrescriptionValueToChange } from '../../../../../utils/constants/prescription-value-to-change'
import {
  getDuration,
  getTakeFormatName,
  getTimingDescription,
} from '../../../../../utils/helpers'
import Tooltip from '../../../../ui/tooltip'
import SelectInput from '../select-input'
import { ProductTakingInstructionsProps } from './props'
import {
  AdjustmentItem,
  AdjustmentItemQuantityLeft,
  AdjustmentItemQuantityRight,
  AdjustmentWrapper,
  MenuItem,
  MenuItemInput,
  MenuItemLabel,
  ReturnIcon,
  SubMenu,
} from './style'

const ProductTakingInstructions = ({
  instructionQuantity,
  setInstructionQuantity,
  product,
  selectedTimings,
  selectedDurations,
  reset,
  durationArray,
  setSelectedDurations,
  setSelectedTimings,
  takeIndex,
  setValueInPrescription,
  setDraftChanged,
}: ProductTakingInstructionsProps) => {
  // Attributes
  let timingOptions = Object.values(BrandInstructionTiming)
  timingOptions = timingOptions.filter(i => i !== 'UNKNOWN')

  // Functions
  const handleTimingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDraftChanged && setDraftChanged(true)
    setSelectedTimings(e.target.value as any)
    setValueInPrescription(
      product,
      e.target.value,
      takeIndex,
      PrescriptionValueToChange.INSTRUCTION_TIMING
    )
  }

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDraftChanged && setDraftChanged(true)
    setSelectedDurations(e.target.value as any)
    setValueInPrescription(
      product,
      e.target.value,
      takeIndex,
      PrescriptionValueToChange.INSTRUCTION_DURATION
    )
  }

  return (
    <AdjustmentWrapper>
      <AdjustmentItem>
        <AdjustmentItemQuantityRight
          onClick={() => {
            if (instructionQuantity > 0) {
              setDraftChanged && setDraftChanged(true)
              setInstructionQuantity(instructionQuantity - 1)
            }
          }}
        >
          -
        </AdjustmentItemQuantityRight>
        <div>
          {instructionQuantity}{' '}
          {getTakeFormatName(instructionQuantity, product?.takeFormat)}
        </div>
        <AdjustmentItemQuantityLeft
          onClick={() => {
            setDraftChanged && setDraftChanged(true)
            setInstructionQuantity(instructionQuantity + 1)
          }}
        >
          +
        </AdjustmentItemQuantityLeft>
      </AdjustmentItem>

      <SelectInput
        text={getTimingDescription(selectedTimings)}
        content={
          <SubMenu>
            {timingOptions.map(timing => (
              <MenuItem key={timing}>
                <MenuItemLabel>
                  <MenuItemInput
                    type='radio'
                    value={timing}
                    checked={selectedTimings?.includes(timing)}
                    onChange={handleTimingChange}
                  ></MenuItemInput>

                  {getTimingDescription(timing)}
                </MenuItemLabel>
              </MenuItem>
            ))}
          </SubMenu>
        }
      />

      <SelectInput
        text={getDuration(selectedDurations)}
        content={
          <SubMenu>
            {durationArray?.map(duration => (
              <MenuItem key={duration}>
                <MenuItemLabel>
                  <MenuItemInput
                    type='radio'
                    value={duration}
                    checked={selectedDurations === (duration as any)}
                    onChange={handleDurationChange}
                  />
                  {getDuration(duration)}
                </MenuItemLabel>
              </MenuItem>
            ))}
          </SubMenu>
        }
      />

      <Tooltip
        size='l'
        label={'Appliquer à nouveau la posologie du laboratoire'}
      >
        <ReturnIcon onClick={reset}>
          <BsArrowClockwise />
        </ReturnIcon>
      </Tooltip>
    </AdjustmentWrapper>
  )
}

export default ProductTakingInstructions
