import styled from 'styled-components/macro'

export const Result = styled.p`
  font-size: 1rem;
  opacity: 0.8;
  margin-left: 0.3125rem;
  padding-right: 0.5rem;
  font-weight: 500;
`
export const ResultWrapper = styled.div`
  display: flex;
  height: 1.625rem;
  align-items: center;
`

export const ResultRow = styled.div`
  width: 100%;
  display: contents;
  flex-wrap: wrap;
  gap: 0.625rem;
`

export const ResultWithActionRow = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.25rem;
  gap: 1.25rem;
  width: 100%;
`

export const ResultAndActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const RightResultWithActionRow = styled.div`
  gap: 0.75rem;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

export const BottomSortByWrapper = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const InputSortBy = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  width: 13.125rem;
  height: auto;
  padding: 12 0;
  flex-direction: column;
  gap: 0.75rem;
`

export const InputDropdown = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
