/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PrescriptionStatus =
  | 'CREATED'
  | 'SENT'
  | 'ORDERED'
  | 'MODIFIED'
  | 'DONE'
  | 'TO_RELAUNCH'
  | 'RELAUNCHED'
  | 'ARCHIVED'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrescriptionStatus = {
  CREATED: 'CREATED' as PrescriptionStatus,
  SENT: 'SENT' as PrescriptionStatus,
  ORDERED: 'ORDERED' as PrescriptionStatus,
  MODIFIED: 'MODIFIED' as PrescriptionStatus,
  DONE: 'DONE' as PrescriptionStatus,
  TO_RELAUNCH: 'TO_RELAUNCH' as PrescriptionStatus,
  RELAUNCHED: 'RELAUNCHED' as PrescriptionStatus,
  ARCHIVED: 'ARCHIVED' as PrescriptionStatus,
}
