import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../../utils/constants'

export const Container = styled.div`
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  font-size: 13px;
  border-radius: 10px;
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
`
export const List = styled.ul`
  margin-bottom: 20px;
  list-style: none;
  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
`

export const Item = styled.li`
  margin-left: 20px;
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`

export const ItemTitleWrapper = styled.div`
  width: 30%;
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
    margin-bottom: 10px;
  }
`
export const ItemTitle = styled.h4`
  letter-spacing: 0px;
  color: #3a835f;
  opacity: 1;
`
export const ItemDescription = styled.div`
  width: 40%;
  color: #393360;
  opacity: 1;
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const ItemDate = styled.p`
  color: #393360;
  opacity: 1;
  font-size: 10px;
`

export const MoreButton = styled.a`
  width: 20%;
  cursor: pointer;
  text-align: left;
  font: normal normal medium 14px/28px Basis Grotesque;
  letter-spacing: 0px;
  color: #393360;
  opacity: 1;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
`

export const MoreDescription = styled.p`
  margin-top: 5px;
  color: #393360;
`

export const SeeMore = styled.button`
  display: block;
  cursor: pointer;
  font-size: 12px;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  margin: 6px 0;
`
