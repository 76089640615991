import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const BodyContainer = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`
export const MainContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 3.75rem;
  margin: 0 10px;

  ${MediaQuery.M_AND_UP} {
    width: 600px;
    margin: 0 auto;
  }
`

export const FooterTextContent = styled.p`
  display: inline;
  cursor: pointer;
  font-size: 12px;
  color: #8f95b2;
  text-align: right;
  margin-bottom: 32px;
`

export const Title = styled.h1`
  color: #080038;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 15.625rem;
  margin-top: 3rem;

  .partword {
    color: #3a845e;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
`
export const Link = styled.a`
  color: #8f95b2;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 2.8125rem;
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  ${MediaQuery.M_AND_UP} {
    position: relative;
    margin-top: 40px;
  }
`

export const Footer = styled.p`
  color: #080038;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  .partword {
    color: #f46363;
    cursor: pointer;
  }
`

export const Form = styled.div`
  display: flex;
  margin-top: 1.875rem;
  flex-direction: column;
  width: 100%;
  margin-top: 1.875rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  > button {
    margin-top: 20px;
  }
`
