import { SearchPatientProps } from './props'
import SearchPatientInput from './input'
import SearchPatientList from './patient-list'

const SearchPatient = ({
  email,
  setShowPatientList,
  setEmail,
  addEmail,
  patients,
  showPatientList,
  isLoading,
  emails,
  setIsInnerDialogOpen,
}: SearchPatientProps) => {
  return (
    <>
      <SearchPatientInput
        setShowPatientList={setShowPatientList}
        setEmail={setEmail}
        addEmail={addEmail}
        email={email}
        patients={patients}
        setIsInnerDialogOpen={setIsInnerDialogOpen}
      />
      <SearchPatientList
        showPatientList={showPatientList}
        isLoading={isLoading}
        patients={patients}
        emails={emails}
        setEmail={setEmail}
        addEmail={addEmail}
        setShowPatientList={setShowPatientList}
      />
    </>
  )
}

export default SearchPatient
