import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useQueryClient } from 'react-query'
import {
  Container,
  Image,
  Content,
  HeaderContent,
  Price,
  FavIcon,
  DetailsButton,
  ButtonWrapper,
  BrandImage,
} from './style'
import { AiFillStar } from 'react-icons/ai'
import { useDeletePrescriberFavoriteListItemByProductIdAndListId } from '../../../../services/api/favorites/favorites'
import { useGetMe } from '../../../../utils/hooks/getMe'
import { useQuery } from '../../../../utils/hooks/getQuery'
import ProductPreviewCardDetailsDialog from '../../product-preview-card/product-details-dialog'
import { forwardRef } from 'react'
import { getLowestPrice } from '../../../../utils/helpers'
import AddToFavorite from './add-to-favorite'
import DeleteToFavorite from './delete-to-favorite'

export interface Props {
  product: any
  mode: string
  allowDeleteFromFavoriteList?: boolean
  setIsInnerDialogOpen?: (v: boolean) => void
}

const ProductItem = forwardRef(
  (
    { product, mode, allowDeleteFromFavoriteList, setIsInnerDialogOpen }: Props,
    ref: any
  ) => {
    // Attributes
    const [isDetailModalOpen, setIsDetailsModalOpen] = useState(false)
    const { data: prescriber } = useGetMe()

    const queryClient = useQueryClient()
    const query = useQuery()
    const favoriteListId = query.get('favoriteListId')

    // Functions to refetch favorite list items and favorite lists after an action is performed
    function refetchFavoriteListItemByProductI() {
      queryClient.invalidateQueries([
        `/prescribers/${prescriber?.id}/products/${product?.id}/favorite-lists`,
      ])
    }
    function refetchFavorite() {
      queryClient.invalidateQueries([`/favorite-lists/${favoriteListId}/items`])
    }
    function refetchAllFavorite() {
      queryClient.invalidateQueries([
        `/prescribers/${prescriber?.id}/favorite-lists/items`,
      ])
    }

    const {
      mutateAsync: deletePrescriberFavoriteListItemByProductIdAndListId,
    } = useDeletePrescriberFavoriteListItemByProductIdAndListId({
      mutation: {
        onSuccess: () => {
          toast.success('Le produit a bien été supprimé')
          refetchFavorite()
          refetchAllFavorite()
          refetchFavoriteListItemByProductI()
        },
        onError: error => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })

    // Functions
    const handleDeletePrescriberFavoriteListItemByProductIdAndListId = () => {
      deletePrescriberFavoriteListItemByProductIdAndListId({
        favoriteListId: favoriteListId,
        productId: product?.id,
      })
    }

    // Hooks
    useEffect(() => {
      setIsInnerDialogOpen && setIsInnerDialogOpen(!isDetailModalOpen)
    }, [isDetailModalOpen])

    // Render
    return (
      <Container $mode={mode} ref={ref}>
        {allowDeleteFromFavoriteList && (
          <>
            {favoriteListId ? (
              <FavIcon
                onClick={
                  handleDeletePrescriberFavoriteListItemByProductIdAndListId
                }
              >
                <AiFillStar />
              </FavIcon>
            ) : (
              <DeleteToFavorite
                favoriteListId={favoriteListId}
                productId={product?.id}
              />
            )}
          </>
        )}
        <Image
          onClick={() => setIsDetailsModalOpen(true)}
          src={product?.coverImage}
          $mode={mode}
        />
        <Content>
          <HeaderContent>
            <h4 onClick={() => setIsDetailsModalOpen(true)}>{product?.name}</h4>
            {product?.brand?.iconUrl && (
              <BrandImage
                src={product?.brand?.iconUrl}
                alt={product?.brand?.name}
                style={{ width: 60, height: 60, marginTop: 10 }}
              />
            )}
          </HeaderContent>
          <Price>{getLowestPrice(product?.variants)}€</Price>
          <ButtonWrapper>
            <AddToFavorite
              productId={product?.id}
              allowDeleteFromFavoriteList={allowDeleteFromFavoriteList}
              favoriteListId={favoriteListId}
            />
            {allowDeleteFromFavoriteList && (
              <DetailsButton onClick={() => setIsDetailsModalOpen(true)}>
                Voir les détails
              </DetailsButton>
            )}
          </ButtonWrapper>
        </Content>

        {isDetailModalOpen && (
          <ProductPreviewCardDetailsDialog
            isOpen={isDetailModalOpen}
            onClose={() => setIsDetailsModalOpen(false)}
            handle={product.handle}
            doNotAllowProductToBeAdded
          />
        )}
      </Container>
    )
  }
)

export default ProductItem
