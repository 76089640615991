import { ProductDialogWarningProps } from './props'
import { Container, Content, Title } from './style'

const ProductDialogWarning = ({ content }: ProductDialogWarningProps) => {
  // Render
  return (
    <Container>
      <Title>Ce produit n'est plus disponible</Title>
      {content && <Content>{content}</Content>}
    </Container>
  )
}

export default ProductDialogWarning
