import styled from 'styled-components/macro'

export const Container = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  max-width: 100%;
  padding: 30px 20px 30px 20px;
`

export const Title = styled.h2`
  color: #333;
  font-size: 14px;
`

export const Description = styled.p`
  opacity: 0.6;
  font-size: 13px;
  margin-top: 10px;
`

export const Button = styled.button`
  cursor: pointer;
  margin-top: 21px;
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid #3a845e;
  background-color: transparent;
  font-size: 13px;
  color: #3a845e;
  border-radius: 4px;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
