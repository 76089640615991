import {
  PrescriptionItemDTO,
  PrescriptionTakeDTO,
} from '../../services/api/types'
import { getProductVariantId } from '../../utils/data'
import { getProductByIdOrHandle } from '../../services/api/product/product'
import { PackagingType } from '../../services/api/types'

// Get subscription price (only 1-month enabled)
export const mapSubscriptionLines = async (
  cart: any,
  packagingType: PackagingType = 'BOTH'
) => {
  let product
  return await Promise.all(
    cart?.lines?.edges?.map(async i => {
      product = await getProductByIdOrHandle(
        i.node.merchandise.product.handle,
        {
          packagingType,
        }
      )

      const variantId = getProductVariantId(product, 'ONE_MONTH-subscription')

      const sellingPlanId =
        i.node.merchandise.sellingPlanAllocations.edges.find(
          alloc => alloc.node.sellingPlan.name === 'Delivery every 30 Days'
        )?.node.sellingPlan.id
      const attributes = i.node.attributes.map(i => ({
        key: i.key,
        value: i.value,
      }))

      return {
        id: i.node.id,
        merchandiseId: variantId,
        sellingPlanId,
        attributes,
      }
    })
  )
}

// Get one time price
export const mapOneTimePurchaseLines = async (
  cart: any,
  duration: string,
  packagingType: PackagingType = 'BOTH'
) => {
  let product
  return await Promise.all(
    cart?.lines?.edges?.map(async i => {
      product = await getProductByIdOrHandle(
        i.node.merchandise.product.handle,
        {
          packagingType,
        }
      )
      const variantId = getProductVariantId(product, duration)

      const attributes = i.node.attributes.map(i => ({
        key: i.key,
        value: i.value,
      }))

      return {
        id: i.node.id,
        merchandiseId: variantId,
        sellingPlanId: null,
        attributes,
      }
    })
  )
}

export const mapShopifyCartToPrescriptionTakeDTOs = async (
  cart: any,
  takes: PrescriptionTakeDTO[] = [],
  packagingType: PackagingType = 'BOTH'
): Promise<PrescriptionTakeDTO[]> => {
  if (!cart?.lines?.edges?.length) return []

  const edges = cart.lines.edges

  let items: PrescriptionItemDTO[] = [] // Initialize items array

  for (let i = 0; i < edges.length; i++) {
    const edge = edges[i]

    // Get product
    const product = await getProductByIdOrHandle(
      edge.node.merchandise.product.handle,
      {
        packagingType,
      }
    )

    const item: PrescriptionItemDTO = {
      product: {
        id: product?.id,
        formatQuantity: Number(product?.formatQuantity),
        formatType: product?.formatType,
        handle: product?.handle,
        name: product?.name,
        pillPicture: product?.pillPicture,
        variantId: edge.node.merchandise.id,
        variantPrice: product?.variants?.find(
          variant => variant.title === edge.node.merchandise.title
        )?.price,
      },
      instructionDuration: product?.brandInstructionDuration,
      instructionTiming: product?.brandInstructionTiming,
      instructionQuantity: product?.brandInstructionQuantity,
      quantity: edge.node.quantity,
      note: edge.node.attributes.find(attr => attr.key === 'notes')?.value,
    }

    items.push(item) // Push the item into the items array
  }

  takes.push({
    // Push the whole items array into the takes array
    type: 'IN_CURE',
    items: items,
  })

  return takes
}
