import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Container = styled.article`
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: flex-start;
  position: relative;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  margin-bottom: 20px;
`

export const Img = styled.img`
  cursor: pointer;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 10px;
  background-color: #faf2e7;
  margin-right: 20px;
`
export const IconImg = styled.img`
  cursor: pointer;
  width: 15px;
  height: 15px;
  object-fit: cover;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Info = styled.div`
  display: flex;
  min-height: 40px;
`
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`
export const Right = styled(Left)`
  flex: 0 1 40px;
`

export const Name = styled.h6`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 5px;
`

export const Price = styled.p`
  font-size: 16px;
  text-align: right;
  white-space: nowrap;

  > small {
    font-size: 14px;
  }
`

export const P = styled.p`
  font-size: 11px;
  opacity: 0.6;
  text-transform: lowercase;
`
export const NotesContent = styled.p`
  display: inline-block;
  font-size: 11px;
  opacity: 0.6;

  padding: 10px;
  background-color: ${Color.MYSTIC};
  border-radius: 10px;
`

export const NotesContainer = styled.div`
  display: inline-block;
  margin-top: 10px;
`

export const Quantity = styled.div`
  margin-top: 8px;
  display: flex;
  font-size: 12px;
  align-items: center;
`

export const Minus = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.MYSTIC};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    border-bottom: 2px solid black;
    width: 6px;
    border-radius: 5px;
  }
`

export const Plus = styled(Minus)`
  &:before {
    content: '';
    border-left: 2px solid black;
    height: 6px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 5px;
  }
`

export const Number = styled.span`
  display: inline-flex;
  justify-content: center;
  min-width: 30px;
`
export const MoreInfo = styled.span`
  font-weight: 300;
  font-size: 10px;
  cursor: pointer;
  margin-left: 5px;
  color: #65b293;
`

export const Remvove = styled.span`
  cursor: pointer;
  margin-left: 15px;
  margin-top: 20px;
`

export const NotesTooltip = styled.div`
  min-width: 250px;
  background-color: white;
  padding: 20px;
  text-align: start;
  color: black;
  h4 {
    font-size: 15px;
    margin-bottom: 20px;
  }
  p {
    background-color: #f4f6fa;
    font-size: 13px;
    font-weight: 300;
    padding: 10px;
  }
`

export const BrandImg = styled.img`
  object-fit: contain;
  width: 55px;
  margin-left: 15px;
`

export const NotesContainerTitle = styled.h4`
  font-size: 12px;
  margin-bottom: 3px;
`

export const H4 = styled.h4`
  font-size: 12px;
  margin: 5px 0 1px 0;
`
