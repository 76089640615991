import styled from 'styled-components/macro'

export const Container = styled.div`
  min-width: 76px;
  max-width: 76px;
  border-radius: 35px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 2px;
  border-width: 1px;
  color: ${props => props.color};
  border: solid;
`
