import styled from 'styled-components/macro'

export const Container = styled.div`
  margin-top: 30px;
`

export const Title = styled.h4`
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
`

export const SubTitle = styled.h3`
  font-size: 14px;
  margin-bottom: 20px;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const Input = styled.input`
  border: 1px solid #e9eaf0;
  width: 100%;
  padding: 10px;
  outline: none;
`

export const CheckboxWrapper = styled.div`
  margin-top: 0px;
  display: flex;
  align-items: center;
  font-size: 12px;
  input {
    margin-right: 5px;
  }
`

export const Button = styled.button`
  margin-top: 20px;
  width: 100%;

  cursor: pointer;
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  background: #3a845e;
  border: 1px solid #3a845e;
  border-radius: 8px;
  color: white;
  white-space: nowrap;
`

export const Email = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  background: var(--BG, #f8f9fc);
  padding: 8px;
  font-size: 11px;
  font-weight: 300;
  border: ${props => (props.isPhoneNumberFieldFilled ? '1px solid red' : '')};
  margin-right: 10px;
  margin-bottom: 5px;

  span {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    margin-left: 5px;
  }
`

export const Emails = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`

export const InputWrapper2 = styled.div`
  display: flex;
`

export const AddButton = styled.button`
  border: none;
  background-color: #3a845e;
  font-family: 'System';
  font-weight: 500;
  font-size: 9px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 0 10px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`

export const ErrorMessage = styled.p`
  display: flex;
  margin-top: 20px;
  span {
    margin-top: 3px;
    display: block;
    min-width: 12px;
    height: 12px;
    background-color: red;
    margin-right: 5px;
  }
  font-size: 12px;
  color: red;
`

export const Message = styled.p`
  font-size: 12px;
  opacity: 0.6;
`
