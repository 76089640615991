import { GainsTablePatientProps } from './props'
import {
  Column,
  Container,
  Email,
  FullName,
  FullNameAbbr,
  IconContainer,
} from './style'

const GainsTablePatient = ({ fullName, email }: GainsTablePatientProps) => {
  // Attributes
  const fullNameSplitted = fullName.split(' ')

  // Render
  return (
    <Container>
      <IconContainer>
        <FullNameAbbr>
          {fullName
            ? `${fullNameSplitted?.[0]?.[0] ?? ''}${
                fullNameSplitted?.[1]?.[0] ?? ''
              }`
            : ''}
        </FullNameAbbr>
      </IconContainer>
      <Column>
        <FullName>{fullName}</FullName>
        <Email>{email}</Email>
      </Column>
    </Container>
  )
}

export default GainsTablePatient
