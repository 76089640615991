import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  margin: 0 auto 36px auto;

  display: flex;
  width: 100%;
`

export const NoteTakingWrapper = styled.div`
  width: 70%;
  background-color: white;
  padding: 40px 80px;
`

export const PatientInfoWrapper = styled.div`
  width: 30%;
  background-color: #fdfcfe;
  min-height: 100vh;
  padding: 20px;
`
