import { Container, Content, Image, Subtitle, Title } from './style'
import { NoFoundProps } from './props'
import { Button } from '../../ui'

const NoFound = ({
  title,
  descritpion,
  clearAllFilters,
  textButton = '  Effacer tous les filtres',
}: NoFoundProps) => {
  // Render
  return (
    <Container>
      <Image src='/assets/images/not-found.png' />
      <Content>
        <Title>{title}</Title>
        <Subtitle>{descritpion}</Subtitle>
        {clearAllFilters && (
          <Button
            appearance='primary'
            onClick={clearAllFilters}
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            {textButton}
          </Button>
        )}
      </Content>
    </Container>
  )
}

export default NoFound
