import styled from 'styled-components/macro'

export const InputButton = styled.button`
  cursor: pointer;
  border: 1px solid #e9eaf0;
  padding: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const Input = styled.input`
  border: 1px solid #e9eaf0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 8px;
  outline: none;
  width: 70%;
`

export const InputWrapper = styled.div`
  margin-top: 12px;
  min-width: 100%;
  position: relative;
`
export const PatientList = styled.div`
  background-color: white;
  max-height: 100px;
  overflow-y: scroll;
  width: 70%;
  position: absolute;
  top: 37px;
  left: 0;
  padding: 0 10px;
  border-radius: 3px;
`

export const PatientItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  margin: 10px 0;
`

export const Inital = styled.span`
  font-size: 9px !important;
  background-color: #fcf2ef;
  color: #d7603a;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
`

export const Email = styled.p`
  font-size: 14px;
`
