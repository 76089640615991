import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  width: 100%;

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h3``

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  z-index: 10;
  position: sticky;
  top: 0px;
  background-color: white;
  height: 70px;
  width: 100%;
`

export const ButtonWrapper = styled.div``

export const NoProductsWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const NoProductsImage = styled.img``

export const NoProductsText = styled.p`
  margin: 20px 0;
  text-align: center;
  color: #080038;
  width: 300px;
`

export const ProductsWrapper = styled.div`
  margin-bottom: 20px;
`
export const ProductsWrapperTitle = styled.h3`
  margin-bottom: 10px;
`
export const ProductsWrapperList = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const OrWrapper = styled.p`
  display: flex;
  align-items: center;
`

export const Or = styled.p`
  margin: 10px 10px;
  color: #999;
`

export const OrLine = styled.div`
  height: 1px;
  width: 80px;
  background-color: #9e9e9e;
  margin-top: 2px;
`
