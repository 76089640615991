import { RightElementsProps } from './props'
import RequestProduct from '../request-product'

const RightElements = ({ viewMode, setViewMode }: RightElementsProps) => {
  return (
    <div>
      <RequestProduct />
    </div>
  )
}

export default RightElements
