import styled from 'styled-components/macro'

export const SubTitle = styled.h3`
  font-size: 16px;
  margin-bottom: 20px;
`

export const SelectWrapper = styled.div``

export const Select = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  p {
    font-size: 15px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border: 1px solid black;
    border-radius: 100%;
    margin-right: 10px;
    span {
      width: 5px;
      height: 5px;
      border: 1px solid black;
      background-color: black;
      border-radius: 100%;
    }
  }
`
