import { useEffect, useState } from 'react'
import queryString from 'query-string'

import NakedLayout from '../../components/layout/naked'
import ResetPasswordPage from '../reset-password'
import EmailConfirmation from '../email-confirmation'
const UserActions = ({ location, history }) => {
  const [mode, setMode] = useState()
  const [oobCode, setOobCode] = useState()
  useEffect(() => {
    const { mode, oobCode } = queryString.parse(location.search)
    if (mode) setMode(mode)
    if (oobCode) setOobCode(oobCode)

    if (!mode || !oobCode) history.push('/')
  }, [location, history])

  return (
    <NakedLayout>
      {mode === 'resetPassword' && (
        <ResetPasswordPage mode={mode} oobCode={oobCode} history={history} />
      )}
      {mode === 'verifyEmail' && (
        <EmailConfirmation mode={mode} oobCode={oobCode} location={location} />
      )}
    </NakedLayout>
  )
}

export default UserActions
