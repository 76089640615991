import { Link } from 'react-router-dom'
import { Dialog } from '../../ui'
import { IconCross } from '../../ui/icon/icons'
import { CreateRecoDialogProps } from './props'
import {
  CloseButton,
  CloseButtonContainer,
  Container,
  Item,
  Title,
  SubTitle,
  Description,
  Button,
  ImgWrapper,
  HeaderItem,
  ImgMatketplace,
  ImgCompliment,
  ImgCompliment2,
} from './style'
import Matketplace from '../../../assets/images/marketplace.png'
import Compliment from '../../../assets/images/compliment.png'
import Compliment2 from '../../../assets/images/compliment2.png'

const CreateRecoDialog = ({
  isOpen,
  onClose,
  type,
  patientUrl,
}: CreateRecoDialogProps) => {
  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      // type='minimalCenter'
      type='large'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={onClose}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <Item>
          <HeaderItem>
            <ImgWrapper>
              <ImgCompliment src={Compliment} alt='Compliment' />
              <ImgCompliment2 src={Compliment2} alt='Compliment2' />
            </ImgWrapper>
            <Title>Compliment</Title>
            <SubTitle>En sachet personnalisé</SubTitle>
            <Description>
              <ul>
                <li>
                  Format: Sachet quotidien personnalisé
                  <ul>
                    <li>
                      Attention: Vous ne retrouverez que les produits de la
                      gamme Symbiose (choix limité)
                    </li>
                  </ul>
                </li>

                <li>
                  Laboratoire disponible: Simplycure
                  <ul>
                    <li>
                      Recommandez uniquement les produits de la gamme Simplycure
                    </li>
                  </ul>
                </li>
              </ul>
            </Description>
          </HeaderItem>
          <Link
            to={
              type === 'PRESCRIPTION'
                ? '/compliment-prescriptions/new'
                : '/protocoles/new?step=1'
            }
          >
            <Button>Créer une cure en sachet</Button>
          </Link>
        </Item>
        <Item>
          <HeaderItem>
            <ImgWrapper>
              <ImgMatketplace src={Matketplace} alt='Matketplace' />
            </ImgWrapper>
            <Title>Multi-marques</Title>
            <SubTitle>Packaging d'origine</SubTitle>
            <Description>
              <ul>
                <li>Format: pots, blisters… </li>
                <li>
                  Laboratoires disponibles: Tous.
                  <ul>
                    <li>
                      Recommandez les formulations des différents laboratoires
                      disponibles.
                    </li>
                  </ul>
                </li>
              </ul>
            </Description>
          </HeaderItem>
          <Link
            to={
              type === 'PRESCRIPTION'
                ? `/prescriptions/new?step=1${patientUrl}`
                : `/marketplace-protocoles/new?step=1&type=marketplace${patientUrl}`
            }
          >
            <Button>Créer une cure multi-marques</Button>
          </Link>
        </Item>
      </Container>
    </Dialog>
  )
}

export default CreateRecoDialog
