import { Container, IconWrapper } from './style'
import Cookies from 'js-cookie'
import { useGetDiscountWithPrescriberByCode } from '../../../services/api/discount/discount'
import { AiFillCheckCircle } from 'react-icons/ai'

const Discount = () => {
  const savedPromoCode = Cookies.get('promoCode')
  const savedPromoCodePercentage = Cookies.get('promoCodePercentage')

  const { data: discountWithPrescriberByCode } =
    useGetDiscountWithPrescriberByCode(savedPromoCode?.toUpperCase(), {
      query: {
        enabled: !!savedPromoCode,
      },
    })

  return discountWithPrescriberByCode ? (
    <Container>
      <IconWrapper>
        <AiFillCheckCircle />
      </IconWrapper>
      {Number(savedPromoCodePercentage) ? (
        <p>
          Le code " {discountWithPrescriberByCode?.code} " de{' '}
          {discountWithPrescriberByCode?.prescriberFirstName}{' '}
          {discountWithPrescriberByCode?.prescriberLastName} est appliqué à
          votre panier.
        </p>
      ) : (
        <p>
          Grâce à {discountWithPrescriberByCode?.prescriberFirstName}{' '}
          {discountWithPrescriberByCode?.prescriberLastName}, vous pouvez
          commander directement sur le catalogue Simplycure.
        </p>
      )}
    </Container>
  ) : (
    <></>
  )
}

export default Discount
