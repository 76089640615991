const Constants = {
  ADD_PRESCRIPTION_FAILED: `ADD_PRESCRIPTION_FAILED`,
  ADD_PRESCRIPTION_LOADING: `ADD_PRESCRIPTION_LOADING`,
  ADD_PRESCRIPTION_SUCCESS: `ADD_PRESCRIPTION_SUCCESS`,
  UPDATE_PRESCRIPTION_FAILED: `UPDATE_PRESCRIPTION_FAILED`,
  UPDATE_PRESCRIPTION_LOADING: `UPDATE_PRESCRIPTION_LOADING`,
  UPDATE_PRESCRIPTION_SUCCESS: `UPDATE_PRESCRIPTION_SUCCESS`,
  ADD_PRESCRIPTION_TAKE_LOADING: `UPDATE_PRESCRIPTION_TAKE_LOADING`,
  ADD_PRESCRIPTION_TAKE_FAILED: `UPDATE_PRESCRIPTION_TAKE_FAILED`,
  ADD_PRESCRIPTION_TAKE_SUCCESS: `UPDATE_PRESCRIPTION_TAKE_SUCCESS`,
  DELETE_PRESCRIPTION_TAKE_LOADING: `DELETE_PRESCRIPTION_TAKE_LOADING`,
  DELETE_PRESCRIPTION_TAKE_SUCCESS: `DELETE_PRESCRIPTION_TAKE_SUCCESS`,
  DELETE_PRESCRIPTION_TAKE_FAILED: `DELETE_PRESCRIPTION_TAKE_FAILED`,
  DELETE_PRESCRIPTION_FAILED: `DELETE_PRESCRIPTION_FAILED`,
  DELETE_PRESCRIPTION_LOADING: `DELETE_PRESCRIPTION_LOADING`,
  DELETE_PRESCRIPTION_SUCCESS: `DELETE_PRESCRIPTION_SUCCESS`,
  ADD_PRESCRIPTION_TAKE: 'ADD_PRESCRIPTION_TAKE',
  DECREASE_PRODUCT_QTTY_PRESCRIPTION_DRAFT:
    'DECREASE_PRODUCT_QTTY_PRESCRIPTION_DRAFT',
  FETCH_PRESCRIPTION_FAILED: `FETCH_PRESCRIPTION_FAILED`,
  FETCH_PRESCRIPTION_LOADING: `FETCH_PRESCRIPTION_LOADING`,
  FETCH_PRESCRIPTION_SUCCESS: `FETCH_PRESCRIPTION_SUCCESS`,
  FETCH_PRESCRIPTIONS_FAILED: `FETCH_PRESCRIPTIONS_FAILED`,
  FETCH_PRESCRIPTIONS_LOADING: `FETCH_PRESCRIPTIONS_LOADING`,
  FETCH_PRESCRIPTIONS_SUCCESS: `FETCH_PRESCRIPTIONS_SUCCESS`,
  INCREASE_PRODUCT_QTTY_PRESCRIPTION_DRAFT:
    'INCREASE_PRODUCT_QTTY_PRESCRIPTION_DRAFT',
  PUSH_PRESCRIPTION_STATUS_FAILED: `PUSH_PRESCRIPTION_STATUS_FAILED`,
  PUSH_PRESCRIPTION_STATUS_LOADING: `PUSH_PRESCRIPTION_STATUS_LOADING`,
  PUSH_PRESCRIPTION_STATUS_RESET: `PUSH_PRESCRIPTION_STATUS_RESET`,
  PUSH_PRESCRIPTION_STATUS_SUCCESS: `PUSH_PRESCRIPTION_STATUS_SUCCESS`,
  REMOVE_PRODUCT_FROM_PRESCRIPTION_DRAFT_TAKE:
    'REMOVE_PRODUCT_FROM_PRESCRIPTION_DRAFT_TAKE',
  RESET_NEW_PRESCRIPTION_DRAFT: `RESET_NEW_PRESCRIPTION_DRAFT`,
  RESET_DELETE_PRESCRIPTION: `RESET_DELETE_PRESCRIPTION`,
  SET_PROTOCOLE_TO_DRAFT: 'SET_PROTOCOLE_TO_DRAFT',
  SET_PRESCRIPTION_TO_DRAFT: 'SET_PRESCRIPTION_TO_DRAFT',
  RESET_PROTOCOLE_DRAFT: 'RESET_PROTOCOLE_DRAFT',
  SEND_BY_EMAIL_FAILED: `SEND_BY_EMAIL_FAILED`,
  SEND_BY_EMAIL_LOADING: `SEND_BY_EMAIL_LOADING`,
  SEND_BY_EMAIL_RESET: `SEND_BY_EMAIL_RESET`,
  SEND_BY_EMAIL_SUCCESS: `SEND_BY_EMAIL_SUCCESS`,
  SET_PRESCRIPTION_DRAFT_PRODUCT_TAKE: 'SET_PRESCRIPTION_DRAFT_PRODUCT_TAKE',
  SET_NEW_PRESCRIPTION_DRAFT: `SET_NEW_PRESCRIPTION_DRAFT`,
  SET_NEW_PRESCRIPTION_ID: 'SET_NEW_PRESCRIPTION_ID',
  SET_PRODUCT_NOTES_PRESCRIPTION_DRAFT_TAKE:
    'SET_PRODUCT_NOTES_PRESCRIPTION_DRAFT_TAKE',
  SET_PRESCRIPTION_TAKE_LABEL: 'SET_PRESCRIPTION_TAKE_LABEL',
  SET_IS_STATUS_SENDED_MANUALLY: 'SET_IS_STATUS_SENDED_MANUALLY',
  SET_IS_STATUS_SENDED_MANUALLY_RESET: 'SET_IS_STATUS_SENDED_MANUALLY_RESET',
  MODIFY_PRESCRIPTION_FAILED: 'MODIFY_PRESCRIPTION_FAILED',
  MODIFY_PRESCRIPTION_SUCCESS: 'MODIFY_PRESCRIPTION_SUCCESS',
  MODIFY_PRESCRIPTION_LOADING: 'MODIFY_PRESCRIPTION_LOADING',
  REMOVE_PRESCRIPTION_TAKE: 'REMOVE_PRESCRIPTION_TAKE',
}

export default Constants
