import HeaderOnlyLogo from '../../components/section/header-only-logo/HeaderOnlyLogo'
import { Image, Title, SubTitle, Container, Main } from './style'
import { Button } from '../../components/ui'
import { useHistory } from 'react-router'
import EmailConfirmationRegisterDialog from '../../components/auth/register-form/patient-registration/patient-registration-dialog'
import NakedLayout from '../../components/layout/naked/NakedLayout'

function EmailConfirmationRegister() {
  // ATRIBUTES
  const history = useHistory()
  const isMobile = window.innerWidth <= 768

  //FUNCTIONS
  const handleRedirect = () => {
    history.push(`/patient/login`)
  }
  //RENDER
  return isMobile ? (
    <div>
      <HeaderOnlyLogo />
      <Container>
        <Main>
          <Image src={'/assets/images/email.png'} alt={'email'} />
          <Title>Félicitations !</Title>
          <SubTitle>
            Votre compte est prêt. Un e-mail a été envoyé <br /> pour confirmer
            votre inscription. Veuillez <br /> vérifier votre boîte de
            réception. <br /> <br /> Merci !
          </SubTitle>
        </Main>
        <Button onClick={() => handleRedirect()}>Se connecter</Button>
      </Container>
    </div>
  ) : (
    <NakedLayout>
      <HeaderOnlyLogo />
      <EmailConfirmationRegisterDialog />
    </NakedLayout>
  )
}

export default EmailConfirmationRegister
