const CheckBig = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 25 25'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <rect
      x='0.828125'
      y='0.414062'
      width='24.1725'
      height='24.1725'
      rx='12.0863'
      fill='#65B293'
    />
    <path
      d='M17.5187 9.22266L10.9625 15.7789L7.98242 12.7988'
      stroke='white'
      strokeWidth='1.19204'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default CheckBig
