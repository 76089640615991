import {
  LabelDescription,
  QuestionContainer,
  Container,
  SalesContainer,
  SalesButton,
} from './style'
import { OnboardingStepProps } from './props'
import { Field, PhoneInput } from '../../../ui'
import { AverageMonthlyRange } from '../../../../services/api/types'

const OnboardingForm = ({
  values,
  setFieldValue,
  handleChange,
}: OnboardingStepProps) => {
  //Attributes
  const averageAppointementOptions = [
    { label: 'Entre 1 et 10', value: AverageMonthlyRange.LESS_10 },
    { label: 'Entre 10 et 20', value: AverageMonthlyRange['10_TO_20'] },
    { label: 'Plus de 20', value: AverageMonthlyRange.MORE_20 },
  ]
  const averageRecommendationsOptions = [
    { label: 'Entre 1 et 10', value: AverageMonthlyRange.LESS_10 },
    { label: 'Entre 10 et 20', value: AverageMonthlyRange['10_TO_20'] },
    { label: 'Plus de 20', value: AverageMonthlyRange.MORE_20 },
  ]

  //Function
  function updateSales(value: boolean) {
    setFieldValue('hasDemoSales', value)
  }
  function updateAppointment(value: AverageMonthlyRange) {
    setFieldValue('averageMonthlyAppointment', value)
  }
  function updateRecommendation(value: AverageMonthlyRange) {
    setFieldValue('averageMonthlyRecommendation', value)
  }

  //Render
  return (
    <Container>
      <QuestionContainer>
        <LabelDescription>
          Quelle est votre numéro de téléphone ?
        </LabelDescription>
        <PhoneInput
          name='phoneNumber'
          onChange={handleChange}
          value={values.phoneNumber ?? ''}
        />
      </QuestionContainer>
      <QuestionContainer>
        <LabelDescription>
          Avez-vous déjà eu une démonstration de la plateforme avec l’un de nos
          collaborateurs ?
        </LabelDescription>
        <SalesContainer>
          <SalesButton
            onClick={() => updateSales(true)}
            isSelected={values.hasDemoSales === true}
          >
            Oui
          </SalesButton>
          <SalesButton
            onClick={() => updateSales(false)}
            isSelected={values.hasDemoSales === false}
          >
            Non
          </SalesButton>
        </SalesContainer>
      </QuestionContainer>
      <QuestionContainer>
        <LabelDescription>
          En moyenne quel volume de consultations effectuez-vous par mois ?
        </LabelDescription>
        <Field
          name='averageMonthlyAppointment'
          type='select'
          placeholder='Nombre de consultation'
          options={averageAppointementOptions}
          onChange={option => updateAppointment(option.value)}
          label=''
          ref={undefined}
          id={undefined}
          onBlur={undefined}
          value={undefined}
          error={undefined}
          touched={undefined}
          isLoading={undefined}
          isRequired={undefined}
        />
      </QuestionContainer>
      <QuestionContainer>
        <LabelDescription>
          En moyenne à quelle fréquence recommandez-vous des compléments
          alimentaires chaque mois ?
        </LabelDescription>
        <Field
          name='averageMonthlyRecommendation'
          type='select'
          placeholder='Nombre de recommandations'
          options={averageRecommendationsOptions}
          onChange={option => updateRecommendation(option.value)}
          label=''
          ref={undefined}
          id={undefined}
          onBlur={undefined}
          value={undefined}
          error={undefined}
          touched={undefined}
          isLoading={undefined}
          isRequired={undefined}
        />
      </QuestionContainer>
    </Container>
  )
}

export default OnboardingForm
