import styled, { keyframes, css } from 'styled-components/macro'

import { Icon } from '../icon/style'
import { Color, MediaQuery } from '../../../utils/constants'

const spin = keyframes`
  100% { 
    transform: rotate(360deg);
  }
`

export const ButtonBase = styled.button`
  padding: 0.4375rem 1.5rem;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  text-align: center;
  opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : '1')};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  background-color: #3a845e;
  white-space: nowrap;
  &:hover {
    background-color: ${({ $isDisabled }) =>
      $isDisabled ? 'none' : 'rgba(0, 0, 0, 0.03)'};
    color: ${({ $isDisabled }) => ($isDisabled ? 'none' : '#3a845e')};
  }

  ${MediaQuery.M_AND_DOWN} {
    padding: 0.5rem 0.9375rem;
  }

  > ${Icon} {
    margin-right: ${({ $hasMarginRight }) =>
      $hasMarginRight ? '.3125rem' : '0'};
    animation: ${({ $isLoading }) =>
      $isLoading
        ? css`
            ${spin} 1s linear infinite
          `
        : 'none'};
  }
`

export const ButtonDefault = styled(ButtonBase)`
  border: 0.0625rem solid #ddd;
`

export const ButtonPrimary = styled(ButtonBase)`
  border: 0.0625rem solid ${Color.DEEP_SEA};
  color: white;
  background-color: ${Color.DEEP_SEA};

  &:hover {
    ${({ $isDisabled }) => {
      if (!$isDisabled) return `color: ${Color.DEEP_SEA};`
    }}
  }
`

export const Calendly = styled(ButtonBase)`
  border: 0.0625rem solid #0069ff;
  color: white;
  background-color: #0069ff;

  &:hover {
    ${({ $isDisabled }) => {
      if (!$isDisabled) return `color: #0069ff;`
    }}
  }
`

export const ButtonRed = styled(ButtonBase)`
  border: 0.0625rem solid ${Color.DANGER};
  background-color: ${Color.DANGER};

  &:hover {
    ${({ $isDisabled }) => {
      if (!$isDisabled) return `color: ${Color.DANGER};`
    }}
  }
`

export const ButtonMinimal = styled(ButtonBase)`
  border: 0.0625rem solid ${Color.DEEP_SEA};
  border: ${({ $border }) =>
    $border
      ? `.0625rem solid ${Color.DEEP_SEA}`
      : `.0625rem solid ${Color.TRANSPARENT}`};
  color: #3a845e;
  background-color: ${({ $isActive }) =>
    $isActive ? `rgba(0, 0, 0, 0.03)` : Color.TRANSPARENT};
`

export const ButtonUltraMinimal = styled(ButtonBase)`
  border: 0.0625rem solid ${Color.TRANSPARENT};
  background-color: ${Color.TRANSPARENT};
  padding: 0;

  &:hover {
    background-color: ${Color.TRANSPARENT};
    text-decoration: underline;
  }
`
