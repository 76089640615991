import styled from 'styled-components/macro'

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 12px;
  padding: 32px 28px;
  width: 600px;

  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const ImgWrapper = styled.div`
  background: #e0f0e9;
  border-radius: 8px;
  height: 150px;
  position: relative;
  overflow: hidden;
`

export const ImgMatketplace = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`
export const ImgCompliment = styled.img`
  position: absolute;
  top: 60px;
  left: -50px;
  width: 160px;
`
export const ImgCompliment2 = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
  height: 450px;
  text-align: center;
  padding: 24px 20px;
  background: #f8f9fc;
  border: 1px solid #e7e9f4;
  border-radius: 12px;
`
export const HeaderItem = styled.div``

export const Title = styled.h3`
  margin: 10px 0;
  font-size: 16px;
`
export const SubTitle = styled.h4`
  margin: 10px 0;
  font-size: 12px;
  opacity: 0.8;
`
export const Description = styled.div`
  margin: 10px 0;
  font-size: 12px;
  opacity: 0.6;

  ul {
    text-align: start;
  }
  li {
    margin: 8px 0 8px 0;
    margin-left: 10px;
  }
`
export const Button = styled.button`
  cursor: pointer;
  border: none;
  width: 100%;
  background: #3a845e;
  border-radius: 6px;
  padding: 8px 16px;
  color: white;
`
