import React, { useState, useEffect } from 'react'
import { DialogProps } from './props'
import {
  Backdrop,
  CloseIcon,
  ContentDiv,
  DialogWrapper,
  LastElement,
  Title,
  TitleWrapper,
} from './style'

export const FilterMobileDialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  children,
  title,
}) => {
  const toggleBodyScroll = (lock: boolean) => {
    if (lock) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }

  //Effect
  useEffect(() => {
    toggleBodyScroll(isOpen)

    return () => toggleBodyScroll(false)
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Backdrop isOpen={isOpen} onClick={onClose}>
      <DialogWrapper isOpen={isOpen} onClick={e => e.stopPropagation()}>
        <TitleWrapper>
          <CloseIcon onClick={onClose} size={'1.125rem'} />
          <Title>{title}</Title>
          <LastElement />
        </TitleWrapper>
        <ContentDiv>{children}</ContentDiv>
      </DialogWrapper>
    </Backdrop>
  )
}
