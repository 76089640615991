import { View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import Trustpilot from '../../../assets/images/trustpilot.png'
import Logodoc from '../../../assets/images/logodoc.png'
import { IBookletHeaderProps } from './props'
import { format } from 'date-fns'

const BookletHeader = ({
  prescriberFirstName,
  prescriberLastName,
  customMessage,
  title,
  prescriptionId,
  prescription,
}: IBookletHeaderProps) => {
  return (
    <View style={styles.header}>
      <View style={styles.section}>
        <View>
          <Image src={Logodoc} style={styles.logo} />
          <Image src={Trustpilot} style={styles.trustpilot} />
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.instructionTitle}>Comment procéder?</Text>
          <Text style={styles.instruction}>
            <Text>
              1. Scanner le QR code ci-dessous ou visiter le lien suivant:
              {'            '}
            </Text>
            <Text
              style={{
                fontWeight: 'black',
                marginBottom: '10px',
              }}
            >
              app.simplycure.com/r/{prescriptionId}
            </Text>
          </Text>
          <Text style={styles.instruction}>
            2. Découvrez la recommandation de{' '}
            <Text
              style={{
                color: '#00855A',
              }}
            >
              {prescriberFirstName} {prescriberLastName}
            </Text>
          </Text>
          <Text style={styles.instruction}>
            3. Passez commande et soyez livré dans les 48h
          </Text>
        </View>
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.recommendationBoxTitle}>
          Votre recommandation par{' '}
          <Text
            style={{
              color: '#00855A',
            }}
          >
            {prescriberFirstName} {prescriberLastName}
          </Text>
        </Text>
        {!!prescription && (
          <Text style={styles.prescriberText}>
            Créée
            <Text
              style={{
                color: '#00855A',
              }}
            >
              {prescription?.patient?.fullName &&
                ' pour ' + prescription?.patient?.fullName}
            </Text>
            <Text>
              {' le ' + format(new Date(prescription?.createdAt), 'dd/MM/yyyy')}
            </Text>
          </Text>
        )}
      </View>
      {(title || customMessage) && (
        <View style={styles.recommendationBox}>
          <Text style={styles.recommendationText}>{customMessage}</Text>
        </View>
      )}
    </View>
  )
}

export default BookletHeader

const styles = StyleSheet.create({
  recommendationBoxTitle: {
    fontSize: '14px', // Large text for the header
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  recommendationBox: {
    border: '1px solid #e7e9f4',
    padding: '10px 10px 7px 10px',
    borderRadius: '10px',
  },
  recommendationTitle: {
    fontSize: '14px', // Slightly smaller text for the recommendation title
    fontWeight: 'bold',
  },
  recommendationText: {
    fontSize: '9px', // Regular text size for the body of the recommendation
    lineHeight: '1.5',
  },
  prescriberText: {
    fontSize: '10px',
    alignSelf: 'center',
    paddingBottom: '9px',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section: {
    padding: '0px 10px 20px 10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
    fontSize: '12px',
    marginBottom: '20px',
  },

  contentContainer: {
    padding: 10,
    backgroundColor: '#EFF6F2',
    borderRadius: '4px',
    width: '330px',
  },

  trustpilotRating: {
    fontSize: 14,
    marginBottom: 5,
  },
  instructionTitle: {
    fontWeight: 'extrabold',
    fontSize: '12px',
    marginBottom: '10px',
  },
  instruction: {
    fontSize: '10px',
    marginBottom: '5px',
    lineHeight: '1.4px',
  },

  logo: {
    width: '150px',
    marginBottom: '20px',
  },
  trustpilot: {
    width: '170px',
  },
  header: {
    position: 'relative',
    width: '100%',
    marginBottom: '20px',
    padding: '20px',
  },
  ImageHeader: {
    position: 'absolute',
    objectFit: 'cover',
    top: 0,
    maxHeight: '100px',
    minHeight: '100px',
    maxWidth: '82vw',
    minWidth: '82vw',
    margin: '0 auto',
  },
  headerPresciberName: {
    fontSize: '16px',
    marginTop: '15px',
    marginBottom: '40px',
  },
  headerText: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
  messageWrapper: {
    fontSize: '11px',
    marginBottom: '20px',
  },
  by: {
    marginBottom: '10px',
    fontSize: '11px',
  },
  presciberName: {
    color: '#03885b',
    marginLeft: '10px',
  },
  firstPage: {
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: '20mm',
    boxSizing: 'border-box',
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100vw',
    padding: '20px',
    borderTop: '1px solid #eee',
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  footerText: {
    fontSize: '9px',
    maxWidth: '85%',
  },
})
