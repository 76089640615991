import styled from 'styled-components/macro'

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.5rem;
  background-color: #e0f0e9;
  border: 0.0625rem solid #c1e0d4;
  padding: 0.75rem 1.25rem;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
`

export const Title = styled.p`
  font-size: 0.875rem;
`

export const Description = styled.p`
  font-size: 0.75rem;
`
