import styled from 'styled-components/macro'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  > button {
    margin-top: 20px;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`

export const Span = styled.span`
  font-size: 14px;
  line-height: 20px;
  padding: 10px 30px;
  margin-bottom: 2px;
`
