import styled from 'styled-components/macro'

export const CardContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  width: 100%;
  height: 133px;
  background: #ffffff;
  /* Elevation/Light/Bottom/01 */
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 17px;
  width: 100%;
  height: 101px;
`

export const Initial = styled.span`
  font-size: 9px !important;
  background-color: #fcf2ef;
  color: #d7603a;
  padding: 5px;
  border-radius: 5px;
  text-transform: uppercase;
`

export const PatientContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
  height: 28px;
`

export const PatientInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 11px;
  width: 100%;
  height: 28px;
`

export const PatientAvatarContainer = styled.div`
  width: 28px;
  height: 28px;
`

export const PatientTextContainer = styled.div`
  font-family: 'System';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #080038;
`

export const PatientArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
  gap: 10px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  background: #f8f9fc;
  border-radius: 6px;
`

export const CardHorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #f8f9fc;
`

export const CardInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  height: 38px;
`

export const PatientInformationText = styled.div`
  font-family: 'System';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #6b6688;
`
