import { RewardFormDialogSuccessProps } from './props'
import {
  Button,
  Container,
  // Description,
  Header,
  Image,
  InfoContainer,
  Title,
} from './style'

const RewardFormDialogSuccess = ({ onClose }: RewardFormDialogSuccessProps) => {
  // Render
  return (
    <Container>
      <Header>
        <Image src='/assets/images/reward-dialog.png' width='100%' />
        <InfoContainer>
          <Title>Votre commande a été validée!</Title>
          {/* <Description>Description here...</Description> */}
        </InfoContainer>
      </Header>
      <Button onClick={onClose}>J'ai compris</Button>
    </Container>
  )
}

export default RewardFormDialogSuccess
