import { Link } from 'react-router-dom'

import { Container, Text, Title, Button } from './style'

interface NoteTitleProps {
  title: string
  description: string
  id: string
}

const NoteTitle = ({ title, description, id }: NoteTitleProps) => {
  // Render
  return (
    <Container>
      <Title>{title}</Title>
      {!!description && (
        <Text>
          {description.substring(0, 36)}...
          <Link to={`/note/${id}`}>
            <Button>Voir plus</Button>
          </Link>
        </Text>
      )}
    </Container>
  )
}

export default NoteTitle
