import styled from 'styled-components/macro'

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  padding: 32px 28px;
  width: 600px;

  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 25.7561px;
  line-height: 31px;
  color: #080038;
`

export const Button = styled.button`
  border-radius: 8px;
  border: none;
  background-color: #3a845e;
  cursor: pointer;
  font-weight: 500;
  font-size: 15.0244px;
  line-height: 18px;
  color: #ffffff;
  padding: 15px 10px;

  &:hover {
    background-color: #3a845e;
  }
`

export const Textarea = styled.textarea`
  height: 250px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  font-family: inherit;
`
