import styled from 'styled-components/macro'

export const Container = styled.div`
  padding: 30px;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`
export const Title = styled.h4``

export const BoxInformation = styled.div`
  box-shadow: 0px 0px 6px rgba(65, 91, 115, 0.1);
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 20px;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
export const BoxInformationRow = styled.div`
  display: flex;
`
export const BoxInformationRowItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const BoxInformationRowItemIcon = styled.span`
  margin-top: 4px;
  margin-right: 10px;
  font-size: 15px;
  padding: 4px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.bg ? props.bg : '')};
`
export const BoxInformationRowItemtext = styled.p``

export const Initial = styled.span`
  margin-right: 10px;
  font-size: 9px !important;
  background-color: #fcf2ef;
  color: #d7603a;
  padding: 5px;
  border-radius: 5px;
  text-transform: uppercase;
`
