import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../../utils/constants'

export const Wrapper = styled.form`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${MediaQuery.M_AND_DOWN} {
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 20px;
  }
`

export const Input = styled.input`
  height: 30px;
  font-size: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  width: 80%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid #e7e9f4;
  padding: 0 3px;
`

export const Button = styled.button`
  width: 20%;
  height: 30px;
  font-size: 10px;
  cursor: pointer;
  border: none;
  background-color: #e7e9f4;
  color: black;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0 8px;
`

export const EditIcon = styled.span`
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  opacity: 0.7;
`

export const EditWrapper = styled.div`
  display: flex;
`
export const PromoCodeText = styled.p`
  font-size: 12px;
`

export const AddPromoCodeButton = styled.p`
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
`
