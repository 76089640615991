import styled from 'styled-components/macro'

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  padding: 32px 28px;

  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
  color: #080038;
`

export const Subtitle = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #8f95b2;
  line-height: 17px;
`

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`

export const DownloadIcon = styled.span`
  margin-top: 5px;
  margin-right: 5px;
  font-size: 15px;
`
