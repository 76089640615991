type TakeTypeEnum = 'in-cure' | 'stand-alone'

export const TakeType = {
  IN_CURE: 'in-cure' as TakeTypeEnum,
  STAND_ALONE: 'stand-alone' as TakeTypeEnum,
}

export type StatusEnum = 'danger' | 'info' | 'success' | 'warning'

export const Status = {
  DANGER: 'danger' as StatusEnum,
  INFO: 'info' as StatusEnum,
  SUCCESS: 'success' as StatusEnum,
  WARNING: 'warning' as StatusEnum,
}

export type ViewModeEnum = 'list' | 'grid' | 'grid2' | 'slide'

export const ViewMode = {
  LIST: 'list' as ViewModeEnum,
  GRID: 'grid' as ViewModeEnum,
  GRID2: 'grid2' as ViewModeEnum,
  SLIDE: 'slide' as ViewModeEnum,
}

export type ViewModeFilterEnum = 'row' | 'column'

export const viewModeFilter = {
  ROW: 'row' as ViewModeFilterEnum,
  COLUMN: 'column' as ViewModeFilterEnum,
}

export enum Tab {
  TEMPLATE_CUSTOM = 'template-custom',
  TEMPLATE_COMPLIMENT = 'template-compliment',
  TEMPLATE_COMMUNITY = 'template-community',
}

export enum PageType {
  PRODUCT = 'productPage',
  RECOMMENDATION = 'recommendationPage',
  PRESCRIPTION = 'prescriptionPage',
  CART = 'CartPage',
  FAVORITE = 'FavoritePage',
}

export type Option = {
  value: string
  label: string
}

export const OptionList: Option[] = [
  { value: 'PERTINENCE', label: 'Pertinence' },
  { value: 'MOST_RECOMMENDED', label: 'Produits recommandé' },
  { value: 'A_Z', label: 'A - Z' },
  { value: 'Z_A', label: 'Z - A' },
  { value: 'HIGHER_PRICE', label: 'Prix croissant' },
  { value: 'LOWER_PRICE', label: 'Prix décroissant' },
]
