import styled from 'styled-components/macro'

export const Label = styled.label`
  font-size: 14px;
  font-weight: 700px;
  position: relative;

  ${({ isRequired }) => {
    if (isRequired) {
      return `
        &:before {
          content: '*';
          position: absolute;
          top; -5px;
          left: -7px;
          color: #f56c6c;
        }
      `
    }
  }}
`
