import React, { useState } from 'react'
import { Container, Button } from './style'
import { IoIosArrowDown } from 'react-icons/io'
import { CSSTransition } from 'react-transition-group'

const Collapse = ({ collapsed, children, headerText }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed)

  return (
    <Container>
      <Button
        onClick={e => {
          e.preventDefault()
          setIsCollapsed(!isCollapsed)
        }}
        $isCollapsed={isCollapsed}
      >
        {headerText}{' '}
        <span>
          <IoIosArrowDown />
        </span>
      </Button>
      <div
        className={`collapse-content ${
          !isCollapsed ? 'collapsed' : 'expanded'
        }`}
        aria-expanded={isCollapsed}
      >
        <CSSTransition
          unmountOnExit
          in={isCollapsed}
          timeout={200}
          classNames='animation'
        >
          <div>{children}</div>
        </CSSTransition>
      </div>
    </Container>
  )
}

export default Collapse
