/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { ProductClinicalStudyDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getClinicalStudiesByProductId = (
  productId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductClinicalStudyDTO[]>(
    { url: `/products/${productId}/clinical-studies`, method: 'get' },
    options
  )
}

export const getGetClinicalStudiesByProductIdQueryKey = (productId: string) => [
  `/products/${productId}/clinical-studies`,
]

export const useGetClinicalStudiesByProductId = <
  TData = AsyncReturnType<typeof getClinicalStudiesByProductId>,
  TError = unknown
>(
  productId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getClinicalStudiesByProductId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetClinicalStudiesByProductIdQueryKey(productId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getClinicalStudiesByProductId>
  > = () => getClinicalStudiesByProductId(productId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getClinicalStudiesByProductId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}
