import styled from 'styled-components/macro'

import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  max-width: ${({ width }) => width};
  width: 100%;

  ${MediaQuery.M_AND_DOWN} {
    max-width: 100%;
    width: 100%;
    border-radius: 0.5rem;
  }
`

export const InputContainer = styled.div`
  position: relative;
  max-width: 100%;
  background: #f8f9fc;
  ${MediaQuery.M_AND_DOWN} {
    background: none;
  }
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  background: white;
  top: 0.375rem;
  right: 0.625rem;
  border-radius: 50%;
  overflow: hidden;
  width: 1.5625rem;
  height: 1.5625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
