/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { BrandDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getAllBrands = (
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<BrandDTO[]>({ url: `/brands`, method: 'get' }, options)
}

export const getGetAllBrandsQueryKey = () => [`/brands`]

export const useGetAllBrands = <
  TData = AsyncReturnType<typeof getAllBrands>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getAllBrands>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllBrandsQueryKey()

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllBrands>> = () =>
    getAllBrands(requestOptions)

  const query = useQuery<AsyncReturnType<typeof getAllBrands>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  )

  return {
    queryKey,
    ...query,
  }
}

export const getBrandById = (
  brandId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<BrandDTO>(
    { url: `/brands/${brandId}`, method: 'get' },
    options
  )
}

export const getGetBrandByIdQueryKey = (brandId: string) => [
  `/brands/${brandId}`,
]

export const useGetBrandById = <
  TData = AsyncReturnType<typeof getBrandById>,
  TError = unknown
>(
  brandId: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getBrandById>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetBrandByIdQueryKey(brandId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getBrandById>> = () =>
    getBrandById(brandId, requestOptions)

  const query = useQuery<AsyncReturnType<typeof getBrandById>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!brandId, ...queryOptions }
  )

  return {
    queryKey,
    ...query,
  }
}
