/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type Country = 'BELGIQUE' | 'FRANCE' | 'LUXEMBOURG'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Country = {
  BELGIQUE: 'BELGIQUE' as Country,
  FRANCE: 'FRANCE' as Country,
  LUXEMBOURG: 'LUXEMBOURG' as Country,
}
