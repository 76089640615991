import styled from 'styled-components/macro'

export const SuccessRegistrationContainer = styled.div`
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  font-size: 22px;
  line-height: 28px;
  max-width: 450px;
  text-align: center;

  > a {
    color: inherit;
  }
`
