/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PushPrescriptionStatus = 'SENT' | 'MODIFIED' | 'DONE'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PushPrescriptionStatus = {
  SENT: 'SENT' as PushPrescriptionStatus,
  MODIFIED: 'MODIFIED' as PushPrescriptionStatus,
  DONE: 'DONE' as PushPrescriptionStatus,
}
