import { Container, ImageLogo, Title, Subtitle } from './style'
import OnboardingForm from './onboarding-step'
import useOnboardingLogic from './logic'
import Button from '../../ui/button'
import { InformationComponentProps } from './props'

const InformationsComponent = ({
  prescriber,
  prescriberDiscount,
  refetchPrescriber,
}: InformationComponentProps) => {
  //Attributes
  const {
    areAllFieldsFilled,
    isEditPrescriberLoading,
    values,
    setFieldValue,
    hanldeUpdatePrescriber,
    handleChange,
  } = useOnboardingLogic({ prescriber, prescriberDiscount, refetchPrescriber })

  // Render
  return (
    <Container>
      <ImageLogo src={'/assets/images/logo-compliment.png'} alt='logo-image' />
      <Title>Plus que quelques infromations !</Title>
      <Subtitle>
        Ces informations nous sont utiles afin de mieux vous accompagner !
      </Subtitle>
      <OnboardingForm
        values={values}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
      />
      <Button
        style={{
          width: '100%',
          marginTop: '1rem',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
        }}
        onClick={() => hanldeUpdatePrescriber(values)}
        appearance={'primary'}
        isLoading={isEditPrescriberLoading}
        isDisabled={!areAllFieldsFilled}
        iconLeft={undefined}
        isActive={undefined}
      >
        Valider
      </Button>
    </Container>
  )
}

export default InformationsComponent
