import { Link } from 'react-router-dom'
import {
  Column,
  Container,
  Email,
  FullName,
  FullNameAbbr,
  IconContainer,
} from './style'
import { patients } from '../../../utils/constants/colors'
import { useMemo } from 'react'
export interface PatientProps {
  firstName: string
  lastName: string
  email: string
  id: number
}

const PatientCell = ({ firstName, lastName, email, id }: PatientProps) => {
  // Attributes
  const color = useMemo(
    () => patients[Math.floor(Math.random() * patients.length)],
    [email]
  )

  // Render
  return (
    <Container>
      {email ? (
        <>
          <IconContainer style={{ backgroundColor: color.background }}>
            <Link to={`/patients/${id}`}>
              <FullNameAbbr style={{ color: color.text }}>
                {`${firstName?.[0]?.[0] ?? 'J'}${lastName?.[1]?.[0] ?? 'E'}`}
              </FullNameAbbr>
            </Link>
          </IconContainer>

          <Link to={`/patients/${id}`}>
            <Column>
              <FullName>{`${firstName ?? ''} ${lastName ?? ''}`}</FullName>
              <Email>{email ?? 'john@exemple.com'}</Email>
            </Column>
          </Link>
        </>
      ) : (
        <Column>
          <Email>Aucun client</Email>
        </Column>
      )}
    </Container>
  )
}

export default PatientCell
