import { Helmet } from 'react-helmet'
import Layout from '../../components/layout/default'
import {
  Container,
  Content,
  DashboardContainer,
  Header,
  TableContainer,
  Title,
} from './style'
import SummariesContainer from '../../components/gains/summaries/container'
import GainsTable from '../../components/gains/tables/table'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import GainsTableHeader from '../../components/gains/tables/header'
import { useGetOrdersByPrescriberId } from '../../services/api/prescriber/prescriber'
import { PrescriberOrderDTO } from '../../services/api/types'
import GainsTableGain from '../../components/gains/tables/table/gain'
import GainsTablePatient from '../../components/gains/tables/table/patient'
import { HeaderLinks } from '../../components/section/header/props'
import Rewards from '../../components/rewards'
import { useGetMe } from '../../utils/hooks/getMe'

const MAX_SIZE_PER_PAGE = 5

const GainsPage = () => {
  // Attributes
  const history = useHistory()
  const { data: prescriber } = useGetMe()

  const [currentOrderPage, setCurrentOrderPage] = useState(0)
  const { data: orders } = useGetOrdersByPrescriberId(prescriber?.id, {
    page: currentOrderPage,
    size: MAX_SIZE_PER_PAGE,
  })
  const orderColumns = useMemo(() => {
    // Define all columns including 'Gain'
    const columns = [
      {
        Header: 'Date',
        accessor: (o: PrescriberOrderDTO) =>
          `${new Date(o.modifiedAt).toLocaleDateString()}`,
      },
      {
        Header: 'Client',
        accessor: (o: PrescriberOrderDTO) => (
          <GainsTablePatient {...o.patient} />
        ),
      },
      {
        Header: 'ID',
        accessor: 'name',
      },
      {
        Header: 'Prix',
        accessor: (o: PrescriberOrderDTO) => `${o.totalPrice?.toFixed(2)}$`,
      },
      {
        Header: 'Points',
        accessor: (o: PrescriberOrderDTO) => <GainsTableGain gain={o.gain} />,
      },
      {
        Header: 'Code promo',
        accessor: (o: PrescriberOrderDTO) =>
          o?.discount
            ? `${o.discount.code} (${o.discount.value}${
                o.discount.valueType === 'fixed_amount' ? 'pts' : '%'
              })`
            : '-',
      },
    ]

    // Remove 'Gain' column if prescriber.disableCommission is true
    if (prescriber?.disableCommission) {
      return columns.filter(column => column.Header !== 'Points')
    }

    return columns
  }, [prescriber?.disableCommission])

  // Render
  return (
    <Layout active={HeaderLinks.Points}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Gains</title>
      </Helmet>
      <Container>
        {!prescriber?.disableCommission && (
          <Header>
            <Title>Vos points</Title>
          </Header>
        )}
        <Content>
          {!prescriber?.disableCommission && (
            <>
              <DashboardContainer>
                <SummariesContainer prescriber={prescriber} />
                {/* <SelectCommission width='25.5rem' height='14.25rem' /> */}
              </DashboardContainer>
            </>
          )}

          <Rewards prescriber={prescriber} />

          <TableContainer>
            <GainsTableHeader
              title='Tableau récapitulatif des commandes'
              subtitle='Tableau récapitulatif de vos gains. Chaque ligne correspond à une commande réalisée 
            par l’un de vos clients.'
              setPage={setCurrentOrderPage}
              page={orders?.page}
              nbPages={orders?.nbPages}
              size={
                orders?.totalAmount < orders?.size
                  ? orders?.totalAmount
                  : orders?.size
              }
              totalAmount={orders?.totalAmount}
            />

            <GainsTable columns={orderColumns} data={orders?.data ?? []} />
          </TableContainer>
        </Content>
      </Container>
    </Layout>
  )
}

export default GainsPage
