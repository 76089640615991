import HeaderSignin from '../../../components/section/header-sign-in/HeaderSignin'
import {
  BodyContainer,
  MainContainer,
  FooterContent,
  Title,
  Footer,
} from './style'
import { Button } from '../../../components/ui'
import { useHistory } from 'react-router'
import usePatientRegistrationLogic from '../../../components/auth/register-form/patient-registration/logic'
import PatientRegisterFormMobile from '../../../components/auth/register-form/patient-registration/patient-register-form-mobile'

const RegisterPatientPageMobile = () => {
  //Attributes
  const history = useHistory()
  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    usePatientRegistrationLogic()

  //Function
  function redirectionToSignIn() {
    history.push('/patient/login')
  }

  //Return
  return (
    <BodyContainer>
      <HeaderSignin />
      <MainContainer>
        <Title>
          Connectez-vous à votre compte <span className='partword'>client</span>
        </Title>
        <PatientRegisterFormMobile
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          values={values}
        />
        <FooterContent>
          <Button onClick={handleSubmit}>Suivant</Button>
          <Footer>
            Déjà un compte?{' '}
            <span className='partword' onClick={redirectionToSignIn}>
              Connectez-vous !
            </span>
          </Footer>
        </FooterContent>
      </MainContainer>
    </BodyContainer>
  )
}

export default RegisterPatientPageMobile
