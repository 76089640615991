const Constants = {
  INIT_CART_LOADING: `INIT_CART_LOADING`,
  INIT_CART_SUCCESS: `INIT_CART_SUCCESS`,
  INIT_CART_FAILED: `INIT_CART_FAILED`,
  ADD_CART_LOADING: `ADD_CART_LOADING`,
  ADD_CART_SUCCESS: `ADD_CART_SUCCESS`,
  ADD_CART_FAILED: `ADD_CART_FAILED`,
  FETCH_CART_LOADING: `FETCH_CART_LOADING`,
  FETCH_CART_SUCCESS: `FETCH_CART_SUCCESS`,
  FETCH_CART_FAILED: `FETCH_CART_FAILED`,
  UPDATE_CART_LOADING: `UPDATE_CART_LOADING`,
  UPDATE_CART_SUCCESS: `UPDATE_CART_SUCCESS`,
  UPDATE_CART_FAILED: `UPDATE_CART_FAILED`,
  CART_LINES_REMOVE_LOADING: `CART_LINES_REMOVE_LOADING`,
  CART_LINES_REMOVE_SUCCESS: `CART_LINES_REMOVE_SUCCESS`,
  CART_LINES_REMOVE_FAILED: `CART_LINES_REMOVE_FAILED`,
  CART_ATTRIBUTES_UPDATE_LOADING: `CART_ATTRIBUTES_UPDATE_LOADING`,
  CART_ATTRIBUTES_UPDATE_SUCCESS: `CART_ATTRIBUTES_UPDATE_SUCCESS`,
  CART_ATTRIBUTES_UPDATE_FAILED: `CART_ATTRIBUTES_UPDATE_FAILED`,
  CART_DISCOUNT_UPDATE_LOADING: `CART_DISCOUNT_UPDATE_LOADING`,
  CART_DISCOUNT_UPDATE_SUCCESS: `CART_DISCOUNT_UPDATE_SUCCESS`,
  CART_DISCOUNT_UPDATE_FAILED: `CART_DISCOUNT_UPDATE_FAILED`,
}

export default Constants
