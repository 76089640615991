import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0 1.25rem;
  min-height: calc(100vh - 4.6875rem);
  background-color: #fdfcfe;
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 1.25rem auto;
`

export const HeaderCta = styled.div`
  width: 100%;
`

export const Nav = styled.div`
  display: flex;
`

export const NavItem = styled.div`
  cursor: pointer;
  margin-right: 2.5rem;
  font-size: 0.875rem;
  color: ${props => (props.active ? '#F46363' : '#777')};
  text-decoration: ${props => (props.active ? 'underline' : 'none')};
`

export const SearchBarContainer = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  gap: 0.25rem;

  height: 3.625rem;

  background: #ffffff;
  /* Elevation/Light/Bottom/01 */

  box-shadow: 0rem 0rem 0.0625rem rgba(40, 41, 61, 0.08),
    0rem 0.0313rem 0.125rem rgba(96, 97, 112, 0.16);
  border-radius: 0.5rem;

  a {
    text-decoration: none !important;
  }
`

export const SearchBarLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem;
  gap: 1rem;

  min-height: 2.125rem;
`

export const SearchBarVerticalLine = styled.div`
  width: 1.5625rem;
  height: 0rem;

  /* Text/200 */

  border: 0.0313rem solid #9c99af;
  transform: rotate(-90deg);
`

export const SearchBarButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;

  width: 8.4375rem;
  height: 2.625rem;

  background: #3d825b;
  border-radius: 0.375rem;

  cursor: pointer;
`

export const SearchBarButtonText = styled.p`
  font-family: 'System';
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  /* identical to box height */

  text-align: center;

  /* White/500 */

  color: #ffffff;
`

export const SearchBarInput = styled.input`
  font-family: 'System';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;

  /* Text/200 */

  color: #9c99af;
  outline: none;
  border: none;

  width: 18.75rem;

  ::placeholder {
    font-family: 'System';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;

    /* Text/200 */

    color: #9c99af;
    outline: none;
    border: none;
  }
`

export const ButtonShare = styled.button`
  cursor: pointer;
  color: #65b293;
  border-radius: 0.375rem;
  padding: 0.5rem 0.625rem;
  font-size: 0.875rem;
  border: 0.0625rem solid #65b293;
  background: transparent;
  white-space: nowrap;
  margin-right: 0.625rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
`
