import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Form } from './style'
import { Button, Field } from '../../../ui'
import { useHistory } from 'react-router-dom'
import { useGetPrescriberProfessions } from '../../../../services/api/prescriber/prescriber'
import { useQuery } from '../../../../utils/hooks/getQuery'
import Select from 'react-select'

const RegisterInformationsSchema = Yup.object({
  first: Yup.string()
    .min(3, 'Minimum 3 charactères requis')
    .required('Champs obligatoire'),
  last: Yup.string()
    .min(3, 'Minimum 3 charactères requis')
    .required('Champs obligatoire'),
  professionId: Yup.string(3, 'Veuillez choisir votre profession').required(
    'Champs obligatoire'
  ),
})

const RegisterInformationsForm = () => {
  const query = useQuery()
  const firstName = query.get('fn')
  const lastName = query.get('ln')
  const email = query.get('email')
  const history = useHistory()
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      first: firstName ? firstName : '',
      last: lastName ? lastName : '',
      professionId: '',
    },
    validationSchema: RegisterInformationsSchema,
    onSubmit: values => {
      const selectedProfession = professionOptions.find(
        pro => pro.value === values.professionId
      )
      let queryString =
        '?fn=' +
        values.first +
        '&ln=' +
        values.last +
        '&profession=' +
        selectedProfession?.label

      if (email && email.trim() !== '') {
        queryString += '&email=' + email
      }

      history.push({
        pathname: '/register/credentials',
        search: queryString,
      })
    },
  })
  const { data: professions, isLoading: isProfessionsLoading } =
    useGetPrescriberProfessions({ size: 100 })

  const professionOptions = (professions?.data ?? [])
    .map(profession => ({
      value: profession.id,
      label: profession.title,
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label))

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label='Prénom'
        name='first'
        type='text'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.first}
        error={errors.first}
        touched={touched.first}
        //isDisabled={editLoading}
        placeholder='Insérez votre prénom'
      />
      <Field
        label='Nom'
        name='last'
        type='text'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.last}
        error={errors.last}
        touched={touched.last}
        //isDisabled={editLoading}
        placeholder='Insérez votre nom'
      />
      <Field
        as={Select}
        label='Profession'
        name='professionId'
        type='select'
        onChange={data => {
          setFieldValue('professionId', data.value)
        }}
        onBlur={handleBlur}
        value={professionOptions.find(
          option => option.value === values?.professionId
        )}
        error={errors.professionId}
        touched={touched.professionId}
        //isDisabled={editLoading}
        placeholder='Sélectionnez une profession'
        options={professionOptions}
        isLoading={isProfessionsLoading}
        isSearchable={true}
      />
      <Button type='submit'>Suivant</Button>
    </Form>
  )
}

export default RegisterInformationsForm
