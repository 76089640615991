import styled from 'styled-components/macro'

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Nav = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 25px 0px 0px 0px;
  gap: 16px;
  width: 100%;
`

export const FavoritesWrapper = styled.div`
  margin-top: 20px;
`

export const NavItem = styled.div`
  cursor: pointer;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: ${props => (props.active ? '#080038' : '#8f95b2')};
  border-bottom: ${props => (props.active ? '1px solid #3A845E;' : '')};
  padding-bottom: 8px;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
