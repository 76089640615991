import PropTypes from 'prop-types'

import { GlobalContainer, Main } from './style'

const NakedLayout = ({ children, isFullBackground = true }) => (
  <GlobalContainer isFullBackground={isFullBackground}>
    <Main>{children}</Main>
  </GlobalContainer>
)

NakedLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NakedLayout
