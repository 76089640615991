import styled from 'styled-components/macro'

export const Button = styled.button`
  cursor: pointer;
  color: #65b293;
  border-radius: 0.375rem;
  padding: 0.5625rem 0.8125rem;
  font-size: 0.75rem;
  border: 0.0625rem solid #65b293;
  background: transparent;
  white-space: nowrap;
`
