import styled from 'styled-components/macro'
import { MediaQuery } from '../../utils/constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding-top: 5rem;
  padding: 1.25rem;

  ${MediaQuery.M_AND_UP} {
    width: 600px;
    margin: 0 auto 0 auto;
    justify-content: center;
    padding-bottom: 20%;
  }
`

export const Title = styled.h1`
  margin-top: 2.5rem;
  color: #080038;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const SubTitle = styled.p`
  margin-top: 1.5rem;
  color: #080038;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
`
export const Image = styled.img`
  margin-top: 5.75rem;
  width: 7.8125rem;
  height: 7.625rem;
`
export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${MediaQuery.M_AND_UP} {
    margin-bottom: 20px;
  }
`
