import { useState } from 'react'
import Pager from '../../../pager'
import { InfoBig } from '../../../ui/icon/icons'
import { GainsTableHeaderProps } from './props'
import {
  Container,
  Subtitle,
  Title,
  TitleContainer,
  TitleWrapper,
  ButtonShop,
} from './style'
import { useGetMe } from '../../../../utils/hooks/getMe'

import TabDialog from './dialog'
import SponsorFormDialog from '../../../sponsor/form-dialog'
import BlockAccessModal from '../../../block-access-modal'

const GainsTableHeader = ({
  title,
  subtitle,
  page,
  nbPages,
  size,
  totalAmount,
  setPage,
}: GainsTableHeaderProps) => {
  // Attributes
  const [isBlockAccessModalOpen, setIsBlockAccessModalOpen] = useState(false)
  const [isFormOpened, setIsFormOpened] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const { data: prescriber } = useGetMe()

  // Render
  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Title>{title}</Title>

          <InfoBig
            onClick={() => setIsDialogOpen(true)}
            width='1.75rem'
            height='1.75rem'
            stroke={'#080038'}
          />
        </TitleWrapper>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>

      <ButtonShop
        onClick={() => {
          if (prescriber?.isVerified) setIsFormOpened(true)
          else setIsBlockAccessModalOpen(true)
        }}
      >
        Echanger mes points
      </ButtonShop>
      <Pager
        width='12.25rem'
        height='2.75rem'
        page={page}
        nbPages={nbPages}
        size={size}
        totalAmount={totalAmount}
        setPage={setPage}
      />

      <TabDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />

      {isFormOpened && (
        <SponsorFormDialog
          isOpen={isFormOpened}
          onClose={() => setIsFormOpened(false)}
          formId={'b1a762c1-83de-4738-a970-573a510ffd34'}
          prescriber={prescriber}
          succeesMessageTitle='Merci ! Votre demande d’échange de points a bien été enregistrée.'
          succeesMessageDescription=''
        />
      )}

      <BlockAccessModal
        isOpen={isBlockAccessModalOpen}
        setIsOpen={setIsBlockAccessModalOpen}
      />
    </Container>
  )
}

export default GainsTableHeader
