import {
  Container,
  MainContent,
  Right,
  Img,
  Left,
  IconsWrapper,
  IconWrapper,
  DescritpionWrapper,
  DescritpionTextInput,
  Header,
  HeaderLeft,
  Name,
  HeaderRight,
  Units,
  Price,
  Title,
  BrandImg,
  NameWrapper,
} from './style'
import { useEffect, useState, useMemo } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import {
  BrandInstructionTiming,
  ProductVariantDTO,
} from '../../../../services/api/types'
import ProductPreviewCardDetailsDialog from '../../../product/product-preview-card/product-details-dialog'
import { useGetProductByIdOrHandle } from '../../../../services/api/product/product'
import { ProductCardMarketplaceProps } from './props'
import SuggestionCard from './suggestion-card'
import ProductQuantity from './productQuantity'
import ProductTakingInstructions from './product-taking-instructions'
import { PrescriptionValueToChange } from '../../../../utils/constants'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { useGetProtocol } from '../../../../services/api/protocols/protocols'
import DiscountPrice from '../../../product/product-preview-card/discount-price'

const durationArray = [
  'until symptoms are resolved',
  'non stop',
  'as needed',
  '7 days',
  '14 days',
  '21 days',
  '30 days',
  '60 days',
  '90 days',
  '120 days',
  '150 days',
  '180 days',
]

const ProductCardMarketplace = ({
  item,
  removeProductFromDraftTake,
  takeIndex,
  setValueInPrescription,
  setDraftChanged,
  statuses,
  lastStatus,
}: ProductCardMarketplaceProps) => {
  // Attributes
  const { location } = useHistory()
  const { protocolId } = queryString.parse(location.search)
  const { data: prescriber } = useGetMe()
  const { data: protocol } = useGetProtocol(
    prescriber?.id,
    Number(protocolId),
    {
      query: {
        enabled: !!prescriber?.id && !!protocolId,
      },
    }
  )

  const protocolIds = useMemo(() => {
    return protocol?.takes?.[0]?.items?.map(i => i?.product?.id) ?? []
  }, [protocol])

  const [isDetailModalOpen, setIsDetailsModalOpen] = useState(false)
  const quantity = item?.quantity ?? 0
  const { data: product, isFetched } = useGetProductByIdOrHandle(
    item?.productHandle
  )
  const [sortedVariants, setSortedVariants] = useState<ProductVariantDTO[]>()
  const [selectedvariant, setSelectedvariant] = useState<ProductVariantDTO>()
  const discountPercentage = prescriber?.activeDiscount?.percentage || 0
  const originalPrice = Number(selectedvariant?.price?.toFixed(2))
  const discountedPrice =
    originalPrice - (originalPrice * discountPercentage) / 100
  const isPrescriptionCreated = !!statuses?.find(s => s.status === 'CREATED')

  let isInProtocol = null
  if (protocol && product) {
    const items = protocol.takes?.[0]?.items
    if (items) {
      isInProtocol = items.find(i => i.product?.handle === product.handle)
    }
  }

  const itemsFirst = lastStatus?.[0]?.items || []
  const itemsSecond = lastStatus?.[1]?.items || []
  const mergedItems = itemsFirst.concat(itemsSecond)
  const productNotInTakes = !mergedItems.find(
    item => item?.product?.handle === product?.handle
  )

  useEffect(() => {
    if (product) {
      const variants = product?.variants
        ?.sort((a, b) => a.price - b.price)
        ?.filter(item => item?.title?.startsWith('mp'))

      setSortedVariants(variants)
      setSelectedvariant(variants?.[0])
    }
  }, [product])

  const [instructionQuantity, setInstructionQuantity] = useState(
    item?.instructionQuantity
      ? item?.instructionQuantity
      : product?.brandInstructionQuantity ?? 0
  )

  const [selectedTimings, setSelectedTimings] =
    useState<BrandInstructionTiming>(
      item?.instructionTiming
        ? item?.instructionTiming
        : product?.brandInstructionTiming
    )
  const [selectedDurations, setSelectedDurations] = useState<string>(
    item?.instructionDuration
      ? item?.instructionDuration
      : product?.brandInstructionDuration
  )

  // Functions
  const reset = () => {
    // setDraftChanged && setDraftChanged(true)
    setSelectedTimings(product?.brandInstructionTiming)
    setSelectedDurations(product?.brandInstructionDuration)
    setInstructionQuantity(product?.brandInstructionQuantity)
    setValueInPrescription(
      product,
      product?.brandInstructionTiming,
      takeIndex,
      PrescriptionValueToChange.INSTRUCTION_TIMING
    )
    setValueInPrescription(
      product,
      product?.brandInstructionDuration,
      takeIndex,
      PrescriptionValueToChange.INSTRUCTION_DURATION
    )
    setValueInPrescription(
      product,
      product?.brandInstructionQuantity,
      takeIndex,
      PrescriptionValueToChange.INSTRUCTION_QUANTITY
    )
  }

  // Hooks
  useEffect(() => {
    if (instructionQuantity && product) {
      setValueInPrescription(
        product,
        instructionQuantity,
        takeIndex,
        PrescriptionValueToChange.INSTRUCTION_QUANTITY
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionQuantity, product])

  useEffect(() => {
    quantity &&
      setValueInPrescription(
        product,
        quantity,
        takeIndex,
        PrescriptionValueToChange.ITEM_QUANTITY
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity])

  useEffect(() => {
    if (!isPrescriptionCreated && !protocolId) {
      reset()
      return
    }

    selectedvariant?.title &&
      setValueInPrescription(
        product,
        selectedvariant?.title,
        takeIndex,
        PrescriptionValueToChange.ITEM_VARIANT_TITLE
      )

    item?.note &&
      product &&
      setValueInPrescription(
        product,
        item?.note,
        takeIndex,
        PrescriptionValueToChange.NOTE
      )

    quantity &&
      setValueInPrescription(
        product,
        quantity,
        takeIndex,
        PrescriptionValueToChange.ITEM_QUANTITY
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, selectedvariant, isInProtocol, protocolIds])

  useEffect(() => {
    if (
      protocolIds.length > 0 &&
      product &&
      !protocolIds.includes(product.id)
    ) {
      reset()
    }
  }, [protocolIds, product])

  useEffect(() => {
    if (
      mergedItems?.length > 0 &&
      product &&
      isPrescriptionCreated &&
      productNotInTakes
    ) {
      reset()
    }
  }, [product, isPrescriptionCreated, productNotInTakes])

  // Render
  return (
    <Container>
      <MainContent>
        <Left>
          <Img
            src={product?.coverImage}
            alt=''
            onClick={e => {
              e.preventDefault()
              setIsDetailsModalOpen(true)
            }}
          />
          <IconsWrapper>
            <IconWrapper
              background='#f46363'
              onClick={() => removeProductFromDraftTake(product, takeIndex)}
            >
              <HiOutlineTrash />
            </IconWrapper>
            <IconWrapper
              background='#3A845E'
              onClick={e => {
                e.preventDefault()
                setIsDetailsModalOpen(true)
              }}
            >
              <AiOutlineInfoCircle />
            </IconWrapper>
          </IconsWrapper>
        </Left>

        <Right>
          <Header>
            <HeaderLeft>
              <NameWrapper>
                <Name>{product?.name}</Name>
                <BrandImg alt={product?.name} src={product?.brand?.iconUrl} />
              </NameWrapper>
              <ProductQuantity
                quantity={quantity}
                setQuantity={(quantity: number) =>
                  setValueInPrescription(
                    product,
                    quantity,
                    takeIndex,
                    PrescriptionValueToChange.ITEM_QUANTITY
                  )
                }
                sortedVariants={sortedVariants}
                product={product}
                takeIndex={takeIndex}
                selectedvariant={selectedvariant}
                setValueInPrescription={setValueInPrescription}
                setSelectedvariant={setSelectedvariant}
              />
            </HeaderLeft>
            <HeaderRight>
              <Price>
                <DiscountPrice
                  discountPercentage={discountPercentage}
                  originalPrice={originalPrice}
                  discountedPrice={discountedPrice}
                />
              </Price>
              <Units>
                {quantity} unité{quantity > 1 ? 's' : ''} (
                {discountPercentage > 0
                  ? `${(discountedPrice * quantity).toFixed(2)}€`
                  : `${(selectedvariant?.price * quantity).toFixed(2)}€`}
                )
              </Units>
            </HeaderRight>
          </Header>

          <Title>Instructions de prise:</Title>

          <ProductTakingInstructions
            instructionQuantity={instructionQuantity}
            setInstructionQuantity={setInstructionQuantity}
            product={product}
            selectedTimings={selectedTimings}
            selectedDurations={selectedDurations}
            reset={reset}
            durationArray={durationArray}
            setSelectedDurations={setSelectedDurations}
            setSelectedTimings={setSelectedTimings}
            setValueInPrescription={setValueInPrescription}
            takeIndex={takeIndex}
            setDraftChanged={setDraftChanged}
          />

          <DescritpionWrapper>
            <DescritpionTextInput
              onChange={e => {
                if (product) {
                  setDraftChanged && setDraftChanged(true)
                  setValueInPrescription(
                    product,
                    e.target.value,
                    takeIndex,
                    PrescriptionValueToChange.NOTE
                  )
                }
              }}
              value={item?.note}
              placeholder='Tapez une note'
            />
          </DescritpionWrapper>
        </Right>
      </MainContent>

      <SuggestionCard
        instructionQuantity={instructionQuantity}
        selectedTimings={selectedTimings}
        selectedDurations={selectedDurations}
        variantTitle={selectedvariant?.title}
        setQuantity={(quantity: number) =>
          setValueInPrescription(
            product,
            quantity,
            takeIndex,
            PrescriptionValueToChange.ITEM_QUANTITY
          )
        }
        quantity={quantity}
        takeTotalQuantity={product?.takeTotalQuantity}
      />

      {/* MODALS */}
      {isDetailModalOpen && (
        <ProductPreviewCardDetailsDialog
          isOpen={isDetailModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          handle={product.handle}
        />
      )}
    </Container>
  )
}

export default ProductCardMarketplace
