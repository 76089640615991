/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { UserDTO, GetUserBySubParams, CreateUserDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

/**
 * Get the information about the current user
 */
export const getUserBySub = (
  params?: GetUserBySubParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserDTO>({ url: `/me`, method: 'get', params }, options)
}

export const getGetUserBySubQueryKey = (params?: GetUserBySubParams) => [
  `/me`,
  ...(params ? [params] : []),
]

export const useGetUserBySub = <
  TData = AsyncReturnType<typeof getUserBySub>,
  TError = unknown
>(
  params?: GetUserBySubParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getUserBySub>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetUserBySubQueryKey(params)

  const queryFn: QueryFunction<AsyncReturnType<typeof getUserBySub>> = () =>
    getUserBySub(params, requestOptions)

  const query = useQuery<AsyncReturnType<typeof getUserBySub>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  )

  return {
    queryKey,
    ...query,
  }
}

export const createUser = (
  createUserDTO: CreateUserDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserDTO>(
    { url: `/users`, method: 'post', data: createUserDTO },
    options
  )
}

export const useCreateUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createUser>,
    TError,
    { data: CreateUserDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createUser>,
    { data: CreateUserDTO }
  > = props => {
    const { data } = props || {}

    return createUser(data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createUser>,
    TError,
    { data: CreateUserDTO },
    TContext
  >(mutationFn, mutationOptions)
}
