import {
  Container,
  TitleWrapper,
  ButtonWrapper,
  Title,
  NoProductsWrapper,
  ProductsWrapper,
  NoProductsImage,
  NoProductsText,
  ProductsWrapperList,
  Or,
  OrWrapper,
  OrLine,
  Header,
} from './style'
import NoProducts from '../../../assets/images/no-products.png'
import { Button, Spinner } from '../../ui'
import ProductCardMarketplace from './productCardMarketplace'
import {
  CreatePrescriptionDTO,
  PrescriptionFileDTO,
  CreateProtocolDTO,
} from '../../../services/api/types'
import { ProductListProps } from './props'
import VrnAlert from './vnr-alert'
import ProtocolSlider from '../../reco/protocol-slider'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { Item } from '../../reco/create-reco-dialog/style'

const ProductList = ({
  setIsSliderProductDialogOpen,
  draft,
  removeProductFromDraftTake,
  setValueInPrescription,
  setIsSliderProtocolDialogOpen,
  isSliderProtocolDialogOpen,
  isChooseProtocolsAllowed,
  setDraftChanged,
  statuses,
  lastStatus,
}: ProductListProps) => {
  // Attributes
  const { location } = useHistory()
  const { prescriptionId } = queryString.parse(location.search)
  const {
    takes,
  }:
    | (CreatePrescriptionDTO & {
        files: PrescriptionFileDTO[]
        lastStatus: string
      })
    | CreateProtocolDTO = draft

  return (
    <>
      <Container>
        {prescriptionId && !draft.takes.length && (
          <Spinner fullScreen={false} />
        )}
        {takes?.[0]?.items?.length > 0 || takes?.[1]?.items?.length > 0 ? (
          <>
            <Header>
              <Title>Vos produits</Title>
              <Button
                appearance='primary'
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
                onClick={() => setIsSliderProductDialogOpen(true)}
                style={{
                  marginLeft: 'auto',
                  marginBottom: '20px',
                }}
              >
                Ajouter un produit
              </Button>
            </Header>
            <VrnAlert takes={takes} />

            {takes?.map((take, index) => (
              <ProductsWrapper key={index}>
                <ProductsWrapperList>
                  {take?.items
                    ?.map((item, itemIndex) => (
                      <ProductCardMarketplace
                        removeProductFromDraftTake={removeProductFromDraftTake}
                        key={take?.items?.[itemIndex]?.productHandle}
                        takeIndex={index}
                        item={item}
                        setValueInPrescription={setValueInPrescription}
                        setDraftChanged={setDraftChanged}
                        statuses={statuses}
                        lastStatus={lastStatus}
                      />
                    ))
                    ?.reverse()}
                </ProductsWrapperList>
              </ProductsWrapper>
            ))}
          </>
        ) : (
          !prescriptionId && (
            <NoProductsWrapper>
              <NoProductsImage src={NoProducts} />
              <NoProductsText>
                Ajoutez des produits pour compléter votre recommandation
              </NoProductsText>
              <Button
                appearance='primary'
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
                onClick={() => setIsSliderProductDialogOpen(true)}
              >
                Ajouter un produit
              </Button>
              {isChooseProtocolsAllowed && (
                <>
                  <OrWrapper>
                    <OrLine />
                    <Or>ou</Or>
                    <OrLine />
                  </OrWrapper>
                  <Button
                    appearance='minimal'
                    iconLeft={undefined}
                    isDisabled={undefined}
                    isLoading={undefined}
                    isActive={undefined}
                    onClick={() => setIsSliderProtocolDialogOpen(true)}
                  >
                    Choisir un protocole
                  </Button>
                </>
              )}
            </NoProductsWrapper>
          )
        )}

        <ProtocolSlider
          isDialogOpen={isSliderProtocolDialogOpen}
          setIsDialogOpen={setIsSliderProtocolDialogOpen}
        />
      </Container>
    </>
  )
}

export default ProductList
