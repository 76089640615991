import cloneDeep from 'lodash.clonedeep'

import Constants from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

const fetchOrdersFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const fetchOrdersLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = { status: ActionType.LOADING, error: null }

  return newState
}
const fetchOrdersSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.list = action?.payload?.data?.data?.orders?.edges
  newState.actions.fetchAll = { status: ActionType.SUCCESS, error: null }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.FETCH_ORDERS_FAILED:
      return fetchOrdersFailed(state, action)
    case Constants.FETCH_ORDERS_LOADING:
      return fetchOrdersLoading(state)
    case Constants.FETCH_ORDERS_SUCCESS:
      return fetchOrdersSuccess(state, action)

    default:
      return state
  }
}
