import { Container, SecondaryTitle, TakeLabel, TakeContainer } from './style'
import ProductTile from '../../product/product-tile'
import { Spinner } from '../../ui'
import { PrescriptionProductsListProps } from './props'
import { PrescriptionTakeType } from '../../../services/api/types'
import { selectDefaultDuration } from '../../../utils/helpers'

const PrescriptionProductsList = ({
  cart,
  isLoading = false,
  recommendedDuration,
  takes,
  titleInCure,
  titleOutCure,
  abilityToManageQuantity = false,
  updateVariantToCart,
  removeVariantsToCart,
  editMode = false,
  prescriberName,
}: PrescriptionProductsListProps) => {
  // Attributes
  const inCureTakes = takes?.filter(
    take => take.type === PrescriptionTakeType.IN_CURE
  )
  const standAloneTakes = takes?.filter(
    take => take.type === PrescriptionTakeType.STAND_ALONE
  )

  // Render
  if (isLoading) return <Spinner fullScreen={undefined} />

  return (
    <Container>
      {!!inCureTakes?.[0]?.items?.length && (
        <div>
          {titleInCure ?? (
            <SecondaryTitle>Les produits en sachets</SecondaryTitle>
          )}
          {inCureTakes.map((take, index) => (
            <TakeContainer key={index}>
              {/* Don't show the title of the take if only one take */}
              {inCureTakes.length > 1 && (
                <TakeLabel>{take?.label || `Prise #${index + 1}`}</TakeLabel>
              )}
              {take?.items.map(item => {
                const edge = cart?.lines?.edges?.find(
                  edge =>
                    edge.node?.attributes?.find(attr => attr.key === 'handle')
                      ?.value === item?.product?.handle
                )

                return (
                  <ProductTile
                    key={item.product?.id}
                    item={item}
                    abilityToManageQuantity={abilityToManageQuantity}
                    recommendedDuration={recommendedDuration}
                    cartDuration={selectDefaultDuration(cart)}
                    updateVariantToCart={updateVariantToCart}
                    removeVariantsToCart={removeVariantsToCart}
                    editMode={editMode}
                    nodeId={edge?.node?.id}
                    attributes={edge?.node?.attributes}
                    prescriberName={prescriberName}
                    price={edge?.node?.merchandise?.price}
                    subtotalAmount={
                      edge?.node?.estimatedCost?.subtotalAmount?.amount
                    }
                    totalAmount={edge?.node?.estimatedCost?.totalAmount?.amount}
                  />
                )
              })}
            </TakeContainer>
          ))}
        </div>
      )}
      {!!standAloneTakes?.[0]?.items?.length && (
        <div>
          {titleOutCure ? (
            titleOutCure
          ) : (
            <SecondaryTitle>Les produits hors sachets</SecondaryTitle>
          )}
          {standAloneTakes.map((take, index) => {
            return (
              <TakeContainer key={index}>
                {/* Don't show the title of the take if only one take */}
                {inCureTakes.length > 1 && (
                  <TakeLabel>{take?.label || `Prise #${index + 1}`}</TakeLabel>
                )}
                {take?.items.map(item => {
                  const edge = cart?.lines?.edges?.find(
                    edge =>
                      edge.node?.attributes?.find(attr => attr.key === 'handle')
                        ?.value === item?.product?.handle
                  )

                  return (
                    <ProductTile
                      key={item.product?.id}
                      item={item}
                      abilityToManageQuantity={abilityToManageQuantity}
                      recommendedDuration={recommendedDuration}
                      cartDuration={selectDefaultDuration(cart)}
                      updateVariantToCart={updateVariantToCart}
                      removeVariantsToCart={removeVariantsToCart}
                      editMode={editMode}
                      nodeId={edge?.node?.id}
                      attributes={edge?.node?.attributes}
                      isStandAlone
                      prescriberName={prescriberName}
                      price={edge?.node?.merchandise?.price}
                      subtotalAmount={
                        edge?.node?.estimatedCost?.subtotalAmount?.amount
                      }
                      totalAmount={
                        edge?.node?.estimatedCost?.totalAmount?.amount
                      }
                    />
                  )
                })}
              </TakeContainer>
            )
          })}
        </div>
      )}
    </Container>
  )
}

export default PrescriptionProductsList
