import { useState } from 'react'
import FiltersList from './filters-list'
import { IResultBarProps } from './props'
import ResultAction from './result-action'
import {
  BottomSortByWrapper,
  Result,
  ResultAndActionRow,
  ResultRow,
  ResultWithActionRow,
  RightResultWithActionRow,
} from './style'
import DropdownEl from '../../ui/dropdown'
import { SortOptions } from '../sort-option'
import { pluralize } from '../../../utils/helpers'
import { Option, OptionList } from '../../../utils/constants'

const ResultBar = ({
  totalAmount,
  values,
  clearFilter,
  setFieldValue,
  onSortBy: handleChangeOrder,
}: IResultBarProps) => {
  const isMobile = window.innerWidth < 768
  const [selectedOption, setSelectedOption] = useState<Option>({
    value: 'PERTINENCE',
    label: 'Pertinence',
  })

  const optionList = OptionList

  const handleSelect = (value: string, field: string) => {
    setSelectedOption({ value, label: field })
    handleChangeOrder(value)
  }

  //Return
  return (
    <ResultWithActionRow>
      <ResultAndActionRow>
        <Result>{pluralize('produit', 'produits', totalAmount)}</Result>
        <RightResultWithActionRow>
          {!isMobile && (
            <>
              <DropdownEl
                content={
                  <SortOptions options={optionList} onSelect={handleSelect} />
                }
              >
                <BottomSortByWrapper>
                  {!selectedOption.value
                    ? 'Trier par'
                    : 'Trier par : ' + selectedOption.label}
                </BottomSortByWrapper>
              </DropdownEl>
            </>
          )}
          <ResultAction clearFilter={clearFilter} />
        </RightResultWithActionRow>
      </ResultAndActionRow>
      <ResultRow>
        <FiltersList
          type='brands'
          values={values}
          setFieldValue={setFieldValue}
        />

        <FiltersList
          type='productTypes'
          values={values}
          setFieldValue={setFieldValue}
        />

        <FiltersList
          type='productTags'
          values={values}
          setFieldValue={setFieldValue}
        />
        <FiltersList
          type='includeIngredients'
          values={values}
          setFieldValue={setFieldValue}
        />

        <FiltersList
          type='excludeIngredients'
          values={values}
          setFieldValue={setFieldValue}
          excludes
        />
        <FiltersList
          type='takeFormat'
          values={values}
          setFieldValue={setFieldValue}
        />
        <FiltersList
          type='productLabels'
          values={values}
          setFieldValue={setFieldValue}
        />

        <FiltersList
          type='allergens'
          values={values}
          setFieldValue={setFieldValue}
        />
      </ResultRow>
    </ResultWithActionRow>
  )
}

export default ResultBar
