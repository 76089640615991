import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  @media (min-width: 68.75rem) {
    display: flex;
    flex-direction: column;
    padding: 2.75rem;
    border-radius: 1rem;
    background: white;
    box-shadow: 0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12);
    margin-left: 12.3125rem;
    margin-right: 1.625rem;
  }

  @media (min-width: 37.5rem) AND (max-width: 68.75rem) {
    display: flex;
    flex-direction: column;
    padding: 2.75rem;
    border-radius: 1rem;
    background: white;
    box-shadow: 0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12);
    margin-left: 25%;
  }

  @media (max-width: 37.5rem) {
    transform: scale(0.9);
    display: flex;
    flex-direction: column;
    padding: 2.75rem;
    border-radius: 1rem;
    background: white;
    box-shadow: 0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12);
    margin-left: 0;
  }
`

export const Title = styled.div`
  font-size: 1.25rem;
  margin-top: 2.75rem;
  color: #080038;
`

export const Subtitle = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: #393360;
  width: 20.4375rem;
  line-height: normal;
  margin-top: 0.375rem;
`

export const FooterText = styled.p`
  font-size: 0.75rem;
  color: #080038;
  text-align: center;

  > a {
    color: #f46363;
    text-decoration: none;
  }
`
export const ImageLogo = styled.img`
  width: 14.4375rem;
  object-fit: contain;
  object-position: center center;
`

export const ImageText = styled.div`
  width: 37.8125rem;
  height: 48rem;
  margin: 1.75rem;
  position: relative;
  filter: drop-shadow(0.25rem 0.0625rem 5.25rem rgba(0, 0, 0, 0.12));
`

export const Image = styled.img`
  width: 37.8125rem;
  height: 48.0625rem;
  object-fit: cover;
  object-position: center center;
  @media (max-width: 68.75rem) {
    visibility: hidden;
  }
`

export const TextImage = styled.div`
  position: absolute;
  top: 3.375rem;
  left: 3.125rem;
  color: white;
  max-width: 2.5rem;
  font-size: 2.2862rem;
  font-weight: 400;
  line-height: 2.7437rem;
  @media (max-width: 68.75rem) {
    visibility: hidden;
  }
`

export const NakedLayout = styled.section`
  overflow-x: hidden;
  overflow-y: hidden;
`

export const BackButtonWrapper = styled.div`
  margin-bottom: 1.25rem;
`
