import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 25.7561px;
  line-height: 31px;
  color: #080038;
`

export const Description = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #080038;
  text-align: center;
`

export const Image = styled.img`
  border-radius: 12px;
`

export const Button = styled.button`
  border-radius: 8px;
  border: none;
  background-color: #3a845e;
  cursor: pointer;
  font-weight: 500;
  font-size: 15.0244px;
  line-height: 18px;
  color: #ffffff;
  padding: 15px 10px;

  &:hover {
    background-color: #3a845e;
  }
`
