import {
  Container,
  Wrapper,
  Content,
  Column,
  SocialWrapper,
  SocialItem,
  LogosWrapper,
  LogoWrapper,
  BottomFooter,
  BottomFooterRight,
  BottomFooterLeft,
  BottomFooterRightTitle,
} from './style'
import { FaFacebookF } from 'react-icons/fa'
import { AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai'
import { Logo } from '../../ui'

const Footer = () => {
  // Render
  return (
    <Container>
      <Wrapper>
        <Content>
          <Column>
            <h5>Besoin d’aide ?</h5>
            <a
              href='https://about.compliment.me/policies/terms-of-service'
              target='_blank'
              rel='noreferrer'
            >
              Conditions générales de vente
            </a>
            <a
              href='https://about.compliment.me/policies/privacy-policy'
              target='_blank'
              rel='noreferrer'
            >
              Politique de confidentialité
            </a>

            <a
              href='https://fr.trustpilot.com/review/compliment.me'
              target='_blank'
              rel='noreferrer'
            >
              Avis clients
            </a>
            <a
              href='https://www.compliment-pro.com/qualityCharter.pdf'
              target='_blank'
              rel='noreferrer'
            >
              Notre charte de qualité
            </a>
          </Column>

          <Column>
            <h5>Nous suivre</h5>
            <SocialWrapper>
              <SocialItem
                href='https://www.facebook.com/simplycure'
                target='_black'
              >
                <FaFacebookF />
              </SocialItem>
              <SocialItem
                href='https://www.instagram.com/simplycure.health/'
                target='_black'
              >
                <AiOutlineInstagram />
              </SocialItem>
              <SocialItem
                href='https://www.youtube.com/@simplycure.health/featured'
                target='_black'
              >
                <AiOutlineYoutube />
              </SocialItem>
            </SocialWrapper>
            <a href='tel:+33745892969 '>Tel: 🇫🇷 +33 7 45 89 29 69 </a>
            <a href='tel:+3228087914'>Tel: 🇧🇪 +32 2 808 79 14</a>
            <a href='mailto:pro@simplycure.com'>pro@simplycure.com</a>
            <p>
              Notre support est à votre disposition du lundi au vendredi de 9h à
              18h.
            </p>
          </Column>
        </Content>

        <BottomFooter>
          <BottomFooterLeft>
            <Logo color='#fff' />
            <p>© Simplycure 2023</p>
            <p>Tous droits réservés</p>
          </BottomFooterLeft>
          <BottomFooterRight>
            <BottomFooterRightTitle>
              Fier d’être reconnu par:
            </BottomFooterRightTitle>
            <LogosWrapper>
              <LogoWrapper
                href='https://www.economie.gouv.fr/dgccrf/dgccrf'
                target='_blank'
              >
                <img
                  alt='logo'
                  src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/footerLogo.png?alt=media&token=a176616a-da61-4559-8f43-29a325dff9e0'
                />
              </LogoWrapper>

              <LogoWrapper
                href='https://syndicat-naturopathie.fr/'
                target='_blank'
              >
                <img
                  alt='logo'
                  src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/footerLogo2.png?alt=media&token=cd6c2b9e-4cf0-40b2-9823-392b837fc3ac'
                />
              </LogoWrapper>
              <LogoWrapper href='https://www.favv-afsca.be/' target='_blank'>
                <img
                  alt='logo'
                  src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/footerLogo3.png?alt=media&token=558da52b-638d-4bd1-9f96-f2ea579cee1c'
                />
              </LogoWrapper>
            </LogosWrapper>
          </BottomFooterRight>
        </BottomFooter>
      </Wrapper>
    </Container>
  )
}

export default Footer
