import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import Layout from '../../components/layout/naked'
import { Container, Content, FlexContainer } from './style'
import { useGetDiscountsByPrescriberId } from '../../services/api/prescriber/prescriber'
import { useCreateDefaultDiscountsByPrescriberId } from '../../services/api/prescriber/prescriber'
import { useGetMe } from '../../utils/hooks/getMe'
import InformationsComponent from '../../components/onboarding/address-step'
import { useCreatePatient } from '../../services/api/patients/patients'

const OnboardingPage = () => {
  // Queries
  const { data: prescriber, refetch: refetchPrescriber } = useGetMe()
  const { data: prescriberDiscount, refetch: refetchPrescriberDiscount } =
    useGetDiscountsByPrescriberId(
      prescriber?.id,
      {},
      {
        query: { enabled: !!prescriber?.id },
      }
    )
  const { mutateAsync: createDiscountCodes } =
    useCreateDefaultDiscountsByPrescriberId()

  const { mutateAsync: addPatient } = useCreatePatient()

  //Effect
  // Add 10% discount code
  useEffect(() => {
    if (!prescriber?.isOnboarded) {
      addPatient({
        prescriberId: prescriber?.id,
        data: {
          email: 'test@simplycure.me',
          firstName: 'Test',
          lastName: 'Simplycure',
          phone: '+32123456789',
          sexe: 'MAN',
          pathology: '',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriber])

  useEffect(() => {
    createDiscountCodes({
      prescriberId: prescriber.id,
    }).then(() => refetchPrescriberDiscount())
  }, [prescriber])

  // Render
  return (
    <Layout isFullBackground={false}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Onboarding</title>
      </Helmet>
      <FlexContainer>
        <Container>
          <Content>
            <InformationsComponent
              prescriber={prescriber}
              prescriberDiscount={prescriberDiscount}
              refetchPrescriber={refetchPrescriber}
            />
          </Content>
        </Container>
      </FlexContainer>
    </Layout>
  )
}

export default OnboardingPage
