import React from 'react'
import { Spinner } from './style'

const SpinnerEl = () => (
  <Spinner>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </Spinner>
)

export default SpinnerEl
