import styled from 'styled-components/macro'

export const PatientItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  margin: 10px 0;
`

export const Inital = styled.span`
  font-size: 14px !important;
  background-color: #fcf2ef;
  color: #d7603a;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
`

export const Email = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  background: var(--BG, #f8f9fc);
  padding: 8px;
  font-size: 14px;
  font-weight: 300;
  border: ${props => (props.isPhoneNumberFieldFilled ? '1px solid red' : '')};
  margin-right: 10px;

  span {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    margin-left: 5px;
  }
`
