import { Spinner } from '../../../../../ui'
import { PatientList } from '../../../../../share-access-dialog/home-page-content/send-email/style'
import PatientItemC from '../../patient-email-item'
import { SearchPatientListProps } from './props'

const SearchPatientList = ({
  showPatientList,
  isLoading,
  patients,
  emails,
  setEmail,
  addEmail,
  setShowPatientList,
}: SearchPatientListProps) => {
  return (
    <>
      {showPatientList && (
        <PatientList
          style={{
            zIndex: '100',
            width: '100%',
            maxHeight: '350px',
            boxShadow:
              'rgba(40, 41, 61, 0.08) 0px 0px 1px, rgba(96, 97, 112, 0.16) 0px 0.5px 2px',
          }}
        >
          {isLoading ? (
            <Spinner fullScreen={false} />
          ) : (
            patients?.data
              ?.filter(patient => !emails.includes(patient.email))
              .map(
                ({ id, firstName, lastName, email }) =>
                  email && (
                    <PatientItemC
                      setEmail={setEmail}
                      addEmail={addEmail}
                      setShowPatientList={setShowPatientList}
                      id={id}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                    />
                  )
              )
          )}
        </PatientList>
      )}
    </>
  )
}

export default SearchPatientList
