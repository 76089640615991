import { useState } from 'react'
import {
  Container,
  HeaderContent,
  ProductInfo,
  ImageWrapper,
  Image,
  TitleHeader,
  Title,
  Hr,
  RecommendedQuantity,
  RecommendedQuantityText,
  RecommendedQuantityNumber,
  PricingWrapper,
  Pricing,
  TableWrapper,
  Table,
  FlexRow,
  BannerPricing,
  ImgLabel,
  QuantityDescription,
  HandleQuantityWrapper,
  HandleQuantityFlexWrapper,
  HandleQuantity,
  MinusButton,
  PlusButton,
  QuantityButton,
  Quantity,
  AddToCartWrapper,
  FavoriteWrapper,
} from './style'
import { HeaderProps } from './props'
import { useGetProductDosingByProductId } from '../../../../services/api/dosings/dosings'
import { useGetAllProductLabelsByProductId } from '../../../../services/api/product/product'
import { useGetAllProductTagsByProductId } from '../../../../services/api/product-tags/product-tags'
import { useGetAllProductTypesByProductId } from '../../../../services/api/product-types/product-types'
import {} from '../../../../services/api/product/product'
import { TakePeriodType } from '../../../../services/api/types'
import {
  getTakeFormatName,
  getTakePackagingFormatName,
  sortAndFilterVariants,
} from '../../../../utils/helpers'
import { leastVariantsPrice } from '../../../../utils/data'
import AddToCart from '../../product-preview-card/add-to-cart'
import { useLocation } from 'react-router'
import Favorite from '../../product-preview-card/favorite'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { useGetDiscountWithPrescriberByCode } from '../../../../services/api/discount/discount'
import Cookies from 'js-cookie'

const Header = ({
  product,
  numberOfCapsule,
  setNumberOfCapsule,
  onClickAddProduct,
  doNotAllowProductToBeAdded,
  isPatientCatalog,
  bookmarks,
  enableFavorite,
  isOpen,
}: HeaderProps) => {
  const location = useLocation()
  const { data: prescriber } = useGetMe()

  const savedPromoCode = Cookies.get('promoCode')
  const { data: discountWithPrescriberByCode } =
    useGetDiscountWithPrescriberByCode(savedPromoCode?.toUpperCase(), {
      query: {
        enabled: !!savedPromoCode,
      },
    })

  let discountPercentage =
    location.pathname === '/patient-catalog'
      ? discountWithPrescriberByCode?.percentage
      : prescriber?.activeDiscount?.percentage || 0

  discountPercentage = product?.brand?.name === 'NHCO' ? 0 : discountPercentage

  const { data: dosings } = useGetProductDosingByProductId(product?.id)
  const { data: allLabels } = useGetAllProductLabelsByProductId(product?.id)
  const { data: tags } = useGetAllProductTagsByProductId(product?.id)
  const { data: types } = useGetAllProductTypesByProductId(product?.id)
  const sortedVariants = sortAndFilterVariants(product)
  const [selectedvariant, setSelectedvariant] = useState(
    product?.variants?.find(i => i.id === sortedVariants[0].id)
  )
  const originalPrice =
    leastVariantsPrice(product?.variants)?.price * numberOfCapsule
  const discountedPrice =
    originalPrice - (originalPrice * discountPercentage) / 100

  function getTakePeriodName(): string {
    switch (dosings?.takePeriod) {
      case TakePeriodType.DAY:
        return 'jour'
      case TakePeriodType.NIGHT:
        return 'nuit'
      default:
        return ''
    }
  }

  const addQuantity = () => {
    onClickAddProduct(numberOfCapsule)
  }

  const handleOnMinusClick = () => {
    if (numberOfCapsule !== 1) {
      setNumberOfCapsule(Number(numberOfCapsule) - 1)
    }
  }

  const handleOnPlusClick = () => {
    setNumberOfCapsule(Number(numberOfCapsule) + 1)
  }

  // Render
  return (
    <Container>
      <ProductInfo>
        <HeaderContent>
          <TitleHeader>
            <Title>{product?.name}</Title>
            <img
              src={product?.brand?.iconUrl}
              alt={product?.brand?.name}
              style={{ width: 60, height: 60, marginTop: 10 }}
            />
          </TitleHeader>
        </HeaderContent>

        <Hr></Hr>

        <QuantityDescription>
          {!product?.standAlone
            ? `${numberOfCapsule} x ${product?.formatQuantity ?? ''} ${
                `${getTakeFormatName(
                  Number(product?.formatQuantity),
                  dosings?.takeFormat
                )} en sachet` ?? ''
              }`
            : `${numberOfCapsule ?? 0} ${
                getTakePackagingFormatName(
                  numberOfCapsule,
                  dosings?.packagingFormat
                ) ?? ''
              } de ${dosings?.takeTotalQuantity ?? ''}  
                  ${`${getTakeFormatName(
                    Number(dosings?.takeTotalQuantity),
                    dosings?.takeFormat
                  )}
                    `} (${dosings?.contentTotal ?? ''} ${
                dosings?.contentUnit ?? ''
              })
                  `}
        </QuantityDescription>

        <RecommendedQuantity>
          <RecommendedQuantityText>
            Quantité quotidienne recommandée
          </RecommendedQuantityText>

          <RecommendedQuantityNumber>
            {dosings?.takeRecommanded}{' '}
            {getTakeFormatName(dosings?.takeRecommanded, dosings?.takeFormat)} /{' '}
            {getTakePeriodName()}
          </RecommendedQuantityNumber>
        </RecommendedQuantity>

        <BannerPricing>
          {!doNotAllowProductToBeAdded &&
          (location.pathname === '/patient-catalog' ||
            location.pathname === '/cart') ? (
            <AddToCartWrapper>
              <AddToCart
                sortedVariants={sortedVariants}
                selectedvariant={selectedvariant}
                setSelectedvariant={setSelectedvariant}
                dosings={dosings}
                isOpen={isOpen}
              />
            </AddToCartWrapper>
          ) : (
            !doNotAllowProductToBeAdded && (
              <HandleQuantityWrapper>
                <HandleQuantityFlexWrapper>
                  <HandleQuantity>
                    <MinusButton onClick={handleOnMinusClick}>-</MinusButton>
                    <Quantity>{numberOfCapsule}</Quantity>
                    <PlusButton onClick={handleOnPlusClick}>+</PlusButton>
                  </HandleQuantity>
                  <QuantityButton onClick={addQuantity}>Ajouter</QuantityButton>
                </HandleQuantityFlexWrapper>
              </HandleQuantityWrapper>
            )
          )}
          <PricingWrapper>
            <FlexRow>
              <Pricing>
                {discountPercentage > 0 ? (
                  <>
                    <span
                      style={{
                        textDecoration: 'line-through',
                        opacity: '.8',
                        fontSize: '22px',
                      }}
                    >
                      {originalPrice}€
                    </span>{' '}
                    <span style={{ color: '#3a835f' }}>
                      {discountedPrice?.toFixed(2)}€{' '}
                    </span>
                  </>
                ) : (
                  <span style={{ color: '#3a835f' }}>{originalPrice}€</span>
                )}
              </Pricing>
              {!product?.standAlone ? (
                <span>pour {numberOfCapsule * 30} gélules par mois</span>
              ) : (
                <span>
                  /{' '}
                  {getTakePackagingFormatName(
                    numberOfCapsule,
                    dosings?.packagingFormat
                  )}
                </span>
              )}
            </FlexRow>
          </PricingWrapper>
        </BannerPricing>
        {!isPatientCatalog && (
          <TableWrapper>
            <Table>
              <tr>
                <td>Objectif santé</td>
                {tags?.map(value => {
                  return (
                    <td>
                      <div>
                        <span></span>
                        <p>{value.name}</p>
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Type de produit</td>
                {types?.map(value => {
                  return (
                    <td>
                      <div>
                        <span></span>
                        <p>{value.name}</p>
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Label</td>
                {allLabels?.data?.map(value => {
                  return (
                    <td>
                      <div>
                        <ImgLabel src={value?.imageUrl} />
                        <p>{value?.title}</p>
                      </div>
                    </td>
                  )
                })}
              </tr>
            </Table>
          </TableWrapper>
        )}
      </ProductInfo>
      <ImageWrapper>
        {enableFavorite && (
          <FavoriteWrapper>
            <Favorite bookmarks={bookmarks} product={product} />
          </FavoriteWrapper>
        )}
        <Image src={product?.coverImage} />
      </ImageWrapper>
    </Container>
  )
}

export default Header
