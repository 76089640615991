import { Dialog } from '../../ui'
import { IconCross } from '../../ui/icon/icons'
import { CiCircleAlert } from 'react-icons/ci'
import {
  Container,
  Section,
  SectionTitle,
  CloseButton,
  CloseButtonContainer,
} from '../../block-access-modal/style'

const InformativePopup = ({ isOpen, setIsOpen }) => {
  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      type='defaultCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={() => setIsOpen(false)}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <Section>
          <span>
            <CiCircleAlert />
          </span>
          <SectionTitle>
            Nouvelle fonctionnalité Nouvelle fonctionnalité Nouvelle
            fonctionnalité Nouvelle fonctionnalité :
          </SectionTitle>
        </Section>
      </Container>
    </Dialog>
  )
}

export default InformativePopup
