import styled from 'styled-components/macro'

export const SelectedPatientWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`

export const CloseSelectedPatient = styled.div`
  font-size: 15px;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
`

export const SelectedPatientInital = styled.div`
  margin-right: 5px;
  font-size: 9px !important;
  background-color: #fcf2ef;
  color: #d7603a;
  padding: 5px;
  border-radius: 5px;
`

export const SelectedPatientFullName = styled.p`
  font-size: 14px;
`
