import { Form, PhoneInputLabel } from './style'
import { Field, PhoneInput } from '../../../../ui'
import { IPatientRegisterFormMobileProps } from './props'

const PatientRegisterFormMobile = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
}: IPatientRegisterFormMobileProps) => {
  //Render
  return (
    <Form>
      <Field
        isRequired={undefined}
        id={undefined}
        ref={undefined}
        label='Prénom'
        name='firstName'
        type='text'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.firstName}
        error={errors.firstName}
        touched={touched.firstName}
        placeholder='Insérez votre prénom'
      />
      <Field
        label='Nom'
        name='lastName'
        type='text'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.lastName}
        error={errors.lastName}
        touched={touched.lastName}
        placeholder='Insérez votre nom'
        isRequired={undefined}
        id={undefined}
        ref={undefined}
      />
      <PhoneInput
        id='phoneNumber'
        label={
          <PhoneInputLabel>
            <p>Téléphone</p>
          </PhoneInputLabel>
        }
        type='tel'
        name='phoneNumber'
        value={values.phoneNumber}
        onChange={handleChange}
        error={errors.phoneNumber}
        touched={touched.phoneNumber}
        isRequired={false}
      />
      <Field
        label='E-mail'
        name='email'
        type='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        placeholder='Insérez votre adresse e-mail'
        isRequired={undefined}
        id={undefined}
        ref={undefined}
      />
      <Field
        label='Mot de passe'
        id='password'
        name='password'
        type='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        placeholder='Mot de passe'
        isRequired={undefined}
        ref={undefined}
      />
    </Form>
  )
}

export default PatientRegisterFormMobile
