import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import {
  Container,
  Infos,
  DiscountInfo,
  CommissionInfo,
  InfosContainer,
  FlexCardAmount,
  CardAmount,
  CardAmountText,
  CardAmountTextAmount,
  PromoCode,
} from './style'
import { useGetDiscountsByPrescriberId } from '../../../services/api/prescriber/prescriber'
import { useGetMe } from '../../../utils/hooks/getMe'

const { Handle } = Slider

const handle = props => {
  const { value, dragging, index, ...restProps } = props

  return (
    <SliderTooltip
      prefixCls='rc-slider-tooltip'
      overlay={`${value} %`}
      visible={dragging}
      placement='top'
      zIndex={50}
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}

const DiscountCode = ({
  selectValue,
  setSelectedValue,
  activeDiscount = '',
}) => {
  const { data: prescriber } = useGetMe()

  const discount = useGetDiscountsByPrescriberId(
    prescriber?.id,
    {},
    {
      query: { enabled: !!prescriber?.id },
    }
  )

  const commissionPercentage = prescriber?.commissionPercentage

  const promoCodes = discount?.data?.data
    ?.map(i => i?.percentage)
    ?.filter(
      code =>
        code !== 8 ||
        commissionPercentage != null ||
        code <= commissionPercentage
    )

  const uniqueNumbersArray = Array.from(promoCodes || [])

  // Sorting the array in ascending order
  uniqueNumbersArray?.sort((a, b) => a - b)
  let transformedObject = uniqueNumbersArray?.reduce(
    (obj, item) => {
      obj[item] = item
      return obj
    },
    { 0: 0 }
  )

  const min = 0
  const max = Math.max(...uniqueNumbersArray)

  //Render
  return (
    <Container>
      <Slider
        min={min}
        max={max}
        handle={handle}
        step={5}
        value={selectValue}
        onChange={v => (v < max + 1 ? setSelectedValue(v) : undefined)}
        marks={transformedObject}
        style={{ margin: '0.5em .3125rem', maxWidth: '19.8125rem' }}
      />
      <Infos>
        <InfosContainer>
          <DiscountInfo>Réduction</DiscountInfo>
          <DiscountInfo>{selectValue}%</DiscountInfo>
        </InfosContainer>
        <InfosContainer style={{ alignItems: 'flex-end' }}>
          <CommissionInfo>Points</CommissionInfo>
          <CommissionInfo>{max - selectValue}%</CommissionInfo>
        </InfosContainer>
      </Infos>
      {activeDiscount ? (
        <PromoCode>Code de réduction: {activeDiscount}</PromoCode>
      ) : (
        ''
      )}

      <FlexCardAmount>
        <CardAmount>
          <CardAmountText>% de la réduction</CardAmountText>
          <CardAmountTextAmount>{selectValue}%</CardAmountTextAmount>
        </CardAmount>
        <CardAmount>
          <CardAmountText>% de points</CardAmountText>
          <CardAmountTextAmount>{max - selectValue}%</CardAmountTextAmount>
        </CardAmount>
      </FlexCardAmount>
    </Container>
  )
}

export default DiscountCode
