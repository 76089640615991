import styled from 'styled-components/macro'

export const TabWrapper = styled.div`
  margin-bottom: 20px;
`

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
`

export const Table = styled.table`
  border-radius: 15px;
  color: #393360;
  width: 100%;
  text-align: center;
`

export const HorizontalSeparator = styled.div`
  border: 0.5px solid #e9eaf0;
`

export const NutritionContainerHeader = styled.div`
  display: flex;
  align-items: center;
`
export const NutritionContainerTitle = styled.h3`
  color: #393360;
  font-size: 14px;
`

export const TableHeaderRow = styled.tr`
  margin-top: 20px;
  border-bottom: 0.5px solid black;
  border-top: 0.5px solid black;
  display: flex;
  padding: 5px;
  font-size: 14px;
`

export const TableHeaderCell = styled.th`
  width: 150px;
  display: flex;
  font-size: 10px;
`

export const TableRow = styled.tr`
  padding: 5px;
  border-bottom: 0.5px solid #e9eaf0;
  margin-bottom: 5px;
  display: flex;
`

export const TableCell = styled.th`
  font-size: 11px;
  text-align: left;
  font-weight: 300;
  width: 150px;
`

export const Select = styled.select`
  background: #f6f7fb;
  border-radius: 15px;
  padding: 5px;
  border: none;
  font-size: 10px;
  margin: 0 8px;
`

export const Icon = styled.span`
  margin-right: 4px;
  font-size: 12px;
`

export const Na = styled.p`
  color: black !important;
`

export const IconWrapper = styled.div`
  display: inline-block;
  font-size: 12px;
  color: black;
  margin-left: 3px;
`

export const PopupContent = styled.div`
  width: 120px;
  margin-top: 10px;
  padding: 15px 20px 10px 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  text-decoration: none;
  color: inherit;
  outline: none;
  font-size: 10px;
`

export const NoProduct = styled.div`
  margin-top: 20px;
  width: 100%;
  text-align: center;
`
