import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../../utils/constants'

export const Container = styled.div`
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  font-size: 13px;
  border-radius: 10px;
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
`
export const List = styled.div`
  margin-bottom: 20px;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const ListTitle = styled.h3``

export const Item = styled.div`
  display: flex;
  align-items: center;
`

export const ItemIcon = styled.div`
  margin-right: 10px;
  margin-top: 5px;
  font-size: 20px;
  color: red;
`

export const ItemText = styled.p`
  color: #393360;
  font-size: 12px;
`

export const FlexList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;

  ${MediaQuery.S_AND_DOWN} {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Card = styled.div`
  border: 0.5px solid #e2e4f3;
  border-radius: 11px;
  padding: 10px;
  font-size: 10px;
  font-weight: 300;
  ${MediaQuery.S_AND_DOWN} {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const CardTitle = styled.div`
  color: #3a835f;
  margin-bottom: 3px;
`
export const CardText = styled.p`
  font-size: 12px;
`
