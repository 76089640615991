import { useState } from 'react'
import { Dialog, Field } from '../../../../ui'
import { IconCross } from '../../../../ui/icon/icons'
import { useGetPrescriberFiles } from '../../../../../services/api/prescriber/prescriber'
import {
  CloseButton,
  CloseButtonContainer,
  Container,
  Title,
  Header,
  Content,
  UploadButton,
  SubTitle,
  ImageLabel,
} from './style'
import { AiOutlineCloudUpload } from 'react-icons/ai'

import UploadDialog from '../upload-dialog'
import { useGetMe } from '../../../../../utils/hooks/getMe'

const FormDialog = ({ isOpen, onClose, files, setFiles }) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const [isFormOpened, setIsFormOpened] = useState(false)

  const { data: prescriberFiles } = useGetPrescriberFiles(prescriber?.id, {
    options: {
      query: { enabled: !!prescriber?.id },
    },
  })

  // Functions
  function close() {
    onClose()
  }

  // Render
  return (
    <>
      <UploadDialog
        isOpen={isFormOpened}
        onClose={() => setIsFormOpened(false)}
        setFiles={data => {
          setFiles([
            ...files,
            {
              prescriberFileId: data.id,
              fileUrl: data.fileUrl,
              fileName: data.fileName,
            },
          ])
        }}
      />
      <Dialog
        isOpen={isOpen}
        onClose={close}
        type='defaultCenter'
        styleContainer={undefined}
      >
        <CloseButtonContainer>
          <CloseButton onClick={close}>
            <IconCross width='1.0831rem' height='1.0831rem' stroke='#2E6A4B' />
          </CloseButton>
        </CloseButtonContainer>
        <Container>
          <Header>
            <Title>Joindre un fichier à la recommandation</Title>
          </Header>
          <Content>
            <SubTitle>Sélectionner un fichier </SubTitle>
            <Field
              type='select'
              options={prescriberFiles?.map(file => ({
                label: (
                  <ImageLabel>
                    <p>{file?.fileName}</p>{' '}
                  </ImageLabel>
                ),
                value: file,
              }))}
              // defaultValue={selectFormatDefaultValue()}
              onChange={data => {
                setFiles([
                  ...files,
                  {
                    prescriberFileId: data.value.id,
                    fileUrl: data.value.fileUrl,
                    fileName: data.value.fileName,
                  },
                ])
                onClose()
              }}
              isSearchable
              isClearable
            />
            <p>ou uploader un nouveau fichier depuis votre ordniateur</p>
            <UploadButton
              onClick={e => {
                e.preventDefault()
                setIsFormOpened(true)
                onClose()
              }}
            >
              <AiOutlineCloudUpload />
            </UploadButton>
          </Content>
        </Container>
      </Dialog>
    </>
  )
}

export default FormDialog
