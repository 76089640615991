import styled from 'styled-components/macro'

export const Container = styled.div`
  margin-top: 40px;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Box = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(65, 91, 115, 0.15);
  width: 48%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 25px;
  border-radius: 5px;
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`
export const EditIcon = styled.span`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
`

export const BoxLine = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`

export const BoxLineItem = styled.div`
  width: 42%;
  display: flex;
  align-items: center;
`
export const BoxLineItemIcon = styled.span`
  margin-top: 4px;
  margin-right: 10px;
  font-size: 15px;
  border-radius: 5px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.bg ? props.bg : '')};
`

export const Initial = styled.span`
  font-size: 9px !important;
  background-color: #fcf2ef;
  color: #d7603a;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
  text-transform: uppercase;
`

export const Note = styled.td`
  h4 {
    margin-right: 10px;
  }
  p {
    display: inline-block;
  }
`

export const BoxText = styled.p`
  font-size: 13px;
`
export const FullName = styled.p`
  font-size: 16px;
`
