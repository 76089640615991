import styled from 'styled-components/macro'
import { MediaQuery, ViewMode } from '../../utils/constants'

export const Content = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
`
export const NoFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5rem;
`

export const ProductsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'row' : 'column'};
  padding: 1.25rem 0;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: space-evenly;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16.25rem, 1fr));
  grid-row-gap: 1.25rem;
  grid-column-gap: 2.8125rem;
  ${MediaQuery.M_AND_DOWN} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.625rem;
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
    @media (max-width: 20.625rem) {
      grid-template-columns: repeat(1, minmax(6.875rem, 1fr));
    }
  }
`

export const Result = styled.p`
  font-size: 1rem;
  opacity: 0.8;
  margin-left: 0.3125rem;
`

export const WrapperSearchbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.9375rem;
`

export const FilterButton = styled.button`
  display: flex;
  flex-direction: row;
  height: 2.875rem;
  padding: 0rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-System-spacing-8, 0.5rem);
  flex-shrink: 0;
  border-radius: var(--Spacing-System-radius-8, 0.5rem);
  border: 0.0625rem solid var(--Compliment-Light-Green-500, #65b293);
  background: #fff;
  color: var(--Compliment-Light-Green-500, #65b293);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`
