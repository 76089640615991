import { Link } from 'react-router-dom'
import { ICloseButtonProps } from './props'
import {
  LargeCloseIconWrapper,
  LargeGrFormClose,
  MediumCloseIcon,
  MediumCloseIconWrapper,
} from './style'

const CloseButton = ({
  linkTo,
  onClick,
  type = 'large',
}: ICloseButtonProps) => {
  //Attributes
  const Icon = type === 'medium' ? MediumCloseIcon : LargeGrFormClose
  const Style =
    type === 'medium' ? MediumCloseIconWrapper : LargeCloseIconWrapper

  //Return
  return linkTo ? (
    <Link to={linkTo}>
      <Style>
        <Icon />
      </Style>
    </Link>
  ) : (
    <Style onClick={onClick}>
      <Icon />
    </Style>
  )
}

export default CloseButton
