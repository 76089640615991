import {
  User,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import React, { createContext, useContext, useState, useEffect } from 'react'
import { auth } from './firebase'
import { getUserBySub } from '../api/users/users'
import { Role } from '../api/types'
import { toast } from 'react-toastify'
import { ErrorCodes } from '../../utils/constants'

interface AuthContextType {
  currentUser: User | null
  login: (email: string, password: string, role: Role) => Promise<void>
  refreshToken: () => Promise<void>
  logout: () => Promise<void>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const login = async (email: string, password: string, role: Role) => {
    const { user } = await signInWithEmailAndPassword(auth, email, password)
    // Store access token in local storage to use it in the API calls
    const idToken = await user.getIdToken()
    localStorage.setItem('access_token', idToken)

    if (role === Role.PRESCRIBER) {
      await verifyUserIsPrescriber()
    } else {
      await verifyUserIsPatient(user)
    }
  }

  const verifyUserIsPrescriber = async () => {
    try {
      await getUserBySub({ role: Role.PRESCRIBER })
      localStorage.setItem('role', Role.PRESCRIBER)
    } catch (e: any) {
      if (
        e.response.data ===
        'repository/unable-to-find-resource?resource=prescriber'
      ) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        toast.error(`Vous n'avez pas de compte prescripteur.`)
        await logout()
        return
      }
    }
  }

  const verifyUserIsPatient = async (user: User) => {
    if (!user.emailVerified) {
      localStorage.removeItem('access_token')
      await sendEmailVerification(user)
      toast.success('Un email vous a été envoyé pour vérifier votre identité.')
      await logout()
      throw new Error(ErrorCodes.EMAIL_NOT_VERIFIED)
    }

    try {
      await getUserBySub({ role: Role.PATIENT })
      localStorage.setItem('role', Role.PATIENT)
    } catch (e: any) {
      if (
        e.response.data ===
        'repository/unable-to-find-resource?resource=patient'
      ) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        toast.error(`Vous n'avez pas de compte Client.`)
        await logout()
        return
      }
    }
  }

  const logout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('role')
    return signOut(auth)
  }

  const refreshToken = async () => {
    if (!currentUser) {
      throw new Error('No user logged in')
    }

    const idToken = await currentUser.getIdToken()
    localStorage.setItem('access_token', idToken)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const idToken = await user.getIdToken()
        localStorage.setItem('access_token', idToken)
      } else {
        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
      }
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value: AuthContextType = {
    currentUser,
    login,
    logout,
    refreshToken,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
