import React from 'react'
import { useHistory } from 'react-router-dom'
import { Container, ArrowBack, ImageLogo } from './style'
import IconArrowLeft from '../../../components/ui/icon/icons/IconArrowLeft'

function HeaderSignin() {
  const { goBack } = useHistory()

  //Render
  return (
    <Container>
      <ArrowBack onClick={() => goBack()}>
        <IconArrowLeft width='.6875rem' height='.6875rem' fill='#2E6A4B' />
      </ArrowBack>
      <ImageLogo
        src={'/assets/images/logo-compliment.png'}
        alt='logo-image'
        onClick={() => goBack()}
      />
    </Container>
  )
}

export default HeaderSignin
