import toast from 'react-hot-toast'
import { useQueryClient } from 'react-query'
import { MenuContainer, MenuContainerItem, Checkbox, FavIcon } from '../style'
import { AiFillStar } from 'react-icons/ai'
import {
  useGetAllPrescriberFavoriteListsByProductId,
  useDeletePrescriberFavoriteListItemByProductIdAndListId,
} from '../../../../../services/api/favorites/favorites'
import { Dropdown } from '../../../../ui'
import { useGetMe } from '../../../../../utils/hooks/getMe'
import { DeleteToFavoriteProps } from './props'
import {
  refetchFavorite,
  refetchAllFavorite,
  refetchFavoriteListItemByProductId,
} from '../../../../../utils/helpers'

const DeleteToFavorite = ({
  favoriteListId,
  productId,
}: DeleteToFavoriteProps) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const queryClient = useQueryClient()

  const { data: favoriteListsByProductId } =
    useGetAllPrescriberFavoriteListsByProductId(prescriber?.id, productId, {
      query: {
        enabled: !!prescriber?.id && !!productId,
      },
    })

  const { mutateAsync: deletePrescriberFavoriteListItemByProductIdAndListId } =
    useDeletePrescriberFavoriteListItemByProductIdAndListId({
      mutation: {
        onSuccess: () => {
          toast.success('Le produit a bien été supprimé')
          refetchFavorite(queryClient, favoriteListId)
          refetchAllFavorite(queryClient, prescriber?.id)
          refetchFavoriteListItemByProductId(
            queryClient,
            prescriber?.id,
            productId
          )
        },
        onError: error => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })

  // Functions
  const handleDeletePrescriberFavoriteListItemByProductIdAndListId = (
    favoriteId: string,
    productId: string
  ) => {
    deletePrescriberFavoriteListItemByProductIdAndListId({
      favoriteListId: favoriteId,
      productId: productId,
    })
  }

  return (
    <Dropdown
      content={
        <MenuContainer>
          {favoriteListsByProductId?.map(favorite => {
            return (
              <MenuContainerItem
                onClick={() =>
                  handleDeletePrescriberFavoriteListItemByProductIdAndListId(
                    favorite?.id,
                    productId
                  )
                }
              >
                <Checkbox checked={true} />
                <p>{favorite?.name}</p>
              </MenuContainerItem>
            )
          })}
        </MenuContainer>
      }
    >
      <FavIcon>
        <AiFillStar />
      </FavIcon>
    </Dropdown>
  )
}

export default DeleteToFavorite
