import {
  ProgressBarWrapper,
  ProgressBarWrapperTitle,
  ProgressBar,
} from '../style'
import { ProgressBarPoint } from './style'
import { ShippingProgressBarProps } from './props'

const ShippingProgressBar = ({
  currentCartAmount,
  freeShippingThreshold,
  isPublic,
}: ShippingProgressBarProps) => {
  // Functions
  function percentageCalculator(currentCartAmount, freeShippingThreshold) {
    const percentage = (currentCartAmount / freeShippingThreshold) * 100
    return percentage.toFixed(2)
  }

  function pointPositionCalculator(pointValue, freeShippingThreshold) {
    return (pointValue / freeShippingThreshold) * 100
  }

  function textFreeShippingCalculator(currentCartAmount) {
    if (currentCartAmount < 59) {
      const remainingAmount = 59 - currentCartAmount
      return (
        <strong style={{ fontWeight: 500 }}>
          Plus que {remainingAmount.toFixed(2)}€ pour bénéficier de la livraison
          à prix réduit.
        </strong>
      )
    } else if (currentCartAmount >= 59 && currentCartAmount < 89) {
      const remainingAmount = 89 - currentCartAmount
      return (
        <strong style={{ fontWeight: 500 }}>
          Livraison à prix réduit ! Plus que {remainingAmount.toFixed(2)}€ pour
          bénéficier de la livraison gratuite en point relais.
        </strong>
      )
    } else if (currentCartAmount >= 89 && currentCartAmount < 139) {
      const remainingAmount = 139 - currentCartAmount
      return (
        <strong style={{ fontWeight: 500 }}>
          Livraison gratuite en point relais ! Plus que{' '}
          {remainingAmount.toFixed(2)}€ pour bénéficier de la livraison gratuite
          à domicile !
        </strong>
      )
    } else {
      return (
        <strong style={{ fontWeight: 500 }}>
          Vous bénéficiez de la livraison gratuite gratuite à domicile!
        </strong>
      )
    }
  }

  //Render
  return (
    <ProgressBarWrapper
      style={{
        paddingBottom: '20px',
      }}
    >
      <ProgressBarWrapperTitle>
        {textFreeShippingCalculator(currentCartAmount)}
      </ProgressBarWrapperTitle>
      <ProgressBar>
        <span
          style={{
            transition: 'all .3s',
            width: `${percentageCalculator(
              currentCartAmount,
              freeShippingThreshold
            )}%`,
          }}
        ></span>
        <ProgressBarPoint
          style={{
            position: 'absolute',
            left: `${pointPositionCalculator(59, freeShippingThreshold)}%`,
          }}
          data-label='€59'
        />
        <ProgressBarPoint
          style={{
            position: 'absolute',
            left: `${pointPositionCalculator(89, freeShippingThreshold)}%`,
          }}
          data-label='€89'
        />
        <ProgressBarPoint
          style={{
            position: 'absolute',
            left: `${pointPositionCalculator(139, freeShippingThreshold)}%`,
          }}
          data-label='€139'
        />
      </ProgressBar>
    </ProgressBarWrapper>
  )
}

export default ShippingProgressBar
