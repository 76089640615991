import { useParams } from 'react-router-dom'
import { useState } from 'react'

import {
  BoxTable,
  TitleBox,
  Title,
  Table,
  NoPrescriptionMessage,
  TableHeader,
  TableHeaderWrapper,
  TableBody,
  TableHeaderItem,
} from './style'
import { useHistory } from 'react-router'
import Button from '../../ui/button'

import { useGetMe } from '../../../utils/hooks/getMe'

import {
  useGetPatient,
  useGetPatientPrescriptions,
} from '../../../services/api/patients/patients'
import CreateRecoDialog from '../../reco/create-reco-dialog'
import PrescriptionRow from '../../prescription/prescriptions-container/prescription-row'

interface PrescriptionTableProps {
  tableTitle?: string
  size?: number
}

const PrescriptionTable = ({ tableTitle, size }: PrescriptionTableProps) => {
  // Attributes
  const history = useHistory()
  const match = useParams()
  const { data: prescriber } = useGetMe()

  const { data: patient } = useGetPatient(prescriber?.id, Number(match.id), {
    query: { enabled: !!prescriber?.id && !!match?.id },
  })
  const { data: patientPrescriptions, refetch: refetchPrescriptions } =
    useGetPatientPrescriptions(patient?.id, {
      size: size ?? undefined,
      sort: 'DATE',
    })

  const [isCreateRecoDialogOpen, setIsCreateRecoDialogOpen] = useState(false)

  // Render
  return (
    <BoxTable>
      <TitleBox>
        <Title>
          {tableTitle ? tableTitle : 'Les recommandations de '}
          {patient?.firstName}
        </Title>

        <Button
          iconLeft={undefined}
          appearance='minimal'
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
          onClick={() => {
            history.push(`/prescriptions/new?step=2&patientId=${patient?.id}`)
          }}
          style={{
            border: '1px solid #3a845e',
          }}
        >
          Nouvelle recommandation
        </Button>
      </TitleBox>
      {patientPrescriptions?.data?.length === 0 ? (
        <NoPrescriptionMessage>
          {patient?.firstName} n'a aucune recommandation
        </NoPrescriptionMessage>
      ) : (
        <Table>
          <TableHeader>
            <TableHeaderWrapper>
              <TableHeaderItem>Id</TableHeaderItem>
              <TableHeaderItem>Date de création</TableHeaderItem>
              <TableHeaderItem></TableHeaderItem>
              <TableHeaderItem>Produits</TableHeaderItem>
              <TableHeaderItem>Commandes</TableHeaderItem>
              <TableHeaderItem>Statut</TableHeaderItem>
              <TableHeaderItem>Actions</TableHeaderItem>
            </TableHeaderWrapper>
          </TableHeader>
          <TableBody>
            {patientPrescriptions?.data?.map(prescription => {
              return (
                <PrescriptionRow
                  key={prescription.id}
                  prescription={prescription}
                  nav={undefined}
                  refetchPrescriptions={refetchPrescriptions}
                  onSendMail={undefined}
                />
              )
            })}
          </TableBody>
        </Table>
      )}

      <CreateRecoDialog
        isOpen={isCreateRecoDialogOpen}
        onClose={() => setIsCreateRecoDialogOpen(false)}
        type='PRESCRIPTION'
        patientUrl={`&patientId=${patient?.id}`}
      />
    </BoxTable>
  )
}

export default PrescriptionTable
