import styled from 'styled-components/macro'

export const Container = styled.div`
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  font-size: 13px;
  border-radius: 10px;
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
`
export const List = styled.ul`
  margin-bottom: 20px;
  list-style: none;

  li::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: red; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
`

export const Item = styled.li`
  margin-left: 20px;
  margin-bottom: 20px;
`

export const Green = styled.span`
  color: #3a835f;
  margin-right: 5px;
`

export const ItemText = styled.p`
  color: #393360;
`
