import styled from 'styled-components/macro'

export const Container = styled.div`
  .animation-enter {
    opacity: 0;
    transform: translateY(-20px);
  }
  .animation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .animation-exit {
    opacity: 1;
  }
  .animation-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms, transform 300ms;
  }
`

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  span {
    font-size: 22px;
    transition: all 0.4s;
    transform: ${({ $isCollapsed }) =>
      $isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`
