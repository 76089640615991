import styled from 'styled-components/macro'
import { Color } from '../../../../../utils/constants'

export const Container = styled.td`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 4px;
  width: auto;
  height: 29px;
`

export const Title = styled.h4`
  color: ${({ color }) => color};
  margin-bottom: 10px;
`

export const Text = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${({ color }) => color};
  opacity: 0.6;
`

export const Button = styled.button`
  font-size: 12px;
  line-height: 14px;
  color: ${({ color }) => color};
  font-size: 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 10px;
  color: ${Color.DEEP_SEA};
`
