import { useState, useEffect } from 'react'

import {
  ProductPreview,
  Img,
  Block1,
  Block2,
  ProductName,
  Quantity,
  Minus,
  Plus,
  NumberQuantity,
  SmallPrice,
  Title,
  Form,
  IconsContainer,
  MainContainer,
  NotesContainer,
  DialogContainer,
  P,
} from './style'
import { Button, Dialog, Field, Icon, Tooltip, Spinner } from '../../../ui'
import { getProductPricePerMonth } from '../../../../utils/data'
import { ProductPreviewProps } from './props'

import { useGetProductDosingByProductId } from '../../../../services/api/dosings/dosings'
import {
  getTakePackagingFormatName,
  getTakeFormatName,
} from '../../../../utils/helpers/functions'

const ProductPreviewEl = ({
  product,
  quantity,
  notes,
  takeIndex,
  isLast,
  setProductNotesDraft,
  decreaseQttyProductFromDraftTake,
  increaseQttyProductFromDraftTake,
  removeProductFromDraftTake,
  onDetailOpen,
}: ProductPreviewProps) => {
  // Attributes
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const { data: dosings } = useGetProductDosingByProductId(product?.id)

  const [note, setNote] = useState('' || notes)
  const [noteHasChanged, setNoteHasChanged] = useState(false)

  useEffect(() => {
    if (notes !== note && !noteHasChanged) {
      setNote(notes)
    }
  }, [notes, note, noteHasChanged])

  const handleNoteModalClose = () => {
    setProductNotesDraft(product, note, takeIndex)
    setNoteHasChanged(false)
    setIsNotesModalOpen(false)
  }

  // Render
  return (
    <div>
      {!product ? (
        <Spinner fullScreen={undefined} />
      ) : (
        <ProductPreview isLast={isLast}>
          <MainContainer>
            <Img src={product?.coverImage} alt={product?.handle} />
            <Block1>
              <ProductName>{product?.name}</ProductName>
              <P>
                {!product?.standAlone
                  ? `${quantity} x ${product?.formatQuantity ?? ''} ${
                      `${getTakeFormatName(
                        Number(product?.formatQuantity),
                        dosings?.takeFormat
                      )} en sachet` ?? ''
                    }`
                  : `${quantity ?? 0} ${
                      getTakePackagingFormatName(
                        quantity,
                        dosings?.packagingFormat
                      ) ?? ''
                    } de ${dosings?.takeTotalQuantity ?? ''}  
                  ${`${getTakeFormatName(
                    Number(dosings?.takeTotalQuantity),
                    dosings?.takeFormat
                  )}
                    `} (${dosings?.contentTotal ?? ''} ${
                      dosings?.contentUnit ?? ''
                    })
                  `}
              </P>

              <Quantity>
                Quantité:&nbsp;&nbsp;&nbsp;
                <Minus
                  onClick={() =>
                    decreaseQttyProductFromDraftTake(product, takeIndex)
                  }
                />
                <NumberQuantity>{quantity}</NumberQuantity>
                <Plus
                  onClick={() =>
                    increaseQttyProductFromDraftTake(product, takeIndex)
                  }
                />
              </Quantity>
            </Block1>
            <Block2>
              <SmallPrice>
                €{getProductPricePerMonth(product) * quantity}
                /mois
              </SmallPrice>
              <IconsContainer>
                <Tooltip label='Voir les détails du produit' size={undefined}>
                  <Icon name='info' onClick={onDetailOpen} />
                </Tooltip>
                <Tooltip
                  label={
                    notes && notes?.length
                      ? 'Gérer la note'
                      : "Ajouter un conseil d'utilisation"
                  }
                  size={undefined}
                >
                  <Icon
                    name={
                      notes && notes?.length ? 'comment-reversed' : 'comment'
                    }
                    onClick={() => setIsNotesModalOpen(true)}
                  />
                </Tooltip>
                <Tooltip label='Retirer de la cure' size={undefined}>
                  <Icon
                    name='trash'
                    onClick={() =>
                      removeProductFromDraftTake(product, takeIndex)
                    }
                  />
                </Tooltip>
              </IconsContainer>
            </Block2>
          </MainContainer>
          {!!notes?.length && (
            <NotesContainer>
              <p>{note}</p>
            </NotesContainer>
          )}
          <Dialog
            isOpen={isNotesModalOpen}
            onClose={handleNoteModalClose}
            styleContainer={undefined}
          >
            <DialogContainer>
              <Title>
                Ajouter un conseil d'utilisation pour "{product?.name}"
              </Title>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                }}
              >
                <Field
                  label='Ces conseils seront ajoutés au livret que votre client recevra avec sa cure.'
                  id={`note-${product?.handle}`}
                  type='textarea'
                  placeholder={`Exemple: "Prenez ce complément dans un grand verre d'eau"`}
                  value={note}
                  onChange={e => {
                    setNoteHasChanged(true)
                    setNote(e.target.value)
                  }}
                  name={undefined}
                  onBlur={undefined}
                  error={undefined}
                  touched={undefined}
                  isRequired={undefined}
                  ref={undefined}
                />
                <Button
                  onClick={handleNoteModalClose}
                  appearance={undefined}
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                >
                  Continuer
                </Button>
              </Form>
            </DialogContainer>
          </Dialog>
        </ProductPreview>
      )}
    </div>
  )
}

export default ProductPreviewEl
