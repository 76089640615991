import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from '@react-pdf/renderer'
import { Color } from '../../utils/constants'
import BookletFooter from './BookletFooter'
import BookletHeader from './BookletHeader'
import BookletProducts from './BookletProducts'
import BookletInfo from './BookletInfo'

const styles = StyleSheet.create({
  page: { paddingTop: 20, paddingBottom: 20 },
  section: { marginTop: 8, marginBottom: 8, marginLeft: 20, marginRight: 20 },
  sectionQuestion: { margin: 3 },
  title: { color: Color.DEEP_SEA, fontSize: 14 },
  subTitle: { color: '#777', fontSize: 12 },
  answer: { color: '#333', fontSize: 10, marginLeft: 10, marginTop: 3 },
})

export const Pdf = ({
  favorites,
  percentage,
  discountCode,
  qrCodeUrl,
  prescriberFullName,
}) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <BookletHeader
          percentage={percentage}
          discountCode={discountCode}
          qrCodeUrl={qrCodeUrl}
          prescriberFullName={prescriberFullName}
        />

        <View
          style={{
            backgroundColor: '#F8F9FC',
            border: '1px solid #e7e9f4',
            borderRadius: '10px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '6px 10px',
            margin: '0px 20px 10px 15px',
            minHeight: 'auto',
            fontSize: '8px',
          }}
        >
          <View
            style={{
              minWidth: '32%',
              maxWidth: '32%',
            }}
          >
            <Text>Nom</Text>
          </View>
          <View
            style={{
              minWidth: '15%',
              maxWidth: '15%',
            }}
          >
            <Text>Laboratoire</Text>
          </View>
          <View
            style={{
              minWidth: '7%',
              maxWidth: '7%',
            }}
          >
            <Text>Prix</Text>
          </View>

          {percentage > 0 && (
            <View
              style={{
                minWidth: '10%',
                maxWidth: '10%',
              }}
            >
              <Text>Prix réduit</Text>
            </View>
          )}
          <View
            style={{
              minWidth: percentage > 0 ? '30%' : '40%',
              maxWidth: percentage > 0 ? '30%' : '40%',
              margin: '0 10px',
            }}
          >
            <Text>Conseils d’utilisation</Text>
          </View>
        </View>

        {favorites?.map(favorite => (
          <>
            {favorite?.products?.length && (
              <View
                style={{
                  backgroundColor: '#EEF7F3',
                  border: '1px solid #e7e9f4',
                  borderRadius: '10px',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  padding: '3px 8px',
                  margin: '3px 20px 3px 15px',
                  minHeight: 'auto',
                  fontSize: '9px',
                }}
              >
                <Text
                  style={{
                    color: '#5DB498',
                  }}
                >
                  {favorite?.favoriteName}
                </Text>
              </View>
            )}

            {favorite?.products?.map(
              ({ name, brand, contraindications, variants, takeFormat }) => {
                return (
                  <BookletProducts
                    name={name}
                    brandName={brand?.name}
                    price={variants[0]?.price}
                    takeTotalQuantity={variants[0]?.takeTotalQuantity}
                    takeFormat={takeFormat}
                    description={contraindications}
                    percentage={percentage}
                  />
                )
              }
            )}
          </>
        ))}
        <View
          style={{
            marginTop: 'auto',
          }}
        >
          <BookletInfo />
          <BookletFooter />
          <Text
            style={{
              fontSize: '7px',
              margin: '0px 10px 0px 15px',
            }}
          >
            Ce document n’est pas une prescription médicale et ne se substitue
            en aucun cas à un traitement médical spécifique.
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export const Doc = ({
  favorites,
  percentage,
  discountCode,
  qrCodeUrl,
  prescriberFullName,
}) => {
  return (
    <PDFDownloadLink
      document={
        <Pdf
          favorites={favorites}
          percentage={percentage}
          discountCode={discountCode}
          qrCodeUrl={qrCodeUrl}
          prescriberFullName={prescriberFullName}
        />
      }
      fileName={`filename.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? 'Chargement...' : 'Exporter le fichier'
      }
    </PDFDownloadLink>
  )
}
