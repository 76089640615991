import { useState } from 'react'

import {
  Container,
  TitleInput,
  DescriptionWrapper,
  DescriptionWrapperTitle,
  DescriptionWrapperInput,
  DatePickerWrapper,
  DatePickerWrapperTitle,
  DatePickerWrapperDate,
  DatePickerWrapperDateIcon,
  PatientWrapper,
  PatientWrapperTitle,
  PatientWrapperSelect,
  PatientWrapperSelectPatientButton,
  PatientWrapperCreatePatientButton,
} from './style'

import { Dropdown } from '../../ui'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { AiOutlineCalendar } from 'react-icons/ai'

import { NoteState } from '../../../pages/notes/props'
import { Updater } from 'use-immer'
import PatientList from './patient-list'
import SelectedPatient from './selected-patient'
import CreateNewPatient from './create-new-patient'
export interface CreateUpdateNoteProps extends NoteState {
  setNotes: (state: Updater<NoteState>) => void
  allowPatientDeletion?: boolean
}

const CreateUpdateNote = ({
  title,
  description,
  consultationDate,
  patientId,
  setNotes,
  allowPatientDeletion = true,
}: CreateUpdateNoteProps) => {
  // Attributes
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  // Render
  return (
    <Container>
      <TitleInput
        value={title}
        onChange={e => {
          setNotes((state: NoteState) => {
            state.title = e.target.value
          })
        }}
        placeholder='Tapez le titre ici'
        autofocus
      />

      <PatientWrapper>
        <PatientWrapperTitle>Concernant:</PatientWrapperTitle>

        {patientId ? (
          <SelectedPatient
            allowDeletion={allowPatientDeletion}
            patientId={patientId}
            setNotes={setNotes}
          />
        ) : (
          <PatientWrapperSelect>
            <Dropdown content={<PatientList setNotes={setNotes} />}>
              <PatientWrapperSelectPatientButton>
                Séléctionner un client
              </PatientWrapperSelectPatientButton>
            </Dropdown>
            <PatientWrapperCreatePatientButton
              onClick={() => setIsDialogOpen(true)}
            >
              ou créer un nouveau client
            </PatientWrapperCreatePatientButton>
          </PatientWrapperSelect>
        )}
      </PatientWrapper>

      <DatePickerWrapper>
        <DatePickerWrapperTitle>Date de consultation:</DatePickerWrapperTitle>
        <DatePickerWrapperDate>
          <DatePickerWrapperDateIcon>
            <AiOutlineCalendar />
          </DatePickerWrapperDateIcon>
          <DatePicker
            dateFormat='dd/MM/yyyy'
            selected={consultationDate}
            onChange={(date: Date) => {
              setNotes((state: NoteState) => {
                state.consultationDate = date
              })
            }}
            placeholderText='Séléctionner un date'
          />
        </DatePickerWrapperDate>
      </DatePickerWrapper>

      <DescriptionWrapper>
        <DescriptionWrapperTitle>Description:</DescriptionWrapperTitle>
        <DescriptionWrapperInput
          value={description}
          onChange={e => {
            setNotes((state: NoteState) => {
              state.description = e.target.value
            })
          }}
          placeholder='Tapez la description ici'
          autofocus
        />
      </DescriptionWrapper>
      <CreateNewPatient
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        setNotes={setNotes}
      />
    </Container>
  )
}

export default CreateUpdateNote
