import styled from 'styled-components/macro'

export const InputContainer = styled.div`
  width: 100%;
  .form-control {
    display: flex !important;
    border: 1px solid #e9eaf0 !important;
    border-radius: 8px !important;
    box-sizing: border-box !important;
    min-height: 38px !important;
    align-items: center !important;
    width: 100% !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02) !important;
  }

  .flag-dropdown {
    background-color: white !important;
    border: 1px solid #e9eaf0 !important;
    border-radius: 8px !important;
  }
  .search-emoji {
    display: none;
  }

  .search-box {
    margin: 0 !important;
    width: 95% !important;
    padding: 7px 5px !important;
    border: 1px solid #eee !important;
    border-radius: 3px !important;
    outline: none !important;
  }

  .style__Field-sc-ue6z58-0 {
    margin: 0 !important;
  }

  span {
    font-size: 11px;
    opacity: 0.7;
  }
`
