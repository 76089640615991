import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const BigWrapper = styled.div`
  width: ${props => (props.isStepValided ? '100%' : ' 49%')};
  display: flex;
  flex-direction: column;
  flex-direction: ${props => (props.isStepValided ? 'row' : ' column')};
  justify-content: space-between;
  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
    flex-direction: ${props => (props.isStepValided ? 'column' : ' none')};
  }
`

export const Wrapper = styled.div`
  margin-top: 20px;
  background-color: yellow;
  width: ${props => (props.isStepValided ? '49%' : '100%')};

  ${MediaQuery.L_AND_DOWN} {
    width: ${props => (props.isStepValided ? '100%' : 'none')};
  }

  height: ${props => (props.isStepValided ? 'auto' : '100%')};
  margin-top: 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 15px;
  padding: 25px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 15px;
    margin-bottom: 15px;
  }
  p {
    font-size: 12px;
    opacity: 0.7;
    margin-bottom: 10px;
  }
  a {
    font-size: 15px;
    color: inherit;
  }
`

export const ButtonCta = styled.button`
  cursor: pointer;
  color: #65b293;
  border-radius: 6px;
  padding: 7px 8px;
  font-size: 12px;
  border: 1px solid #65b293;
  background: transparent;
  white-space: nowrap;
  margin-right: 10px;
  width: 150px;
`

export const ButtonWrapper = styled.div`
  display: flex;
`
