import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const Tab = styled.div`
  margin-top: 40px;
  margin-bottom: -10px;
  display: flex;
  justify-content: space-between;
`

export const TabItem = styled.div`
  cursor: pointer;
  width: 50%;
  padding: 15px;
  text-align: center;
  border-bottom: ${props =>
    props.active ? `1px solid ${Color.DEEP_SEA}` : ''};
  color: ${props => (props.active ? Color.DEEP_SEA : '#777')};
`
