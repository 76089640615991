import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 1.5rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  z-index: 20;
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.25rem;
  margin: 2rem 4.5rem 0rem 4.5rem;
`

export const Title = styled.h1`
  margin-top: 2.5rem;
  color: #080038;
  text-align: center;
  font-size: 1.5rem;
`

export const SubTitle = styled.p`
  margin-top: 1.5rem;
  color: #080038;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
`

export const Image = styled.img`
  width: 7.5rem;
  height: auto;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  z-index: 20;
`
