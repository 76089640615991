import styled from 'styled-components/macro'

export const AlertContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: black;
  padding: 0.75rem 1.25rem;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
`

export const Title = styled.p`
  text-align: center;
  font-size: 0.875rem;
  color: white;
  font-weight: 400;
`
