import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 35.5px 20px 35.5px 40px;
  background: white;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const TitleContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

export const Title = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #080038;
`

export const DurationContainer = styled.div`
  padding: 5px 6px;
  background-color: #e4dff2;
  border-radius: 4px;
`

export const Duration = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #614e97;
`

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #8f95b2;
  max-width: 435px;
`

export const Body = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 28px;
  }
`

export const CreatedByContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`

export const CreatedBy = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #080038;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const MenuContainer = styled.div`
  position: relative;
`

export const PopoverContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 4px 1px 84px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 38px;
  right: 0;
  z-index: 15;

  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const PopoverRow = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: 6px 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

export const PopoverText = styled.p`
  font-weight: 400;
  font-size: 11.9308px;
  line-height: 14px;
  color: #080038;
`

export const ProductsPreviewContainerWrapper = styled.div`
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const UseButton = styled.button`
  cursor: pointer;
  font-weight: 400;
  font-size: 11.9308px;
  line-height: 14px;
  color: white;
  background-color: #3a845e;
  padding: 10px;
  border-radius: 10px;
  border: none;
`
