import styled from 'styled-components/macro'

export const BadgePrimaryContainer = styled.div`
  display: flex;
  height: 1rem;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background-color: #e0f0e9;
  border-radius: 6.25rem;
`
export const BadgePrimaryValue = styled.p`
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #518e76;
`
