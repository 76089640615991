import styled from 'styled-components/macro'

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  padding: 32px 28px;
  max-height: 90vh;
  overflow: auto;
  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const HubspotContainer = styled.div`
  display: ${({ displayed }) => (displayed ? 'flex' : 'none')};
`

export const LoadingContainer = styled.div`
  display: ${({ displayed }) => (displayed ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 300px;
`

export const NoFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`

export const NoFormTitle = styled.p`
  font-weight: 500;
  font-size: 25.7561px;
  line-height: 31px;
  color: #080038;
`
