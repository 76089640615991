export interface SignUpForm {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  password: string
}

export enum SignUpStep {
  STEP_1 = 1,
  STEP_2 = 2,
}
