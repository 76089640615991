import { Select, SelectTitle, SelectText } from './style'
import {
  MdOutlineRadioButtonUnchecked,
  MdOutlineRadioButtonChecked,
} from 'react-icons/md'

const SelectCommission = ({ title, description, switchAccount, selected }) => {
  return (
    <Select selected={selected} onClick={switchAccount}>
      <SelectTitle selected={selected}>
        <span>
          {selected ? (
            <MdOutlineRadioButtonChecked />
          ) : (
            <MdOutlineRadioButtonUnchecked />
          )}
        </span>
        {title}
      </SelectTitle>
      <SelectText>{description}</SelectText>
    </Select>
  )
}

export default SelectCommission
