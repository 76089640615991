/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type {
  FileResponseDTO,
  FileDTO,
  PrescriptionPagingDTO,
  GetPatientPrescriptionsParams,
  QuizPagingDTO,
  GetPatientQuizzesParams,
  NotePagingDTO,
  GetPatientNotesParams,
  PatientDTO,
  CreatePatientDTO,
  PatientPagingDTO,
  GetPatientsParams,
  UpdatePatientDTO,
} from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const uploadPatientsListFile = (
  fileDTO: FileDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<FileResponseDTO>(
    { url: `/patients/upload-list`, method: 'post', data: fileDTO },
    options
  )
}

export const useUploadPatientsListFile = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof uploadPatientsListFile>,
    TError,
    { data: FileDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof uploadPatientsListFile>,
    { data: FileDTO }
  > = props => {
    const { data } = props || {}

    return uploadPatientsListFile(data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof uploadPatientsListFile>,
    TError,
    { data: FileDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const getPatientPrescriptions = (
  patientId: number,
  params?: GetPatientPrescriptionsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionPagingDTO>(
    { url: `/patients/${patientId}/prescriptions`, method: 'get', params },
    options
  )
}

export const getGetPatientPrescriptionsQueryKey = (
  patientId: number,
  params?: GetPatientPrescriptionsParams
) => [`/patients/${patientId}/prescriptions`, ...(params ? [params] : [])]

export const useGetPatientPrescriptions = <
  TData = AsyncReturnType<typeof getPatientPrescriptions>,
  TError = unknown
>(
  patientId: number,
  params?: GetPatientPrescriptionsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientPrescriptions>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPatientPrescriptionsQueryKey(patientId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPatientPrescriptions>
  > = () => getPatientPrescriptions(patientId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPatientPrescriptions>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!patientId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getPatientQuizzes = (
  patientId: number,
  params?: GetPatientQuizzesParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<QuizPagingDTO>(
    { url: `/patients/${patientId}/quizzes`, method: 'get', params },
    options
  )
}

export const getGetPatientQuizzesQueryKey = (
  patientId: number,
  params?: GetPatientQuizzesParams
) => [`/patients/${patientId}/quizzes`, ...(params ? [params] : [])]

export const useGetPatientQuizzes = <
  TData = AsyncReturnType<typeof getPatientQuizzes>,
  TError = unknown
>(
  patientId: number,
  params?: GetPatientQuizzesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientQuizzes>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPatientQuizzesQueryKey(patientId, params)

  const queryFn: QueryFunction<AsyncReturnType<typeof getPatientQuizzes>> =
    () => getPatientQuizzes(patientId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPatientQuizzes>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!patientId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getPatientNotes = (
  patientId: number,
  params?: GetPatientNotesParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NotePagingDTO>(
    { url: `/patients/${patientId}/notes`, method: 'get', params },
    options
  )
}

export const getGetPatientNotesQueryKey = (
  patientId: number,
  params?: GetPatientNotesParams
) => [`/patients/${patientId}/notes`, ...(params ? [params] : [])]

export const useGetPatientNotes = <
  TData = AsyncReturnType<typeof getPatientNotes>,
  TError = unknown
>(
  patientId: number,
  params?: GetPatientNotesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientNotes>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPatientNotesQueryKey(patientId, params)

  const queryFn: QueryFunction<AsyncReturnType<typeof getPatientNotes>> = () =>
    getPatientNotes(patientId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPatientNotes>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!patientId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const createPatient = (
  prescriberId: number,
  createPatientDTO: CreatePatientDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PatientDTO>(
    {
      url: `/prescribers/${prescriberId}/patients`,
      method: 'post',
      data: createPatientDTO,
    },
    options
  )
}

export const useCreatePatient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createPatient>,
    TError,
    { prescriberId: number; data: CreatePatientDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createPatient>,
    { prescriberId: number; data: CreatePatientDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return createPatient(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createPatient>,
    TError,
    { prescriberId: number; data: CreatePatientDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const getPatients = (
  prescriberId: number,
  params?: GetPatientsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PatientPagingDTO>(
    { url: `/prescribers/${prescriberId}/patients`, method: 'get', params },
    options
  )
}

export const getGetPatientsQueryKey = (
  prescriberId: number,
  params?: GetPatientsParams
) => [`/prescribers/${prescriberId}/patients`, ...(params ? [params] : [])]

export const useGetPatients = <
  TData = AsyncReturnType<typeof getPatients>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetPatientsParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getPatients>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPatientsQueryKey(prescriberId, params)

  const queryFn: QueryFunction<AsyncReturnType<typeof getPatients>> = () =>
    getPatients(prescriberId, params, requestOptions)

  const query = useQuery<AsyncReturnType<typeof getPatients>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!prescriberId, ...queryOptions }
  )

  return {
    queryKey,
    ...query,
  }
}

export const getPatient = (
  prescriberId: number,
  patientId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PatientDTO>(
    {
      url: `/prescribers/${prescriberId}/patients/${patientId}`,
      method: 'get',
    },
    options
  )
}

export const getGetPatientQueryKey = (
  prescriberId: number,
  patientId: number
) => [`/prescribers/${prescriberId}/patients/${patientId}`]

export const useGetPatient = <
  TData = AsyncReturnType<typeof getPatient>,
  TError = unknown
>(
  prescriberId: number,
  patientId: number,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getPatient>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPatientQueryKey(prescriberId, patientId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getPatient>> = () =>
    getPatient(prescriberId, patientId, requestOptions)

  const query = useQuery<AsyncReturnType<typeof getPatient>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(prescriberId && patientId), ...queryOptions }
  )

  return {
    queryKey,
    ...query,
  }
}

export const updatePatient = (
  prescriberId: number,
  patientId: number,
  updatePatientDTO: UpdatePatientDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PatientDTO>(
    {
      url: `/prescribers/${prescriberId}/patients/${patientId}`,
      method: 'put',
      data: updatePatientDTO,
    },
    options
  )
}

export const useUpdatePatient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePatient>,
    TError,
    { prescriberId: number; patientId: number; data: UpdatePatientDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePatient>,
    { prescriberId: number; patientId: number; data: UpdatePatientDTO }
  > = props => {
    const { prescriberId, patientId, data } = props || {}

    return updatePatient(prescriberId, patientId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof updatePatient>,
    TError,
    { prescriberId: number; patientId: number; data: UpdatePatientDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const deletePatient = (
  prescriberId: number,
  patientId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/prescribers/${prescriberId}/patients/${patientId}`,
      method: 'delete',
    },
    options
  )
}

export const useDeletePatient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deletePatient>,
    TError,
    { prescriberId: number; patientId: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deletePatient>,
    { prescriberId: number; patientId: number }
  > = props => {
    const { prescriberId, patientId } = props || {}

    return deletePatient(prescriberId, patientId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof deletePatient>,
    TError,
    { prescriberId: number; patientId: number },
    TContext
  >(mutationFn, mutationOptions)
}
