import { useHistory } from 'react-router'
import {
  Button,
  NotFoundWapper,
  SubTitleNotFound,
  TitleNotFound,
  WrapperContentNotFound,
} from './style'

export const PrescriptionNotFound = () => {
  //Attributes
  const history = useHistory()

  function handleRedirect() {
    history.push('/patient/catalog')
  }

  //Return
  return (
    <NotFoundWapper>
      <WrapperContentNotFound>
        <TitleNotFound>
          Vous n'avez actuellement pas de recommandations
        </TitleNotFound>
        <SubTitleNotFound>
          Veuillez patienter jusqu'à ce que votre thérapeute vous envoie une
          recommandation afin qu'elle soit affichée dans votre liste.
        </SubTitleNotFound>
        <Button onClick={handleRedirect}>Voir notre catalogue</Button>
      </WrapperContentNotFound>
    </NotFoundWapper>
  )
}
