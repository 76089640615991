import { useEffect } from 'react'
import {
  Container,
  Header,
  Body,
  CreatedBy,
  CreatedByContainer,
  Duration,
  DurationContainer,
  Title,
  TitleContainer,
  Description,
  PopoverContainer,
  PopoverRow,
  PopoverText,
  MenuContainer,
  UseButton,
} from './style'
import { useClickOutside } from '../../../utils/hooks'
import { ProtocolCardProps } from './props'
import { PrescriptionRecommendedDuration } from '../../../services/api/types'
import { Dots } from '../../ui/icon/icons'
import { useRef, useState } from 'react'
import IconButton from '../../ui/icon-button'
import { useHistory } from 'react-router'
import { useDeleteProtocol } from '../../../services/api/protocols/protocols'
import { toast } from 'react-toastify'
import { useGetMe } from '../../../utils/hooks/getMe'

import ProtocolDialog from '../protololes-dialog'

const getDescription = (description: string) =>
  !!description
    ? description.length > 100
      ? description.slice(0, 100).trim() + '...'
      : description
    : '–'

const ProtocolCard = ({
  protocol,
  refetchProtocols,
  setIsDialogOpen,
  displayAddButton,
  setIsInnerDialogOpen,
}: ProtocolCardProps) => {
  // Attributes
  const { push, location } = useHistory()
  const { data: prescriber } = useGetMe()
  const { mutateAsync: deleteProtocol } = useDeleteProtocol({
    mutation: {
      onSuccess: () => {
        toast.success('Le protocole a été supprimé avec succès!')
        refetchProtocols()
      },
      onError: () => {
        toast.error(
          `Il y a eu une erreur dans la supression du protocole, merci de réessayer.`
        )
      },
    },
  })

  // Handle popover
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [isProtocolDialogOpen, setIsProtocolDialogOpen] =
    useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside({
    ref: ref,
    onClick: () => setIsPopoverOpen(false),
  })

  // Functions
  const renderDuration = (duration: PrescriptionRecommendedDuration) => {
    let d = '1 mois'

    switch (duration) {
      case PrescriptionRecommendedDuration.ONE_MONTH:
        d = '1 mois'
        break
      case PrescriptionRecommendedDuration.TWO_MONTH:
        d = '2 mois'
        break
      case PrescriptionRecommendedDuration.THREE_MONTH:
        d = '3 mois'
        break
    }

    return `Durée du protocole: ${d}`
  }

  function handleUseSelectButtonRedirection() {
    if (setIsDialogOpen !== undefined) {
      setIsDialogOpen(false)
    }
    if (protocol.protocolType === 'MARKETPLACE') {
      push(`${location.pathname + location.search}&protocolId=${protocol?.id}`)
    } else {
      push(`compliment-prescriptions/new?step=1&protocolId=${protocol?.id}`)
    }
  }

  // Hooks
  useEffect(() => {
    setIsInnerDialogOpen && setIsInnerDialogOpen(!isProtocolDialogOpen)
  }, [isProtocolDialogOpen])

  // Render
  return (
    <Container>
      {/* Header */}
      <Header>
        <TitleContainer>
          <Title>{protocol?.name}</Title>
          {!(protocol?.protocolType === 'MARKETPLACE') && (
            <DurationContainer>
              <Duration>
                {renderDuration(protocol?.recommendedDuration)}
              </Duration>
            </DurationContainer>
          )}
        </TitleContainer>
        <Description>{getDescription(protocol?.description)}</Description>
      </Header>

      {/* Body */}
      <Body>
        <CreatedByContainer>
          <CreatedBy>{`Crée par: ${protocol?.prescriberFirstName} ${protocol?.prescriberLastName}`}</CreatedBy>

          {displayAddButton && (
            <>
              <UseButton onClick={handleUseSelectButtonRedirection}>
                Ajouter
              </UseButton>
              <UseButton
                style={{
                  background: 'transparent',
                  color: '#333',
                }}
                onClick={() => {
                  setIsProtocolDialogOpen(true)
                }}
              >
                Voir
              </UseButton>
            </>
          )}

          <MenuContainer>
            <IconButton
              width='2.125rem'
              height='2.125rem'
              color='#3A845E'
              bgColor='#FFFFFF'
              onHoverBgColor='rgba(216, 230, 223, 0.5)'
              onClick={() => setIsPopoverOpen(true)}
            >
              {color => (
                <Dots
                  stroke={color}
                  fill={color}
                  width='1.125rem'
                  height='1.125rem'
                />
              )}
            </IconButton>

            {/* Menu popover */}
            {isPopoverOpen && (
              <PopoverContainer ref={ref}>
                <PopoverRow
                  onClick={() => {
                    setIsProtocolDialogOpen(true)
                  }}
                >
                  <PopoverText>Voir</PopoverText>
                </PopoverRow>
                <PopoverRow onClick={handleUseSelectButtonRedirection}>
                  <PopoverText>Utiliser</PopoverText>
                </PopoverRow>
                {protocol?.prescriberId === prescriber.id && (
                  <PopoverRow
                    onClick={() =>
                      deleteProtocol({
                        prescriberId: prescriber?.id,
                        protocolId: protocol.id,
                      })
                    }
                  >
                    <PopoverText>Supprimer</PopoverText>
                  </PopoverRow>
                )}
              </PopoverContainer>
            )}
          </MenuContainer>
        </CreatedByContainer>
      </Body>
      <ProtocolDialog
        setIsOpen={setIsProtocolDialogOpen}
        isOpen={isProtocolDialogOpen}
        protocolId={protocol?.id}
        setIsDialogOpen={setIsDialogOpen}
      />
    </Container>
  )
}

export default ProtocolCard
