import styled from 'styled-components/macro'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 20px;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  min-height: 150px;
  background-color: white;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 15px;
`

export const CardHeader = styled.div`
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

export const P = styled.p`
  font-size: 13px;
  padding-bottom: 1em;
  text-align: center;
`
