import { Link } from 'react-router-dom'
import {
  Wrapper,
  FooterText,
  Title,
  ImageLogo,
  Subtitle,
  Image,
  ImageText,
  TextImage,
  TextImagePrescription,
} from './style'
import NakedLayout from '../../../components/layout/naked'
import RegisterCredentialsForm from '../../../components/auth/register-form/credentials'
import { ActionType } from '../../../utils/constants'
import { useState } from 'react'

const RegisterCredentialsPage = ({ location }) => {
  const [registerStatus, setRegisterStatus] = useState(undefined)

  // Function
  function handleRegisterStatusChange(state) {
    setRegisterStatus(state)
  }

  return (
    <NakedLayout
      isFullBackground={registerStatus !== ActionType.SUCCESS ? true : false}
    >
      <Wrapper>
        <div>
          <ImageLogo
            src={'/assets/images/logo-compliment.png'}
            alt='logo-image'
          />
          <Title>Créez vos identifiants</Title>
          <Subtitle>
            Saisissez votre adresse mail et votre mot de passe qui vous
            permettrons d'accéder à votre compte.
          </Subtitle>
          <RegisterCredentialsForm
            onRegisterStatusChange={handleRegisterStatusChange}
            registerStatus={registerStatus}
          />
          <FooterText>
            Déjà un compte ?{' '}
            <Link
              to={{
                pathname: '/login',
                search: location.search,
              }}
            >
              Connectez-vous
            </Link>
          </FooterText>
        </div>
      </Wrapper>
      <ImageText>
        {registerStatus !== ActionType.SUCCESS ? (
          <div>
            <Image
              src={'/assets/images/image-join-community.png'}
              alt='image-join-community'
            />
            <TextImage>
              Rejoignez une communauté avec plus de 10000 professionnels de
              santé
            </TextImage>
          </div>
        ) : (
          <div>
            <Image
              src={'/assets/images/image-easy-prescription.png'}
              alt='image-easy-prescription'
            />
            <TextImagePrescription>
              Facilitez votre recommandation, offrez une expérience patient
              unique.
            </TextImagePrescription>
          </div>
        )}
      </ImageText>
    </NakedLayout>
  )
}

export default RegisterCredentialsPage
