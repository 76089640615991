/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { ConsentDTO, CreateConsentDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getConsentsByPrescriberId = (
  prescriberId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ConsentDTO[]>(
    { url: `/prescribers/${prescriberId}/consents`, method: 'get' },
    options
  )
}

export const getGetConsentsByPrescriberIdQueryKey = (prescriberId: number) => [
  `/prescribers/${prescriberId}/consents`,
]

export const useGetConsentsByPrescriberId = <
  TData = AsyncReturnType<typeof getConsentsByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getConsentsByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetConsentsByPrescriberIdQueryKey(prescriberId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getConsentsByPrescriberId>
  > = () => getConsentsByPrescriberId(prescriberId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getConsentsByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const createConsent = (
  prescriberId: number,
  createConsentDTO: CreateConsentDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ConsentDTO>(
    {
      url: `/prescribers/${prescriberId}/consents`,
      method: 'post',
      data: createConsentDTO,
    },
    options
  )
}

export const useCreateConsent = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createConsent>,
    TError,
    { prescriberId: number; data: CreateConsentDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createConsent>,
    { prescriberId: number; data: CreateConsentDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return createConsent(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createConsent>,
    TError,
    { prescriberId: number; data: CreateConsentDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const deleteConsentByConsentId = (
  prescriberId: number,
  consentId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/prescribers/${prescriberId}/consents/${consentId}`,
      method: 'delete',
    },
    options
  )
}

export const useDeleteConsentByConsentId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteConsentByConsentId>,
    TError,
    { prescriberId: number; consentId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteConsentByConsentId>,
    { prescriberId: number; consentId: string }
  > = props => {
    const { prescriberId, consentId } = props || {}

    return deleteConsentByConsentId(prescriberId, consentId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof deleteConsentByConsentId>,
    TError,
    { prescriberId: number; consentId: string },
    TContext
  >(mutationFn, mutationOptions)
}
