import Axios, { AxiosRequestConfig } from 'axios'

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_NEXTGEN_API_URL,
})

export interface InstanceOptions {
  testId: string
}

const getImprovedConfig = async () => {
  const accessToken = localStorage.getItem('access_token')
  return {
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
  }
}

export const customInstance = async <T>(
  config: AxiosRequestConfig,
  options?: InstanceOptions
): Promise<T> => {
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({
    ...config,
    ...(await getImprovedConfig()),
    cancelToken: source.token,
  }).then(response => response?.data)

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query')
  }

  return promise
}
