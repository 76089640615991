import styled from 'styled-components'

export const Wrapper = styled.a`
  text-decoration: none;
  color: inherit;
  margin-bottom: 5px;
`
export const StarsWrapper = styled.div`
  display: flex;
`
export const StarWrapper = styled.div`
  margin-right: 3px;
  color: #f79e27;
`
export const Title = styled.h4`
  font-size: 15px;
`
export const Text = styled.div`
  font-size: 12px;
`
export const ImgLogo = styled.img`
  width: 90px;
  object-fit: contain;
`
