import styled from 'styled-components/macro'

export const Container = styled.div`
  a {
    font-size: 12px;
    border-radius: 5px;
    line-height: 20px;
    padding: 6px 16px 6px 23px;
    text-decoration: none;
    color: inherit;
    outline: none;
    display: flex;
    align-items: center;
  }
`
export const Text = styled.p`
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 1px;
  padding-bottom: 1px;
`

export const SubMenu = styled.div`
  margin-top: 10px;
  padding: 15px 20px 10px 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  a {
    text-decoration: none;
    color: inherit;
    outline: none;
    font-size: 14px;
    opacity: 0.8;
  }
`

export const SubMenuItem = styled.div`
  margin-bottom: 10px;
`

export const PlusIcon = styled.span`
  cursor: pointer;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #00875c;
  color: white !important;
`
