import styled from 'styled-components/macro'

export const Modal = styled.div`
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: auto;
  background-color: white;
  border-radius: 5px;
  text-align: center;
`

export const Description = styled.p`
  margin-bottom: 20px;
`

export const Button = styled.button`
  padding: 12px;
  width: auto;
  background: #e74c3c;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  border: none;
`
