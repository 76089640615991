import styled from 'styled-components/macro'
import { MediaQuery, Color } from '../../../utils/constants'

export const Container = styled.div`
  position: sticky;
  top: 1.25rem;
  width: 100%;
  min-width: 21.875rem;
  padding: 0rem 2.5rem;
  margin: 3.125rem 0rem;
  /* box-shadow: 0rem .625rem 2.5rem -1.0625rem rgba(0, 0, 0, 0.5); */
  /* border-radius: 1.5625rem; */
  height: ${props => (props.height ? props.height : 'none')};

  ${MediaQuery.M_AND_DOWN} {
    padding: 0rem 1.25rem 1.25rem 1.25rem;
    border-radius: 0rem;
    /* display: ${props =>
      props.displayMAndDown ? props.displayMAndDown : 'block'};
   */
  }
`

export const Choice = styled.div`
  margin-bottom: 1.25rem;
  h3 {
    font-size: 0.875rem;
    font-weight: 300;
    margin-bottom: 0.75rem;
  }
`
export const ChoiceHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Customized = styled.div`
  background-color: white;
  box-shadow: 0rem 0rem 0.0625rem rgba(40, 41, 61, 0.08),
    0rem 0.0313rem 0.125rem rgba(96, 97, 112, 0.16);
  padding: 0.9375rem;
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.column ? 'column' : 'none')};

  & > *:not(:last-child) {
    margin-bottom: ${props => (props.column ? '.9375rem' : 'none')};
  }
`

export const Selector = styled.button`
  position: relative;
  cursor: pointer;
  height: 2.1875rem;
  padding: 0.5rem;
  border: ${props =>
    props.active ? '.0625rem solid #00865C88' : '.0625rem solid transparent'};
  opacity: ${props => (props.active ? '1' : '.7')};
  background-color: ${props => (props.active ? '#00865C33' : '')};
  border-radius: 0.5rem;
  width: ${props => (props.fullWidth ? '100%' : '49%')};
  width: ${props => (props.column ? '100%' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 0.0625rem solid #00865c88;
    background-color: #00865c33;
  }

  p {
    margin-top: 0.125rem;
    font-size: 0.75rem;
    position: absolute;
    top: 100%;
    left: 0;
  }
`

export const LoadingIcon = styled.span`
  font-size: 0.625rem;
  display: block;
  margin-top: 0rem;
  margin-left: 0.625rem;
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const SubscriptionContent = styled.div`
  div {
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem;
    p {
      font-size: 0.875rem;
    }
    span {
      color: #64b595;
      margin-right: 0.625rem;
    }
  }
`

export const SelectorWrapper = styled.div`
  display: flex;
`

export const SelectorItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 1.25rem;
  div {
    margin-right: 0.375rem;
    width: 0.75rem;
    height: 0.75rem;
    border: 0.0625rem solid ${Color.DEEP_SEA};
    border-radius: 100%;
    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.3125rem;
      height: 0.3125rem;
      background-color: ${Color.DEEP_SEA};
      border-radius: 100%;
    }
  }
  p {
    font-size: 0.8125rem;
    opacity: 0.8;
  }
`

export const SubscriptionInfo = styled.div`
  font-size: 0.875rem;
  opacity: 0.8;
  margin-top: 1.25rem;
  list-style: none;
  p {
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    span {
      background-color: #e0f0e9;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.4375rem;
      min-width: 1.4375rem;
      margin-right: 0.625rem;
      border-radius: 0.25rem;
      img {
        width: 45%;
      }
    }
  }
`

export const InfoCircle = styled.span`
  font-size: 1rem;
`
