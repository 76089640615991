import styled from 'styled-components/macro'

export const Container = styled.div`
  margin-bottom: -30px;
  ul,
  li {
    list-style: none;
  }
`

export const List = styled.ul`
  list-style-type: none;
  list-style: none;
  padding: 0;
`

export const ListItem = styled.li`
  margin-bottom: 0.5rem;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const SelectedFavorites = styled.div`
  margin-top: 2rem;
`

export const CheckboxList = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 150px);
  /* max-height: 100px; */
  /* overflow: scroll; */
`

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 5px;
  p {
    word-wrap: break-word;
    overflow: hidden;
  }
`

export const Checkbox = styled.input`
  margin-right: 10px;

  &[type='checkbox'] {
    appearance: none;
    background-color: #fff;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 1px solid #3a845e;
    border-radius: 3px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  &[type='checkbox']::before {
    content: '';
    width: 16px;
    height: 16px;
    transform: scale(0);
    border-radius: 3px;
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: #3a845e;
  }
  &[type='checkbox']:checked::before {
    transform: scale(1);
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const DownloadIcon = styled.span`
  margin-top: 5px;
  margin-right: 5px;
  font-size: 15px;
`
export const InlineButtonWrapper = styled.div`
  margin-bottom: 30px;
  margin-top: -10px;
  display: flex;
`

export const InlineButton = styled.span`
  cursor: pointer;
  margin-top: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #9c99af;
  line-height: 12px;
  display: flex;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 10px;
`
