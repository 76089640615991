import { useState } from 'react'
import { Collapse } from 'react-collapse'
import {
  Wrapper,
  Header,
  Steps,
  StepWrapper,
  Step,
  StepIcon,
  StepText,
  StepLeft,
  CollapseContent,
} from './style'
import { CgRadioCheck } from 'react-icons/cg'
import { IoIosArrowDown } from 'react-icons/io'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Button, Spinner } from '../../ui'
import { useHistory } from 'react-router'
import { ITutorialStepsProps } from './props'

const StepItem = ({ borderBottom = false, title, content, done }) => {
  const [isCollapsed, setCollapsed] = useState(false)

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed)
  }

  return (
    <StepWrapper borderBottom={borderBottom}>
      <Step onClick={toggleCollapse}>
        <StepLeft>
          {done ? (
            <StepIcon
              style={{
                fontSize: '17px',
                color: '#00865C',
              }}
            >
              <IoCheckmarkCircle />
            </StepIcon>
          ) : (
            <StepIcon>
              <CgRadioCheck />
            </StepIcon>
          )}
          <StepText
            style={{
              textDecoration: done ? 'line-through' : '',
            }}
          >
            {title}
          </StepText>
        </StepLeft>
        {!done && (
          <StepIcon>
            <IoIosArrowDown />
          </StepIcon>
        )}
      </Step>
      {!done && (
        <Collapse isOpened={isCollapsed}>
          <CollapseContent>{content}</CollapseContent>
        </Collapse>
      )}
    </StepWrapper>
  )
}

const FistSteps = ({
  hasAddress,
  hasFavouriteProduct,
  hasPatients,
  hasPrescriptions,
  isPrescriptionLoading,
  isAllFavoriteListsLoading,
}: ITutorialStepsProps) => {
  //Attributes
  const history = useHistory()

  //Render
  return hasPrescriptions &&
    hasPatients &&
    hasFavouriteProduct &&
    hasAddress ? (
    <></>
  ) : (
    <Wrapper>
      <Header>
        <h3>Familiarisez-vous avec Simplycure !</h3>
        <a
          href='https://www.youtube.com/watch?v=UlyzL6i9Hh0&ab_channel=Simplycure'
          target='_blank'
          rel='noreferrer'
        >
          <span>
            <AiOutlineInfoCircle />
          </span>
          Voir la vidéo de présentation
        </a>
      </Header>

      {isPrescriptionLoading || isAllFavoriteListsLoading ? (
        <Spinner fullScreen={false} />
      ) : (
        <Steps>
          <StepItem
            title='Ajouter un client'
            borderBottom
            done={hasPatients}
            content={
              <>
                <p>
                  L'ajout de nouveaux clients permet une personnalisation
                  précise des recommandations.
                </p>
                <Button
                  onClick={() => {
                    history.push('/patients/new')
                  }}
                  appearance='primary'
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                >
                  Nouveau client
                </Button>
              </>
            }
          />
          <StepItem
            done={hasPrescriptions}
            content={
              <>
                <p>
                  Utilisez les recommandations pour envoyer une sélection de
                  produits à votre client.
                </p>
                <Button
                  onClick={() => {
                    history.push('/prescriptions/new?step=2')
                  }}
                  appearance='primary'
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                >
                  Nouvelle recommandation
                </Button>
              </>
            }
            title='Créer votre première recommandation'
            borderBottom
          />
          <StepItem
            done={hasFavouriteProduct}
            borderBottom
            content={
              <>
                <p>
                  Utilisez les favoris pour retrouver plus facilement vos
                  produits préférés. Vous gagnerez du temps par la suite !
                </p>
                <Button
                  onClick={() => {
                    history.push('/favorites')
                  }}
                  appearance='primary'
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                >
                  Ajouter des produits favoris
                </Button>
              </>
            }
            title='Ajouter des produits favoris'
          />
          <StepItem
            done={hasAddress}
            content={
              <>
                <p>
                  Ajouter votre adresse depuis les settings de l'application
                </p>
                <Button
                  onClick={() => {
                    history.push('/settings/address')
                  }}
                  appearance='primary'
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                >
                  Ajouter votre adresse
                </Button>
              </>
            }
            title='Ajouter votre adresse'
          />
        </Steps>
      )}
    </Wrapper>
  )
}

export default FistSteps
