import { useHistory } from 'react-router'
import {
  Container,
  Header,
  Body,
  New,
  CategoryTitle,
  NewPlus,
  NewText,
} from './style'
import CreateCategory from './create-category'

import CategoryItem from './category-item'
import { useQuery } from '../../../../utils/hooks/getQuery'

interface FavoriteList {
  id: string
}

const Categories = ({
  userFavoriteLists,
  setIsCreateCategoryDialogOpen,
  isCreateCategoryDialogOpen,
}) => {
  // Attributes
  const history = useHistory()
  const query = useQuery()
  const currentFavoriteListId = query.get('favoriteListId')

  // Functions
  const handleCreateCategoryClick = () => {
    setIsCreateCategoryDialogOpen(true)
  }
  const closeCreateCategoryDialog = () => {
    setIsCreateCategoryDialogOpen(false)
  }
  const navigateToFavorites = () => {
    history.push('/favorites')
  }

  // Render
  return (
    <Container>
      <Header>Catégories</Header>
      <Body>
        <New onClick={handleCreateCategoryClick}>
          <NewPlus>+</NewPlus>
          <NewText>Créer une nouvelle catégorie</NewText>
        </New>
        <CategoryTitle
          currentFavoriteListId={!currentFavoriteListId}
          onClick={navigateToFavorites}
        >
          <p>Tous les favoris</p>
        </CategoryTitle>
        {userFavoriteLists
          ?.sort(function (a, b) {
            if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
              return -1
            }
            if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
              return 1
            }
            return 0
          })
          ?.map((favoriteList: FavoriteList) => (
            <CategoryItem
              key={favoriteList.id}
              currentFavoriteListId={currentFavoriteListId}
              category={favoriteList}
            />
          ))}
      </Body>

      <CreateCategory
        isOpen={isCreateCategoryDialogOpen}
        onClose={closeCreateCategoryDialog}
      />
    </Container>
  )
}

export default Categories
