import {
  PatientItem,
  Initial,
  FullName,
  Email,
  IconDelete,
  InfoPatient,
  PatientItemLeft,
  AddPhoneNumber,
} from '../style'
import { GrClose } from 'react-icons/gr'
import { SelectedCustomerProps } from './props'

const SelectedCustomer = ({
  selectedPatient,
  setIsDialogOpen,
  prescription,
  handlePatientRemove,
}: SelectedCustomerProps) => {
  return (
    <div style={{ marginTop: '20px' }}>
      {selectedPatient && (
        <PatientItem key={selectedPatient.id} style={{ marginBottom: '10px' }}>
          <PatientItemLeft
            onClick={() => {
              setIsDialogOpen(true)
            }}
          >
            <Initial>
              {selectedPatient?.firstName && selectedPatient?.lastName
                ? `${selectedPatient?.firstName?.[0]}${selectedPatient?.lastName?.[0]}`
                : selectedPatient?.email?.[0]}
            </Initial>
            <InfoPatient>
              <FullName>
                {selectedPatient.firstName} {selectedPatient.lastName}
              </FullName>

              <Email>{selectedPatient.email}</Email>
              {selectedPatient.phone ? (
                <Email>{selectedPatient.phone}</Email>
              ) : (
                <AddPhoneNumber
                  onClick={() => {
                    setIsDialogOpen(true)
                  }}
                >
                  Ajouter nr téléphone
                </AddPhoneNumber>
              )}
            </InfoPatient>
          </PatientItemLeft>
          {!prescription?.patient?.id && (
            <IconDelete onClick={handlePatientRemove}>
              <GrClose />
            </IconDelete>
          )}
        </PatientItem>
      )}
    </div>
  )
}

export default SelectedCustomer
