import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../services/auth/provider'
import { useQueryClient } from 'react-query'
import { Logo, Button } from '../../ui'
import Cart from '../patient-public/cart'
import MobileMenu from '../../mobile-menu'
import BurgerMenu from '../../ui/menu-button'
import {
  GlobalContainer,
  Main,
  Header,
  NavItem,
  Wrapper,
  RightWrapper,
  NavWrapper,
  CartWrapper,
  MobileNavWrapper,
} from './style'
import { ILayoutProps } from './props'

const DefaultLayout = ({ children, nav = [], showCart }: ILayoutProps) => {
  //Attributes
  const history = useHistory()
  const client = useQueryClient()
  const { logout } = useAuth()
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  function handleLogout() {
    logout().then(() => {
      client.invalidateQueries()
      client.removeQueries()
      history.push('/portail')
    })
  }
  //Return
  return (
    <GlobalContainer>
      <Header>
        <Wrapper>
          <Link to='/'>
            <Logo />
          </Link>
        </Wrapper>

        <NavWrapper>
          {nav.map(i => (
            <Link to={i.url} key={i.url}>
              <NavItem
                style={{
                  borderBottom:
                    history.location.pathname === i.url
                      ? '2px solid #01875c'
                      : '',
                }}
              >
                {i.text}
              </NavItem>
            </Link>
          ))}
        </NavWrapper>

        <RightWrapper>
          {showCart && (
            <CartWrapper>
              <Cart url='/patient/cart' />
            </CartWrapper>
          )}

          <Button
            appearance={undefined}
            iconLeft={undefined}
            isActive={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            onClick={handleLogout}
          >
            Se déconnecter
          </Button>
        </RightWrapper>

        <MobileNavWrapper>
          {showCart && (
            <CartWrapper>
              <Cart url='/patient/cart' />
            </CartWrapper>
          )}

          <MobileMenu
            isOpen={isMobileNavOpen}
            onClose={() => setIsMobileNavOpen(!isMobileNavOpen)}
            onLogout={handleLogout}
          />
          <BurgerMenu onClick={() => setIsMobileNavOpen(!isMobileNavOpen)} />
        </MobileNavWrapper>
      </Header>
      <Main>{children}</Main>
    </GlobalContainer>
  )
}

export default DefaultLayout
