import { gql } from '@apollo/client'

export const PRESCRIPTION = gql`
  query getPrescription($id: Int!) {
    prescription(where: { id: { _eq: $id } }) {
      created_at
      custom_message
      id
      patient_id
      recommended_duration
      purchase_option
      discount {
        code
        percentage
      }
      patient {
        firstname
        name
        email
        id
      }
      prescription_statuses {
        id
        created
        prescription_id
        status
        prescription_takes {
          id
          index
          label
          type
          prescription_items {
            notes
            product_handle
            quantity
            shopify_product {
              shopify_product {
                title
              }
            }
          }
        }
      }
      prescriber {
        firstname
        name
        email
        id
      }
    }
  }
`

export const GET_PATIENT_PRESCRIPTIONS = gql`
  query getPrescriptions($id: Int!) {
    prescription(where: { patient_id: { _eq: $id } }) {
      id
      patient_id
      prescription_takes {
        id
        prescription_items {
          id
        }
      }
      prescription_statuses {
        id
      }
    }
  }
`

export const PRESCRIBER_PRESCRIPTIONS = gql`
  query getPrescriberPrescriptions($prescriberId: Int!) {
    prescription(where: { prescriber_id: { _eq: $prescriberId } }) {
      created_at
      custom_message
      id
      patient_id
      recommended_duration
      purchase_option
      total_amount
      subtotal_amount
      discount {
        code
        percentage
      }
      patient {
        firstname
        name
      }
      prescription_statuses {
        id
        created
        status
        prescription_takes {
          id
          index
          label
          type
          prescription_items {
            notes
            product_handle
            quantity
            shopify_product {
              shopify_product {
                title
              }
            }
          }
        }
      }
    }
  }
`

export const ADD_PRESCRIPTION = gql`
  mutation AddPrescription(
    $purchaseOption: String
    $customMessage: String
    $discountCode: String
    $patientId: Int!
    $prescriberId: Int!
    $prescriptionTakes: prescription_take_arr_rel_insert_input!
    $recommendedDuration: String!
    $totalAmount: float8!
    $subtotalAmount: float8!
    $parentId: Int
  ) {
    insert_prescription(
      objects: {
        custom_message: $customMessage
        discount_code: $discountCode
        patient_id: $patientId
        prescriber_id: $prescriberId
        recommended_duration: $recommendedDuration
        purchase_option: $purchaseOption
        prescription_statuses: {
          data: [{ status: "CREATED", prescription_takes: $prescriptionTakes }]
        }
        total_amount: $totalAmount
        subtotal_amount: $subtotalAmount
        parent_id: $parentId
      }
    ) {
      returning {
        created_at
        custom_message
        id
        patient_id
        recommended_duration
        total_amount
        subtotal_amount
        discount {
          code
          percentage
        }
        patient {
          firstname
        }
        prescription_statuses {
          id
          created
          status
          prescription_takes {
            id
            index
            label
            type
            prescription_items {
              notes
              product_handle
              quantity
              shopify_product {
                shopify_product {
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_PRESCRIPTION = gql`
  mutation UpdatePrescription(
    $customMessage: String
    $discountCode: String
    $recommendedDuration: String!
    $prescriptionId: Int!
    $purchaseOption: String
    $totalAmount: float8!
    $subtotalAmount: float8!
  ) {
    update_prescription_by_pk(
      pk_columns: { id: $prescriptionId }
      _set: {
        discount_code: $discountCode
        custom_message: $customMessage
        recommended_duration: $recommendedDuration
        purchase_option: $purchaseOption
        total_amount: $totalAmount
        subtotal_amount: $subtotalAmount
      }
    ) {
      created_at
      custom_message
      id
      patient_id
      recommended_duration
      total_amount
      subtotal_amount
      discount {
        code
        percentage
      }
      patient {
        firstname
      }
      prescription_statuses {
        created
        status
      }
    }
  }
`

export const ADD_PRESCRIPTION_TAKE = gql`
  mutation AddPrescriptionTake(
    $prescriptionTakes: [prescription_take_insert_input!]!
  ) {
    insert_prescription_take(objects: $prescriptionTakes) {
      returning {
        id
        index
        label
        type
        prescription_status {
          prescription_id
        }
        prescription_items {
          notes
          product_handle
          quantity
          shopify_product {
            shopify_product {
              title
            }
          }
        }
      }
    }
  }
`

export const PUSH_PRESCRIPTION_STATUS = gql`
  mutation AddPrescriptionStatus(
    $status: String!
    $prescriptionId: Int!
    $prescriptionTakes: prescription_take_arr_rel_insert_input
  ) {
    insert_prescription_status(
      objects: {
        status: $status
        prescription_id: $prescriptionId
        prescription_takes: $prescriptionTakes
      }
    ) {
      returning {
        created
        id
        prescription_id
        status
        prescription_takes {
          id
          index
          label
          type
          prescription_items {
            notes
            product_handle
            quantity
            shopify_product {
              shopify_product {
                title
              }
            }
          }
        }
      }
    }
  }
`

export const DELETE_PRESCRIPTION = gql`
  mutation DeletePrescription($id: Int!) {
    delete_prescription_item(
      where: {
        prescription_take: {
          prescription_status: { prescription_id: { _eq: $id } }
        }
      }
    ) {
      affected_rows
    }
    delete_prescription_take(
      where: { prescription_status: { prescription_id: { _eq: $id } } }
    ) {
      affected_rows
    }
    delete_prescription_status(where: { prescription_id: { _eq: $id } }) {
      affected_rows
    }
    delete_prescription_by_pk(id: $id) {
      id
    }
  }
`

export const DELETE_PRESCRIPTION_TAKE = gql`
  mutation DeletePrescriptionTake($prescriptionId: Int!) {
    delete_prescription_item(
      where: {
        prescription_take: {
          prescription_status: { prescription_id: { _eq: $prescriptionId } }
        }
      }
    ) {
      returning {
        id
        prescription_take_id
      }
    }
    delete_prescription_take(
      where: {
        prescription_status: { prescription_id: { _eq: $prescriptionId } }
      }
    ) {
      returning {
        id
        prescription_status {
          prescription_id
        }
      }
    }
  }
`
