import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 54px 0;
  background-color: #fdfcfe;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 53px;
  padding: 0 78px;
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #080038;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const DashboardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 78px;
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  padding: 0 78px;
`
