import { useHistory } from 'react-router'
import { Button } from '../../../../ui'
import {
  Wrapper,
  Container,
  Main,
  Title,
  SubTitle,
  Image,
  ButtonWrapper,
} from './style'

function EmailConfirmationRegisterDialog() {
  //Attributes
  const history = useHistory()

  const handleRedirect = () => {
    history.push(`/patient/login`)
  }

  //Render
  return (
    <Wrapper>
      <Container>
        <Main>
          <Image src={'/assets/images/email.png'} alt={'email'} />
          <Title>Félicitations !</Title>
          <SubTitle>
            Votre compte est prêt. Un e-mail a été envoyé <br /> pour confirmer
            votre inscription. Veuillez <br /> vérifier votre boîte de
            réception. <br />
            <br /> Merci !
          </SubTitle>
        </Main>
        <ButtonWrapper>
          <Button onClick={handleRedirect}>Se connecter</Button>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  )
}

export default EmailConfirmationRegisterDialog
