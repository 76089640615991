import RegisterPatientPageMobile from './register-patient-mobile'
import RegisterPatientWebApp from './register-patient-web-app'

const RegisterPatientPage = () => {
  //Attributes
  const isMobile = window.innerWidth <= 768

  //Return
  return (
    <> {isMobile ? <RegisterPatientPageMobile /> : <RegisterPatientWebApp />}</>
  )
}

export default RegisterPatientPage
