import { Option } from '../../../utils/constants'
import { IPropsSortOptions } from './props'
import { InputDropdown, InputSortBy } from './style'

export const SortOptions = ({ options, onSelect }: IPropsSortOptions) => {
  //Return
  return (
    <InputSortBy>
      {options.map((option: Option) => (
        <InputDropdown onClick={() => onSelect(option.value, option.label)}>
          {option.label}
        </InputDropdown>
      ))}
    </InputSortBy>
  )
}
