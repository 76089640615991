import { ProductDialogMainProps } from './props'
import { Container } from './style'
import Formulation from './formulation'
import Benefits from './benefits'
import Posology from './posology'
import Sourcing from './sourcing'
import Studies from './studies'
import MoreInformation from './more-information'
// import ProtocolsList from './protocols'

const ProductDialogMain = ({
  product,
  numberOfCapsules,
  setNumberOfCapsule,
  isPatientCatalog,
}: ProductDialogMainProps) => {
  // Render
  return (
    <Container>
      <Benefits
        product={product}
        isPatientCatalog={isPatientCatalog}
      ></Benefits>

      <Formulation
        product={product}
        numberOfCapsules={numberOfCapsules}
        setNumberOfCapsule={setNumberOfCapsule}
        isPatientCatalog={isPatientCatalog}
      ></Formulation>

      {!isPatientCatalog && <Posology product={product} />}
      <Sourcing product={product} />
      {!isPatientCatalog && <Studies product={product} />}
      {!isPatientCatalog && <MoreInformation product={product} />}
      {/* <ProtocolsList productId={product?.id} /> */}
    </Container>
  )
}

export default ProductDialogMain
