export const professionOptions = [
  { value: 'Autre', label: 'Autre' },
  { value: 'Chiropracteur', label: 'Chiropracteur' },
  { value: 'Coach nutrition', label: 'Coach nutrition' },
  { value: 'Coach santé', label: 'Coach santé' },
  { value: 'Coach sportif', label: 'Coach sportif' },
  { value: 'Conseiller en nutrition', label: 'Conseiller en nutrition' },
  { value: 'Dentiste', label: 'Dentiste' },
  { value: 'Diététicien', label: 'Diététicien' },
  {
    value: 'Diététicien - Nutritionniste',
    label: 'Diététicien - Nutritionniste',
  },
  { value: 'Diététicien Hospitalier', label: 'Diététicien Hospitalier' },
  { value: 'Endobiogénie', label: 'Endobiogénie' },
  { value: 'Energéticien', label: 'Energéticien' },
  { value: 'Esthéticienne', label: 'Esthéticienne' },
  { value: 'Etudiant', label: 'Etudiant' },
  { value: 'Gastro-entérologue', label: 'Gastro-entérologue' },
  { value: 'Gynécologue', label: 'Gynécologue' },
  { value: 'Herbaliste', label: 'Herbaliste' },
  { value: 'Homéopathe', label: 'Homéopathe' },
  { value: 'Infirmière', label: 'Infirmière' },
  { value: 'Kinésiologue', label: 'Kinésiologue' },
  { value: 'Kinésithérapeute', label: 'Kinésithérapeute' },
  { value: 'Masseur', label: 'Masseur' },
  { value: 'Médecin', label: 'Médecin' },
  { value: 'Médecin Anti-age', label: 'Médecin Anti-age' },
  { value: 'Médecin fonctionnel', label: 'Médecin fonctionnel' },
  { value: 'Médecin généraliste', label: 'Médecin généraliste' },
  { value: 'Médecin nutritionniste', label: 'Médecin nutritionniste' },
  { value: 'Médecin psychologue', label: 'Médecin psychologue' },
  {
    value: 'Médecine traditionelle chinoise',
    label: 'Médecine traditionelle chinoise',
  },
  { value: 'Micro-nutritionniste', label: 'Micro-nutritionniste' },
  { value: 'Naturopathe', label: 'Naturopathe' },
  { value: 'Nutrithérapeute', label: 'Nutrithérapeute' },
  { value: 'Nutritionniste', label: 'Nutritionniste' },
  { value: 'Oncologue', label: 'Oncologue' },
  { value: 'Ostéopathe', label: 'Ostéopathe' },
  { value: 'Pharmacien', label: 'Pharmacien' },
  { value: 'Psychologue', label: 'Psychologue' },
  { value: 'Psychopraticienne', label: 'Psychopraticienne' },
  { value: 'Réflexologue', label: 'Réflexologue' },
  { value: 'Sage Femme', label: 'Sage Femme' },
  { value: 'Sophrologue', label: 'Sophrologue' },
  { value: 'Thérapeute', label: 'Thérapeute' },
]

export const monthsOptions = [
  {
    value: 'Je ne recommande pas de compléments alimentaires',
    label: 'Je ne recommande pas de compléments alimentaires',
  },
  {
    value: 'Je n’en recommande pas encore, je souhaiterais me former',
    label: 'Je n’en recommande pas encore, je souhaiterais me former',
  },
  { value: '1-5', label: '1-5' },
  { value: '5-10', label: '5-10' },
  { value: '10-20', label: '10-20' },
  { value: '20+', label: '20+' },
]
