import { useState } from 'react'
import {
  Container,
  Content,
  TextContent,
  Button,
  TextContentTitle,
} from './style'
import { PrescriptionType } from '../../../../services/api/types'
import CreateCategory from '../categories/create-category'
import ProductSlider2 from '../../../reco/product-slider-v2'
import { PageType } from '../../../../utils/constants'
import IAddProductProps from './props'

const AddProduct = ({
  currentFavoriteListName,
  favoriteLists,
  favoriteListId,
  refetchCurrentFavoriteList,
}: IAddProductProps) => {
  // Attributes
  const [isSliderProductDialogOpen, setIsSliderProductDialogOpen] =
    useState(false)
  const [isCreateCategoryDialogOpen, setIsCreateCategoryDialogOpen] =
    useState(false)

  // Functions
  function closeCreateCategoryDialog() {
    setIsCreateCategoryDialogOpen(false)
  }
  function openDialog() {
    if (favoriteLists?.length === 0 && !favoriteListId) {
      setIsCreateCategoryDialogOpen(true)
    } else {
      setIsSliderProductDialogOpen(true)
    }
  }

  // Render
  return (
    <Container>
      <Content>
        <TextContent>
          <TextContentTitle>Les favoris</TextContentTitle>
        </TextContent>
        {currentFavoriteListName ? (
          <Button onClick={openDialog}>
            Ajouter un produit à {currentFavoriteListName}
          </Button>
        ) : (
          <Button onClick={openDialog}>Ajouter un produit</Button>
        )}
      </Content>
      {isSliderProductDialogOpen && (
        <ProductSlider2
          addProductToTake={undefined}
          currentFavoriteListName={currentFavoriteListName}
          favoriteId={favoriteListId}
          isDialogOpen={isSliderProductDialogOpen}
          prescriptionType={PrescriptionType.MARKETPLACE}
          setIsDialogOpen={setIsSliderProductDialogOpen}
          type={PageType.FAVORITE}
          favoriteLists={favoriteLists}
          refetchCurrentFavoriteList={refetchCurrentFavoriteList}
        />
      )}
      <CreateCategory
        isOpen={isCreateCategoryDialogOpen}
        onClose={closeCreateCategoryDialog}
      />
    </Container>
  )
}

export default AddProduct
