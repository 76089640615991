import styled from 'styled-components/macro'

export const ElementGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
`

export const Value = styled.div`
  font-size: 18px;
  font-weight: bold;
  border-radius: 3px;
  min-height: 40px;
  position: relative;
`

export const Description = styled.p`
  margin-top: 10px;
  font-size: 14px;
  max-width: 400px;
  opacity: 0.6;
`

export const ControlContainer = styled.div`
  position: absolute;
  top: -5px;
  right: 100%;
  padding: 10px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  > div {
    cursor: pointer;
  }
`

export const EditForm = styled.div`
  display: flex;
  width: 100%;
`
