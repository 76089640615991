import axios from 'axios'
import { useEffect, useState } from 'react'
import { useAuth } from './provider'
import { AXIOS_INSTANCE } from '../api/config'
import { useHistory } from 'react-router'
import * as Sentry from '@sentry/react'

export const AuthInterceptor = ({ children }) => {
  // Attributes
  const auth = useAuth()
  const history = useHistory()
  const [responseInterceptorId, setResponseInterceptorId] = useState<
    number | undefined
  >()
  const currentRouter = history.location.pathname
  const includeRecommendationPublicRoute = currentRouter.includes('/r/')

  // Functions
  // Handle responses from evey request
  function handleResponseInterceptor() {
    if (responseInterceptorId !== undefined) {
      return
    }

    const respInterceptorId = AXIOS_INSTANCE.interceptors.response.use(
      // Don't do anything if the status is 2xx
      response => response,
      // Handle response with statuses that don't match 2xx
      async error => {
        if (!error.response || error.response.status !== 401) {
          throw error
        }

        // Refresh token when call has a 401 unauthorized status
        // Then re-send the call
        const originalConfig = error.config
        if (error.response.status === 401 && !originalConfig._retry) {
          // Set _retry to true to avoid infinite loop
          originalConfig._retry = true

          try {
            // Refresh id token
            await auth.refreshToken()
          } catch (error) {
            Sentry.captureException(error)
            await auth.logout()
            if (!includeRecommendationPublicRoute) {
              history.replace('/portail')
            }
            return
          }

          // Set authorization header with new token
          const accessToken = localStorage.getItem('access_token')
          if (accessToken) {
            axios.defaults.headers.authorization = `bearer ${accessToken}`
          }

          // Return the request
          return axios(originalConfig)
        }
      }
    )

    setResponseInterceptorId(respInterceptorId)
  }

  useEffect(() => {
    // Handle responses from evey request
    handleResponseInterceptor()

    // Clean interceptor handlers
    return () => {
      if (responseInterceptorId) {
        AXIOS_INSTANCE.interceptors.response.eject(responseInterceptorId)
      }
    }
  }, [])

  return children
}
