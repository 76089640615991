/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type AverageMonthlyRange = 'LESS_10' | '10_TO_20' | 'MORE_20'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AverageMonthlyRange = {
  LESS_10: 'LESS_10' as AverageMonthlyRange,
  '10_TO_20': '10_TO_20' as AverageMonthlyRange,
  MORE_20: 'MORE_20' as AverageMonthlyRange,
}
