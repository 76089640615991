import { PrescriptionStatus } from '../../../services/api/types'
import { PrescriptionStatusCellProps } from './props'
import { Success, Info, Warning, Done, CheckIcon, Text } from './style'
import { BsCheck2All } from 'react-icons/bs'
import { BsHourglassSplit } from 'react-icons/bs'

const PrescriptionStatusCell = ({
  status = PrescriptionStatus.DONE,
  isPrescriptionConsulted = false,
  isDateOlderThanOneWeek,
  isSendDateOlderThanOneWeek,
  isVisited,
}: PrescriptionStatusCellProps) => {
  const getComponent = (status: PrescriptionStatus) =>
    ({
      [PrescriptionStatus.CREATED]: Info,
      [PrescriptionStatus.SENT]: isVisited ? Success : Warning,
      [PrescriptionStatus.ORDERED]: Success,
      [PrescriptionStatus.DONE]: Done,
      [PrescriptionStatus.MODIFIED]: Warning,
      [PrescriptionStatus.TO_RELAUNCH]: Done,
      [PrescriptionStatus.ARCHIVED]: Done,
      [PrescriptionStatus.RELAUNCHED]: Success,
    }[status])

  const getDisplayText = (status: PrescriptionStatus) =>
    ({
      [PrescriptionStatus.CREATED]: 'Créée',
      [PrescriptionStatus.SENT]: 'Envoyée',
      [PrescriptionStatus.MODIFIED]: 'Modifiée',
      [PrescriptionStatus.ORDERED]: 'Achetée',
      [PrescriptionStatus.DONE]: 'Finie',
      [PrescriptionStatus.TO_RELAUNCH]: 'À renouveler',
      [PrescriptionStatus.ARCHIVED]: 'archivé',
      [PrescriptionStatus.RELAUNCHED]: 'Renouvelé',
    }[status])

  // Render
  const Component = getComponent(status) ?? Done

  return (
    <Component isPrescriptionConsulted={isPrescriptionConsulted}>
      <Text>{getDisplayText(status) ?? ''}</Text>
      {isPrescriptionConsulted && !isDateOlderThanOneWeek && (
        <CheckIcon>
          <BsCheck2All />
        </CheckIcon>
      )}
      {isPrescriptionConsulted && isDateOlderThanOneWeek && (
        <CheckIcon>
          <BsHourglassSplit />
        </CheckIcon>
      )}
      {!isPrescriptionConsulted && isSendDateOlderThanOneWeek && (
        <CheckIcon>
          <BsHourglassSplit />
        </CheckIcon>
      )}
    </Component>
  )
}

export default PrescriptionStatusCell
