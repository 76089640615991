import styled from 'styled-components/macro'

import {
  Icon
} from '../icon/style'

const getSize = size =>
  ({
    m: '.75rem',
    s: '.375rem',
  } [size])

export const InputContainer = styled.div `
  display: flex;
  border: 0.0625rem solid #e9eaf0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  min-height: 2.375rem;
  align-items: center;
  width: 100%;
  box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.02);

  > ${Icon} {
    margin: 0 0 0 0.625rem;
  }
`

export const Input = styled.input `
  padding: ${({ size }) => getSize(size)};
  height: ${({ customHeight }) => customHeight || '2.375rem'};
  outline: none;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  font-size: 1rem;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.8' : '1')};

  ::placeholder {
    font-size: 0.875rem;
    color: #8f95b2;
    line-height: 1.05rem;
    font-weight: 400;
    font-style: normal;
  }
`

export const Eye = styled.div `
  margin-right: 0.75rem;
  align-items: center;
  display: flex;
`