import { useQueryClient } from 'react-query'
import { Button, MenuContainer, MenuContainerItem } from '../style'
import {
  useAddPrescriberFavoriteListItemToList,
  useGetAllPrescriberFavoriteListsByPrescriberId,
} from '../../../../../services/api/favorites/favorites'
import { Dropdown } from '../../../../ui'
import { useGetMe } from '../../../../../utils/hooks/getMe'
import { AddToFavoriteProps } from './props'
import {
  refetchFavorite,
  refetchAllFavorite,
} from '../../../../../utils/helpers'
import { toast } from 'react-toastify'

const AddToFavorite = ({
  allowDeleteFromFavoriteList,
  favoriteListId,
  productId,
}: AddToFavoriteProps) => {
  // Attributes
  const { data: prescriber } = useGetMe()
  const queryClient = useQueryClient()

  // Hooks to add and delete favorite list items
  const { mutateAsync: addPrescriberFavoriteListItemToList } =
    useAddPrescriberFavoriteListItemToList({
      mutation: {
        onSuccess: () => {
          toast.success('Le produit a bien été ajouté aux favoris')
          refetchFavorite(queryClient, favoriteListId)
          refetchAllFavorite(queryClient, prescriber?.id)
        },
        onError: error => {
          handleAddPrescriberFavoriteListItemToListError(error)
        },
      },
    })

  function handleAddPrescriberFavoriteListItemToListError(error: any) {
    if (error.response.data === 'usecase/already-exists') {
      return toast.error('Ce produit existe déjà dans cette liste.')
    }
    return toast.error('Oops! Une erreur est survenue.')
  }

  // Fetch all favorite lists
  const { data: favoriteLists } =
    useGetAllPrescriberFavoriteListsByPrescriberId(prescriber?.id, null, {
      query: {
        enabled: !!prescriber?.id,
      },
    })

  // Functions
  const handleAddPrescriberFavoriteListItemToList = (
    favoriteListId: string
  ) => {
    addPrescriberFavoriteListItemToList({
      favoriteListId,
      data: {
        productId: productId,
      },
    })
  }

  return (
    !allowDeleteFromFavoriteList && (
      <>
        {favoriteListId ? (
          <Button
            onClick={() =>
              handleAddPrescriberFavoriteListItemToList(favoriteListId)
            }
          >
            Ajouter aux favoris
          </Button>
        ) : (
          <Dropdown
            content={
              <MenuContainer>
                {favoriteLists?.map(favorite => {
                  return (
                    <MenuContainerItem
                      onClick={() =>
                        handleAddPrescriberFavoriteListItemToList(favorite?.id)
                      }
                    >
                      {favorite?.name}
                    </MenuContainerItem>
                  )
                })}
              </MenuContainer>
            }
          >
            <Button>Ajouter aux favoris</Button>
          </Dropdown>
        )}
      </>
    )
  )
}

export default AddToFavorite
