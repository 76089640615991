import { useGetAllProductTraceabilitiesByProductId } from '../../../../../services/api/product/product'
import { SourcingProps } from './props'
import { Container, Title, List, Item, ItemText, Green } from './style'
import { HorizontalSeparator } from '../../left-side/style'

const Sourcing = ({ product }: SourcingProps) => {
  //BE Calls
  const { data: traceabilities } = useGetAllProductTraceabilitiesByProductId(
    product?.id
  )

  if (traceabilities?.totalAmount === 0) {
    return <></>
  }

  // Render
  return (
    <Container>
      <Title>Sourcing</Title>
      <HorizontalSeparator />
      <List>
        <div>
          {traceabilities?.data?.map(value => {
            return (
              <Item>
                <ItemText>
                  <Green>{value?.imageUrl}</Green>
                  {value?.title}
                </ItemText>
              </Item>
            )
          })}
        </div>
      </List>
    </Container>
  )
}

export default Sourcing
