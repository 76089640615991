import { useEffect, useState } from 'react'

import { Form, AttachFile, FileList, File } from './style'
import { Container, Title } from '../style'
import { Field, FieldGroup, Tooltip, Icon } from '../../../ui'
import { RiAttachment2 } from 'react-icons/ri'
import { useGetMe } from '../../../../utils/hooks/getMe'

import Collapse from '../../../ui/collapse'
import FormDialog from './form-dialog'
import { GrClose } from 'react-icons/gr'

import { useGetPatient } from '../../../../services/api/patients/patients'
import {
  PrescriptionRecommendedDuration,
  PurchaseOption,
  Role,
} from '../../../../services/api/types'

const durationOptions = [
  { value: PrescriptionRecommendedDuration.ONE_MONTH, label: '1' },
  { value: PrescriptionRecommendedDuration.TWO_MONTH, label: '2' },
  { value: PrescriptionRecommendedDuration.THREE_MONTH, label: '3' },
]

const purchaseOptions = [
  {
    value: PurchaseOption.BOTH,
    label: 'Abonnement ou achat unique',
  },
  { value: PurchaseOption.SUBSCRIPTION, label: 'Abonnement' },
  { value: PurchaseOption.ONE_TIME, label: 'Achat unique' },
]

const shareOptions = [
  { value: false, label: 'Non' },
  { value: true, label: 'Oui' },
]

const getFallbackMessage = (patientName, prescriber) => {
  let discountContent = ''
  if (prescriber.discountCode) {
    discountContent += `Vous bénéficiez d'une réduction de ${prescriber.discountPercentage}% sur votre commande avec le code ${prescriber.discountCode}.`
  }

  return `Bonjour ${patientName || ''}, voici ma recommandation.
${discountContent}
${prescriber.firstName || '*votre nom*'}`
}

const PrescriptionFormStep3 = ({
  customMessage,
  setCustomMessage,
  duration,
  setDuration,
  purchaseOption,
  setPurchaseOption,

  // Only for protocol
  share,
  setShare,

  // Only for prescription
  files,
  setFiles,
  patientId,
}) => {
  // Attributes
  const [isFormFilesOpened, setIsFormFilesOpened] = useState(false)

  const { data: prescriber } = useGetMe()

  const { data: patient } = useGetPatient(prescriber?.id, patientId, {
    query: { enabled: !!prescriber?.id && patientId >= 0 },
  })
  const durationValueIndex = durationOptions.findIndex(
    u => u.value === duration
  )
  const shareValueIndex = shareOptions.findIndex(u => u.value === share)
  const pushaseOptionValueIndex = purchaseOptions.findIndex(
    u => u.value === purchaseOption
  )

  useEffect(() => {
    if (!customMessage?.length) {
      setCustomMessage(
        getFallbackMessage(
          patient && patient.firstName ? patient.firstName : '',
          prescriber,
          duration
        )
      )
      setDuration(durationOptions[2].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Return
  return (
    <Container>
      <Title>Finaliser la cure</Title>
      <Form>
        <Field
          label={`Ajouter un message personnalisé pour ${
            patient ? patient.firstName : 'votre client'
          }`}
          name='message'
          id='message'
          type='textarea'
          onChange={e => setCustomMessage(e.target.value)}
          value={customMessage}
        />
        {setFiles && (
          <>
            <AttachFile
              onClick={e => {
                e.preventDefault()
                setIsFormFilesOpened(true)
              }}
            >
              <span>
                <RiAttachment2 />
              </span>
              <p>Joindre un fichier</p>
            </AttachFile>
            <FileList>
              {files?.map(file => (
                <File key={file.id}>
                  <a href={file.fileUrl} target='_blank' rel='noreferrer'>
                    {file?.fileName}
                  </a>
                  <span
                    onClick={() => {
                      setFiles(files.filter(f => f.id !== file.id))
                    }}
                  >
                    <GrClose />
                  </span>
                </File>
              ))}
            </FileList>
          </>
        )}
        <FieldGroup
          label='Durée recommandée'
          labelAppend={
            <Tooltip
              size='l'
              label='Pour chaque mois recommandé, votre patient recevra une boite de 30 sachets quotidien contenant votre prescription'
            >
              <Icon name='info' />
            </Tooltip>
          }
        >
          <Field
            type='select'
            name='duration'
            value={durationOptions[durationValueIndex]}
            options={durationOptions}
            onChange={data => setDuration(data.value)}
            defaultValue={durationOptions[2]}
          />
          <Field type='text' name='month' value='mois' disabled />
        </FieldGroup>

        <div
          style={{
            marginTop: '20px',
          }}
        ></div>

        <Collapse collapsed={false} headerText='Paramètres avancés'>
          <div
            style={{
              marginTop: '10px',
            }}
          ></div>
          <FieldGroup
            label="Options d'achat"
            labelAppend={
              <Tooltip
                size='l'
                label='Offrez la possibilité à vos clients de choisir entre un achat unique ou un abonnement où restreignez à un des deux choix.'
              >
                <Icon name='info' />
              </Tooltip>
            }
          >
            <Field
              type='select'
              name='purchaseOption'
              value={purchaseOptions[pushaseOptionValueIndex]}
              options={purchaseOptions}
              onChange={data => setPurchaseOption(data.value)}
              defaultValue={purchaseOptions[0]}
            />
          </FieldGroup>

          {setShare && (
            <FieldGroup
              label='Partager le protocole'
              labelAppend={
                <Tooltip
                  size='l'
                  label='Le protocole sera accessible à tous les prescripteurs de la plateforme'
                >
                  <Icon name='info' />
                </Tooltip>
              }
            >
              <Field
                type='select'
                name='duration'
                value={shareOptions[shareValueIndex]}
                options={shareOptions}
                onChange={data => setShare(data.value)}
                defaultValue={shareOptions[0]}
              />
            </FieldGroup>
          )}
        </Collapse>
      </Form>

      {/* Dialogs */}
      <FormDialog
        isOpen={setFiles && isFormFilesOpened}
        onClose={() => setIsFormFilesOpened(false)}
        files={files}
        setFiles={setFiles}
      />
    </Container>
  )
}

export default PrescriptionFormStep3
