import styled from 'styled-components/macro'

import { Color, MediaQuery } from '../../../../utils/constants'

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #e2e4f3;
  position: sticky;
  top: 0;
  padding: 0 25px;
  background-color: white;
  z-index: 10;
`

export const Nav = styled.nav`
  display: flex;
  height: 100%;
  align-items: center;
`

export const Ul = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

export const CalendarLink = styled.a`
  color: #7961bd !important;
  display: flex;
  align-items: center;
  img {
    margin-right: 3px;
  }
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 12.5px 6px;

  button {
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 30px;
    border: none;
    margin-right: 20px;
    background-color: ${Color.DEEP_SEA};
    color: white;
    ${MediaQuery.M_AND_DOWN} {
      margin-bottom: 15px;
    }
  }
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }

  > a {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    color: black;
    text-decoration: none;
    padding: 10px 10px;
    border-radius: 60px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    ${({ active }) =>
      active === 'true' ? 'background-color: rgba(121, 97, 189, 0.13);' : ''}
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
`
