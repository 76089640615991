/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type BrandInstructionTiming =
  | 'ONCE_PER_DAY'
  | 'TWICE_PER_DAY'
  | 'THREE_TIMES_PER_DAY'
  | 'FOUR_TIMES_PER_DAY'
  | 'EVERY_MORNING'
  | 'EVERY_NOON'
  | 'EVERY_EVENING'
  | 'UNKNOWN'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BrandInstructionTiming = {
  ONCE_PER_DAY: 'ONCE_PER_DAY' as BrandInstructionTiming,
  TWICE_PER_DAY: 'TWICE_PER_DAY' as BrandInstructionTiming,
  THREE_TIMES_PER_DAY: 'THREE_TIMES_PER_DAY' as BrandInstructionTiming,
  FOUR_TIMES_PER_DAY: 'FOUR_TIMES_PER_DAY' as BrandInstructionTiming,
  EVERY_MORNING: 'EVERY_MORNING' as BrandInstructionTiming,
  EVERY_NOON: 'EVERY_NOON' as BrandInstructionTiming,
  EVERY_EVENING: 'EVERY_EVENING' as BrandInstructionTiming,
  UNKNOWN: 'UNKNOWN' as BrandInstructionTiming,
}
