// useRequestProductLogic.ts
import { useState, useEffect } from 'react'
import { RequestProductProps } from './props'
import { useGetUserBySub } from '../../../services/api/users/users'
import { Role } from '../../../services/api/types'
import { ILogicReturn } from './types'

export const useRequestProductLogic = ({
  setIsInnerDialogOpen,
}: RequestProductProps): ILogicReturn => {
  //Attributes
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const userRole = localStorage.getItem('role') as Role

  //Queries
  const { data: prescriber } = useGetUserBySub(
    {
      role: userRole,
    },
    {
      query: {
        enabled: !!userRole,
        retry: 1,
      },
    }
  )

  //Effects
  useEffect(() => {
    if (setIsInnerDialogOpen) {
      setIsInnerDialogOpen(!isModalOpen)
    }
  }, [isModalOpen, setIsInnerDialogOpen])

  //Return
  return {
    isModalOpen,
    setIsModalOpen,
    prescriber,
  }
}
