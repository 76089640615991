import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  list: [],
  /**
   * New draft correspond to a new prescription, it's a 3 step form so the
   * user can quit the process and come back if needed
   */
  newDraft: {
    message: '',
    patientId: null,
    takes: [],
    duration: 1,
    durationUnit: 'month',
    files: [],
    lastStatus: '',
    parentId: '',
  },
  /**
   * By storing the last created prescription id, we can display specific
   * actions like `review`, `send email to patient`,...
   */
  lastCreatedId: null,
  isStatusSendedManually: false,
  actions: {
    add: { ...INITIAL_ACTION_STATE },
    update: {
      prescription: { ...INITIAL_ACTION_STATE },
      take: {
        add: { ...INITIAL_ACTION_STATE },
        delete: { ...INITIAL_ACTION_STATE },
      },
      item: { ...INITIAL_ACTION_STATE },
    },
    delete: { ...INITIAL_ACTION_STATE },
    fetchAll: { ...INITIAL_ACTION_STATE },
    fetchOne: { ...INITIAL_ACTION_STATE },
    sendByEmail: { ...INITIAL_ACTION_STATE },
    pushStatus: { ...INITIAL_ACTION_STATE },
  },
}

export default state
