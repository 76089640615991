import { useState } from 'react'
import { useGetAllProductBenefitsByProductId } from '../../../../../services/api/product/product'
import { IconCheckBig } from '../../../../ui/icon/icons'
import { HorizontalSeparator } from '../../left-side/style'
import { BenefitsProps } from './props'
import {
  FlexColumn,
  FlexRow,
  FlexRowBenefits,
  IconContainer,
  SecondaryTitle,
  SmallText,
  Wrapper,
  BenefitDescription,
  SeeMore,
} from './style'
import { FlexList, Card, CardTitle, CardText } from '../formulation/style'

const Benefits = ({ product, isPatientCatalog }: BenefitsProps) => {
  // Attributes
  const minLengthStringDescription = 500
  const [maxLengthStringDescription, setMaxLengthStringDescription] = useState(
    minLengthStringDescription
  )

  //BE Calls
  const { data: benefits } = useGetAllProductBenefitsByProductId(product?.id, {
    isPatientDisplay: isPatientCatalog,
  })

  // Render
  return (
    <Wrapper>
      <SecondaryTitle>A propos du produit</SecondaryTitle>
      <HorizontalSeparator />

      {isPatientCatalog && (
        <>
          <FlexList>
            {benefits?.data?.map(benefit => {
              return (
                <Card>
                  <CardTitle>{benefit?.title}</CardTitle>
                  <CardText>{benefit?.content}</CardText>
                </Card>
              )
            })}
          </FlexList>
        </>
      )}

      {!isPatientCatalog && (
        <>
          <FlexRow>
            {benefits?.data?.map(value => {
              return (
                <FlexColumn key={value?.id}>
                  <FlexRowBenefits>
                    <IconContainer color={'#57B58F'}>
                      <IconCheckBig
                        width='10px'
                        height='10px'
                        stroke='#57B58F'
                      />
                    </IconContainer>
                    <SmallText>{value?.title}</SmallText>
                  </FlexRowBenefits>
                </FlexColumn>
              )
            })}
          </FlexRow>
          {!!benefits?.data?.length && <HorizontalSeparator />}
          <BenefitDescription
            style={{ marginTop: '15px' }}
            className='content'
            dangerouslySetInnerHTML={{
              __html: product?.benefitDescription?.slice(
                0,
                maxLengthStringDescription
              ),
            }}
          ></BenefitDescription>
          {product?.benefitDescription?.length > maxLengthStringDescription ? (
            <SeeMore onClick={() => setMaxLengthStringDescription(100000000)}>
              Voir plus
            </SeeMore>
          ) : (
            product?.benefitDescription?.length >
              minLengthStringDescription && (
              <SeeMore
                onClick={() =>
                  setMaxLengthStringDescription(minLengthStringDescription)
                }
              >
                Voir moins
              </SeeMore>
            )
          )}
        </>
      )}
    </Wrapper>
  )
}

export default Benefits
