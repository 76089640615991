/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PatientSexe = 'MAN' | 'WOMAN'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientSexe = {
  MAN: 'MAN' as PatientSexe,
  WOMAN: 'WOMAN' as PatientSexe,
}
