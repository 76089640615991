import { useMemo, useState } from 'react'

import {
  Container,
  CureContent,
  Footer,
  IconContainer,
  IconsContainer,
  InputContainer,
  Note,
  NoteContainer,
  ProductsList,
  SecondaryTitle,
  TakeContainer,
  Title,
  Total,
  Duration,
  HelperText,
  FooterLine,
  MiddleFooter,
} from './style'
import ProductPreview from './product-preview'
import { Button, Icon, Input, Tooltip } from '../../ui'
import { useGetMe } from '../../../utils/hooks/getMe'

import { displayRecommendedDuration } from '../../../utils/helpers'
import { useGetPatient } from '../../../services/api/patients/patients'
import { PrescriptionTakeType, Role } from '../../../services/api/types'
import PrescriptionDraftInfo from './prescription-draft-info'
import EditDiscountCodeModal from './edit-discount-code-modal'

const NewPrescriptionDraftPreview = ({
  draft,
  setProductNotesDraft,
  decreaseQttyProductFromDraftTake,
  increaseQttyProductFromDraftTake,
  removeProductFromDraftTake,
  removeTake,
  amounts,
  addTake,
  setTakeLabel,
  products,
  openDetailModal,
  patientId,
  removeItemFromDraftByIndex,
}) => {
  // Attributes
  const { takes, customMessage, recommendedDuration } = draft
  const { data: prescriber } = useGetMe()

  const { data: patient } = useGetPatient(prescriber?.id, patientId, {
    query: { enabled: !!patientId && !!prescriber?.id && patientId >= 0 },
  })
  const [takeIndexLabelEditing, setTakeIndexLabelEditing] = useState(null)
  const [takeLabelValue, setTakeLabelValue] = useState('')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const isTakesMoreThanThreeProducts = !!takes?.find(i => i?.items?.length >= 3)

  // Functions
  const labelDefaultValue = (index, take) => {
    return take.label || `Prise #${index + 1}`
  }

  // Set takes
  const { inCureTakes, outCureTake } = useMemo(() => {
    // const inCure: (CreatePrescriptionTakeDTO | CreateProtocolTakeDTO)[] = []
    const inCure = []
    let outCure

    takes.forEach(take => {
      if (take.type === PrescriptionTakeType.IN_CURE) {
        inCure.push(take)
      } else {
        outCure = take
      }
    })

    return { inCureTakes: inCure, outCureTake: outCure }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takes])

  function retrieveProduct(product, itemIndex, takeIndex) {
    const item = products?.find(prod => prod.handle === product?.productHandle)

    if (item === undefined) {
      removeItemFromDraftByIndex(takeIndex, itemIndex)
      return
    }
    return item
  }

  // Render
  return (
    <Container>
      {patient && (
        <Title>{`La cure personnalisée de ${patient.firstName}`}</Title>
      )}
      <CureContent>
        {!!inCureTakes.length && (
          <SecondaryTitle>Produits en sachets/pots</SecondaryTitle>
        )}
        {!isTakesMoreThanThreeProducts && <PrescriptionDraftInfo />}
        {inCureTakes?.map((take, index) => (
          <TakeContainer key={index} hasBackground={inCureTakes.length > 1}>
            {takeIndexLabelEditing !== index && (
              <SecondaryTitle>
                {labelDefaultValue(index, take)}
                <IconContainer>
                  <Tooltip label='Editer le nom de la prise' size={undefined}>
                    <Icon
                      name='pencil'
                      onClick={() => {
                        setTakeLabelValue(labelDefaultValue(index, take))
                        setTakeIndexLabelEditing(index)
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    label='Supprimer la prise entièrement'
                    size={undefined}
                  >
                    <Icon name='trash' onClick={() => removeTake(index)} />
                  </Tooltip>
                </IconContainer>
              </SecondaryTitle>
            )}
            {takeIndexLabelEditing === index && (
              <InputContainer>
                <Input
                  value={takeLabelValue}
                  onChange={e => setTakeLabelValue(e.target.value)}
                  size='s'
                />
                <IconsContainer>
                  <Icon
                    name='check'
                    onClick={() => {
                      setTakeLabel(takeLabelValue, index)
                      setTakeIndexLabelEditing(null)
                      setTakeLabelValue('')
                    }}
                  />
                  <Icon
                    name='cancel'
                    onClick={() => {
                      setTakeIndexLabelEditing(null)
                      setTakeLabelValue('')
                    }}
                  />
                </IconsContainer>
              </InputContainer>
            )}
            <ProductsList>
              {!take.items.length && (
                <HelperText>
                  Pour ajouter des produits à cette prise, séléctionnez le
                  produit souhaité dans la liste de gauche
                </HelperText>
              )}
              {take.items.map((item, itemIndex) => (
                <ProductPreview
                  isLast={itemIndex === take.items.length - 1}
                  takeIndex={index}
                  key={`${item.productHandle}-${index}`}
                  quantity={item.quantity}
                  notes={item.note}
                  recommendedDuration={recommendedDuration}
                  setProductNotesDraft={setProductNotesDraft}
                  decreaseQttyProductFromDraftTake={
                    decreaseQttyProductFromDraftTake
                  }
                  increaseQttyProductFromDraftTake={
                    increaseQttyProductFromDraftTake
                  }
                  removeProductFromDraftTake={removeProductFromDraftTake}
                  product={retrieveProduct(item, itemIndex, index)}
                  onDetailOpen={() => openDetailModal(item.productHandle)}
                />
              ))}
            </ProductsList>
          </TakeContainer>
        ))}
        {!!inCureTakes.length && (
          <Button
            onClick={addTake}
            appearance='primary'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            Séparer la cure en {inCureTakes.length + 1} prises
          </Button>
        )}
        {!!outCureTake && (
          <div>
            <SecondaryTitle>Produits à part</SecondaryTitle>
            {outCureTake.items.map((item, itemIndex) => (
              <ProductPreview
                isLast={itemIndex === outCureTake.items.length - 1}
                takeIndex={inCureTakes?.length}
                key={item.productHandle}
                quantity={item.quantity}
                notes={item.note}
                recommendedDuration={recommendedDuration}
                setProductNotesDraft={setProductNotesDraft}
                decreaseQttyProductFromDraftTake={
                  decreaseQttyProductFromDraftTake
                }
                increaseQttyProductFromDraftTake={
                  increaseQttyProductFromDraftTake
                }
                removeProductFromDraftTake={removeProductFromDraftTake}
                product={products?.find(
                  prod => prod.handle === item.productHandle
                )}
                onDetailOpen={() => openDetailModal(item.productHandle)}
              />
            ))}
          </div>
        )}
      </CureContent>
      {customMessage && (
        <NoteContainer>
          <Note>{customMessage}</Note>
        </NoteContainer>
      )}
      <Footer>
        <FooterLine>
          Sous-total:&nbsp;{' '}
          <strong style={{ fontWeight: 500 }}>
            {amounts?.subTotal?.toFixed(2)}€
          </strong>
        </FooterLine>

        <FooterLine>
          Réduction : -{amounts?.discount?.percentage}% avec le code&nbsp;
          <strong style={{ fontWeight: 500 }}>
            {' '}
            {amounts?.discount?.code}{' '}
          </strong>
          <Button
            appearance='primary'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
            onClick={() => setIsDialogOpen(true)}
            style={{
              padding: '1px 8px',
              fontSize: '10px',
              marginLeft: '10px',
            }}
          >
            Modifier
          </Button>
        </FooterLine>
        <MiddleFooter>
          <FooterLine>
            Total pour{' '}
            <Duration>
              {displayRecommendedDuration(recommendedDuration)}
            </Duration>{' '}
          </FooterLine>
          <FooterLine>
            {' '}
            <Total> {amounts?.total?.toFixed(2)}€</Total>
          </FooterLine>
          <FooterLine style={{ opacity: '.5' }}>
            ou €{amounts?.totalRecommendedDuration?.toFixed(2)}
            /mois pendant {displayRecommendedDuration(recommendedDuration)}
          </FooterLine>
        </MiddleFooter>
        <FooterLine>
          Votre commission :{' '}
          <strong style={{ fontWeight: 500 }}>
            {' '}
            {amounts?.commissionPrice?.toFixed(2)}€
          </strong>
        </FooterLine>
      </Footer>
      <EditDiscountCodeModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </Container>
  )
}

export default NewPrescriptionDraftPreview
