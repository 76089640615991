/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PrescriberRewardStatus =
  | 'IN_PROGRESS'
  | 'FINISHED'
  | 'RECIEVED'
  | 'CLOSED'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrescriberRewardStatus = {
  IN_PROGRESS: 'IN_PROGRESS' as PrescriberRewardStatus,
  FINISHED: 'FINISHED' as PrescriberRewardStatus,
  RECIEVED: 'RECIEVED' as PrescriberRewardStatus,
  CLOSED: 'CLOSED' as PrescriberRewardStatus,
}
