import { View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import CreditCardIcon from '../../assets/images/creditCardIcon.png'
import UserIcon from '../../assets/images/userIcon.png'
import TransportIcon from '../../assets/images/transportIcon.png'
import EuropIcon from '../../assets/images/european-union.png'

const BookletInfo = () => (
  <View style={styles.wrapper}>
    <View style={styles.item}>
      <Image src={TransportIcon} style={styles.image} />
      <Text>Livraison à domicile dans les 48 à 72h</Text>
    </View>
    <View style={styles.item}>
      <Image src={CreditCardIcon} style={styles.image} />
      <Text>Satisfait ou remboursé, retours gratuits</Text>
    </View>
    <View style={styles.item}>
      <Image src={EuropIcon} style={styles.image} />
      <Text>Des formules issues de laboratoires français et belges.</Text>
    </View>
    <View style={styles.item}>
      <Image src={UserIcon} style={styles.image} />
      <Text>Une question? Notre service client est disponible 24/7</Text>
    </View>
  </View>
)

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px 10px',
    margin: '40px 10px 10px 15px',
    minHeight: 'auto',
  },
  item: {
    width: '20%',
    textAlign: 'center',
    fontSize: '8px',
  },
  image: {
    width: '12px',
    margin: '0 auto 5px auto',
  },
})

export default BookletInfo
