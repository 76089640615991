import styled from 'styled-components/macro'

// import { Color } from '../../../utils/constants';

export const Header = styled.header`
  display: flex;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #ddd8;
  position: sticky;
  top: 0;
  padding: 0 20px;
  background-color: white;
  z-index: 10;
`
