import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f8f9fc;
  border: 1px solid #e7e9f4;
  border-radius: 12px;
  min-height: auto;
`

export const Header = styled.div`
  padding: 20px;
`

export const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 16px;
`

export const From = styled.p`
  font-size: 12px;
  span {
    opacity: 0.6;
  }
`
export const FlexItem = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
`
export const Text = styled.p`
  display: flex;
  align-items: center;
  strong {
    margin-left: 5px;
    font-weight: bold;
    font-size: 14px;
  }
`

export const ReductionTitle = styled.p`
  margin-bottom: 5px;
`

export const Hr = styled.div`
  margin: 25px 0;
  width: 100%;
  height: 1px;
  background: #e7e9f4;
`
export const Footer = styled.div`
  padding: 20px;
  background: #f2f4f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`
export const Total = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 3px;
`
export const Commission = styled.div`
  font-size: 13px;
  opacity: 0.6;
`

export const DropdownContent = styled.div`
  a {
    text-decoration: none;
    color: inherit;
  }
`
export const DropDownContentItem = styled.div`
  color: #393360;
  font-size: 14px;
  cursor: pointer;
  padding: 12px 8px 12px 12px;
  border-radius: 4px;
  white-space: nowrap;
  &:hover {
    background: #f2f4f9;
  }
`
export const IconWrapper = styled.div`
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
`

export const InlineButton = styled.button`
  width: 100%;
  outline: none;
  color: inherit;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  span {
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
`
