import styled from 'styled-components/macro'

export const Container = styled.div`
  background-image: url('https://firebasestorage.googleapis.com/v0/b/compliment-pro-prod.appspot.com/o/2-banner.png?alt=media&token=1d0661dc-90c8-42dc-be9d-51e5bb79e5e1');
  background-position: right;
  background-size: cover;
  min-height: 143px;
  padding: 20px;
  margin: 20px;
  border-radius: 12px;
  opacity: 1;
`

export const TextWrapper = styled.div`
  width: 55%;
`

export const Title = styled.h3`
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
  letter-spacing: 0px;
  color: #080038;
  opacity: 1;
`

export const Description = styled.p`
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #080038;
  opacity: 1;
`
