import { IBurgerMenuProps } from './props'
import { LargeRiMenuLine, MenuIcon } from './style'
import { Link } from 'react-router-dom'

const BurgerMenu = ({ linkTo, onClick }: IBurgerMenuProps) => {
  //Return
  return linkTo ? (
    <Link to={linkTo ? linkTo : '#'}>
      <MenuIcon>
        <LargeRiMenuLine />
      </MenuIcon>
    </Link>
  ) : (
    <MenuIcon
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <LargeRiMenuLine />
    </MenuIcon>
  )
}

export default BurgerMenu
