import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`

export const Content = styled.div`
  max-width: 998px;
  width: 100%;
  padding: 65px 0;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Title = styled.h1`
  font-size: 22px;
  width: 650px;
  margin-bottom: 20px;
`
