import {
  Container,
  BoxInformation,
  BoxInformationRow,
  BoxInformationRowItem,
  BoxInformationRowItemtext,
  BoxInformationRowItemIcon,
  TitleWrapper,
  Title,
  Initial,
} from './style'
import { AiOutlineMail } from 'react-icons/ai'
import { FiPhone } from 'react-icons/fi'
import { GiPill } from 'react-icons/gi'
import { AiOutlineMan, AiOutlineWoman } from 'react-icons/ai'
import { BsArrowClockwise } from 'react-icons/bs'

import { Spinner } from '../../../components/ui'

import { useGetMe } from '../../../utils/hooks/getMe'

import { useGetPatient } from '../../../services/api/patients/patients'

const PatientInfo = ({ patientId }: { patientId: number }) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const { data: patient, isLoading } = useGetPatient(
    prescriber?.id,
    patientId,
    {
      query: {
        enabled: !!prescriber?.id && !!patientId,
      },
    }
  )

  // Functions
  function getSexIcon(sexe: string) {
    if (sexe === 'WOMAN') return <AiOutlineWoman />
    else return <AiOutlineMan />
  }

  function getSexText(sexe: string) {
    if (sexe === 'WOMAN') return 'Féminin'
    else return 'Masculin'
  }

  // Render
  return isLoading ? (
    <Spinner fullScreen={false} />
  ) : (
    <Container>
      <TitleWrapper>
        <Title>Informations</Title>
      </TitleWrapper>
      <BoxInformation>
        <BoxInformationRow>
          <BoxInformationRowItem>
            <Initial>
              {patient?.firstName?.[0]}
              {patient?.lastName?.[0]}
            </Initial>
            <BoxInformationRowItemtext>
              {patient?.firstName} {patient?.lastName}
            </BoxInformationRowItemtext>
          </BoxInformationRowItem>
          <BoxInformationRowItem>
            <BoxInformationRowItemIcon>
              <AiOutlineMail />
            </BoxInformationRowItemIcon>
            <BoxInformationRowItemtext>
              {patient?.email}
            </BoxInformationRowItemtext>
          </BoxInformationRowItem>
        </BoxInformationRow>
        <BoxInformationRow>
          <BoxInformationRowItem>
            <BoxInformationRowItemIcon>
              <FiPhone />
            </BoxInformationRowItemIcon>
            <BoxInformationRowItemtext>
              {patient?.phone}
            </BoxInformationRowItemtext>
          </BoxInformationRowItem>
        </BoxInformationRow>
      </BoxInformation>

      <BoxInformation>
        <BoxInformationRow>
          <BoxInformationRowItem>
            <BoxInformationRowItemIcon bg='#E0F0E9'>
              <BsArrowClockwise />
            </BoxInformationRowItemIcon>
            <BoxInformationRowItemtext>
              Age: {patient?.age ? `${patient?.age} ans` : '/'}
            </BoxInformationRowItemtext>
          </BoxInformationRowItem>
        </BoxInformationRow>
        <BoxInformationRow>
          <BoxInformationRowItem>
            <BoxInformationRowItemIcon bg='#E0F0E9'>
              {getSexIcon(patient?.sexe)}
            </BoxInformationRowItemIcon>
            <BoxInformationRowItemtext>
              Sexe: {patient?.sexe ? getSexText(patient?.sexe) : '/'}
            </BoxInformationRowItemtext>
          </BoxInformationRowItem>
        </BoxInformationRow>
        <BoxInformationRow>
          <BoxInformationRowItem>
            <BoxInformationRowItemIcon bg='#E0F0E9'>
              <GiPill />
            </BoxInformationRowItemIcon>
            <BoxInformationRowItemtext>
              Notes:
              {patient?.pathology
                ? `
                  ${patient?.pathology?.substring(0, 36)}
                  ${patient?.pathology?.length > 36 ? '...' : ''}
                  `
                : '/'}
            </BoxInformationRowItemtext>
          </BoxInformationRowItem>
        </BoxInformationRow>
      </BoxInformation>
    </Container>
  )
}

export default PatientInfo
