import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../../utils/constants'

export const DialogContainerSelectTake = styled.div`
  padding: 20px;
`

export const HeaderDialog = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  img {
    margin-right: 10px;
    object-fit: cover;
    border-radius: 10px;
    width: 60px;
    height: 60px;
  }
`

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
`
export const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 15px;
`

export const TakeChoice = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${Color.TRANSPARENT};
  margin: 10px 0;
  border-radius: 4px;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  width: 100%;
`

export const TakeChoices = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`

export const PillIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 22px;
  min-height: 22px;
  max-width: 22px;
  max-height: 22px;

  border-radius: 100%;
  font-size: 15px;
  margin-top: 3px;
  margin-right: 10px;
  background-color: ${props => (props.selected ? '#9481CA' : '')};
  color: ${props => (props.selected ? 'white' : Color.DEEP_SEA)};
  border: ${props =>
    props.selected ? '1px solid #9481CA' : `1px solid ${Color.DEEP_SEA}`};
`
