import styled from 'styled-components/macro'

export const SelectProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
`

export const SelectInputContainer = styled.div`
  width: 32%;
  padding: 2px;
`
