import styled from 'styled-components/macro'
import { Color } from '../../utils/constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  background-color: #fdfcfe;
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
`
export const Header = styled.div`
  p,
  ul {
    margin-bottom: 10px;
  }
  ul {
    margin-left: 20px;
  }
  button {
    color: ${Color.DEEP_SEA};
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`
export const SeeMore = styled.div`
  display: ${props => (props.seeMore ? 'block' : 'none')};
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;

  thead > tr {
    height: 77px;
  }
  th {
    padding: 0 12px;
    text-align: start;
    color: #080038;
    font-size: 14px;
    line-height: 17px;
  }
  th:first-child {
    padding: 0 12px 0 30px;
  }
  th:last-child {
    padding: 0 30px 0 12px;
  }
  tbody > tr {
    height: 78px;
  }
  td {
    padding: 0 12px;
    font-size: 14px;
    line-height: 17px;
    color: #080038;
  }
  td:first-child {
    padding: 0 12px 0 30px;
  }
  td:last-child {
    padding: 0 30px 0 12px;
  }
`

export const TableHead = styled.thead`
  /* border-bottom: 1px solid black; */
`

export const TableBody = styled.tbody``

export const Cell = styled.td`
  /* flex-grow: 1;
  width: ${({ width }) => width || 'auto'}; */
`

export const Row = styled.tr`
  /* width: 100%;

  ${Cell}:nth-child(4) {
    max-width: 100px;
  } */
`

export const HeadRow = styled(Row)`
  /* ${Cell} {
    padding: 10px 0;
  } */
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`

export const SeeIcon = styled.div`
  font-size: 15px;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`
