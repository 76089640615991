import formatTitle from 'title'
import {
  ShopifyService,
  CartLineUpdateInput,
  CurrencyCode,
  CartLineInput,
} from '../services/shopify.service'
import Cookies from 'js-cookie'

const CHECKOUT_IDD = 'CHECKOUT_IDD'

export namespace CartService {
  export interface CartItem {
    id: string
    title: string
    quantity: number
    productPictureUrl: string
    recoProductName: string
    recoProductWhy: string
    variant: {
      id: string
      title: string
      url: string
      handle: string
      price: {
        amount: number
        currencyCode: CurrencyCode
      }
      image: {
        src: string
        alt: string
      }
    }
  }

  export interface DiscountCodes {
    code: string
    applicable: boolean
  }

  export type Cart = {
    id: string
    items: CartItem[]
    subtotal: {
      amount: number
      currencyCode: CurrencyCode
    }
    tax: {
      amount: number
      currencyCode: CurrencyCode
    }
    total: {
      amount: number
      currencyCode: CurrencyCode
    }
    discountCodes: DiscountCodes[]
    url: string
  }

  export async function getCart(): Promise<Cart | undefined> {
    const checkoutId = Cookies.get(CHECKOUT_IDD)

    if (checkoutId) {
      const { cart } = await ShopifyService.getCart({ id: checkoutId })

      if (cart?.__typename === 'Cart') {
        const items: CartItem[] = cart.lines.edges.map(({ node }) => {
          const item: CartItem = {
            id: node.id,

            title: formatTitle(node.merchandise.product.title),
            quantity: node.quantity,
            productPictureUrl: node.merchandise.product.productPicture?.value
              ? JSON.parse(node.merchandise.product.productPicture.value)[0].src
              : '',
            recoProductName:
              node.merchandise.product.recoProductName?.value || '',
            recoProductWhy:
              node.merchandise.product.recoProductWhy?.value || '',
            variant: {
              id: node.merchandise.id!,
              title: node.merchandise.title!,
              url: `/products/${node.merchandise.product.handle}`,
              handle: node.merchandise.product.handle,
              price: {
                amount: Number(node.merchandise.priceV2.amount),
                currencyCode: node.merchandise.priceV2.currencyCode!,
              },
              image: {
                src: node.merchandise?.image?.transformedSrc!,
                alt: node.merchandise?.image?.altText || '',
              },
            },
          }

          return item
        })

        const discountCodes: DiscountCodes[] = cart.discountCodes.map(d => {
          return {
            code: d.code,
            applicable: d.applicable,
          }
        })

        return {
          id: cart.id,
          items,
          discountCodes,
          url: cart.checkoutUrl,
          subtotal: {
            amount: Number(cart.estimatedCost.subtotalAmount.amount),
            currencyCode: cart.estimatedCost.subtotalAmount.currencyCode,
          },
          tax: {
            amount: Number(cart.estimatedCost.totalTaxAmount?.amount),
            currencyCode:
              cart.estimatedCost.totalTaxAmount?.currencyCode ??
              CurrencyCode['Eur'],
          },
          total: {
            amount: Number(cart.estimatedCost.totalAmount.amount),
            currencyCode: cart.estimatedCost.totalAmount.currencyCode,
          },
        }
      }
    } else {
      return {
        id: '',
        items: [],
        discountCodes: [],
        url: '',
        subtotal: {
          amount: 0,
          currencyCode: CurrencyCode['Eur'],
        },
        tax: {
          amount: 0,
          currencyCode: CurrencyCode['Eur'],
        },
        total: {
          amount: 0,
          currencyCode: CurrencyCode['Eur'],
        },
      }
    }
  }

  export async function getItemCount(): Promise<number> {
    let count: number = 0
    const checkoutId = Cookies.get(CHECKOUT_IDD)

    if (checkoutId) {
      const { cart } = await ShopifyService.getCartItemCount({
        checkoutId,
      })

      if (cart?.__typename === 'Cart') {
        cart?.lines.edges.forEach(lineItem => {
          count += lineItem.node.quantity
        })
      }
    }

    return count
  }

  export async function addItem(lineItem: CartLineInput): Promise<void> {
    try {
      const checkoutId = Cookies.get(CHECKOUT_IDD)

      await ShopifyService.cartLinesAdd({
        cartId: checkoutId,
        lines: lineItem,
      }).then(async res => {
        // create cart if checkoutId expired
        if (!res?.cartLinesAdd?.cart) {
          const { cartCreate } = await ShopifyService.cartCreate({
            input: { lines: [lineItem] },
          })

          Cookies.set(CHECKOUT_IDD, cartCreate?.cart?.id!, {
            expires: 7,
          })
        }
        const cart = await getCart()

        if (!cart) {
          const { cartCreate } = await ShopifyService.cartCreate({
            input: { lines: [lineItem] },
          })

          Cookies.set(CHECKOUT_IDD, cartCreate?.cart?.id!, {
            expires: 7,
          })
        }
      })
    } catch (error) {
      const { cartCreate } = await ShopifyService.cartCreate({
        input: { lines: [lineItem] },
      })

      Cookies.set(CHECKOUT_IDD, cartCreate?.cart?.id!, {
        expires: 7,
      })
    }
  }

  export async function cartCreate(lineItem: CartLineInput[]): Promise<void> {
    const { cartCreate } = await ShopifyService.cartCreate({
      input: { lines: lineItem },
    })

    Cookies.set(CHECKOUT_IDD, cartCreate?.cart?.id!, {
      expires: 7,
    })
  }

  export async function updateItem(
    lineItem: CartLineUpdateInput
  ): Promise<void> {
    const checkoutId = Cookies.get(CHECKOUT_IDD)
    await ShopifyService.cartLinesUpdate({
      cartId: checkoutId,
      lines: lineItem,
    })
  }

  export async function removeItem(lineItemId: string): Promise<void> {
    const checkoutId = Cookies.get(CHECKOUT_IDD)
    await ShopifyService.cartLinesRemove({
      cartId: checkoutId,
      lineIds: lineItemId,
    })
  }

  export async function discountCodesUpdate(
    discountCodes: string
  ): Promise<void> {
    try {
      const checkoutId = Cookies.get(CHECKOUT_IDD)

      await ShopifyService.cartDiscountCodesUpdate({
        cartId: checkoutId,
        discountCodes,
      }).then(async res => {
        // create cart if checkoutId expired
        if (!res?.cartDiscountCodesUpdate?.cart) {
          const { cartCreate } = await ShopifyService.cartCreate({
            input: { lines: [], discountCodes: [discountCodes] },
          })
          Cookies.set(CHECKOUT_IDD, cartCreate?.cart?.id!, {
            expires: 7,
          })
        }
      })
    } catch (error) {
      const { cartCreate } = await ShopifyService.cartCreate({
        input: { lines: [], discountCodes: [discountCodes] },
      })
      Cookies.set(CHECKOUT_IDD, cartCreate?.cart?.id!, {
        expires: 7,
      })
    }
  }
}
