import { Button } from '../style'
import { FooterProps } from './props'

const Footer = ({
  isChecked,
  handleChange,
  setChecked,
  sendEmail,
  emails,
}: FooterProps) => {
  return (
    <>
      <Button
        style={{
          background: !emails?.length ? '#dcdcdc' : '',
          border: !emails?.length ? '1px solid #dcdcdc' : '',
          cursor: !emails?.length ? 'not-allowed' : '',
        }}
        isChecked={isChecked}
        onClick={sendEmail}
      >
        Envoyer la recommandation
      </Button>
    </>
  )
}

export default Footer
