import styled from 'styled-components'
import { Color, MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 80px 40px;
  ${MediaQuery.XL_AND_DOWN} {
    width: 100%;
  }
  ${MediaQuery.L_AND_DOWN} {
    padding: 40px 20px;
  }
`

export const Title = styled.h3`
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  width: 400px;
  margin-bottom: 40px;

  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
`

export const Content = styled.div``
export const Green = styled.span`
  color: ${Color.DEEP_SEA};
`
export const Item = styled.div`
  margin: 0 auto !important;
  display: flex !important;
  align-items: center !important;
  width: 600px !important;

  ${MediaQuery.M_AND_DOWN} {
    padding: 0 !important;
    width: 100% !important;
  }
`
export const Img = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin-right: 30px;
  ${MediaQuery.M_AND_DOWN} {
    width: 40px;
    height: 40px;
  }
`
export const TextContent = styled.div`
  font-size: 14px;
  line-height: 25px;
`
export const QuoteImg = styled.img`
  width: 30px;
  object-fit: contain;
`
export const Text = styled.div`
  margin-bottom: 15px;
`
export const Footer = styled.div`
  display: flex !important;
  align-items: center !important;
  font-size: 10px !important;
`
export const Line = styled.div`
  width: 40px;
  border-radius: 10px;
  border: 1px solid ${Color.DEEP_SEA};
  margin-right: 20px;
`
export const Name = styled.div``
export const StarsIcon = styled.div`
  display: flex !important;
  align-items: center !important;
  margin-left: 20px;
`

export const ImgContainer = styled.div`
  width: 120px;
  min-width: 120px;
  height: 120px;
  min-height: 120px;
  background-color: #9ff6d3;
  border-radius: 50%;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${MediaQuery.M_AND_DOWN} {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
`
export const ImgText = styled.span`
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
  letter-spacing: -0.18px;
  ${MediaQuery.M_AND_DOWN} {
    font-size: 16px;
  }
`
