import styled from 'styled-components/macro'

import { ViewMode, MediaQuery } from '../../utils/constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  background-color: #fdfcfe;
  ${MediaQuery.M_AND_DOWN} {
    padding: 0rem;
  }
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
`
export const ProductsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'row' : 'column'};
  padding: 20px 0;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 40px 0 20px 0;
  position: sticky;
  top: 84px;
  background-color: white;
  padding: 20px;
  z-index: 1;
  border-radius: 14px;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const InputContainer = styled.div`
  width: 250px;
  max-width: 250px;
`
export const ButtonWrapper = styled.div`
  justify-content: flex-end;
  padding-left: 2px;
  width: 300px;
  max-width: 300px;
`

export const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`
