import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px;
  background-color: #fdfcfe;
  min-height: calc(100vh - 75px);
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
`
