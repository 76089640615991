/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PrescriptionType = 'LEGACY' | 'COMPLIMENT' | 'MARKETPLACE'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrescriptionType = {
  LEGACY: 'LEGACY' as PrescriptionType,
  COMPLIMENT: 'COMPLIMENT' as PrescriptionType,
  MARKETPLACE: 'MARKETPLACE' as PrescriptionType,
}
