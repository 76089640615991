import { Form, FooterTextContent } from './style'
import { Button, Field } from '../../../ui'
import useSignInUserLogic from '../logic'

const LoginForm = () => {
  //Logic
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isAllLoading,
    resetPassword,
  } = useSignInUserLogic()

  //Return
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label='Email'
        name='email'
        id='login-email'
        type='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        isDisabled={isAllLoading}
        placeholder='Insérez votre adresse mail'
      />
      {errors.email && errors.touched}
      <Field
        label='Mot de passe'
        id='password'
        name='password'
        type='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        isDisabled={isAllLoading}
        placeholder='Mot de passe'
      />
      <FooterTextContent onClick={() => resetPassword(values.email)}>
        Mot de passe oublié ?
      </FooterTextContent>
      <Button type='submit' isLoading={isAllLoading}>
        Se connecter
      </Button>
    </Form>
  )
}

export default LoginForm
