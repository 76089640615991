import { Helmet } from 'react-helmet'
import { Spinner } from '../../../components/ui'
import { Container, Content } from './style'
import Layout from '../../../components/layout/default'
import { useGetMe } from '../../../utils/hooks/getMe'
import { HeaderLinks } from '../../../components/section/header/props'
import PrescriptionHeader from './header'
import PrescriptionBody from './body'
import { useRouteMatch } from 'react-router'
import { useGetPrescriptionById } from '../../../services/api/prescriptions/prescriptions'

const PrescriptionPage = () => {
  // Attributes
  const { params }: any = useRouteMatch()
  const prescriptionId = Number(params.id)
  const { data: prescriber } = useGetMe()

  const {
    data: prescription,
    isLoading,
    refetch,
  } = useGetPrescriptionById(prescriber?.id, prescriptionId)

  // Render
  return (
    <Layout active={HeaderLinks.Recommandations}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Recommandation </title>
      </Helmet>
      <Container>
        <Content>
          {isLoading ? (
            <Spinner fullScreen={undefined} />
          ) : (
            <>
              <PrescriptionHeader
                prescription={prescription}
                patient={prescription?.patient}
              />
              <PrescriptionBody
                title={`Détails de la recommandation de ${prescription?.patient?.firstName}`}
                prescription={prescription}
                refetchPrescription={refetch}
              />
            </>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default PrescriptionPage
