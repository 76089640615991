import styled, { keyframes } from 'styled-components/macro'

import { Icon } from '../icon/style'

const spin = keyframes`
  100% { 
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  cursor: pointer;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${({ borderRadius }) => borderRadius};

  &:hover {
    background-color: ${({ onHoverBgColor }) => onHoverBgColor};
    color: ${({ onHoverColor }) => onHoverColor};
  }

  > ${Icon} {
    animation: ${spin} 1s linear infinite;
  }
`
