/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type GetProtocolsType = 'SELF' | 'COMPLIMENT'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProtocolsType = {
  SELF: 'SELF' as GetProtocolsType,
  COMPLIMENT: 'COMPLIMENT' as GetProtocolsType,
}
