import styled from 'styled-components/macro'

export const Select = styled.div`
  border: ${props =>
    props.selected ? '1px solid #3a845e' : '1px solid  #fcf2ef;'};
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
`

export const SelectTitle = styled.h4`
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  font-weight: 500;
  span {
    display: flex;
    margin-top: 2px;
    margin-right: 5px;
    color: ${props => (props.selected ? '#3a845e' : '')};
  }
`

export const SelectText = styled.p`
  font-size: 13px;
`
