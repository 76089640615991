import ToggleButton from 'react-toggle-button'
import {
  selectDefaultDuration,
  getMonthlyPrice,
  removeVariantsToCart,
} from '../../utils/helpers'
import { useCartLinesRemove } from '../../utils/hooks'

const ToggleBoxPoduct = ({
  addBoxProduct,
  setToggle,
  toggle,
  addToCart,
  cart,
  boxProductId,
}) => {
  // Attributes
  const { cartLinesRemove } = useCartLinesRemove()

  // Fonctions
  const onToggle = value => {
    if (!value)
      addBoxProduct(
        addToCart,
        cart,
        selectDefaultDuration(cart),
        getMonthlyPrice(cart)
      )
    if (value) removeVariantsToCart(cartLinesRemove, [boxProductId], cart?.id)
    setToggle(!value)
  }

  // Render
  return (
    <ToggleButton
      inactiveLabel={''}
      activeLabel={''}
      value={toggle}
      onToggle={value => {
        onToggle(value)
      }}
    />
  )
}

export default ToggleBoxPoduct
