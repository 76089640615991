import { Role } from '../../services/api/types'
import { useGetUserBySub } from '../../services/api/users/users'

export const useGetMe = () => {
  //Attributes
  const role = localStorage.getItem('role') as Role | null

  //Query
  const { data, refetch, isLoading } = useGetUserBySub(
    { role: role as Role },
    {
      query: {
        enabled: !!role,
      },
    }
  )

  //Render
  return { data, refetch, isLoading }
}
