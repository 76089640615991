import { useState } from 'react'
import { Dialog } from '../../ui'
import { IconCross } from '../../ui/icon/icons'
import { PrescriberContactDialogProps } from './props'
import {
  Button,
  CloseButton,
  CloseButtonContainer,
  Container,
  HeaderContainer,
  Title,
  Textarea,
} from './style'

import toast from 'react-hot-toast'
import axios from 'axios'

const PrescriberContactDialog = ({
  isOpen,
  onClose,
  prescriberFirstName,
  prescriberLastName,
  prescriberEmail,
  prescriberId,
  prescriptionId,
  patientFirstName,
  patientLastName,
  patientEmail,
  customMessage,
}: PrescriberContactDialogProps) => {
  // Attributes
  const [message, setMessage] = useState(
    `Bonjour ${prescriberFirstName} ${prescriberLastName}, Je suis arrivé au terme de mon traitement de compléments alimentaires. J’aimerais avoir votre conseil pour la suite. ${patientFirstName} ${patientLastName}`
  )

  // Functions
  const onSubmit = () => {
    const data = {
      prescriber_firstname: prescriberFirstName,
      prescriber_lastname: prescriberLastName,
      prescriber_email: prescriberEmail,
      prescriber_id: prescriberId,
      prescription_id: prescriptionId,
      patient_firstname: patientFirstName,
      patient_lastname: patientLastName,
      patient_email: patientEmail,
      message: customMessage,
    }

    try {
      toast
        .promise(
          axios.post(
            'https://hook.eu1.make.com/959vco7kwbiua9ez2w6o2jgwj5zw4b7n',
            data
          ),
          {
            loading: "En cours d'envoie...",
            success: 'Le message a bien été transmis',
            error: 'Oops! Une erreur est survenue.',
          },
          {
            duration: 3000,
          }
        )
        .then(() => {
          onClose()
        })
    } catch (error: any) {
      toast.error(error)
    }
  }

  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      type='minimalCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={onClose}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <HeaderContainer>
          <Title>Demander conseil à votre thérapeute </Title>
          <Textarea
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </HeaderContainer>
        <Button onClick={onSubmit}>Contacter votre thérapeute</Button>
      </Container>
    </Dialog>
  )
}

export default PrescriberContactDialog
