/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type RewardType = 'MONTHLY' | 'GLOBAL'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RewardType = {
  MONTHLY: 'MONTHLY' as RewardType,
  GLOBAL: 'GLOBAL' as RewardType,
}
