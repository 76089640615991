import styled from 'styled-components'
import { Font } from '../../utils/constants'
import { keyframes } from 'styled-components'

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`

export const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transform: translateX(${props => (props.isOpen ? '0' : '100%')});
  animation: ${props => (props.isOpen ? slideIn : slideOut)} 0.5s forwards;
`

export const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.0625rem solid var(--Stroke, #e9eaf0);
  padding: 1.25rem 1.5rem;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`

export const MenuItem = styled.div`
  color: var(--Text-400, #393360);
  text-align: flex-start;
  font-family: ${Font.BASIS_GROTESQUE_PRO};
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
`

export const LogoutButton = styled.button`
  background-color: #3a845e;
  color: white;
  border-radius: 0.375rem;
  cursor: pointer;
  margin-top: auto;
  padding: 1.5rem 1.75rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
`

export const WrapperItems = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.75rem;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  height: 100%;
`
