import styled from 'styled-components/macro'

export const Title = styled.h1`
  font-size: 22px;
  margin-bottom: 20px;
`

export const Hr = styled.hr`
  margin: 20px 0;
  width: 100%;
  border-color: #ddd;
  border-style: none;
  border-bottom-style: solid;
`

export const Form = styled.form``
export const InputsFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : '100%')};
`
export const Label = styled.label`
  font-size: 12px;
  opacity: 0.6;
  margin-bottom: 10px;
`
export const Input = styled.input`
  border: 1px solid #e9eaf0;
  border-radius: 8px;
  padding: 12px;
  outline: none;
`

export const Button = styled.button`
  cursor: pointer;
  border: none;
  color: white;
  background: #3a845e;
  border-radius: 8px;
  padding: 12px;
`
