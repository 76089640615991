const IconGrid = () => (
  <svg viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='9' height='9' rx='1' fill='grey' />
    <rect y='12' width='9' height='9' rx='1' fill='grey' />
    <rect x='12' width='9' height='9' rx='1' fill='grey' />
    <rect x='12' y='12' width='9' height='9' rx='1' fill='grey' />
  </svg>
)

export default IconGrid
