import * as Yup from 'yup'

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 charactères requis')
    .required('Champs obligatoire'),
  email: Yup.string().email('Email invalide').required('Champs obligatoire'),
})

export default LoginSchema
