import {
  DialogContainerSelectTake,
  TakeChoice,
  TakeChoices,
  Title,
  SubTitle,
  HeaderDialog,
  PillIcon,
} from './style'
import toast from 'react-hot-toast'
import { Dialog, Button } from '../../../ui'
import { GiPill } from 'react-icons/gi'
import { TakeType } from '../../../../utils/constants'
import { useGetProductByIdOrHandle } from '../../../../services/api/product/product'
import { PackagingType } from '../../../../services/api/types'

const DialogSelectTake = ({
  selectedHandle,
  handle,
  setSelectedHandle,
  takes,
  setTakeChoices,
  getVariantName,
  recommendedDuration,
  takeChoices,
  setSearch,
  addProduct,
  quantity = 1,
}) => {
  const { data: product } = useGetProductByIdOrHandle(
    selectedHandle,
    {
      packagingType: PackagingType.PERSONNALISED,
    },
    {
      query: { enabled: !!selectedHandle },
    }
  )

  return (
    <Dialog
      isOpen={selectedHandle === handle}
      onClose={() => setSelectedHandle('')}
      styleContainer={{ zIndex: 20 }}
    >
      <DialogContainerSelectTake>
        {product && (
          <HeaderDialog>
            <img src={product?.coverImage} alt={product?.name} />
            <Title>Ajouter “ {product?.name} ” à la cure ?</Title>
          </HeaderDialog>
        )}

        <SubTitle>Séléctionner la prise</SubTitle>
        <TakeChoices>
          {takes
            ?.filter(take => take.type !== TakeType.STAND_ALONE)
            .map((take, index) => (
              <TakeChoice
                key={index}
                onClick={() => {
                  setTakeChoices(index)
                }}
              >
                <PillIcon selected={takeChoices === index}>
                  <GiPill />
                </PillIcon>
                {take.type === TakeType.STAND_ALONE
                  ? 'Produit hors cure'
                  : take.label || `Prise #${index + 1}`}
              </TakeChoice>
            ))}
        </TakeChoices>
        <Button
          style={{ width: '100%' }}
          appearance='primary'
          onClick={() => {
            const variantId = product?.variants?.find(
              variant =>
                variant.title ===
                getVariantName(recommendedDuration, product.handle)
            )?.id

            toast.promise(
              addProduct(
                variantId,
                takeChoices + '',
                product?.handle,
                takes[takeChoices]?.label
                  ? takes[takeChoices]?.label
                  : `PRISE #${takeChoices}`,
                quantity
              ),
              {
                loading: 'en cours...',
                success: 'Le produit a bien été ajouté',
                error: 'Oops! Une erreur est survenue.',
              },
              {
                duration: 3000,
              }
            )
            setSelectedHandle('')
            setSearch('')
          }}
          iconLeft={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
        >
          Continuer
        </Button>
      </DialogContainerSelectTake>
    </Dialog>
  )
}

export default DialogSelectTake
