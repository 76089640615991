import { Link } from 'react-router-dom'

import {
  Status,
  FlexTd,
  MoreIcon,
  DropdownContent,
  DropdownContentItem,
  DropdownContentItemIcon,
  DropdownContentItemText,
  Container,
  Line,
  SeeIcon,
  FlexContainer,
} from './style'

import { Dropdown } from '../../../ui'

import { AiOutlineEye } from 'react-icons/ai'
import { IoIosMore } from 'react-icons/io'

import { format } from 'date-fns'

interface PatientPrescriptionProps {
  createdAt?: string
  description?: string
  id?: string
  modifiedAt?: string
  title?: string
  key?: string
}

const PatientPrescription = ({
  createdAt,
  description,
  id,
  modifiedAt,
  title,
}: PatientPrescriptionProps) => {
  // Render
  return (
    <Container>
      <FlexTd>
        <Line>
          {title?.substring(0, 36)}
          {title?.length > 36 && '...'}
        </Line>
      </FlexTd>
      <FlexTd>
        <Line>
          {createdAt ? format(new Date(createdAt), 'dd/MM/yyyy') : '/'}
        </Line>
      </FlexTd>
      <FlexTd>
        <Line>
          {modifiedAt ? format(new Date(modifiedAt), 'dd/MM/yyyy') : '/'}
        </Line>
      </FlexTd>
      <Status></Status>
      <FlexTd>
        <FlexContainer>
          <Link to={`/note/${id}`}>
            <SeeIcon>
              <AiOutlineEye />
            </SeeIcon>
          </Link>
          <Dropdown
            content={
              <DropdownContent>
                <Link to={`/note/${id}`}>
                  <DropdownContentItem>
                    <DropdownContentItemIcon>
                      <AiOutlineEye />
                    </DropdownContentItemIcon>
                    <DropdownContentItemText>
                      Voir la note
                    </DropdownContentItemText>
                  </DropdownContentItem>
                </Link>
              </DropdownContent>
            }
          >
            <MoreIcon>
              <IoIosMore />
            </MoreIcon>
          </Dropdown>
        </FlexContainer>
      </FlexTd>
    </Container>
  )
}

export default PatientPrescription
