import PropTypes from 'prop-types'

import {
  Section,
  Header,
  Container,
  Card,
  CardHeader,
  CardContent,
  P,
} from './style'
import { Button } from '../../ui'
import { useGetMe } from '../../../utils/hooks/getMe'

const IntegrationsContainer = ({ headerCta }) => {
  const { data: prescriber } = useGetMe()

  return (
    <Section>
      <Header>{headerCta}</Header>
      <Container>
        <Card>
          <CardHeader>Calendly</CardHeader>
          <CardContent>
            <P>
              Connectez votre compte Calendly à Simplycure afin de pouvoir gérer
              vos meetings et client directement depuis Simplycure.
            </P>
            <Button
              appearance={'calendly'}
              onClick={() =>
                (window.location.href = `https://auth.calendly.com/oauth/authorize?client_id=${process.env.REACT_APP_CALENDLY_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_CALENDLY_REDIRECT_URI}&response_type=code`)
              }
              isDisabled={prescriber?.calendlyCode !== ''}
            >
              Connecter
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Section>
  )
}

IntegrationsContainer.defaultProps = {
  headerCta: undefined,
}

IntegrationsContainer.propTypes = {
  headerCta: PropTypes.node,
}

export default IntegrationsContainer
