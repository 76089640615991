import { PriceWraper } from '../style'
import { DiscountPriceProps } from './props'

const DiscountPrice = ({
  discountPercentage,
  originalPrice,
  discountedPrice,
  hideDiscountPercentage = false,
  from,
}: DiscountPriceProps) => {
  return (
    <div>
      {discountPercentage > 0 ? (
        <PriceWraper
          style={{
            color: '#3A845E',
            flexDirection: from === 'recommendationPage' ? 'column' : '',
            alignItems: from === 'recommendationPage' ? 'flex-end' : '',
          }}
        >
          <span
            style={{
              textDecoration: 'line-through',
              marginRight: hideDiscountPercentage ? '.1875rem' : '.25rem',
              fontSize: '.875rem',
              fontStyle: 'normal',
              fontWeight: 500,
              fontFamily: 'Poppins',
              color: '#080038',
            }}
          >
            {originalPrice}€
          </span>{' '}
          {discountedPrice?.toFixed(2)}€{' '}
        </PriceWraper>
      ) : (
        <>{originalPrice}€</>
      )}
    </div>
  )
}

export default DiscountPrice
