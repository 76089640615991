import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  background-color: #fdfcfe;
  .k-pdf-export p {
    font-size: 13px;
  }
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
`

export const ExportPdf = styled.div`
  margin-top: 40px;
  margin-right: 80px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  a {
    cursor: pointer;
    background-color: ${Color.DEEP_SEA};
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 30px;
    text-decoration: none;
  }
`

export const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px 0;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  width: 100%;

  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  color: #080038;
  color: ${Color.DEEP_SEA};
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 18px;
  }
`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const QuestionTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: #080038;
  opacity: 0.5;
`

export const QuestionResponse = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #080038;
  margin-left: 12px;
`

export const ScoresContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 18px;
  }
`

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 4px;
  }
`

export const ScoreTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: #080038;
`

export const ScoreValue = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: #080038;
`
