import styled from 'styled-components/macro'

import { ViewMode } from '../../utils/constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 0 1.25rem;
  background-color: #fdfcfe;
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  margin-top: 0.9375rem;
`

export const ProductsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ $mode }) =>
    $mode === ViewMode.GRID ? 'row' : 'column'};
  padding: 1.25rem 0;
  flex-wrap: wrap;
`

export const Button = styled.button`
  cursor: pointer;
  color: white;
  border-radius: 0.375rem;
  border: 0.0625rem solid transparent;
  padding: 0.5625rem 0.8125rem;
  font-family: basic grotesque, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  background: #3a845e;
  white-space: nowrap;
`
