import styled from 'styled-components/macro'

export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const QuantityHandleWrapper = styled.div`
  margin-right: 20px;
  font-size: 10px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  background: #ffffff;
  border: 1px solid #e7e9f4;
  border-radius: 100px;
`

export const QuantityHandleNumber = styled.div`
  margin: 0 8px;
`

export const QuantityHandlerMinus = styled.div`
  cursor: pointer;
  padding: 0 10px;
  font-size: 14px;
`
export const QuantityHandlerPlus = styled.div`
  cursor: pointer;
  padding: 0 8px;
  font-size: 14px;
`

export const QuantityChoiceWrapper = styled.div``

export const QuantityChoice = styled.span`
  cursor: pointer;
  margin-right: 10px;
  padding: 6px 10px;
  background-color: ${props => (props.selected ? '#65B293' : '#fff')};
  color: ${props => (props.selected ? '#fff' : '#8f95b2')};
  border: 1px solid #e7e9f4;
  border-radius: 100px;
  font-size: 10px;
`
