import PasswordColor from './password-color'
import {
  FlexContainer,
  ParagraphMedium,
  ParagraphStrong,
  ParagraphWeak,
} from './style'

const PasswordLevel = ({ name }) => {
  //Attributes
  const renderswitch = () => {
    switch (true) {
      case (name.length >= 1 && name.length <= 4) ||
        (name.length >= 1 &&
          !(
            /[A-Z]/.test(name) ||
            /[0-9]/.test(name) ||
            //eslint-disable-next-line
            /[!@#\$%\^&\*]/.test(name)
          )):
        return [
          <PasswordColor color='#F46363' />,
          <PasswordColor color='#E7E9F4' />,
          <PasswordColor color='#E7E9F4' />,
          <ParagraphWeak>Weak</ParagraphWeak>,
        ]
      case name.length > 4 &&
        (/[A-Z]/.test(name) || /[0-9]/.test(name)) &&
        !/[!@#\$%\^&\*]/.test(name): //eslint-disable-line
        return [
          <PasswordColor color='#FF9138' />,
          <PasswordColor color='#FF9138' />,
          <PasswordColor color='#E7E9F4' />,
          <ParagraphMedium>Medium</ParagraphMedium>,
        ]
      case name.length > 4 &&
        /[!@#\$%\^&\*]/.test(name) && //eslint-disable-line
        (/[A-Z]/.test(name) || /[0-9]/.test(name) || /[a-z]/.test(name)):
        return [
          <PasswordColor color='#2ED477' />,
          <PasswordColor color='#2ED477' />,
          <PasswordColor color='#2ED477' />,
          <ParagraphStrong>Strong</ParagraphStrong>,
        ]
      default:
        return [
          <PasswordColor color='#E7E9F4' />,
          <PasswordColor color='#E7E9F4' />,
          <PasswordColor color='#E7E9F4' />,
        ]
    }
  }

  return <FlexContainer>{renderswitch()}</FlexContainer>
}
export default PasswordLevel
