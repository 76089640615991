export const patients = [
  {
    background: '#d9efea',
    text: '#219D6B',
  },
  {
    background: '#fcf2ef',
    text: '#F46363',
  },
  {
    background: '#d2dcff',
    text: '#1D4FFF',
  },
  {
    background: '#ffeffd',
    text: '#ED3DDB',
  },
]
