import { GraphQLClient } from 'graphql-request'
import { getSdk } from './shopify/generated/graphql'

const endpoint = process.env.SHOPIFY_STOREFRONT_API_ENDPOINT!
const accessToken = process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN!

export const client = new GraphQLClient(
  'https://boelsvictor.myshopify.com/api/2022-01/graphql.json',
  {
    headers: {
      'X-Shopify-Storefront-Access-Token': '594045d74ada8a3bc198b58eb2e767fb',
    },
  }
)

export const ShopifyService = getSdk(client)

export * from './shopify/generated/graphql'
