import { Container, NoFoundWrapper, ContainerList } from './style'
import { PageType, ViewMode } from '../../../../utils/constants'
import { Spinner } from '../../../ui'
import { NoFound } from '../../../ui'
import ICategoryListProps from './props'
import ProductPreviewCard from '../../product-preview-card/ProductPreviewCard'
import { useState } from 'react'

const CategoriesList = ({
  allFavoriteLists,
  isAllFavoriteListsLoading,
  favoriteLists,
  favoriteListId,
  isFavoriteListsLoading,
  setIsInnerDialogOpen,
}: ICategoryListProps) => {
  // Attributes
  const [productDetailsModal, setProductDetailsModal] = useState<string>('')
  const allFavoriteListsHandles = allFavoriteLists?.map(i => i.handle)
  const listToDisplay = favoriteListId ? favoriteLists : allFavoriteLists

  // Render
  if (isFavoriteListsLoading || isAllFavoriteListsLoading) {
    return <Spinner fullScreen={false} />
  }
  return (
    <Container>
      <ContainerList>
        {listToDisplay?.map(product => {
          return (
            <ProductPreviewCard
              key={product.handle}
              mode={ViewMode.GRID}
              hasDetailModalOpen={productDetailsModal === product.handle}
              closeDetailModal={() => setProductDetailsModal('')}
              product={product}
              from={PageType.FAVORITE}
              enableFavorite={true}
              doNotAllowProductToBeAdded={false}
              bookmarks={allFavoriteListsHandles?.includes(product.handle)}
              isPatientCatalog={true}
              allowProductUrl={true}
              setIsInnerDialogOpen={setIsInnerDialogOpen}
            />
          )
        })}
      </ContainerList>
      <NoFoundWrapper>
        {!listToDisplay?.length && (
          <NoFound title='Aucun favoris' descritpion='' />
        )}
      </NoFoundWrapper>
    </Container>
  )
}

export default CategoriesList
