import { useEffect } from 'react'
import Layout from '../../../components/layout/note'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

import { Container, NoteTakingWrapper, PatientInfoWrapper } from './style'

import { useGetNoteById } from '../../../services/api/notes/notes'

import { useUpdatePatientNote } from '../../../services/api/notes/notes'
import CreateUpdateNote from '../../../components/notes/create-update-note'
import PatientInfo from '../../../components/notes/patient-info'

import { NoteState } from '../props'
import { useImmer } from 'use-immer'

const NotePage = ({ match }) => {
  // Attributes
  const noteId = match.params.id
  const { data: noteData, isSuccess } = useGetNoteById(noteId)

  const [note, setNotes] = useImmer<NoteState>({
    title: '',
    description: '',
    consultationDate: new Date(),
    patientId: null,
  })
  const { mutateAsync: updatePatientNote } = useUpdatePatientNote({
    mutation: {
      onSuccess: () => {
        toast('La note a bien été mise à jour')
      },
      onError: () => {
        toast.error('Oops! Une erreur est survenue.')
      },
    },
  })

  const updateNote = () => {
    if (!note?.patientId) {
      toast.error('Veuillez selectionner un client')
      return
    }
    updatePatientNote({
      patientId: note?.patientId,
      noteId: noteId,
      data: {
        title: note?.title,
        description: note?.description,
        consultedAt: note?.consultationDate?.toISOString(),
      },
    })
  }

  useEffect(() => {
    isSuccess &&
      setNotes((state: NoteState) => {
        state.title = noteData.title
        state.description = noteData.description
        state.consultationDate = new Date(noteData.consultedAt)
        state.patientId = noteData.patientId
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  // Render
  return (
    <Layout onClick={updateNote}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Note </title>
      </Helmet>
      <Container>
        <NoteTakingWrapper>
          {isSuccess && (
            <CreateUpdateNote
              title={note.title}
              description={note.description}
              consultationDate={note.consultationDate}
              patientId={note.patientId}
              setNotes={setNotes}
              allowPatientDeletion={false}
            />
          )}
        </NoteTakingWrapper>
        <PatientInfoWrapper>
          {isSuccess && note?.patientId && (
            <PatientInfo patientId={note.patientId} />
          )}
        </PatientInfoWrapper>
      </Container>
    </Layout>
  )
}

export default NotePage
