import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { getTakeFormatName } from '../../utils/helpers/functions'

const BookletProProducts = ({
  name,
  brandName,
  description,
  price,
  percentage,
  takeFormat,
  takeTotalQuantity,
}) => {
  function calculateDiscountedPrice(originalPrice, discountPercentage) {
    return (originalPrice - originalPrice * (discountPercentage / 100)).toFixed(
      2
    )
  }

  // Render
  return (
    <View style={styles.wrapper}>
      <View
        style={{
          minWidth: '32%',
          maxWidth: '32%',
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: '15%',
          }}
        >
          <Text style={styles.input}></Text>
        </View>
        <Text
          style={{
            width: '80%',
          }}
        >
          {name}{' '}
          <Text
            style={{
              fontSize: '5px',
            }}
          >
            {takeTotalQuantity}{' '}
            {getTakeFormatName(takeTotalQuantity, takeFormat)}
          </Text>
        </Text>
      </View>
      <View
        style={{
          minWidth: '15%',
          maxWidth: '15%',
        }}
      >
        <Text>{brandName}</Text>
      </View>
      <View
        style={{
          minWidth: '7%',
          maxWidth: '7%',
        }}
      >
        <Text
          style={{
            textDecoration: percentage > 0 ? 'line-through' : 'none',
          }}
        >
          {price}€
        </Text>
      </View>

      {percentage > 0 && (
        <View
          style={{
            minWidth: '10%',
            maxWidth: '10%',
          }}
        >
          <Text>{calculateDiscountedPrice(price, percentage)}€</Text>
        </View>
      )}

      <View
        style={{
          minWidth: percentage > 0 ? '30%' : '40%',
          maxWidth: percentage > 0 ? '30%' : '40%',
          margin: '0 10px',
        }}
      >
        <Text
          style={{
            marginTop: '7px',
            borderBottom: '1px dotted black',
          }}
        ></Text>
      </View>
    </View>
  )
}

export default BookletProProducts

// Create styles
const styles = StyleSheet.create({
  wrapper: {
    borderRadius: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '3px 10px',
    margin: '0px 20px 0px 15px',
    minHeight: 'auto',
    fontSize: '7px',
  },
  input: {
    width: '8px',
    height: '8px',
    border: '1px solid black',
  },
})
