import styled from 'styled-components/macro'
import {
  MediaQuery
} from '../../../utils/constants'

export const Container = styled.div `
  display: flex;
  transform: ${({ isVisible }) => (isVisible ? 'none' : 'translateX(-625rem)')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
`

export const Overlay = styled.div `
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
`

export const BaseOuter = styled.div `
  display: flex;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: auto;
`

export const OuterDialog = styled(BaseOuter)
`
  padding: 12.5rem 0;
  position: absolute;
  justify-content: center;
  align-items: flex-start;
`

export const OuterCenterDialog = styled(BaseOuter)
`
  position: absolute;
  justify-content: center;
  align-items: center;
`

export const OuterMinimalDialog = styled(BaseOuter)
`
  padding: 12.5rem 0;
  position: absolute;
  justify-content: center;
  align-items: flex-start;
`

export const OuterMinimalCenterDialog = styled(BaseOuter)
`
  position: absolute;
  justify-content: center;
  align-items: center;
`

export const OuterLargeDialog = styled(OuterDialog)
`
  padding: 3.125rem 0;
`

export const OuterAsideDialog = styled(BaseOuter)
`
  justify-content: flex-end;
`

export const OuterFullDialog = styled(BaseOuter)
``

export const BaseDialog = styled.div `
  z-index: 10;
  background-color: white;
  box-shadow: 0 0.9375rem 2.8125rem 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  cursor: default;
`

export const DefaultDialog = styled(BaseDialog)
`
  width: 37.5rem;
  border-radius: 0.9375rem;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? 'translateX(0) scale(1)' : 'translateX(-0.625rem) scale(0.9)'};
  ${MediaQuery.M_AND_DOWN} {
    width: 90%;
  }
`

export const DefaultCenterDialog = styled(BaseDialog)
`
  width: 37.5rem;
  border-radius: 0.9375rem;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? 'translateX(0) scale(1)' : 'translateX(-0.625rem) scale(0.9)'};
  ${MediaQuery.M_AND_DOWN} {
    width: 90%;
  }
`

export const MinimalDialog = styled(BaseDialog)
`
  width: 26.1875rem;
  border-radius: 0.9375rem;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? 'translateX(0) scale(1)' : 'translateX(-0.625rem) scale(0.9)'};
  ${MediaQuery.M_AND_DOWN} {
    width: 90%;
  }
`

export const MinimalCenterDialog = styled(BaseDialog)
`
  width: 26.1875rem;
  border-radius: 0.9375rem;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? 'translateX(0) scale(1)' : 'translateX(-0.625rem) scale(0.9)'};
  ${MediaQuery.M_AND_DOWN} {
    width: 90%;
  }
`

export const LargeDialog = styled(DefaultDialog)
`
  width: 80vw;
  max-width: 62.375rem;
  ${MediaQuery.M_AND_DOWN} {
    width: 90%;
    /* padding: 2.5rem 1.25rem; */
  }
`

export const AsideDialog = styled(BaseDialog)
`
  z-index: 10;
  width: ${({ width }) => (width ? width : '70%')};
  min-height: 100vh;
  max-height: 100vh;
  padding: ${({ padding, noPadding }) =>
    padding ? padding : noPadding ? '0' : '2.1875rem 4.0625rem'};
  background-color: ${({ noPadding }) => (noPadding ? '#fdfcfe' : '')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  overflow: auto;
  transform: ${({ isVisible }) =>
    isVisible ? 'translateX(0)' : 'translateX(100%)'};
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
`

export const FullDialog = styled(DefaultDialog)
`
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  padding: 0;
`

export const NavDialog = styled(DefaultDialog)
`
  position: absolute;
  top: 0;
  right: 0;
  width: 40vw;
  height: 100vh;
  border-radius: 0;
  padding: 0;
`

export const OuterNavDialog = styled(OuterDialog)
``