import { Dialog } from '../../../../ui'
import { IconCross } from '../../../../ui/icon/icons'
import { RewardDialogProps } from './props'
import {
  Button,
  CloseButton,
  CloseButtonContainer,
  Container,
  ContentContainer,
  Section,
  SectionDescription,
  SectionTitle,
  Ul,
} from './style'

const TabDialog = ({ isOpen, onClose }: RewardDialogProps) => {
  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      type='minimalCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={onClose}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <ContentContainer>
          <Section>
            <SectionTitle>
              Ce tableau reprend l’historique de tous vos échanges de points.
            </SectionTitle>
            <Ul>
              <SectionDescription>
                Historique: Ancien échange de points
              </SectionDescription>
              <SectionDescription>
                Bon d’achat: Echange de points contre un bon d’achat pour des
                produits, qu’il ait été utilisé ou non.
              </SectionDescription>
              <SectionDescription>
                Paiement sur votre compte: Echange de points contre un bon
                d’achat, qu’il ait été utilisé ou non.
              </SectionDescription>
              <SectionDescription>
                Paiement à Simplycure: Echange de points contre un virement sur
                votre compte en banque.
              </SectionDescription>
            </Ul>
          </Section>
        </ContentContainer>
        <Button onClick={onClose}>J'ai compris</Button>
      </Container>
    </Dialog>
  )
}

export default TabDialog
