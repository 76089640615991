import React from 'react'
import { SpinnerContainer, Spinner } from './style'

const SpinnerEl = ({ fullScreen }) => (
  <SpinnerContainer fullScreen={fullScreen}>
    <Spinner>
      <div></div>
      <div></div>
    </Spinner>
  </SpinnerContainer>
)

export default SpinnerEl
