import { useState } from 'react'
import { Container, Content, ButtonGroup, Button } from './style'
import Formations from './news'
import Webinars from './webinars'

const NewsFeed = () => {
  const [showComponent, setShowComponent] = useState('formations')

  const handleButtonClick = component => {
    setShowComponent(component)
  }

  return (
    <Container>
      <h3>Formations & webinars</h3>
      <ButtonGroup>
        <Button
          onClick={() => handleButtonClick('formations')}
          selected={showComponent === 'formations'}
        >
          Webinars récents
        </Button>
        <Button
          // onClick={() => handleButtonClick('webinars')}
          onClick={() =>
            window.open(
              'https://www.bigmarker.com/communities/simplycure/conferences',
              '_blank'
            )
          }
          selected={showComponent === 'webinars'}
        >
          Prochains webinars
        </Button>
      </ButtonGroup>
      <Content>
        {showComponent === 'formations' && <Formations />}
        {showComponent === 'webinars' && <Webinars />}
      </Content>
    </Container>
  )
}
export default NewsFeed
