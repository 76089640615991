import { Container, Item, ItemIconWrapper, ItemText } from './style'
import { BsTruck } from 'react-icons/bs'
import { CiCreditCard1 } from 'react-icons/ci'
import { AiOutlineUser } from 'react-icons/ai'

const Arguments = () => {
  return (
    <Container>
      <Item>
        <ItemIconWrapper>
          <BsTruck />
        </ItemIconWrapper>
        <ItemText>
          Livraison à domicile{' '}
          <strong style={{ fontWeight: 500 }}>dans les 48 à 72h</strong>
        </ItemText>
      </Item>
      <Item>
        <ItemIconWrapper>
          <CiCreditCard1 />
        </ItemIconWrapper>
        <ItemText>
          <strong style={{ fontWeight: 500 }}>Satisfait ou remboursé,</strong>{' '}
          retours gratuits
        </ItemText>
      </Item>
      <Item>
        <ItemIconWrapper>
          <img
            alt='flags'
            src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/Flags.png?alt=media&token=1a63c28c-6d5d-4ef5-8551-653373d8e7da'
          />
        </ItemIconWrapper>
        <ItemText>
          Des formules issues de laboratoires{' '}
          <strong style={{ fontWeight: 500 }}>français et belges.</strong>
        </ItemText>
      </Item>
      <Item>
        <ItemIconWrapper>
          <AiOutlineUser />
        </ItemIconWrapper>
        <ItemText>
          Une question? Notre service client est{' '}
          <strong>disponible 24/7</strong>
        </ItemText>
      </Item>
    </Container>
  )
}

export default Arguments
