import styled from 'styled-components/macro'

export const Container = styled.td`
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 12px;
  }
  a {
    text-decoration: none;
    color: inherit;
    transition: all 0.2s;
    &:hover {
      opacity: 0.6;
    }
  }
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: #fcf2ef;
  border-radius: 7.04px;
`

export const FullNameAbbr = styled.p`
  color: #d7603a;
  line-height: 15px;
  font-weight: 700;
  font-size: 12.78px;
  text-transform: uppercase;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const FullName = styled.p`
  font-size: 12px;
  color: #080038;
`

export const Email = styled.p`
  font-size: 12px;
  color: #ceccd7;
`
