import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Container, Content } from './style'
import { fetchAll } from '../../store/order'
import Layout from '../../components/layout/default'
import OrderTable from '../../components/order/table'
import PageTitle from '../../components/section/page-title'

const OrdersPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAll())
  }, [dispatch])

  return (
    <Layout>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Commandes</title>
      </Helmet>
      <PageTitle title='Commandes' />
      <Container>
        <Content>
          <OrderTable />
        </Content>
      </Container>
    </Layout>
  )
}

export default OrdersPage
