import styled from 'styled-components/macro'

export const Graph = styled.div`
  min-width: 378px;
  max-width: 378px;
  border: 1px solid #e9eaf0;
  border-radius: 10px;
  padding: 13px;
`
export const GraphLegend = styled.ol`
  display: flex;
  font-size: 12px;
  opacity: 0.6;
  list-style: none;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`
