import { QueryClient } from 'react-query'

export function refetchMe(queryClient: QueryClient) {
  queryClient.invalidateQueries([`/me`])
}

export function refetchFavorite(
  queryClient: QueryClient,
  favoriteListId: string
) {
  queryClient.invalidateQueries([`/favorite-lists/${favoriteListId}/items`])
}
export function refetchAllFavorite(
  queryClient: QueryClient,
  authUserId: number
) {
  queryClient.invalidateQueries([
    `/prescribers/${authUserId}/favorite-lists/items`,
  ])
}
export function refetchFavoriteListItemByProductId(
  queryClient: QueryClient,
  authUserId: number,
  productId: string
) {
  queryClient.invalidateQueries([
    `/prescribers/${authUserId}/products/${productId}/favorite-lists`,
  ])
}

export function refetchaFvoriteListsByProductId(
  queryClient: QueryClient,
  authUserId: number,
  productId: string
) {
  queryClient.invalidateQueries([
    `/prescribers/${authUserId}/products/${productId}/favorite-lists`,
  ])
}

export function refetchPrescription(
  queryClient: QueryClient,
  authUserId: number,
  prescriptionId: number
) {
  queryClient.invalidateQueries([
    `/prescribers/${authUserId}/prescriptions/${prescriptionId}`,
  ])
}

export function refetchPatient(queryClient: QueryClient, authUserId: number) {
  queryClient.invalidateQueries([
    `/prescribers/${authUserId}/patients`,
    { size: 1000 },
  ])
}

export function refetchPatientItem(
  queryClient: QueryClient,
  authUserId: number,
  patientId: number
) {
  queryClient.invalidateQueries([
    `/prescribers/${authUserId}/patients/${patientId}`,
  ])
}

export function refetchPatientByEmail(
  queryClient: QueryClient,
  authUserId: number,
  email: string
) {
  queryClient.invalidateQueries([
    `/prescribers/${authUserId}/patients`,
    { q: email },
    { size: 1000 },
  ])
}
