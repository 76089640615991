import { PricingProps } from './props'
import {
  Wrapper,
  Price,
  BarredPrice,
  PriceWrapper,
  DiscountPercentage,
} from './style'

const Pricing = ({ price, quantity, discountPercentage }: PricingProps) => {
  const discountedPrice = (price - price * (discountPercentage / 100)).toFixed(
    2
  )

  return (
    <Wrapper>
      {discountPercentage ? (
        <PriceWrapper>
          <Price>{discountedPrice}€</Price>
          <BarredPrice>{price?.toFixed(2)}€</BarredPrice>
          <DiscountPercentage>(-{discountPercentage}%)</DiscountPercentage>
        </PriceWrapper>
      ) : (
        <Price>{price?.toFixed(2)}€</Price>
      )}
    </Wrapper>
  )
}

export default Pricing
