import { Container, Label, Input, Textarea, InputWrapper } from './style'

const index = ({ setName, name, setDescription, description }) => {
  return (
    <Container>
      <InputWrapper>
        <Label>Nom du protocole*</Label>
        <Input
          placeholder='Stress*'
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </InputWrapper>

      <InputWrapper>
        <Label>Description</Label>
        <Textarea
          placeholder='Description'
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </InputWrapper>
    </Container>
  )
}

export default index
