import styled from 'styled-components/macro'

export const Card = styled.div`
  border-radius: var(--Spacing-System-radius-4, 0.25rem);
  border: 0.0625rem solid rgba(230, 229, 235, 0.65);
  background: var(--White-500, #fff);
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
`
export const TopContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BottomContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`

export const PrescriptorLabel = styled.div`
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
export const ProductLabel = styled.div`
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const ProductDiv = styled.div`
  margin-top: 0.5rem;
`

export const Separator = styled.div`
  border: 0.0625rem solid rgba(230, 229, 235, 0.65);
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const Product = styled.p`
  color: #6b6688;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const Title = styled.p`
  color: #080038;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
`

export const UnderTitle = styled.p`
  color: #6b6688;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
`

export const BoughtStatusContainer = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem;
  text-align: center;
  background-color: #3a845e;
`
export const SeenStatusContainer = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem;
  text-align: center;
  background-color: #d5f6e4;
`
export const NotSeenStatusContainer = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem;
  text-align: center;
  background-color: #fcf2c9;
`

export const BoughtStatus = styled.p`
  color: #f1fcf6;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const SeenStatus = styled.p`
  color: #080038;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const NotSeenStatus = styled.p`
  color: #923b3b;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const ImagePrescriptor = styled.img`
  width: 1rem;
  height: 1rem;
  border-radius: 0.1875rem;
`

export const PrescriptorLabelTitle = styled.p`
  color: #080038;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
`
export const PrescriptorTitle = styled.p`
  color: #080038;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* .75rem */
`

export const PrescriptorEmail = styled.p`
  color: #6b6688;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* .75rem */
`
export const ImageProfile = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 0.4402rem;
`
export const ImageProduct = styled.img`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.1875rem;
`
export const PrescriptorTop = styled.div`
  display: flex;
`

export const PrescriptorBody = styled.div`
  align-items: center;
  margin-top: 0.75rem;
  display: flex;
  gap: 0.5rem;
`

export const PriceText = styled.p`
  color: #080038;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const PromoText = styled.p`
  color: #3a845e;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PromoContainer = styled.div`
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
  justify-content: space-between;
`
