import { Route } from 'react-router-dom'
import { Spinner } from '../components/ui'
import { useEffect } from 'react'
import NakedLayout from '../components/layout/naked'
import { useHistory } from 'react-router'
import { useGetUserBySub } from '../services/api/users/users'
import { Role } from '../services/api/types'

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  // Attributes
  const userRole = localStorage.getItem('role') as Role
  const renderRoute = props => <Component {...props} />

  const { data: prescriber, isLoading: isPrescriberLoading } = useGetUserBySub(
    { role: userRole },
    {
      query: {
        enabled: !!userRole,
        retry: 1,
      },
    }
  )
  const history = useHistory()

  useEffect(() => {
    if (
      !isPrescriberLoading &&
      !prescriber?.isOnboarded &&
      localStorage.getItem('role') === Role.PRESCRIBER
    )
      history.push('/onboarding/profile')
  }, [history, prescriber])

  useEffect(() => {
    if (!userRole) history.replace('/portail')
  }, [userRole])

  if (isPrescriberLoading) {
    return (
      <NakedLayout>
        <Spinner fullScreen={undefined} />
      </NakedLayout>
    )
  }

  return <Route {...rest} render={renderRoute} />
}

export default AuthenticatedRoute
