import styled from 'styled-components/macro'

export const Title = styled.h1`
  font-size: 22px;
  margin-bottom: 20px;
`

export const Hr = styled.hr`
  margin: 20px 0;
  width: 100%;
  border-color: #ddd;
  border-style: none;
  border-bottom-style: solid;
`
