import Pager from '../../../pager'
import { GainsTableHeaderProps } from './props'
import { Container, Subtitle, Title, TitleContainer } from './style'

const GainsTableHeader = ({
  title,
  subtitle,
  page,
  nbPages,
  size,
  totalAmount,
  setPage,
}: GainsTableHeaderProps) => {
  // Render
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
      <Pager
        width='12.25rem'
        height='2.75rem'
        page={page}
        nbPages={nbPages}
        size={size}
        totalAmount={totalAmount}
        setPage={setPage}
      />
    </Container>
  )
}

export default GainsTableHeader
