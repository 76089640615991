import styled from 'styled-components/macro'

export const CollapseButton = styled.button`
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  color: #6b6688;
  border: none;
  background-color: transparent;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CollapseContent = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
  p {
    margin: 10px 0;
  }

  ul {
    margin: 5px 0 5px 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;

    span {
      display: inline;
      color: green;
    }
  }
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
`

export const Accordion = styled.h3`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;

  span {
    width: 24px;
    height: 24px;
    background: #f8f9fc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
