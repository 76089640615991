import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  actions: {
    edit: { ...INITIAL_ACTION_STATE },
    add_discounts: { ...INITIAL_ACTION_STATE },
    calendly_code: { ...INITIAL_ACTION_STATE },
  },
}

export default state
