import SummaryOrder from '../../../../../components/reco/summary-order'
import { PrescriptionResumeProps } from './props'
import { Wrapper } from './style'
import Customers from '../../../../../components/reco/customers'
import RecoLink from '../../../../../components/reco/reco-link'
import { useGetPrescriptionStatus } from '../../../../../services/api/prescriptions/prescriptions'
import { useGetMe } from '../../../../../utils/hooks/getMe'

const PrescriptionResume = ({
  setFieldValue,
  draft,
  isFormFilesOpened,
  setIsFormFilesOpened,
  isAmountsLoading,
  onPrescriptionSubmitted,
  onPrescriptionSent,
  amounts,
  selectedPatient,
  setIsShareDialogOpen,
  setDraftChanged,
  productsWithTakes,
  prescription,
  prescriptionSelectedPatient,
  setPrescriptionSelectedPatient,
  saveBookletPdf,
}: PrescriptionResumeProps) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const { data: lastStatus } = useGetPrescriptionStatus(
    prescription?.id,
    prescription?.statuses?.[0]?.id
  )

  return (
    <Wrapper>
      <Customers
        selectedPatient={prescriptionSelectedPatient}
        setSelectedPatient={setPrescriptionSelectedPatient}
        patientId={draft?.patientId}
        setDraftChanged={setDraftChanged}
        prescription={prescription}
      />

      <SummaryOrder
        isAmountsLoading={isAmountsLoading}
        onPrescriptionSubmitted={onPrescriptionSubmitted}
        onPrescriptionSent={onPrescriptionSent}
        amounts={amounts}
        firstName={selectedPatient?.firstName}
        lastName={selectedPatient?.lastName}
        authUserId={prescriber?.id}
        draft={draft}
        setIsShareDialogOpen={setIsShareDialogOpen}
        productsWithTakes={productsWithTakes}
        prescription={prescription}
        patient={prescriptionSelectedPatient}
        setDraftChanged={setDraftChanged}
        saveBookletPdf={saveBookletPdf}
        lastStatus={lastStatus}
        statuses={prescription?.statuses}
      />

      <RecoLink
        onPrescriptionSubmitted={onPrescriptionSubmitted}
        draft={draft}
        setDraftChanged={setDraftChanged}
      />
    </Wrapper>
  )
}

export default PrescriptionResume
