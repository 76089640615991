import { useGetProductByIdOrHandle } from '../../../../services/api/product/product'
import ProductDialog from '../../product-dialog'
import { ProductPreviewCardDetailsDialogProps } from './props'

const ProductPreviewCardDetailsDialog = ({
  handle,
  onClose,
  isOpen,
  onAddInCure,
  numbreOfProducts,
  setNumberOfProducts,
  onClickAddProduct,
  doNotAllowProductToBeAdded,
  isPatientCatalog,
  allowProductUrl,
  bookmarks,
  enableFavorite,
}: ProductPreviewCardDetailsDialogProps) => {
  // Queries
  const { data: product, isLoading } = useGetProductByIdOrHandle(handle, {
    // query: { enabled: !!handle },
  })

  // Render
  return (
    <ProductDialog
      isOpen={isOpen}
      onClose={onClose}
      product={product}
      onAddInCure={onAddInCure ? () => onAddInCure(product) : undefined}
      numbreOfProducts={numbreOfProducts}
      setNumberOfProducts={setNumberOfProducts}
      onClickAddProduct={onClickAddProduct}
      isProductLoading={isLoading}
      doNotAllowProductToBeAdded={doNotAllowProductToBeAdded}
      isPatientCatalog={isPatientCatalog}
      allowProductUrl={allowProductUrl}
      bookmarks={bookmarks}
      enableFavorite={enableFavorite}
    />
  )
}

export default ProductPreviewCardDetailsDialog
