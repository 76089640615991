import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 36px;
  }
`

export const TabContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 24px;
  }
`

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;
  gap: 4px;
  height: 58px;
  background: #ffffff;
  /* Elevation/Light/Bottom/01 */
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
`

export const SearchBarLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  min-height: 34px;
`

export const SearchBarVerticalLine = styled.div`
  width: 25px;
  height: 0px;
  /* Text/200 */
  border: 0.5px solid #9c99af;
  transform: rotate(-90deg);
`

export const SearchBarButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 135px;
  height: 42px;
  background: #3d825b;
  border-radius: 6px;
  cursor: pointer;
`

export const SearchBarButtonText = styled.div`
  font-family: 'System';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  /* White/500 */
  color: #ffffff;
`

export const SearchBarInput = styled.input`
  font-family: 'System';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* Text/200 */
  color: #9c99af;
  outline: none;
  border: none;
  width: 300px;
  ::placeholder {
    font-family: 'System';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* Text/200 */
    color: #9c99af;
    outline: none;
    border: none;
  }
`

export const TabItem = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? '#080038' : '#8f95b2')};
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? ` &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 20px;
    background-color: #89b59e;
  }`
      : undefined}
`

export const PlaceholderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProtocolsListContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`
