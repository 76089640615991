import { useState } from 'react'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

import {
  Container,
  Content,
  Nav,
  NavItem,
  MoreIcon,
  DropdownContent,
  DropdownContentItem,
  DropdownContentItemIcon,
  DropdownContentItemText,
  MoreIconWrapper,
} from './style'
import { Dropdown } from '../../../components/ui'

import { IoIosMore } from 'react-icons/io'
import { AiOutlineDelete } from 'react-icons/ai'

import { useDeletePatient } from '../../../services/api/patients/patients'
import { useGetMe } from '../../../utils/hooks/getMe'
import {
  useGetPatient,
  useGetPatientQuizzes,
  useGetPatientNotes,
  useGetPatientPrescriptions,
} from '../../../services/api/patients/patients'
import Layout from '../../../components/layout/default'
import OverviewPatient from '../../../components/patient/overview-patient'
import PrescriptionTable from '../../../components/patient/prescription-table'
import NotesTable from '../../../components/patient/notes-table'
import { useOnboardingProfileCheck } from '../../../utils/hooks'
import { HeaderLinks } from '../../../components/section/header/props'

type NavState = 'overview' | 'prescriptions' | 'notes' | 'quizzes'

const PatientPage = ({ history, match }) => {
  useOnboardingProfileCheck()

  // Attributes
  const [nav, setNav] = useState<NavState>('overview')
  const { data: prescriber } = useGetMe()

  const { data: patient } = useGetPatient(
    prescriber?.id,
    Number(match.params.id)
  )
  const { data: patientQuizzes } = useGetPatientQuizzes(patient?.id)
  const { data: patientNotes } = useGetPatientNotes(patient?.id)
  const { data: patientPrescriptions } = useGetPatientPrescriptions(patient?.id)

  const { mutateAsync: deletePatient } = useDeletePatient({
    mutation: {
      onSuccess: () => {
        toast.success('Le patient a été supprimé avec succès !')
        history.push('/patients')
      },
      onError: () => {
        toast.error(
          'Il y a eu une erreur dans la supression du patient, merci de réessayer.'
        )
      },
    },
  })

  // Render
  return (
    <Layout active={HeaderLinks.Patients}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Patient</title>
      </Helmet>
      <Container>
        <Content>
          <Nav>
            <NavItem
              onClick={() => setNav('overview')}
              active={nav === 'overview'}
            >
              Overview
            </NavItem>
            <NavItem
              onClick={() => setNav('prescriptions')}
              active={nav === 'prescriptions'}
            >
              Recommandations ({patientPrescriptions?.totalAmount})
            </NavItem>
            <NavItem onClick={() => setNav('notes')} active={nav === 'notes'}>
              Notes ({patientNotes?.totalAmount})
            </NavItem>

            <MoreIconWrapper>
              <Dropdown
                content={
                  <DropdownContent>
                    <DropdownContentItem
                      onClick={() => {
                        deletePatient({
                          prescriberId: prescriber?.id,
                          patientId: Number(match.params.id),
                        })
                      }}
                    >
                      <DropdownContentItemIcon>
                        <AiOutlineDelete />
                      </DropdownContentItemIcon>
                      <DropdownContentItemText>
                        Supprimer le profil
                      </DropdownContentItemText>
                    </DropdownContentItem>
                  </DropdownContent>
                }
              >
                <MoreIcon>
                  <IoIosMore />
                </MoreIcon>
              </Dropdown>
            </MoreIconWrapper>
          </Nav>
          {nav === 'overview' && <OverviewPatient patient={patient} />}
          {nav === 'prescriptions' && <PrescriptionTable />}
          {nav === 'notes' && <NotesTable />}
        </Content>
      </Container>
    </Layout>
  )
}

export default PatientPage
