/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type RemunerationType =
  | 'LEGACY'
  | 'PAYMENT_SENT'
  | 'PAYMENT_RECEIVED'
  | 'GIFT_CARD_PRO'
  | 'GIFT_CARD_PATIENT'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RemunerationType = {
  LEGACY: 'LEGACY' as RemunerationType,
  PAYMENT_SENT: 'PAYMENT_SENT' as RemunerationType,
  PAYMENT_RECEIVED: 'PAYMENT_RECEIVED' as RemunerationType,
  GIFT_CARD_PRO: 'GIFT_CARD_PRO' as RemunerationType,
  GIFT_CARD_PATIENT: 'GIFT_CARD_PATIENT' as RemunerationType,
}
