import { useParams } from 'react-router-dom'
import { useState } from 'react'
import {
  Container,
  Flex,
  Box,
  BoxLine,
  BoxLineItem,
  BoxLineItemIcon,
  Initial,
  BoxText,
  FullName,
  EditIcon,
} from './style'
import { AiOutlineEdit } from 'react-icons/ai'

import { useGetPatient } from '../../../services/api/patients/patients'
import { useGetMe } from '../../../utils/hooks/getMe'

import { AiOutlineMail } from 'react-icons/ai'
import { FiPhone } from 'react-icons/fi'
import { GiPill } from 'react-icons/gi'
import { AiOutlineMan, AiOutlineWoman } from 'react-icons/ai'
import { BsArrowClockwise } from 'react-icons/bs'
import EditPatientDialog from './edit-patient-dialog'

const IntroPatient = () => {
  // Attributes
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const match = useParams()
  const { data: prescriber } = useGetMe()

  const { data: patient } = useGetPatient(prescriber?.id, Number(match.id), {
    query: { enabled: !!prescriber?.id && !!match?.id },
  })

  // Functions
  function getSexIcon(sexe: string) {
    if (sexe === 'WOMAN') return <AiOutlineWoman />
    else return <AiOutlineMan />
  }

  function getSexText(sexe: string) {
    if (sexe === 'WOMAN') return 'Féminin'
    else return 'Masculin'
  }

  // Render
  return (
    <Container>
      <Flex>
        <Box>
          <BoxLine>
            <BoxLineItem>
              <Initial>
                {patient?.firstName?.[0] ?? 'J'}
                {patient?.lastName?.[0] ?? 'E'}
              </Initial>
              <FullName>
                {patient?.firstName ?? 'John'} {patient?.lastName ?? 'Exemple'}
              </FullName>
            </BoxLineItem>
          </BoxLine>
          <BoxLine>
            <BoxLineItem>
              <BoxLineItemIcon>
                <AiOutlineMail />
              </BoxLineItemIcon>
              <BoxText>{patient?.email ?? 'john@exemple.com'}</BoxText>
            </BoxLineItem>
            <BoxLineItem>
              <BoxLineItemIcon>
                <FiPhone />
              </BoxLineItemIcon>
              <BoxText>{patient?.phone ?? '/'}</BoxText>
            </BoxLineItem>
          </BoxLine>
          <EditIcon onClick={() => setIsDialogOpen(true)}>
            <AiOutlineEdit />
          </EditIcon>
        </Box>

        <Box>
          <BoxLine>
            <BoxLineItem>
              <BoxLineItemIcon bg='#E0F0E9'>
                <BsArrowClockwise />
              </BoxLineItemIcon>
              <BoxText>
                Age: {patient?.age ? `${patient?.age} ans` : '/'}
              </BoxText>
            </BoxLineItem>
            <BoxLineItem>
              <BoxLineItemIcon bg='#E0F0E9'>
                {getSexIcon(patient?.sexe)}
              </BoxLineItemIcon>
              <BoxText>
                Sexe: {patient?.sexe ? getSexText(patient?.sexe) : '/'}
              </BoxText>
            </BoxLineItem>
          </BoxLine>
          <BoxLine>
            <BoxLineItem>
              <BoxLineItemIcon bg='#E0F0E9'>
                <GiPill />
              </BoxLineItemIcon>
              <BoxText>
                Notes:
                {patient?.pathology
                  ? `
                  ${patient?.pathology?.substring(0, 36)}
                  ${patient?.pathology?.length > 36 ? '...' : ''}
                  `
                  : '/'}
              </BoxText>
            </BoxLineItem>
          </BoxLine>
          <EditIcon onClick={() => setIsDialogOpen(true)}>
            <AiOutlineEdit />
          </EditIcon>
        </Box>
      </Flex>

      <EditPatientDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        firstName={patient?.firstName}
        age={patient?.age}
        lastName={patient?.lastName}
        sexe={patient?.sexe}
        pathology={patient?.pathology}
        phone={patient?.phone}
        email={patient?.email}
        patientId={patient?.id}
      />
    </Container>
  )
}

export default IntroPatient
