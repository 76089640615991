/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type { QuizDTO, CreateQuizDTO, SaveQuizDTO } from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const createPatientQuiz = (
  patientId: number,
  createQuizDTO: CreateQuizDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<QuizDTO>(
    {
      url: `/patients/${patientId}/quizzes`,
      method: 'post',
      data: createQuizDTO,
    },
    options
  )
}

export const useCreatePatientQuiz = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createPatientQuiz>,
    TError,
    { patientId: number; data: CreateQuizDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createPatientQuiz>,
    { patientId: number; data: CreateQuizDTO }
  > = props => {
    const { patientId, data } = props || {}

    return createPatientQuiz(patientId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createPatientQuiz>,
    TError,
    { patientId: number; data: CreateQuizDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const getQuizById = (
  quizId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<QuizDTO>(
    { url: `/quizzes/${quizId}`, method: 'get' },
    options
  )
}

export const getGetQuizByIdQueryKey = (quizId: string) => [`/quizzes/${quizId}`]

export const useGetQuizById = <
  TData = AsyncReturnType<typeof getQuizById>,
  TError = unknown
>(
  quizId: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getQuizById>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetQuizByIdQueryKey(quizId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getQuizById>> = () =>
    getQuizById(quizId, requestOptions)

  const query = useQuery<AsyncReturnType<typeof getQuizById>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!quizId, ...queryOptions }
  )

  return {
    queryKey,
    ...query,
  }
}

export const saveQuiz = (
  patientId: number,
  quizId: string,
  saveQuizDTO: SaveQuizDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/patients/${patientId}/quizzes/${quizId}`,
      method: 'put',
      data: saveQuizDTO,
    },
    options
  )
}

export const useSaveQuiz = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof saveQuiz>,
    TError,
    { patientId: number; quizId: string; data: SaveQuizDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof saveQuiz>,
    { patientId: number; quizId: string; data: SaveQuizDTO }
  > = props => {
    const { patientId, quizId, data } = props || {}

    return saveQuiz(patientId, quizId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof saveQuiz>,
    TError,
    { patientId: number; quizId: string; data: SaveQuizDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const getQuizzesByPrescriberId = (
  prescriberId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<QuizDTO[]>(
    { url: `/prescribers/${prescriberId}/quizzes`, method: 'get' },
    options
  )
}

export const getGetQuizzesByPrescriberIdQueryKey = (prescriberId: number) => [
  `/prescribers/${prescriberId}/quizzes`,
]

export const useGetQuizzesByPrescriberId = <
  TData = AsyncReturnType<typeof getQuizzesByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getQuizzesByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizzesByPrescriberIdQueryKey(prescriberId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getQuizzesByPrescriberId>
  > = () => getQuizzesByPrescriberId(prescriberId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getQuizzesByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}
