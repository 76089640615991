import Cookies from 'js-cookie'
import { Role } from '../../../../services/api/types'
import { useGetMe } from '../../../../utils/hooks/getMe'
import { useLocation } from 'react-router'
import { useGetDiscountWithPrescriberByCode } from '../../../../services/api/discount/discount'
import { leastVariantsPrice } from '../../../../utils/data'
import { PageType } from '../../../../utils/constants'
import { IUseProductQuantityLogicProps } from './props'
import { IUseProductQuantityLogicReturn } from './type'

const useProductQuantityLogic = ({
  allowingAddProduct,
  from,
  numberOfCapsule,
  onClick,
  setNumberOfCapsule,
  product,
}: IUseProductQuantityLogicProps): IUseProductQuantityLogicReturn => {
  //Attributes
  const location = useLocation()
  const { data: prescriber } = useGetMe()
  const role = localStorage.getItem('role') as Role
  const savedPromoCode = Cookies.get('promoCode')
  const criteriaToDisplay =
    !allowingAddProduct &&
    from !== PageType.CART &&
    from !== PageType.PRODUCT &&
    onClick &&
    product?.active

  //Queries
  const { data: discountWithPrescriberByCode } =
    useGetDiscountWithPrescriberByCode(savedPromoCode?.toUpperCase(), {
      query: {
        enabled: !!savedPromoCode,
      },
    })

  const discountPercentage =
    location.pathname === '/patient-catalog'
      ? discountWithPrescriberByCode?.percentage
      : prescriber?.activeDiscount?.percentage || 0

  const originalPrice =
    leastVariantsPrice(product?.variants)?.price * numberOfCapsule

  const discountedPrice =
    originalPrice -
    (originalPrice * displayDiscountPercentageDisplayed(product?.brand?.name)) /
      100

  //Functions
  function handleOnMinusClick() {
    if (numberOfCapsule !== 1) {
      setNumberOfCapsule(Number(numberOfCapsule) - 1)
    }
  }

  function handleOnPlusClick() {
    setNumberOfCapsule(Number(numberOfCapsule) + 1)
  }

  function addQuantity() {
    onClick(numberOfCapsule)
  }

  function displayDiscountPercentageDisplayed(brand: string) {
    if (!brand) return 0
    if (brand === 'NHCO') {
      return 0
    }
    if (brand === 'NR&D (Nutri-logics)') {
      return discountPercentage > 10 ? 10 : discountPercentage
    }

    return discountPercentage
  }

  //Render
  return {
    addQuantity,
    displayDiscountPercentageDisplayed,
    criteriaToDisplay,
    discountedPrice,
    handleOnMinusClick,
    handleOnPlusClick,
    originalPrice,
    role,
  }
}

export default useProductQuantityLogic
