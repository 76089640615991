import Constants from './constants'
import axios from 'axios'

export const fetchOrdersLoading = () => ({
  type: Constants.FETCH_ORDERS_LOADING,
})
export const fetchOrdersSuccess = payload => ({
  type: Constants.FETCH_ORDERS_SUCCESS,
  payload,
})
export const fetchOrdersFailed = error => ({
  type: Constants.FETCH_ORDERS_FAILED,
  error,
})

export const fetchAll = () => dispatch => {
  dispatch(fetchOrdersLoading())
  axios
    .post(
      'https://europe-west1-compliment-backoffice.cloudfunctions.net/shopifyGraphqlQuery',
      {
        query: `{
            orders (first: 10, query: "TanguyTestPro", reverse: true) {
             edges {
                cursor
              node {
                id
                lineItems (first:5) {
                  edges {
                    node {
                      title
                    }
                  }
                }
              }
            }
            }
      }`,
        variables: {},
      }
    )
    .then(res => {
      dispatch(fetchOrdersSuccess(res))
    })
    .catch(e => {
      dispatch(fetchOrdersFailed(e))
    })
}
