import { TakeChoice, DialogContainer } from './style'
import { PrescriptionTakeType } from '../../../../../services/api/types'
import { Title } from '../../style'
import { Dialog } from '../../../../ui'
import { DialogSelectTakeProps } from './props'

const DialogSelectTake = ({
  selectedHandle,
  setSelectedHandle,
  takes,
  addProduct,
  product,
  quantity,
}: DialogSelectTakeProps) => {
  return (
    <Dialog
      isOpen={selectedHandle === product.handle}
      onClose={() => setSelectedHandle('')}
      styleContainer={undefined}
    >
      <DialogContainer>
        <Title>Pour quelle prise voulez vous ajouter {product?.name}?</Title>
        {takes
          ?.filter(take => take.type === PrescriptionTakeType.IN_CURE)
          .map((take, index) => (
            <TakeChoice
              key={index}
              onClick={() => addProduct(product, index, quantity)}
            >
              {take.label ?? `Prise #${index + 1}`}
            </TakeChoice>
          ))}
      </DialogContainer>
    </Dialog>
  )
}

export default DialogSelectTake
