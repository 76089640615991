import { View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import Trustpilot from '../../assets/images/trustpilot.png'
import Logodoc from '../../assets/images/logodoc.png'

const BookletHeader = ({
  percentage,
  discountCode,
  qrCodeUrl,
  prescriberFullName,
}) => {
  return (
    <View style={styles.header}>
      <View style={styles.section}>
        <View>
          <Image src={Logodoc} style={styles.logo} />
          <Image src={Trustpilot} style={styles.trustpilot} />
          <Text style={styles.title}>Bon de commande</Text>
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.instructionTitle}>Comment procéder?</Text>
          <View style={styles.instruction1}>
            <View style={styles.bullet}>
              <Text style={styles.bulletText}>1</Text>
            </View>

            <Text>
              Visiter la page{' '}
              <Text
                style={{
                  fontWeight: 'black',
                  textDecoration: 'underline',
                }}
              >
                simplycure.com/patient
              </Text>{' '}
              ou scanner le QR code.
            </Text>
          </View>
          <View style={styles.instruction1}>
            <View style={styles.bullet}>
              <Text style={styles.bulletText}>2</Text>
            </View>
            <Text>
              Ajouter les produits recommandés par{' '}
              <Text
                style={{
                  color: '#5EB498',
                }}
              >
                {prescriberFullName}
              </Text>{' '}
              à votre panier.
            </Text>
          </View>

          <View style={styles.instruction}>
            <View style={styles.bullet}>
              <Text style={styles.bulletText}>3</Text>
            </View>
            <Text>
              Passer commande en entrant le code{' '}
              <Text
                style={{
                  color: '#5EB498',
                  fontSize: '10px',
                }}
              >
                {discountCode}
              </Text>{' '}
              <Text
                style={{
                  fontSize: '8px',
                }}
              >
                {percentage > 0 && `(${percentage}%)`}{' '}
              </Text>{' '}
              et soyez livré dans les 72h
            </Text>
          </View>

          <Image
            source={qrCodeUrl}
            style={{
              width: '77px',
              position: 'absolute',
              top: '5px',
              right: '5px',
            }}
          />
        </View>
      </View>
    </View>
  )
}

export default BookletHeader

const styles = StyleSheet.create({
  bullet: {
    fontSize: '10px',
    minWidth: '18px',
    minHeight: '18px',
    maxWidth: '18px',
    mawHeight: '18px',
    border: '1px solid black',
    borderRadius: '100%',
    position: 'relative',
    marginRight: '5px',
  },
  bulletText: {
    position: 'absolute',
    top: '13%',
    left: '34%',
  },
  recommendationBoxTitle: {
    fontSize: '14px', // Large text for the header
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  recommendationBox: {
    border: '1px solid #e7e9f4',
    padding: '10px 10px 7px 10px',
    borderRadius: '10px',
  },
  recommendationTitle: {
    fontSize: '14px', // Slightly smaller text for the recommendation title
    fontWeight: 'bold',
  },
  recommendationText: {
    fontSize: '9px', // Regular text size for the body of the recommendation
    lineHeight: '1.5',
  },

  section: {
    padding: '0px 10px 20px 10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
    fontSize: '16px',
    marginTop: '12px',
    color: '#5DB498',
  },
  contentContainer: {
    padding: 10,
    backgroundColor: '#EFF6F2',
    borderRadius: '4px',
    width: '330px',
  },

  trustpilotRating: {
    fontSize: 14,
    marginBottom: 5,
  },
  instructionTitle: {
    fontWeight: 'extrabold',
    fontSize: '13px',
    marginBottom: '13px',
  },
  instruction1: {
    fontSize: '9px',
    marginBottom: '7px',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '105px',
  },
  instruction: {
    fontSize: '9px',
    marginBottom: '7px',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '25px',
  },

  logo: {
    width: '150px',
    marginBottom: '12px',
  },
  trustpilot: {
    width: '170px',
  },
  header: {
    position: 'relative',
    width: '100%',
    marginBottom: '0px',
    padding: '20px 20px 0px 20px',
  },
  ImageHeader: {
    position: 'absolute',
    objectFit: 'cover',
    top: 0,
    maxHeight: '100px',
    minHeight: '100px',
    maxWidth: '82vw',
    minWidth: '82vw',
    margin: '0 auto',
  },
  headerPresciberName: {
    fontSize: '16px',
    marginTop: '15px',
    marginBottom: '40px',
  },
  headerText: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
  messageWrapper: {
    fontSize: '11px',
    marginBottom: '20px',
  },
  by: {
    marginBottom: '10px',
    fontSize: '11px',
  },
  presciberName: {
    color: '#03885b',
    marginLeft: '10px',
  },
  firstPage: {
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: '20mm',
    boxSizing: 'border-box',
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100vw',
    padding: '20px',
    borderTop: '1px solid #eee',
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  footerText: {
    fontSize: '9px',
    maxWidth: '85%',
  },
})
