import { useDispatch as useDispatchRedux } from 'react-redux'
import { toast } from 'react-toastify'

import { getErrorMessage } from '../helpers'

export * from './routeCheck'
export * from './cartHooks'
export { default as useClickOutside } from './useClickOutside'

export const useDispatch = () => {
  const dispatch = useDispatchRedux()

  return callback => {
    try {
      dispatch(callback)
    } catch (e) {
      // TODO: send error to Sentry
      console.warn(e.message)
      toast.error(getErrorMessage(e.message))
    }
  }
}
