import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const SecondaryTitle = styled.h2`
  font-size: 18px;
  line-height: 21.6px;
  margin-bottom: 20px;
  font-weight: 400;
  scroll-margin: 56px;
  ${MediaQuery.M_AND_DOWN} {
    margin: 0 20px 20px 20px;
  }
`

export const Img = styled.img`
  width: 100%;
  max-width: 367px;
  height: 156px;
  border-top-left-radius: 8px;
  transform: scale(1.04);
  object-fit: cover;
  ${MediaQuery.M_AND_DOWN} {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`

export const IconImage = styled.img`
  width: 29px;
  height: 29px;
  margin-right: 8px;
`

export const Small = styled.p`
  font-size: 14px;
  line-height: 18px;
`

export const SmallText = styled(Small)`
  white-space: pre-wrap;
  font-size: 12px;
  line-height: 12px;
`

export const DialogTitle = styled.h2`
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  margin-right: 16px;
`

export const ImgContainer = styled.div`
  width: 100%;
  height: 156px;
  border-top-left-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  ${MediaQuery.M_AND_DOWN} {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`

export const Wrapper = styled.div`
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border-radius: 8px;
  background: white;
  padding: 24px 20px;
  margin-bottom: 32px;
`

export const HorizontalSeparator = styled.div`
  border: 0.5px solid #f3f4f7;
  margin-bottom: 30px;
  margin-top: 30px;
`

export const LeftContainer = styled.div`
  background: #faf9fb;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  min-width: 367px;
  display: flex;
  flex-direction: column;
  flex: 1;
  ${MediaQuery.M_AND_DOWN} {
    min-width: 100%;
  }
`

export const IconContainer = styled.div`
  border: 1px solid ${props => props.color};
  box-sizing: border-box;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  margin-right: 8px;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const MarginContainer = styled.div`
  padding: 32px 47px;
  ${MediaQuery.M_AND_DOWN} {
    padding: 0;
  }
  ${MediaQuery.M_AND_UP} {
    overflow-y: scroll;
  }
`

export const CardContainer = styled.div`
  margin-bottom: ${props => (props.isLast ? '0px' : '20px')};
`

export const FlexRowIconSquare = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${props => (props.isLast ? '0px' : '16px')};
  gap: 8px;
`

export const MoreInfoContainer = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: #65b293;
  text-decoration: underline #65b293;
`

export const NameProductContainer = styled.div`
  align-items: center;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
`

export const GridTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 16px;
  row-gap: 12px;
`

export const GridTag = styled.div`
  display: flex;
  align-items: center;
  width: calc(50% - 8px);
  gap: 12px;
`

export const GridTagIconContainer = styled.div`
  border: 1px solid ${props => props.color};
  box-sizing: border-box;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
`

export const TagText = styled.p`
  font-size: 12px;
  line-height: 12px;
  color: #6b6688;
  width: calc(100% - 32px);
  overflow-wrap: break-word;
`

export const BenefitPlacholderContainer = styled.div`
  width: 1.8125rem;
  height: 1.8125rem;
`

export const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border-top: 1px solid #e2e4f3;
`
