import Filter from './filter'
import { IFiltersProps } from './props'
import { FiltersColumn } from './style'
import { OptionList } from '../../../utils/constants'

const Filters = ({
  values,
  allBrands,
  allProductTypes,
  allProductTags,
  allProductLabels,
  allIngredients,
  allTakeFormat,
  allAllergens,
  setFieldValue,
  onSortBy: handleChangeOrder,
}: IFiltersProps) => {
  //Attributes

  const optionList = OptionList
  const isMobile = window.innerWidth < 768

  //Render
  return (
    <FiltersColumn>
      {isMobile && (
        <Filter
          allValues={optionList}
          selectedOption={values.sortBy}
          type='sortBy'
          setFieldValue={setFieldValue}
        />
      )}
      <Filter
        allValues={allBrands}
        selectedValues={values.brands}
        type='brands'
        setFieldValue={setFieldValue}
      />
      <Filter
        allValues={allProductTypes}
        selectedValues={values.productTypes}
        type='productTypes'
        setFieldValue={setFieldValue}
      />
      <Filter
        allValues={allProductLabels?.map(allProduct => ({
          id: allProduct,
          name: allProduct,
        }))}
        selectedValues={values.productLabels}
        type='productLabels'
        setFieldValue={setFieldValue}
      />
      <Filter
        allValues={allTakeFormat?.map(allTakeFormat => ({
          id: allTakeFormat,
          name: allTakeFormat,
        }))}
        selectedValues={values.takeFormat}
        type='takeFormat'
        setFieldValue={setFieldValue}
      />
      <Filter
        allValues={allProductTags}
        selectedValues={values.productTags}
        type='productTags'
        setFieldValue={setFieldValue}
      />
      <Filter
        allValues={allIngredients}
        selectedValues={values.includeIngredients}
        type='includeIngredients'
        setFieldValue={setFieldValue}
      />
      <Filter
        allValues={allIngredients}
        selectedValues={values.excludeIngredients}
        type='excludeIngredients'
        setFieldValue={setFieldValue}
      />
      <Filter
        allValues={allAllergens}
        selectedValues={values.allergens}
        type='allergens'
        setFieldValue={setFieldValue}
      />
    </FiltersColumn>
  )
}

export default Filters
