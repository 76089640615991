import styled from 'styled-components/macro'

export const Container = styled.div`
  cursor: pointer;
  align-items: center;
  border-radius: 12px;
  opacity: 1;
  background: #f8f9fc;
  border: 1px solid #e7e9f4;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 20px;
`
export const Left = styled.div`
  display: flex;
  align-items: center;
  margin-right: 6px;
  width: 100%;
`
export const IconWapper = styled.div`
  display: inline-block;
  font-size: 20px;
  margin-top: 3px;
`

export const MiniIconWapper = styled.div`
  font-size: 12px;
  margin-top: 1px;
  margin-right: 5px;
`
export const Text = styled.p`
  margin-left: 10px;
  text-decoration: underline;
`
export const Right = styled.div``

export const RightText = styled.p`
  display: flex;
  width: 100%;
`

export const TabWrapper = styled.div`
  margin-top: 20px;
`

export const Ingredient = styled.span`
  margin: 0 3px;
  font-size: 13px;
`
