import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'
import { Tooltip } from '../../ui/tooltip/style'

export const Container = styled.div`
  display: flex;
  position: sticky;
  top: 75px;
  flex-direction: column;
  padding: 0px 20px;
  width: 100%;
  max-height: calc(100vh - 75px);
  max-width: 550px;
  overflow-y: auto;
`

export const Title = styled.h2`
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 10px;
`

export const CureContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
`

export const TakeContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ hasBackground }) =>
    hasBackground ? `${Color.DEEP_SEA}11` : 'none'};
  border-radius: 4px;
  padding: 0 10px;
  margin-bottom: 10px;
  align-items: flex-start;
`

export const ProductsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const NoteContainer = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid ${Color.MYSTIC};
  border-radius: 10px;
`

export const Note = styled.p`
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  line-height: 18px;
  white-space: pre-wrap;
  font-style: italic;
`

export const Footer = styled.div`
  margin: 30px 0 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Price = styled.div`
  span {
    font-size: 16px;
    text-decoration: line-through;
  }
  font-size: 24px;
`
export const FooterLine = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 7px;
  font-family: 'Arial';
  display: flex;
  align-items: center;
  strong {
    font-weight: 700;
  }
`
export const MiddleFooter = styled.div`
  margin: 15px 0;
`

export const Total = styled.p`
  font-size: 22px;
  font-weight: 700;
`

export const Duration = styled.p`
  background-color: #d8e6df;
  padding: 5px 8px;
  border-radius: 3px;
  margin-left: 5px;
`

export const P = styled.p`
  font-size: 12px;
`

export const SecondaryTitle = styled.h4`
  font-size: 18px;
  margin: 10px 0;
  position: relative;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 10px);
  cursor: pointer;
  opacity: 0.4;
  display: flex;

  &:hover {
    opacity: 1;
  }

  > ${Tooltip} {
    margin: 4px;
  }
`

export const IconsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  cursor: pointer;
  transform: translateY(-50%);
  display: flex;

  > div {
    opacity: 0.4;
    margin-left: 10px;

    &:hover {
      opacity: 1;
    }
  }
`

export const InputContainer = styled.div`
  position: relative;
  padding: 5px 0;
`

export const HelperText = styled.p`
  text-align: center;
  margin: 10px;
  font-size: 14px;
`
export const EditButton = styled.button`
  cursor: pointer;
  font-size: 18px;
  border: none;
  background: transparent;
  margin-left: 15px;
  opacity: 0.6;
`
