import { Container } from './style'
import { format } from 'date-fns'

interface DateProps {
  date: string
}

const NoteDate = ({ date }: DateProps) => {
  // Render
  return (
    <Container>{date ? format(new Date(date), 'dd/MM/yyyy') : '/'}</Container>
  )
}

export default NoteDate
