import { StatusContainer, P, Consulted } from '../style'
import PrescriptionStatusCell from '../../prescription-status'
import { renderStatusTime } from '../index'
import { useGetPatient } from '../../../../services/api/patients/patients'
import { useGetMe } from '../../../../utils/hooks/getMe'
import { Link } from 'react-router-dom'

const StatusItem = ({ index, status }) => {
  const { data: prescriber } = useGetMe()

  const { data: patient } = useGetPatient(prescriber?.id, status?.patientId, {
    query: {
      enabled: !!prescriber?.id && !!status?.patientId,
    },
  })

  return (
    <StatusContainer key={index}>
      <P>{`${renderStatusTime(new Date(status.createdAt))} -`}</P>
      <PrescriptionStatusCell
        status={status.status}
        isVisited={!!status.visitedAt}
      />
      {patient && (
        <Consulted>
          Envoyé à{' '}
          <span>
            <Link to={`/patients/${patient?.id}`}>{patient?.email}</Link>
          </span>
        </Consulted>
      )}
      {status.visitedAt && (
        <Consulted>
          {status.visitedAt && patient ? 'et ' : ''}
          consulté{' '}
          {renderStatusTime(new Date(status.visitedAt)).toLocaleLowerCase()}
        </Consulted>
      )}
    </StatusContainer>
  )
}

export default StatusItem
