import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 4px;
  width: 88px;
  height: 31px;
  text-align: center;
`

export const Text = styled.p`
  font-size: 12px;
  line-height: 13px;
  color: ${({ color }) => color};
`
