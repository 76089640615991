import React, { useState, useEffect, useRef } from 'react'
import {
  InputWrapper,
  Input,
  InputButton,
  PatientList,
  PatientItem,
  Inital,
  Email,
} from './style'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { useGetPatients } from '../../../../services/api/patients/patients'
import { Spinner } from '../../../ui'
import axios from 'axios'
import { toast } from 'react-toastify'

const SendEmail = ({ textToCopy }) => {
  const [email, setEmail] = useState('')
  const [showPatientList, setShowPatientList] = useState(false)
  const inputWrapperRef = useRef(null)

  const { data: prescriber } = useGetMe()

  const { data: patients, isLoading } = useGetPatients(
    prescriber?.id,
    {
      q: email,
      size: 1000,
    },
    {
      query: {
        enabled: !!prescriber?.id,
      },
    }
  )

  const handleSendEmail = async () => {
    const data = {
      discountCode: prescriber?.activeDiscount?.code,
      discountCodePercentage: prescriber?.activeDiscount?.percentage,
      email: email,
      link: `app.simplycure.com/patient-catalog?discountCode=${prescriber?.activeDiscount?.code}`,
      fullMessage: textToCopy,
      prescriberFullName: `${prescriber.firstName} ${prescriber.lastName}`,
    }

    try {
      await axios.post(
        'https://hook.eu1.make.com/8kyk83fvjqgbtztbh6ixm8dkkdxp7jkm',
        data
      )
      toast.success("L'email a bien été envoyé")
      setEmail('')
    } catch (error) {
      toast.error("Une erreur s'est produite lors de l'envoi du mail")
    }
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        inputWrapperRef.current &&
        !inputWrapperRef.current.contains(event.target)
      ) {
        setShowPatientList(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [inputWrapperRef])

  const handleEmailChange = e => {
    setEmail(e.target.value)
    setShowPatientList(true)
  }

  return (
    <InputWrapper ref={inputWrapperRef}>
      <Input placeholder='Email' value={email} onChange={handleEmailChange} />
      <InputButton onClick={handleSendEmail}>Envoyer</InputButton>
      {showPatientList && (
        <PatientList>
          {isLoading ? (
            <Spinner fullScreen={false} />
          ) : (
            patients?.data?.map(
              ({ id, firstName, lastName, email }) =>
                email && (
                  <PatientItem
                    onClick={() => {
                      setEmail(email)
                      setShowPatientList(false)
                    }}
                    key={id}
                  >
                    <Inital>
                      {firstName?.[0]}
                      {lastName?.[0]}
                    </Inital>
                    <Email>{email}</Email>
                  </PatientItem>
                )
            )
          )}
        </PatientList>
      )}
    </InputWrapper>
  )
}

export default SendEmail
