import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  cursor: pointer;
`

export const InfoContainer = styled.div`
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  padding: 20px 10px;
  opacity: 0.8;
  cursor: default;
  margin-bottom: 10px;
  min-width: 260px;
  align-items: center;
  flex-direction: column;
  display: flex;
`

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Name = styled.p`
  font-size: 16px;
  margin-top: 10px;
`
export const Email = styled.p`
  font-size: 12px;
  margin-top: 3px;
`
