import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const Container = styled.div`
  background: #f8f9fc;
  border: 1px solid #e7e9f4;
  border-radius: 12px;
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`

export const Left = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: row;
    margin-bottom: 20px;
  }
`

export const Right = styled.div`
  width: 100%;
`

export const Img = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 5px;
  object-fit: cover;
  ${MediaQuery.M_AND_DOWN} {
    width: 80px;
    height: 80px;
  }
`

export const IconsWrapper = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
  ${MediaQuery.M_AND_DOWN} {
    position: absolute;
    top: 15px;
    right: 30px;
    margin-right: -12px;
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
  font-size: 15px;
  width: 26px;
  height: 26px;
  background: ${props => (props.background ? props.background : '')};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

export const DescritpionWrapper = styled.div`
  margin-top: 10px;
  padding-top: 20px;
  background: #ffffff;
  border: 1px solid #e9eaf0;
  border-radius: 8px;
  padding: 12px;
`
export const DescritpionTextInput = styled.div`
  color: #080038;
  font-size: 13px;
  width: 100%;
  min-height: auto;
  border: none;
  outline: none;
  resize: none;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const HeaderLeft = styled.div``

export const QuantityWrapperFlex = styled.div`
  display: flex;
  align-items: center;
`

export const QuantityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  ${MediaQuery.M_AND_DOWN} {
    margin-bottom: -10px;
  }
`

export const Name = styled.h3`
  margin-bottom: 20px;

  ${MediaQuery.M_AND_DOWN} {
    font-size: 15px;
    margin: 0;
  }
`
export const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20px;

  ${MediaQuery.M_AND_DOWN} {
    margin: 20px 0 10px 0;
    align-items: flex-start;
  }
`

export const Price = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`

export const Units = styled.div`
  font-size: 12px;
  color: #8f95b2;
`

export const Title = styled.h5`
  margin: 0px 0 15px 0;
`

export const QuantityHandleWrapper = styled.div`
  font-size: 10px;
  width: auto;
  display: flex;
  justify-content: space-between;
  padding: 7px 10px;
  background: #ffffff;
  border: 1px solid #e7e9f4;
  border-radius: 100px;
`

export const QuantityHandlerMinus = styled.div`
  cursor: pointer;
  font-size: 13px;
  padding: 0 5px;
`
export const QuantityHandlerPlus = styled.div`
  cursor: pointer;
  font-size: 13px;
  padding: 0 5px;
`

export const QuantityHandleNumber = styled.div`
  margin: 0 5px;
  font-size: 12px;
`

export const QuantityChoiceWrapper = styled.div`
  flex-wrap: wrap;
  margin-top: -4px;
`

export const QuantityChoice = styled.span`
  white-space: nowrap;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 3px;
  padding: 6px 10px;
  background-color: ${props => (props.selected ? '#65B293' : '#fff')};
  color: ${props => (props.selected ? '#fff' : '#8f95b2')};
  border: 1px solid #e7e9f4;
  border-radius: 100px;
  font-size: 10px;

  ${MediaQuery.M_AND_DOWN} {
    margin-right: 5px;
  }
`

export const AdjustmentWrapper = styled.div`
  margin: 10px 0 0px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${MediaQuery.M_AND_DOWN} {
    margin-bottom: 10px;
  }
`

export const AdjustmentItem = styled.span`
  white-space: nowrap;
  margin-bottom: 3px;
  cursor: pointer;
  margin-right: 10px;
  padding: 6px 10px;
  background: #ffffff;
  border: 1px solid #e7e9f4;
  border-radius: 100px;
  font-size: 10px;
  color: #8f95b2;
  ${MediaQuery.M_AND_DOWN} {
    font-size: 9px;
    margin-right: 5px;
  }
`

export const AdjustmentItemQuantity = styled.span`
  cursor: pointer;
  margin: 0 4px;
  font-size: 12px;
`

export const ReturnIcon = styled.div`
  margin-left: 10px;
  cursor: pointer;
  color: #8f95b2;
`

export const ArrowDown = styled.span`
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
`

export const SubMenu = styled.div`
  height: 200px;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 20px 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  & > * :not(:last-child) {
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
    color: inherit;
    outline: none;
    font-size: 14px;
    opacity: 0.8;
  }
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  min-width: 100px;
`
export const MenuItemInput = styled.input`
  margin-right: 5px;
  cursor: pointer;
  &[type='checkbox'] {
    appearance: none;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 1px solid #00875c;
    border-radius: 3px;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }

  &[type='checkbox']:checked:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00875c;
    border-left: 2px solid #00875c;
    border-bottom: 2px solid #00875c;
  }
`
export const MenuItemLabel = styled.label`
  cursor: pointer;
  margin-top: 3px;
  font-size: 13px;
  opacity: 0.7;
`

export const BrandImg = styled.img`
  object-fit: contain;
  width: 55px;
  margin-left: 10px;
  margin-top: -18px;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const MobileBrandImg = styled.img`
  display: none;
  object-fit: contain;
  width: 55px;
  margin-left: 10px;
  margin-top: -18px;

  ${MediaQuery.M_AND_DOWN} {
    display: block;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    height: 25px;
  }
`

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const Select = styled.select`
  background: white;
  border-radius: 15px;
  padding: 5px;
  border: 1px solid #eee;
  font-size: 10px;
  margin: 0 8px;
  outline: none;
`

export const SeeMore = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
`

export const DesktopPricing = styled.div`
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const MobilePricing = styled.div`
  display: none;
  ${MediaQuery.M_AND_DOWN} {
    display: block;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  float: right;
  margin-top: 15px;
  color: #c53030;
  border: 1px solid #fc8181;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #fff5f5;
  p {
    font-size: 10px;
  }
  span {
    margin-top: 3px;
    margin-right: 5px;
    font-size: 15px;
  }
`

export const MobileNameWrapper = styled.div`
  display: none;
  margin-left: 10px;
  flex-direction: column;
  ${MediaQuery.M_AND_DOWN} {
    display: flex;
  }
`
