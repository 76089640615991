import { useFilterLogic } from '../logic'
import { IFavoriteProps } from './props'
import { Content, ProductsContainer } from './style'
import { Spinner } from '../../../ui'
import ProductPreviewCard from '../../../product/product-preview-card/ProductPreviewCard'
import { TableWithFilters } from '../../style'
import Filter from '../filter'

export const FavoriteProduct = ({
  onProductClicked,
  addProduct,
  takes,
  recommendedDuration,
  packagingType,
  mode,
  selectedHandle,
  setSelectedHandle,
  setIsInnerDialogOpen,
}: IFavoriteProps) => {
  //Attributes
  const {
    allFavoriteLists,
    isAllFavoriteListsLoading,
    favoriteListsCategories,
    setFilter,
    allFavoriteListsHandles,
    list,
  } = useFilterLogic({ packagingType: 'ORIGINAL' })

  //Return
  return (
    <>
      <Content>
        <TableWithFilters>
          <Filter allValues={favoriteListsCategories} setFilter={setFilter} />
          <ProductsContainer>
            {isAllFavoriteListsLoading ? (
              <Spinner fullScreen={false} />
            ) : (
              list &&
              list?.map(product => (
                <ProductPreviewCard
                  mode={mode}
                  key={product.handle}
                  product={product}
                  onClick={quantity => onProductClicked(product, quantity)}
                  onSignUp={undefined}
                  hasDetailModalOpen={undefined}
                  closeDetailModal={undefined}
                  selectedHandle={selectedHandle}
                  setSelectedHandle={setSelectedHandle}
                  takes={takes}
                  addProduct={addProduct}
                  from='prescriptionPage'
                  setTakeChoices={undefined}
                  getVariantName={undefined}
                  takeChoices={undefined}
                  setSearch={undefined}
                  bookmarks={allFavoriteListsHandles.includes(product.handle)}
                  setIsInnerDialogOpen={setIsInnerDialogOpen}
                />
              ))
            )}
          </ProductsContainer>
        </TableWithFilters>
      </Content>
    </>
  )
}
