import { useState } from 'react'
import {
  Button,
  ButtonText,
  ButtonArrow,
  ArrowIcon,
  PopupContent,
  LinkText,
} from './style'
import Popup from 'reactjs-popup'
import { IoIosArrowDown } from 'react-icons/io'

const DropdownButton = ({
  linkText,
  DropDownContent,
  onClick,
  textColor,
  popupContentWidth,
  isAblelToClick = true,
  popupContentMarginRight,
}: {
  linkText?: string | React.ReactNode
  DropDownContent?: React.ReactNode
  onClick?: (any) => void
  textColor?: string
  popupContentWidth?: string
  isAblelToClick?: boolean
  popupContentMarginRight?: string
}) => {
  //  Attributes
  const [arrowPosition, setArrowPosition] = useState<'leave' | 'enter'>('leave')

  // Render
  return (
    <Button textColor={textColor}>
      {DropDownContent && (
        <ButtonText>
          <LinkText onClick={onClick} isAblelToClick={isAblelToClick}>
            {linkText}
          </LinkText>
          <Popup
            trigger={
              <ButtonArrow isAblelToClick={isAblelToClick}>
                <ArrowIcon
                  textColor={textColor}
                  isOpen={arrowPosition === 'enter'}
                >
                  <IoIosArrowDown />
                </ArrowIcon>
              </ButtonArrow>
            }
            position='bottom center'
            on='hover'
            closeOnDocumentClick
            mouseLeaveDelay={100}
            mouseEnterDelay={0}
            arrow={false}
            onClose={v => {
              setArrowPosition('leave')
            }}
            onOpen={v => {
              setArrowPosition('enter')
            }}
          >
            <PopupContent
              style={{
                width: popupContentWidth ? popupContentWidth : 'auto',
                marginRight: popupContentMarginRight
                  ? popupContentMarginRight
                  : 'auto',
              }}
            >
              {DropDownContent}
            </PopupContent>
          </Popup>
        </ButtonText>
      )}
    </Button>
  )
}

export default DropdownButton
