import { HashRouter, BrowserRouter, Switch, Route } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { Redirect, useLocation, useHistory } from 'react-router'
import AuthenticatedRoute from './AuthenticatedRoute'
import UnauthenticatedOnlyRoute from './UnauthenticatedOnlyRoute'
import routes from './routes'
import { isElectron } from '../utils/helpers'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useGetMe } from '../utils/hooks/getMe'
import { AuthInterceptor } from '../services/auth/interceptor'

const Router = isElectron() ? HashRouter : BrowserRouter
const tagManagerArgs = {
  gtmId: 'GTM-KRBMSSTB',
}

const MyHubspotEntity = () => {
  // Attributes
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // Effects
  useEffect(() => {
    // @ts-ignore
    // window.HubSpotConversations?.widget?.refresh()
  }, [location.pathname])

  return null
}

const AppRouter = () => {
  const { data: currentPrescriber } = useGetMe()
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])

  return (
    <Router>
      <AuthInterceptor>
        <Toaster />
        <MyHubspotEntity />
        <RouteTracker />
        <Switch>
          {routes.map(route => {
            if (route.isPublic) {
              return (
                <Route
                  key={route.path}
                  component={route.c}
                  exact={route.isExact === undefined ? true : route.isExact}
                  {...route}
                />
              )
            }

            const RouteComponent = route.auth
              ? AuthenticatedRoute
              : UnauthenticatedOnlyRoute

            if (currentPrescriber?.isVerified || !route.notVerified)
              return (
                <RouteComponent
                  key={route.path}
                  path={route.path}
                  exact={route.isExact === undefined ? true : route.isExact}
                  component={route.c}
                  {...route}
                />
              )
          })}
          <Redirect from='*' to='/' />
        </Switch>
      </AuthInterceptor>
    </Router>
  )
}

const RouteTracker = () => {
  const history = useHistory()

  useEffect(() => {
    history.listen(location => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pagePath: location.pathname,
          pageTitle: document.title,
        },
      })
    })
  }, [history])

  return null
}

export default AppRouter
