import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  current: null,
  isEmailVerified: false,
  token: null,
  userUid: null,
  actions: {
    fetchAuthUser: { ...INITIAL_ACTION_STATE },
    login: { ...INITIAL_ACTION_STATE },
    logout: { ...INITIAL_ACTION_STATE },
    register: { ...INITIAL_ACTION_STATE },
    resetPassword: { ...INITIAL_ACTION_STATE },
    sendResetPassword: { ...INITIAL_ACTION_STATE },
    verifyEmail: { ...INITIAL_ACTION_STATE },
  },
}

export default state
