import styled from 'styled-components/macro'

export const Container = styled.div`
  background: #f8f9fc;
  border: 1px solid #e7e9f4;
  border-radius: 12px;
  margin-bottom: 35px;
  padding: 15px;
`

export const SearchContainer = styled.div`
  position: relative;
  padding: 20px 0 0 0;
  width: 100%;
`

export const Header = styled.div`
  border-bottom: 1px solid #e7e9f4;
  font-size: 16px;
  padding-bottom: 15px;
`

export const Or = styled.div`
  text-align: center;
  font-size: 12px;
  margin: 12px 0;
  opacity: 0.6;
`

export const Right = styled.div`
  display: flex;
  align-items: center;
`

export const IconPlus = styled.span`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px dashed #65b293;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  margin-right: 40px;
`

export const Input = styled.input`
  background-color: white;
  color: #080038;
  font-size: 16px;
  border: 1px solid #eee;
  outline: none;
  padding: 12px 10px;
  width: 100%;
  font-size: 12px;
  border-radius: 6px;
`

export const PatientList = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  width: 30%;
  height: 300px;
  overflow-y: scroll;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #e9eaf0;
  border-radius: 8px;
  z-index: 11;
`

export const PatientItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`
export const PatientItemLeft = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const InfoPatient = styled.div``

export const Patient = styled.div`
  display: flex;
  align-items: center;
`
export const Initial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #65b293;
  border-radius: 100px;
  color: #65b293;
  text-transform: uppercase;
  margin-right: 15px;
  white-space: nowrap;
`
export const InfoWrapper = styled.div``
export const FullName = styled.p`
  font-size: 15px;
  margin-bottom: 3px;
`
export const Email = styled.p`
  font-size: 10px;
  opacity: 0.6;
`

export const AddPhoneNumber = styled.p`
  font-size: 10px;
  opacity: 0.6;
  text-decoration: underline;
`

export const IconDelete = styled.div`
  cursor: pointer;
  padding: 8px;
  gap: 10px;
  width: 32px;
  height: 32px;
  background: #e9eaf0;
  border-radius: 100px;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const InputWrapper = styled.div`
  width: 100%;
`

export const SearchBarButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;

  width: auto;

  background: #3d825b;
  border-radius: 6px;

  cursor: pointer;
`

export const SearchBarButtonText = styled.div`
  font-family: 'System';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  /* identical to box height */

  text-align: center;

  /* White/500 */

  color: #ffffff;
`
