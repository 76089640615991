import { Form, PhoneInputLabel, SpaceDiv } from './style'
import { Field, PhoneInput } from '../../../../ui'
import { Button } from '../../../../ui'
import { IPatientRegisterFormProps } from './props'
import { SignUpStep } from '../type'

const PatientRegisterForm = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  step,
  onStepChange,
  handleSubmit,
  validateFormFields,
  isLoading,
}: IPatientRegisterFormProps) => {
  //Functions
  function handleNextClick() {
    if (validateFormFields()) onStepChange(SignUpStep.STEP_2)
  }

  //Return
  return (
    <Form>
      {step === SignUpStep.STEP_1 && (
        <>
          <Field
            isRequired={undefined}
            id={undefined}
            ref={undefined}
            label='Prénom'
            name='firstName'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
            placeholder='Insérez votre prénom'
          />
          <Field
            label='Nom'
            name='lastName'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={errors.lastName}
            touched={touched.lastName}
            placeholder='Insérez votre nom'
            isRequired={undefined}
            id={undefined}
            ref={undefined}
          />
          <SpaceDiv />
          <Button
            onClick={handleNextClick}
            type='button'
            appearance='primary'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            Suivant
          </Button>
        </>
      )}

      {step === SignUpStep.STEP_2 && (
        <>
          <PhoneInput
            id='phoneNumber'
            label={
              <PhoneInputLabel>
                <p>Téléphone</p>
              </PhoneInputLabel>
            }
            type='tel'
            name='phoneNumber'
            value={values.phoneNumber}
            onChange={handleChange}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            isRequired={false}
          />

          <Field
            label='E-mail'
            name='email'
            type='email'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={errors.email}
            touched={touched.email}
            placeholder='Insérez votre adresse e-mail'
            isRequired={undefined}
            id={undefined}
            ref={undefined}
          />

          <Field
            label='Mot de passe'
            id='password'
            name='password'
            type='password'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors.password}
            touched={touched.password}
            placeholder='Mot de passe'
            isRequired={undefined}
            ref={undefined}
          />
          <SpaceDiv />
          <Button
            onClick={handleSubmit}
            appearance='primary'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={isLoading}
            isActive={undefined}
          >
            Créer un compte
          </Button>
        </>
      )}
    </Form>
  )
}

export default PatientRegisterForm
