import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const GlobalContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
`

export const Main = styled.main`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`

export const Aside = styled.aside`
  width: 35%;
  display: flex;
  /* background-color: ${Color.BLACK_SQUEEZE};
  border-left: 1px solid ${Color.MYSTIC}; */
`
