/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type {
  PrescriberFavoriteListDTO,
  GetAllPrescriberFavoriteListsByPrescriberIdParams,
  CreatePrescriberFavoriteListDTO,
  ProductDTO,
  GetAllProductsFromPrescriberFavoriteItemsParams,
  GetAllProductsFromPrescriberFavoriteListItemsByListIdParams,
  PrescriberFavoriteListItemDTO,
  CreatePrescriberFavoriteListItemDTO,
} from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getAllPrescriberFavoriteListsByPrescriberId = (
  prescriberId: number,
  params?: GetAllPrescriberFavoriteListsByPrescriberIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberFavoriteListDTO[]>(
    {
      url: `/prescribers/${prescriberId}/favorite-lists`,
      method: 'get',
      params,
    },
    options
  )
}

export const getGetAllPrescriberFavoriteListsByPrescriberIdQueryKey = (
  prescriberId: number,
  params?: GetAllPrescriberFavoriteListsByPrescriberIdParams
) => [
  `/prescribers/${prescriberId}/favorite-lists`,
  ...(params ? [params] : []),
]

export const useGetAllPrescriberFavoriteListsByPrescriberId = <
  TData = AsyncReturnType<typeof getAllPrescriberFavoriteListsByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetAllPrescriberFavoriteListsByPrescriberIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllPrescriberFavoriteListsByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllPrescriberFavoriteListsByPrescriberIdQueryKey(prescriberId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllPrescriberFavoriteListsByPrescriberId>
  > = () =>
    getAllPrescriberFavoriteListsByPrescriberId(
      prescriberId,
      params,
      requestOptions
    )

  const query = useQuery<
    AsyncReturnType<typeof getAllPrescriberFavoriteListsByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const createPrescriberFavoriteList = (
  prescriberId: number,
  createPrescriberFavoriteListDTO: CreatePrescriberFavoriteListDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberFavoriteListDTO>(
    {
      url: `/prescribers/${prescriberId}/favorite-lists`,
      method: 'post',
      data: createPrescriberFavoriteListDTO,
    },
    options
  )
}

export const useCreatePrescriberFavoriteList = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createPrescriberFavoriteList>,
    TError,
    { prescriberId: number; data: CreatePrescriberFavoriteListDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createPrescriberFavoriteList>,
    { prescriberId: number; data: CreatePrescriberFavoriteListDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return createPrescriberFavoriteList(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createPrescriberFavoriteList>,
    TError,
    { prescriberId: number; data: CreatePrescriberFavoriteListDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const deletePrescriberFavoriteListById = (
  prescriberId: number,
  favoriteListId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/prescribers/${prescriberId}/favorite-lists/${favoriteListId}`,
      method: 'delete',
    },
    options
  )
}

export const useDeletePrescriberFavoriteListById = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deletePrescriberFavoriteListById>,
    TError,
    { prescriberId: number; favoriteListId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deletePrescriberFavoriteListById>,
    { prescriberId: number; favoriteListId: string }
  > = props => {
    const { prescriberId, favoriteListId } = props || {}

    return deletePrescriberFavoriteListById(
      prescriberId,
      favoriteListId,
      requestOptions
    )
  }

  return useMutation<
    AsyncReturnType<typeof deletePrescriberFavoriteListById>,
    TError,
    { prescriberId: number; favoriteListId: string },
    TContext
  >(mutationFn, mutationOptions)
}
export const updatePrescriberFavoriteListById = (
  prescriberId: number,
  favoriteListId: string,
  createPrescriberFavoriteListDTO: CreatePrescriberFavoriteListDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberFavoriteListDTO>(
    {
      url: `/prescribers/${prescriberId}/favorite-lists/${favoriteListId}`,
      method: 'put',
      data: createPrescriberFavoriteListDTO,
    },
    options
  )
}

export const useUpdatePrescriberFavoriteListById = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePrescriberFavoriteListById>,
    TError,
    {
      prescriberId: number
      favoriteListId: string
      data: CreatePrescriberFavoriteListDTO
    },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePrescriberFavoriteListById>,
    {
      prescriberId: number
      favoriteListId: string
      data: CreatePrescriberFavoriteListDTO
    }
  > = props => {
    const { prescriberId, favoriteListId, data } = props || {}

    return updatePrescriberFavoriteListById(
      prescriberId,
      favoriteListId,
      data,
      requestOptions
    )
  }

  return useMutation<
    AsyncReturnType<typeof updatePrescriberFavoriteListById>,
    TError,
    {
      prescriberId: number
      favoriteListId: string
      data: CreatePrescriberFavoriteListDTO
    },
    TContext
  >(mutationFn, mutationOptions)
}
export const getAllPrescriberFavoriteListsByProductId = (
  prescriberId: number,
  productId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberFavoriteListDTO[]>(
    {
      url: `/prescribers/${prescriberId}/products/${productId}/favorite-lists`,
      method: 'get',
    },
    options
  )
}

export const getGetAllPrescriberFavoriteListsByProductIdQueryKey = (
  prescriberId: number,
  productId: string
) => [`/prescribers/${prescriberId}/products/${productId}/favorite-lists`]

export const useGetAllPrescriberFavoriteListsByProductId = <
  TData = AsyncReturnType<typeof getAllPrescriberFavoriteListsByProductId>,
  TError = unknown
>(
  prescriberId: number,
  productId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllPrescriberFavoriteListsByProductId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllPrescriberFavoriteListsByProductIdQueryKey(prescriberId, productId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllPrescriberFavoriteListsByProductId>
  > = () =>
    getAllPrescriberFavoriteListsByProductId(
      prescriberId,
      productId,
      requestOptions
    )

  const query = useQuery<
    AsyncReturnType<typeof getAllPrescriberFavoriteListsByProductId>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(prescriberId && productId),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}

export const getAllProductsFromPrescriberFavoriteItems = (
  prescriberId: number,
  params?: GetAllProductsFromPrescriberFavoriteItemsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductDTO[]>(
    {
      url: `/prescribers/${prescriberId}/favorite-lists/items`,
      method: 'get',
      params,
    },
    options
  )
}

export const getGetAllProductsFromPrescriberFavoriteItemsQueryKey = (
  prescriberId: number,
  params?: GetAllProductsFromPrescriberFavoriteItemsParams
) => [
  `/prescribers/${prescriberId}/favorite-lists/items`,
  ...(params ? [params] : []),
]

export const useGetAllProductsFromPrescriberFavoriteItems = <
  TData = AsyncReturnType<typeof getAllProductsFromPrescriberFavoriteItems>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetAllProductsFromPrescriberFavoriteItemsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllProductsFromPrescriberFavoriteItems>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllProductsFromPrescriberFavoriteItemsQueryKey(prescriberId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllProductsFromPrescriberFavoriteItems>
  > = () =>
    getAllProductsFromPrescriberFavoriteItems(
      prescriberId,
      params,
      requestOptions
    )

  const query = useQuery<
    AsyncReturnType<typeof getAllProductsFromPrescriberFavoriteItems>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getAllProductsFromPrescriberFavoriteListItemsByListId = (
  favoriteListId: string,
  params?: GetAllProductsFromPrescriberFavoriteListItemsByListIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProductDTO[]>(
    { url: `/favorite-lists/${favoriteListId}/items`, method: 'get', params },
    options
  )
}

export const getGetAllProductsFromPrescriberFavoriteListItemsByListIdQueryKey =
  (
    favoriteListId: string,
    params?: GetAllProductsFromPrescriberFavoriteListItemsByListIdParams
  ) => [`/favorite-lists/${favoriteListId}/items`, ...(params ? [params] : [])]

export const useGetAllProductsFromPrescriberFavoriteListItemsByListId = <
  TData = AsyncReturnType<
    typeof getAllProductsFromPrescriberFavoriteListItemsByListId
  >,
  TError = unknown
>(
  favoriteListId: string,
  params?: GetAllProductsFromPrescriberFavoriteListItemsByListIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<
        typeof getAllProductsFromPrescriberFavoriteListItemsByListId
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllProductsFromPrescriberFavoriteListItemsByListIdQueryKey(
      favoriteListId,
      params
    )

  const queryFn: QueryFunction<
    AsyncReturnType<
      typeof getAllProductsFromPrescriberFavoriteListItemsByListId
    >
  > = () =>
    getAllProductsFromPrescriberFavoriteListItemsByListId(
      favoriteListId,
      params,
      requestOptions
    )

  const query = useQuery<
    AsyncReturnType<
      typeof getAllProductsFromPrescriberFavoriteListItemsByListId
    >,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!favoriteListId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const addPrescriberFavoriteListItemToList = (
  favoriteListId: string,
  createPrescriberFavoriteListItemDTO: CreatePrescriberFavoriteListItemDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriberFavoriteListItemDTO>(
    {
      url: `/favorite-lists/${favoriteListId}/items`,
      method: 'post',
      data: createPrescriberFavoriteListItemDTO,
    },
    options
  )
}

export const useAddPrescriberFavoriteListItemToList = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof addPrescriberFavoriteListItemToList>,
    TError,
    { favoriteListId: string; data: CreatePrescriberFavoriteListItemDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof addPrescriberFavoriteListItemToList>,
    { favoriteListId: string; data: CreatePrescriberFavoriteListItemDTO }
  > = props => {
    const { favoriteListId, data } = props || {}

    return addPrescriberFavoriteListItemToList(
      favoriteListId,
      data,
      requestOptions
    )
  }

  return useMutation<
    AsyncReturnType<typeof addPrescriberFavoriteListItemToList>,
    TError,
    { favoriteListId: string; data: CreatePrescriberFavoriteListItemDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const deletePrescriberFavoriteListItemByProductIdAndListId = (
  favoriteListId: string,
  productId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/favorite-lists/${favoriteListId}/product/${productId}`,
      method: 'delete',
    },
    options
  )
}

export const useDeletePrescriberFavoriteListItemByProductIdAndListId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<
      typeof deletePrescriberFavoriteListItemByProductIdAndListId
    >,
    TError,
    { favoriteListId: string; productId: string },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<
      typeof deletePrescriberFavoriteListItemByProductIdAndListId
    >,
    { favoriteListId: string; productId: string }
  > = props => {
    const { favoriteListId, productId } = props || {}

    return deletePrescriberFavoriteListItemByProductIdAndListId(
      favoriteListId,
      productId,
      requestOptions
    )
  }

  return useMutation<
    AsyncReturnType<
      typeof deletePrescriberFavoriteListItemByProductIdAndListId
    >,
    TError,
    { favoriteListId: string; productId: string },
    TContext
  >(mutationFn, mutationOptions)
}
