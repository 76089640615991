import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px;
  box-shadow: 4px 1px 84px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  background: white;
  margin-left: 197px;

  @media (max-width: 1000px) {
    transform: scale(0.9);
    margin-left: 0;
  }
`

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 40px;
`

export const Subtitle = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: normal;
  color: #393360;
  width: 327px;
  line-height: 16.8px;
`

export const TitleSuccess = styled.h1`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 40px;
  width: 310px;
`

export const SubtitleSuccess = styled.h2`
  margin-top: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: normal;
  color: #8f95b2;
  width: 310px;
  line-height: 16.8px;
`

export const ImageLogo = styled.img`
  width: 231px;
  object-fit: contain;
  object-position: center center;
`

export const ImageText = styled.div`
  width: 605px;
  height: 100%;
  margin: 28px;
  position: relative;
  filter: drop-shadow(4px 1px 84px rgba(0, 0, 0, 0.12));
  @media (max-width: 1000px) {
    display: none;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const TextImage = styled.div`
  position: absolute;
  top: 54px;
  left: 50px;
  color: white;
  max-width: 320px;
  font-size: 36.58px;
  font-weight: 400;
  line-height: 43.9px;
`

export const TextImagePrescription = styled.div`
  position: absolute;
  top: 57px;
  left: 69px;
  color: white;
  max-width: 430px;
  font-size: 36.58px;
  font-weight: 400;
  line-height: 43.9px;
`

export const FooterText = styled.p`
  font-size: 12px;
  color: #080038;
  text-align: center;

  > a {
    color: #f46363;
    text-decoration: none;
  }
`

export const FooterTextSuccess = styled.p`
  font-size: 12px;
  max-width: 280px;
  color: #080038;
  margin-bottom: 10px;

  > a {
    color: #f46363;
    text-decoration: none;
  }
`

export const SuccessRegistrationContainer = styled.div`
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  font-size: 22px;
  line-height: 28px;
  max-width: 450px;
  text-align: center;

  > a {
    color: inherit;
  }
`

export const Button = styled.a`
  border-radius: 8px;
  border: none;
  background-color: #3a845e;
  cursor: pointer;
  font-weight: 500;
  font-size: 15.0244px;
  line-height: 18px;
  color: #ffffff;
  padding: 15px 10px;
  &:hover {
    background-color: #3a845e;
  }
`
