import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const Wrapper = styled.div`
  a {
    color: black !important;
    text-decoration: none !important;
  }
`

export const Container = styled.div`
  margin-top: 10px;
  margin-right: 200px;
  padding: 15px 20px 10px 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  width: 300px;
`

export const Header = styled.div`
  text-align: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
`

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const ProductList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const CTA = styled.button`
  outline: none;
  cursor: pointer;
  width: 100%;
  padding: 5px 24px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  text-align: center;
  background-color: orange;
  background-color: #3a845e;
  border: none;
`

export const CartWrapper = styled.div`
  font-size: 17px;
  position: relative;
`

export const CartWrapperText = styled.div`
  display: flex;
  p {
    font-size: 12px;
    margin-right: 8px;
    margin-top: 3px;
    ${MediaQuery.M_AND_DOWN} {
      display: none;
    }
  }
`

export const CartQuantity = styled.span`
  position: absolute;
  top: -8px;
  right: -10px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: orange;
  color: wheat;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
