import styled from 'styled-components/macro'

import { Color, MediaQuery } from '../../../../utils/constants'

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #e2e4f3;
  position: sticky;
  top: 0;
  padding: 0 25px;
  background-color: white;
  z-index: 10;
`

export const Nav = styled.nav`
  display: flex;
  height: 100%;
  align-items: center;
`

export const Ul = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 12.5px 6px;
`

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Button = styled.button`
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 5px;
  border: none;
  margin-right: 20px;
  background-color: ${Color.DEEP_SEA};
  color: white;
  ${MediaQuery.M_AND_DOWN} {
    margin-bottom: 15px;
  }
`

export const BackIcon = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.DEEP_SEA};
  background-color: #d8e6df;
`
