import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'
import { Icon } from '../../../components/ui/icon/style'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
`

export const BreadCrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  margin: 50px 0;

  > a,
  > span {
    color: inherit;
    text-decoration: none;
    font-size: 14px;
  }

  > a {
    font-weight: 900;
  }

  > ${Icon} {
    margin: 0 10px;
    width: 10px;
    height: 10px;
  }
`

export const PatientProfile = styled.div`
  display: flex;
  margin-bottom: 50px;
`

export const AvatarContainer = styled.div`
  width: 300px;
  display: flex;
`

export const PatientInfos = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ElementGroup = styled.div`
  display: flex;
  margin-bottom: 20px;
  min-height: 40px;
  align-items: center;

  > label {
    width: 200px;
  }
`

export const ControlContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  display: none;
  padding: 10px;

  > div {
    cursor: pointer;
  }
`

export const Value = styled.span`
  font-size: 18px;
  position: relative;

  &:hover ${ControlContainer} {
    display: ${({ showControls }) => (showControls ? 'block' : 'none')};
  }
`

export const EditForm = styled.div`
  display: flex;
`

export const PatientPageHeader = styled.div`
  display: flex;
  width: 100%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 60%;
  margin: 50px 0;
`

export const Nav = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #eee;
  position: relative;
`

export const DropdownContent = styled.div`
  padding: 10px 20px;
  margin-bottom: -10px;
  & > *:last-child {
    color: red;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  div {
    margin-bottom: 10px;
  }
`

export const DropdownContentItem = styled.div`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
`
export const DropdownContentItemIcon = styled.span`
  margin-right: 10px;
  font-size: 15px;
`
export const DropdownContentItemText = styled.p`
  font-size: 12px;
`

export const NavItem = styled.div`
  margin-right: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  font-size: 14px;
  opacity: ${props => (props.active ? 1 : 0.5)};
  cursor: pointer;
  border-bottom: ${props => (props.active ? '2px solid green' : '')};
`

export const MoreIcon = styled.td`
  cursor: pointer;
  color: ${Color.DEEP_SEA};
  font-size: 20px !important;
`

export const MoreIconWrapper = styled.td`
  position: absolute;
  right: 0;
`
