import { RemunerationType } from '../../../../../services/api/types'
import { GainsTableOrderStatusProps } from './props'
import { Container, Text } from './style'

const displayStatus = {
  [RemunerationType.GIFT_CARD_PRO]: 'Bon d’achat',
  [RemunerationType.PAYMENT_SENT]: 'Paiement sur votre compte',
  [RemunerationType.LEGACY]: 'Historique',
  [RemunerationType.PAYMENT_RECEIVED]: 'Paiement à Simplycure ',
  [RemunerationType.GIFT_CARD_PATIENT]: 'Montants commandés pour vos patients',
}

const colors = {
  [RemunerationType.GIFT_CARD_PRO]: '#3A845E',
  [RemunerationType.PAYMENT_SENT]: '#3A845E',
  [RemunerationType.LEGACY]: '#6772E5',
  [RemunerationType.PAYMENT_RECEIVED]: '#FF9138',
  [RemunerationType.GIFT_CARD_PATIENT]: '#FFFFFF',
}

const bgColors = {
  [RemunerationType.GIFT_CARD_PRO]: '#E0F0E9',
  [RemunerationType.PAYMENT_SENT]: '#E0F0E9',
  [RemunerationType.LEGACY]: '#D6E6FC',
  [RemunerationType.PAYMENT_RECEIVED]: '#FCF2EF',
  [RemunerationType.GIFT_CARD_PATIENT]: '#923B3B',
}

const GainsTableOrderStatus = ({ status }: GainsTableOrderStatusProps) => {
  // Render
  return (
    <Container bgColor={bgColors[status]}>
      <Text color={colors[status]}>{displayStatus[status]}</Text>
    </Container>
  )
}

export default GainsTableOrderStatus
