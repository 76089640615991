import styled from 'styled-components/macro'

export const Container = styled.div``

export const Price = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`
export const BarredPrice = styled.div`
  margin-right: 5px;
  font-size: 13px;
  margin-bottom: 5px;
  text-decoration: line-through;
  opacity: 0.4;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
`
