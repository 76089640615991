import { Button } from './style'
import { RequestProductProps } from './props'
import SponsorFormDialog from '../../sponsor/form-dialog'
import { useRequestProductLogic } from './logic'

const RequestProduct = (props: RequestProductProps) => {
  //ATRIBUTES
  const { isModalOpen, setIsModalOpen, prescriber } =
    useRequestProductLogic(props)

  //Return
  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>
        Demande de nouveaux produits
      </Button>
      {isModalOpen && (
        <SponsorFormDialog
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          formId={'d0ee01bf-f889-448a-b779-570ff3c937d9'}
          prescriber={prescriber}
          succeesMessageTitle='Votre demande a bien été envoyée'
          succeesMessageDescription='Nous ferons notre mieux pour les intégrer dès que possible. Dans certains cas, ils peuvent être disponibles en 1 semaine !'
          successMessageText='Fermer'
          successImageLink=''
        />
      )}
    </>
  )
}

export default RequestProduct
