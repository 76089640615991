const IconCheckBig = props => (
  <svg
    viewBox='0 0 10 10'
    fill='none'
    {...props}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.33268 2.5L3.74935 7.08333L1.66602 5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default IconCheckBig
