import { Container, IconContainer, Title } from './style'
import { QuizCategoryHeaderProps } from './props'
import { ChevronLeft } from '../../../ui/icon/icons'
import { useState } from 'react'

const QuizCategoryHeader = ({
  categoryTitle,
  color,
  onToggleDropDown,
  patientFirstname,
  patientName,
  createdAt,
}: QuizCategoryHeaderProps) => {
  // Attributes
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)

  // Functions
  function handleToggleDropDown() {
    if (!onToggleDropDown) return

    onToggleDropDown(!isDropDownOpen)
    setIsDropDownOpen(!isDropDownOpen)
  }

  // Render
  return (
    <Container onClick={onToggleDropDown ? handleToggleDropDown : undefined}>
      <Title color={color}>{categoryTitle}</Title>
      {categoryTitle === 'Patient' && (
        <div>
          {patientFirstname && <p> {`${patientFirstname} ${patientName}`}</p>}
          <p style={{ fontSize: '10px' }}> {createdAt}</p>
        </div>
      )}
      {onToggleDropDown && (
        <IconContainer isDropDownOpen={isDropDownOpen}>
          <ChevronLeft fill={color} width='1.5rem' height='1.5rem' />
        </IconContainer>
      )}
    </Container>
  )
}

export default QuizCategoryHeader
