import { BackIcon } from './style'
import { Link } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { IBackButtonProps } from './props'

const BackButton = ({
  linkTo,
  onClick,
  style,
  buttonText,
}: IBackButtonProps) => {
  //Return
  return linkTo ? (
    <Link to={linkTo ? linkTo : '#'}>
      <BackIcon style={style}>
        <AiOutlineArrowLeft />
        <p>{buttonText ?? 'Retour'}</p>
      </BackIcon>
    </Link>
  ) : (
    <BackIcon
      style={style}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <AiOutlineArrowLeft />
      <p>{buttonText ?? 'Retour'}</p>
    </BackIcon>
  )
}

export default BackButton
