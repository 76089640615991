import styled from 'styled-components/macro'

export const Button = styled.button`
  cursor: pointer;
  color: #65b293;
  border-radius: 0.375rem;
  padding: 0.5rem 0.625rem;
  font-size: 0.875rem;
  border: 0.0625rem solid #65b293;
  background: transparent;
  white-space: nowrap;
`

export const ButtonWrapper = styled.div`
  display: flex;
`
