import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.p`
  font-size: 12px;
  display: flex;
  align-items: center;
  ${MediaQuery.M_AND_DOWN} {
    margin-top: 20px;
  }

  p {
    line-height: 18px;
  }

  ${MediaQuery.M_AND_DOWN} {
    font-size: 12px;
  }
`

export const IconWrapper = styled.div`
  font-size: 16px;
  margin-right: 8px;
  color: #27ae60;
  margin-top: 4px;
  ${MediaQuery.M_AND_DOWN} {
    font-size: 16px;
  }
`
