import styled from 'styled-components/macro'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 8px 0;

  > button {
    margin-top: 20px;
  }
`
