import { useState } from 'react'
import { Dialog, BackButton } from '../../../components/ui'
import { Container, Header, Title } from './style'
import Email from './email-section'
import Link from './link-section'
import Nav from './nav'
import { SendPrescriptionDialogProps } from './props'

export type SelectedOptionUnion = 'LINK' | 'EMAIL'

const SendPrescriptionDialog = ({
  isOpen,
  setIsOpen,
  prescriptionId,
  lastStatus,
  status,
}: SendPrescriptionDialogProps) => {
  const [selectedOption, setSelectedOption] =
    useState<SelectedOptionUnion>('EMAIL')
  const [isInnerDialogOpen, setIsInnerDialogOpen] = useState<boolean>(true)

  // Function
  const handleBackButtonClick = () => {
    setIsOpen(false)
  }

  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      type='aside'
      width='40%'
      closeOnOverlayClick={isInnerDialogOpen}
    >
      <Container>
        <Header>
          <BackButton onClick={handleBackButtonClick} />
          <Title>Partager la recommandation</Title>
        </Header>
        <Nav
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <div
          style={{
            visibility: selectedOption === 'EMAIL' ? 'visible' : 'hidden',
            height: selectedOption === 'EMAIL' ? 'auto' : '0px',
          }}
        >
          <Email
            prescriptionId={Number(prescriptionId)}
            setIsInnerDialogOpen={setIsInnerDialogOpen}
          />
        </div>

        <div
          style={{
            visibility: selectedOption === 'LINK' ? 'visible' : 'hidden',
            height: selectedOption === 'EMAIL' ? 'auto' : '0px',
          }}
        >
          <Link
            prescriptionId={prescriptionId}
            lastStatus={lastStatus}
            status={status}
          />
        </div>
      </Container>
    </Dialog>
  )
}

export default SendPrescriptionDialog
