import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${props => (props.hasCustomBg ? '269.32px' : '253px')};
  height: 228px;
  padding: 20px;
  border-radius: 15.42px;
  box-shadow: 0px 0px 1.28481px rgba(40, 41, 61, 0.08),
    0px 0.642405px 2.56962px rgba(96, 97, 112, 0.16);
  background: ${props =>
    props.hasCustomBg ? 'url(/assets/images/gain-summary-bg.png)' : 'white'};
  background-size: contain;
`

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 12px;
  background-color: #f8f9fc;
`

export const Title = styled.p`
  color: ${props => (props.hasCustomBg ? 'white' : '#6b6688')};
  font-size: 16px;
`

export const Price = styled.p`
  color: ${props => (props.hasCustomBg ? 'white' : '#080038')};
  font-size: 24px;
  font-weight: 500;
`

export const TooltipContainer = styled.div`
  position: relative;
`

export const Tooltip = styled.div`
  position: absolute;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 189px;
  border-radius: 8px;
  padding: 12px;
  background-color: ${props => (props.hasCustomBg ? 'white' : '#080038')};
  right: 0;
`

export const TooltipContent = styled.p`
  color: ${props => (props.hasCustomBg ? '#080038' : 'white')};
  font-size: 12px;
`
