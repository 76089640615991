/**
 * Color contain all colors for the app,
 * names are generated with "Name that color" tool
 * https://chir.ag/projects/name-that-color/
 */
export const Color = {
  BLACK_SQUEEZE: '#f7fafc',
  DEEP_SEA: '#00865c',
  MINE_SHAFT: '#333333',
  MONTE_CARLO: '#7ED3BA',
  MIRAGE: '#171924',
  MYSTIC: '#e3e8ee',
  TRANSPARENT: 'rgba(255, 255, 255, 0)',
  // Statuses
  DANGER: '#842029',
  INFO: '#084298',
  SUCCESS: '#0f5132',
  WARNING: '#664d03',
}

export const Font = {
  BASIS_GROTESQUE_PRO: 'System',
  SCTO_GROTESK_A: 'Scto Grotesk A',
  SCTO_GROTESK_B: 'Scto Grotesk B',
}

export const Breakpoint = {
  XS: '320px',
  S: '498px',
  M: '768px',
  L: '1024px',
  XL: '1280px',
  XL2: '1300px',
  XXL: '1465px',
}

export const MediaQuery = {
  XS_AND_DOWN: `@media screen and (max-width: ${Breakpoint.XS})`,
  S_AND_DOWN: `@media screen and (max-width: ${Breakpoint.S})`,
  M_AND_DOWN: `@media screen and (max-width: ${Breakpoint.M})`,
  L_AND_DOWN: `@media screen and (max-width: ${Breakpoint.L})`,
  XL_AND_DOWN: `@media screen and (max-width: ${Breakpoint.XL})`,
  XL2_AND_DOWN: `@media screen and (max-width: ${Breakpoint.XL2})`,
  XXL_AND_DOWN: `@media screen and (max-width: ${Breakpoint.XXL})`,

  XS_AND_UP: `@media screen and (min-width: ${Breakpoint.XS})`,
  S_AND_UP: `@media screen and (min-width: ${Breakpoint.S})`,
  M_AND_UP: `@media screen and (min-width: ${Breakpoint.M})`,
  L_AND_UP: `@media screen and (min-width: ${Breakpoint.L})`,
  XL_AND_UP: `@media screen and (min-width: ${Breakpoint.XL})`,
  XL2_AND_UP: `@media screen and (min-width: ${Breakpoint.XL2})`,
  XXL_AND_UP: `@media screen and (min-width: ${Breakpoint.XXL})`,
}
