export const ProductCategory = {
  ARTICULATION: 'Articulations - Os',
  BEAUTY: 'Beauté',
  CERVEAU: 'Cerveau - Mémoire',
  CHIRURGIE_BARIATRIQUE: 'Chirurgie bariatrique',
  CIRCULATION: 'Circulation - Cardiovasculaire',
  DETOX: 'Detoxication',
  DIGESTION: 'Digestion',
  DEFENSE_IMMUNITAIRES: 'Défenses immunitaires',
  ENERGY: 'Énergie - Vitalité',
  CHILDREN: 'Enfants',
  EQUILIBRE_HORMONAL: 'Équilibre hormonal',
  Fertilite: 'Fertilité',
  FONCTION_THYROIDIENNE: 'Fonction thyroïdienne',
  PREGNANCY: 'Grossesse',
  INTESTIN: 'Intestin - Microbiote',
  METABOLISME: 'Métabolisme - Poids',
  NOUVEAUTE: 'Nouveauté',
  SPORT: 'Sport',
  STRESS: 'Stress - Sommeil',
}

export const ProductHandle = {
  ACEROLA: 'acerola-extra-bio',
  ASHWAGANDHA: 'ashwagandha-extra-bio',
  BOURRACHE: 'bourrache-onagre-bio',
  CHARDON_MARIE: 'chardon-marie-bio',
  COLLAGENE: 'collagene',
  CURCUMA: 'curcuma',
  DIET: 'complete-diet',
  DRAINAGE: 'complexe-drainage',
  ENERGY: 'complexe-energy',
  GLUTAMINE: 'glutamine',
  HAIR_NAIL: 'complexe-cheveux-ongles',
  IRON: 'pidolate-de-fer',
  MAGNESIUM: 'complexe-de-magnesium',
  MELATONINE: 'spray-de-melatonine',
  OMEGA_3_VEGGIE: 'omega-3-veggie',
  OMEGA_3: 'omega-3-epa-dha',
  OSTEOSIN: 'osteosin',
  PAMPLEMOUSSE: 'pepin-de-pamplemousse',
  PRENATAL: 'complexe-prenatal',
  PROBIO: 'probiotique',
  RELAX: 'complexe-relax',
  SUN: 'complete-sun',
  TYROSINE: 'tyrosine',
  V_B12: 'vitamine-b12',
  V_C: 'vitamine-c-liposomale',
  V_D: 'vitamine-d',
  VEGAN_WHEY: 'proteines-vegetales',
  ZINC: 'pidolate-de-zinc',
  COMPLETE_THYROIDE: 'complete-thyroide',
  ENZYMES_DIGESTIVES: 'enzymes-digestives',
  PHYCO: 'phyco',
  LYCO: 'lyco',
  METABO: 'metabo',
  IMMUNO: 'immuno',
  FLEXO: 'flexo',
  COENZYME_Q10: 'coenzyme-q10',
  GLUTATHION: 'glutathion',
  GRIFFONIA_5HTP: 'griffonia-5htp',
  L_GLUTAMIN_POUDRE: 'l-glutamin-poudre',
  VITABIOTIC_IMMUNE_VITANUTRICS: 'vitabiotic-immune-vitanutrics',
  VITASPRAY_MELATONINE_VITANUTRICS: 'vitaspray-melatonine-vitanutrics',
  VITAPROZEN_VITANUTRICS: 'vitaprozen-vitanutrics',
  VITASTRESS_GABACTIV_VITANUTRICS: 'vitastress-gabactiv-vitanutrics',
  VITASYNERGIE_VITANUTRICS: 'vitasynergie-vitanutrics',
  VITACIDE_VITANUTRICS: 'vitacide-vitanutrics',
  VITABIOTIC_PROTECT_VITANUTRICS: 'vitabiotic-protect-vitanutrics',
  VITAK2_VITANUTRICS: 'vitak2-vitanutrics',
  VITABIOTIC_CONFORT_VITANUTRICS: 'vitabiotic-confort-vitanutrics',
  VITABASE_VITANUTRICS: 'vitabase-vitanutrics',
  VITASILICIUM_VITANUTRICS: 'vitasilicium-vitanutrics',
  VITACHOLGREEN_VITANUTRICS: 'vitacholgreen-vitanutrics',
  VITADETOX_VITANUTRICS: 'vitadetox-vitanutrics',
  VITAREDOX_VITANUTRICS: 'vitaredox-vitanutrics',
  ACIDE_ALPHA_LIPOIQUE_VITANUTRICS: 'acide-alpha-lipoique-vitanutrics',
  VERGEREINE_VITANUTRICS: 'vergereine-vitanutrics',
  CALCIUM_VITANUTRICS: 'calcium-vitanutrics',
  VITACBD_VITANUTRICS: 'vitacbd-vitanutrics',
  VITACOLLAGENE_HA_VITANUTRICS: 'vitacollagene-ha-vitanutrics',
  VITAFER_COMPLEX_VITANUTRICS: 'vitafer-complex-vitanutrics',
  VITARUTINE_VITANUTRICS: 'vitarutine-vitanutrics',
  VITA_D3_VEGAN_VITANUTRICS: 'vita-d3-vegan-vitanutrics',
  CHROME_EQUI_NUTRI: 'chrome-equi-nutri',
  INOSITOL_CHOLINE_EQUI_NUTRI: 'inositol-choline-equi-nutri',
  MUCO_PERMEA_EQUI_NUTRI: 'muco-permea-equi-nutri',
  VIT_E_EQUI_NUTRI: 'vit-e-equi-nutri',
  LITHOTHAMNE_EQUI_NUTRI: 'lithothamne-equi-nutri',
  CHARDON_MARIE_EQUI_NUTRI: 'chardon-marie-equi-nutri',
  COMPLEX_JUNIOR_EQUI_NUTRI: 'complex-junior-equi-nutri',
  FRAMBOISIER_EQUI_NUTRI: 'framboisier-equi-nutri',
  GENÉVRIER_EQUI_NUTRI: 'genévrier-equi-nutri',
  CASSIS_EQUI_NUTRI: 'cassis-equi-nutri',
  FIGUIER_EQUI_NUTRI: 'figuier-equi-nutri',
  SEQUOIA_EQUI_NUTRI: 'sequoia-equi-nutri',
  POMMIER_EQUI_NUTRI: 'pommier-equi-nutri',
  ROMARIN_EQUI_NUTRI: 'romarin-equi-nutri',
  NOYER_EQUI_NUTRI: 'noyer-equi-nutri',
  OLIVIER_EQUI_NUTRI: 'olivier-equi-nutri',
  SOMMEIL_EQUI_NUTRI: 'sommeil-equi-nutri',
  CONFORT_FEMININ_EQUI_NUTRI: 'confort-feminin-equi-nutri',
  CONFORT_JEUNE_FEMME_EQUI_NUTRI: 'confort-jeune-femme-equi-nutri',
  MYRTILLE_EQUI_NUTRI: 'myrtille-equi-nutri',
  AUBEPINE_EQUI_NUTRI: 'aubepine-equi-nutri',
  RETOUR_VEINEUX_EQUI_NUTRI: 'retour-veineux-equi-nutri',
  SERENITE_EQUI_NUTRI: 'serenite-equi-nutri',
  DEPURATIF_EQUI_NUTRI: 'depuratif-equi-nutri',
  IMMUNITE_EQUI_NUTRI: 'immunite-equi-nutri',
  SUCRE_EQUI_NUTRI: 'sucre-equi-nutri',
  TRANSIT_EQUI_NUTRI: 'transit-equi-nutri',
  IODE_DYNVEO: 'iode-dynveo',
  OMEGA_3_VEGETAL_DYNVEO: 'omega-3-vegetal-dynveo',
  LACTOFERRINE_BIOACTIVE_DYNVEO: 'lactoferrine-bioactive-dynveo',
  RHODIOLA_ROSEA_DYNVEO: 'rhodiola-rosea-dynveo',
  SAFRAN_DYNVEO: 'safran-dynveo',
  FIBRES_ACACIA_DYNVEO: 'fibres-acacia-dynveo',
  BERBERINE_DYNVEO: 'berberine-dynveo',
  GARCINIA_CAMBOGIA_DYNVEO: 'garcinia-cambogia-dynveo',
  MULTIVITAMINES_NUTRIPURE: 'multivitamines-nutripure',
  MAGNESIUM_NUTRIPURE: 'magnesium-nutripure',
  OMEGA_3_NUTRIPURE: 'omega-3-nutripure',
  ARTHROPURE_NUTRIPURE: 'arthropure-nutripure',
  COLLAGENE_NUTRIPURE: 'collagene-nutripure',
  WHEY_NATIVE_NUTRIPURE: 'whey-native-nutripure',
  WHEY_VANILLE_NUTRIPURE: 'whey-vanille-nutripure',
  GLUTAMINE_NUTRIPURE: 'glutamine-nutripure',
  CREATINE_NUTRIPURE: 'creatine-nutripure',
  BCAA_NUTRIPURE: 'bcaa-nutripure',
  PURE_ELECTROLYTES_NUTRIPURE: 'pure-electrolytes-nutripure',
  PURE_WORKOUTE_NUTRIPURE: 'pure-workoute-nutripure',
}

export const ProductType = {
  ACIDE_AMINE: 'Acide aminé',
  ACIDES_GRAS: 'Acides gras',
  ANTIOXYDANT: 'Antioxydants',
  COMPLEXES: 'Complexes',
  GEMMOTHÉRAPIE: 'Gemmothérapie',
  MINERAUX: 'Minéraux',
  PHYTOTHERAPIE: 'Phytothérapie',
  PROBIOTIQUES: 'Probiotiques',
  VITAMINES: 'Vitamines',
}

export const BRANDS = {
  ALFA_NUTRIFARMA: 'Alfa-Nutrifarma',
  BE_LIFE: 'Be-life',
  COMPLIMENT: 'Compliment',
  EQUI_NUTRI: 'Equi-nutri',
  LEPIVITS: 'Lepivits',
  SYMBIOSE: 'Symbiose by Simplycure',
  VERA_SANA: 'Vera Sana',
}
