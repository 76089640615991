import { Container, Icon, P } from './style'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const PrescriptionDraftInfo = () => {
  // Render
  return (
    <Container>
      <Icon>
        <AiOutlineInfoCircle />
      </Icon>
      <P>
        Le sachet quotidien est uniquement utilsé à partir de 3 compléments pas
        prise. Pour une ou deux gélules, les produits sont livrés en pots
      </P>
    </Container>
  )
}

export default PrescriptionDraftInfo
