import styled from 'styled-components/macro'

export const Button = styled.button`
  cursor: pointer;
  color: white;
  border-radius: 0.375rem;
  border: 0.0625rem solid transparent;
  padding: 0.5625rem 0.8125rem;
  font-family: basic grotesque, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  background: #3a845e;
  white-space: nowrap;
`
