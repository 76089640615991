import styled from 'styled-components/macro'

import { Color } from '../../../../../utils/constants'

export const TakeChoice = styled.button`
  cursor: pointer;
  border: 1px solid ${Color.MYSTIC};
  padding: 20px;
  background-color: ${Color.TRANSPARENT};
  margin: 10px 0;
  border-radius: 4px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  width: 100%;

  &:hover {
    background-color: ${Color.MYSTIC}55;
  }
`

export const DialogContainer = styled.div`
  padding: 20px;
`
