import * as Yup from 'yup'

const PatientRegisterFormSchema = Yup.object({
  firstName: Yup.string()
    .min(3, 'Minimum 3 caractères requis')
    .required('Champ obligatoire'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 caractères requis')
    .required('Champ obligatoire'),
  phoneNumber: Yup.string()
    .min(3, 'Minimum 3 caractères requis')
    .required('Champ obligatoire'),
  email: Yup.string()
    .email('Format email invalide')
    .required('Champ obligatoire'),
  password: Yup.string()
    .min(6, 'Minimum 6 caractères requis')
    .required('Champ obligatoire'),
})

export default PatientRegisterFormSchema
