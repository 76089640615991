import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const GlobalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 75px;
  min-height: 75px;
  position: sticky;
  top: 0;
  padding: 20px;
  background-color: white;
  z-index: 10;

  ${MediaQuery.M_AND_DOWN} {
    height: 60px;
    min-height: 60px;
    padding: 40px 20px 5px 20px;
  }

  a {
    text-decoration: none;
    color: black;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`
export const MobileButtons = styled.div`
  display: none;
  ${MediaQuery.M_AND_DOWN} {
    display: block;
  }
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;
`

export const LinkTelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-size: 10px;
    color: #7961bd;
    text-decoration: none;
  }
`

export const TelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  color: #7961bd;
`

export const TelIconWrapper = styled.div`
  font-size: 13px;
  margin-right: 15px;
`

export const NavItem = styled.div`
  font-size: 13px;
  margin-right: 40px;
  text-decoration: underline;
`
