import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  StyleSheet,
} from '@react-pdf/renderer'
import { Color } from '../../../utils/constants'
import BookletFooter from './BookletFooter'
import BookletHeader from './BookletHeader'
import BookletProProducts from './BookleMeProducts'
import { IBookletProps } from './props'

const styles = StyleSheet.create({
  page: { paddingTop: 20, paddingBottom: 20 },
  section: { marginTop: 8, marginBottom: 8, marginLeft: 20, marginRight: 20 },
  sectionQuestion: { margin: 3 },
  title: { color: Color.DEEP_SEA, fontSize: 14 },
  subTitle: { color: '#777', fontSize: 12 },
  answer: { color: '#333', fontSize: 10, marginLeft: 10, marginTop: 3 },
})

export const Pdf = ({
  products,
  prescriberFirstName,
  prescriberLastName,
  title,
  customMessage,
  discountPercentage,
  discountTotal,
  total,
  prescriptionId,
  qrCodeUrl,
  prescription,
}: IBookletProps) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <BookletHeader
          prescriberFirstName={prescriberFirstName}
          prescriberLastName={prescriberLastName}
          title={title}
          customMessage={customMessage}
          prescriptionId={prescriptionId}
          prescription={prescription}
        />
        <View
          style={{
            marginTop: '-20px',
          }}
        >
          {products?.map(product => {
            return (
              <BookletProProducts
                product={product}
                prescriberFirstName={prescriberFirstName}
                prescriberLastName={prescriberLastName}
                discountPercentage={discountPercentage}
              />
            )
          })}
        </View>
        <BookletFooter
          discountTotal={discountTotal}
          discountPercentage={discountPercentage}
          total={total}
          prescriberFirstName={prescriberFirstName}
          prescriberLastName={prescriberLastName}
          prescriptionId={prescriptionId}
          dataUrl={qrCodeUrl}
        />
      </Page>
    </Document>
  )
}

export const Doc = ({
  products,
  prescriberFirstName,
  prescriberLastName,
  title,
  customMessage,
  discountPercentage,
  discountTotal,
  total,
  prescriptionId,
  qrCodeUrl,
}) => {
  return (
    <PDFDownloadLink
      document={
        <Pdf
          products={products}
          prescriberFirstName={prescriberFirstName}
          prescriberLastName={prescriberLastName}
          title={title}
          customMessage={customMessage}
          discountPercentage={discountPercentage}
          discountTotal={discountTotal}
          total={total}
          prescriptionId={prescriptionId}
          qrCodeUrl={qrCodeUrl}
        />
      }
      fileName={`filename.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? 'Chargement...' : 'Exporter le fichier'
      }
    </PDFDownloadLink>
  )
}
