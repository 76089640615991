import { Container, HeaderTitle, CardWrapper } from './style'
import { useGetMe } from '../../../utils/hooks/getMe'

import { Button } from '../../ui'
import { useHistory } from 'react-router'

const CTA = () => {
  const history = useHistory()
  const { data: prescriber } = useGetMe()

  return (
    <Container>
      <HeaderTitle>Bonjour {prescriber?.firstName} !</HeaderTitle>
      <CardWrapper>
        <Button
          onClick={() => {
            history.push('/prescriptions/new?step=2')
          }}
          appearance='primary'
          iconLeft={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
        >
          Nouvelle recommandation
        </Button>
      </CardWrapper>
    </Container>
  )
}

export default CTA
