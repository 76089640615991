import { useState, useEffect } from 'react'
import {
  Container,
  Modal,
  Title,
  Description,
  Input,
  Button,
  Flex,
} from './style'
import toast from 'react-hot-toast'
import Cookies from 'js-cookie'
import { CartService } from '../../../services/cart.service'
import { useMutation } from 'react-query'
import { getDiscountWithPrescriberByCode } from '../../../services/api/discount/discount'
import { AccessModalProps } from './props'
import queryString from 'query-string'
import { useHistory } from 'react-router'

const AccessModal = ({
  setIsOpen,
  isOpen,
  description,
  setIsPatientOnboardingOpen,
}: AccessModalProps) => {
  const { location } = useHistory()
  const { goToCheckout } = queryString.parse(location.search)
  const [promoCode, setPromoCode] = useState('')

  const discountCodesUpdate = useMutation(CartService.discountCodesUpdate)

  const handlePromoCode = e => {
    setPromoCode(e.target.value)
  }

  const handleButtonClick = async (e?: React.FormEvent, code = promoCode) => {
    if (e) e.preventDefault()

    try {
      const data = await getDiscountWithPrescriberByCode(code?.toUpperCase())
      discountCodesUpdate.mutateAsync(code)
      Cookies.set('promoCode', code)
      Cookies.set('promoCodePercentage', `${data.percentage}`)

      if (data.percentage > 0)
        toast.success(
          `Le code de ${data.prescriberFirstName} ${data.prescriberLastName} a bien été appliqué avec succès. Vous pouvez désormais passer commande.`,
          {
            duration: 5000,
          }
        )
      else toast.success('')
      setIsOpen(false)
      if (goToCheckout) window.location.href = goToCheckout as string
      setIsPatientOnboardingOpen && setIsPatientOnboardingOpen(true)
    } catch {
      toast.error("Échec de l'application du code. Veuillez réessayer.")
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const discountCode = urlParams.get('discountCode')

    if (discountCode) {
      // setIsOpen(true)
      handleButtonClick(null, discountCode)
      setPromoCode(discountCode)
    }
  }, [])

  return (
    <Container style={{ display: `${isOpen ? 'block' : 'none'}` }}>
      <Modal>
        <Description>
          {description
            ? description
            : 'Pour accéder au catalogue et commander des produits, veuillez introduire le code de votre prescripteur.'}
        </Description>

        <Flex onSubmit={e => handleButtonClick(e)}>
          <Input
            type='text'
            value={promoCode}
            onChange={handlePromoCode}
            placeholder='Entrez votre code'
          />
          <Button type='submit'>Appliquer</Button>
        </Flex>
      </Modal>
    </Container>
  )
}

export default AccessModal
