import { useState } from 'react'
import {
  Wrapper,
  Header,
  List,
  Item,
  Img,
  TextWrapper,
  Button,
  SeeMore,
  Info,
} from './style'
import { client } from '../../../../services/sanity/client'
import { useQuery } from 'react-query'
import {
  convertTimestampOrDateStringToFrenchDateString,
  sortDates,
  transformSanityImageURL,
} from '../../../../utils/helpers'

const NewsItem = ({
  title,
  description,
  cta_text,
  cta_url,
  imageUrl,
  speaker,
  date,
}) => {
  const isLongContent = description?.length > 100
  const [isOpened, setIsOpened] = useState(!isLongContent)

  const toggleContent = () => {
    setIsOpened(!isOpened)
  }

  return (
    <Item>
      <Img src={imageUrl} />
      <TextWrapper>
        <h3>{title}</h3>
        <p>
          {isLongContent && !isOpened ? (
            <span>{description.substring(0, 100)}... </span>
          ) : (
            <span>{description} </span>
          )}

          {isLongContent && (
            <SeeMore onClick={toggleContent}>
              {isOpened ? 'Voir moins' : 'Voir plus'}
            </SeeMore>
          )}
        </p>
        <Info>
          {convertTimestampOrDateStringToFrenchDateString(date)} • {speaker}
        </Info>
      </TextWrapper>
      <Button href={cta_url} target='_blank'>
        {cta_text}
      </Button>
    </Item>
  )
}

const fetchWebinars = async () => {
  try {
    const webinarsData = await client.fetch('*[_type == "webinars"]')
    return webinarsData
  } catch (error) {
    throw error
  }
}

const News = () => {
  const { data } = useQuery('oldWebinars', fetchWebinars)

  return (
    <Wrapper>
      <Header>
        <h3>Webinars récents</h3>
        <a
          rel='noreferrer'
          href='https://complimentme.notion.site/Simplycure-Academy-911e0b279ed546caa8d519066b4dfe44'
          target='_blank'
        >
          Accédez à l’academy -&gt;
        </a>
      </Header>
      <List>
        {Array.isArray(data) &&
          data
            ?.sort((a, b) => {
              // Handle null values by pushing them to the end
              if (!a.date) return 1 // 'a' goes to the end
              if (!b.date) return -1 // 'b' goes to the end

              // Convert dates to timestamps (milliseconds since Unix epoch)
              const dateA = new Date(a.date).getTime()
              const dateB = new Date(b.date).getTime()

              // Compare timestamps for descending order
              return dateB - dateA
            })
            ?.map(
              ({
                title,
                description,
                date,
                cta_text,
                cta_url,
                image,
                speaker,
              }) => (
                <NewsItem
                  key={title}
                  speaker={speaker}
                  date={date}
                  title={title}
                  description={description}
                  cta_text={cta_text}
                  cta_url={cta_url}
                  imageUrl={transformSanityImageURL(image?.asset?._ref)}
                />
              )
            )}
      </List>
    </Wrapper>
  )
}

export default News
