import { useState, useEffect } from 'react'
import { useGetAllIngredientsByProductId } from '../../../../../services/api/ingredients/ingredients'
import { useGetProductNutritionalIntakesByProductId } from '../../../../../services/api/nutritional-intakes/nutritional-intakes'
import { PatientSexe, Role } from '../../../../../services/api/types'
import { Country } from '../../../../../services/api/types/country'
import { PatientTarget } from '../../../../../services/api/types/patientTarget'
import { useGetVNRValueByProductIdAndCountryAndSexeAndPatientTarget } from '../../../../../services/api/vnrvalues/vnrvalues'
import { useGetAllProductFormulationsByProductId } from '../../../../../services/api/product/product'
import { useGetProductDosingByProductId } from '../../../../../services/api/dosings/dosings'
import { useGetPrescriberAddressByPrescriberId } from '../../../../../services/api/prescriber/prescriber'
import { HorizontalSeparator } from '../../left-side/style'
import { FormulationProps } from './props'
import { Collapse } from 'react-collapse'
import {
  WrapperTitle,
  Text,
  SelectPills,
  FlexRow,
  IngredientContainer,
  NutritionContainerHeader,
  NutritionContainerTitle,
  NutritionContainer,
  SecondaryTitle,
  TableWrapper,
  Table,
  TableCell,
  TableCellIngredient,
  TableCellQuantity,
  TableHeaderCell,
  TableHeaderCellIngredient,
  TableHeaderCellQuantity,
  TableHeaderRow,
  TableRow,
  Wrapper,
  Select,
  Card,
  CardTitle,
  CardText,
  FlexList,
  SeeMore,
  SelectPillsText,
  SelectPillsQuantity,
  SelectPillsQuantityButton,
  Icon,
  Na,
} from './style'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { BsCheck2Circle } from 'react-icons/bs'
import { CiWarning } from 'react-icons/ci'
import {
  capitalizeFirstLetter,
  getTakeFormatName,
} from '../../../../../utils/helpers'
import { useGetMe } from '../../../../../utils/hooks/getMe'

export const translatePatientSexe = (patientSexe: PatientSexe) => {
  switch (patientSexe) {
    case 'MAN':
      return 'Homme'
    case 'WOMAN':
      return 'Femme'
    default:
      return ''
  }
}

export const getNames = (
  patientTarget: PatientTarget
): {
  value?: PatientTarget
  label: string
} => {
  switch (patientTarget) {
    case PatientTarget.ZERO_TO_SIX_MONTHS:
      return {
        value: PatientTarget.ZERO_TO_SIX_MONTHS,
        label: '0-6 mois',
      }
    case PatientTarget.SEVEN_TO_ELEVEN_MONTHS:
      return {
        value: PatientTarget.SEVEN_TO_ELEVEN_MONTHS,
        label: '7-11 mois',
      }
    case PatientTarget.ONE_TO_THREE_YEARS:
      return {
        value: PatientTarget.ONE_TO_THREE_YEARS,
        label: '1-3 ans',
      }
    case PatientTarget.FOUR_TO_SIX_YEARS:
      return {
        value: PatientTarget.FOUR_TO_SIX_YEARS,
        label: '4-6 ans',
      }
    case PatientTarget.SEVEN_TO_TEN_YEARS:
      return {
        value: PatientTarget.SEVEN_TO_TEN_YEARS,
        label: '7-10 ans',
      }
    case PatientTarget.ELEVEN_TO_FOURTEEN_YEARS:
      return {
        value: PatientTarget.ELEVEN_TO_FOURTEEN_YEARS,
        label: '11-14 ans',
      }
    case PatientTarget.FIFTEEN_TO_SEVENTEEN_YEARS:
      return {
        value: PatientTarget.FIFTEEN_TO_SEVENTEEN_YEARS,
        label: '15-17 ans',
      }
    case PatientTarget.EIGHTEEN_TO_SIXTY_YEARS:
      return {
        value: PatientTarget.EIGHTEEN_TO_SIXTY_YEARS,
        label: '18-60 ans',
      }
    case PatientTarget.SIXTY_TO_MORE_YEARS:
      return {
        value: PatientTarget.SIXTY_TO_MORE_YEARS,
        label: '>60 ans',
      }
    case PatientTarget.Pregnancy:
      return {
        value: PatientTarget.Pregnancy,
        label: 'Grossesse',
      }
    case PatientTarget.Feeding:
      return {
        value: PatientTarget.Feeding,
        label: 'Allaitement',
      }
    default:
      return {
        label: '',
      }
  }
}

const Formulation = ({ product, isPatientCatalog }: FormulationProps) => {
  // Attributes
  const [numberOfCapsules, setNumberOfCapsule] = useState(1)
  const [collapseIngredients, setCollapseIngredients] = useState<boolean>(false)
  const [collapseNutritions, setcollapseNutritions] = useState<boolean>(false)
  const [country, setCountry] = useState<Country>('FRANCE')
  const [sexe, setSexe] = useState<PatientSexe>('WOMAN')
  const [patientTarget, setPatientTarget] = useState<PatientTarget>(
    'EIGHTEEN_TO_SIXTY_YEARS'
  )
  const { data: dosings } = useGetProductDosingByProductId(product?.id)

  const { data: ingredients } = useGetAllIngredientsByProductId(product?.id)
  const sortedIngredients = ingredients
    ?.filter(value => value.unit !== 'excipient')
    ?.sort((a, b) => b?.quantity - a?.quantity)
    ?.sort((a, b) => {
      if (a?.unit === 'g') {
        return a?.unit?.localeCompare(b?.unit)
      }
      if (a?.unit === 'mg') {
        return a?.unit?.localeCompare(b?.unit)
      }
      if (a?.unit === 'μg') {
        return a?.unit?.localeCompare(b?.unit)
      }
      return b?.unit?.localeCompare(a?.unit)
    })

  const excipients = ingredients
    ?.filter(value => value.unit === 'excipient')
    ?.sort((a, b) => b?.quantity - a?.quantity)
    ?.sort((a, b) => {
      if (a?.unit === 'g') {
        return a?.unit?.localeCompare(b?.unit)
      }
      if (a?.unit === 'mg') {
        return a?.unit?.localeCompare(b?.unit)
      }
      if (a?.unit === 'μg') {
        return a?.unit?.localeCompare(b?.unit)
      }
      return b?.unit?.localeCompare(a?.unit)
    })

  const { data: nutritions } = useGetProductNutritionalIntakesByProductId(
    product?.id
  )
  const sortedNutritions = nutritions
    ?.sort((a, b) => b?.quantity - a?.quantity)
    ?.sort((a, b) => {
      if (a?.unit === 'g') {
        return a?.unit?.localeCompare(b?.unit)
      }
      if (a?.unit === 'mg') {
        return a?.unit?.localeCompare(b?.unit)
      }
      if (a?.unit === 'μg') {
        return a?.unit?.localeCompare(b?.unit)
      }
      return b?.unit?.localeCompare(a?.unit)
    })

  const { data: vnrValues } =
    useGetVNRValueByProductIdAndCountryAndSexeAndPatientTarget(product?.id, {
      country: country,
      sexe: sexe,
      target: patientTarget,
    })

  const { data: formulations } = useGetAllProductFormulationsByProductId(
    product?.id,
    {
      size: 100, // Shouldn't have a lot of formulations
    },
    { query: { enabled: !!product?.id } }
  )

  const { data: prescriber } = useGetMe()

  const { data: addressByPrescriberId } = useGetPrescriberAddressByPrescriberId(
    prescriber?.id,
    {
      query: { enabled: !!prescriber?.id },
    }
  )

  //Functions
  const handleCountryChange = e => {
    setCountry(e)
  }

  const handleSexeChange = e => {
    setSexe(e)
  }

  const handlePatientTargetChange = e => {
    setPatientTarget(e)
  }

  const handleOnMinusClick = () => {
    if (numberOfCapsules !== 1) {
      setNumberOfCapsule(Number(numberOfCapsules) - 1)
    }
  }

  const handleOnPlusClick = () => {
    setNumberOfCapsule(Number(numberOfCapsules) + 1)
  }

  // Hooks
  useEffect(() => {
    // Check if addressByPrescriberId and its country property exists
    if (addressByPrescriberId && addressByPrescriberId.country) {
      // Convert the country to uppercase
      const uppercaseCountry = addressByPrescriberId.country.toUpperCase()

      // Check if the uppercase country exists in the keys of the Country object
      if (Object.keys(Country).includes(uppercaseCountry)) {
        setCountry(uppercaseCountry as Country)
      }
    }
  }, [addressByPrescriberId])

  // Render
  return (
    (!!formulations?.data?.length ||
      !!ingredients?.length ||
      !!nutritions?.length) && (
      <Wrapper>
        <WrapperTitle>
          <SecondaryTitle>Formulation</SecondaryTitle>
          <Text>Informations pour</Text>
          <SelectPills>
            <SelectPillsText>
              {numberOfCapsules}{' '}
              {getTakeFormatName(numberOfCapsules, dosings?.takeFormat)}
            </SelectPillsText>
            <SelectPillsQuantity>
              <SelectPillsQuantityButton
                onClick={handleOnPlusClick}
                position='top'
              >
                <IoIosArrowUp />
              </SelectPillsQuantityButton>
              <SelectPillsQuantityButton
                onClick={handleOnMinusClick}
                position='bottom'
              >
                <IoIosArrowDown />
              </SelectPillsQuantityButton>
            </SelectPillsQuantity>
          </SelectPills>
        </WrapperTitle>
        <HorizontalSeparator />
        {!isPatientCatalog && (
          <FlexList>
            {formulations?.data?.map(formulation => {
              const number = (
                Number(formulation?.percentageTitled?.split(' ')[0]) *
                numberOfCapsules
              ).toFixed(2)
              const unit = formulation?.percentageTitled?.split(' ')[1]
              return (
                <Card>
                  <CardTitle>{formulation?.title}</CardTitle>
                  <CardText>
                    {formulation?.ingredients
                      ?.split('/')
                      ?.map((ingredient, index) => (
                        <p key={`${formulation?.id}_${index}`}>
                          {ingredient}
                          {formulation?.percentageTitled
                            ? ` - ${number} ${unit}`
                            : ''}
                        </p>
                      ))}
                  </CardText>
                </Card>
              )
            })}
          </FlexList>
        )}
        <FlexRow>
          {!!ingredients?.length && (
            <IngredientContainer>
              <NutritionContainerTitle>Ingrédients</NutritionContainerTitle>

              <TableWrapper>
                <Table>
                  <TableHeaderRow>
                    <TableHeaderCellIngredient>
                      Ingrédient
                    </TableHeaderCellIngredient>
                    <TableHeaderCellQuantity>
                      {numberOfCapsules}{' '}
                      {getTakeFormatName(numberOfCapsules, dosings?.takeFormat)}
                    </TableHeaderCellQuantity>

                    <TableHeaderCell>Détails</TableHeaderCell>
                  </TableHeaderRow>

                  {sortedIngredients?.slice(0, 9)?.map(value => {
                    return (
                      <TableRow>
                        <TableCellIngredient>
                          {value?.ingredient?.name}
                        </TableCellIngredient>
                        <TableCellQuantity>
                          {parseFloat(
                            (
                              numberOfCapsules * Number(value?.quantity)
                            ).toFixed(2)
                          )}{' '}
                          {value?.unit}
                        </TableCellQuantity>

                        <TableCell>{value?.detail}</TableCell>
                      </TableRow>
                    )
                  })}
                  <Collapse isOpened={collapseIngredients}>
                    {sortedIngredients?.slice(9, 1000)?.map(value => {
                      return (
                        <TableRow>
                          <TableCellIngredient>
                            {value?.ingredient?.name}
                          </TableCellIngredient>
                          <TableCellQuantity>
                            {parseFloat(
                              (
                                numberOfCapsules * Number(value?.quantity)
                              ).toFixed(2)
                            )}{' '}
                            {value?.unit}
                          </TableCellQuantity>
                          <TableCell>{value?.detail}</TableCell>
                        </TableRow>
                      )
                    })}
                  </Collapse>
                  {sortedIngredients?.length > 9 && (
                    <SeeMore
                      onClick={() =>
                        setCollapseIngredients(!collapseIngredients)
                      }
                    >
                      {collapseIngredients ? 'Voir moins' : 'Voir plus'}
                    </SeeMore>
                  )}
                </Table>
              </TableWrapper>
            </IngredientContainer>
          )}

          {!!nutritions?.length && (
            <NutritionContainer>
              <NutritionContainerHeader>
                <NutritionContainerTitle>
                  Apport nutritionnel
                </NutritionContainerTitle>

                <Select
                  value={country}
                  onChange={e => handleCountryChange(e.target.value)}
                >
                  {(Object.keys(Country) as Array<keyof typeof Country>).map(
                    value => {
                      return (
                        <>
                          <option value={value}>
                            {capitalizeFirstLetter(value)}
                          </option>
                        </>
                      )
                    }
                  )}
                </Select>

                <Select
                  value={sexe}
                  onChange={e => handleSexeChange(e.target.value)}
                >
                  {(
                    Object.keys(PatientSexe) as Array<keyof typeof PatientSexe>
                  ).map(value => {
                    return (
                      <>
                        <option value={value}>
                          {translatePatientSexe(value)}
                        </option>
                      </>
                    )
                  })}
                </Select>

                <Select
                  value={patientTarget}
                  onChange={e => handlePatientTargetChange(e.target.value)}
                >
                  {(
                    Object.keys(PatientTarget) as Array<
                      keyof typeof PatientTarget
                    >
                  ).map(v => {
                    const { label, value } = getNames(v)
                    return (
                      <>
                        <option value={value}>{label} </option>
                      </>
                    )
                  })}
                </Select>
              </NutritionContainerHeader>
              <TableWrapper>
                <Table>
                  <TableHeaderRow>
                    <TableHeaderCellIngredient>
                      Ingrédient
                    </TableHeaderCellIngredient>
                    <TableHeaderCellQuantity>
                      {numberOfCapsules}{' '}
                      {getTakeFormatName(numberOfCapsules, dosings?.takeFormat)}
                    </TableHeaderCellQuantity>

                    <TableHeaderCell>VNR</TableHeaderCell>
                    <TableHeaderCell>Dosage Max</TableHeaderCell>
                  </TableHeaderRow>
                  {sortedNutritions?.slice(0, 9)?.map(value => {
                    const vnrValue = vnrValues?.find(
                      object =>
                        object?.nutritionalIntake?.id ===
                        value?.nutritionalIntake?.id
                    )
                    const pourcentage = Number(
                      (
                        ((numberOfCapsules * Number(value?.quantity)) /
                          vnrValue?.value) *
                        100
                      ).toFixed(0)
                    )

                    const pourcentageMax = Number(
                      (
                        ((numberOfCapsules * Number(value?.quantity)) /
                          vnrValue?.maxValue) *
                        100
                      ).toFixed(0)
                    )

                    return (
                      <TableRow>
                        <TableCellIngredient>
                          {value?.nutritionalIntake?.name}
                        </TableCellIngredient>
                        <TableCellQuantity>
                          {parseFloat(
                            (numberOfCapsules * value?.quantity).toFixed(2)
                          )}{' '}
                          {value?.unit}
                        </TableCellQuantity>

                        <TableCell>
                          {vnrValue?.value}
                          {pourcentage ? ` (${pourcentage}%)` : ''}
                        </TableCell>
                        <TableCell
                          style={{
                            color:
                              Number(pourcentageMax) > 100
                                ? '#F68282'
                                : '#3A835F',
                          }}
                        >
                          {vnrValue?.maxValue ? (
                            <>
                              <Icon>
                                {Number(pourcentageMax) > 100 ? (
                                  <CiWarning />
                                ) : (
                                  <BsCheck2Circle />
                                )}
                              </Icon>
                              {vnrValue?.maxValue}
                              {` (${pourcentageMax}%)`}
                            </>
                          ) : (
                            <Na> Na</Na>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}

                  <Collapse isOpened={collapseNutritions}>
                    {sortedNutritions?.slice(9, 1000)?.map(value => {
                      const vnrValue = vnrValues?.find(
                        object =>
                          object?.nutritionalIntake?.id ===
                          value?.nutritionalIntake?.id
                      )
                      const pourcentage = Number(
                        (
                          ((numberOfCapsules * Number(value?.quantity)) /
                            vnrValue?.value) *
                          100
                        ).toFixed(0)
                      )
                      const pourcentageMax = Number(
                        (
                          ((numberOfCapsules * Number(value?.quantity)) /
                            vnrValue?.maxValue) *
                          100
                        ).toFixed(0)
                      )

                      return (
                        <TableRow>
                          <TableCellIngredient>
                            {value?.nutritionalIntake?.name}
                          </TableCellIngredient>
                          <TableCellQuantity>
                            {parseFloat(
                              (numberOfCapsules * value?.quantity).toFixed(2)
                            )}{' '}
                            {value?.unit}
                          </TableCellQuantity>

                          <TableCell>
                            {vnrValue?.value}
                            {pourcentage ? ` (${pourcentage}%)` : ''}
                          </TableCell>
                          <TableCell
                            style={{
                              color:
                                Number(pourcentageMax) > 100
                                  ? '#F68282'
                                  : '#3A835F',
                            }}
                          >
                            {vnrValue?.maxValue ? (
                              <>
                                <Icon>
                                  {Number(pourcentageMax) > 100 ? (
                                    <CiWarning />
                                  ) : (
                                    <BsCheck2Circle />
                                  )}
                                </Icon>
                                {vnrValue?.maxValue}
                                {` (${pourcentageMax}%)`}
                              </>
                            ) : (
                              <Na> Na</Na>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Collapse>

                  {sortedNutritions?.length > 9 && (
                    <SeeMore
                      onClick={() => setcollapseNutritions(!collapseNutritions)}
                    >
                      {collapseNutritions ? 'Voir moins' : 'Voir plus'}
                    </SeeMore>
                  )}
                </Table>
              </TableWrapper>
            </NutritionContainer>
          )}
        </FlexRow>
        {!!excipients?.length && (
          <Card>
            <CardTitle> Autres ingrédients</CardTitle>
            <CardText>
              {excipients?.map(value => {
                return (
                  <span>
                    {value?.ingredient?.name}{' '}
                    {value?.detail ? `(${value?.detail})` : ''},
                  </span>
                )
              })}
            </CardText>
          </Card>
        )}
      </Wrapper>
    )
  )
}

export default Formulation
