import { Container, ImageLogo } from './style'
import { useHistory } from 'react-router-dom'

function HeaderOnlyLogo() {
  const history = useHistory()
  return (
    <Container>
      <ImageLogo
        src={'/assets/images/logo-compliment.png'}
        alt='logo-image'
        onClick={() => history.goBack()}
      />
    </Container>
  )
}

export default HeaderOnlyLogo
