import { AiTwotoneStar } from 'react-icons/ai'
import {
  Wrapper,
  StarsWrapper,
  StarWrapper,
  Title,
  Text,
  ImgLogo,
} from './style'

const Trustpilot = () => {
  return (
    <Wrapper
      href='https://fr.trustpilot.com/review/compliment.me'
      target='_blank'
    >
      <StarsWrapper>
        <StarWrapper>
          <AiTwotoneStar />
        </StarWrapper>
        <StarWrapper>
          <AiTwotoneStar />
        </StarWrapper>
        <StarWrapper>
          <AiTwotoneStar />
        </StarWrapper>
        <StarWrapper>
          <AiTwotoneStar />
        </StarWrapper>
        <StarWrapper>
          <AiTwotoneStar />
        </StarWrapper>
      </StarsWrapper>
      <Title>Excellent</Title>
      <Text>4,1 basé sur 335 avis</Text>
      <ImgLogo
        src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/trustpilot.png?alt=media&token=a5b20afb-d4b0-4ea3-a7d3-04c16d2b656e'
        alt='Trustpilot'
      />
    </Wrapper>
  )
}

export default Trustpilot
