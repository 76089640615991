import styled from 'styled-components/macro'

export const InputSortBy = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  width: 13.125rem;
  height: auto;
  padding: 12 0;
  flex-direction: column;
  gap: 0.75rem;
`

export const InputDropdown = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
