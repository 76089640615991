import { useState } from 'react'
import { Container, Title, Text, ButtonWrapper } from './style'
import { useGetPatient } from '../../../services/api/patients/patients'
import { useGetMe } from '../../../utils/hooks/getMe'

import { SuccessfullySentProps } from './props'
import { Tooltip } from '../../ui'

const SuccessfullySent = ({
  patientId,
  statuses,
  prescriptionId,
  saveBookletPdf,
}: SuccessfullySentProps) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  const { data: prescriber } = useGetMe()

  const { data: defaultPatient } = useGetPatient(prescriber?.id, patientId, {
    query: {
      enabled: !!prescriber?.id && !!patientId,
    },
  })

  const isPrescriptionSent = !!statuses?.find(i => i.status === 'SENT')

  const textToCopy = `${window.location.origin}/r/${
    prescriptionId ? prescriptionId : '...'
  }`

  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(textToCopy)
    setIsLinkCopied(true)
    setTimeout(() => setIsLinkCopied(false), 3000)
  }

  return (
    isPrescriptionSent && (
      <Container>
        <Title>Recommandation envoyée avec succès ✅</Title>
        {defaultPatient && (
          <Text>
            {defaultPatient?.firstName} {defaultPatient?.lastName} (
            {defaultPatient?.email}) a reçu la recommandation par email et SMS.
          </Text>
        )}
        <ButtonWrapper>
          <Tooltip
            size='l'
            label={isLinkCopied ? `Copié !` : 'Copier dans le presse papier'}
          >
            <button onClick={handleCopyToClipBoard}>Copier le lien</button>
          </Tooltip>

          <button onClick={() => saveBookletPdf(false)}>Télécharger</button>
          <button onClick={() => saveBookletPdf(true)}>Imprimer</button>
        </ButtonWrapper>
      </Container>
    )
  )
}

export default SuccessfullySent
