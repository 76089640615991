import { Dialog } from '../../../components/ui'
import { IconCross } from '../../../components/ui/icon/icons'
import { BlockAccessModalProps } from './props'
import {
  CloseButton,
  CloseButtonContainer,
  Container,
  ContentContainer,
  Section,
  SectionTitle,
} from './style'
import { CiCircleAlert } from 'react-icons/ci'

const BlockAccessDialog = ({ isOpen, setIsOpen }: BlockAccessModalProps) => {
  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      type='defaultCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={() => setIsOpen(false)}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <ContentContainer>
          <Section>
            <span>
              <CiCircleAlert />
            </span>
            <SectionTitle>
              Il n'est désormais plus possible d'ajouter de nouveaux
              questionnaires à notre plateforme. Toutefois, l'accès à
              l'historique des questionnaires existants reste disponible pour
              consultation.
            </SectionTitle>
          </Section>
        </ContentContainer>
      </Container>
    </Dialog>
  )
}

export default BlockAccessDialog
