import {
  Container,
  Flex,
  Box,
  BoxLine,
  BoxLineItem,
  BoxLineItemIcon,
  Initial,
  BoxText,
  FullName,
} from './style'
import { useGetPatient } from '../../../services/api/patients/patients'
import { useGetMe } from '../../../utils/hooks/getMe'
import { AiOutlineMail } from 'react-icons/ai'
import { FiPhone } from 'react-icons/fi'
import { GiPill } from 'react-icons/gi'
import { AiOutlineMan, AiOutlineWoman } from 'react-icons/ai'
import { BsArrowClockwise } from 'react-icons/bs'

const IntroPatient = ({ patientId }) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const { data: patient } = useGetPatient(prescriber?.id, patientId, {
    query: { enabled: !!prescriber?.id && !!patientId },
  })

  // Functions
  function getSexIcon(sexe: string) {
    if (sexe === 'WOMAN') return <AiOutlineWoman />
    else return <AiOutlineMan />
  }

  function getSexText(sexe: string) {
    if (sexe === 'WOMAN') return 'Féminin'
    else return 'Masculin'
  }

  // Render
  return (
    <Container>
      <Flex>
        <Box>
          <BoxLine>
            <BoxLineItem>
              <Initial>
                {patient?.firstName?.[0]}
                {patient?.lastName?.[0]}
              </Initial>
              <FullName>
                {patient?.firstName} {patient?.lastName}
              </FullName>
            </BoxLineItem>
          </BoxLine>
          <BoxLine>
            <BoxLineItem>
              <BoxLineItemIcon>
                <AiOutlineMail />
              </BoxLineItemIcon>
              <BoxText>{patient?.email}</BoxText>
            </BoxLineItem>
            <BoxLineItem>
              <BoxLineItemIcon>
                <FiPhone />
              </BoxLineItemIcon>
              <BoxText>{patient?.phone}</BoxText>
            </BoxLineItem>
          </BoxLine>
        </Box>

        <Box>
          <BoxLine>
            <BoxLineItem>
              <BoxLineItemIcon bg='#E0F0E9'>
                <BsArrowClockwise />
              </BoxLineItemIcon>
              <BoxText>
                Age: {patient?.age ? `${patient?.age} ans` : '/'}
              </BoxText>
            </BoxLineItem>
            <BoxLineItem>
              <BoxLineItemIcon bg='#E0F0E9'>
                {getSexIcon(patient?.sexe)}
              </BoxLineItemIcon>
              <BoxText>
                Sexe: {patient?.sexe ? getSexText(patient?.sexe) : '/'}
              </BoxText>
            </BoxLineItem>
          </BoxLine>
          <BoxLine>
            <BoxLineItem>
              <BoxLineItemIcon bg='#E0F0E9'>
                <GiPill />
              </BoxLineItemIcon>
              <BoxText>
                Notes:
                {patient?.pathology
                  ? `
                  ${patient?.pathology?.substring(0, 36)}
                  ${patient?.pathology?.length > 36 ? '...' : ''}
                  `
                  : '/'}
              </BoxText>
            </BoxLineItem>
          </BoxLine>
        </Box>
      </Flex>
    </Container>
  )
}

export default IntroPatient
