import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import mixpanel from 'mixpanel-browser'
import { Form, Label, ErrorMessage } from './style'
import { Button, Field } from '../../../ui'
import { ActionType } from '../../../../utils/constants'
import { useQuery } from '../../../../utils/hooks/getQuery'
import PasswordLevel from './password-level'
import { useHistory } from 'react-router'
import { Role } from '../../../../services/api/types'
import { useCreateUser } from '../../../../services/api/users/users'
import { useAuth } from '../../../../services/auth/provider'

const RegisterCredentialsSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Minimum 8 charactère')
    .required('Champs requis'),
  email: Yup.string().email('Email invalide').required('Champs requis'),
  acceptTerms: Yup.boolean()
    .required('Vous devez accepter les termes et conditions')
    .oneOf([true], 'Vous devez accepter les termes et conditions'),
})

const RegisterCredentialsForm = ({
  onRegisterStatusChange,
  registerStatus,
}) => {
  const query = useQuery()
  const firstName = query.get('fn')
  const lastName = query.get('ln')
  const profession = query.get('profession')
  const email = query.get('email')
  const { replace } = useHistory()
  const { login } = useAuth()
  const { mutateAsync: createUser } = useCreateUser()

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: email ? email : '',
        password: '',
        acceptTerms: false,
      },
      validationSchema: RegisterCredentialsSchema,
      onSubmit: async values => {
        //TODO Edit prescriber
        onRegisterStatusChange(ActionType.LOADING)
        if (!!firstName && !!lastName) {
          mixpanel.register({
            Email: values.email,
          })
          mixpanel.track('Register', {
            email: values.email,
          })
          await createUser({
            data: {
              firstName: firstName,
              lastName: lastName,
              profession: profession,
              email: values?.email,
              password: values?.password,
              role: Role.PRESCRIBER,
            },
          })
            .then(async () => {
              await login(values.email, values.password, Role.PRESCRIBER)
              onRegisterStatusChange(ActionType.SUCCESS)
              replace('/')
            })
            .catch(err => handleCreatingUserError(err))
            .finally(() => onRegisterStatusChange(undefined))
        }
      },
    })

  //Function
  function handleCreatingUserError(err) {
    if (
      err.response.data.toString().includes('gateway/unable-to-create-user')
    ) {
      toast.error(
        "Impossible de créer un compte avec l'adresse renseignée, veuillez contacter notre support"
      )
      return
    }
    toast.error('Une erreur est survenue lors de la création de votre compte')
  }

  //Render
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label='Email'
        name='email'
        id='register-email'
        type='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        isDisabled={registerStatus === ActionType.LOADING}
        placeholder='Insérez votre adresse mail'
      />
      <Field
        label='Mot de passe'
        id='register-password'
        name='password'
        type='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        isDisabled={registerStatus === ActionType.LOADING}
        placeholder='Insérez votre mot de passe'
      />
      <PasswordLevel name={values.password} />

      <Label>
        <input
          type='checkbox'
          name='acceptTerms'
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.acceptTerms}
        />
        <p>
          {' '}
          J’accepte{' '}
          <a
            href='https://www.simplycure.com/politique-de-cookies'
            target='_blank'
            rel='noreferrer'
          >
            la Politique de Cookie
          </a>
          ,{' '}
          <a
            href='https://www.simplycure.com/politique-de-vie-privee'
            target='_blank'
            rel='noreferrer'
          >
            de Vie privée
          </a>
          ,{' '}
          <a
            href='https://www.simplycure.com/traitement-de-donnees'
            target='_blank'
            rel='noreferrer'
          >
            la Convention de traitement de données
          </a>{' '}
          <a
            href='https://www.simplycure.com/conditions-generales-dutilisation'
            target='_blank'
            rel='noreferrer'
          >
            Conditions générales d’utilisation de Simplycure{' '}
          </a>{' '}
          .
        </p>
      </Label>
      {touched.acceptTerms && errors.acceptTerms ? (
        <ErrorMessage style={{ color: 'red' }}>
          {errors.acceptTerms}
        </ErrorMessage>
      ) : null}
      <Button type='submit' isLoading={registerStatus === ActionType.LOADING}>
        Créer un compte
      </Button>
    </Form>
  )
}

export default RegisterCredentialsForm
