import {
  Container,
  CloseButtonContainer,
  CloseButton,
  Info,
  InfoWrapper,
} from './style'
import { Dialog } from '../../../../ui'
import { IconCross } from '../../../../ui/icon/icons'
import axios from 'axios'
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai'
import { useQuery } from 'react-query'

const fetchEventData = async eventId => {
  const url = `https://api.livestorm.co/v1/events/${eventId}`
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/vnd.api+json',
      Authorization: process.env.REACT_APP_LIVESTORM_API_KEY,
    },
  }

  const response = await axios(url, options)
  return response.data
}

const WebinarDialog = ({ isOpen, onClose, eventId, date }) => {
  const { data, error } = useQuery(['event', eventId], () =>
    fetchEventData(eventId)
  )

  function convertMinutesToText(minutes) {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    let result = 'Environ '

    if (hours > 0) {
      result += hours + ' heure'
      if (hours > 1) {
        result += 's'
      }
      result += ' et '
    }

    if (remainingMinutes > 0) {
      result += remainingMinutes + ' minute'
      if (remainingMinutes > 1) {
        result += 's'
      }
    }

    return result
  }

  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      type='large'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={onClose}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        {error ? (
          <p>
            Erreur dans l'extraction des données. Veuillez rafraîchir la page.
          </p>
        ) : (
          <>
            <h1>{data?.data?.attributes?.title}</h1>

            <InfoWrapper>
              <Info>
                <span>
                  <AiOutlineCalendar />
                </span>
                <p>{date}</p>
              </Info>
              <Info>
                <span>
                  <AiOutlineClockCircle />
                </span>
                <p>
                  {convertMinutesToText(
                    data?.data?.attributes?.estimated_duration
                  )}
                </p>
              </Info>
            </InfoWrapper>

            <div
              dangerouslySetInnerHTML={{
                __html: data?.data?.attributes?.description,
              }}
            ></div>
          </>
        )}
      </Container>
    </Dialog>
  )
}

export default WebinarDialog
