import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const BodyContainer = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 3.75rem;
  ${MediaQuery.M_AND_UP} {
    width: 37.5rem;
    margin: 0 auto;
  }
`

export const Title = styled.h1`
  color: #080038;
  margin-top: 2rem;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  .partword {
    color: #3a845e;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`
export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  ${MediaQuery.M_AND_UP} {
    position: relative;
    margin-top: 40px;
  }
`
export const Footer = styled.p`
  color: #080038;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-top: 0.5rem;
  .partword {
    color: #f46363;
    cursor: pointer;
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.875rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`
