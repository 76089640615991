import styled from 'styled-components/macro'
import { Color } from '../../../../utils/constants'

export const HeaderSection = styled.section`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
  flex-direction: column;
  padding-bottom: 50px;
`

export const MainTitle = styled.h1`
  font-size: 32px;
`

export const SecondaryTitle = styled.h3`
  font-size: 22px;
  margin: 15px 0;
`

export const BodySection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 50px;
  align-items: flex-start;
`

export const P = styled.p`
  font-size: 16px;
  white-space: pre-wrap;
  margin-bottom: 10px;
`
export const PrescriptionProductsListWrappper = styled.div`
  width: 700px;
`

export const RecoLink = styled.p`
  font-size: 14px;
  color: ${Color.DEEP_SEA};
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 3px;
  word-wrap: break-word;
  max-width: 100%;
`

export const Pane = styled.div`
  display: flex;
  width: 100%;
`

export const Note = styled(P)`
  background-color: #ddd;
  padding: 10px;
  border-radius: 10px;
`

export const RecoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const ContentFlex = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

export const SummaryOrderWrapper = styled.div`
  position: sticky;
  top: 90px;
  left: 0;
  margin-top: 37px;
  width: 35%;
`

export const Tab = styled.div`
  margin-top: 40px;
  margin-bottom: -10px;
  display: flex;
  justify-content: space-between;
`

export const TabItem = styled.div`
  cursor: pointer;
  width: 50%;
  padding: 15px;
  text-align: center;
  border-bottom: ${props =>
    props.active ? `1px solid ${Color.DEEP_SEA}` : ''};
  color: ${props => (props.active ? Color.DEEP_SEA : '#777')};
`
