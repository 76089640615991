import { toast } from 'react-toastify'
import {
  Form,
  InputsFlexWrapper,
  InputWrapper,
  Label,
  Input,
  Button,
} from './style'
import { useFormik } from 'formik'
import { useGetMe } from '../../../utils/hooks/getMe'

import { useUpdatePrescriber } from '../../../services/api/prescriber/prescriber'

const ProfileView = () => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const { mutateAsync: updatePrescriber } = useUpdatePrescriber()
  const { handleSubmit, handleChange, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: prescriber?.firstName ? prescriber?.firstName : '',
      name: prescriber?.lastName ? prescriber?.lastName : '',
      email: prescriber?.email ? prescriber?.email : '',
      bankAccount: prescriber?.bankAccount ? prescriber?.bankAccount : '',
    },
    onSubmit: values => {
      updatePrescriber({
        prescriberId: prescriber?.id,
        data: {
          firstName: values.firstname,
          lastName: values.name,
          phoneNumber: prescriber?.phone || '',
          enterpriseName: prescriber?.enterpriseName || '',
          tvaNumber: prescriber?.tvaNumber || '',
          invoiceAddress: prescriber?.invoiceAddress || '',
          bankAccount: values.bankAccount,
          activeDiscountId: prescriber?.activeDiscountId,
          calendlyCode: prescriber?.calendlyCode,
          isOnboarded: prescriber?.isOnboarded,
          profession: prescriber?.profession,
          disableCommission: prescriber?.disableCommission,
        },
      })
        .then(() => {
          toast.success('Votre profil a été mis à jour avec succès')
        })
        .catch(() => {
          toast.error("Une erreur s'est produite, réessayez plus tard")
        })
    },
  })

  // Render
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <InputsFlexWrapper>
          <InputWrapper width='42%'>
            <Label>Prénom *</Label>
            <Input
              name='firstname'
              type='text'
              onChange={handleChange}
              value={values.firstname}
            />
          </InputWrapper>
          <InputWrapper width='50%'>
            <Label>Nom de famille *</Label>
            <Input
              name='name'
              type='text'
              onChange={handleChange}
              value={values.name}
            />
          </InputWrapper>
        </InputsFlexWrapper>

        <InputsFlexWrapper>
          <InputWrapper width='100%'>
            <Label>Email</Label>
            <Input
              style={{ cursor: 'not-allowed' }}
              name='email'
              type='text'
              value={values.email}
            />
          </InputWrapper>
        </InputsFlexWrapper>

        <InputsFlexWrapper>
          <InputWrapper width='100%'>
            <Label>Compte Bancaire</Label>
            <Input
              name='bankAccount'
              type='text'
              onChange={handleChange}
              value={values.bankAccount}
            />
          </InputWrapper>
        </InputsFlexWrapper>
        <InputsFlexWrapper>
          <InputWrapper width='40%'>
            <Label></Label>
            <Button type='submit'>Enregistrer</Button>
          </InputWrapper>
        </InputsFlexWrapper>
      </Form>
    </div>
  )
}

export default ProfileView
