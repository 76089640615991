import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../../utils/constants'

export const Wrapper = styled.div`
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
`

export const Commission = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #ff9138;
  font-size: 12px;
  font-weight: 300;
  span {
    margin-right: 10px;
    background-color: #ffe9d7;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
`

export const LoadingIcon = styled.span`
  font-size: 10px;
  display: block;
  margin-top: 0px;
  margin-left: 10px;
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const Pricing = styled.div`
  margin: 30px 0 30px 0;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  padding: 30px 15px 15px 15px;
`

export const PricingLine = styled.div`
  font-weight: 300;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`
export const TotalPricingLine = styled.div`
  font-weight: 300;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  background-color: #e7e9f4;
  padding: 15px 20px;
  border-radius: 5px;
`
export const Highlight = styled.span`
  background-color: ${props => (props.bg ? props.bg : '#e0f0e9')};
  color: ${props => (props.bg ? 'inherit' : Color.DEEP_SEA)};
  padding: 3px 6px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 10px;
`

export const PaymentMethods = styled.div`
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  padding: 15px;
  span {
    display: block;
    margin-bottom: 15px;
  }
`

export const PaymentMethodsText = styled.div`
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
`
export const PaymentImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PaymentImage = styled.img`
  width: 40px;
  height: 25px;
  border-radius: 5px;
  object-fit: contain;
  background-color: white;
  filter: grayscale(20%);
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
`

export const Hr = styled.hr`
  margin-bottom: 20px;
  border: 1px dotted #eee;
`
export const ProgressBarWrapper = styled.div`
  margin-top: 20px;
`

export const ProgressBarWrapperTitle = styled.h4`
  margin-bottom: 10px;
  font-size: 10px;
  text-align: center;
`

export const ProgressBar = styled.div`
  height: 8px;
  width: 100%;
  background-color: #e7e7e7;
  border-radius: 30px;
  position: relative;
  span {
    display: block;
    height: 100%;
    background-color: #00865c;
    border-radius: 30px;
    max-width: 100%;
  }
`

export const OrWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  hr {
    width: 40%;
    opacity: 0.4;
  }
`

export const PromoCode = styled.div`
  display: flex;
  margin: 30px 0 30px 0;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  padding: 15px;

  font-size: 12px;
  span {
    font-size: 18px;
    margin-top: 3px;
    margin-right: 5px;
  }
`

export const PromoCodeMobile = styled.div`
  margin: 30px 0 30px 0;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  padding: 15px;

  font-size: 12px;
`
