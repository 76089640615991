import {
  FlexWrapper,
  HandleQuantityFlexWrapper,
  HandleQuantity,
  MinusButton,
  PlusButton,
  Quantity,
  QuantityButton,
  Price,
  PriceLimiter,
} from '../style'
import { getTakePackagingFormatName } from '../../../../utils/helpers/functions'
import { PageType } from '../../../../utils/constants'
import { QuantityProps } from './props'
import DiscountPrice from '../discount-price'
import useProductQuantityLogic from './logic'

const QuantityC = ({
  allowingAddProduct,
  from,
  onClick,
  product,
  numberOfCapsule,
  mode,
  setNumberOfCapsule,
  hideDiscountPercentage,
  type,
}: QuantityProps) => {
  // Attributes
  const {
    addQuantity,
    criteriaToDisplay,
    displayDiscountPercentageDisplayed,
    discountedPrice,
    handleOnMinusClick,
    handleOnPlusClick,
    originalPrice,
    role,
  } = useProductQuantityLogic({
    allowingAddProduct,
    from,
    numberOfCapsule,
    onClick,
    product,
    setNumberOfCapsule,
  })
  const handleQuantityFlexWrapperClick = e => {
    e.stopPropagation()
  }

  // Render
  return (
    <FlexWrapper>
      <Price $mode={mode}>
        <DiscountPrice
          discountPercentage={displayDiscountPercentageDisplayed(
            product?.brand?.name
          )}
          originalPrice={originalPrice}
          discountedPrice={discountedPrice}
          hideDiscountPercentage={hideDiscountPercentage}
          from={from}
        />
        <PriceLimiter $mode={mode}>
          /
          {getTakePackagingFormatName(
            numberOfCapsule,
            product?.packagingFormat
          )}
        </PriceLimiter>
      </Price>
      {criteriaToDisplay && type !== PageType.FAVORITE && (
        <HandleQuantityFlexWrapper onClick={handleQuantityFlexWrapperClick}>
          <HandleQuantity role={role}>
            <MinusButton onClick={handleOnMinusClick}>-</MinusButton>
            <Quantity>{numberOfCapsule}</Quantity>
            <PlusButton onClick={handleOnPlusClick}>+</PlusButton>
          </HandleQuantity>
          <QuantityButton onClick={addQuantity}>Ajouter</QuantityButton>
        </HandleQuantityFlexWrapper>
      )}
    </FlexWrapper>
  )
}

export default QuantityC
