import styled from 'styled-components/macro'

export const ResultActionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

export const ResultActionButton = styled.div`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`
