import styled, { keyframes } from 'styled-components'
import { MdClose } from 'react-icons/md'

export const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

export const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`

export const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: opacity 0.3s;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
`

export const DialogWrapper = styled.div<{ isOpen: boolean }>`
  background: white;
  width: 100%;
  transition: transform 0.3s;
  animation: ${({ isOpen }) => (isOpen ? slideUp : slideDown)} 0.3s forwards;
  height: 80%;
  overflow-y: auto;
  padding: 1.5rem 0.625rem 0.625rem 0.625rem;
  border-radius: var(--Spacing-System-radius-8, 0.5rem)
    var(--Spacing-System-radius-8, 0.5rem) 0rem 0rem;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1.5rem;
  margin-bottom: 1.625rem;
  align-items: center;
`

export const ContentDiv = styled.div`
  height: 100%;
`

export const Title = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Popins', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const CloseIcon = styled(MdClose)`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`

export const LastElement = styled.div`
  width: 1.125rem;
`
