import { useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import {
  BoxTable,
  TitleBox,
  Title,
  Table,
  NoPrescriptionMessage,
  TableHeader,
  TableHeaderWrapper,
  TableBody,
  TableHeaderItem,
} from './style'
import { Button } from '../../ui'

import { useGetMe } from '../../../utils/hooks/getMe'

import {
  useGetPatient,
  useGetPatientNotes,
} from '../../../services/api/patients/patients'

import PatientQuizzes from './patient-notes'
import BlockAccessModal from '../../../components/block-access-modal'

interface QuizzesTableProps {
  tableTitle?: string
  size?: number
}

const QuizzesTable = ({ tableTitle, size }: QuizzesTableProps) => {
  // Attributes
  const history = useHistory()
  const match = useParams()
  const { data: prescriber } = useGetMe()

  const { data: patient } = useGetPatient(prescriber?.id, Number(match.id))
  const { data: patientNotes } = useGetPatientNotes(patient?.id, {
    size: size ?? undefined,
    sort: 'DATE',
  })
  const [isBlockAccessModalOpen, setIsBlockAccessModalOpen] = useState(false)

  // Render
  return (
    <BoxTable>
      <TitleBox>
        <Title>
          {tableTitle ? tableTitle : 'Les notes de '}
          {patient?.firstName}
        </Title>

        <Button
          iconLeft={undefined}
          appearance='minimal'
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
          onClick={() => {
            if (prescriber?.isVerified)
              history.push(
                `/notes/new?patientId=${patient?.id}&backUrl=/patients/${patient?.id}`
              )
            else setIsBlockAccessModalOpen(true)
          }}
          style={{
            border: '1px solid #3a845e',
          }}
        >
          Nouvelle note
        </Button>
      </TitleBox>
      {patientNotes?.data?.length === 0 ? (
        <NoPrescriptionMessage>
          {patient?.firstName} n'a aucune note
        </NoPrescriptionMessage>
      ) : (
        <Table>
          <TableHeader>
            <TableHeaderWrapper>
              <TableHeaderItem>Titre</TableHeaderItem>
              <TableHeaderItem>Date de création</TableHeaderItem>
              <TableHeaderItem>Dernière modification</TableHeaderItem>
              <TableHeaderItem></TableHeaderItem>
            </TableHeaderWrapper>
          </TableHeader>
          <TableBody>
            {patientNotes?.data?.map(
              ({ createdAt, description, title, id, modifiedAt }) => {
                return (
                  <PatientQuizzes
                    createdAt={createdAt}
                    modifiedAt={modifiedAt}
                    title={title}
                    description={description}
                    id={id}
                    key={id}
                  />
                )
              }
            )}
          </TableBody>
        </Table>
      )}

      <BlockAccessModal
        isOpen={isBlockAccessModalOpen}
        setIsOpen={setIsBlockAccessModalOpen}
      />
    </BoxTable>
  )
}

export default QuizzesTable
