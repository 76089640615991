import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const Container = styled.div`
  margin-top: 40px;
  width: 440px;
  text-align: center;
  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
  }
`
