import styled from 'styled-components/macro'

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 0.3125rem 0.625rem;
  width: 100%;
`
export const MenuContainerHeader = styled.div`
  padding: 0.3125rem;
  margin-bottom: 0.3125rem;
  border-bottom: 0.0625rem #e7e9f4 solid;
  width: 100%;
  font-size: 0.75rem;
`
export const CreateCategoryButton = styled.button`
  cursor: pointer;
  float: right;
  background: transparent;
  border: none;
`
export const ButtonFavorite = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 0.625rem;
  background: #3a845e;
  border: 0.0625rem solid #3a845e;
  border-radius: 0.5rem;
  margin-top: 0.75rem;
  font-size: 0.875rem;
  text-align: center;
  color: #ffffff;
`

export const MenuContainerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.3125rem 0.625rem;
  div {
    display: flex;
    align-items: center;
  }
  p {
    margin-bottom: 0.125rem;
  }
`
export const MenuContainerItemButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
`

export const Checkbox = styled.div`
  width: 0.9375rem;
  height: 0.9375rem;
  border: 0.125rem solid #65b293;
  margin-right: 0.625rem;
  border-radius: 0.1875rem;
  background-color: ${props => (props.checked ? '#65b293' : '')};
`
