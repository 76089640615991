import styled from 'styled-components/macro'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 47px 0 8px 0;

  > button {
    margin-top: 20px;
  }
`

export const FooterText = styled.div`
  font-size: 12px;
  margin-top: 20px;
  max-width: 280px;
  margin-bottom: 5px;
`

export const FooterTextContent = styled.p`
  display: inline;
  cursor: pointer;
  font-size: 12px;
  color: #8f95b2;
  text-align: right;
  margin-bottom: 32px;
`

export const Error = styled.p`
  color: red;
  font-size: 12px;
`
