import styled from 'styled-components/macro'

export const AdjustmentWrapper = styled.div`
  margin: 10px 0 20px 0;
  display: flex;
  align-items: center;
`

export const AdjustmentItem = styled.span`
  cursor: pointer;
  margin-right: 10px;
  padding: 6px 10px;
  background: #ffffff;
  border: 1px solid #e7e9f4;
  border-radius: 100px;
  font-size: 10px;
  color: #8f95b2;
  display: flex;
  align-items: center;
`

export const AdjustmentItemQuantityRight = styled.span`
  cursor: pointer;
  padding-right: 15px;
  padding-left: 5px;

  font-size: 15px;
`

export const AdjustmentItemQuantityLeft = styled.span`
  cursor: pointer;
  padding-left: 15px;
  padding-right: 5px;
  font-size: 15px;
`
export const SubMenu = styled.div`
  height: 200px;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 20px 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  & > * :not(:last-child) {
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
    color: inherit;
    outline: none;
    font-size: 14px;
    opacity: 0.8;
  }
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  min-width: 100px;
`
export const MenuItemInput = styled.input`
  margin-right: 5px;
  cursor: pointer;
  &[type='checkbox'] {
    appearance: none;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 1px solid #00875c;
    border-radius: 3px;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }

  &[type='checkbox']:checked:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00875c;
    border-left: 2px solid #00875c;
    border-bottom: 2px solid #00875c;
  }
`
export const MenuItemLabel = styled.label`
  cursor: pointer;
  margin-top: 3px;
  font-size: 13px;
  opacity: 0.7;
`
export const ReturnIcon = styled.div`
  margin-left: 10px;
  cursor: pointer;
  color: #8f95b2;
`
