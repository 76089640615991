import { useState } from 'react'
import { CollapseButton, CollapseContent, Accordion } from './style'
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from 'react-icons/md'
import { FaqItemProps } from './props'

const FaqItem = ({ question, text }: FaqItemProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  const toggleVisibility = () => {
    setVisible(!visible)
  }

  return (
    <Accordion>
      <CollapseButton onClick={toggleVisibility}>
        <p>{question}</p>
        <span>
          {visible ? (
            <MdOutlineKeyboardArrowUp />
          ) : (
            <MdOutlineKeyboardArrowDown />
          )}
        </span>
      </CollapseButton>
      <CollapseContent visible={visible}>{text}</CollapseContent>
    </Accordion>
  )
}

export default FaqItem
