import {
  Container,
  Content,
  Title,
  Subtitle,
  Top,
  Right,
  TitleBlock,
} from './style'
import { PageTitleProps } from './props'

const PageTitle = ({
  title,
  subtitleInfo,
  subtitle,
  rightElements,
  rightSecondElements,
  titleClassName,
  styleContent,
  style,
}: PageTitleProps) => (
  <Container style={style}>
    <Content styleContent={styleContent}>
      <Top>
        <TitleBlock>
          <Title className={titleClassName}>{title}</Title>
          {subtitleInfo && (
            <Title className={titleClassName}>{subtitleInfo}</Title>
          )}
        </TitleBlock>
        {rightSecondElements && <Right>{rightSecondElements}</Right>}
        {rightElements && <Right>{rightElements}</Right>}
      </Top>

      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Content>
  </Container>
)

export default PageTitle
