/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getAllProductLabels = (
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string[]>(
    { url: `/product-labels`, method: 'get' },
    options
  )
}

export const getGetAllProductLabelsQueryKey = () => [`/product-labels`]

export const useGetAllProductLabels = <
  TData = AsyncReturnType<typeof getAllProductLabels>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<typeof getAllProductLabels>,
    TError,
    TData
  >
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllProductLabelsQueryKey()

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllProductLabels>> =
    () => getAllProductLabels(requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getAllProductLabels>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query,
  }
}
