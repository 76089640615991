import { useRef, useEffect, useState } from 'react'
import { Input, InputWrapper2, AddButton, Message } from '../../style'
import { SearchPatientInputProps } from './props'
import CreateNewPatient from '../../create-new-patient'

const SearchPatientInput = ({
  setShowPatientList,
  setEmail,
  addEmail,
  email,
  patients,
  setIsInnerDialogOpen,
}: SearchPatientInputProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [patient, setPatient] = useState(null)
  const inputRef = useRef(null)

  // Functions
  const handleInputChange = e => {
    setShowPatientList(true)
    setEmail(e.target.value)
  }

  const handleInputKeyDown = e => {
    if (e.key === 'Enter' && email) {
      addEmail(email).then(patient => {
        setPatient(patient)
        setIsDialogOpen(true)
      })
      setShowPatientList(false)
    }
  }

  const handleAddButtonClick = () => {
    addEmail(email).then(patient => {
      setPatient(patient)
      setIsDialogOpen(true)
    })
    setShowPatientList(false)
  }

  useEffect(() => {
    // Focus the input element when the component is mounted
    inputRef.current.focus()
  }, [])

  return (
    <>
      <InputWrapper2>
        <Input
          placeholder='Rechercher un client ou ajouter un email pour en créer un nouveau'
          value={email}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          ref={inputRef}
        />
        {!patients?.data?.length && (
          <AddButton onClick={handleAddButtonClick}>Créer</AddButton>
        )}

        <CreateNewPatient
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          setIsInnerDialogOpen={setIsInnerDialogOpen}
          patient={patient}
        />
      </InputWrapper2>
      {!patients?.data?.length && <Message>Tapez sur enter</Message>}
    </>
  )
}

export default SearchPatientInput
