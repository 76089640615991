const Constants = {
  EDIT_PRESCRIBER_FAILED: `EDIT_PRESCRIBER_FAILED`,
  EDIT_PRESCRIBER_LOADING: `EDIT_PRESCRIBER_LOADING`,
  EDIT_PRESCRIBER_RESET: `EDIT_PRESCRIBER_RESET`,
  EDIT_PRESCRIBER_SUCCESS: `EDIT_PRESCRIBER_SUCCESS`,
  ADD_DISCOUNT_CODES_FAILED: `ADD_DISCOUNT_CODES_FAILED`,
  ADD_DISCOUNT_CODES_LOADING: `ADD_DISCOUNT_CODES_LOADING`,
  ADD_DISCOUNT_CODES_SUCCESS: `ADD_DISCOUNT_CODES_SUCCESS`,
  ADD_CALENDLY_CODE_FAILED: `ADD_CALENDLY_CODE_FAILED`,
  ADD_CALENDLY_CODE_LOADING: `ADD_CALENDLY_CODE_LOADING`,
  ADD_CALENDLY_CODE_SUCCESS: `ADD_CALENDLY_CODE_SUCCESS`,
}

export default Constants
