import styled from 'styled-components/macro'

export const ElementGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ControlContainer = styled.div`
  position: absolute;
  top: -5px;
  right: 100%;
  padding: 10px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  > div {
    cursor: pointer;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 327px;
`

export const Infos = styled.div`
  display: flex;
  margin-top: 4px;
`

export const InfosContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin: 1em 0;
`

export const MoreInfosContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`

export const MoreInfosText = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #080038;
`

export const CommissionInfo = styled.p`
  font-size: 12px;
  position: relative;
`

export const DiscountInfo = styled.p`
  font-size: 12px;
  position: relative;
  width: 50%;
`

export const ExampleText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #080038;
  text-align: center;
  margin-bottom: 16px;
`

export const FlexCardAmount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 12px;
`

export const CardAmount = styled.div`
  border-radius: 8px;
  width: 158px;
  height: 116px;
  padding: 16px;
  background: #f8f9fc;
`

export const CardAmountText = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #080038;
`

export const CardAmountTextAmount = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #080038;
`

export const PromoCode = styled.p`
  font-size: 13px;
  opacity: 0.7;
`
