import styled from 'styled-components/macro'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InfoCircle = styled.div`
  margin-top: 0.3125rem;
  margin-left: 0.1875rem;
`

export const PhoneInputLabel = styled.div`
  display: flex;
  align-items: center;
`

export const SpaceDiv = styled.div`
  margin-bottom: 2.25rem;
`
