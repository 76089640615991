import React from 'react'
import { StepContainer, Step, Number, Text } from './style'

const StepEl = ({ currentStep, steps, texts }) => (
  <StepContainer>
    {[...Array(steps).keys()].map(step => {
      const realStep = step + 1

      return (
        <Step key={step}>
          <Number isActive={currentStep >= realStep}>{realStep}</Number>
          <Text>{texts[step]}</Text>
        </Step>
      )
    })}
  </StepContainer>
)

StepEl.defaultProps = {
  currentStep: 1,
  steps: 3,
  texts: [],
}

export default StepEl
