import { useEffect } from 'react'

function isRefChild(element, root) {
  return root?.contains(element) && Boolean(element.closest('body'))
}

const isEventOutsideEl = (e, ref) =>
  e?.target && ref?.current && !isRefChild(e.target, ref.current)

const useClickOutside = ({ onClick, ref, exceptionRefs = [] }) => {
  const handleClick = event => {
    if (typeof onClick !== 'function') return

    if (exceptionRefs.length) {
      if (
        exceptionRefs.every(reference => isEventOutsideEl(event, reference)) &&
        isEventOutsideEl(event, ref)
      ) {
        onClick(event)
      }
    } else if (isEventOutsideEl(event, ref)) {
      onClick(event)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    document.addEventListener('touchstart', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('touchstart', handleClick)
    }
  })
}

export default useClickOutside
