import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const FlexItem = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
  }
`
export const CTAContainer = styled.div``

export const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

export const BackIcon = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.DEEP_SEA};
  background-color: #d8e6df;
`

export const TextContainer = styled.div`
  margin-left: 20px;
`

export const TextContainerHeader = styled.h3`
  color: #080038;
  font-size: 16px;
  margin-bottom: 3px;
`
export const TextContainerP = styled.p`
  color: #8f95b2;
  font-size: 12px;
`

export const Brand = styled.p`
  cursor: pointer;
  background: ${props => (props.selected ? ' #e0f0e9' : '')};
  color: ${props => (props.selected ? ' #65b293' : '  #8F95B2')};
  border-radius: 6px;
  padding: 8px 10px;
  font-size: 12px;
  margin-right: 20px;
  text-decoration: none;
`

export const P = styled.p`
  color: #8f95b2;
  font-size: 12px;
  margin-right: 20px;
`
export const ProtocolButton = styled.button`
  cursor: pointer;
  color: #65b293;
  border-radius: 6px;
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #65b293;
  background: transparent;
`
