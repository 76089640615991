import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../../utils/constants'

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.p`
  color: #393360;
  font-size: 12px;
`
export const SelectPills = styled.div`
  background: none;
  outline: none;
  color: #3a835f;
  border-radius: 15px;
  padding: 5px;
  border: none;
  font-size: 10px;
  margin: 0 8px;
  display: flex;
  align-items: center;
`
export const SelectPillsText = styled.p`
  font-size: 13px;
  margin-right: 10px;
`

export const SelectPillsQuantity = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 3px;
  }
`
export const SelectPillsQuantityButton = styled.button`
  font-size: 8px;
  cursor: pointer;
  border-radius: ${props =>
    props.position === 'top' ? '3px 3px 0px 0px' : '0px 0px 3px 3px'};

  padding: 0px 5px;
  background-color: #e7f0eb;
  border: none;
  color: #3a835f;
`

export const SecondaryTitle = styled.h2`
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 400;
  scroll-margin: 56px;
  margin-right: 30px;
`

export const Wrapper = styled.div`
  overflow: auto;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border-radius: 8px;
  background: white;
  padding: 24px 20px;
  margin-bottom: 32px;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  & > div:not(:last-child) {
    margin-right: ${props => props.gap};
  }

  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
`

export const Table = styled.table`
  border-radius: 15px;
  color: #393360;
  width: 100%;
  text-align: center;
`

export const HorizontalSeparator = styled.div`
  border: 0.5px solid #e9eaf0;
`

export const IngredientContainer = styled.div`
  padding: 16px 24px;
  margin-left: 0px;
  border-radius: 8px;
`

export const NutritionContainerHeader = styled.div`
  display: flex;
  align-items: center;
`
export const NutritionContainerTitle = styled.h3`
  color: #393360;
  font-size: 14px;
`

export const TableHeaderRow = styled.tr`
  margin-top: 20px;
  border-bottom: 0.5px solid black;
  border-top: 0.5px solid black;
  display: flex;
  padding: 5px;
  font-size: 14px;
`
export const TableHeaderCellIngredient = styled.th`
  width: 100px;
  display: flex;
  font-size: 12px;
  margin-right: 10px;
`

export const TableHeaderCell = styled.th`
  width: 100px;
  display: flex;
  font-size: 12px;
`

export const TableHeaderCellQuantity = styled.th`
  color: green;

  width: 70px;
  display: flex;
  font-size: 12px;
`

export const TableRow = styled.tr`
  padding: 5px;
  border-bottom: 0.5px solid #e9eaf0;
  margin-bottom: 5px;
  display: flex;
`

export const TableCellIngredient = styled.th`
  text-align: left;
  font-size: 12px;
  width: 100px;
  margin-right: 10px;
`

export const TableCell = styled.th`
  font-size: 11px;
  text-align: left;
  font-weight: 300;
  width: 100px;
`

export const TableCellQuantity = styled.th`
  color: green;
  width: 70px;
  text-align: start;
  font-size: 12px;
`

export const NutritionContainer = styled.div`
  padding: 16px 24px;
  margin-left: 0px;
  border-radius: 8px;
`

export const Select = styled.select`
  background: #f6f7fb;
  border-radius: 15px;
  padding: 5px;
  border: none;
  font-size: 10px;
  margin: 0 8px;
`

export const FlexList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;

  ${MediaQuery.S_AND_DOWN} {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Card = styled.div`
  border: 0.5px solid #e2e4f3;
  border-radius: 11px;
  padding: 10px;
  font-size: 10px;
  font-weight: 300;
  ${MediaQuery.S_AND_DOWN} {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const CardTitle = styled.div`
  color: #3a835f;
  font-size: 12px;
  margin-bottom: 5px;
`
export const CardText = styled.p`
  font-size: 12px;
  color: #393360;
  font-weight: 400;
`

export const SeeMore = styled.button`
  cursor: pointer;
  font-size: 12px;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  margin-top: 20px;
`

export const Icon = styled.span`
  margin-right: 4px;
  font-size: 12px;
`

export const Na = styled.p`
  color: black !important;
`
