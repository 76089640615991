import {
  Container,
  Title,
  Item,
  Img,
  TextContent,
  QuoteImg,
  Text,
  Footer,
  Line,
  Name,
  StarsIcon,
  ImgContainer,
  ImgText,
} from './style'
import Slider from 'react-slick'
import Trustpilot from './trustpilot'

const Testimony = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  // Render
  return (
    <Container>
      <link
        rel='stylesheet'
        type='text/css'
        href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
      />
      <link
        rel='stylesheet'
        type='text/css'
        href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
      />
      <Title>Nos avis clients</Title>

      <Trustpilot />

      <Slider {...settings}>
        {[
          {
            imgSrc:
              'https://user-images.trustpilot.com/5c0ee1014f494078d6b73295/73x73.png',
            text: "Super produits. Très bonnes formulations! Qualité du packaging top. L'aspect durabilité est bien pris en compte. Superbe expérience.",
            name: 'Nicolas Debray',
          },
          {
            text: "Excellent choix de compléments alimentaires, aux ingrédients faciles à consulter, clairement détaillés sur le site. On ressent assez rapidement les effets. Très chouette expérience utilisateur du site, prix doux, livraison rapide. Coup de cœur pour l'engagement social et écologique. Merci!",
            name: 'Cristina Manea',
          },
          {
            text: "Site bien clair, qui aide à faire le bon choix de compléments par rapport à ses propres besoins. La dose journalière est facilitée par les petits sachets (recyclables 👍). Je sens une réelle différence depuis le début de ma cure, et j'en suis à un mois 1/2 de traitement. Je pense tester très prochainement le collagène. Je recommande vivement, enfin une cure qui apporte de réels changements.",
            name: 'Anne Françoise',
          },
        ].map((i, index) => (
          <Item key={index}>
            {i.imgSrc ? (
              <Img src={i.imgSrc} />
            ) : (
              <ImgContainer>
                <ImgText>
                  {i.name
                    .split(/\s/)
                    .reduce(
                      (response, word) => (response += word.slice(0, 1)),
                      ''
                    )}
                </ImgText>
              </ImgContainer>
            )}
            <TextContent>
              <QuoteImg
                src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/quote653TGRGV.png?alt=media&token=a0b40694-115e-478a-b37f-e1d70f19f81c'
                alt='quote'
              />
              <Text>{i.text}</Text>
              <Footer>
                <Line></Line>
                <Name>{i.name}</Name>
                <StarsIcon>
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/star567bre.png?alt=media&token=0cd082a4-a941-4cc7-a52e-65356395cf51'
                    alt='star'
                  />
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/star567bre.png?alt=media&token=0cd082a4-a941-4cc7-a52e-65356395cf51'
                    alt='star'
                  />
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/star567bre.png?alt=media&token=0cd082a4-a941-4cc7-a52e-65356395cf51'
                    alt='star'
                  />
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/star567bre.png?alt=media&token=0cd082a4-a941-4cc7-a52e-65356395cf51'
                    alt='star'
                  />
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/compliment-backoffice.appspot.com/o/star567bre.png?alt=media&token=0cd082a4-a941-4cc7-a52e-65356395cf51'
                    alt='star'
                  />
                </StarsIcon>
              </Footer>
            </TextContent>
          </Item>
        ))}
      </Slider>
    </Container>
  )
}

export default Testimony
