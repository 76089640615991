import { Row, Cell } from '../style'
import {
  PrescriptionLinked,
  PrescriptionTypeWrapper,
  TrackingLink,
} from './style'
import { IOrderRowProps } from './props'
import Button from '../../ui/button'
import { PatientCell } from '../../ui'

const OrderRow = ({ order, prescriber }: IOrderRowProps) => {
  // Render
  return (
    <Row key={order.id}>
      <Cell>
        <PrescriptionTypeWrapper>{order?.id}</PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <PrescriptionTypeWrapper>{order?.createdAt}</PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <PrescriptionTypeWrapper>{order?.totalPrice}€</PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <PrescriptionTypeWrapper discount={order?.totalDiscounts}>
          {order?.totalDiscounts}€
        </PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <PrescriptionTypeWrapper>{order?.promoCode}</PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <PrescriptionTypeWrapper>
          <PrescriptionLinked> {order?.prescriptionId}</PrescriptionLinked>
        </PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <PrescriptionTypeWrapper>
          <PatientCell
            email={prescriber?.email}
            firstName={prescriber?.firstName}
            lastName={prescriber?.lastName}
            id={undefined}
          />
        </PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <PrescriptionTypeWrapper>
          <TrackingLink>Lien de tracking</TrackingLink>
        </PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <Button
          iconLeft={undefined}
          appearance='primary'
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
          style={{
            padding: '.3125rem .5rem',
            fontSize: '.75rem',
          }}
          onClick={undefined}
        >
          Voir commande
        </Button>
      </Cell>
    </Row>
  )
}

export default OrderRow
