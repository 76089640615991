import { useState } from 'react'

import Icon from '../icon'
import { IconButtonProps } from './props'
import { Container } from './style'

const IconButton = ({
  children,
  width = '1.75rem',
  height = '1.75rem',
  color = '#393360',
  onHoverColor,
  onHoverBgColor,
  bgColor = 'white',
  onClick,
  isLoading = false,
  borderRadius = '.375rem',
}: IconButtonProps) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <Container
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      bgColor={bgColor}
      onHoverColor={onHoverColor}
      onHoverBgColor={onHoverBgColor}
      width={width}
      height={height}
      borderRadius={borderRadius}
    >
      {isLoading ? (
        <Icon name='loading' />
      ) : (
        children(isHovering ? onHoverColor ?? color : color)
      )}
    </Container>
  )
}

export default IconButton
