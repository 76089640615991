/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type {
  VNRValueDTO,
  GetVNRValueByProductIdAndCountryAndSexeAndPatientTargetParams,
} from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getVNRValueByProductIdAndCountryAndSexeAndPatientTarget = (
  productId: string,
  params?: GetVNRValueByProductIdAndCountryAndSexeAndPatientTargetParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<VNRValueDTO[]>(
    { url: `/products/${productId}/vnr`, method: 'get', params },
    options
  )
}

export const getGetVNRValueByProductIdAndCountryAndSexeAndPatientTargetQueryKey =
  (
    productId: string,
    params?: GetVNRValueByProductIdAndCountryAndSexeAndPatientTargetParams
  ) => [`/products/${productId}/vnr`, ...(params ? [params] : [])]

export const useGetVNRValueByProductIdAndCountryAndSexeAndPatientTarget = <
  TData = AsyncReturnType<
    typeof getVNRValueByProductIdAndCountryAndSexeAndPatientTarget
  >,
  TError = unknown
>(
  productId: string,
  params?: GetVNRValueByProductIdAndCountryAndSexeAndPatientTargetParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<
        typeof getVNRValueByProductIdAndCountryAndSexeAndPatientTarget
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetVNRValueByProductIdAndCountryAndSexeAndPatientTargetQueryKey(
      productId,
      params
    )

  const queryFn: QueryFunction<
    AsyncReturnType<
      typeof getVNRValueByProductIdAndCountryAndSexeAndPatientTarget
    >
  > = () =>
    getVNRValueByProductIdAndCountryAndSexeAndPatientTarget(
      productId,
      params,
      requestOptions
    )

  const query = useQuery<
    AsyncReturnType<
      typeof getVNRValueByProductIdAndCountryAndSexeAndPatientTarget
    >,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}
