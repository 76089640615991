import styled from 'styled-components/macro'

export const Container = styled.div`
  opacity: 1 !important ;
  width: 200px;
  max-height: 300px;
  overflow-y: scroll;
  background-color: white;
  z-index: 100;
  padding: 20px 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 3px rgba(65, 91, 115, 0.1);
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`
export const PatientItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`

export const Inital = styled.span`
  font-size: 9px !important;
  background-color: #fcf2ef;
  color: #d7603a;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
`

export const FullName = styled.p`
  font-size: 14px;
`

export const InputPatientList = styled.input`
  width: 100%;
  padding: 7px 5px;
  border: 1px solid #eee;
  border-radius: 3px;
  outline: none;
`
