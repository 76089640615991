import { useState } from 'react'
import {
  Container,
  PatientItem,
  Inital,
  FullName,
  InputPatientList,
} from './style'
import { Spinner } from '../../../ui'

import { NoteState } from '../../../../pages/notes/props'
import { Updater } from 'use-immer'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { useGetPatients } from '../../../../services/api/patients/patients'

export interface PatientListProps {
  setNotes: (state: Updater<NoteState>) => void
}

const PatientList = ({ setNotes }: PatientListProps) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const [search, setSearch] = useState('')
  const { data: patients, isLoading } = useGetPatients(
    prescriber?.id,
    {
      q: search,
      size: 1000,
    },
    {
      query: {
        enabled: !!prescriber?.id,
      },
    }
  )

  // Render
  return (
    <Container>
      <InputPatientList
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder='Recherche de patient...'
      />
      {isLoading ? (
        <Spinner fullScreen={false} />
      ) : (
        patients?.data?.map(({ id, firstName, lastName }) => (
          <PatientItem
            onClick={() => {
              setNotes((state: NoteState) => {
                state.patientId = id
              })
            }}
            key={id}
          >
            <Inital>
              {firstName?.[0]}
              {lastName?.[0]}
            </Inital>
            <FullName>
              {firstName} {lastName}
            </FullName>
          </PatientItem>
        ))
      )}
    </Container>
  )
}

export default PatientList
