import { Helmet } from 'react-helmet'
import Layout from '../../components/layout/default'
import {
  Container,
  Content,
  SearchBarButton,
  SearchBarButtonText,
  SearchBarContainer,
  SearchBarInput,
  SearchBarLeftContainer,
  SearchBarVerticalLine,
  Result,
} from './style'
import { HeaderLinks } from '../../components/section/header/props'
import {
  getGetPatientsQueryKey,
  getPatients,
} from '../../services/api/patients/patients'
import { Spinner } from '../../components/ui'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useInfiniteQuery } from 'react-query'
import { PatientDTO } from '../../services/api/types'
import { useInView } from 'react-intersection-observer'
import { PatientCard } from '../../components/patient/card'
import { useGetMe } from '../../utils/hooks/getMe'

const SearchBarLoupe = () => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='34' height='34' rx='17' fill='#D7603A' fill-opacity='0.08' />
      <path
        d='M16.2273 22.4095C19.6414 22.4095 22.4091 19.6418 22.4091 16.2277C22.4091 12.8136 19.6414 10.0459 16.2273 10.0459C12.8132 10.0459 10.0455 12.8136 10.0455 16.2277C10.0455 19.6418 12.8132 22.4095 16.2273 22.4095Z'
        stroke='#D7603A'
        stroke-width='1.54545'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M23.9549 23.9551L20.5935 20.5938'
        stroke='#D7603A'
        stroke-width='1.54545'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

const PatientsPage = () => {
  // Attributes
  const { push } = useHistory()
  const { data: prescriber } = useGetMe()

  const [search, setSearch] = useState('')
  const PAGE_SIZE = 20
  const { ref, entry } = useInView()

  const {
    data: patientsData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery(
    getGetPatientsQueryKey(prescriber?.id, { q: search, size: PAGE_SIZE }),
    async params => {
      return await getPatients(prescriber?.id, {
        q: search,
        page: params?.pageParam,
        size: PAGE_SIZE,
      })
    },
    {
      // staleTime: STALE_TIME,
      getNextPageParam: page => {
        if (page?.page + 1 !== page?.nbPages) {
          return page?.page + 1
        } else {
          return undefined
        }
      },
    }
  )

  const patients = patientsData?.pages?.flatMap(page => page.data) as
    | PatientDTO[]
    | []

  // Functions
  const handleAddPatientClicked = () => {
    push('/patients/new')
  }

  // Effects
  useEffect(() => {
    // Scroll Observer
    if (entry?.isIntersecting && !isFetchingNextPage) {
      // Fetch the new page
      fetchNextPage()
    }
  }, [entry])

  // Render
  return (
    <Layout active={HeaderLinks.Patients}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Clients</title>
      </Helmet>
      <Container>
        <SearchBarContainer>
          <SearchBarLeftContainer>
            <SearchBarLoupe />
            <SearchBarVerticalLine />
            <SearchBarInput
              value={search}
              onChange={e => setSearch(e?.target?.value)}
              placeholder='Recherchez un client'
            />
          </SearchBarLeftContainer>
          <SearchBarButton onClick={handleAddPatientClicked}>
            <SearchBarButtonText>Ajouter un client</SearchBarButtonText>
          </SearchBarButton>
        </SearchBarContainer>

        <Result>
          {patientsData?.pages[0]?.totalAmount} résultat
          {patientsData?.pages[0]?.totalAmount > 1 && 's'}
        </Result>

        <Content>
          <PatientCard
            patient={{
              id: -1,
              createdAt: '',
              modifiedAt: '',
              email: 'john@exemple.com',
              firstName: 'Exemple',
              lastName: 'John',
              phone: '/',
              age: 30,
              sexe: 'MAN',
              pathology: '',
            }}
            index={0}
          />
          {isLoading ? (
            <Spinner fullScreen={false} />
          ) : (
            patients?.map((patient, index) => {
              const total = (index / PAGE_SIZE) * 100
              if (total % 50 === 0 && hasNextPage) {
                return (
                  <PatientCard
                    key={patient?.id}
                    patient={patient}
                    index={index}
                    ref={ref}
                  />
                )
              }
              return (
                <PatientCard
                  key={patient?.id}
                  patient={patient}
                  index={index}
                />
              )
            })
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default PatientsPage
