import styled from 'styled-components/macro'

export const Container = styled.div`
  border-radius: 12px;
  border: 1px solid #5a846a;
  padding: 15px;
  background: #fbfffc;
  margin: 20px 20px 0px 20px;
`

export const Title = styled.h3`
  font-size: 14px;
  margin-bottom: 5px;
`

export const Text = styled.p`
  font-size: 12px;
  opacity: 0.6;
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  button {
    font-size: 11px;
    color: #5a846a;
    cursor: pointer;
    margin-right: 20px;
    background-color: transparent;
    border: none;
    border: 1px solid #5a846a;
    padding: 4px 3px;
    border-radius: 3px;
  }
`
