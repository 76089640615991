import styled from 'styled-components/macro'

export const PatientItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 14px;
  cursor: pointer;
`

export const Inital = styled.span`
  margin-bottom: 5px;
  font-size: 14px !important;
  background-color: #fcf2ef;
  color: #d7603a;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
`

export const Email = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 300;
  border: ${props => (props.isPhoneNumberFieldFilled ? '1px solid red' : '')};
  margin-right: 10px;
  margin-bottom: 5px;
  span {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    margin-left: 5px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  margin-left: 5px;
  font-size: 20px;
`

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
`

export const AddPhoneNumber = styled.span`
  text-decoration: underline;
  opacity: 0.7;
`
export const Edit = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;

  span {
    margin-right: 4px;
    margin-top: 3px;
  }

  p {
    font-size: 13px;
  }
`

export const InfoCircle = styled.span`
  margin-left: 4px;
`
export const PhoneWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const PhoneIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 5px;
`
