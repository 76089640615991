import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: sticky;
  top: 100px;
  right: 0;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f8f9fc;
  border: 1px solid #e7e9f4;
  border-radius: 12px;
  margin-bottom: 30px;
`
