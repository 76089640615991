const Constants = {
  FETCH_AUTH_USER_FAILED: `FETCH_AUTH_USER_FAILED`,
  FETCH_AUTH_USER_LOADING: `FETCH_AUTH_USER_LOADING`,
  FETCH_AUTH_USER_RESET: `FETCH_AUTH_USER_RESET`,
  FETCH_AUTH_USER_SUCCESS: `FETCH_AUTH_USER_SUCCESS`,
  LOGIN_FAILED: `LOGIN_FAILED`,
  LOGIN_LOADING: `LOGIN_LOADING`,
  LOGIN_SUCCESS: `LOGIN_SUCCESS`,
  LOGOUT_FAILED: `LOGOUT_FAILED`,
  LOGOUT_LOADING: `LOGOUT_LOADING`,
  LOGOUT_SUCCESS: `LOGOUT_SUCCESS`,
  REGISTER_FAILED: `REGISTER_FAILED`,
  REGISTER_LOADING: `REGISTER_LOADING`,
  REGISTER_SUCCESS: `REGISTER_SUCCESS`,
  RESET_PASSWORD_FAILED: `RESET_PASSWORD_FAILED`,
  RESET_PASSWORD_LOADING: `RESET_PASSWORD_LOADING`,
  RESET_PASSWORD_SUCCESS: `RESET_PASSWORD_SUCCESS`,
  SEND_RESET_PASSWORD_SUCCESS: `SEND_RESET_PASSWORD_SUCCESS`,
  SEND_RESET_PASSWORD_FAILED: `SEND_RESET_PASSWORD_FAILED`,
  SET_AUTH_PRESCRIBER: `SET_AUTH_PRESCRIBER`,
  SET_IS_EMAIL_VERIFIED: `SET_IS_EMAIL_VERIFIED`,
  SET_TOKEN: `SET_TOKEN`,
  SET_UID: `SET_UID`,
  CONFIRM_EMAIL_FAILED: `CONFIRM_EMAIL_FAILED`,
  CONFIRM_EMAIL_SUCCESS: `CONFIRM_EMAIL_SUCCESS`,
}

export default Constants
