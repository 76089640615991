import { useState } from 'react'
import Popup from 'reactjs-popup'
import { AdjustmentItem, ArrowDown } from './style'
import { SlArrowDown } from 'react-icons/sl'

const SelectInput = ({ content, text }) => {
  //  Attributes
  const [arrowPosition, setArrowPosition] = useState<'leave' | 'enter'>('leave')

  return (
    <Popup
      trigger={
        <AdjustmentItem>
          {text}
          <ArrowDown isOpen={arrowPosition === 'enter'}>
            <SlArrowDown />
          </ArrowDown>
        </AdjustmentItem>
      }
      onClose={v => {
        setArrowPosition('leave')
      }}
      onOpen={v => {
        setArrowPosition('enter')
      }}
      position='bottom center'
      on='hover'
      closeOnDocumentClick
      mouseLeaveDelay={100}
      mouseEnterDelay={0}
      contentStyle={{ padding: '0px', border: 'none' }}
      arrow={false}
    >
      {content}
    </Popup>
  )
}

export default SelectInput
