import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../../utils/constants'
import { Small } from '../../style'

export const SecondaryTitle = styled.h2`
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 400;
  scroll-margin: 56px;
  margin-bottom: -15px;
`

export const Wrapper = styled.div`
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border-radius: 8px;
  background: white;
  padding: 24px 20px;
  margin-bottom: 32px;
`

export const FlexRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-gap: 20px 10px;
  flex-direction: row;
  margin & > div:not(:last-child) {
    margin-right: ${props => props.gap};
  }
  ${MediaQuery.M_AND_DOWN} {
    grid-template-columns: repeat(2, 50%);
  }
`

export const FlexRowBenefits = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 5px;
  margin-right: 5px;
  margin & > div:not(:last-child) {
    margin-right: ${props => props.gap};
  }
`

export const HorizontalSeparator = styled.div`
  border: 0.5px solid #e9eaf0;
`

export const IconContainer = styled.div`
  border: 1px solid ${props => props.color};
  box-sizing: border-box;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  margin-right: 8px;
`

export const SmallText = styled(Small)`
  white-space: pre-wrap;
  font-size: 12px;
  line-height: 12px;
  color: #393360;
`
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    margin-right: ${props => props.gap};
  }
`

export const BenefitDescriptionTitle = styled.h3`
  font-size: 15px;
  margin: 20px 0 10px 0;
`

export const BenefitDescription = styled.div`
  font-size: 14px;
  color: #393360;
  h1,
  h2,
  h3,
  h4 {
    font-size: 14px;
    margin-bottom: 10px;
    color: #393360;
  }
  p {
    font-size: 12px;
    color: #393360;
  }
`

export const SeeMore = styled.button`
  display: block;
  cursor: pointer;
  font-size: 12px;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  margin: 6px 0;
  text-align: start;
`
