import QuizCategoryBodyGrid from './body-grid'
import QuizCategoryBodyList from './body-list'
import QuizCategoryHeader from './header'
import QuizCategoryGraph from './graph'
import {
  QuizCategoryColor,
  QuizCategoryFormat,
  QuizCategoryProps,
} from './props'
import { Container, Wrapper } from './style'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import moment from 'moment'
import { useState } from 'react'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

const colors: {
  [color in QuizCategoryColor]: { title: string; background: string }
} = {
  [QuizCategoryColor.DEFAULT]: {
    title: '#080038',
    background: '#fff',
  },
  [QuizCategoryColor.BLUE]: {
    title: '#6772E5',
    background: '#D6E6FC',
  },
  [QuizCategoryColor.ORANGE]: {
    title: '#FF9138',
    background: '#FCF2EF',
  },
  [QuizCategoryColor.GREEN]: {
    title: '#3A845E',
    background: '#E0F0E9',
  },
}

const QuizCategory = ({
  format = QuizCategoryFormat.LIST,
  category,
  scores,
  color = QuizCategoryColor.DEFAULT,
  patientFirstname,
  patientName,
  quizCreatedAt,
}: QuizCategoryProps) => {
  // Attributes
  const [isOpen, setIsOpen] = useState<boolean>(false)

  // Render
  return (
    <Wrapper>
      <Container>
        <QuizCategoryHeader
          createdAt={
            quizCreatedAt
              ? moment(quizCreatedAt).format('DD/MM/YYYY')
              : undefined
          }
          patientFirstname={patientFirstname}
          patientName={patientName}
          categoryTitle={category?.title}
          color={colors[color]?.title}
          onToggleDropDown={
            format === QuizCategoryFormat.LIST ? setIsOpen : undefined
          }
        />

        {/* GRID BODY */}
        {format === QuizCategoryFormat.GRID && (
          <QuizCategoryBodyGrid category={category} />
        )}
        {/* LIST BODY */}
        {isOpen && format === QuizCategoryFormat.LIST && (
          <QuizCategoryBodyList
            category={category}
            color={colors[color].background}
          />
        )}
      </Container>

      {/* GRID GRAPH */}
      {!!scores?.length && format === QuizCategoryFormat.GRID && (
        <QuizCategoryGraph
          color='#00865c'
          scores={scores}
          labels={[
            '1 = Tout va bien',
            '2 = Fragile',
            '3 = Problème significatif',
          ]}
        />
      )}
      {/* LIST GRAPH */}
      {!!scores?.length && isOpen && format === QuizCategoryFormat.LIST && (
        <QuizCategoryGraph
          color={colors[color].title}
          scores={scores}
          title={category.title}
        />
      )}
    </Wrapper>
  )
}

export default QuizCategory
