import styled from 'styled-components/macro'
import { Color } from '../../../../../utils/constants'

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 36px 24px 24px 24px;
  border-bottom: 1px solid #e7e9f4;
`

export const Title = styled.h3`
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 17px;
`

export const Content = styled.div`
  padding: 20px 32.5px 17px 32.5px;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const UploadButton = styled.button`
  cursor: pointer;
  border: none;
  font-size: 26px;
  background-color: ${Color.DEEP_SEA};
  color: white;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

export const SubTitle = styled.h3`
  font-size: 25px;
  font-weight: 300;
`
export const ImageLabel = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
  }
`
