/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type {
  ProtocolPagingDTO,
  GetProtocolsParams,
  ProtocolDTO,
  CreateProtocolDTO,
  GetSharedProtocolsWithProductIdParams,
  GetProtocolsWithProductIdForPrescriberParams,
} from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getProtocols = (
  prescriberId: number,
  params?: GetProtocolsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProtocolPagingDTO>(
    { url: `/prescribers/${prescriberId}/protocols`, method: 'get', params },
    options
  )
}

export const getGetProtocolsQueryKey = (
  prescriberId: number,
  params?: GetProtocolsParams
) => [`/prescribers/${prescriberId}/protocols`, ...(params ? [params] : [])]

export const useGetProtocols = <
  TData = AsyncReturnType<typeof getProtocols>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetProtocolsParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getProtocols>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetProtocolsQueryKey(prescriberId, params)

  const queryFn: QueryFunction<AsyncReturnType<typeof getProtocols>> = () =>
    getProtocols(prescriberId, params, requestOptions)

  const query = useQuery<AsyncReturnType<typeof getProtocols>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!prescriberId, ...queryOptions }
  )

  return {
    queryKey,
    ...query,
  }
}

export const createProtocol = (
  prescriberId: number,
  createProtocolDTO: CreateProtocolDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProtocolDTO>(
    {
      url: `/prescribers/${prescriberId}/protocols`,
      method: 'post',
      data: createProtocolDTO,
    },
    options
  )
}

export const useCreateProtocol = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createProtocol>,
    TError,
    { prescriberId: number; data: CreateProtocolDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createProtocol>,
    { prescriberId: number; data: CreateProtocolDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return createProtocol(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createProtocol>,
    TError,
    { prescriberId: number; data: CreateProtocolDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const getSharedProtocolsWithProductId = (
  productId: string,
  params?: GetSharedProtocolsWithProductIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProtocolPagingDTO>(
    { url: `/products/${productId}/protocols`, method: 'get', params },
    options
  )
}

export const getGetSharedProtocolsWithProductIdQueryKey = (
  productId: string,
  params?: GetSharedProtocolsWithProductIdParams
) => [`/products/${productId}/protocols`, ...(params ? [params] : [])]

export const useGetSharedProtocolsWithProductId = <
  TData = AsyncReturnType<typeof getSharedProtocolsWithProductId>,
  TError = unknown
>(
  productId: string,
  params?: GetSharedProtocolsWithProductIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getSharedProtocolsWithProductId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetSharedProtocolsWithProductIdQueryKey(productId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getSharedProtocolsWithProductId>
  > = () => getSharedProtocolsWithProductId(productId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getSharedProtocolsWithProductId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!productId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getProtocolsWithProductIdForPrescriber = (
  prescriberId: number,
  productId: string,
  params?: GetProtocolsWithProductIdForPrescriberParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProtocolPagingDTO>(
    {
      url: `/prescribers/${prescriberId}/products/${productId}/protocols`,
      method: 'get',
      params,
    },
    options
  )
}

export const getGetProtocolsWithProductIdForPrescriberQueryKey = (
  prescriberId: number,
  productId: string,
  params?: GetProtocolsWithProductIdForPrescriberParams
) => [
  `/prescribers/${prescriberId}/products/${productId}/protocols`,
  ...(params ? [params] : []),
]

export const useGetProtocolsWithProductIdForPrescriber = <
  TData = AsyncReturnType<typeof getProtocolsWithProductIdForPrescriber>,
  TError = unknown
>(
  prescriberId: number,
  productId: string,
  params?: GetProtocolsWithProductIdForPrescriberParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getProtocolsWithProductIdForPrescriber>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetProtocolsWithProductIdForPrescriberQueryKey(
      prescriberId,
      productId,
      params
    )

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getProtocolsWithProductIdForPrescriber>
  > = () =>
    getProtocolsWithProductIdForPrescriber(
      prescriberId,
      productId,
      params,
      requestOptions
    )

  const query = useQuery<
    AsyncReturnType<typeof getProtocolsWithProductIdForPrescriber>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(prescriberId && productId),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}

export const getProtocol = (
  prescriberId: number,
  protocolId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ProtocolDTO>(
    {
      url: `/prescribers/${prescriberId}/protocols/${protocolId}`,
      method: 'get',
    },
    options
  )
}

export const getGetProtocolQueryKey = (
  prescriberId: number,
  protocolId: number
) => [`/prescribers/${prescriberId}/protocols/${protocolId}`]

export const useGetProtocol = <
  TData = AsyncReturnType<typeof getProtocol>,
  TError = unknown
>(
  prescriberId: number,
  protocolId: number,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getProtocol>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetProtocolQueryKey(prescriberId, protocolId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getProtocol>> = () =>
    getProtocol(prescriberId, protocolId, requestOptions)

  const query = useQuery<AsyncReturnType<typeof getProtocol>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(prescriberId && protocolId), ...queryOptions }
  )

  return {
    queryKey,
    ...query,
  }
}

export const deleteProtocol = (
  prescriberId: number,
  protocolId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/prescribers/${prescriberId}/protocols/${protocolId}`,
      method: 'delete',
    },
    options
  )
}

export const useDeleteProtocol = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteProtocol>,
    TError,
    { prescriberId: number; protocolId: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteProtocol>,
    { prescriberId: number; protocolId: number }
  > = props => {
    const { prescriberId, protocolId } = props || {}

    return deleteProtocol(prescriberId, protocolId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof deleteProtocol>,
    TError,
    { prescriberId: number; protocolId: number },
    TContext
  >(mutationFn, mutationOptions)
}
