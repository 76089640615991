import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  gap: 8px;
`

export const Title = styled.h3``

export const Content = styled.p`
  font-size: 12px;
`
