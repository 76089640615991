import { SponsorFormDialogSuccessProps } from './props'
import {
  Button,
  Container,
  Description,
  Header,
  Image,
  InfoContainer,
  Title,
} from './style'

const SponsorFormDialogSuccess = ({
  onClose,
  succeesMessageTitle,
  succeesMessageDescription,
  successMessageText,
  successImageLink,
}: SponsorFormDialogSuccessProps) => {
  // Render
  return (
    <Container>
      <Header>
        {successImageLink && (
          <Image
            src={
              successImageLink
                ? successImageLink
                : '/assets/images/reward-dialog.png'
            }
            width='100%'
          />
        )}
        <InfoContainer>
          <Title>
            {succeesMessageTitle
              ? succeesMessageTitle
              : 'Merci ! Votre parrainage a bien été enregistré.'}
          </Title>
          <Description>{succeesMessageDescription}</Description>
        </InfoContainer>
      </Header>
      <Button onClick={onClose}>
        {successMessageText ? successMessageText : "J'ai compris"}
      </Button>
    </Container>
  )
}

export default SponsorFormDialogSuccess
