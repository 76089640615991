import { PrescriptionValueToChange } from '../../../../../utils/constants/prescription-value-to-change'
import {
  getNumberBetweenUnderscores,
  getTakeFormatName,
  isContentUnit,
} from '../../../../../utils/helpers'
import { ProductQuantityProps } from './props'
import {
  QuantityWrapper,
  QuantityHandleWrapper,
  QuantityHandlerMinus,
  QuantityHandleNumber,
  QuantityHandlerPlus,
  QuantityChoiceWrapper,
  QuantityChoice,
} from './style'

const ProductQuantity = ({
  quantity,
  setQuantity,
  sortedVariants,
  product,
  takeIndex,
  selectedvariant,
  setSelectedvariant,
  setValueInPrescription,
}: ProductQuantityProps) => {
  return (
    <QuantityWrapper>
      <QuantityHandleWrapper>
        <QuantityHandlerMinus
          onClick={() => {
            if (quantity > 0) setQuantity(quantity - 1)
          }}
        >
          -
        </QuantityHandlerMinus>
        <QuantityHandleNumber>{quantity}</QuantityHandleNumber>
        <QuantityHandlerPlus onClick={() => setQuantity(quantity + 1)}>
          +
        </QuantityHandlerPlus>
      </QuantityHandleWrapper>
      <QuantityChoiceWrapper>
        {sortedVariants?.map((item, index) => {
          return (
            <QuantityChoice
              selected={item?.id === selectedvariant?.id}
              key={index}
              onClick={() => {
                setValueInPrescription(
                  product,
                  item?.title,
                  takeIndex,
                  PrescriptionValueToChange.ITEM_VARIANT_TITLE
                )
                setSelectedvariant(item)
              }}
            >
              {getNumberBetweenUnderscores(item?.title)}{' '}
              {isContentUnit(product?.takeFormat)
                ? item?.contentUnit
                : getTakeFormatName(
                    getNumberBetweenUnderscores(item?.title),
                    product?.takeFormat
                  )}
            </QuantityChoice>
          )
        })}
      </QuantityChoiceWrapper>
    </QuantityWrapper>
  )
}

export default ProductQuantity
