import styled from 'styled-components/macro'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  margin: auto;
  align-items: flex-start;

  > button {
    margin-top: 20px;
  }

  .ReactCollapse--collapse {
    transition: height 500ms;
    width: 100% !important;
  }
`

export const MoreInfo = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  border-bottom: 1px solid #cecece;
`

export const MoreInfoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  p {
    display: block;
  }
`
export const MoreInfoArrow = styled.div`
  font-size: 18px;
  display: block;
  color: #a3a3a3;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.2s;
`

export const EmailWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

export const Email = styled.div`
  padding: 12px 15px;
  background-color: #eee;
  width: 100%;
  border-radius: 4px;
  font-size: 13px;
`

export const EmailLabel = styled.div`
  font-size: 13px;
  margin-bottom: 6px;
`
export const InfoCircle = styled.div`
  margin-top: 5px;
  margin-left: 3px;
`

export const PhoneInputLabel = styled.div`
  display: flex;
  align-items: center;
`
