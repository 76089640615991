import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetMe } from '../../../utils/hooks/getMe'

import {
  Title,
  SubTitle,
  PatientLink,
  LinkWrapper,
  IconWrapper,
  SendEmailTitle,
  InlineButton,
} from '../style'
import { Tooltip, Button } from '../../ui'
import { FaRegCopy, FaCheck } from 'react-icons/fa'
import SendEmail from './send-email'

const ShareAccessDialog = ({ displayEditPercentageButton = false }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const { data: prescriber } = useGetMe()

  const textToCopy = `https://app.simplycure.com/patient-catalog?discountCode=${prescriber?.activeDiscount?.code}`

  // Functions
  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(textToCopy)
    setIsLinkCopied(true)
    setTimeout(() => setIsLinkCopied(false), 3000)
  }

  return (
    <>
      <Title>Partagez l’accès à votre catalogue à vos patients</Title>

      <SubTitle>
        Partager un accès direct au catalogue Simplycure à vos clients qui
        bénéficieront de votre code thérapeute pour réaliser leurs achats.{' '}
        {displayEditPercentageButton && (
          <Link to='/settings/payment'>
            <InlineButton>Modifier mon %</InlineButton>
          </Link>
        )}
      </SubTitle>

      <LinkWrapper>
        <PatientLink>{textToCopy}</PatientLink>
        <Tooltip
          size='l'
          label={isLinkCopied ? `Copié !` : 'Copier dans le presse papier'}
        >
          <Button
            appearance='minimal'
            onClick={handleCopyToClipBoard}
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            <IconWrapper>
              {' '}
              {isLinkCopied ? <FaCheck /> : <FaRegCopy />}
            </IconWrapper>
            copier
          </Button>
        </Tooltip>
      </LinkWrapper>

      <SendEmailTitle>Envoyer par mail </SendEmailTitle>

      <SendEmail textToCopy={textToCopy} />
    </>
  )
}

export default ShareAccessDialog
