import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import mixpanel from 'mixpanel-browser'
import { Form, FooterTextContent } from './style'
import { Button, Field } from '../../ui'
import { getFetchAuthPrescriberStatus } from '../../../store/auth-user'
import { ActionType } from '../../../utils/constants'
import { useHistory } from 'react-router'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { useAuth } from '../../../services/auth/provider'
import { Role } from '../../../services/api/types'

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 charactères requis')
    .required('Champs obligatoire'),
  email: Yup.string().email('Email invalide').required('Champs obligatoire'),
})

const LoginForm = () => {
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const fetchAuthPrescriberStatus = useSelector(getFetchAuthPrescriberStatus)
  const history = useHistory()
  const { login } = useAuth()
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: LoginSchema,
      onSubmit: values => {
        mixpanel.track('Login', {
          email: values.email,
        })
        setIsLoginLoading(true)
        login(values.email, values.password, Role.PRESCRIBER)
          .then(() => {
            history.replace('/home')
          })
          .catch(() => toast.error("L'email ou le mot de passe est incorrect"))
          .finally(() => setIsLoginLoading(false))
      },
    })

  const resetPassword = async email => {
    if (!email) {
      return toast.error(
        'Un email doit être renseigné afin de demander une réinitialisation de mot de passe'
      )
    }
    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
      return toast.success(
        'Un email pour réinitialiser votre mot de passe vous a été envoyé.'
      )
    } catch {
      return toast.error(
        "Une erreur s'est produite, veuillez vérifier l'adresse email renseignée"
      )
    }
  }

  const isAllLoading =
    isLoginLoading || fetchAuthPrescriberStatus === ActionType.LOADING

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label='Email'
        name='email'
        id='login-email'
        type='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        isDisabled={isAllLoading}
        placeholder='Insérez votre adresse mail'
      />
      {errors.email && errors.touched}
      <Field
        label='Mot de passe'
        id='password'
        name='password'
        type='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        isDisabled={isAllLoading}
        placeholder='Mot de passe'
      />
      <FooterTextContent onClick={() => resetPassword(values.email)}>
        Mot de passe oublié ?
      </FooterTextContent>
      <Button type='submit' isLoading={isAllLoading}>
        Se connecter
      </Button>
    </Form>
  )
}

export default LoginForm
