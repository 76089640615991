import { Link as RouterLink } from 'react-router-dom'
import {
  MenuContainer,
  MenuHeader,
  MenuItem,
  LogoutButton,
  WrapperItems,
  Wrapper,
} from './style'
import { IMobileMenuProps } from './props'
import { Button, Logo } from '../ui'
import CloseButton from '../ui/close-button'
import { useEffect } from 'react'

const MobileMenu = ({ isOpen, onClose, onLogout }: IMobileMenuProps) => {
  //Attributes
  const toggleBodyScroll = (lock: boolean) => {
    if (lock) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }

  //Effect
  useEffect(() => {
    toggleBodyScroll(isOpen)

    return () => toggleBodyScroll(false)
  }, [isOpen])

  if (!isOpen) return null

  //Return
  return (
    <MenuContainer isOpen={isOpen}>
      <MenuHeader>
        <Logo />
        <CloseButton onClick={onClose} />
      </MenuHeader>
      <Wrapper>
        <WrapperItems>
          <MenuItem
            as={RouterLink}
            to='/patient/recommendations'
            onClick={onClose}
          >
            Mes recommandations
          </MenuItem>
          <MenuItem as={RouterLink} to='/patient/catalog' onClick={onClose}>
            Catalogue
          </MenuItem>
        </WrapperItems>
        <Button
          appearance={undefined}
          iconLeft={undefined}
          isActive={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          onClick={onLogout}
        >
          Se déconnecter
        </Button>
      </Wrapper>
    </MenuContainer>
  )
}

export default MobileMenu
