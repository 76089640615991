import styled from 'styled-components/macro'

export const Container = styled.div`
  margin-top: 60px;
  width: 100%;
  table {
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 12px;
  }
  thead > tr {
    height: 77px;
  }
  th {
    padding: 0 12px;
    text-align: start;
    color: #080038;
    font-size: 14px;
    line-height: 17px;
  }
  th:first-child {
    padding: 0 12px 0 30px;
  }
  th:last-child {
    padding: 0 30px 0 12px;
  }
  tbody > tr {
    height: 78px;
  }
  td {
    padding: 0 12px;
    font-size: 14px;
    line-height: 17px;
    color: #080038;
  }
  td:first-child {
    padding: 0 12px 0 30px;
  }
  td:last-child {
    padding: 0 30px 0 12px;
  }
`

export const SpinnerWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const PlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
`
