import cloneDeep from 'lodash.clonedeep'

import Constants from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

const fetchProductsFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const fetchProductsLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchAll = { status: ActionType.LOADING, error: null }

  return newState
}
const fetchProductsSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.list = [...action.payload]
  newState.actions.fetchAll = { status: ActionType.SUCCESS, error: null }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.FETCH_PRODUCTS_FAILED:
      return fetchProductsFailed(state, action)
    case Constants.FETCH_PRODUCTS_LOADING:
      return fetchProductsLoading(state)
    case Constants.FETCH_PRODUCTS_SUCCESS:
      return fetchProductsSuccess(state, action)

    default:
      return state
  }
}
