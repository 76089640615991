import styled from 'styled-components/macro'

export const Container = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 2rem auto;
`

export const Title = styled.h4`
  font-size: 1rem;
  margin-left: 1.25rem;
`

export const Button = styled.button`
  cursor: pointer;
  color: white;
  border-radius: 0.375rem;
  border: 0.0625rem solid transparent;
  padding: 0.5625rem 0.8125rem;
  font-family: basic grotesque, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  width: 5.625rem;
  background: #3a845e;
  white-space: nowrap;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 0.875rem;
    margin-top: 0.7em;
  }
`

export const Header = styled.div`
  display: flex;
  position: sticky;
  align-items: center;
  top: 0;
  padding: 1.25rem;
  z-index: 90;
  border-bottom: 0.0625rem solid var(--Line, #e7e9f4);
  background-color: #fdfcfe;
`

export const ButtonProtocolsWrapper = styled.div`
  display: flex;
  margin: 1.25rem 0 0rem 0;
`

export const ProductSearchBarHeader = styled.p`
  font-size: 0.875rem;
  margin-top: 0.7em;
`

export const StickyContainer = styled.div`
  display: flex;
  position: sticky;
  top: 5.3125rem;
  flex-direction: column;
  background-color: white;
  padding: 1.25rem;
  z-index: 1;
  border-radius: 0.875rem;
  box-shadow: 0rem 0rem 0.0625rem rgba(40, 41, 61, 0.08),
    0rem 0.0313rem 0.125rem rgba(96, 97, 112, 0.16);
  width: 100%;
  margin-top: 1.25rem;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Nav = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.625rem 1.5rem 0rem 0rem;
  gap: 1rem;
  width: 100%;
`

export const NavItem = styled.div`
  cursor: pointer;
  margin-right: 1.25rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  display: flex;
  align-items: center;
  color: ${props => (props.active ? '#080038' : '#8f95b2')};
  border-bottom: ${props => (props.active ? '.0625rem solid #3A845E;' : '')};
  padding-bottom: 0.5rem;
`
export const FavoritesListByPackagingTypeWrapper = styled.div`
  margin-top: 1.25rem;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
