import styled from 'styled-components/macro'
import { Color } from '../../../../utils/constants'

export const Container = styled.tr``

export const EditIcon = styled.span`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
`

export const Status = styled.td`
  span {
    padding: 5px 8px;
    border-radius: 5px;
  }
`

export const FlexTd = styled.td`
  padding: 0 !important;
  span {
    margin-right: 10px;
  }
  p {
    display: inline-block;
  }
`

export const Line = styled.p`
  display: inline-block;
`

export const Note = styled.td`
  h4 {
    margin-right: 10px;
  }
  p {
    display: inline-block;
  }
`

export const MoreIcon = styled.td`
  cursor: pointer;
  color: ${Color.DEEP_SEA};
  font-size: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DropdownContent = styled.div`
  padding: 10px 20px;
  a {
    color: inherit;
    text-decoration: none;
  }
`

export const DropdownContentItem = styled.div`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
`
export const DropdownContentItemIcon = styled.span`
  margin-right: 10px;
  font-size: 15px;
`
export const DropdownContentItemText = styled.p`
  font-size: 12px;
`

export const SeeIcon = styled.div`
  font-size: 15px;
  color: black;
  margin-right: 30px;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
