import { useState } from 'react'
import {
  FilterBody,
  FilterContainer,
  FilterHeader,
  FilterTitle,
  ValueItem,
  ValuesContainer,
  Checkbox,
  CheckboxContainer,
  StyledCheckBig,
  ValueContainer,
} from './style'
import { IFilterProps } from './props'
import { PrescriberFavoriteListDTO } from '../../../../services/api/types'

const FavoritesFilter = ({ allValues, setFilter }: IFilterProps) => {
  //Attributes
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedFavorite, setSelectedFavorite] = useState(null)

  //Functions
  function changeSizeContainer() {
    setIsExpanded(!isExpanded)
  }

  function handleSelectFavorite(favorite) {
    if (selectedFavorite === favorite.id) {
      setSelectedFavorite(null)
      setFilter(undefined)
    } else {
      setSelectedFavorite(favorite.id)
      setFilter(favorite.id)
    }
  }

  function isChecked(favoriteId) {
    return selectedFavorite === favoriteId
  }

  function sortFavoriteListByName(
    favoriteArray: PrescriberFavoriteListDTO[]
  ): PrescriberFavoriteListDTO[] {
    // Vérifie si l'entrée est un tableau
    if (!Array.isArray(favoriteArray)) {
      console.error('Input is not an array')
      return []
    }
    const sortedFavoriteArray = [...favoriteArray]
    sortedFavoriteArray.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    return sortedFavoriteArray
  }

  //Return
  return (
    <FilterContainer>
      <FilterHeader onClick={changeSizeContainer}>
        <FilterTitle>Favoris</FilterTitle>
      </FilterHeader>
      {!!allValues?.length && (
        <FilterBody>
          <ValuesContainer>
            {sortFavoriteListByName(allValues)?.map(value => {
              return (
                <ValueContainer
                  key={value.id}
                  onClick={() => handleSelectFavorite(value)}
                >
                  <CheckboxContainer>
                    <Checkbox type='checkbox' checked={isChecked(value.id)} />
                    <StyledCheckBig />
                  </CheckboxContainer>
                  <ValueItem>{value.name}</ValueItem>
                </ValueContainer>
              )
            })}
          </ValuesContainer>
        </FilterBody>
      )}
    </FilterContainer>
  )
}

export default FavoritesFilter
