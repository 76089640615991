import { Helmet } from 'react-helmet'
import Layout from '../../components/layout/public'
import PageTitle from '../../components/section/page-title'
import { Container, Content } from './style'
import { useOnboardingProfileCheck } from '../../utils/hooks'
import { ViewMode } from '../../utils/constants'
import { HeaderLinks } from '../../components/section/header/props'
import Products from '../../components/products/products'
import UrlProductDialog from '../../components/product/url-product-dialog'

const PublicProductsPage = () => {
  useOnboardingProfileCheck()

  return (
    <Layout isPublic active={HeaderLinks.Catalogue}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Catalogue</title>
      </Helmet>
      <Container>
        <PageTitle
          title='Découvrez la gamme de produits'
          rightElements={<></>}
        />
        <Content>
          <Products
            viewModeProduct={ViewMode.GRID}
            from='productPage'
            enableFavorite={false}
            isPatientCatalog
            allowProductUrl={true}
            doNotAllowProductToBeAdded
          />
        </Content>
      </Container>
      <UrlProductDialog />
    </Layout>
  )
}

export default PublicProductsPage
