import styled from 'styled-components/macro'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  z-index: 1000;
`

export const Modal = styled.div`
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: auto;
  background-color: white;
  border-radius: 5px;
`

export const Title = styled.h3`
  margin-bottom: 10px;
`

export const Description = styled.p`
  margin-bottom: 20px;
`

export const Input = styled.input`
  background: #f8f9fc;
  color: #080038;
  font-size: 16px;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0 10px;
`

export const Button = styled.button`
  position: absolute;
  right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: auto;
  height: 42px;
  background: #3d825b;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  border: none;
`

export const Flex = styled.form`
  display: flex;
  justify-content: space-between;
  height: 40px;
`
