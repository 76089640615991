import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  min-width: 20.4375rem;
  padding: 2.75rem;
`

export const ImageLogo = styled.img`
  width: 231px;
  object-fit: contain;
  object-position: center center;
  margin-bottom: 0.625rem;
`

export const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
  color: #080038;
`

export const Subtitle = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: #393360;
  width: 20.4375rem;
  line-height: 1rem;
`

export const TextButton = styled.div`
  margin-top: 0.75rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: #9c99af;
  line-height: 0.75rem;
  font-family: Basis Grotesque Pro;
  cursor: pointer;
  text-align: center;
`
