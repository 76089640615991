import { Container, TakeLabel, TakeContainer } from './style'
import ProductItem from './product'
import { Spinner } from '../../ui'
import { PrescriptionProductsListProps } from './props'
import { PrescriptionTakeType } from '../../../services/api/types'
import NoProduct from './no-product'
import { useLocation } from 'react-router'
import { calculateDiscountPercentage } from '../../../utils/helpers'

const MarketplacePrescriptionProductsList = ({
  cart,
  isLoading = false,
  takes,
  abilityToManageQuantity = false,
  updateVariantToCart,
  removeVariantsToCart,
  setIsOpenDialogMoreProducts,
  isPatientCatalog,
}: PrescriptionProductsListProps) => {
  // Attributes
  const location = useLocation()
  const discountPercentage = calculateDiscountPercentage(
    cart?.estimatedCost?.totalAmount?.amount,
    cart?.estimatedCost?.subtotalAmount?.amount
  )
  const inCureTakes = takes?.filter(
    take => take.type === PrescriptionTakeType.IN_CURE
  )
  const standAloneTakes = takes?.filter(
    take => take.type === PrescriptionTakeType.STAND_ALONE
  )

  // Render
  if (isLoading) return <Spinner fullScreen={undefined} />

  return (
    <Container>
      {location.pathname === '/cart' &&
        !inCureTakes?.[0]?.items?.length &&
        !standAloneTakes?.[0]?.items?.length && (
          <NoProduct
            setIsOpenDialogMoreProducts={setIsOpenDialogMoreProducts}
          />
        )}

      {!!inCureTakes?.[0]?.items?.length && (
        <div>
          {inCureTakes.map((take, index) => (
            <TakeContainer key={index}>
              {/* Don't show the title of the take if only one take */}
              {inCureTakes.length > 1 && (
                <TakeLabel>{take?.label || `Prise #${index + 1}`}</TakeLabel>
              )}
              {take?.items.map(item => {
                const edge = cart?.lines?.edges?.find(
                  edge =>
                    edge.node.merchandise.product.handle ===
                    item?.product?.handle
                )

                return (
                  <ProductItem
                    key={item.product?.id}
                    item={item}
                    abilityToManageQuantity={abilityToManageQuantity}
                    updateVariantToCart={updateVariantToCart}
                    removeVariantsToCart={removeVariantsToCart}
                    nodeId={edge?.node?.id}
                    attributes={edge?.node?.attributes}
                    subtotalAmount={
                      edge?.node?.estimatedCost?.subtotalAmount?.amount
                    }
                    totalAmount={edge?.node?.estimatedCost?.totalAmount?.amount}
                    discountPercentage={discountPercentage}
                    isPatientCatalog={isPatientCatalog}
                  />
                )
              })}
            </TakeContainer>
          ))}
        </div>
      )}

      {!!standAloneTakes?.[0]?.items?.length && (
        <div>
          {standAloneTakes.map((take, index) => {
            return (
              <TakeContainer key={index}>
                {/* Don't show the title of the take if only one take */}
                {inCureTakes.length > 1 && (
                  <TakeLabel>{take?.label || `Prise #${index + 1}`}</TakeLabel>
                )}
                {take?.items.map(item => {
                  const edge = cart?.lines?.edges?.find(
                    edge =>
                      edge.node.merchandise.product.handle ===
                      item?.product?.handle
                  )

                  return (
                    <ProductItem
                      key={item.product?.id}
                      item={item}
                      abilityToManageQuantity={abilityToManageQuantity}
                      updateVariantToCart={updateVariantToCart}
                      removeVariantsToCart={removeVariantsToCart}
                      nodeId={edge?.node?.id}
                      attributes={edge?.node?.attributes}
                      subtotalAmount={
                        edge?.node?.estimatedCost?.subtotalAmount?.amount
                      }
                      totalAmount={
                        edge?.node?.estimatedCost?.totalAmount?.amount
                      }
                      discountPercentage={discountPercentage}
                      isPatientCatalog={isPatientCatalog}
                    />
                  )
                })}
              </TakeContainer>
            )
          })}
        </div>
      )}
    </Container>
  )
}

export default MarketplacePrescriptionProductsList
