import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;

  & > *:not(:last-child) {
    margin-right: 22px;
  }

  ${({ onClick }) => onClick && 'cursor: pointer;'}
`
export const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${({ color }) => color};
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;

  ${({ isDropDownOpen }) => isDropDownOpen && 'transform: rotate(-90deg);'}
`
