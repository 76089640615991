import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  a {
    text-decoration: none !important;
    color: inherit !important;
  }
`

export const CardWrapper = styled.div`
  display: flex;
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 40px;
`

export const Icon = styled.div`
  font-size: 20px;
  margin-right: 10px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeaderTitle = styled.h2`
  font-size: 24px;
  font-weight: 400;
`

export const Title = styled.h4`
  font-size: 13px;
  font-weight: 400;
`

export const Description = styled.p`
  font-size: 12px;
  opacity: 0.6;
`
