import styled from 'styled-components/macro'

export const Container = styled.div``

export const Label = styled.label`
  margin-bottom: 15px;
  font-size: 16px;
`

export const Textarea = styled.textarea`
  background: #ffffff;
  height: 100px;
  border: 1px solid #e9eaf0;
  border-radius: 8px;
  outline: none;
  padding: 10px;
`

export const Input = styled.input`
  background: #ffffff;
  border: 1px solid #e9eaf0;
  border-radius: 8px;
  outline: none;
  padding: 10px;
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f8f9fc;
  border: 1px solid #e7e9f4;
  border-radius: 12px;
  margin-bottom: 30px;
`
