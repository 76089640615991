import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  z-index: 1000;
`

export const Modal = styled.div`
  padding: 1.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28.125rem;
  height: auto;
  background-color: white;
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  ${MediaQuery.L_AND_DOWN} {
    width: 20rem;
  }
`

export const Description = styled.p`
  margin-bottom: 1.25rem;
`

export const Input = styled.input`
  background: #f8f9fc;
  color: #080038;
  font-size: 0.875rem;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0 0.625rem;
  border-radius: 0.375rem 0rem 0rem 0.375rem;
`

export const Button = styled.button`
  position: absolute;
  right: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  width: auto;
  height: 2.625rem;
  background: #3d825b;
  border-radius: 0rem 0.375rem 0.375rem 0rem;
  cursor: pointer;
  color: white;
  border: none;
`

export const Flex = styled.form`
  display: flex;
  justify-content: space-between;
  height: 2.5rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
