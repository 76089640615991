import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dialog, Spinner } from '../../../../ui'
import { IconCross } from '../../../../ui/icon/icons'
import { toast } from 'react-toastify'
import {
  CloseButton,
  CloseButtonContainer,
  Container,
  Title,
  DragDrop,
  Header,
  Content,
  DragDropContainer,
  Footer,
  DragDropTitle,
  DragDropContent,
  DragDropContentTexts,
  DragDropSubtitle,
  Image,
  FileDroppedContainer,
  FileDroppedTitle,
  FileDroppedRemove,
  DragDropExtError,
} from './style'
import {
  getPrescriptionNewDraft,
  setNewPrescriptionDraft,
} from '../../../../../store/prescription'
import { useDropzone } from 'react-dropzone'
import { useUploadPrescriberFile } from '../../../../../services/api/prescriber/prescriber'
import { toBase64 } from '../../../../../utils/helpers/fileHelper'
import { useGetMe } from '../../../../../utils/hooks/getMe'

const UploadDialog = ({ isOpen, onClose, setFiles }) => {
  // Attributes
  const dispatch = useDispatch()
  const { files } = useSelector(getPrescriptionNewDraft)
  const { data: prescriber } = useGetMe()

  const [file, setFile] = useState(null)
  const [hasInvalidExtension, setHasInvalidExtension] = useState<boolean>(false)

  // Hooks
  const { mutateAsync: uploadFile, isLoading } = useUploadPrescriberFile({
    mutation: {
      onSuccess: () => {
        toast.success('Le fichier a bien été uploadé')
        close()
      },
      onError: () => {
        toast.error("Erreur lors de l'import ")
      },
    },
  })

  const onDrop = useCallback(async files => {
    const file = files[0]
    if (!file) return
    setHasInvalidExtension(false)
    setFile(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: {
    //   'application/vnd.ms-excel': ['.xls'],
    //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    //     '.xlsx',
    //   ],
    //   'text/csv': ['.csv'],
    // },
  })

  async function onUpload() {
    // if (!file) return

    const content = await toBase64(file)
    const res = await uploadFile({
      prescriberId: prescriber?.id,
      data: {
        data: content.split(',')[1],
        fileName: file?.name,
      },
    })
    setFiles(res)
    dispatch(setNewPrescriptionDraft(files.push(res)))
  }

  // Functions
  function close() {
    setFile(null)
    onClose()
  }

  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      type='defaultCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={close}>
          <IconCross width='1.0831rem' height='1.0831rem' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        <Header>
          <Title>Importer un fichier existant</Title>
        </Header>
        <Content>
          <DragDropContainer>
            {isLoading ? (
              <Spinner fullScreen={false} />
            ) : file ? (
              <FileDroppedContainer>
                <FileDroppedTitle>{file.name}</FileDroppedTitle>
                <FileDroppedRemove onClick={() => setFile(null)}>
                  <IconCross
                    width='1.0831rem'
                    height='1.0831rem'
                    stroke='#2E6A4B'
                  />
                </FileDroppedRemove>
              </FileDroppedContainer>
            ) : (
              <DragDrop {...getRootProps()}>
                <input {...getInputProps()} />

                <DragDropContent>
                  <Image
                    src='/assets/images/drag-and-drop-file.png'
                    alt='file'
                  />
                  <DragDropContentTexts>
                    {isDragActive ? (
                      <DragDropTitle> Drop le fichier ici... </DragDropTitle>
                    ) : (
                      <DragDropTitle>
                        Drag & Drop ou Parcourir pour importer
                      </DragDropTitle>
                    )}
                    <DragDropSubtitle></DragDropSubtitle>
                    {hasInvalidExtension && (
                      <DragDropExtError>Fichier invalid</DragDropExtError>
                    )}
                  </DragDropContentTexts>
                </DragDropContent>
              </DragDrop>
            )}
          </DragDropContainer>
          <Footer>
            <Button
              appearance='default'
              iconLeft={undefined}
              isDisabled={file == null}
              isLoading={isLoading}
              isActive={undefined}
              style={{ width: '100%', height: '2.5625rem' }}
              onClick={onUpload}
            >
              Importer
            </Button>
          </Footer>
        </Content>
      </Container>
    </Dialog>
  )
}

export default UploadDialog
