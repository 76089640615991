import styled from 'styled-components/macro'
import { GrFormClose } from 'react-icons/gr'
import { Color } from '../../../utils/constants'

export const LargeCloseIconWrapper = styled.div``

export const MediumCloseIconWrapper = styled.div`
  width: 2.3125rem;
  height: 2.3125rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.DEEP_SEA};
  background-color: #d8e6df;
  cursor: pointer;
`
export const MediumCloseIcon = styled(GrFormClose)`
  font-size: 1.375rem;
`

export const LargeGrFormClose = styled(GrFormClose)`
  font-size: 2rem;
`
