import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  ${MediaQuery.M_AND_DOWN} {
    background-color: #0000004d;
    align-content: center;
    padding: 1.5rem;
  }
`

export const Step = styled.div`
  display: flex;
  justify-content: space-between;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column-reverse;
  }
`

export const Img = styled.img`
  height: 100vh;
  width: 50vw;
  object-fit: cover;
  ${MediaQuery.M_AND_DOWN} {
    width: 100vw;
    height: 50vh;
    display: none;
  }
`

export const Welcom = styled.div`
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  ${MediaQuery.M_AND_DOWN} {
    background-color: white;
    z-index: 10;
    margin-top: -30px;
    width: fit-content;
    border-radius: 0.75rem;
  }
`

export const WelcomContent = styled.div`
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  width: 500px;
  height: auto;
  padding: 40px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-size: 16px;
    opacity: 0.6;
    margin-bottom: 15px;
  }
  ul {
    font-size: 14px;
    opacity: 0.8;
    margin-bottom: 40px;
    list-style: none;
    li {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      span {
        background-color: #e0f0e9;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        min-width: 25px;
        margin-right: 10px;
        border-radius: 4px;
        img {
          width: 70%;
        }
      }
    }
  }
  ${MediaQuery.M_AND_DOWN} {
    box-shadow: none;
    width: 100%;
    padding: 40px 0px;
  }
  ${MediaQuery.L_AND_DOWN} {
    width: 80%;
  }
`

export const WelcomHeader = styled.div`
  margin-bottom: 20px;
  h3 {
    display: block;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 20px;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
`

export const PrescriptionButton = styled.button`
  text-align: center;
  background: none;
  border: none;
  margin-top: 20px;
  cursor: pointer;
`

export const Info = styled.p`
  font-size: 10px !important;
`
