import { IResultActionProps } from './props'
import { ResultActionButton, ResultActionRow } from './style'

const ResultAction = ({ clearFilter }: IResultActionProps) => {
  return (
    <ResultActionRow>
      <ResultActionButton onClick={clearFilter}>
        Effacer les filtres
      </ResultActionButton>
    </ResultActionRow>
  )
}

export default ResultAction
