import toast from 'react-hot-toast'
import {
  MenuContainer,
  MenuContainerHeader,
  CreateCategoryButton,
  MenuContainerItem,
  Checkbox,
} from './style'

import { useQueryClient } from 'react-query'
import { FavoriteDropDownContentProps } from './props'
import { useGetMe } from '../../../../../utils/hooks/getMe'
import {
  useAddPrescriberFavoriteListItemToList,
  useDeletePrescriberFavoriteListItemByProductIdAndListId,
  useGetAllPrescriberFavoriteListsByProductId,
} from '../../../../../services/api/favorites/favorites'
import {
  refetchaFvoriteListsByProductId,
  refetchAllFavorite,
} from '../../../../../utils/helpers'

export const FavoriteDropDownContent = ({
  isOpen,
  product,
  favoriteLists,
  refetchCurrentFavoriteList,
  onCreateCategory,
}: FavoriteDropDownContentProps) => {
  // Attrubutes
  const { data: prescriber } = useGetMe()

  const queryClient = useQueryClient()

  const { mutateAsync: addPrescriberFavoriteListItemToList } =
    useAddPrescriberFavoriteListItemToList({
      mutation: {
        onSuccess: () => {
          toast.success('Le produit a bien été ajouté aux favoris')
          refetchAllFavorite(queryClient, prescriber?.id)
          refetchaFvoriteListsByProductId(
            queryClient,
            prescriber?.id,
            product?.id
          )
          if (!!refetchCurrentFavoriteList) return refetchCurrentFavoriteList()
        },
        onError: error => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })
  const { mutateAsync: deletePrescriberFavoriteListItemByProductIdAndListId } =
    useDeletePrescriberFavoriteListItemByProductIdAndListId({
      mutation: {
        onSuccess: () => {
          toast.success('Le produit a bien été supprimé des favoris')
          refetchAllFavorite(queryClient, prescriber?.id)
          refetchaFvoriteListsByProductId(
            queryClient,
            prescriber?.id,
            product?.id
          )
          if (!!refetchCurrentFavoriteList) return refetchCurrentFavoriteList()
        },
        onError: error => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })

  const { data: favoriteListsByProductId } =
    useGetAllPrescriberFavoriteListsByProductId(prescriber?.id, product?.id, {
      query: {
        enabled: !!prescriber?.id && !!product?.id && isOpen,
      },
    })

  const favoriteListsIdByProductId = favoriteListsByProductId?.map(i => i.id)

  // Function
  const handleClickOnFavoriteListItem = (favoriteId: string) => {
    if (favoriteListsIdByProductId?.includes(favoriteId))
      deletePrescriberFavoriteListItemByProductIdAndListId({
        productId: product?.id,
        favoriteListId: favoriteId,
      })
    if (!favoriteListsIdByProductId?.includes(favoriteId))
      addPrescriberFavoriteListItemToList({
        favoriteListId: favoriteId,
        data: {
          productId: product?.id,
        },
      })
  }

  // Render
  return (
    <MenuContainer>
      <MenuContainerHeader>
        <CreateCategoryButton onClick={() => onCreateCategory()}>
          Créer une nouvelle catégorie
        </CreateCategoryButton>
      </MenuContainerHeader>
      {favoriteLists?.map(favorite => {
        return (
          <MenuContainerItem
            onClick={() => handleClickOnFavoriteListItem(favorite?.id)}
          >
            <div>
              <Checkbox
                checked={favoriteListsIdByProductId?.includes(favorite?.id)}
              />
              <p> {favorite?.name}</p>
            </div>
          </MenuContainerItem>
        )
      })}
    </MenuContainer>
  )
}
