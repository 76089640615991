import { useState } from 'react'
import { Container, Left, IconWapper, Text, TabWrapper } from './style'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import Tab from './tab'
import { VrnAlertProps } from './props'
import { calculateNutritionalValues } from './function'

const VrnAlert = ({ takes }: VrnAlertProps) => {
  // Attributes
  const [toggle, setToggle] = useState(false)
  const [productVnr, setProductVnr] = useState([])

  // Functions
  function getOverLimitItems(productVnr) {
    const allItems = calculateNutritionalValues(productVnr)

    const overLimitItems = allItems?.filter(
      item =>
        Number.isFinite(item?.dosageMax?.pourcentage) &&
        item?.dosageMax?.pourcentage > 100
    )

    return overLimitItems
  }

  const overLimitItem = getOverLimitItems(productVnr)

  const isSuccess = overLimitItem?.length === 0

  // Render
  return (
    <>
      <Container isSuccess={isSuccess}>
        <Left onClick={() => setToggle(!toggle)}>
          <IconWapper>
            {toggle ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </IconWapper>
          <Text>
            {toggle ? 'Cacher' : 'Afficher'} le tableau récapitulatif des
            apports:
          </Text>
        </Left>

        <TabWrapper style={{ display: toggle ? 'block' : 'none' }}>
          <Tab
            takes={takes}
            setProductVnr={setProductVnr}
            productVnr={productVnr}
          />
        </TabWrapper>
      </Container>
    </>
  )
}

export default VrnAlert
