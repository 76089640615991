import { useEffect } from 'react'
import { DialogContent, Title, Header } from './style'
import { toast } from 'react-toastify'
import { Dialog, BackButton } from '../../../../ui'
import CreatePatientForm from '../../../../patient/create-patient-form'
import {
  useCreatePatient,
  useUpdatePatient,
} from '../../../../../services/api/patients/patients'
import { useGetMe } from '../../../../../utils/hooks/getMe'
import { getErrorMessage } from '../../../../../utils/helpers'
import { CreateNewPatientProps } from './props'
import {
  refetchPatient,
  refetchPatientItem,
} from '../../../../../utils/helpers/refetch'
import { useQueryClient } from 'react-query'

const CreateNewPatient = ({
  isDialogOpen,
  setIsDialogOpen,
  setIsInnerDialogOpen,
  patient,
  setPatient,
}: CreateNewPatientProps) => {
  const queryClient = useQueryClient()
  const { data: prescriber } = useGetMe()

  // Attributes
  const { mutateAsync: addPatient, isLoading: patientLoading } =
    useCreatePatient({
      mutation: {
        onSuccess: async patient => {
          toast('Le client a été créé avec succès !')
          setPatient && setPatient(patient)

          setIsDialogOpen(false)
          refetchPatient(queryClient, prescriber?.id)
        },
        onError: (error: any) => {
          toast.error(getErrorMessage(error?.response?.data))
        },
      },
    })

  const { mutateAsync: updatePatient } = useUpdatePatient({
    mutation: {
      onSuccess: async patient => {
        toast('Le client a été mis à jour avec succès !')

        setIsDialogOpen(false)
        refetchPatient(queryClient, prescriber?.id)
        refetchPatientItem(queryClient, prescriber?.id, patient?.id)
      },
      onError: error => {
        toast.error(getErrorMessage(''))
      },
    },
  })

  // Functions
  const onNewPatientSubmitted = async newPatientData => {
    if (patient)
      updatePatient({
        prescriberId: prescriber?.id,
        patientId: patient?.id,
        data: {
          email: newPatientData.email,
          firstName: newPatientData.firstName ? newPatientData.firstName : ' ',
          lastName: newPatientData?.lastName ? newPatientData?.lastName : ' ',
          phone: newPatientData.phone
            ? newPatientData.phone.startsWith('+')
              ? `${newPatientData.phone}`
              : `+${newPatientData.phone}`
            : ' ',
          age: newPatientData.age,
          sexe: newPatientData.sexe,
          pathology: newPatientData.pathology,
        },
      })
    else
      addPatient({
        prescriberId: prescriber?.id,
        data: {
          email: newPatientData.email,
          firstName: newPatientData.firstName ? newPatientData.firstName : ' ',
          lastName: newPatientData.lastName ? newPatientData.lastName : ' ',
          phone: newPatientData.phone ? `+${newPatientData.phone}` : ' ',
          age: Number.isNaN(Number(newPatientData.age))
            ? undefined
            : Number(newPatientData.age),
          sexe: newPatientData.sexe ? newPatientData.sexe : undefined,
          pathology: newPatientData.pathology,
        },
      })
  }

  // Hooks
  useEffect(() => {
    setIsInnerDialogOpen && setIsInnerDialogOpen(!isDialogOpen)
  }, [isDialogOpen])

  //Render
  return (
    <Dialog
      type='aside'
      isOpen={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      styleContainer={{
        zIndex: '100',
      }}
      width='auto'
    >
      <DialogContent>
        <Header>
          <BackButton onClick={() => setIsDialogOpen(false)} />
          <Title>
            {patient ? 'Modifier un client' : 'Créez un nouveau client'}
          </Title>
        </Header>

        <CreatePatientForm
          onSubmit={onNewPatientSubmitted}
          isLoading={patientLoading}
          patient={patient}
        />
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewPatient
