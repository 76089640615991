import Slider, { SliderTooltip } from 'rc-slider'
import { InfosWrapper, FlexCardAmountWrapper, Container } from './style'
import { Title } from '../style'
import {
  Infos,
  DiscountInfo,
  CommissionInfo,
  InfosContainer,
  FlexCardAmount,
  CardAmount,
  CardAmountText,
  CardAmountTextAmount,
  PromoCode,
} from '../../prescriber/discount-code/style'
import { DiscountSliderProps } from './props'
import { useGetMe } from '../../../utils/hooks/getMe'

import 'rc-slider/assets/index.css'
const { Handle } = Slider

const handle = props => {
  const { value, dragging, index, ...restProps } = props
  return (
    <SliderTooltip
      prefixCls='rc-slider-tooltip'
      overlay={`${value} %`}
      visible={dragging}
      placement='top'
      zIndex={50}
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}

const DiscountSlider = ({
  selectValue,
  setSelectValue,
  percentageArray,
  discountCode,
}: DiscountSliderProps) => {
  const { data: prescriber } = useGetMe()

  let commissionPercentage = prescriber?.commissionPercentage

  if (
    commissionPercentage !== null &&
    !isNaN(commissionPercentage) &&
    commissionPercentage > 0
  ) {
    percentageArray = percentageArray?.filter(
      num => num <= commissionPercentage
    )
  }

  let transformedObject = percentageArray?.reduce(
    (obj, item) => {
      obj[item] = item
      return obj
    },
    { 0: 0 }
  )

  const min = 0
  const max = percentageArray && Math.max(...percentageArray)

  return (
    <Container>
      <Title
        style={{
          marginBottom: '25px',
        }}
      >
        Etape 2: Quel pourcentage de réduction souhaitez-vous appliquer pour vos
        clients ?
      </Title>

      <Slider
        min={min}
        max={max}
        handle={handle}
        step={5}
        value={selectValue}
        onChange={v => (v < max + 1 ? setSelectValue(v) : undefined)}
        marks={transformedObject}
        style={{ margin: '0.5em .3125rem', maxWidth: '19.8125rem' }}
      />

      <InfosWrapper>
        <Infos>
          <InfosContainer>
            <DiscountInfo>Réduction</DiscountInfo>
            <DiscountInfo>{selectValue}%</DiscountInfo>
          </InfosContainer>
          <InfosContainer style={{ alignItems: 'flex-end' }}>
            <CommissionInfo>Commission</CommissionInfo>
            <CommissionInfo>{max - selectValue}%</CommissionInfo>
          </InfosContainer>
        </Infos>
      </InfosWrapper>

      {discountCode ? (
        <PromoCode>
          Votre code{' '}
          <span
            style={{
              color: '#00865C',
            }}
          >
            {discountCode}
          </span>{' '}
          offre {selectValue}% de réduction à vos clients.
        </PromoCode>
      ) : (
        ''
      )}

      <FlexCardAmountWrapper
        style={{
          marginBottom: '-30px',
        }}
      >
        <FlexCardAmount>
          <CardAmount>
            <CardAmountText>% de la réduction</CardAmountText>
            <CardAmountTextAmount>{selectValue}%</CardAmountTextAmount>
          </CardAmount>
          <CardAmount>
            <CardAmountText>% de points</CardAmountText>
            <CardAmountTextAmount>{max - selectValue}%</CardAmountTextAmount>
          </CardAmount>
        </FlexCardAmount>
      </FlexCardAmountWrapper>
    </Container>
  )
}

export default DiscountSlider
