import {
  Container,
  Right,
  Img,
  BrandImg,
  Left,
  IconsWrapper,
  IconWrapper,
  DescritpionWrapper,
  DescritpionTextInput,
  Header,
  HeaderLeft,
  QuantityWrapper,
  QuantityWrapperFlex,
  Name,
  HeaderRight,
  Title,
  QuantityHandleWrapper,
  QuantityHandlerMinus,
  QuantityHandleNumber,
  QuantityHandlerPlus,
  QuantityChoiceWrapper,
  QuantityChoice,
  AdjustmentWrapper,
  AdjustmentItem,
  NameWrapper,
  SeeMore,
  MobileBrandImg,
  DesktopPricing,
  MobilePricing,
  ImageWrapper,
  MobileNameWrapper,
  Info,
} from './style'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import {
  getTakeFormatName,
  getTimingDescription,
  getDuration,
  getNumberBetweenUnderscores,
  isContentUnit,
  convertShopifyVariantIdToBase64,
} from '../../../../utils/helpers'
import { useGetProductByIdOrHandle } from '../../../../services/api/product/product'
import ProductPreviewCardDetailsDialog from '../../../../components/product/product-preview-card/product-details-dialog'
import { useGetProductDosingByProductId } from '../../../../services/api/dosings/dosings'
import Pricing from './pricing'
import { CiCircleAlert } from 'react-icons/ci'

const Product = ({
  item,
  abilityToManageQuantity,
  updateVariantToCart,
  removeVariantsToCart,
  nodeId,
  attributes,
  subtotalAmount,
  totalAmount,
  discountPercentage,
  isPatientCatalog,
}) => {
  // Attributes
  const instructionDuration =
    attributes?.find(attr => attr?.key === '_instructionDuration')?.value ??
    item?.instructionDuration
  const instructionQuantity =
    attributes?.find(attr => attr?.key === '_instructionQuantity')?.value ??
    item?.instructionQuantity
  const instructionTiming =
    attributes?.find(attr => attr?.key === '_instructionTiming')?.value ??
    item?.instructionTiming
  const { data: product } = useGetProductByIdOrHandle(item?.product?.handle, {})
  const [isDetailModalOpen, setIsDetailsModalOpen] = useState(false)

  let selectedvariant = product?.variants?.find(
    i => i.id === convertShopifyVariantIdToBase64(item?.product?.variantId)
  )
  if (!selectedvariant) {
    selectedvariant = product?.variants?.find(
      i => i.id === item?.product?.variantId
    )
  }

  //Queries
  const { data: dosings } = useGetProductDosingByProductId(item?.product?.id)
  const productAttributes = attributes?.map(i => ({
    key: i?.key,
    value: i?.value,
  }))
  const isLongContent = item?.note?.length > 100
  const [isOpened, setIsOpened] = useState(!isLongContent)

  const toggleContent = () => {
    setIsOpened(!isOpened)
  }

  // Render
  return (
    <Container>
      <Left>
        <ImageWrapper>
          <Img
            src={product?.coverImage}
            alt=''
            onClick={e => {
              e.preventDefault()
              setIsDetailsModalOpen(true)
            }}
          />
          <MobileNameWrapper>
            <MobileBrandImg src={product?.brand?.iconUrl} />
            <Name>{item?.product?.name}</Name>
          </MobileNameWrapper>
        </ImageWrapper>
        <IconsWrapper>
          {abilityToManageQuantity && (
            <IconWrapper
              background='#f46363'
              onClick={() => {
                toast.promise(
                  removeVariantsToCart && removeVariantsToCart([nodeId]),
                  {
                    loading: 'en cours...',
                    success: 'Le produit a bien été supprimé',
                    error: 'Oops! Une erreur est survenue.',
                  },
                  {
                    duration: 3000,
                  }
                )
              }}
            >
              <HiOutlineTrash />
            </IconWrapper>
          )}
          <IconWrapper
            background='#3A845E'
            onClick={e => {
              e.preventDefault()
              setIsDetailsModalOpen(true)
            }}
          >
            <AiOutlineInfoCircle />
          </IconWrapper>
        </IconsWrapper>
      </Left>

      <Right>
        <Header>
          <HeaderLeft>
            <NameWrapper>
              <Name>{item?.product?.name}</Name>
              <BrandImg src={product?.brand?.iconUrl} />
            </NameWrapper>
            <QuantityWrapper>
              <QuantityWrapperFlex>
                <QuantityChoiceWrapper>
                  <QuantityChoice selected={true}>
                    {getNumberBetweenUnderscores(selectedvariant?.title)}{' '}
                    {isContentUnit(dosings?.takeFormat)
                      ? selectedvariant?.contentUnit
                      : getTakeFormatName(
                          getNumberBetweenUnderscores(selectedvariant?.title),
                          dosings?.takeFormat
                        )}
                  </QuantityChoice>
                </QuantityChoiceWrapper>

                <QuantityHandleWrapper>
                  {abilityToManageQuantity && (
                    <QuantityHandlerMinus
                      onClick={() => {
                        if (item?.quantity === 1)
                          toast.promise(
                            removeVariantsToCart &&
                              removeVariantsToCart([nodeId]),
                            {
                              loading: 'en cours...',
                              success: '',
                              error: 'Oops! Une erreur est survenue.',
                            },
                            {
                              duration: 3000,
                            }
                          )
                        else
                          toast.promise(
                            updateVariantToCart &&
                              updateVariantToCart(
                                nodeId,
                                item?.quantity - 1,
                                productAttributes
                              ),
                            {
                              loading: 'en cours...',
                              success: '',
                              error: 'Oops! Une erreur est survenue.',
                            },
                            {
                              duration: 3000,
                            }
                          )
                      }}
                    >
                      -
                    </QuantityHandlerMinus>
                  )}
                  <QuantityHandleNumber>{item?.quantity}</QuantityHandleNumber>
                  {abilityToManageQuantity && (
                    <QuantityHandlerPlus
                      onClick={() =>
                        toast.promise(
                          updateVariantToCart &&
                            updateVariantToCart(
                              nodeId,
                              item?.quantity + 1,
                              productAttributes
                            ),
                          {
                            loading: 'en cours...',
                            success: '',
                            error: 'Oops! Une erreur est survenue.',
                          },
                          {
                            duration: 3000,
                          }
                        )
                      }
                    >
                      +
                    </QuantityHandlerPlus>
                  )}
                </QuantityHandleWrapper>
              </QuantityWrapperFlex>

              <MobilePricing>
                <Pricing
                  price={Number(selectedvariant?.price?.toFixed(2))}
                  quantity={item?.quantity}
                  subtotalAmount={subtotalAmount}
                  totalAmount={totalAmount}
                  discountPercentage={discountPercentage}
                />
              </MobilePricing>
            </QuantityWrapper>
          </HeaderLeft>
          <HeaderRight>
            <DesktopPricing>
              <Pricing
                price={Number(selectedvariant?.price?.toFixed(2))}
                quantity={item?.quantity}
                subtotalAmount={subtotalAmount}
                totalAmount={totalAmount}
                discountPercentage={discountPercentage}
              />
            </DesktopPricing>
          </HeaderRight>
        </Header>

        <Title>Instructions de prise:</Title>

        <AdjustmentWrapper>
          <AdjustmentItem>
            {instructionQuantity}{' '}
            {getTakeFormatName(instructionQuantity, dosings?.takeFormat)}
          </AdjustmentItem>

          <AdjustmentItem>
            {getTimingDescription(instructionTiming)}
          </AdjustmentItem>

          <AdjustmentItem>{getDuration(instructionDuration)}</AdjustmentItem>
        </AdjustmentWrapper>

        {item?.note && (
          <DescritpionWrapper>
            <DescritpionTextInput>
              {/* {item?.note} */}

              {isLongContent && !isOpened ? (
                <span>{item?.note.substring(0, 100)}... </span>
              ) : (
                <span>{item?.note} </span>
              )}

              {isLongContent && (
                <SeeMore onClick={toggleContent}>
                  {isOpened ? 'Voir moins' : 'Voir plus'}
                </SeeMore>
              )}
            </DescritpionTextInput>
          </DescritpionWrapper>
        )}

        {item?.isNotOrdered && (
          <Info>
            <span>
              <CiCircleAlert />
            </span>
            <p>Ce Produit n'a pas été acheté </p>
          </Info>
        )}

        {item?.isNotCreated && (
          <Info>
            <span>
              <CiCircleAlert />
            </span>
            <p>Ce produit n'a pas été prescrit </p>
          </Info>
        )}
      </Right>

      {/* MODALS */}
      {isDetailModalOpen && (
        <ProductPreviewCardDetailsDialog
          isOpen={isDetailModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          handle={item?.product.handle}
          isPatientCatalog={isPatientCatalog}
        />
      )}
    </Container>
  )
}

export default Product
