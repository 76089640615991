import { Title, Subtitle } from '../../style'
import { Button } from '../../../ui'
import { NoFavoritesProps } from './props'

const NoFavorites = ({
  onClose,
  setIsCreateCategoryDialogOpen,
}: NoFavoritesProps) => {
  return (
    <div>
      <Title>Ajouter des produits à votre bon de commande </Title>
      <Subtitle>Veuillez créer des produits favoris</Subtitle>
      <Button
        appearance='primary'
        iconLeft={undefined}
        isDisabled={undefined}
        isLoading={undefined}
        isActive={undefined}
        onClick={() => {
          onClose()
          setIsCreateCategoryDialogOpen(true)
        }}
      >
        Créer une catégorie de favoris
      </Button>
    </div>
  )
}

export default NoFavorites
