import { DialogContent, Title } from './style'
import { toast } from 'react-toastify'
import { Dialog } from '../../../ui'
import CreatePatientForm from '../../../patient/create-patient-form'
import { useCreatePatient } from '../../../../services/api/patients/patients'

import { getErrorMessage } from '../../../../utils/helpers'
import { useGetMe } from '../../../../utils/hooks/getMe'

const CreateNewPatient = ({ isDialogOpen, setIsDialogOpen, setNotes }) => {
  const { data: prescriber } = useGetMe()

  // Attributes
  const { mutateAsync: addPatient, isLoading: patientLoading } =
    useCreatePatient({
      mutation: {
        onSuccess: async patient => {
          toast('Le client a été créé avec succès !')

          setIsDialogOpen(false)
          setNotes(state => {
            state.patientId = patient.id
          })
        },
        onError: error => {
          const errorMessage = error?.response?.data
          toast.error(getErrorMessage(errorMessage))
        },
      },
    })

  // Functions
  const onNewPatientSubmitted = async newPatientData => {
    addPatient({
      prescriberId: prescriber?.id,
      data: {
        email: newPatientData.email,
        firstName: newPatientData.firstName,
        lastName: newPatientData.lastName,
        phone: newPatientData.phone,
        age: Number.isNaN(Number(newPatientData.age))
          ? undefined
          : Number(newPatientData.age),
        sexe: newPatientData.sexe ? newPatientData.sexe : undefined,
        pathology: newPatientData.pathology,
      },
    })
  }

  //Render
  return (
    <Dialog
      type='aside'
      isOpen={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
    >
      <DialogContent>
        <Title>Créez un nouveau client pour votre note</Title>
        <CreatePatientForm
          onSubmit={onNewPatientSubmitted}
          isLoading={patientLoading}
        />
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewPatient
