import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 12px;
  }
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8e6df;
  border-radius: 8px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
`

export const Text = styled.p`
  font-weight: 500;
  font-size: 14.4528px;
  line-height: 17px;
  color: #080038;
`
