import { View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import CreditCardIcon from '../../../assets/images/creditCardIcon.png'
import UserIcon from '../../../assets/images/userIcon.png'
import TransportIcon from '../../../assets/images/transportIcon.png'

const BookletFooter = ({
  prescriptionId,
  dataUrl,
  discountTotal,
  total,
  prescriberFirstName,
  prescriberLastName,
  discountPercentage,
}) => (
  <View style={styles.footer}>
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '0 20px',
      }}
    >
      <Text style={styles.scanText}>Scanner pour commander</Text>
    </View>
    <View style={styles.priceWrapper}>
      <View style={styles.bannerSection}>
        <View style={styles.bannerSectionText}>
          <View style={styles.bannerSectionIconWrapper}>
            <Image src={TransportIcon} style={styles.bannerSectionIcon} />
          </View>
          <Text>Livraison à domicile en 48h. Gratuit àpd 89€.</Text>
        </View>
        <View style={styles.bannerSectionText}>
          <View style={styles.bannerSectionIconWrapper}>
            <Image src={CreditCardIcon} style={styles.bannerSectionIcon} />
          </View>
          <Text>
            Paiement par CB, carte de crédit, Bancontact, Paypal et virement
            bancaire
          </Text>
        </View>
        <View style={styles.bannerSectionTextLast}>
          <View style={styles.bannerSectionIconWrapper}>
            <Image src={UserIcon} style={styles.bannerSectionIcon} />
          </View>
          <Text>Une question? Notre service client est disponible 24/7</Text>
        </View>
      </View>
      <View style={styles.orderSection}>
        {discountPercentage > 0 && (
          <View style={styles.subtotal}>
            <Text>Sous-total</Text> <Text>{total}€</Text>
          </View>
        )}
        {discountPercentage > 0 && (
          <View style={styles.discount}>
            <Text>
              Reduction de{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {prescriberFirstName} {prescriberLastName}
              </Text>
            </Text>
            <Text>{(total - discountTotal).toFixed(2)}€</Text>
          </View>
        )}
        {discountTotal && (
          <View style={styles.total}>
            <Text style={{ textAlign: 'left' }}>Total </Text>{' '}
            <Text>{discountTotal}€</Text>
          </View>
        )}
      </View>
      <View style={styles.qrSection}>
        <Image
          style={{
            width: 93,
            height: 93,
          }}
          id={prescriptionId}
          src={dataUrl}
        />
      </View>
    </View>

    <View style={styles.bottom}>
      <Text style={styles.footerText}>
        <>
          Pour obtenir des informations supplémentaires concernant votre
          recommandation, les produits, ou pour recommander votre traitement,
          veuillez scanner le QR-code ou visiter le lien ci-dessous :
          https://app.simplycure.com/r/
          {prescriptionId}.
        </>
        Des questions ? Contactez nos conseillers du lundi au vendredi (9h-18h)
        via{' '}
        <Text
          style={{
            color: '#03885b',
            padding: '0 10px',
          }}
        >
          pro@simplycure.com
        </Text>{' '}
        , au{' '}
        <Text
          style={{
            color: '#03885b',
            padding: '0 10px',
          }}
        >
          Tel: 🇫🇷 +33 7 45 89 29 69 ou Tel: 🇧🇪 +32 2 808 79 14{' '}
        </Text>{' '}
        ou sur notre site{' '}
        <Text
          style={{
            color: '#03885b',
            padding: '0 10px',
          }}
        >
          www.simplycure.com/{' '}
        </Text>{' '}
      </Text>

      <View></View>
    </View>

    <Text
      style={{
        fontSize: '7px',
        margin: '-13px 10px 0px 20px',
      }}
    >
      Ce document n’est pas une prescription médicale et ne se substitue en
      aucun cas à un traitement médical spécifique.
    </Text>
  </View>
)

const styles = StyleSheet.create({
  footer: {},
  priceWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bannerSection: {
    margin: '10px',
    padding: '10px 30px 10px 10px',
    flexGrow: 1,
    justifyContent: 'center',
    fontSize: '10px',
    backgroundColor: '#F8F9FC',
    width: '39%',
    height: '80px',
  },
  bannerSectionText: {
    fontSize: '8px',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '6px',
  },
  bannerSectionTextLast: {
    fontSize: '8px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bannerSectionIconWrapper: {
    backgroundColor: '#e0F0e9',
    minHeight: '17px',
    minWidth: '17px',
    maxHeight: '17px',
    maxWidth: '17px',
    borderRadius: '4px',
    marginRight: '4px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerSectionIcon: {
    maxWidth: '8px',
    objectFit: 'contain',
  },
  orderSection: {
    margin: '10px',
    padding: '10px',
    flexGrow: 2,
    textAlign: 'right',
    justifyContent: 'center',
    width: '39%',
    backgroundColor: '#eff6f2',
    height: '80px',
  },
  qrSection: {
    width: '19%',
  },
  subtotal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '11px',
    marginBottom: '5px',
  },
  discount: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '11px',
    marginBottom: '5px',
  },
  total: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '11px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  qrCode: {
    width: '50px',
    height: '50px',
  },
  scanText: {
    fontSize: '12px',
    marginTop: '5px',
  },
  logo: {
    width: '150px',
    margin: '-15px auto 15px auto',
  },
  header: {
    position: 'relative',
    height: '100px',
    width: '100%',
    marginBottom: '20px',
    padding: '20px',
  },
  ImageHeader: {
    position: 'absolute',
    objectFit: 'cover',
    top: 0,
    maxHeight: '100px',
    minHeight: '100px',
    maxWidth: '82vw',
    minWidth: '82vw',
    margin: '0 auto',
  },
  headerPresciberName: {
    fontSize: '22px',
    marginTop: '15px',
    marginBottom: '10px',
  },
  headerText: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
  messageWrapper: {
    fontSize: '11px',
    marginBottom: '20px',
  },
  by: {
    marginBottom: '10px',
    fontSize: '11px',
  },
  presciberName: {
    color: '#03885b',
    marginLeft: '10px',
  },
  firstPage: {
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: '20mm',
    boxSizing: 'border-box',
  },
  bottom: {
    width: '100vw',
    padding: '20px',
    borderTop: '1px solid #eee',
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  footerText: {
    fontSize: '9px',
    maxWidth: '100%',
  },
})

export default BookletFooter
