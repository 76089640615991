import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 36px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.p`
  color: #080038;
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
  margin-right: 10px;
`

export const Subtitle = styled.p`
  color: #6b6688;
  font-size: 14px;
  max-width: 556px;
  line-height: 20px;
  margin-top: 4px;
`

export const ButtonShop = styled.a`
  margin-top: 40px;
  border-radius: 8px;
  border: none;
  color: #65b293;
  background-color: transparent;
  border: 1px solid #3a845e;
  color: #3a845e;
  cursor: pointer;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  padding: 11.5px 10px;
  width: 140px;
`
