/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type QuizStatus = 'SENT' | 'COMPLETED'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuizStatus = {
  SENT: 'SENT' as QuizStatus,
  COMPLETED: 'COMPLETED' as QuizStatus,
}
