import cloneDeep from 'lodash.clonedeep'

import Constant from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

const fetchAuthUserFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.fetchAuthUser = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const fetchAuthUserLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchAuthUser = { status: ActionType.LOADING }

  return newState
}
const fetchAuthUserSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.current = action.payload
  newState.actions.fetchAuthUser = { status: ActionType.SUCCESS }

  return newState
}
const fetchAuthUserReset = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchAuthUser = { status: ActionType.RESET }

  return newState
}

const loginFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.login = { status: ActionType.FAILED, error: action.error }

  return newState
}
const loginLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.login = { status: ActionType.LOADING }

  return newState
}
const loginSuccess = state => {
  const newState = cloneDeep(state)
  newState.actions.login = { status: ActionType.SUCCESS }

  return newState
}

const confirmEmailFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.verifyEmail = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}

const confirmEmailSuccess = state => {
  const newState = cloneDeep(state)
  newState.actions.verifyEmail = { status: ActionType.SUCCESS }

  return newState
}

const resetPasswordFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.resetPassword = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const resetPasswordLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.resetPassword = { status: ActionType.LOADING }

  return newState
}
const resetPasswordSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.resetPassword = { status: ActionType.SUCCESS }

  return newState
}

const sendResetPasswordFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.sendResetPassword = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}

const sendResetPasswordSuccess = state => {
  const newState = cloneDeep(state)
  newState.actions.sendResetPassword = { status: ActionType.SUCCESS }

  return newState
}

const registerFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.register = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const registerLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.register = { status: ActionType.LOADING }

  return newState
}
const registerSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.register = { status: ActionType.SUCCESS }

  return newState
}

const logoutFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.logout = { status: ActionType.FAILED, error: action.error }

  return newState
}
const logoutLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.logout = { status: ActionType.LOADING }

  return newState
}
const logoutSuccess = () => {
  return initialState
}

const setUid = (state, action) => {
  const newState = cloneDeep(state)
  newState.userUid = action.payload

  return newState
}
const setToken = (state, action) => {
  const newState = cloneDeep(state)
  newState.token = action.payload

  return newState
}
const setIsEmailVerified = (state, action) => {
  const newState = cloneDeep(state)
  newState.isEmailVerified = action.payload

  return newState
}
const setAuthPrescriber = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.current = {
    ...newState.current,
    ...payload,
  }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.FETCH_AUTH_USER_FAILED:
      return fetchAuthUserFailed(state, action)
    case Constant.FETCH_AUTH_USER_LOADING:
      return fetchAuthUserLoading(state)
    case Constant.FETCH_AUTH_USER_SUCCESS:
      return fetchAuthUserSuccess(state, action)
    case Constant.FETCH_AUTH_USER_RESET:
      return fetchAuthUserReset(state)

    case Constant.LOGIN_FAILED:
      return loginFailed(state, action)
    case Constant.LOGIN_LOADING:
      return loginLoading(state)
    case Constant.LOGIN_SUCCESS:
      return loginSuccess(state)

    case Constant.RESET_PASSWORD_FAILED:
      return resetPasswordFailed(state, action)
    case Constant.RESET_PASSWORD_LOADING:
      return resetPasswordLoading(state)
    case Constant.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action)

    case Constant.SEND_RESET_PASSWORD_FAILED:
      return sendResetPasswordFailed(state, action)
    case Constant.SEND_RESET_PASSWORD_SUCCESS:
      return sendResetPasswordSuccess(state)

    case Constant.REGISTER_FAILED:
      return registerFailed(state, action)
    case Constant.REGISTER_LOADING:
      return registerLoading(state)
    case Constant.REGISTER_SUCCESS:
      return registerSuccess(state, action)

    case Constant.LOGOUT_FAILED:
      return logoutFailed(state, action)
    case Constant.LOGOUT_LOADING:
      return logoutLoading(state)
    case Constant.LOGOUT_SUCCESS:
      return logoutSuccess()

    case Constant.SET_UID:
      return setUid(state, action)
    case Constant.SET_TOKEN:
      return setToken(state, action)
    case Constant.SET_IS_EMAIL_VERIFIED:
      return setIsEmailVerified(state, action)
    case Constant.SET_AUTH_PRESCRIBER:
      return setAuthPrescriber(state, action)

    case Constant.CONFIRM_EMAIL_FAILED:
      return confirmEmailFailed(state, action)
    case Constant.CONFIRM_EMAIL_SUCCESS:
      return confirmEmailSuccess(state)

    default:
      return state
  }
}
