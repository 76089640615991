const Dots = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z'
      strokeWidth='1.8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.00098 9.75C9.41519 9.75 9.75098 9.41421 9.75098 9C9.75098 8.58579 9.41519 8.25 9.00098 8.25C8.58676 8.25 8.25098 8.58579 8.25098 9C8.25098 9.41421 8.58676 9.75 9.00098 9.75Z'
      strokeWidth='1.8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.74902 9.75C4.16324 9.75 4.49902 9.41421 4.49902 9C4.49902 8.58579 4.16324 8.25 3.74902 8.25C3.33481 8.25 2.99902 8.58579 2.99902 9C2.99902 9.41421 3.33481 9.75 3.74902 9.75Z'
      strokeWidth='1.8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Dots
