import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 20px;
  max-width: 320px;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const Image = styled.img`
  width: 67.57px;
  height: 72.26px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Title = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #080038;
`

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #9c99af;
`
