import styled from 'styled-components/macro'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const FormTitle = styled.h4`
  font-size: 14px;
  margin-top: 0.7em;
`

export const ButtonProtocolsWrapper = styled.div`
  display: flex;
  margin: 20px 0 0px 0;
`

export const FieldWrapper = styled.div`
  z-index: 2;
`
