import { useEffect } from 'react'
import { HubspotFormProps } from './props'
import { Container } from './style'

const HubspotForm = ({ formId, onSubmitted, onReady }: HubspotFormProps) => {
  // Effects
  useEffect(() => {
    // @ts-ignore
    if (window.hbspt) {
      // @ts-ignore
      window.hbspt.forms.create({
        region: process.env.REACT_APP_HUBSPOT_REGION,
        portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
        formId: formId,
        target: '#hubspot-form',
        onFormSubmitted: onSubmitted,
        onFormReady: onReady,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Render
  return <Container id='hubspot-form' />
}

export default HubspotForm
