import { Link } from 'react-router-dom'
import {
  Container,
  DropdownContent,
  MoreIcon,
  DropdownItem,
  DropdownIcon,
  DropdownText,
  FlexContainer,
  SeeIcon,
} from './style'
import { Dropdown } from '../../../../../components/ui'
import { AiOutlineEye } from 'react-icons/ai'
import { IoIosMore } from 'react-icons/io'

interface MoreProps {
  id: string
}

const More = ({ id }: MoreProps) => {
  // Render
  return (
    <Container>
      <FlexContainer>
        <Link to={`/note/${id}`}>
          <SeeIcon>
            <AiOutlineEye />
          </SeeIcon>
        </Link>
        <Dropdown
          content={
            <DropdownContent>
              <Link to={`/note/${id}`}>
                <DropdownItem>
                  <DropdownIcon>
                    <AiOutlineEye />
                  </DropdownIcon>
                  <DropdownText>Voir la note</DropdownText>
                </DropdownItem>
              </Link>
            </DropdownContent>
          }
        >
          <MoreIcon>
            <IoIosMore />
          </MoreIcon>
        </Dropdown>
      </FlexContainer>
    </Container>
  )
}

export default More
