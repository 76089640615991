import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
  padding: 65px 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const MenuContainer = styled.p`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  a {
    font-size: 12px;
    padding: 2px 10px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 60%;
  margin: 50px 0;
`

export const DropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 0 10px 0;
`
