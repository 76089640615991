import { Link, useLocation } from 'react-router-dom'
import { Nav, NavItem } from './style'
import { useGetMe } from '../../../utils/hooks/getMe'

const SettingsAsideNav = () => {
  const { pathname } = useLocation()
  const { data: prescriber } = useGetMe()

  const navItems = [
    { to: '/settings/address', label: 'Coordonnées' },
    {
      to: '/settings/payment',
      label: prescriber?.disableCommission
        ? 'Réductions clients'
        : 'Points et réduction',
    },
    { to: '/settings/brands', label: 'Marques' },
  ]

  return (
    <Nav>
      {navItems.map(item => {
        return (
          <NavItem isActive={pathname === item.to}>
            <Link key={item.to} to={item.to}>
              {item.label}
            </Link>
          </NavItem>
        )
      })}
    </Nav>
  )
}

export default SettingsAsideNav
