import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Button, ButtonWrapper } from './style'
import { RequestProductProps } from './props'
import { useGetMe } from '../../../utils/hooks/getMe'

import SponsorFormDialog from '../../sponsor/form-dialog'

const RequestProduct = ({
  setIsInnerDialogOpen,
  isFavoriteButtonDisplayed,
}: RequestProductProps) => {
  const { data: presciber } = useGetMe()
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  // Hooks
  useEffect(() => {
    setIsInnerDialogOpen && setIsInnerDialogOpen(!isModalOpen)
  }, [isModalOpen])

  return (
    <>
      <ButtonWrapper>
        {isFavoriteButtonDisplayed && (
          <Button
            style={{
              background: '#3A845E',
              color: '#fff',
              marginRight: '20px',
            }}
            onClick={() => {
              history.push('/favorites')
            }}
          >
            Mes favoris
          </Button>
        )}
        <Button onClick={() => setIsModalOpen(true)}>
          Demande de nouveaux produits
        </Button>
      </ButtonWrapper>

      {/* DIALOGS */}
      {isModalOpen && (
        <SponsorFormDialog
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          formId={'1005ba2e-b333-4a69-a4bd-3e25af42ac2a'}
          prescriber={presciber}
          succeesMessageTitle='Votre demande a bien été envoyée'
          succeesMessageDescription='Nous ferons notre mieux pour les intégrer dès que possible. Dans certains cas, ils peuvent être disponibles en 1 semaine !'
          successMessageText='Fermer'
          successImageLink=''
        />
      )}
    </>
  )
}

export default RequestProduct
