import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 36px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.p`
  color: #080038;
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
`

export const Subtitle = styled.p`
  color: #6b6688;
  font-size: 14px;
  max-width: 556px;
  line-height: 20px;
  margin-top: 4px;
`
