import { useEffect, useState } from 'react'
import LoginPatientPageMobile from '../../components/auth/login-patient/login-patient-mobile'
import LoginPatientWebApp from '../../components/auth/login-patient/login-patient-web-app'

const LoginPatientPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 768)
    })

    return () => {
      window.removeEventListener('resize', () => {
        setIsMobile(window.innerWidth < 768)
      })
    }
  }, [])

  return <>{isMobile ? <LoginPatientPageMobile /> : <LoginPatientWebApp />}</>
}

export default LoginPatientPage
