import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  list: [],
  actions: {
    fetchAll: { ...INITIAL_ACTION_STATE },
  },
}

export default state
