import PropTypes from 'prop-types'

import { GlobalContainer, Main } from './style'
import Header from '../../section/header'
import AlertBanner from '../../home/alert-banner'
import { useHistory } from 'react-router'

const DefaultLayout = ({ children, isPublic = false, active }) => {
  //Attributes
  const { location } = useHistory()
  const displayBanner = location.pathname === '/'

  //Render
  return (
    <GlobalContainer>
      {/* <AlertBanner displayBanner={displayBanner} /> */}
      <Header isPublic={isPublic} active={active} />
      <Main>{children}</Main>
    </GlobalContainer>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
