import {
  Form,
  InputsFlexWrapper,
  InputWrapper,
  Label,
  Input,
  Button,
  Select,
  Error,
} from './style'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PhoneInput } from '../../../ui'
import {
  useCreatePrescriberAddress,
  useUpdatePrescriberAddress,
  useGetPrescriberAddressByPrescriberId,
  useUpdatePrescriber,
} from '../../../../services/api/prescriber/prescriber'
import { useGetMe } from '../../../../utils/hooks/getMe'

const AddressForm = () => {
  // Attribites
  const countries = [
    'France',
    'Belgique',
    'Luxembourg',
    'Suisse',
    'Allemagne',
    'Autriche',
    'Chypre',
    'Espagne',
    'Estonie',
    'Finlande',
    'Grèce',
    'Irlande',
    'Italie',
    'Lettonie',
    'Lituanie',
    'Malte',
    'Pays-Bas',
    'Portugal',
    'Slovaquie',
    'Slovénie',
  ]

  const history = useHistory()
  const { mutateAsync: editPrescriber } = useUpdatePrescriber()
  const { data: prescriber } = useGetMe()

  const { mutateAsync: createPrescriberAddress } = useCreatePrescriberAddress()
  const { mutateAsync: updatePrescriberAddress } = useUpdatePrescriberAddress()

  const { data: addressByPrescriberId } = useGetPrescriberAddressByPrescriberId(
    prescriber?.id,
    {
      query: { enabled: !!prescriber?.id },
    }
  )

  const schema = Yup.object().shape({
    phone: Yup.string()
      .min(6, 'Champs obligatoire')
      .required('Champs obligatoire'),
    address: Yup.string().required('Champs obligatoire'),
    number: Yup.string().required('Champs obligatoire'),
    city: Yup.string().required('Champs obligatoire'),
    postalCode: Yup.string().required('Champs obligatoire'),
    country: Yup.string().required('Champs obligatoire'),
  })

  const onSubmit = async () => {
    try {
      await editPrescriber({
        prescriberId: prescriber?.id,
        data: {
          activeDiscountId: prescriber?.activeDiscount?.id || null,
          firstName: prescriber?.firstName,
          lastName: prescriber?.lastName,
          phoneNumber: prescriber?.phoneNumber,
          enterpriseName: prescriber?.enterpriseName,
          invoiceAddress: prescriber?.invoiceAddress,
          bankAccount: prescriber?.bankAccount,
          calendlyCode: prescriber?.calendlyCode,
          profession: prescriber?.profession,
          isOnboarded: true,
          disableCommission: prescriber?.disableCommission,
        },
      })

      toast.success("Votre onboarding s'est déroulé avec succès!", {
        duration: 3000,
      })
      history.push('/')
    } catch (error) {
      toast.error("Une erreur s'est produite", { duration: 3000 })
    }
  }

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      enterpriseName: addressByPrescriberId?.enterpriseName
        ? addressByPrescriberId.enterpriseName
        : '',
      phone: addressByPrescriberId?.phoneNumber
        ? addressByPrescriberId.phoneNumber
        : prescriber?.phoneNumber,
      address: addressByPrescriberId?.street
        ? addressByPrescriberId.street
        : '',
      number: addressByPrescriberId?.number ? addressByPrescriberId.number : '',
      tav: addressByPrescriberId?.tvaNumber
        ? addressByPrescriberId.tvaNumber
        : '',
      city: addressByPrescriberId?.city ? addressByPrescriberId.city : '',
      postalCode: addressByPrescriberId?.postalCode
        ? addressByPrescriberId.postalCode
        : '',
      country: addressByPrescriberId?.country
        ? addressByPrescriberId.country
        : countries[0],
    },
    validationSchema: schema,
    onSubmit: values => {
      if (addressByPrescriberId)
        toast
          .promise(
            updatePrescriberAddress({
              prescriberId: prescriber?.id,
              data: {
                id: addressByPrescriberId.id,
                street: values.address,
                number: values.number,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                more: '',
                tvaNumber: values.tav,
                phonePrefix: '',
                phoneNumber: values.phone,
                enterpriseName: values.enterpriseName,
              },
            }),
            {
              loading: 'en cours...',
              success: "L'adresse a bien été mise à jour",
              error: "Une erreur s'est produite ",
            },
            {
              duration: 3000,
            }
          )
          .then(() => {
            // toast.success("Votre onboarding s'est déroulé avec succès!", {
            //   duration: 3000,
            // })
            // window.location.replace('/')
          })
      else
        toast
          .promise(
            createPrescriberAddress({
              prescriberId: prescriber?.id,
              data: {
                street: values.address,
                number: values.number,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                more: '',
                tvaNumber: values.tav,
                phonePrefix: '',
                phoneNumber: values.phone,
                enterpriseName: values.enterpriseName,
              },
            }),
            {
              loading: 'en cours...',
              success: "L'adresse a bien été mise à jour",
              error: "Une erreur s'est produite ",
            },
            {
              duration: 3000,
            }
          )
          .then(() => {
            // toast.success("Votre onboarding s'est déroulé avec succès!", {
            //   duration: 3000,
            // })
            // window.location.replace('/')
          })
    },
  })

  // Render
  return (
    <Form onSubmit={handleSubmit}>
      <InputsFlexWrapper>
        <InputWrapper width='100%'>
          <Label>Téléphone *</Label>
          <PhoneInput onChange={handleChange} value={values.phone} />
          {touched.phone && (
            <Error style={{ top: '77px' }}>{errors.phone}</Error>
          )}
        </InputWrapper>
      </InputsFlexWrapper>
      <InputsFlexWrapper>
        <InputWrapper width='72%'>
          <Label>Adresse *</Label>
          <Input
            name='address'
            type='text'
            onChange={handleChange}
            value={values.address}
            error={errors.hasOwnProperty('address') && touched.address}
            isRequired
          />
          {touched.address && <Error>{errors.address}</Error>}
        </InputWrapper>
        <InputWrapper width='22%'>
          <Label>Nr *</Label>
          <Input
            name='number'
            type='text'
            onChange={handleChange}
            value={values.number}
            error={errors.hasOwnProperty('number') && touched.number}
          />
          {touched.number && <Error>{errors.number}</Error>}
        </InputWrapper>
      </InputsFlexWrapper>

      <InputsFlexWrapper>
        <InputWrapper width='63%'>
          <Label>Ville *</Label>
          <Input
            name='city'
            type='text'
            onChange={handleChange}
            value={values.city}
            error={errors.hasOwnProperty('city') && touched.city}
          />
          {touched.city && <Error>{errors.city}</Error>}
        </InputWrapper>
        <InputWrapper width='33%'>
          <Label>Code postal *</Label>
          <Input
            name='postalCode'
            type='text'
            onChange={handleChange}
            value={values.postalCode}
            error={errors.hasOwnProperty('postalCode') && touched.postalCode}
          />
          {touched.postalCode && <Error>{errors.postalCode}</Error>}
        </InputWrapper>
      </InputsFlexWrapper>

      <InputsFlexWrapper>
        <InputWrapper width='47%'>
          <Label>Pays *</Label>

          <Select
            name='country'
            onChange={handleChange}
            value={values.country}
            error={errors.hasOwnProperty('country')}
          >
            {countries.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </Select>
        </InputWrapper>

        <InputWrapper width='47%'>
          <Label>.</Label>
          <Button type='submit'>Enregistrer</Button>
        </InputWrapper>
      </InputsFlexWrapper>
    </Form>
  )
}

export default AddressForm
