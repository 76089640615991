import { ErrorContainer, Field } from '../field/style'
import { InputContainer } from './style'
import Label from '../label'
import PhoneInput from 'react-phone-input-2'
import fr from 'react-phone-input-2/lang/fr.json'
import { useGetPrescriberAddressByPrescriberId } from '../../../services/api/prescriber/prescriber'
import { useGetMe } from '../../../utils/hooks/getMe'
import { IPhoneInputProps } from './types'

const PhoneInputEl = ({
  value,
  onChange,
  error,
  touched,
  isRequired,
  label,
  info,
  name,
  id,
  type,
}: IPhoneInputProps) => {
  // Attributes
  const countryToCodeMapping = {
    France: 'fr',
    Belgique: 'be',
    Luxembourg: 'lu',
    Suisse: 'ch',
    Allemagne: 'de',
    Autriche: 'at',
    Chypre: 'cy',
    Espagne: 'es',
    Estonie: 'ee',
    Finlande: 'fi',
    Grèce: 'gr',
    Irlande: 'ie',
    Italie: 'it',
    Lettonie: 'lv',
    Lituanie: 'lt',
    Malte: 'mt',
    'Pays-Bas': 'nl',
    Portugal: 'pt',
    Slovaquie: 'sk',
    Slovénie: 'si',
  }
  const { data: prescriber } = useGetMe()

  const { data: addressByPrescriberId } = useGetPrescriberAddressByPrescriberId(
    prescriber?.id,
    {
      query: { enabled: !!prescriber?.id },
    }
  )

  // Functions
  const getDefaultPhoneInputCountry = () => {
    const country = addressByPrescriberId?.country
    return countryToCodeMapping[country] || 'fr' // default to 'fr' if country is not in the mapping
  }

  function getValueOrDefault(value) {
    // Trim the input value to remove leading/trailing whitespace
    const trimmedValue = value.trim()

    // Check if the trimmed value is empty or undefined, return '33' if so
    return trimmedValue === '' || trimmedValue === undefined
      ? '33'
      : trimmedValue
  }

  return (
    <Field>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <InputContainer>
        <PhoneInput
          searchPlaceholder='Recherche'
          localization={fr}
          enableSearch
          disableSearchIcon={false}
          country={getDefaultPhoneInputCountry()}
          inputProps={{
            name: name ?? 'phone',
            required: false,
            autoFocus: true,
          }}
          value={getValueOrDefault(value)}
          onChange={onChange(name ?? 'phone')}
          preferredCountries={['be', 'fr', 'lu', 'es', 'ch', 'de', 'it', 'us']}
          countryCodeEditable={false}
        />
        {error && touched ? <ErrorContainer>{error}</ErrorContainer> : null}
        {info && <span>{info}</span>}
      </InputContainer>
    </Field>
  )
}

export default PhoneInputEl
