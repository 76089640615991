import { useState } from 'react'
import {
  Wrapper,
  Header,
  List,
  Item,
  Date,
  TextWrapper,
  Button,
  Right,
  Img,
  EventType,
} from '../style'
import { SeeMore } from '../../webinars/news/style'
import { client } from '../../../../services/sanity/client'
import { useQuery } from 'react-query'
import {
  convertTimestampOrDateStringToFrenchDateString,
  sortDates,
  transformSanityImageURL,
} from '../../../../utils/helpers'

const fetchData = async () => {
  const data = await client.fetch('*[_type == "latest-news"]')
  return data
}

const LatestNews = () => {
  const { data } = useQuery('latestNews', fetchData)

  return (
    <Wrapper>
      <Header>
        <h3>Dernières actualités</h3>
      </Header>
      <List>
        {sortDates(data, 'MostRecentToOldest', 'event_date')?.map(
          ({
            cta_text,
            cta_url,
            description,
            event_type,
            event_date,
            title,
            image,
          }) => (
            <Row
              image={image}
              title={title}
              event_type={event_type}
              description={description}
              event_date={event_date}
              cta_url={cta_url}
              cta_text={cta_text}
            />
          )
        )}
      </List>
    </Wrapper>
  )
}

export default LatestNews

const Row = ({
  image,
  title,
  event_type,
  description,
  event_date,
  cta_url,
  cta_text,
}) => {
  const isLongContent = description?.length > 100
  const [isOpened, setIsOpened] = useState(!isLongContent)

  const toggleContent = () => {
    setIsOpened(!isOpened)
  }

  return (
    <Item>
      {image && <Img src={transformSanityImageURL(image?.asset?._ref)} />}
      <TextWrapper>
        <EventType>{event_type}</EventType>
        <h3>{title}</h3>
        <p>
          {isLongContent && !isOpened ? (
            <span>{description.substring(0, 100)}... </span>
          ) : (
            <span>{description} </span>
          )}

          {isLongContent && (
            <SeeMore onClick={toggleContent}>
              {isOpened ? 'Voir moins' : 'Voir plus'}
            </SeeMore>
          )}
        </p>
        <Date>
          {convertTimestampOrDateStringToFrenchDateString(event_date)}
        </Date>
      </TextWrapper>
      <Right>
        <Button href={cta_url}>{cta_text}</Button>
      </Right>
    </Item>
  )
}
