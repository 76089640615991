import { useState } from 'react'
import { useUpdatePrescriberRewardByPrescriberIdAndRewardId } from '../../../services/api/prescriber/prescriber'
import { PrescriberRewardStatus } from '../../../services/api/types'
import HubspotForm from '../../hubspot-form'
import { Dialog, Spinner } from '../../ui'
import { IconCross } from '../../ui/icon/icons'
import { RewardFormDialogProps } from './props'
import {
  CloseButton,
  CloseButtonContainer,
  Container,
  HubspotContainer,
  LoadingContainer,
  NoFormContainer,
  NoFormTitle,
} from './style'
import RewardFormDialogSuccess from './success'

const RewardFormDialog = ({
  isOpen,
  onClose,
  prescriber,
  rewardId,
  formId,
}: RewardFormDialogProps) => {
  // Attributes
  const [isFormLoading, setIsFormLoading] = useState<boolean>(true)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const { mutateAsync: updateReward } =
    useUpdatePrescriberRewardByPrescriberIdAndRewardId()

  // Functions
  function onFormSubmitted() {
    setIsFormSubmitted(true)
    updateReward({
      prescriberId: prescriber?.id,
      rewardId: rewardId,
      data: { status: PrescriberRewardStatus.RECIEVED },
    })
  }

  function onFormReady(form) {
    setIsFormLoading(false)

    // Fill default values
    form.find('input[name="firstname"]').val(prescriber?.firstName).change()
    form.find('input[name="lastname"]').val(prescriber?.lastName).change()
    form.find('input[name="email"]').val(prescriber?.email).change()
  }

  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      type='defaultCenter'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton onClick={onClose}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container>
        {!formId ? (
          <NoFormContainer>
            <NoFormTitle>Pas de formulaire disponible</NoFormTitle>
          </NoFormContainer>
        ) : (
          <div>
            <LoadingContainer displayed={isFormLoading}>
              <Spinner fullScreen={undefined} />
            </LoadingContainer>

            <HubspotContainer displayed={!isFormLoading}>
              {isFormSubmitted ? (
                <RewardFormDialogSuccess onClose={onClose} />
              ) : (
                <HubspotForm
                  formId={formId}
                  onSubmitted={onFormSubmitted}
                  onReady={onFormReady}
                />
              )}
            </HubspotContainer>
          </div>
        )}
      </Container>
    </Dialog>
  )
}

export default RewardFormDialog
