import { useEffect } from 'react'
import {
  SelectedPatientWrapper,
  CloseSelectedPatient,
  SelectedPatientInital,
  SelectedPatientFullName,
} from './style'
import { AiOutlineCloseCircle } from 'react-icons/ai'

import { useGetMe } from '../../../../utils/hooks/getMe'

import { useGetPatient } from '../../../../services/api/patients/patients'
import { NoteState } from '../../../../pages/notes/props'
import { Updater } from 'use-immer'

export interface SelectedPatientProps {
  patientId: number
  setNotes: (state: Updater<NoteState>) => void
  allowDeletion?: boolean
}

const SelectedPatient = ({
  patientId,
  setNotes,
  allowDeletion,
}: SelectedPatientProps) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const { data: patient, isSuccess } = useGetPatient(
    prescriber?.id,
    patientId,
    {
      query: {
        enabled: !!prescriber?.id && !!patientId,
      },
    }
  )

  useEffect(() => {
    isSuccess &&
      setNotes((state: NoteState) => {
        if (state.title.length <= 0)
          state.title = `Consultation: ${patient?.firstName} ${patient?.lastName}`
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  // Render
  return (
    <SelectedPatientWrapper>
      <SelectedPatientInital>
        {patient?.firstName?.[0]}
        {patient?.lastName?.[0]}
      </SelectedPatientInital>
      <SelectedPatientFullName>
        {patient?.firstName} {patient?.lastName}
      </SelectedPatientFullName>
      {allowDeletion && (
        <CloseSelectedPatient
          onClick={() => {
            setNotes((state: NoteState) => {
              state.patientId = null
            })
          }}
        >
          <AiOutlineCloseCircle />
        </CloseSelectedPatient>
      )}
    </SelectedPatientWrapper>
  )
}

export default SelectedPatient
