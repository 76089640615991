import { ProductDTO, ProductVariantDTO } from '../../services/api/types'

export function getProductPrice(
  product: ProductDTO,
  recommendedDuration = 'ONE_MONTH'
) {
  if (product?.standAlone) {
    return (
      getVariantForStandAloneProduct(product, recommendedDuration)?.price || 0
    )
  }
  return getProductVariant(product, recommendedDuration)?.price || 0
}

export function getProductPricePerMonth(product: ProductDTO) {
  return getProductPrice(product, 'ONE_MONTH')
}

function getVariantForStandAloneProduct(
  product: ProductDTO,
  recommendedDuration: string
): ProductVariantDTO | undefined {
  const variantTitles = {
    ONE_MONTH: '1month_onetime_pro',
    TWO_MONTH: '2month_onetime_pro',
    THREE_MONTH: '3month_onetime_pro',
    'ONE_MONTH-subscription': '1month_subscription_pro',
    'TWO_MONTH-subscription': '2month_subscription_pro',
    'THREE_MONTH-subscription': '3month_subscription_pro',
  }
  let variantTitle = '1month_onetime'

  variantTitle = variantTitles[recommendedDuration]

  return product?.variants?.find(({ title }) => title === variantTitle)
}

export function getProductVariantId(
  product: ProductDTO,
  recommendedDuration = 'ONE_MONTH'
) {
  if (product?.standAlone) {
    return getVariantForStandAloneProduct(product, recommendedDuration)?.id
  }

  return getProductVariant(product, recommendedDuration)?.id
}

function getVariantTitleByRecommendedDuration(recommendedDuration) {
  return {
    ONE_MONTH: '1month_onetime',
    TWO_MONTH: '2month_onetime',
    THREE_MONTH: '3month_onetime',
    'ONE_MONTH-subscription': '1month_subscription',
    'TWO_MONTH-subscription': '2month_subscription',
    'THREE_MONTH-subscription': '3month_subscription',
  }[recommendedDuration]
}

function getProductVariant(
  product: ProductDTO,
  recommendedDuration = 'ONE_MONTH'
) {
  return product?.variants?.find(
    ({ title }) =>
      title === getVariantTitleByRecommendedDuration(recommendedDuration)
  )
}

export enum boxProductVariants {
  FREE = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzQ3NzA0MzE4MzgzMw==',
  ONE_MONTH_SUBSCRIPTION = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzQ5MDg1MDQ3MjE1Mw==',
  ONE_MONTH_SUBSCRIPTION_SELLING_PLAN_ID = 'Z2lkOi8vc2hvcGlmeS9TZWxsaW5nUGxhbi8zMTg1NTA4NTY5',
  ONE_MONTH = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzM4MzEwMzc4MzEyOQ==',
  TWO_MONTH = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzM4MzEwMzgxNTg5Nw==',
  THREE_MONTH = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzM4MzEwMzg0ODY2NQ==',
}

export const leastVariantsPrice = variants => {
  if (variants?.length === 0) {
    return 0
  }
  return variants?.reduce((minPriceProductVariant, currentProductVariant) => {
    return minPriceProductVariant?.price < currentProductVariant?.price
      ? minPriceProductVariant
      : currentProductVariant
  }, 0)
}
