import styled from 'styled-components/macro'

export const Filters = styled.div`
  gap: 0.625rem;
  display: flex;
  flex-wrap: wrap;
`

export const Filter = styled.div`
  margin-top: 0.625rem;
  margin-right: 0.625rem;
  background-color: #e9eaf0;
  border-radius: 1.875rem;
  padding: 0.25rem;
  font-size: 0.75rem;
  display: flex;
  p {
    white-space: nowrap;
    margin: 0 0.3125rem;
  }
  span {
    cursor: pointer;
    margin-top: 0.125rem;
    margin-left: 0.5rem;
  }
`
