export const getInitCartStatus = ({ cart }) => cart.actions.initCart.status
export const getAddToCartStatus = ({ cart }) => cart.actions.addToCart.status
export const getupdateCartStatus = ({ cart }) => cart.actions.updateCart.status
export const getcartLinesRemoveStatus = ({ cart }) =>
  cart.actions.cartLinesRemove.status
export const getcartAtributesUpadteStatus = ({ cart }) =>
  cart.actions.cartAttributesUpdate.status
export const getCartDiscountUpadteStatus = ({ cart }) =>
  cart.actions.cartDiscountUpdate.status
export const getCart = ({ cart }) => cart.cart
