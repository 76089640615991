import styled from 'styled-components/macro'

export const Suggestion = styled.div`
  margin-top: 20px;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  padding: 8px 20px;
  font-weight: 300;
  font-size: 13px;

  display: flex;
  align-items: center;

  p {
    opacity: 0.6;
    span {
      cursor: pointer;
      text-decoration: underline;
      margin-left: 10px;
    }
  }
`

export const SuggestionIcon = styled.span`
  font-size: 20px;
  margin-right: 20px;
  opacity: 0.6;
`
