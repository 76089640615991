import styled from 'styled-components/macro'

import { Color, MediaQuery } from '../../../utils/constants'

export const Button = styled.button`
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 900;
  color: ${({ isDisabled }) => (isDisabled ? '#ddd' : Color.DEEP_SEA)};
  padding: 10px 20px;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0);

  &:hover {
    transform: scale(1.02);
  }
`

export const Small = styled.p`
  font-size: 14px;
  line-height: 18px;
`

export const DialogHeader = styled.div`
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid ${Color.MYSTIC};

  > div:nth-child(1) {
    flex: 1;
    padding-right: 50px;
  }
`

export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ isActive }) => (isActive ? 'inherit' : '#ffa760')};
  border-radius: 8px;

  background-color: #faf9fb;
  overflow-y: scroll;
`
export const SpinnerContainer = styled.div`
  height: 400px;
`

export const Container = styled.div`
  max-height: 85vh;
  padding: 40px;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
    padding: 40px 20px 20px 20px;
  }
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 100;
  left: 5px;
  top: 5px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const PillImageContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ $bg }) => `url("${$bg}")`};
`

export const PillImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`

export const DialogSubTitle = styled.h2`
  font-size: 20px;
`

export const VerticalSeparator = styled.div`
  min-width: 1px;
  background: #e7e9f4;
`

export const StrongPoints = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 20px;
`

export const Point = styled.ul`
  position: relative;
  padding: 0 0 20px 0;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: 8px;
    left: -22px;
    background-color: ${Color.MINE_SHAFT};
  }
  &:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top: 12px;
    left: -19px;
    background-color: ${Color.MINE_SHAFT};
  }

  &:last-child:after {
    display: none;
  }
`

export const PointText = styled.p`
  font-size: 14px;
  margin-top: 10px;
  white-space: pre-wrap;
`
