import HeaderSignin from '../../../section/header-sign-in/HeaderSignin'
import {
  BodyContainer,
  MainContainer,
  Title,
  FooterContent,
  Footer,
  Form,
  FooterTextContent,
} from './style'
import { Button, Field } from '../../../ui'
import { useHistory } from 'react-router'
import useSignInUserLogic from '../logic'

function LoginPatientPageMobile() {
  //Attributes
  const history = useHistory()

  //Functions
  function redirectionToSignUp() {
    history.push('/patient/register')
  }

  //Logic
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isAllLoading,
    resetPassword,
  } = useSignInUserLogic()

  //Render
  return (
    <BodyContainer>
      <HeaderSignin />
      <MainContainer onSubmit={handleSubmit}>
        <Title>
          Connectez-vous <br /> à votre{' '}
          <span className='partword'>compte client !</span>
        </Title>
        <Form>
          <Field
            label='Email'
            name='email'
            id='login-email'
            type='email'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={errors.email}
            touched={touched.email}
            isDisabled={isAllLoading}
            placeholder='Insérez votre adresse mail'
          />
          {errors.email && errors.touched}
          <Field
            label='Mot de passe'
            id='password'
            name='password'
            type='password'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors.password}
            touched={touched.password}
            isDisabled={isAllLoading}
            placeholder='Mot de passe'
          />
          <FooterTextContent onClick={() => resetPassword(values.email)}>
            Mot de passe oublié ?
          </FooterTextContent>
        </Form>
        <FooterContent>
          <Button type='submit'>Me connecter</Button>
          <Footer>
            Vous n’avez pas encore de compte ?{' '}
            <span className='partword' onClick={redirectionToSignUp}>
              Inscrivez-vous !
            </span>
          </Footer>
        </FooterContent>
      </MainContainer>
    </BodyContainer>
  )
}

export default LoginPatientPageMobile
