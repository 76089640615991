import { Link } from 'react-router-dom'
import { Container, InfoContainer, P, Label } from './style'
import { Avatar } from '../../ui'
import { PatientTileProps } from './props'

const PatientTile = ({ patient }: PatientTileProps) => (
  <Container>
    <Link to={`/patients/${patient?.id}`}>
      <Avatar
        size='l'
        firstName={patient?.firstName}
        lastName={patient?.lastName}
        image={undefined}
      />
    </Link>
    <Link to={`/patients/${patient?.id}`}>
      <InfoContainer>
        <Label>Nom</Label>
        <P>{`${patient?.firstName} ${patient?.lastName}`}</P>
        <Label>Email</Label>
        <P>{patient?.email}</P>
        <Label>Téléphone</Label>
        <P>{patient?.phone || '-'}</P>
      </InfoContainer>
    </Link>
  </Container>
)

export default PatientTile
