import styled from 'styled-components/macro'
import { Color } from '../../utils/constants'

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Container = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 32px 28px;
  min-width: 650px;
`

export const Title = styled.h3`
  margin: 10px 0;
  font-size: 16px;
`
export const SubTitle = styled.h4`
  margin: 5px 0 20px 0;
  font-size: 13px;
  opacity: 0.8;
`

export const ButtonWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
`

export const TextContent = styled.div`
  font-size: 14px;
  background: #f6f7fb;
  padding: 10px;
  border-radius: 3px;
`

export const Select = styled.select`
  width: 100%;
  background: #f6f7fb;
  border-radius: 3px;
  padding: 6px 20px;
  border: none;
  font-size: 14px;
  margin: 0 8px;
  outline: none;
`

export const InputButton = styled.button`
  cursor: pointer;
  border: 1px solid #e9eaf0;
  padding: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const Input = styled.input`
  border: 1px solid #e9eaf0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 8px;
  outline: none;
  width: 100%;
`
export const CopyToClipboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const InputWrapper = styled.div`
  margin-top: 10px;
  min-width: 100%;
`

export const PatientLink = styled.p`
  max-width: 85%;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
  color: ${Color.DEEP_SEA};
  border: 1px solid #ddd;
  padding: 13px;
  border-radius: 3px;
  word-wrap: break-word;
  white-space: nowrap;
  font-weight: 500;
`

export const EditReduction = styled.button`
  margin-left: 3px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  opacity: 0.7;
  border-bottom: 1px solid #777;
  span {
    font-size: 9px;
    margin-right: 3px;
  }
`

export const MoreInfo = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  width: 65%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;

  .ReactCollapse--collapse {
    transition: height 100ms;
    width: 100% !important;
  }
`

export const MoreInfoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  p {
    display: block;
    font-size: 14px;
    opacity: 0.8;
  }
`

export const SendEmailTitle = styled.h3`
  font-size: 14px;
  opacity: 0.8;
  margin: 20px 0 10px 0;
`

export const MoreInfoArrow = styled.div`
  font-size: 18px;
  display: block;
  color: #a3a3a3;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.2s;
`
export const IconWrapper = styled.span`
  margin-right: 3px;
  margin-top: 3px;
`

export const InlineButton = styled.button`
  text-decoration: underline;
  border: none;
  background-color: transparent;
`
