import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  display: flex;
  border-radius: 12px;
  background-color: #3d6b58;

  padding: 20px 29px;
  border: 1px solid #e9eaf0;
  width: 100%;
  ${({ size }) => (size === 'SMALL' ? 'min-height: 368px;' : 'height: 204px;')}
`

export const Bur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
  z-index: 10;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    margin-bottom: 40px;
    text-align: center;
    width: 80%;
    color: white;
    line-height: 26px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ size }) => (size === 'SMALL' ? '100%' : '533px')};
  height: 100%;
  z-index: 2;

  & > div:not(:last-child) {
    margin-bottom: 17px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TooltipIconContainer = styled.div`
  min-width: 28px;
  height: 28px;
  cursor: pointer;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > p:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`

export const Description = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  max-width: 289px;
`
export const InlineButton = styled.a`
  margin-top: 20px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
`

export const CountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
`

export const ButtonShop = styled.a`
  margin-top: 40px;
  border-radius: 8px;
  border: none;
  color: #65b293;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration: none;

  padding: 11.5px 10px;
  width: 250px;
`

export const Image1 = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
`

export const Image2 = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
`

export const Image3 = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
`
export const DiscountWrapper = styled.div`
  color: white;
  font-size: 45px;
  position: absolute;
  z-index: 1;
  bottom: 160px;
  right: 100px;
`

export const Button = styled.button`
  border-radius: 8px;
  border: none;
  background-color: #3f9066;
  cursor: pointer;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  padding: 12px 10px;
  width: 220px;
  margin-top: 20px;
  font-size: 13px;
  transition: all 0.2s;

  &:hover {
    background-color: #449c6f;
  }
`
