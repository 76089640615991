import { Helmet } from 'react-helmet'

import { useHistory } from 'react-router'
import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import { Container, Content } from './style'
import { Button } from '../../components/ui'
import ProtocolsContainer from '../../components/protocoles/container'
import { HeaderLinks } from '../../components/section/header/props'

const ProtocolesPages = () => {
  // Attributes
  const history = useHistory()
  // Return
  return (
    <Layout active={HeaderLinks.Protocoles}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Protocoles</title>
      </Helmet>
      <Container>
        <PageTitle
          title='Mes protocoles'
          subtitle='Les protocoles sont des recommandations de produits pré-enregistrées que vous pouvez réutiliser à tout moment. Vous pouvez également modifier le protocole avant d’envoyer la recommandation à votre patient.'
          rightElements={
            <Button
              onClick={() =>
                history.push(
                  '/marketplace-protocoles/new?step=1&type=marketplace'
                )
              }
              appearance='primary'
              iconLeft={undefined}
              isDisabled={undefined}
              isLoading={undefined}
              isActive={undefined}
            >
              Nouveau protocole
            </Button>
          }
        />
        <Content>
          <ProtocolsContainer />
        </Content>
      </Container>
    </Layout>
  )
}

export default ProtocolesPages
