import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  margin-top: 64px;
  padding: 0 78px;

  & > div:not(:last-child) {
    margin-right: 43px;
  }
`

export const GlobalRewardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 372px;
  overflow: auto;

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const SeeButton = styled.button`
  cursor: pointer;
  border: none;
  text-decoration: underline;
  background-color: transparent;
  text-align: center;
`
