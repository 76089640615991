import { useState, useEffect } from 'react'
import { Dialog } from '../../ui'
import Step1 from '../step-1/Step1'
import axios from 'axios'

const ModalEl = ({
  prescriberName,
  prescriptionFirstname,
  prescriberLastName,
  cart,
  isTakesMoreThanThreeProducts,
  isMarketPlace,
  patientEmail,
  checkoutUrl,
}) => {
  // Attributes
  const [open, setOpen] = useState<boolean>(false)
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1)

  // Functions
  function getCookie(name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  // Hooks
  useEffect(() => {
    // Set cookie
    setTimeout(() => {
      let expires = 'max-age=' + 30 * 24 * 60 * 60
      document.cookie = 'onboarding=true; ' + expires + ';'
    }, 3000)
  }, [])

  useEffect(() => {
    let onboarding = getCookie('onboarding')
    setOpen(onboarding ? false : true)
  }, [])

  useEffect(() => {
    const sendPrescription = async () => {
      if (open === false && patientEmail) {
        try {
          await axios.post(
            'https://hook.eu1.make.com/qcrjqswj6chdi67a2kjtrsz7kter75by',
            { patientEmail: patientEmail, prescriptionLink: checkoutUrl }
          )
        } catch (error) {
          console.error('Error while sending prescription:', error)
          // Handle error if needed
        }
      }
    }

    sendPrescription()
  }, [open])

  return (
    <Dialog
      isOpen={open}
      onClose={async () => {
        setOpen(false)
      }}
      type='defaultCenter'
      styleContainer={undefined}
    >
      <Step1
        prescriberName={prescriberName}
        prescriptionFirstname={prescriptionFirstname}
        prescriberLastName={prescriberLastName}
        setStep={setStep}
        setOpen={setOpen}
        isMarketPlace={isMarketPlace}
      />
    </Dialog>
  )
}

export default ModalEl
