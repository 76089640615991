import cloneDeep from 'lodash.clonedeep'

import Constants from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

const editPrescriberFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.edit = { status: ActionType.FAILED, error }

  return newState
}
const editPrescriberLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.edit = { status: ActionType.LOADING }

  return newState
}
const editPrescriberSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.actions.edit = { status: ActionType.SUCCESS }

  return newState
}
const editPrescriberReset = state => {
  const newState = cloneDeep(state)
  newState.actions.edit = { status: ActionType.RESET }

  return newState
}

const addDiscountFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.add_discounts = { status: ActionType.FAILED, error }

  return newState
}
const addDiscountLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.add_discounts = { status: ActionType.LOADING }

  return newState
}
const addDiscountSuccess = state => {
  const newState = cloneDeep(state)
  newState.actions.add_discounts = { status: ActionType.SUCCESS }

  return newState
}

const addCalendlyCodeFailed = (state, { error }) => {
  const newState = cloneDeep(state)
  newState.actions.calendly_code = { status: ActionType.FAILED, error }

  return newState
}
const addCalendlyCodeLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.calendly_code = { status: ActionType.LOADING }

  return newState
}
const addCalendlyCodeSuccess = state => {
  const newState = cloneDeep(state)
  newState.actions.calendly_code = { status: ActionType.SUCCESS }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.EDIT_PRESCRIBER_FAILED:
      return editPrescriberFailed(state, action)
    case Constants.EDIT_PRESCRIBER_LOADING:
      return editPrescriberLoading(state)
    case Constants.EDIT_PRESCRIBER_SUCCESS:
      return editPrescriberSuccess(state, action)
    case Constants.EDIT_PRESCRIBER_RESET:
      return editPrescriberReset(state)

    case Constants.ADD_DISCOUNT_CODES_FAILED:
      return addDiscountFailed(state, action)
    case Constants.ADD_DISCOUNT_CODES_LOADING:
      return addDiscountLoading(state, action)
    case Constants.ADD_DISCOUNT_CODES_SUCCESS:
      return addDiscountSuccess(state, action)

    case Constants.ADD_CALENDLY_CODE_FAILED:
      return addCalendlyCodeFailed(state, action)
    case Constants.ADD_CALENDLY_CODE_LOADING:
      return addCalendlyCodeLoading(state, action)
    case Constants.ADD_CALENDLY_CODE_SUCCESS:
      return addCalendlyCodeSuccess(state, action)

    default:
      return state
  }
}
