import styled from 'styled-components/macro'

import { Color, MediaQuery } from '../../../../utils/constants'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  background-color: #fdfcfe;
`
export const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100%;
  background-color: ${props => (props.blur ? '#f9f8fb90' : 'none')};
  display: ${props => (props.blur ? 'block' : 'none')};
  z-index: 1;
`

export const Content = styled.div`
  max-width: 998px;
  width: 95%;
  padding: 20px 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  ${MediaQuery.M_AND_DOWN} {
    padding: 10px 0px;
  }
`

export const HeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  ${MediaQuery.M_AND_DOWN} {
    align-items: start;
    flex-direction: column;
    padding-bottom: 20px;
  }
`

export const MainTitle = styled.h1`
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 0px;
  color: ${Color.MINE_SHAFT};
  ${MediaQuery.M_AND_DOWN} {
    font-size: 18px;
  }
  b {
    font-weight: 900;
  }
`

export const SecondaryTitle = styled.h3`
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 15px;
  color: ${Color.MINE_SHAFT};
  ${MediaQuery.M_AND_DOWN} {
    margin-top: 10px;
  }
`

export const BodySection = styled.section`
  display: flex;
  width: 100%;
  padding: 20px 0;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`

export const FooterBar = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 20px;
  border-top: 1px solid #ddd;
  background-color: #fff;
  ${MediaQuery.M_AND_DOWN} {
    padding: 10px 20px;
  }
  ${MediaQuery.M_AND_UP} {
    display: none;
  }
`
export const ShippingProgressBarWrapper = styled.div`
  ${MediaQuery.M_AND_UP} {
    display: none;
  }
`

export const FooterLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const FooterIcon = styled.span`
  font-size: 26px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
  }
`

export const P = styled.p`
  font-size: 16px;
  white-space: pre-wrap;
`

export const Note = styled(P)`
  margin-top: 10px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  max-width: 500px;
  font-weight: 400;
  font-size: 14px;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const Right = styled(Left)`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40%;
  max-width: 40%;
  ${MediaQuery.M_AND_DOWN} {
    margin-left: 0;
    min-width: 100%;
    max-width: 100%;
    margin-top: 30px;
  }
`

export const Img = styled.img`
  width: 100%;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
  max-width: 380px;
  ${MediaQuery.M_AND_DOWN} {
    max-height: 200px;
  }
  ${MediaQuery.S_AND_DOWN} {
    min-width: 100%;
  }
`

export const TransportInfo = styled.div`
  display: flex;
  padding: 30px 0;
  align-items: center;
  > ${P} {
    font-size: 12px;
    max-width: 250px;
    color: ${Color.DEEP_SEA};
  }
`

export const IconWrapper = styled.div`
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 50%;
  background-color: ${Color.MONTE_CARLO}55;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  > div {
    width: 40px;
    height: 40px;
    stroke: ${Color.DEEP_SEA};
  }
`

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 40px;
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;
  width: 100%;
  ${MediaQuery.M_AND_DOWN} {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
  }
`

export const Li = styled.li`
  position: relative;
  font-size: 14px;
  width: 80%;
  max-width: 250px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
  > div {
    position: absolute;
    top: 50%;
    right: calc(100% + 15px);
    transform: translateY(-50%);
    fill: ${Color.DEEP_SEA};
  }
`

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TotalPrice = styled.p`
  > b {
    font-size: 26px;
    ${MediaQuery.M_AND_DOWN} {
      font-size: 20px;
    }
  }
  > small {
    font-size: 12px;
    opacity: 0.8;
    ${MediaQuery.M_AND_DOWN} {
      font-size: 10px;
    }
  }
`

export const Discount = styled.p`
  font-size: 12px;
  max-width: 180px;
  margin: 0 30px;
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }
  > b {
    font-weight: 900;
  }
  > strong {
    color: ${Color.DEEP_SEA};
  }
`

export const PaymentMethods = styled.div`
  display: flex;
`

export const PaymentImage = styled.img`
  width: 55px;
  height: 30px;
  object-fit: contain;
`

export const RowText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const TextLeft = styled(P)`
  font-size: 14px;
  border-bottom: 1px dotted #ddd;
  flex: 1;
`

export const TextRight = styled(P)`
  border-bottom: 1px dotted #ddd;
`

export const SelectionWrapper = styled.div`
  margin-top: 20px;
  background-color: #d6f1e7;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 300;
  font-size: 13px;
`

export const Selection = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid ${Color.DEEP_SEA};
  border-radius: 100px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 100px;
    background-color: ${Color.DEEP_SEA};
  }
`

export const Select = styled.select`
  font-size: 25px;
  border: none;
  outline: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 10px;
  padding: 3px 10px;
  padding-right: 30px;
`

export const OrderSummary = styled.div`
  padding: 10px 20px;
  /* box-shadow: 0px 0px 6px rgba(65, 91, 115, 0.25); */
  border-radius: 15px;
`

export const InfoCard = styled.div`
  margin-top: 30px;
  padding: 20px 20px;
  /* box-shadow: 0px 0px 6px rgba(65, 91, 115, 0.25); */
  border-radius: 15px;
`

export const InfoCardContainer = styled.div`
  display: flex;
  img {
    width: 50%;
    height: 400px;
  }
  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
  }
`

export const InfoCardTitle = styled.h3`
  font-size: 25px;
  margin-bottom: 25px;
`

export const LineButton = styled.span`
  font-size: 13px;
  cursor: pointer;
  font-weight: 300;
  border-bottom: 1px solid ${Color.DEEP_SEA};
  color: ${Color.DEEP_SEA};
  text-decoration: none;
  white-space: nowrap;
  span {
    margin-right: 5px;
    font-size: 10px;
  }
`

export const TitleWrapperFlex = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${MediaQuery.M_AND_DOWN} {
    align-items: start;
    flex-direction: column;
    margin-bottom: 20px;
  }
`

export const DialogPaymentClose = styled.span`
  position: absolute;
  top: 10px;
  right: 23px;
  z-index: 10000;
  font-size: 30px;
`

export const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  min-width: 100vw;
`

export const DialogContainer = styled.div`
  padding: 20px;
`

export const FilesWrapper = styled.div`
  margin-top: 20px;
  display: flex;
`

export const FilesWrapperIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${Color.DEEP_SEA};
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const FilesWrapperContent = styled.div``

export const FilesWrapperTitle = styled.h4`
  font-size: 10px;
`

export const FileList = styled.div`
  display: flex;
  margin-top: 5px;
`

export const File = styled.a`
  cursor: pointer;
  border: 1px solid #eee;
  display: inline-block;
  margin-right: 5px;
  font-size: 10px;
  width: auto;
  height: auto;
  padding: 3px;
`

export const ReOrder = styled.div`
  margin-top: 15px;
  background-color: #e0f0e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  padding: 15px;
  border-radius: 4px;
  z-index: 2;
  position: relative;
  border: 1px solid #00855a20;
  p {
    color: ${Color.DEEP_SEA};
    font-size: 20px;
    margin-bottom: 20px;
  }
  div {
    display: flex;
  }
`

export const ReOrderButton = styled.button`
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: none;
  background-color: ${Color.DEEP_SEA};
  color: white;
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  font-size: 11px;
`

export const PrescriberAdviceButton = styled.button`
  margin-top: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;

  p {
    color: inherit;
    font-size: 12px;
    text-decoration: underline;
  }

  span {
    margin-bottom: 14px;
    transition: all 0.2s;
  }

  &:hover span {
    margin-left: 2px;
  }
`

export const Commission = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #ff9138;
  font-size: 12px;
  font-weight: 300;
  span {
    margin-right: 10px;
    background-color: #ffe9d7;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
`

export const PricingLine = styled.div`
  font-weight: 300;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`
export const TotalPricingLine = styled.div`
  font-weight: 300;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  background-color: #e7e9f4;
  padding: 10px 20px;
  border-radius: 5px;
`
export const Highlight = styled.span`
  background-color: ${props => (props.bg ? props.bg : '#e0f0e9')};
  color: ${props => (props.bg ? 'inherit' : Color.DEEP_SEA)};
  padding: 3px 6px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 10px;
`

export const NoteWrapper = styled.div`
  margin-top: 20px;
`

export const NoteWrapperHeader = styled.h3`
  font-size: 16px;
  margin-bottom: 10px;
`

export const NoteTitle = styled.h4`
  font-size: 17px;
  margin-bottom: 10px;
`
