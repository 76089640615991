import styled from 'styled-components/macro'

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #080038;
`

export const FlexLeftColumn = styled.div`
  width: 327px;
  display: flex;
  flex-direction: column;
`

export const CheckboxList = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 150px);
`

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
`

export const Checkbox = styled.input`
  margin-right: 10px;

  &[type='checkbox'] {
    appearance: none;
    background-color: #fff;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 1px solid #3a845e;
    border-radius: 3px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  &[type='checkbox']::before {
    content: '';
    width: 16px;
    height: 16px;
    transform: scale(0);
    border-radius: 3px;
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: #3a845e;
  }
  &[type='checkbox']:checked::before {
    transform: scale(1);
  }
`

export const TextButton = styled.div`
  margin-top: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #9c99af;
  line-height: 12px;
  display: flex;
  cursor: pointer;
  text-decoration: underline;
`
