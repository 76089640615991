import { useState } from 'react'
import { Dropdown, Drawer } from './style'
import { IDropdownElProps } from './props'

const DropdownEl = ({
  children,
  drawerStyle,
  content,
  ...props
}: IDropdownElProps) => {
  //Attributes
  const [isOpen, setIsOpen] = useState(false)

  const handleKeyPress = ({ key }) => {
    if (key !== 'Enter') return
    setIsOpen(!isOpen)
  }
  //Return
  return (
    <Dropdown
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      tabIndex={0}
      onKeyPress={handleKeyPress}
      {...props}
    >
      {children}
      <Drawer isOpen={isOpen} style={drawerStyle}>
        {content}
      </Drawer>
    </Dropdown>
  )
}

export default DropdownEl
