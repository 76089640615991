import {
  Footer,
  PriceContainer,
  InactiveText,
  ButtonGroup,
  Button,
} from '../style'
import { FooterProps } from './props'

const FooterC = ({ product, onSignUp, mode }: FooterProps) => {
  return (
    <>
      {!product?.active ||
        (onSignUp && (
          <Footer $mode={mode}>
            <PriceContainer $mode={mode}>
              {!product?.active ? (
                <InactiveText>
                  <strong style={{ fontWeight: 500 }}>Inactif</strong>
                </InactiveText>
              ) : (
                <div></div>
              )}
            </PriceContainer>

            <ButtonGroup>
              {onSignUp && (
                <Button
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    onSignUp()
                  }}
                >
                  Utiliser
                </Button>
              )}
            </ButtonGroup>
          </Footer>
        ))}
    </>
  )
}

export default FooterC
