import { useState } from 'react'
import { EmailItemProps } from './props'
import { useGetMe } from '../../../../../utils/hooks/getMe'
import { useGetPatients } from '../../../../../services/api/patients/patients'
import CreateNewPatient from '../create-new-patient'
import { IoIosClose } from 'react-icons/io'
import { CiMail, CiPhone } from 'react-icons/ci'
import {
  PatientItem,
  Inital,
  CloseButton,
  Wrapper,
  AddPhoneNumber,
  Email,
  Edit,
  InfoCircle,
  PhoneWrapper,
  PhoneIcon,
} from './style'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { CiEdit } from 'react-icons/ci'
import { Tooltip } from '../../../../ui'

const EmailItem = ({
  deleteEmail,
  index,
  email,
  setIsInnerDialogOpen,
  isChecked,
}: EmailItemProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { data: prescriber } = useGetMe()

  const { data: patients } = useGetPatients(
    prescriber?.id,
    {
      q: email,
      size: 1000,
    },
    {
      query: {
        enabled: !!prescriber?.id,
      },
    }
  )

  const patient = patients?.data?.[0]

  return (
    <Wrapper>
      <PatientItem key={index}>
        <Email>
          {patient && patient?.firstName && (
            <Inital>
              {patient?.firstName[0]} {patient?.lastName[0]}
            </Inital>
          )}
          {patient?.firstName} {patient?.lastName}
        </Email>
        <Email
          style={{ marginBottom: '10px' }}
          onClick={() => setIsDialogOpen(true)}
        >
          <span>
            <CiMail />
          </span>
          {email}
        </Email>

        <PhoneWrapper onClick={() => setIsDialogOpen(true)}>
          <PhoneIcon>
            <CiPhone />
          </PhoneIcon>
          {patient?.phone ? (
            patient?.phone
          ) : (
            <AddPhoneNumber>
              Ajouter un numero téléphone
              <Tooltip
                size='l'
                label='En ajoutant le numéro de téléphone de votre client, nous enverrons la recommandation également par SMS afin de maximiser les taux d’ouvertures.'
              >
                <InfoCircle>
                  <AiOutlineInfoCircle />
                </InfoCircle>
              </Tooltip>
            </AddPhoneNumber>
          )}
        </PhoneWrapper>

        <CloseButton onClick={() => deleteEmail(index)}>
          <IoIosClose />
        </CloseButton>

        <Edit onClick={() => setIsDialogOpen(true)}>
          <span>
            <CiEdit />
          </span>
          <p>Modifier</p>
        </Edit>
      </PatientItem>

      <CreateNewPatient
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        setIsInnerDialogOpen={setIsInnerDialogOpen}
        patient={patient}
      />
    </Wrapper>
  )
}

export default EmailItem
