import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  max-height: 75px;
  min-height: 75px;
  position: sticky;
  top: 0;
  padding: 0 20px;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
`

export const Nav = styled.nav`
  display: flex;
  height: 100%;
  align-items: center;
`

export const Ul = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  align-items: center;

  > a,
  span {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    color: black;
    text-decoration: none;
    padding: 0px 10px;
    border-radius: 60px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 60px;
  height: 38px;
  > a,
  span {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    color: black;
    text-decoration: none;
    padding: 0px 10px;
    border-radius: 60px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  ${MediaQuery.M_AND_DOWN} {
    display: none;
  }

  ${({ active }) =>
    active === 'true'
      ? 'background-color: rgba(121, 97, 189, 0.13);'
      : ''}/* &:hover {
    background-color: rgba(121, 97, 189, 0.13);
  } */
`

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;

  > a,
  span {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    color: black;
    text-decoration: none;
    padding: 0px 10px;
    border-radius: 60px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
`

export const Academy = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;

  p {
    color: green;
    font-size: 8.5px;
    position: absolute;
    top: -13px;
    right: -13px;
    white-space: nowrap;
  }
`

export const DropDownContent = styled.div`
  a {
    text-decoration: none;
    color: inherit;
  }
`
export const DropDownContentItem = styled.div`
  color: #393360;
  font-size: 14px;
  cursor: pointer;
  padding: 12px 8px 12px 12px;
  border-radius: 4px;
  &:hover {
    background: #f2f4f9;
  }
`
