import { useState } from 'react'
import { Wrapper, BigWrapper, ButtonCta, ButtonWrapper } from './style'
import { useGetMe } from '../../../utils/hooks/getMe'

import SponsorFormDialog from '../../sponsor/form-dialog'
import ShareAccessDialog from '../../share-access-dialog'
import {
  LinkWrapper,
  PatientLink,
  IconWrapper,
} from '../../share-access-dialog/style'
import { Tooltip, Button } from '../../../components/ui'
import { FaRegCopy, FaCheck } from 'react-icons/fa'

const CtaCatalogPatient = ({ isStepValided, isStepLoaded }) => {
  // Attributes
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const [isLinkCopied2, setIsLinkCopied2] = useState(false)
  const [isFormOpened, setIsFormOpened] = useState<boolean>(false)
  const [isShareAccessDialogOpened, setIsShareAccessDialogOpened] =
    useState<boolean>(false)
  const { data: prescriber } = useGetMe()

  const textToCopy = `https://app.simplycure.com/patient-catalog?discountCode=${prescriber?.activeDiscount?.code}`
  const textToCopy2 = `https://share-eu1.hsforms.com/171v4nGKvSJOgAqAxW8pr9gf2dsm?notes_a_l_import=${prescriber?.email}`

  // Functions
  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(textToCopy)
    setIsLinkCopied(true)
    setTimeout(() => setIsLinkCopied(false), 3000)
  }

  const handleCopyToClipBoard2 = () => {
    navigator.clipboard.writeText(textToCopy2)
    setIsLinkCopied2(true)
    setTimeout(() => setIsLinkCopied2(false), 3000)
  }

  return (
    <BigWrapper isStepValided={isStepValided}>
      <Wrapper isStepValided={isStepValided} style={{ minHeight: '49%' }}>
        <h3>
          Vos patients peuvent accéder à votre catalogue et votre réduction en
          se rendant sur :{' '}
        </h3>

        <LinkWrapper>
          <PatientLink>{textToCopy}</PatientLink>
          <Tooltip
            size='l'
            label={isLinkCopied ? `Copié !` : 'Copier dans le presse papier'}
          >
            <Button
              appearance='minimal'
              onClick={handleCopyToClipBoard}
              iconLeft={undefined}
              isDisabled={undefined}
              isLoading={undefined}
              isActive={undefined}
              style={{
                marginBottom: '10px',
                marginLeft: '4px',
                width: '80px',
              }}
            >
              <IconWrapper>
                {' '}
                {isLinkCopied ? <FaCheck /> : <FaRegCopy />}
              </IconWrapper>
              copier
            </Button>
          </Tooltip>
        </LinkWrapper>

        <ButtonCta onClick={() => setIsShareAccessDialogOpened(true)}>
          Partager
        </ButtonCta>
      </Wrapper>

      <Wrapper isStepValided={isStepValided}>
        <h3>Parrainez un collègue et gagner 50€</h3>
        <p>
          Invitez un collègue à rejoindre Simplycure et recevez tous les deux
          50€ de bon d’achat.
        </p>
        <ButtonWrapper>
          <ButtonCta onClick={() => setIsFormOpened(true)}>Inviter</ButtonCta>

          <Tooltip
            size='l'
            label={isLinkCopied2 ? `Copié !` : 'Copier dans le presse papier'}
          >
            <ButtonCta onClick={() => handleCopyToClipBoard2()}>
              Partager votre lien
            </ButtonCta>
          </Tooltip>
        </ButtonWrapper>
      </Wrapper>

      {isFormOpened && (
        <SponsorFormDialog
          isOpen={isFormOpened}
          onClose={() => setIsFormOpened(false)}
          formId={'20778582-295b-40a6-a384-467ff00bf442'}
          prescriber={prescriber}
          succeesMessageTitle='Merci ! Votre parrainage a bien été enregistré.'
          succeesMessageDescription="Nous reviendrons vers vous après avoir contacté votre parrainé. S'il s'inscrit à Simplycure, vous recevrez un bon d'achat Simplycure de 50€."
          prefilledInfo='referral_email_1'
        />
      )}

      {isShareAccessDialogOpened && (
        <ShareAccessDialog
          isOpen={isShareAccessDialogOpened}
          onClose={() => setIsShareAccessDialogOpened(false)}
        />
      )}
    </BigWrapper>
  )
}

export default CtaCatalogPatient
