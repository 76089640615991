import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import { Wrapper, Title, Content } from './style'
import NakedLayout from '../../components/layout/naked'
import ResetPasswordForm from '../../components/auth/reset-password-form'
import { getRegisterStatus } from '../../store/auth-user'
import { ActionType } from '../../utils/constants'

const ResetPassordPage = ({ mode, oobCode, history }) => {
  const resetPasswordStatus = useSelector(getRegisterStatus)

  useEffect(() => {
    if (mode !== 'resetPassword' || !oobCode) {
      history.push('/')
    }
  })

  return (
    <NakedLayout>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Mot de passe oublié </title>
      </Helmet>
      <Content>
        <Wrapper>
          {resetPasswordStatus !== ActionType.SUCCESS && (
            <div>
              <Title>Réinitialiser son mot de passe</Title>
              <ResetPasswordForm oobCode={oobCode} history={history} />
            </div>
          )}
        </Wrapper>
      </Content>
    </NakedLayout>
  )
}

export default ResetPassordPage
