import { useState } from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../../components/layout/default'
import { Container, Wrapper } from './style'
import { HeaderLinks } from '../../components/section/header/props'
import NotesTable from '../../components/notes/table'
import NotesTableHeader from '../../components/notes/table/header'

const NotesPage = () => {
  // Attributes
  const [search, setSearch] = useState('')

  // Render
  return (
    <Layout active={HeaderLinks.Notes}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Notes </title>
      </Helmet>
      <Container>
        <Wrapper>
          <NotesTableHeader search={search} setSearch={setSearch} />
          <NotesTable search={search} />
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default NotesPage
