import { Logo } from '../../ui'
import { Button } from '../../ui'
import {
  GlobalContainer,
  Main,
  Header,
  Buttons,
  TelWrapper,
  TelIconWrapper,
  LinkTelWrapper,
  NavItem,
  RightWrapper,
  NavWrapper,
  MobileButtons,
} from './style'
import { BsTelephone } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { RecoProps } from './props'

const DefaultLayout = ({
  children,
  nav = [],
  setIsOpenDialogMoreProducts,
  from,
}: RecoProps) => {
  // Functions
  const handleResetCure = () => {
    localStorage.removeItem('cartId')
    localStorage.removeItem('sessionId')
    window.location.reload()
  }

  const handleOpenDialogMoreProducts = () => {
    setIsOpenDialogMoreProducts(true)
  }

  return (
    <GlobalContainer>
      <Header>
        <RightWrapper>
          {from === 'recommendation' ? (
            <Logo />
          ) : (
            <Link to={'/patient-catalog'}>
              <Logo />
            </Link>
          )}

          <NavWrapper>
            {nav.map(i => (
              <Link to={i.url}>
                <NavItem>{i.text}</NavItem>
              </Link>
            ))}
          </NavWrapper>
        </RightWrapper>
        <MobileButtons>
          <Button
            appearance='primary'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
            style={{ fontSize: '12px', padding: '5px 12px' }}
            onClick={handleOpenDialogMoreProducts}
          >
            Ajouter des produits
          </Button>
        </MobileButtons>
        <Buttons>
          <TelWrapper>
            <TelIconWrapper>
              <BsTelephone />
            </TelIconWrapper>
            <LinkTelWrapper>
              <a href='tel:+33745892969 '>Tel: 🇫🇷 +33 7 45 89 29 69 </a>
              <a href='tel:+3228087914'>Tel: 🇧🇪 +32 2 808 79 14</a>
            </LinkTelWrapper>
          </TelWrapper>
          <Button
            appearance='primary'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
            style={{ marginRight: '15px' }}
            onClick={handleOpenDialogMoreProducts}
          >
            Ajouter des produits
          </Button>
          <Button
            appearance='minimal'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
            onClick={handleResetCure}
          >
            Réinitialiser la cure
          </Button>
        </Buttons>
      </Header>
      <Main>{children}</Main>
    </GlobalContainer>
  )
}

export default DefaultLayout
