import styled from 'styled-components/macro'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: white;
  padding-left: 1.25rem;
  padding-top: 1.5rem;
  justify-content: start;
  padding-bottom: 1.5rem;
  padding-right: 1.25rem;
`

export const Title = styled.h1`
  color: #080038;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const SubTitle = styled.h4`
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
  color: #393360;
  max-width: 19.4375rem;
  height: 4.6875rem;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Image = styled.img`
  width: 19.4375rem;
  border-radius: 0.5rem;
  object-fit: contain;
  object-position: center center;
  margin-bottom: 1.25rem;
`
