import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  & > *:not(:last-child) {
    margin-right: 40px;
  }
`

export const Container = styled.div`
  min-width: 778px;
  max-width: 778px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #e9eaf0;
  background: #ffffff;
  position: relative;
`
