import styled from 'styled-components/macro'

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const Container = styled.div`
  background: white;
  box-shadow: 0px 4px 40px rgba(234, 235, 249, 0.6);
  border-radius: 16px;
`

export const Content = styled(Container)``
