import {
  Container,
  Question,
  QuestionContainer,
  QuestionsContainer,
  Response,
  SectionContainer,
  Title,
  TitleContainer,
} from './style'
import { QuizCategoryBodyListProps } from './props'

const QuizCategoryBodyList = ({
  category,
  color,
}: QuizCategoryBodyListProps) => {
  // Render
  return (
    <Container>
      {category?.sections?.map(section => (
        <SectionContainer key={section.id}>
          <TitleContainer color={color}>
            <Title>{section.title}</Title>
          </TitleContainer>
          <QuestionsContainer>
            {section?.questions?.map((question, i) => (
              <QuestionContainer key={`sections-${section.id}-questions-${i}`}>
                <Question>{question.title}</Question>
                <Response>{question.response}</Response>
              </QuestionContainer>
            ))}
          </QuestionsContainer>
        </SectionContainer>
      ))}
    </Container>
  )
}

export default QuizCategoryBodyList
