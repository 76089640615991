import styled from 'styled-components/macro'

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  left: -50px;
  top: 3px;
`

export const CloseButton = styled.div`
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  padding: 32px 28px;

  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 25.7561px;
  line-height: 31px;
  color: #080038;
`

export const Image = styled.img`
  border-radius: 12px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Section = styled.div`
  display: flex;
  align-items: center;

  span {
    color: red;
    margin-right: 10px;
    font-size: 20px;
  }

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #080038;
  opacity: 0.7;
`
export const Ul = styled.ul`
  margin-left: 20px;
`

export const SectionDescription = styled.li`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #080038;
  margin-bottom: 10px;
`

export const Button = styled.button`
  border-radius: 8px;
  border: none;
  background-color: #3a845e;
  cursor: pointer;
  font-weight: 500;
  font-size: 15.0244px;
  line-height: 18px;
  color: #ffffff;
  padding: 15px 10px;

  &:hover {
    background-color: #3a845e;
  }
`
