import { useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { getFetchAuthPrescriberStatus } from '../../../store/auth-user'
import LoginSchema from '../../../components/auth/patient-login-form/formShema'
import { useAuth } from '../../../services/auth/provider'
import { ActionType, ErrorCodes } from '../../../utils/constants'
import { toast } from 'react-toastify'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { Role } from '../../../services/api/types'
import { UseSignInUserLogicReturn } from './types'

function useSignInUserLogic(): UseSignInUserLogicReturn {
  //Attributes
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const fetchAuthPrescriberStatus = useSelector(getFetchAuthPrescriberStatus)
  const history = useHistory()
  const { login } = useAuth()

  const isAllLoading =
    isLoginLoading || fetchAuthPrescriberStatus === ActionType.LOADING

  //Functions
  const resetPassword = async (email: string): Promise<void> => {
    if (!email) {
      toast.error(
        'Un email doit être renseigné afin de demander une réinitialisation de mot de passe'
      )
      return
    }
    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
      toast.success(
        'Un email pour réinitialiser votre mot de passe vous a été envoyé.'
      )
    } catch {
      toast.error(
        "Une erreur s'est produite, veuillez vérifier l'adresse email renseignée"
      )
    }
  }

  const submitForm = values => {
    setIsLoginLoading(true)
    login(values.email, values.password, Role.PATIENT)
      .then(() => {
        history.replace('/patient/recommendations')
      })
      .catch(e => {
        if (e.message === ErrorCodes.EMAIL_NOT_VERIFIED) {
          history.replace('/patient/register/confirm')
        } else {
          toast.error("L'email ou le mot de passe est incorrect")
        }
      })
      .finally(() => setIsLoginLoading(false))
  }

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: LoginSchema,
      onSubmit: submitForm,
    })

  //Return
  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isAllLoading,
    resetPassword,
  }
}

export default useSignInUserLogic
