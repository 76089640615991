import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import { useGetUserBySub } from '../services/api/users/users'
import { Role } from '../services/api/types'

const UnauthenticatedRouteOnly = ({ component: Component, ...rest }) => {
  //Attributes
  const userRole = localStorage.getItem('role')
  const { data: prescriber } = useGetUserBySub(
    { role: localStorage.getItem('role') },
    { query: { enabled: !!userRole, retry: 1 } }
  )
  const renderRoute = props => <Component {...props} />
  const renderToDashboard = props => (
    <Redirect
      to={{
        pathname:
          localStorage.getItem('role') === Role.PATIENT
            ? '/patient/recommendations'
            : '/',
        state: { from: props.location },
      }}
    />
  )

  return (
    <Route
      {...rest}
      render={props =>
        prescriber ? renderToDashboard(props) : renderRoute(props)
      }
    />
  )
}

export default UnauthenticatedRouteOnly
