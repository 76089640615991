/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type {
  PrescriptionsPagingDTO,
  GetPrescriptionsByPrescriberIdParams,
  PrescriptionDTO,
  CreatePrescriptionDTO,
  PushPrescriptionStatusDTO,
  UpdatePrescriptionDTO,
  PublicPrescriptionDTO,
  PrescriptionStatusDTO,
  SendPrescriptionDTO,
  SendPrescriptionReminderDTO,
  PrescriptionFileDTO,
  SavePrescriptionFilesDTO,
  PrescriptionAmountsDTO,
  CalculPrescriptionAmountsDTO,
  PatchPrescriptionStatusVisitedAtParams,
  SimplifiedPrescriptionsPagingDTO,
  GetAllPrescriptionsByPatientParams,
  SimplifiedPrescriptionDTO,
} from '.././types'
import { customInstance } from '.././config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const getPrescriptionsByPrescriberId = (
  prescriberId: number,
  params?: GetPrescriptionsByPrescriberIdParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionsPagingDTO>(
    {
      url: `/prescribers/${prescriberId}/prescriptions`,
      method: 'get',
      params,
    },
    options
  )
}

export const getGetPrescriptionsByPrescriberIdQueryKey = (
  prescriberId: number,
  params?: GetPrescriptionsByPrescriberIdParams
) => [`/prescribers/${prescriberId}/prescriptions`, ...(params ? [params] : [])]

export const useGetPrescriptionsByPrescriberId = <
  TData = AsyncReturnType<typeof getPrescriptionsByPrescriberId>,
  TError = unknown
>(
  prescriberId: number,
  params?: GetPrescriptionsByPrescriberIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriptionsByPrescriberId>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrescriptionsByPrescriberIdQueryKey(prescriberId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPrescriptionsByPrescriberId>
  > = () => getPrescriptionsByPrescriberId(prescriberId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriptionsByPrescriberId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriberId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const createPrescription = (
  prescriberId: number,
  createPrescriptionDTO: CreatePrescriptionDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionDTO>(
    {
      url: `/prescribers/${prescriberId}/prescriptions`,
      method: 'post',
      data: createPrescriptionDTO,
    },
    options
  )
}

export const useCreatePrescription = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createPrescription>,
    TError,
    { prescriberId: number; data: CreatePrescriptionDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createPrescription>,
    { prescriberId: number; data: CreatePrescriptionDTO }
  > = props => {
    const { prescriberId, data } = props || {}

    return createPrescription(prescriberId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof createPrescription>,
    TError,
    { prescriberId: number; data: CreatePrescriptionDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const getPrescriptionById = (
  prescriberId: number,
  prescriptionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionDTO>(
    {
      url: `/prescribers/${prescriberId}/prescriptions/${prescriptionId}`,
      method: 'get',
    },
    options
  )
}

export const getGetPrescriptionByIdQueryKey = (
  prescriberId: number,
  prescriptionId: number
) => [`/prescribers/${prescriberId}/prescriptions/${prescriptionId}`]

export const useGetPrescriptionById = <
  TData = AsyncReturnType<typeof getPrescriptionById>,
  TError = unknown
>(
  prescriberId: number,
  prescriptionId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriptionById>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrescriptionByIdQueryKey(prescriberId, prescriptionId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getPrescriptionById>> =
    () => getPrescriptionById(prescriberId, prescriptionId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriptionById>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(prescriberId && prescriptionId),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}

export const deletePrescription = (
  prescriberId: number,
  prescriptionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/prescribers/${prescriberId}/prescriptions/${prescriptionId}`,
      method: 'delete',
    },
    options
  )
}

export const useDeletePrescription = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deletePrescription>,
    TError,
    { prescriberId: number; prescriptionId: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deletePrescription>,
    { prescriberId: number; prescriptionId: number }
  > = props => {
    const { prescriberId, prescriptionId } = props || {}

    return deletePrescription(prescriberId, prescriptionId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof deletePrescription>,
    TError,
    { prescriberId: number; prescriptionId: number },
    TContext
  >(mutationFn, mutationOptions)
}
export const pushPrescriptionStatus = (
  prescriberId: number,
  prescriptionId: number,
  pushPrescriptionStatusDTO: PushPrescriptionStatusDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionDTO>(
    {
      url: `/prescribers/${prescriberId}/prescriptions/${prescriptionId}`,
      method: 'post',
      data: pushPrescriptionStatusDTO,
    },
    options
  )
}

export const usePushPrescriptionStatus = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof pushPrescriptionStatus>,
    TError,
    {
      prescriberId: number
      prescriptionId: number
      data: PushPrescriptionStatusDTO
    },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof pushPrescriptionStatus>,
    {
      prescriberId: number
      prescriptionId: number
      data: PushPrescriptionStatusDTO
    }
  > = props => {
    const { prescriberId, prescriptionId, data } = props || {}

    return pushPrescriptionStatus(
      prescriberId,
      prescriptionId,
      data,
      requestOptions
    )
  }

  return useMutation<
    AsyncReturnType<typeof pushPrescriptionStatus>,
    TError,
    {
      prescriberId: number
      prescriptionId: number
      data: PushPrescriptionStatusDTO
    },
    TContext
  >(mutationFn, mutationOptions)
}
export const updatePrescription = (
  prescriberId: number,
  prescriptionId: number,
  updatePrescriptionDTO: UpdatePrescriptionDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionDTO>(
    {
      url: `/prescribers/${prescriberId}/prescriptions/${prescriptionId}`,
      method: 'put',
      data: updatePrescriptionDTO,
    },
    options
  )
}

export const useUpdatePrescription = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePrescription>,
    TError,
    {
      prescriberId: number
      prescriptionId: number
      data: UpdatePrescriptionDTO
    },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePrescription>,
    {
      prescriberId: number
      prescriptionId: number
      data: UpdatePrescriptionDTO
    }
  > = props => {
    const { prescriberId, prescriptionId, data } = props || {}

    return updatePrescription(
      prescriberId,
      prescriptionId,
      data,
      requestOptions
    )
  }

  return useMutation<
    AsyncReturnType<typeof updatePrescription>,
    TError,
    {
      prescriberId: number
      prescriptionId: number
      data: UpdatePrescriptionDTO
    },
    TContext
  >(mutationFn, mutationOptions)
}
export const getPublicPrescriptionById = (
  prescriptionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PublicPrescriptionDTO>(
    { url: `/prescriptions/${prescriptionId}/public`, method: 'get' },
    options
  )
}

export const getGetPublicPrescriptionByIdQueryKey = (
  prescriptionId: number
) => [`/prescriptions/${prescriptionId}/public`]

export const useGetPublicPrescriptionById = <
  TData = AsyncReturnType<typeof getPublicPrescriptionById>,
  TError = unknown
>(
  prescriptionId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPublicPrescriptionById>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPublicPrescriptionByIdQueryKey(prescriptionId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPublicPrescriptionById>
  > = () => getPublicPrescriptionById(prescriptionId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPublicPrescriptionById>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriptionId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getPrescriptionStatus = (
  prescriptionId: number,
  statusId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionStatusDTO>(
    {
      url: `/prescriptions/${prescriptionId}/statuses/${statusId}`,
      method: 'get',
    },
    options
  )
}

export const getGetPrescriptionStatusQueryKey = (
  prescriptionId: number,
  statusId: number
) => [`/prescriptions/${prescriptionId}/statuses/${statusId}`]

export const useGetPrescriptionStatus = <
  TData = AsyncReturnType<typeof getPrescriptionStatus>,
  TError = unknown
>(
  prescriptionId: number,
  statusId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriptionStatus>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrescriptionStatusQueryKey(prescriptionId, statusId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getPrescriptionStatus>> =
    () => getPrescriptionStatus(prescriptionId, statusId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriptionStatus>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(prescriptionId && statusId),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}

export const sendPrescription = (
  prescriberId: number,
  prescriptionId: number,
  sendPrescriptionDTO: SendPrescriptionDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/prescribers/${prescriberId}/prescriptions/${prescriptionId}/send`,
      method: 'post',
      data: sendPrescriptionDTO,
    },
    options
  )
}

export const useSendPrescription = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof sendPrescription>,
    TError,
    { prescriberId: number; prescriptionId: number; data: SendPrescriptionDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof sendPrescription>,
    { prescriberId: number; prescriptionId: number; data: SendPrescriptionDTO }
  > = props => {
    const { prescriberId, prescriptionId, data } = props || {}

    return sendPrescription(prescriberId, prescriptionId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof sendPrescription>,
    TError,
    { prescriberId: number; prescriptionId: number; data: SendPrescriptionDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const sendPrescriptionReminder = (
  prescriptionId: number,
  sendPrescriptionReminderDTO: SendPrescriptionReminderDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/prescriptions/${prescriptionId}/reminder`,
      method: 'post',
      data: sendPrescriptionReminderDTO,
    },
    options
  )
}

export const useSendPrescriptionReminder = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof sendPrescriptionReminder>,
    TError,
    { prescriptionId: number; data: SendPrescriptionReminderDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof sendPrescriptionReminder>,
    { prescriptionId: number; data: SendPrescriptionReminderDTO }
  > = props => {
    const { prescriptionId, data } = props || {}

    return sendPrescriptionReminder(prescriptionId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof sendPrescriptionReminder>,
    TError,
    { prescriptionId: number; data: SendPrescriptionReminderDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const getPrescriptionFiles = (
  prescriptionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionFileDTO[]>(
    { url: `/prescriptions/${prescriptionId}/files`, method: 'get' },
    options
  )
}

export const getGetPrescriptionFilesQueryKey = (prescriptionId: number) => [
  `/prescriptions/${prescriptionId}/files`,
]

export const useGetPrescriptionFiles = <
  TData = AsyncReturnType<typeof getPrescriptionFiles>,
  TError = unknown
>(
  prescriptionId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriptionFiles>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPrescriptionFilesQueryKey(prescriptionId)

  const queryFn: QueryFunction<AsyncReturnType<typeof getPrescriptionFiles>> =
    () => getPrescriptionFiles(prescriptionId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriptionFiles>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!prescriptionId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const savePrescriptionFiles = (
  prescriptionId: number,
  savePrescriptionFilesDTO: SavePrescriptionFilesDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/prescriptions/${prescriptionId}/files`,
      method: 'post',
      data: savePrescriptionFilesDTO,
    },
    options
  )
}

export const useSavePrescriptionFiles = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof savePrescriptionFiles>,
    TError,
    { prescriptionId: number; data: SavePrescriptionFilesDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof savePrescriptionFiles>,
    { prescriptionId: number; data: SavePrescriptionFilesDTO }
  > = props => {
    const { prescriptionId, data } = props || {}

    return savePrescriptionFiles(prescriptionId, data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof savePrescriptionFiles>,
    TError,
    { prescriptionId: number; data: SavePrescriptionFilesDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const relaunchPrescriptionById = (
  prescriptionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionDTO>(
    { url: `/prescriptions/${prescriptionId}/relaunch`, method: 'patch' },
    options
  )
}

export const useRelaunchPrescriptionById = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof relaunchPrescriptionById>,
    TError,
    { prescriptionId: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof relaunchPrescriptionById>,
    { prescriptionId: number }
  > = props => {
    const { prescriptionId } = props || {}

    return relaunchPrescriptionById(prescriptionId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof relaunchPrescriptionById>,
    TError,
    { prescriptionId: number },
    TContext
  >(mutationFn, mutationOptions)
}
export const archivePrescriptionById = (
  prescriptionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionDTO>(
    { url: `/prescriptions/${prescriptionId}/archive`, method: 'patch' },
    options
  )
}

export const useArchivePrescriptionById = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof archivePrescriptionById>,
    TError,
    { prescriptionId: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof archivePrescriptionById>,
    { prescriptionId: number }
  > = props => {
    const { prescriptionId } = props || {}

    return archivePrescriptionById(prescriptionId, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof archivePrescriptionById>,
    TError,
    { prescriptionId: number },
    TContext
  >(mutationFn, mutationOptions)
}
export const calculPrescriptionAmounts = (
  calculPrescriptionAmountsDTO: CalculPrescriptionAmountsDTO,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrescriptionAmountsDTO>(
    {
      url: `/prescriptions/calcul-amounts`,
      method: 'post',
      data: calculPrescriptionAmountsDTO,
    },
    options
  )
}

export const useCalculPrescriptionAmounts = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof calculPrescriptionAmounts>,
    TError,
    { data: CalculPrescriptionAmountsDTO },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof calculPrescriptionAmounts>,
    { data: CalculPrescriptionAmountsDTO }
  > = props => {
    const { data } = props || {}

    return calculPrescriptionAmounts(data, requestOptions)
  }

  return useMutation<
    AsyncReturnType<typeof calculPrescriptionAmounts>,
    TError,
    { data: CalculPrescriptionAmountsDTO },
    TContext
  >(mutationFn, mutationOptions)
}
export const patchPrescriptionStatusVisitedAt = (
  prescriptionId: number,
  params?: PatchPrescriptionStatusVisitedAtParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/prescription/${prescriptionId}/visited`, method: 'patch', params },
    options
  )
}

export const usePatchPrescriptionStatusVisitedAt = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof patchPrescriptionStatusVisitedAt>,
    TError,
    { prescriptionId: number; params?: PatchPrescriptionStatusVisitedAtParams },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {}

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof patchPrescriptionStatusVisitedAt>,
    { prescriptionId: number; params?: PatchPrescriptionStatusVisitedAtParams }
  > = props => {
    const { prescriptionId, params } = props || {}

    return patchPrescriptionStatusVisitedAt(
      prescriptionId,
      params,
      requestOptions
    )
  }

  return useMutation<
    AsyncReturnType<typeof patchPrescriptionStatusVisitedAt>,
    TError,
    { prescriptionId: number; params?: PatchPrescriptionStatusVisitedAtParams },
    TContext
  >(mutationFn, mutationOptions)
}
export const getAllPrescriptionsByPatient = (
  userPatientId: number,
  params?: GetAllPrescriptionsByPatientParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SimplifiedPrescriptionsPagingDTO>(
    {
      url: `/user-patients/${userPatientId}/prescriptions`,
      method: 'get',
      params,
    },
    options
  )
}

export const getGetAllPrescriptionsByPatientQueryKey = (
  userPatientId: number,
  params?: GetAllPrescriptionsByPatientParams
) => [
  `/user-patients/${userPatientId}/prescriptions`,
  ...(params ? [params] : []),
]

export const useGetAllPrescriptionsByPatient = <
  TData = AsyncReturnType<typeof getAllPrescriptionsByPatient>,
  TError = unknown
>(
  userPatientId: number,
  params?: GetAllPrescriptionsByPatientParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllPrescriptionsByPatient>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllPrescriptionsByPatientQueryKey(userPatientId, params)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllPrescriptionsByPatient>
  > = () => getAllPrescriptionsByPatient(userPatientId, params, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getAllPrescriptionsByPatient>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!userPatientId, ...queryOptions })

  return {
    queryKey,
    ...query,
  }
}

export const getPrescriptionByIdByPatient = (
  userPatientId: number,
  prescriptionId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SimplifiedPrescriptionDTO>(
    {
      url: `/user-patients/${userPatientId}/prescriptions/${prescriptionId}`,
      method: 'get',
    },
    options
  )
}

export const getGetPrescriptionByIdByPatientQueryKey = (
  userPatientId: number,
  prescriptionId: number
) => [`/user-patients/${userPatientId}/prescriptions/${prescriptionId}`]

export const useGetPrescriptionByIdByPatient = <
  TData = AsyncReturnType<typeof getPrescriptionByIdByPatient>,
  TError = unknown
>(
  userPatientId: number,
  prescriptionId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPrescriptionByIdByPatient>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrescriptionByIdByPatientQueryKey(userPatientId, prescriptionId)

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPrescriptionByIdByPatient>
  > = () =>
    getPrescriptionByIdByPatient(userPatientId, prescriptionId, requestOptions)

  const query = useQuery<
    AsyncReturnType<typeof getPrescriptionByIdByPatient>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(userPatientId && prescriptionId),
    ...queryOptions,
  })

  return {
    queryKey,
    ...query,
  }
}
