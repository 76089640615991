/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type TakeFormatType =
  | 'TABLESPOON'
  | 'CAPSULE'
  | 'SPRAY'
  | 'DROPS'
  | 'BAG'
  | 'TABLETS'
  | 'SOFT_CAPSULE'
  | 'POD'
  | 'TEASPOON'
  | 'GLASS'
  | 'CAP'
  | 'AMPOULE'
  | 'NUT'
  | 'PATCH'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TakeFormatType = {
  TABLESPOON: 'TABLESPOON' as TakeFormatType,
  CAPSULE: 'CAPSULE' as TakeFormatType,
  SPRAY: 'SPRAY' as TakeFormatType,
  DROPS: 'DROPS' as TakeFormatType,
  BAG: 'BAG' as TakeFormatType,
  TABLETS: 'TABLETS' as TakeFormatType,
  SOFT_CAPSULE: 'SOFT_CAPSULE' as TakeFormatType,
  POD: 'POD' as TakeFormatType,
  TEASPOON: 'TEASPOON' as TakeFormatType,
  GLASS: 'GLASS' as TakeFormatType,
  CAP: 'CAP' as TakeFormatType,
  AMPOULE: 'AMPOULE' as TakeFormatType,
  NUT: 'NUT' as TakeFormatType,
  PATCH: 'PATCH' as TakeFormatType,
}
