import { SubTitle, SelectWrapper, Select } from './style'
import { NavProps } from './props'

const Nav = ({ setSelectedOption, selectedOption }: NavProps) => {
  // Functions
  const handleLinkClick = () => {
    setSelectedOption('LINK')
  }

  const handleEmailClick = () => {
    setSelectedOption('EMAIL')
  }

  return (
    <>
      <SubTitle>Comment souhaitez-vous envoyer la recommandation ?</SubTitle>
      <SelectWrapper>
        <Select onClick={handleLinkClick}>
          <div>{selectedOption === 'LINK' && <span></span>}</div>
          <p>Partager le lien</p>
        </Select>
        <Select onClick={handleEmailClick}>
          <div>{selectedOption === 'EMAIL' && <span></span>}</div>
          <p>Envoyer un e-mail</p>
        </Select>
      </SelectWrapper>
    </>
  )
}

export default Nav
