import styled from 'styled-components/macro'

export const Title = styled.h1`
  color: var(--Text-400, #393360);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  padding-top: 2rem;
`

export const SubTitle = styled.h1`
  margin-top: 0.75rem;
  color: var(--Text-300, #6b6688);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const NotFoundWapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 4.6875rem);
  padding-bottom: 7.5rem;
`

export const WrapperContentNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 35.1875rem;
`

export const TitleNotFound = styled.h1`
  color: var(--Text-500, #080038);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'basic-grotesque-pro', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-self: stretch;
  text-align: center;
`

export const SubTitleNotFound = styled.h1`
  color: var(--Text-300, #6b6688);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.75rem;
  text-align: center;
`

export const Button = styled.button`
  border: none;
  display: flex;
  height: 2.5rem;
  padding: 0rem 1.25rem;
  margin-top: 1.25rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: var(--Compliment-Dark-Green-500, #3a845e);
  color: var(--White-500, var(--Surfaces-Fill-surface-fill-white, #fff));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'basic-grotesque-pro', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
