import styled from 'styled-components/macro'
import { MediaQuery } from '../../utils/constants'

export const BodyContainer = styled.div`
  background-color: #f8f9fc;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 64px;
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.6875rem;
  /* margin-top:1.25rem;
  padding-top: 40px; */
`
export const Title = styled.h1`
  padding-top: 32px;
  color: #080038;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const SubTitle = styled.h3`
  color: #393360;
  max-width: auto;
  margin-bottom: 24px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${MediaQuery.M_AND_UP} {
    flex-direction: row;
  }
`
