import { useState } from 'react'
import {
  useGetAllPrescriberFavoriteListsByPrescriberId,
  useGetAllProductsFromPrescriberFavoriteItems,
  useGetAllProductsFromPrescriberFavoriteListItemsByListId,
} from '../../../services/api/favorites/favorites'
import { useGetMe } from '../../../utils/hooks/getMe'

export const useFilterLogic = ({ packagingType }) => {
  //Attributes
  const { data: prescriber } = useGetMe()

  //Queries
  const { data: allFavoriteLists, isLoading: isAllFavoriteListsLoading } =
    useGetAllProductsFromPrescriberFavoriteItems(
      prescriber?.id,
      {
        packagingType: packagingType,
      },
      {
        query: {
          enabled: !!prescriber?.id,
        },
      }
    )
  const {
    data: favoriteListsCategories,
    refetch: refetchFavoriteListsCategories,
  } = useGetAllPrescriberFavoriteListsByPrescriberId(prescriber?.id)

  // Attributes
  const [filter, setFilter] = useState<string>()
  const allFavoriteListsHandles = allFavoriteLists?.map(i => i.handle)

  // Fetch favorite lists
  const { data: favoriteLists } =
    useGetAllProductsFromPrescriberFavoriteListItemsByListId(
      filter,
      {
        packagingType: packagingType,
      },
      {
        query: {
          enabled: !!prescriber?.id,
        },
      }
    )

  // Determine the list to display based on the selected filter
  const list = filter ? favoriteLists : allFavoriteLists

  //Return
  return {
    allFavoriteLists,
    isAllFavoriteListsLoading,
    favoriteListsCategories,
    filter,
    setFilter,
    allFavoriteListsHandles,
    refetchFavoriteListsCategories,
    favoriteLists,
    list,
  }
}
