import { useState } from 'react'
import { toast } from 'react-toastify'
import { checkVAT, belgium, france, luxembourg } from 'jsvat'

import { Hr } from './style'

import ProfileItem from './profile-item'
import {
  useCreateDefaultDiscountsByPrescriberId,
  useUpdatePrescriber,
} from '../../../services/api/prescriber/prescriber'
import ShareAccess from '../../share-access-dialog/home-page-content'
import BlockAccessModal from '../../../components/block-access-modal'
import { useGetMe } from '../../../utils/hooks/getMe'
import SelectCommissionType from './select-commission-type'

const PaymentSection = () => {
  const { data: prescriber, refetch: refetchPrescriber } = useGetMe()
  const [editField, setEditField] = useState('')
  const [selectValue, setSelectedValue] = useState(
    prescriber?.activeDiscount?.percentage
  )
  const { mutateAsync: createDiscountCodes } =
    useCreateDefaultDiscountsByPrescriberId()
  const { mutateAsync: updatePrescriber } = useUpdatePrescriber()
  const [isBlockAccessModalOpen, setIsBlockAccessModalOpen] = useState(false)

  const onSubmit = async value => {
    if (editField === 'tvaNumber') {
      const vatInfos = checkVAT(value, [belgium, france, luxembourg])
      if (!vatInfos.isValid) {
        toast.error(
          "Le numéro de TVA entré n'est pas correct, merci de ré-essayer."
        )
        return
      }
    }

    let discountSelected = null

    try {
      const defaultDiscounts = await createDiscountCodes({
        prescriberId: prescriber?.id,
      })

      discountSelected = defaultDiscounts?.data?.find(
        d => d.percentage === selectValue
      )
    } catch (err) {
      toast.error(
        "Une erreur s'est produite dans la création de vos promo codes"
      )
      return
    }

    updatePrescriber({
      prescriberId: prescriber?.id,
      data: {
        firstName: prescriber?.firstName || '',
        lastName: prescriber?.lastName || '',
        phoneNumber: prescriber?.phoneNumber || '',
        enterpriseName:
          (editField === 'enterpriseName'
            ? value
            : prescriber?.enterpriseName) || '',
        tvaNumber:
          (editField === 'tvaNumber' ? value : prescriber?.tvaNumber) || '',
        invoiceAddress:
          (editField === 'invoiceAddress'
            ? value
            : prescriber?.invoiceAddress) || '',
        bankAccount:
          (editField === 'bankAccount' ? value : prescriber?.bankAccount) || '',
        activeDiscountId: discountSelected?.id || null,
        calendlyCode: prescriber?.calendlyCode,
        isOnboarded: prescriber?.isOnboarded,
        profession: prescriber?.profession,
        disableCommission: prescriber?.disableCommission,
      },
    })
      .then(() => {
        toast.success('Votre profil a été mis à jour avec succès')
        refetchPrescriber()
        setEditField('')
      })
      .catch(() => {
        toast.error("Une erreur s'est produite, réessayez plus tard")
      })
  }
  return (
    <div>
      <ProfileItem
        defaultValue={prescriber?.activeDiscount?.code}
        activeDiscountPercentage={prescriber?.activeDiscount?.percentage}
        label='Votre code personnel de réduction'
        description="Ce code peut-être utilisé par vos clients afin de bénéficier d'un tarif
        avantageux lors de leurs commandes."
      />
      <Hr />
      <SelectCommissionType
        prescriber={prescriber}
        refetchPrescriber={refetchPrescriber}
      />
      <Hr />
      {!prescriber?.disableCommission && (
        <>
          <ProfileItem
            defaultValue={selectValue}
            valueSuffix='%'
            label='Pourcentage de réduction'
            openEdit={() => {
              if (prescriber?.isVerified) setEditField('discount')
              else setIsBlockAccessModalOpen(true)
            }}
            isEditing={true}
            description={`Votre code ${
              prescriber?.activeDiscount?.code
                ? prescriber?.activeDiscount?.code
                : ''
            } offre ${prescriber?.activeDiscount?.percentage}% de
        réduction à vos clients qui l'utilisent.`}
            type='select'
            selectValue={selectValue}
            setSelectedValue={setSelectedValue}
            onSubmit={onSubmit}
            firstName={prescriber?.firstName}
            lastName={prescriber?.lastName}
            activeDiscount={prescriber?.activeDiscount?.code}
          />
          <Hr />
        </>
      )}

      <ShareAccess />
      <Hr />
      <BlockAccessModal
        isOpen={isBlockAccessModalOpen}
        setIsOpen={setIsBlockAccessModalOpen}
      />
    </div>
  )
}

export default PaymentSection
