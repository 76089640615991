import { useGetMe } from '../../../utils/hooks/getMe'

import { useGetPrescriberNotes } from '../../../services/api/notes/notes'
import { Container, SpinnerWrapper, PlaceholderWrapper } from './style'
import { Spinner, NoFound } from '../../../components/ui'
import TableItem from './table-item'

export interface NotesTableProps {
  search: string
}

const NotesTable = ({ search }: NotesTableProps) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const { data: prescriberNotes, isLoading: isPrescriberNotesLoading } =
    useGetPrescriberNotes(
      prescriber?.id,
      {
        size: undefined,
        sort: 'DATE',
        search,
      },
      {
        query: {
          enabled: !!prescriber?.id,
        },
      }
    )

  // Render
  return (
    <Container>
      {isPrescriberNotesLoading ? (
        <SpinnerWrapper>
          <Spinner fullScreen={false} />
        </SpinnerWrapper>
      ) : !prescriberNotes?.data?.length ? (
        <PlaceholderWrapper>
          <NoFound
            title='Aucune Note'
            descritpion='Nous n’avons trouvé aucun résultat à votre recherche'
          />
        </PlaceholderWrapper>
      ) : (
        <table>
          <thead>
            <tr>
              <th style={{ fontWeight: 500 }}>Client</th>
              <th style={{ fontWeight: 500 }}>Titre de la note</th>
              <th style={{ fontWeight: 500 }}>Date de consultation</th>
              <th style={{ fontWeight: 500 }}>Dernière modification</th>
              <th style={{ fontWeight: 500 }}></th>
            </tr>
          </thead>

          <tbody>
            {prescriberNotes?.data?.map(
              ({
                consultedAt,
                createdAt,
                description,
                id,
                modifiedAt,
                title,
                patientFirstName,
                patientLastName,
                patientEmail,
                patientId,
              }) => {
                return (
                  <TableItem
                    key={id}
                    consultedAt={consultedAt}
                    createdAt={createdAt}
                    description={description}
                    id={id}
                    modifiedAt={modifiedAt}
                    title={title}
                    patientFirstName={patientFirstName}
                    patientLastName={patientLastName}
                    patientEmail={patientEmail}
                    patientId={patientId}
                  />
                )
              }
            )}
          </tbody>
        </table>
      )}
    </Container>
  )
}

export default NotesTable
