import {
  Container,
  ContentContainer,
  HeaderContainer,
  HeaderTitle,
  TooltipIconContainer,
} from './style'
import { RewardContainerProps } from './props'
import { InfoBig } from '../../ui/icon/icons'

const RewardContainer = ({
  title,
  onTooltipClick,
  children,
}: RewardContainerProps) => {
  // Render
  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle>{title}</HeaderTitle>
        <TooltipIconContainer>
          {onTooltipClick && (
            <InfoBig
              onClick={onTooltipClick}
              width='1.75rem'
              height='1.75rem'
              stroke='#080038'
            />
          )}
        </TooltipIconContainer>
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  )
}

export default RewardContainer
