import styled from 'styled-components/macro'

export const Content = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
`

export const HeaderCatalogueHeader = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 4.6875rem;
  z-index: 2;
  border-radius: 0.875rem;
  flex-direction: column;
`

export const WrapperHeaderCatalogue = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 4.6875rem;
  padding-top: 1.25rem;
  z-index: 1;
  border-radius: 0.875rem;
  flex-direction: column;
  background-color: #fdfcfe;
`

export const ProductsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5625rem;
  z-index: 0;
  justify-content: center;
`

export const TableWithFilters = styled.div`
  padding-top: 1.25rem;
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: #fdfcfe;
`

export const Table = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WrapperTableContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
