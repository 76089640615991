import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import NakedLayout from '../../../components/layout/naked'

import usePatientRegistrationLogic from '../../../components/auth/register-form/patient-registration/logic'
import {
  Wrapper,
  FooterText,
  ImageLogo,
  Subtitle,
  ImageText,
  Image,
  TextImage,
  BackButtonWrapper,
  Title,
} from './style'
import { useHistory } from 'react-router'
import { BackButton } from '../../../components/ui'
import { useState } from 'react'
import PatientRegisterForm from '../../../components/auth/register-form/patient-registration/patient-register-form-web'
import { SignUpStep } from '../../../components/auth/register-form/patient-registration/type'

const RegisterPatientWebApp = () => {
  //Attributes
  const [step, setStep] = useState(SignUpStep.STEP_1)
  const history = useHistory()
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isSubmissionSuccessful,
    validateFormFields,
    isRegisterUserLoading,
  } = usePatientRegistrationLogic()

  //Functions
  function handleStepChange(newStep) {
    setStep(newStep)
  }

  function handleBackButtonClick() {
    if (step === SignUpStep.STEP_1) {
      history.push('/portail')
    } else {
      setStep(SignUpStep.STEP_1)
    }
  }

  //Return
  return (
    <>
      <NakedLayout>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Simplycure | register </title>
        </Helmet>
        <Wrapper>
          <BackButtonWrapper>
            <BackButton onClick={handleBackButtonClick} />
          </BackButtonWrapper>
          <ImageLogo
            src={'/assets/images/logo-compliment.png'}
            alt='logo-image'
          />
          <Title>Faisons plus ample connaissance</Title>
          <Subtitle>Nous apprécions connaître nos clients</Subtitle>
          <PatientRegisterForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            values={values}
            step={step}
            onStepChange={handleStepChange}
            handleSubmit={handleSubmit}
            isSubmissionSuccessful={isSubmissionSuccessful}
            validateFormFields={validateFormFields}
            isLoading={isRegisterUserLoading}
          />
          <FooterText>
            Dejà un compte ?{' '}
            <Link
              to={{
                pathname: '/patient/login',
              }}
            >
              Connectez-vous !
            </Link>
          </FooterText>
        </Wrapper>
        <ImageText>
          <Image
            src={'/assets/images/frame.png'}
            alt='image-easy-presciption'
          />
          <TextImage>
            Commandez vos compléments alimentaires en toute simplicité
          </TextImage>
        </ImageText>
      </NakedLayout>
    </>
  )
}

export default RegisterPatientWebApp
