import { Container } from './style'
import PatientCell from '../../../ui/patient-cell'
import NoteTitle from './note-title'
import Date from './date'
import More from './more'

export interface TableItemProps {
  consultedAt: string
  createdAt: string
  description: string
  id: string
  modifiedAt: string
  title: string
  patientFirstName: string
  patientLastName: string
  patientEmail: string
  patientId: number
  key: string
}

const TableItem = ({
  consultedAt,
  description,
  id,
  modifiedAt,
  title,
  patientFirstName,
  patientLastName,
  patientEmail,
  patientId,
}: TableItemProps) => {
  // Attributes

  // Render
  return (
    <Container>
      <PatientCell
        firstName={patientFirstName}
        lastName={patientLastName}
        email={patientEmail}
        id={patientId}
      />
      <NoteTitle id={id} title={title} description={description} />
      <Date date={consultedAt} />
      <Date date={modifiedAt} />
      <More id={id} />
    </Container>
  )
}

export default TableItem
