import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NewPrescriptionHeaderProps } from './props'
import {
  Container,
  CTAContainer,
  FlexItem,
  TextContainer,
  TextContainerHeader,
} from './style'
import {
  DialogContent,
  Title,
  DialogContainer,
} from '../../prescriptions/new-prescription-compliment/style'
import { Button, ButtonGroup, Dialog } from '../../../components/ui'

import { BackButton } from '../../../components/ui'

const NewPrescriptionHeader = ({
  prescriptionType,
  onCancel,
  currentStep,
  texts,
  setIsSliderProtocolDialogOpen,
  handleResetForm,
}: NewPrescriptionHeaderProps) => {
  // Attributes
  const history = useHistory()
  const [isDialogOpen, setIsDialogOpen] = useState('')
  const [isDialogOpen2, setIsDialogOpen2] = useState(false)

  // Functions
  const switchPrescriptionType = (url: string) => {
    history.push(url)
  }

  // Render
  return (
    <Container>
      <FlexItem>
        <CTAContainer>
          <BackButton onClick={() => setIsDialogOpen2(true)} />
        </CTAContainer>
        <TextContainer>
          <TextContainerHeader>Nouveau protocole</TextContainerHeader>
        </TextContainer>
      </FlexItem>

      <Dialog
        type='default'
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen('')}
        styleContainer={undefined}
      >
        <DialogContainer>
          <DialogContent>
            <Title isCenter>
              Attention, si vous changez de type de recommandation toutes vos
              données encodées seront perdues.
              <br />
              <br />
              Êtes-vous certain de vouloir changer de type de recommandation ?
            </Title>
            <ButtonGroup spaced>
              <Button
                appearance='minimal'
                onClick={() => setIsDialogOpen('')}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Non, je continue ma recommandation
              </Button>
              <Button
                onClick={() => {
                  switchPrescriptionType(isDialogOpen)
                  setIsDialogOpen('')
                }}
                appearance={undefined}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Oui, je suis sûr
              </Button>
            </ButtonGroup>
          </DialogContent>
        </DialogContainer>
      </Dialog>

      <Dialog
        type='default'
        isOpen={isDialogOpen2}
        onClose={() => setIsDialogOpen2(false)}
        styleContainer={undefined}
      >
        <DialogContainer>
          <DialogContent>
            <Title isCenter>
              Attention, si vous annulez toutes vos données encodées seront
              perdues.
              <br />
              <br />
              Êtes-vous certain de vouloir quitter la création de votre
              protocole ?
            </Title>
            <ButtonGroup spaced>
              <Button
                appearance='minimal'
                onClick={() => setIsDialogOpen2(false)}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Non, je continue mon protocole
              </Button>
              <Button
                onClick={() => {
                  setIsDialogOpen2(false)
                  history.push('/protocoles')
                }}
                appearance={undefined}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Oui, je suis sûr
              </Button>
            </ButtonGroup>
          </DialogContent>
        </DialogContainer>
      </Dialog>
    </Container>
  )
}

export default NewPrescriptionHeader
