import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  list: [],
  /**
   * New draft correspond to a new prescription, it's a 3 step form so the
   * user can quit the process and come back if needed
   */
  newDraft: {
    name: '',
    message: '',
    description: '',
    takes: [],
    duration: 1,
    durationUnit: 'month',
    share: false,
    files: [],
  },
  actions: {
    add: { ...INITIAL_ACTION_STATE },
    delete: { ...INITIAL_ACTION_STATE },
    fetchAll: { ...INITIAL_ACTION_STATE },
    fetchOne: { ...INITIAL_ACTION_STATE },
    pushStatus: { ...INITIAL_ACTION_STATE },
  },
}

export default state
