import styled from 'styled-components/macro'

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;
  gap: 4px;

  height: 58px;

  background: #ffffff;
  /* Elevation/Light/Bottom/01 */

  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;

  a {
    text-decoration: none !important;
  }
`

export const SearchBarLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  min-height: 34px;
`

export const SearchBarVerticalLine = styled.div`
  width: 25px;
  height: 0px;

  /* Text/200 */

  border: 0.5px solid #9c99af;
  transform: rotate(-90deg);
`

export const SearchBarButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;

  width: 135px;
  height: 42px;

  background: #3d825b;
  border-radius: 6px;

  cursor: pointer;
`

export const SearchBarButtonText = styled.p`
  font-family: 'System';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  /* White/500 */

  color: #ffffff;
`

export const SearchBarInput = styled.input`
  font-family: 'System';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  /* Text/200 */

  color: #9c99af;
  outline: none;
  border: none;

  width: 300px;

  ::placeholder {
    font-family: 'System';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    /* Text/200 */

    color: #9c99af;
    outline: none;
    border: none;
  }
`
