import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useUpdatePrescriberOnboarding } from '../../../services/api/prescriber/prescriber'
import {
  IUseOnboardingLogic,
  OnboardingFormik,
  OnboardingLogicProps,
} from './type'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router'

const useOnboardingLogic = ({
  prescriber,
  prescriberDiscount,
  refetchPrescriber,
}: OnboardingLogicProps): IUseOnboardingLogic => {
  //Attributes
  const { push } = useHistory()
  const defaultDiscountPercentage = prescriberDiscount?.data?.find(
    discount => discount.percentage === 10
  )?.id

  //Queries
  const { mutateAsync: updatePrescriber, isLoading: isEditPrescriberLoading } =
    useUpdatePrescriberOnboarding()

  // Form
  const schema = Yup.object().shape({
    averageAppointementParWeek: Yup.string().required('Champs obligatoire'),
    averageRecommendationsPerWeek: Yup.string().required('Champs obligatoire'),
    hasDemoSales: Yup.boolean().required('Champs obligatoire'),
    phoneNumber: Yup.string().required('Champs obligatoire'),
  })

  const { values, setFieldValue, handleChange } = useFormik<OnboardingFormik>({
    enableReinitialize: true,
    initialValues: {
      averageMonthlyAppointment: undefined,
      averageMonthlyRecommendation: undefined,
      hasDemoSales: undefined,
      phoneNumber: undefined,
    },
    validationSchema: schema,
    onSubmit: values => hanldeUpdatePrescriber(values),
  })
  const areAllFieldsFilled = Object.values(values).every(
    value => value !== undefined && value !== ''
  )

  //Function
  async function hanldeUpdatePrescriber(values: OnboardingFormik) {
    updatePrescriber(
      {
        prescriberId: prescriber?.id,
        data: {
          phoneNumber: values?.phoneNumber,
          hasDemoSales: values?.hasDemoSales,
          averageMonthlyAppointment: values.averageMonthlyAppointment,
          averageMonthlyRecommendation: values?.averageMonthlyRecommendation,
        },
      },
      {
        onSuccess: handleUpdatePresciberSuccess,
        onError: handleUpdatePrescriberError,
      }
    )
  }

  async function handleUpdatePresciberSuccess() {
    await refetchPrescriber()
    toast.success("Votre onboarding s'est déroulé avec succès!", {
      duration: 3000,
    })
    push('/')
  }

  function handleUpdatePrescriberError() {
    toast.error("Une erreur s'est produite", { duration: 3000 })
  }

  //Render
  return {
    areAllFieldsFilled,
    isEditPrescriberLoading,
    hanldeUpdatePrescriber,
    setFieldValue,
    values,
    handleChange,
  }
}

export default useOnboardingLogic
