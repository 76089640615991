import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../../utils/constants'

export const Container = styled.div`
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  font-size: 13px;
  border-radius: 10px;
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
`

export const FlexList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
  ${MediaQuery.S_AND_DOWN} {
    grid-template-columns: repeat(1, 1fr);
  }
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const Card = styled.div`
  height: 100px;
  padding: 20px 10px;
  font-size: 10px;
  font-weight: 300;
  background: #f2f3f8;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
`

export const CardTitle = styled.div`
  color: #3a835f;
  margin-bottom: 3px;
  font-size: 13px;
  margin-bottom: 10px;
`
export const CardButton = styled.a`
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  text-decoration: none;
`

export const CardButtonText = styled.p`
  opacity: 0.7;
  font-size: 12px;
  margin-right: 6px;
`

export const CardButtonIcon = styled.span`
  font-size: 13px;
  margin-top: 5px;
`
