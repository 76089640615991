import { Field } from '../../../ui'
import { Form } from './style'
import { Container, Title } from '../style'
import { CreateProtocoleStep1Props } from './props'

const CreateProtocoleStep1 = ({
  name,
  setName,
  description,
  setDescription,
}: CreateProtocoleStep1Props) => {
  // Render
  return (
    <Container>
      <Title>Informations du protocole</Title>
      <Form>
        <Field
          label='Nom du protocole'
          placeholder='Protocole stress'
          type='text'
          defaultValue={name}
          onChange={event => {
            setName(event.target.value)
          }}
          value={name}
          name={'name'}
          id={undefined}
          onBlur={undefined}
          error={undefined}
          touched={undefined}
          isRequired={undefined}
          ref={undefined}
        />
        <Field
          label={`Ajouter une description pour votre protocole`}
          name='message'
          id='message'
          type='textarea'
          onChange={e => setDescription(e.target.value)}
          defaultValue={description}
          value={description}
          onBlur={undefined}
          error={undefined}
          touched={undefined}
          isRequired={undefined}
          ref={undefined}
        />
      </Form>
    </Container>
  )
}

export default CreateProtocoleStep1
