import { useState } from 'react'
import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import { Container, Content } from './style'
import { useOnboardingProfileCheck } from '../../utils/hooks'
import { HeaderLinks } from '../../components/section/header/props'
import Categories from '../../components/product/favorites/categories'
import AddProduct from '../../components/product/favorites/add-product'
import CategoriesList from '../../components/product/favorites/categories-list'
import { Button } from '../../components/ui'
import OrderFormDialog from '../../components/order-form-dialog'
import { DownloadIcon } from '../../components/order-form-dialog/style'
import { MdDownload } from 'react-icons/md'
import { useGetMe } from '../../utils/hooks/getMe'
import {
  useGetAllPrescriberFavoriteListsByPrescriberId,
  useGetAllProductsFromPrescriberFavoriteItems,
  useGetAllProductsFromPrescriberFavoriteListItemsByListId,
} from '../../services/api/favorites/favorites'
import { PackagingType } from '../../services/api/types'
import { useQuery } from '../../utils/hooks/getQuery'

const ProductsPage = () => {
  //Attributes
  const query = useQuery()
  const favoriteListId = query.get('favoriteListId')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isCreateCategoryDialogOpen, setIsCreateCategoryDialogOpen] =
    useState(false)

  //Queries
  const { data: prescriber } = useGetMe()
  const { data: allFavoriteLists, isLoading: isAllFavoriteListsLoading } =
    useGetAllProductsFromPrescriberFavoriteItems(
      prescriber?.id,
      {
        packagingType: PackagingType.BOTH,
      },
      {
        query: {
          enabled: !!prescriber?.id,
        },
      }
    )
  const {
    data: favoriteLists,
    isLoading: isFavoriteListsLoading,
    refetch: refetchCurrentFavoriteList,
  } = useGetAllProductsFromPrescriberFavoriteListItemsByListId(
    favoriteListId,
    {
      packagingType: PackagingType.BOTH,
    },
    {
      query: {
        enabled: !!favoriteListId,
      },
    }
  )
  const { data: userFavoriteLists } =
    useGetAllPrescriberFavoriteListsByPrescriberId(prescriber?.id, null, {
      query: {
        enabled: !!prescriber?.id,
      },
    })
  const currentFavoriteListName = userFavoriteLists?.find(
    userFavoriteItem => userFavoriteItem?.id === favoriteListId
  )?.name

  // Hooks
  useOnboardingProfileCheck()

  // Render
  return (
    <Layout active={HeaderLinks.Catalogue}>
      <PageTitle
        title='Favoris'
        rightElements={
          <>
            <Button
              onClick={() => setIsDialogOpen(true)}
              appearance='primary'
              iconLeft={undefined}
              isDisabled={undefined}
              isLoading={undefined}
              isActive={undefined}
              style={{
                height: '32px',
                fontSize: '10px',
                padding: '10px 15px',
              }}
            >
              <DownloadIcon>
                <MdDownload />
              </DownloadIcon>
              Téléchager mon bon de commande
            </Button>
          </>
        }
      />
      <Container>
        <Categories
          userFavoriteLists={userFavoriteLists}
          setIsCreateCategoryDialogOpen={setIsCreateCategoryDialogOpen}
          isCreateCategoryDialogOpen={isCreateCategoryDialogOpen}
        />
        <Content>
          <AddProduct
            currentFavoriteListName={currentFavoriteListName}
            favoriteLists={favoriteLists}
            favoriteListId={favoriteListId}
            refetchCurrentFavoriteList={refetchCurrentFavoriteList}
          />
          <CategoriesList
            allFavoriteLists={allFavoriteLists}
            favoriteListId={favoriteListId}
            favoriteLists={favoriteLists}
            isAllFavoriteListsLoading={isAllFavoriteListsLoading}
            isFavoriteListsLoading={isFavoriteListsLoading}
          />
        </Content>
      </Container>
      {isDialogOpen && (
        <OrderFormDialog
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          setIsCreateCategoryDialogOpen={setIsCreateCategoryDialogOpen}
        />
      )}
    </Layout>
  )
}

export default ProductsPage
