import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from './style'
import {
  Checkbox,
  IconAngleRight,
  IconArrowDown,
  IconArrowLeft,
  IconCancel,
  IconCheck,
  IconComment,
  IconCommentReversed,
  IconDownload,
  IconGrid,
  IconInfo,
  IconList,
  IconLoading,
  IconMenu,
  IconPencil,
  IconPlus,
  IconSearch,
  IconShipping,
  IconTrash,
  TickBox,
} from './icons'
import GrayButton from './icons/button'

const icons = {
  'angle-right': <IconAngleRight />,
  'arrow-down': <IconArrowDown />,
  'arrow-left': <IconArrowLeft />,
  'comment-reversed': <IconCommentReversed />,
  cancel: <IconCancel />,
  check: <IconCheck />,
  checkBox: <Checkbox />,
  comment: <IconComment />,
  download: <IconDownload />,
  grid: <IconGrid />,
  info: <IconInfo />,
  list: <IconList />,
  loading: <IconLoading />,
  menu: <IconMenu />,
  pencil: <IconPencil />,
  plus: <IconPlus />,
  search: <IconSearch />,
  shipping: <IconShipping />,
  tickBox: <TickBox />,
  trash: <IconTrash />,
  grayButton: <GrayButton />,
}

const IconEl = ({ name, ...props }) => <Icon {...props}>{icons[name]}</Icon>

IconEl.propTypes = {
  /**
   * The icon name
   */
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
  color: PropTypes.string,
}

IconEl.defaultProps = {
  color: undefined,
}

export default IconEl
