import Constants from './constants'

export const addProtocoleLoading = () => ({
  type: Constants.ADD_PROTOCOLE_LOADING,
})
export const addProtocoleSuccess = payload => ({
  type: Constants.ADD_PROTOCOLE_SUCCESS,
  payload: payload.insert_protocole.returning[0],
})
export const resetDeleteProtocole = () => ({
  type: Constants.RESET_DELETE_PROTOCOLE,
})
export const deleteProtocoleLoading = () => ({
  type: Constants.DELETE_PROTOCOLE_LOADING,
})
export const addProtocoleFailed = error => ({
  type: Constants.ADD_PROTOCOLE_FAILED,
  error,
})
export const deleteProtocoleSuccess = payload => ({
  type: Constants.DELETE_PROTOCOLE_SUCCESS,
  payload: payload.update_protocole.returning[0],
})
export const deleteProtocoleFailed = error => ({
  type: Constants.DELETE_PROTOCOLE_FAILED,
  error,
})
export const fetchProtocolesLoading = () => ({
  type: Constants.FETCH_PROTOCOLES_LOADING,
})
export const fetchProtocolesSuccess = payload => ({
  type: Constants.FETCH_PROTOCOLES_SUCCESS,
  payload: payload.protocole,
})
export const fetchProtocolesFailed = error => ({
  type: Constants.FETCH_PROTOCOLES_FAILED,
  error,
})

export const fetchProtocoleLoading = () => ({
  type: Constants.FETCH_PROTOCOLE_LOADING,
})
export const fetchProtocoleSuccess = payload => ({
  type: Constants.FETCH_PROTOCOLE_SUCCESS,
  payload: payload.protocole[0],
})
export const fetchProtocoleFailed = error => ({
  type: Constants.FETCH_PROTOCOLE_FAILED,
  error,
})

export const setNewProtocoleDraft = payload => ({
  type: Constants.SET_NEW_PROTOCOLE_DRAFT,
  payload,
})
export const setProtocoleDraftProductTake = payload => ({
  type: Constants.SET_PROTOCOLE_DRAFT_PRODUCT_TAKE,
  payload,
})
export const removeProductFromDraftTake = payload => ({
  type: Constants.REMOVE_PRODUCT_FROM_PROTOCOLE_DRAFT_TAKE,
  payload,
})
export const resetNewProtocoleDraft = payload => ({
  type: Constants.RESET_NEW_PROTOCOLE_DRAFT,
  payload,
})
export const decreaseQttyProductFromDraftTake = payload => ({
  type: Constants.DECREASE_PRODUCT_QTTY_PROTOCOLE_DRAFT,
  payload,
})
export const increaseQttyProductFromDraftTake = payload => ({
  type: Constants.INCREASE_PRODUCT_QTTY_PROTOCOLE_DRAFT,
  payload,
})
export const setProductNotesDraft = payload => ({
  type: Constants.SET_PRODUCT_NOTES_PROTOCOLE_DRAFT_TAKE,
  payload,
})
export const addTake = payload => ({
  type: Constants.ADD_PROTOCOLE_TAKE,
  payload,
})
export const removeTake = payload => ({
  type: Constants.REMOVE_PROTOCOLE_TAKE,
  payload,
})
export const setTakeLabel = payload => ({
  type: Constants.SET_PROTOCOLE_TAKE_LABEL,
  payload,
})
