import styled from 'styled-components/macro'

export const Container = styled.div``

export const InfosWrapper = styled.div`
  width: 330px;
`
export const FlexCardAmountWrapper = styled.div`
  width: 330px;
  margin-top: 10px;
`
