import styled from 'styled-components/macro'

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`

export const Title = styled.h3`
  font-size: 22px;
  margin-bottom: 20px;
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
`
