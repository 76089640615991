import {
  Container,
  Title,
  List,
  Item,
  ItemIcon,
  ItemText,
  Card,
  CardTitle,
  CardText,
  FlexList,
  ListTitle,
} from './style'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { PosologyProps } from './props'
import { useGetPosologieByProductId } from '../../../../../services/api/posologies/posologies'
import { HorizontalSeparator } from '../../left-side/style'

const Posology = ({ product }: PosologyProps) => {
  // Attributs
  const contreIndication = product?.contraindications?.split('/')
  const allergies = product?.allergies?.split('/')

  //BE Calls
  const { data: posology } = useGetPosologieByProductId(product?.id)

  return (
    <Container>
      <Title>Posologie & contre-indications</Title>
      <HorizontalSeparator />
      <div>
        <FlexList>
          <Card>
            <CardTitle>Dosage recommandé</CardTitle>
            <CardText>{posology?.recommendedDosage}</CardText>
          </Card>
          <Card>
            <CardTitle>Durée recommandée</CardTitle>
            <CardText>{posology?.recommendedDuration}</CardText>
          </Card>
          <Card>
            <CardTitle>Conseils d'utilisation</CardTitle>
            <CardText>{posology?.usingAdvice}</CardText>
          </Card>
        </FlexList>
        <List>
          <ListTitle>Contre-indications</ListTitle>
          {contreIndication?.map(value => {
            return (
              <Item>
                <ItemIcon>
                  <IoCloseCircleOutline />
                </ItemIcon>
                <ItemText>{value}</ItemText>
              </Item>
            )
          })}
          <ListTitle>Allergènes</ListTitle>
          {allergies?.map(value => {
            return (
              <Item>
                <ItemIcon>
                  <IoCloseCircleOutline />
                </ItemIcon>
                <ItemText>{value}</ItemText>
              </Item>
            )
          })}
        </List>
      </div>
    </Container>
  )
}

export default Posology
