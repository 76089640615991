import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Wrapper = styled.div`
  margin-top: 20px;
  background-color: yellow;
  width: 49%;
  height: auto;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 15px;
  padding: 25px 20px;
  overflow: auto;
  min-height: 336.5px;
  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
    margin-bottom: 20px;
  }
`

export const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  h3 {
    font-size: 16px;
  }

  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    font-size: 12px;
    opacity: 0.7;
  }

  span {
    font-size: 13px;
    display: block;
    margin-top: 1px;
    margin-right: 4px;
  }
`

export const Steps = styled.div`
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
`
export const StepsTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 14px;
  opacity: 0.7;
`

export const StepWrapper = styled.div`
  padding: 20px 40px;
  background-color: #f4f6fa;
  display: flex;
  flex-direction: column;
  border-bottom: ${props => (props.borderBottom ? '1px solid #eee' : '')};
`

export const Step = styled.div`
  cursor: pointer;
  background-color: #f4f6fa;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const StepLeft = styled.div`
  display: flex;
  align-items: center;
`

export const StepIcon = styled.span`
  font-size: 14px;
  opacity: 0.8;
`

export const StepText = styled.div`
  margin-left: 10px;
  margin-bottom: 3px;
  font-size: 13px;
`

export const CollapseContent = styled.div`
  margin-top: 15px;
  font-size: 14px;
  p {
    margin-bottom: 10px;
  }
  ul {
    margin-top: 5px;
    margin-left: 20px;
  }
`
