import { useState } from 'react'
import {
  Category,
  MenuContainer,
  GoKebabVerticalWrapper,
  CategoryName,
  MenuContainerItem,
} from './style'
import { Dropdown } from '../../../../ui'
import CreateCategory from '../create-category'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { useQueryClient } from 'react-query'
import { VscKebabVertical } from 'react-icons/vsc'
import { useDeletePrescriberFavoriteListById } from '../../../../../services/api/favorites/favorites'
import { useGetMe } from '../../../../../utils/hooks/getMe'
import {
  PrescriberFavoriteListDTO,
  Role,
} from '../../../../../services/api/types'
export interface Props {
  category: PrescriberFavoriteListDTO
  currentFavoriteListId: string
}

const CategoryItem = ({ category, currentFavoriteListId }: Props) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const history = useHistory()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const queryClient = useQueryClient()

  // Refetch favorites after category modification
  function refetchFavorite() {
    queryClient.invalidateQueries([
      `/prescribers/${prescriber?.id}/favorite-lists`,
    ])
  }

  function refetchAllFavorite() {
    queryClient.invalidateQueries([
      `/prescribers/${prescriber?.id}/favorite-lists/items`,
    ])
  }

  // Delete favorite list mutation
  const { mutateAsync: deletePrescriberFavoriteListById } =
    useDeletePrescriberFavoriteListById({
      mutation: {
        onSuccess: () => {
          toast.success('La catégorie a bien été supprimée')
          history.push('/favorites')
          refetchFavorite()
          refetchAllFavorite()
        },
        onError: () => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })

  // Functions
  const handleDeleteFavoriteList = () => {
    const prescriberId = prescriber?.id
    const favoriteListId = category?.id

    if (prescriberId && favoriteListId) {
      deletePrescriberFavoriteListById({
        prescriberId,
        favoriteListId,
      })
    }
  }

  const handleMenuItemClick = () => {
    setIsDialogOpen(true)
  }

  const handleCategoryClick = () => {
    history.push('/favorites?favoriteListId=' + category?.id)
  }

  // Render
  return (
    <Category
      key={category?.id}
      onClick={handleCategoryClick}
      currentFavoriteListId={currentFavoriteListId === category?.id}
    >
      <CategoryName>{category?.name}</CategoryName>
      <Dropdown
        content={
          <MenuContainer>
            <MenuContainerItem onClick={handleMenuItemClick}>
              Modifier la catégorie
            </MenuContainerItem>
            <MenuContainerItem onClick={handleDeleteFavoriteList}>
              Supprimer la catégorie
            </MenuContainerItem>
          </MenuContainer>
        }
      >
        <GoKebabVerticalWrapper>
          <VscKebabVertical />
        </GoKebabVerticalWrapper>
      </Dropdown>

      <CreateCategory
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        categoryName={category?.name}
        categoryId={category?.id}
      />
    </Category>
  )
}

export default CategoryItem
