import Layout from '../../components/layout/portail'
import { Content, Title, SubTitle, Header } from './style'
import { Helmet } from 'react-helmet'
import OrdersTable from '../../components/order-patient'

function PatientOrdersHistoryPage() {
  // Render
  return (
    <Layout
      nav={[
        {
          url: '/patient/recommendations',
          text: 'Mes recommandations',
        },
        // {
        //   url: '/patient/orders',
        //   text: 'Historique de commandes',
        // },
        {
          url: '/patient/catalog',
          text: 'Catalogue',
        },
      ]}
      showCart
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | commandes</title>
      </Helmet>
      <Content>
        <Header>
          <Title>Historique des commandes</Title>
          <SubTitle>Chaque ligne correspond à une commande effectuée</SubTitle>
        </Header>
        <OrdersTable />
      </Content>
    </Layout>
  )
}

export default PatientOrdersHistoryPage
