import Constants from './constants'

export const initCartLoading = () => ({
  type: Constants.INIT_CART_LOADING,
})
export const initCartSuccess = payload => ({
  type: Constants.INIT_CART_SUCCESS,
  payload: payload,
})
export const initCartFailed = error => ({
  type: Constants.INIT_CART_FAILED,
  error,
})

export const fecthCartLoading = () => ({
  type: Constants.FETCH_CART_LOADING,
})
export const fecthCartSuccess = payload => ({
  type: Constants.FETCH_CART_SUCCESS,
  payload: payload,
})
export const fecthCartFailed = error => ({
  type: Constants.FETCH_CART_FAILED,
  error,
})

export const addCartLoading = () => ({
  type: Constants.ADD_CART_LOADING,
})
export const addCartSuccess = payload => ({
  type: Constants.ADD_CART_SUCCESS,
  payload: payload,
})
export const addCartFailed = error => ({
  type: Constants.ADD_CART_FAILED,
  error,
})

export const fetchCartLoading = () => ({
  type: Constants.FETCH_CART_LOADING,
})
export const fetchCartSuccess = payload => ({
  type: Constants.FETCH_CART_SUCCESS,
  payload: payload,
})
export const fetchCartFailed = error => ({
  type: Constants.FETCH_CART_FAILED,
  error,
})

export const updateCartLoading = () => ({
  type: Constants.UPDATE_CART_LOADING,
})
export const updateCartSuccess = payload => ({
  type: Constants.UPDATE_CART_SUCCESS,
  payload: payload,
})
export const updateCartFailed = error => ({
  type: Constants.UPDATE_CART_FAILED,
  error,
})

export const cartLinesRemoveLoading = () => ({
  type: Constants.CART_LINES_REMOVE_LOADING,
})
export const cartLinesRemoveSuccess = payload => ({
  type: Constants.CART_LINES_REMOVE_SUCCESS,
  payload: payload,
})
export const cartLinesRemoveFailed = error => ({
  type: Constants.CART_LINES_REMOVE_FAILED,
  error,
})

export const cartAttributesUpdateLoading = () => ({
  type: Constants.CART_ATTRIBUTES_UPDATE_LOADING,
})
export const cartAttributesUpdateSuccess = payload => ({
  type: Constants.CART_ATTRIBUTES_UPDATE_SUCCESS,
  payload: payload,
})
export const cartAttributesUpdateFailed = error => ({
  type: Constants.CART_ATTRIBUTES_UPDATE_FAILED,
  error,
})

export const cartDiscountUpdateLoading = () => ({
  type: Constants.CART_DISCOUNT_UPDATE_LOADING,
})
export const cartDiscountUpdateSuccess = payload => ({
  type: Constants.CART_DISCOUNT_UPDATE_SUCCESS,
  payload: payload,
})
export const cartDiscountUpdateFailed = error => ({
  type: Constants.CART_DISCOUNT_UPDATE_FAILED,
  error,
})
