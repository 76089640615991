import { Success, Info } from './style'
import { QuizStatusTagProps } from './props'
import { QuizStatus } from '../../../services/api/types'

const getComponent = (status: QuizStatus) =>
  ({
    [QuizStatus.SENT]: Info,
    [QuizStatus.COMPLETED]: Success,
  }[status])

const getDisplayText = status =>
  ({
    [QuizStatus.SENT]: 'Envoyé',
    [QuizStatus.COMPLETED]: 'Complété',
  }[status])

const QuizStatusTag = ({ status }: QuizStatusTagProps) => {
  // Attributes
  let Component = getComponent(status)

  // Render
  return <Component>{getDisplayText(status)}</Component>
}

export default QuizStatusTag
