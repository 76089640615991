import Popup from 'reactjs-popup'
import {
  Wrapper,
  Container,
  Header,
  PriceWrapper,
  CTA,
  CartWrapper,
  CartWrapperText,
  CartQuantity,
} from './style'
import { useQuery } from 'react-query'
import { CartService } from '../../../../services/cart.service'
import { CART_ITEM_COUNT_QUERY, CART_QUERY } from '../../../../utils/constants'
import { BsCart2 } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { CartProps } from './props'

const Cart = ({ url }: CartProps) => {
  const cart = useQuery(CART_QUERY, () => {
    return CartService.getCart()
  })
  const itemCount = useQuery(CART_ITEM_COUNT_QUERY, () =>
    CartService.getItemCount()
  )

  return (
    <Wrapper>
      <Popup
        trigger={
          <Link to={url ? url : '/cart'}>
            <CartWrapper>
              <CartWrapperText>
                <p> Mon Panier</p>
                <BsCart2 />
              </CartWrapperText>
              <CartQuantity>{itemCount?.data}</CartQuantity>
            </CartWrapper>
          </Link>
        }
        position='bottom center'
        on='hover'
        closeOnDocumentClick
        mouseLeaveDelay={100}
        mouseEnterDelay={0}
        contentStyle={{ padding: '0px', border: 'none' }}
        arrow={false}
      >
        <Container>
          <Header>Ton Panier ({itemCount?.data})</Header>

          <PriceWrapper>
            <span>Sous-total</span>
            <span>€{cart?.data?.subtotal?.amount}</span>
          </PriceWrapper>

          {itemCount?.data > 0 && (
            <Link to={url ? url : '/cart'}>
              <CTA>Continuer vers la page panier</CTA>
            </Link>
          )}
        </Container>
      </Popup>
    </Wrapper>
  )
}

export default Cart
