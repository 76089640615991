import { useState } from 'react'
import {
  Container,
  Label,
  Textarea,
  CustomMessageWrapper,
  FileList,
  File,
} from './style'
import { PersonalizedMessageProps } from './props'
import FileUpload from '../file-upload'
import { GrClose } from 'react-icons/gr'

const PersonalizedMessage = ({
  setCustomMessage,
  customMessage,
  style,
  setDraftChanged,
  draft,
  setFieldValue,
}: PersonalizedMessageProps) => {
  const [isFormFilesOpened, setIsFormFilesOpened] = useState(false)

  const autoGrowTextarea = event => {
    event.target.style.height = 'auto' // Reset height to recalculate
    event.target.style.height = `${event.target.scrollHeight}px` // Set new height based on content
  }

  return (
    <Container style={style}>
      <Label>
        <p>Note pour le client</p>
        <FileUpload
          isFormFilesOpened={isFormFilesOpened}
          setIsFormFilesOpened={setIsFormFilesOpened}
          draft={draft}
          setFieldValue={setFieldValue}
          setDraftChanged={setDraftChanged}
        />
      </Label>
      {setCustomMessage ? (
        <Textarea
          placeholder='Ecrivez votre message...'
          value={customMessage}
          onChange={e => {
            setCustomMessage(e.target.value)
            setDraftChanged(true)
          }}
          onInput={autoGrowTextarea}
        ></Textarea>
      ) : (
        <CustomMessageWrapper>{customMessage}</CustomMessageWrapper>
      )}

      <FileList>
        {draft?.files?.map(file => (
          <File key={file.id}>
            <a href={file.fileUrl} target='_blank' rel='noreferrer'>
              {file?.fileName}
            </a>
            <span
              onClick={() => {
                setDraftChanged(true)
                setFieldValue(
                  'files',
                  draft?.files.filter(f => f.fileName !== file.fileName)
                )
              }}
            >
              <GrClose />
            </span>
          </File>
        ))}
      </FileList>
    </Container>
  )
}

export default PersonalizedMessage
