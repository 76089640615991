import styled from 'styled-components/macro'

export const StepContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 52px;
  }
`

export const Step = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

export const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: ${({ isActive }) => (isActive ? '#619D7E' : '#F8F9FC')};
  color: ${({ isActive }) => (isActive ? '#FFFFFF' : '#3A845E')};

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #080038;
`
