import { MdExpandMore } from 'react-icons/md'
import {
  Checkbox,
  FilterBody,
  FilterContainer,
  FilterHeader,
  FilterTitle,
  ValuesContainer,
  ValueItem,
  ValueContainer,
  CheckboxContainer,
  StyledCheckBig,
  WrapperSearch,
  ClearButton,
  BadgeContainer,
  FilterReinitialize,
  IconContainer,
} from './style'
import { IFilterProps } from './props'
import InputEl from '../../../ui/input'
import { useFilterLogic } from './logic'
import { AiOutlineClose } from 'react-icons/ai'
import Badge from '../../../auth/content/badge'

const Filter = <T,>(props: IFilterProps<T>) => {
  // Attributes
  const {
    isExpanded,
    changeSizeContainer,
    search,
    setSearch,
    filteredValues,
    getTexts,
    handleClickItem,
    isChecked,
    resetAllFilters,
    handleClickSortBy,
    isCheckedSortBy,
  } = useFilterLogic(props)

  // Functions
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: T
  ) => {
    e.stopPropagation()
    handleClickItem(e.target.checked, value)
  }

  function sortFilteredValues(
    filteredValues: any[],
    isChecked: (value: any) => boolean
  ) {
    return filteredValues?.sort((a, b) => {
      const isCheckedA = isChecked(a)
      const isCheckedB = isChecked(b)

      if (isCheckedA && !isCheckedB) {
        return -1
      } else if (!isCheckedA && isCheckedB) {
        return 1
      } else {
        return a['name'].localeCompare(b['name'])
      }
    })
  }

  function modifyDisplayValue(filteredValue: any) {
    if (!filteredValue) return
    switch (props.type) {
      case 'allergens':
        return 'Sans ' + filteredValue['name']
      default:
        return filteredValue['name']
    }
  }

  const sortedFilteredValues = sortFilteredValues(filteredValues, isChecked)

  // Render
  return (
    <FilterContainer>
      <FilterHeader onClick={changeSizeContainer}>
        <FilterTitle>{getTexts().title}</FilterTitle>
        <BadgeContainer>
          {!!props.selectedValues?.length && (
            <Badge value={props.selectedValues?.length} />
          )}
          <IconContainer isOpen={isExpanded}>
            <MdExpandMore style={{ width: '1.25rem', height: '1.25rem' }} />
          </IconContainer>
        </BadgeContainer>
      </FilterHeader>
      {isExpanded && (
        <FilterBody>
          {props.type !== 'sortBy' && (
            <>
              <WrapperSearch>
                <InputEl
                  type='text'
                  onChange={e => {
                    setSearch(e.target.value)
                  }}
                  value={search}
                  placeholder={getTexts().placeholder}
                  iconLeft='search'
                  inputContainerStyling={{
                    backgroundColor: '#F8F9FC',
                  }}
                />
                {search && (
                  <ClearButton onClick={() => setSearch('')}>
                    <AiOutlineClose style={{ width: '0.75rem' }} />
                  </ClearButton>
                )}
              </WrapperSearch>
            </>
          )}
          <ValuesContainer>
            {props.type === 'sortBy' && (
              <>
                {props.allValues?.map(value => {
                  return (
                    <ValueContainer
                      key={value['value']}
                      onClick={() => handleClickSortBy(value['value'])}
                    >
                      <CheckboxContainer>
                        <Checkbox
                          type='checkbox'
                          onChange={() => handleClickSortBy(value['value'])}
                          checked={isCheckedSortBy(value['value'])}
                        />
                        <StyledCheckBig />
                      </CheckboxContainer>
                      <ValueItem>{value['label']}</ValueItem>
                    </ValueContainer>
                  )
                })}
              </>
            )}
            {sortedFilteredValues?.map(filteredValue => {
              return (
                <ValueContainer
                  key={filteredValue['id']}
                  onClick={() =>
                    handleClickItem(!isChecked(filteredValue), filteredValue)
                  }
                >
                  <CheckboxContainer>
                    <Checkbox
                      type='checkbox'
                      onChange={e => handleCheckboxChange(e, filteredValue)}
                      checked={isChecked(filteredValue)}
                    />
                    <StyledCheckBig />
                  </CheckboxContainer>
                  <ValueItem>{modifyDisplayValue(filteredValue)}</ValueItem>
                </ValueContainer>
              )
            })}
          </ValuesContainer>
          {!!props.selectedValues?.length && (
            <FilterReinitialize onClick={() => resetAllFilters()}>
              Réinitialiser les filtres
            </FilterReinitialize>
          )}
        </FilterBody>
      )}
    </FilterContainer>
  )
}

export default Filter
