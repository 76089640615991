import { useState } from 'react'
import { Form, ButtonProtocolsWrapper, FormTitle, FieldWrapper } from './style'
import { Button, Field } from '../../../../ui'
import { useGetMe } from '../../../../../utils/hooks/getMe'
import { useGetProtocols } from '../../../../../services/api/protocols/protocols'
import {
  GetProtocolsType,
  PrescriptionType,
  Role,
} from '../../../../../services/api/types'
import { SelectProtocolProps } from './props'
import { Tab } from '../../../../../utils/constants'

const SelectProtocol = ({
  canSelectProtocole,
  setSelectedProtocolId,
  selectedProtocole,
}: SelectProtocolProps) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const [activeTab, setActiveTab] = useState<Tab>(Tab.TEMPLATE_COMPLIMENT)

  // Fetch Protocols
  const { data: protocols, isLoading: isProtocolsLoading } = useGetProtocols(
    prescriber?.id,
    {
      protocolsType:
        activeTab === Tab.TEMPLATE_COMPLIMENT
          ? GetProtocolsType.COMPLIMENT
          : GetProtocolsType.SELF,
      size: 5000,
    },
    {
      query: {
        enabled: !!prescriber?.id,
      },
    }
  )

  const filteredProtocols = protocols?.data?.filter(protocol => {
    return protocol.protocolType !== PrescriptionType.MARKETPLACE
  })

  const protocolsOptions =
    filteredProtocols?.map(p => ({
      value: p.id,
      label: p.name,
    })) ?? []
  protocolsOptions.push({ value: null, label: 'Aucun' })

  // Render
  return (
    <Form>
      {canSelectProtocole && (
        <>
          <FormTitle>Sélectionner un protocole existant</FormTitle>
          <ButtonProtocolsWrapper>
            <Button
              appearance='minimal'
              onClick={e => {
                e.preventDefault()
                setActiveTab(Tab.TEMPLATE_COMPLIMENT)
              }}
              isActive={activeTab === Tab.TEMPLATE_COMPLIMENT}
              iconLeft={undefined}
              isDisabled={undefined}
              isLoading={undefined}
            >
              Simplycure
            </Button>
            <Button
              appearance='minimal'
              onClick={e => {
                e.preventDefault()
                setActiveTab(Tab.TEMPLATE_CUSTOM)
              }}
              isActive={activeTab === Tab.TEMPLATE_CUSTOM}
              iconLeft={undefined}
              isDisabled={undefined}
              isLoading={undefined}
            >
              Mes protocoles
            </Button>
          </ButtonProtocolsWrapper>
          <FieldWrapper>
            <Field
              label=''
              type='select'
              options={protocolsOptions}
              value={{
                value: selectedProtocole?.id ?? '',
                label: selectedProtocole?.name ?? '',
              }}
              isLoading={isProtocolsLoading}
              onChange={data => {
                // We click on the same protocol or we remove the protocol selection
                if (
                  data.value === (selectedProtocole?.id ?? '') ||
                  !data.value
                ) {
                  setSelectedProtocolId(null)
                  return
                }
                // A new protocol has been selected and we want this to be used for the prescription
                setSelectedProtocolId(data.value)
              }}
              isSearchable
              name={undefined}
              id={undefined}
              onBlur={undefined}
              error={undefined}
              touched={undefined}
              isRequired={undefined}
              ref={undefined}
            />
          </FieldWrapper>
        </>
      )}
    </Form>
  )
}

export default SelectProtocol
