import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import NakedLayout from '../../../layout/naked/NakedLayout'
import LoginForm from '../login-form'
import {
  Wrapper,
  FooterText,
  ImageLogo,
  Subtitle,
  ImageText,
  Image,
  TextImage,
} from './style'
import { BackButton } from '../../../ui'
import { useHistory } from 'react-router'

const LoginPatientWebApp = () => {
  //Attributes
  const history = useHistory()

  //Functions
  const handleBackButton = () => {
    history.push('/portail')
  }

  //Return
  return (
    <NakedLayout>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Login </title>
      </Helmet>
      <Wrapper>
        <BackButton
          style={{ marginBottom: '20px' }}
          onClick={handleBackButton}
        />
        <ImageLogo
          src={'/assets/images/logo-compliment.png'}
          alt='logo-image'
        />
        <Subtitle>Connectez-vous à votre compte Client !</Subtitle>
        <LoginForm />
        <FooterText>
          Pas encore de compte ?{' '}
          <Link
            to={{
              pathname: '/patient/register',
            }}
          >
            Créer un compte
          </Link>
        </FooterText>
      </Wrapper>
      <ImageText>
        <Image src={'/assets/images/frame.png'} alt='image-easy-presciption' />
        <TextImage>
          Commandez vos compléments alimentaires en toute simplicité
        </TextImage>
      </ImageText>
    </NakedLayout>
  )
}

export default LoginPatientWebApp
