import { useState } from 'react'
import toast from 'react-hot-toast'
import { Drawer, Dropdown, FavIcon } from './style'
import { FavoriteProps } from './props'
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import {
  useGetAllPrescriberFavoriteListsByPrescriberId,
  useDeletePrescriberFavoriteListItemByProductIdAndListId,
  useAddPrescriberFavoriteListItemToList,
} from '../../../../services/api/favorites/favorites'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { useQueryClient } from 'react-query'
import CreateCategory from '../../favorites/categories/create-category'
import {
  refetchAllFavorite,
  refetchaFvoriteListsByProductId,
} from '../../../../utils/helpers'
import { PageType } from '../../../../utils/constants'
import { ButtonFavorite } from '../style'
import { FavoriteDropDownContent } from './dropdown-content'

const Favorite = ({
  bookmarks,
  product,
  type,
  favoriteId,
  userFavoriteLists,
  refetchCurrentFavoriteList,
}: FavoriteProps) => {
  // Attrubutes
  const [isCreateCategoryDialogOpen, setIsCreateCategoryDialogOpen] =
    useState<boolean>(false)

  const [isOpen, setIsOpen] = useState(false)
  const isProductNotInCurrentFavoriteList = !userFavoriteLists?.find(
    userProduct => userProduct?.handle === product?.handle
  )
  const { data: prescriber } = useGetMe()

  const queryClient = useQueryClient()

  const { mutateAsync: addPrescriberFavoriteListItemToList } =
    useAddPrescriberFavoriteListItemToList({
      mutation: {
        onSuccess: () => {
          toast.success('Le produit a bien été ajouté aux favoris')
          refetchAllFavorite(queryClient, prescriber?.id)
          refetchaFvoriteListsByProductId(
            queryClient,
            prescriber?.id,
            product?.id
          )
          if (!!refetchCurrentFavoriteList) return refetchCurrentFavoriteList()
        },
        onError: error => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })
  const { mutateAsync: deletePrescriberFavoriteListItemByProductIdAndListId } =
    useDeletePrescriberFavoriteListItemByProductIdAndListId({
      mutation: {
        onSuccess: () => {
          toast.success('Le produit a bien été supprimé des favoris')
          refetchAllFavorite(queryClient, prescriber?.id)
          refetchaFvoriteListsByProductId(
            queryClient,
            prescriber?.id,
            product?.id
          )
          if (!!refetchCurrentFavoriteList) return refetchCurrentFavoriteList()
        },
        onError: error => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })

  const { data: favoriteLists } =
    useGetAllPrescriberFavoriteListsByPrescriberId(prescriber?.id, null, {
      query: {
        enabled: !!prescriber?.id && isOpen,
      },
    })

  // Functions
  const closeCreateCategoryDialog = () => {
    setIsCreateCategoryDialogOpen(false)
  }

  const handleClickOnFavIcon = () => {
    if (!favoriteLists?.length) {
      setIsCreateCategoryDialogOpen(true)
    }

    if (isProductNotInCurrentFavoriteList)
      return addPrescriberFavoriteListItemToList({
        favoriteListId: favoriteId,
        data: {
          productId: product?.id,
        },
      })

    return deletePrescriberFavoriteListItemByProductIdAndListId({
      productId: product?.id,
      favoriteListId: favoriteId,
    })
  }

  const addProductToFavorite = (favoriteId: string) => {
    addPrescriberFavoriteListItemToList({
      favoriteListId: favoriteId,
      data: {
        productId: product?.id,
      },
    })
  }

  function handleAddToFavorite() {
    if (!favoriteLists?.length) {
      return setIsCreateCategoryDialogOpen(true)
    }
    if (isProductNotInCurrentFavoriteList)
      return addPrescriberFavoriteListItemToList({
        favoriteListId: favoriteId,
        data: {
          productId: product?.id,
        },
      })

    return deletePrescriberFavoriteListItemByProductIdAndListId({
      productId: product?.id,
      favoriteListId: favoriteId,
    })
  }

  function textToDisplay() {
    if (isProductNotInCurrentFavoriteList) return 'Ajouter à'
    return 'Retirer de'
  }

  const favoriteName = favoriteLists
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .find(favorite => favorite.id === favoriteId)?.name

  // Render
  return (
    <>
      <Dropdown
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        tabIndex={0}
      >
        {type === PageType.FAVORITE ? (
          <ButtonFavorite onClick={handleAddToFavorite}>
            {favoriteId
              ? `${textToDisplay()} ${favoriteName}`
              : 'Ajouter aux favoris'}
          </ButtonFavorite>
        ) : (
          <FavIcon onClick={handleClickOnFavIcon}>
            {bookmarks ? <AiFillStar /> : <AiOutlineStar />}
          </FavIcon>
        )}
        <Drawer isOpen={isOpen}>
          {!favoriteId ? (
            !!favoriteLists?.length && (
              <FavoriteDropDownContent
                isOpen={isOpen}
                product={product}
                favoriteLists={favoriteLists}
                onCreateCategory={() => setIsCreateCategoryDialogOpen(true)}
                refetchCurrentFavoriteList={refetchCurrentFavoriteList}
              />
            )
          ) : (
            <></>
          )}
        </Drawer>
      </Dropdown>
      {isCreateCategoryDialogOpen && (
        <CreateCategory
          isOpen={isCreateCategoryDialogOpen}
          onClose={closeCreateCategoryDialog}
          addProductToFavorite={addProductToFavorite}
          refetchCurrentFavoriteList={refetchCurrentFavoriteList}
        />
      )}
    </>
  )
}

export default Favorite
