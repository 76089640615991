export const reactSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#2D2C2C' : '#8F95B2',
    backgroundColor: 'white',
    height: '38px',
    cursor: 'pointer',
    textSize: '14px',
    '&:active': {
      backgroundColor: 'white',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '38px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: !state.menuIsOpen ? '10px' : 'initial',
    borderBottomRightRadius: !state.menuIsOpen ? '10px' : 'initial',
    boxShadow: 'none',
    borderLeft: `1px solid #E9EAF0`,
    borderTop: `1px solid #E9EAF0`,
    borderRight: `1px solid #E9EAF0`,
    borderBottom: !state.menuIsOpen ? `1px solid #E9EAF0` : '1px solid white',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    borderRadius: '20px',
    backgroundColor: '#E9EAF0',
    padding: '2px',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    textSize: '14px',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  menu: (provided, state) => ({
    backgroundColor: 'white',
    marginTop: 'none',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    borderLeft: `1px solid #E9EAF0`,
    borderBottom: `1px solid #E9EAF0`,
    borderRight: `1px solid #E9EAF0`,
    position: 'absolute',
    width: '100%',
    zIndex: 20,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#8F95B2',
    fontSize: '14px',
    fontWeight: '400',
  }),
  singleValue: (provided, state) => ({
    fontSize: '14px',
  }),
}

export const customStylesError = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#2D2C2C' : '#8F95B2',
    backgroundColor: 'white',
    height: '48px',
    cursor: 'pointer',
    textSize: '14px',
    '&:active': {
      backgroundColor: 'white',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '48px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: !state.menuIsOpen ? '10px' : 'initial',
    borderBottomRightRadius: !state.menuIsOpen ? '10px' : 'initial',
    boxShadow: 'none',
    borderLeft: `1px solid #F46363`,
    borderTop: `1px solid #F46363`,
    borderRight: `1px solid #F46363`,
    borderBottom: !state.menuIsOpen ? `1px solid #F46363` : '1px solid white',
    '&:hover': {},
  }),
  multiValue: (provided, state) => ({
    ...provided,
    borderRadius: '20px',
    backgroundColor: '#E9EAF0',
    padding: '0 2px',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    textSize: '14px',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  menu: (provided, state) => ({
    backgroundColor: 'white',
    marginTop: 'none',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    borderLeft: `1px solid #F46363`,
    borderBottom: `1px solid #F46363`,
    borderRight: `1px solid #F46363`,
    position: 'absolute',
    width: '100%',
    zIndex: 20,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#8F95B2',
    fontSize: '14px',
    fontWeight: '400',
  }),
  singleValue: (provided, state) => ({
    fontSize: '14px',
  }),
}
